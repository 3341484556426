import React from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import { styles } from '../../styles';
import { IntegratedInputProps } from '../../CardCustomField.i';
import { useCardCustomFieldContext } from '../../../../../context/CardCustomFieldContext';
import { useCardDataContext } from '../../../../../context/CardDataContext';

const useStyles = makeStyles(() => createStyles(styles()));

export const IntegratedField = ({
  customField,
  phaseIndex,
  customFieldIndex,
  isInCurrentCardPhase,
}: IntegratedInputProps) => {
  const classes = useStyles();
  const { handleOpenModal } = useCardCustomFieldContext();
  const { handleFillCustomField } = useCardDataContext();
  const { primaryKeyColumnName } = customField;
  const { displayColumnName } = customField;
  const hasFormat =
    customField?.format !== null && customField?.format !== undefined;
  const hasDynamicFormat =
    customField?.dynamicFormat !== null &&
    customField?.dynamicFormat !== undefined;
  const currentFormat = hasFormat
    ? customField?.format
    : customField?.dynamicFormat;
  const primaryKeyDisplayName: string = hasDynamicFormat
    ? currentFormat[primaryKeyColumnName]?.name || ''
    : primaryKeyColumnName;
  const formattedDisplayName: string = hasDynamicFormat
    ? currentFormat[displayColumnName]?.name || ''
    : displayColumnName;

  return (
    <Box className={classes.typeIntegrated}>
      <Box className={classes.customFieldIntegrationTypeContainer}>
        {customField.valueJSON &&
        Object.keys(customField.valueJSON).length !== 0 ? (
          <Box>
            <Box className={classes.integratedLabel}>{customField.name}</Box>
            <Typography
              component="span"
              className={classes.integratedFieldPrimaryKey}
            >
              {customField.valueJSON[primaryKeyDisplayName]}
            </Typography>
            <Typography
              align="left"
              component="span"
              noWrap
              className={classes.integratedFieldDisplayColumnName}
            >
              {`|
                ${customField.valueJSON[formattedDisplayName]}`}
            </Typography>
          </Box>
        ) : (
          <TextField
            className={classes.integratedFieldDummyTextField}
            contentEditable={false}
            label={customField.name}
            autoComplete="off"
            disabled={customField.isInactive || customField.isDisabled}
            required={customField.isRequired && isInCurrentCardPhase}
            InputLabelProps={{ shrink: false, style: { width: '100%' } }}
            InputProps={{
              disableUnderline: true,
              onKeyDown: (e: { preventDefault: () => void }) => {
                e.preventDefault();
                return false;
              },
            }}
          />
        )}
      </Box>
      <Box className={classes.integratedFieldButtons}>
        {customField.valueJSON &&
          Object.keys(customField.valueJSON).length !== 0 && (
            <IconButton
              aria-label="Excluir"
              size="small"
              className={classes.typeIntegratedButton}
              disabled={customField.isInactive || customField.isDisabled}
              onClick={() =>
                handleFillCustomField(
                  phaseIndex,
                  customFieldIndex,
                  undefined,
                  {},
                  customField.id,
                )
              }
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
        <IconButton
          aria-label="Procurar campo integrado"
          size="small"
          className={classes.typeIntegratedButton}
          disabled={customField.isInactive || customField.isDisabled}
          onClick={() =>
            handleOpenModal(customField, customFieldIndex, phaseIndex)
          }
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
