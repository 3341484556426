/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Id, toast } from 'react-toastify';

export const toastLoadingUpdate = (
  toastId: Id,
  message: string,
  type: 'success' | 'error',
  secondsToAutoClose: number,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCloseFunction: () => void = () => {},
) => {
  toast.update(toastId, {
    render: message,
    type,
    autoClose: secondsToAutoClose,
    isLoading: false,
    theme: 'colored',
    closeOnClick: true,
    position: toast.POSITION.TOP_RIGHT,
    onClose: () => {
      onCloseFunction();
    },
    closeButton: true,
    delay: 500,
  });
};
