import React, { useCallback, useEffect, useState } from 'react';
import {
  formatWidgetTaskObject,
  splitTaskAndCardIds,
} from 'src/utils/taskUtils';
import { statuses, taskOrigin, types } from 'src/utils/taskFilterColumnsUtils';
import { TaskModalContext } from 'src/context/TaskModalContext';
import { TaskTypesIds } from 'src/utils/taskTypes.constants';
import { MemoizedDataTable } from 'src/components/ui/data-table';
import { SelectTaskTypeModal } from 'src/components/TaskModal/components/SelectTaskTypeModal';
import { ModalDefaultWidthLg } from 'src/components/ModalDefaultWidthLg';
import { TaskModal } from 'src/components/TaskModal';
import {
  isCurrentDateWithinDateRange,
  isDateWithinDateRange,
  isOverdueDate,
  sortByDateProperty,
} from 'src/utils/dateFormatAndComparisonUtils';
import { TaskDeletionDialog } from 'src/components/TaskModal/components/TaskDeletionDialog';
import { Row } from '@tanstack/react-table';
import { taskColumns } from '../components/taskColumns';
import {
  CalendarFilterProps,
  CalendarTaskData,
  TaskData,
  VisualizationTypes,
  WorkspaceTaskData,
} from '../types';
import { TaskFilters } from '../components/taskFilters';
import { TaskDnDCalendar } from '../components/calendar/TaskDnDCalendar';

export interface TaskWidgetProps {
  readonly tasks: WorkspaceTaskData | undefined;
  readonly userOptions: { id: string; name: string }[];
  readonly handleRefreshTaskList: () => void;
}

type objectWithLabelAndValue = { value: string; label: string; id: string };
type AlertStateProps = {
  isOpen: boolean;
  taskId: string | null;
  canSendEmail: boolean;
};
type TaskModalProps = {
  selectedTaskId: string | null;
  newTaskType: TaskTypesIds | null;
  isOpen: boolean;
  isProcessTask: boolean;
  card_id?: string;
  dateRangeSuggestion?: {
    start: Date;
    end: Date;
  };
};

export const UserTasks: React.FC<TaskWidgetProps> = ({
  tasks,
  userOptions,
  handleRefreshTaskList,
}) => {
  const [visualizationFilter, setVisualizationFilter] =
    useState<VisualizationTypes>('TODAY');
  const [allTasks, setAllTasks] = useState<TaskData[]>([]);
  const [tableTasks, setTableTasks] = useState<TaskData[]>([]);
  const [calendarTasks, setCalendarTasks] = useState<CalendarTaskData[]>([]);
  const [filteredCalendarTasks, setFilteredCalendarTasks] = useState<
    CalendarTaskData[]
  >([]);
  const [calendarFilters, setCalendarFilters] = useState<
    CalendarFilterProps | undefined
  >(undefined);
  const [selectTypeModalOpen, setSelectTypeModalOpen] = useState(false);
  const [taskModalState, setTaskModalState] = useState<TaskModalProps>({
    selectedTaskId: null,
    newTaskType: null,
    isOpen: false,
    isProcessTask: false,
    card_id: undefined,
    dateRangeSuggestion: undefined,
  });
  const [deleteAlertState, setDeleteAlertState] = useState<AlertStateProps>({
    isOpen: false,
    taskId: '',
    canSendEmail: false,
  });

  const formatTasks = (
    tasks: WorkspaceTaskData,
    convertDatesToDateObjects: boolean,
  ) => {
    const newCardTasks = tasks.cardTasks.map(task =>
      formatWidgetTaskObject(task, true, false, convertDatesToDateObjects),
    );
    const newCardTasksAsGuest = tasks.cardTasksAsGuest.map(task =>
      formatWidgetTaskObject(task, true, true, convertDatesToDateObjects),
    );
    const newSingleTasks = tasks.singleTasks.map(task =>
      formatWidgetTaskObject(task, false, false, convertDatesToDateObjects),
    );
    const newSingleTasksAsGuest = tasks.singleTasksAsGuest.map(task =>
      formatWidgetTaskObject(task, false, true, convertDatesToDateObjects),
    );
    return [
      ...newCardTasksAsGuest,
      ...newCardTasks,
      ...newSingleTasks,
      ...newSingleTasksAsGuest,
    ];
  };

  useEffect(() => {
    if (tasks) {
      const newTasks = formatTasks(tasks, false);
      const newCalendarTasks = formatTasks(tasks, true)
        .filter(task => task.startsAt && task.endsAt)
        .map(task => ({
          allDay: undefined,
          title: task.name,
          start: task.startsAt as Date,
          end: task.endsAt as Date,
          resource: task,
        }));
      setAllTasks(newTasks);
      setTableTasks(newTasks.filter(task => !task?.isFromConcludedCard));
      setCalendarTasks(newCalendarTasks);
    }
  }, [tasks]);

  const handleCloseSelectModal = () => {
    setSelectTypeModalOpen(false);
  };

  const handleAddNewTask = (dateRange?: { start: Date; end: Date }) => {
    const newDateRange =
      dateRange?.start && dateRange?.end ? dateRange : undefined;
    setSelectTypeModalOpen(true);
    setTaskModalState(oldValue => ({
      ...oldValue,
      dateRangeSuggestion: newDateRange,
    }));
  };

  const handleConfirmSelectModal = (typeId: TaskTypesIds) => {
    setSelectTypeModalOpen(false);
    const timeoutId = setTimeout(() => {
      setTaskModalState(oldValue => ({
        ...oldValue,
        newTaskType: typeId,
        isOpen: true,
        isProcessTask: false,
      }));
    }, 200); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
  };

  const handleOpenTaskModal = useCallback(
    (taskUniqueId: string | null) => {
      const [taskId, cardId] = splitTaskAndCardIds(taskUniqueId);
      const findTaskByIdAndCard = allTasks.find(
        tl =>
          tl.taskId === taskId &&
          tl?.card?.id === cardId &&
          tl.isProcessTask === true,
      );
      const foundTaskId = allTasks.filter(tl => tl.taskId === taskId);
      const taskData =
        foundTaskId.length > 1 && cardId !== undefined && cardId.length > 0
          ? findTaskByIdAndCard
          : foundTaskId[0];

      setTaskModalState({
        newTaskType: null,
        selectedTaskId: taskId,
        isOpen: true,
        isProcessTask: taskData?.isProcessTask || false,
        card_id: taskData?.card?.id || undefined,
        dateRangeSuggestion: undefined,
      });
    },
    [allTasks],
  );

  const handleCloseTaskModal = () => {
    setTaskModalState({
      newTaskType: null,
      selectedTaskId: null,
      isOpen: false,
      isProcessTask: false,
      dateRangeSuggestion: undefined,
    });
    handleRefreshTaskList();
  };

  const handleFilterByVisualization = (type: VisualizationTypes) => {
    let tasks: TaskData[] = [];
    if (type === 'TODAY') {
      tasks = tableTasks
        .filter(
          task =>
            !task.completedAt &&
            task.startsAt &&
            task.endsAt &&
            isCurrentDateWithinDateRange(
              task.startsAt.toString(),
              task.endsAt.toString(),
            ),
        )
        .sort((a, b) => sortByDateProperty(a.startsAt, b.startsAt));
    }
    if (type === 'OVERDUE') {
      tasks = tableTasks
        .filter(
          task =>
            !task.completedAt &&
            task.startsAt &&
            task.endsAt &&
            isOverdueDate(task.endsAt.toString()),
        )
        .sort((a, b) => sortByDateProperty(a.startsAt, b.startsAt));
    }
    if (type === 'UNSCHEDULED') {
      tasks = tableTasks
        .filter(task => !task.completedAt && (!task.startsAt || !task.endsAt))
        .sort((a, b) => sortByDateProperty(a.createdAt, b.createdAt));
    }

    return tasks;
  };

  const handleChangeVisualizationFilter = (type: VisualizationTypes) => {
    setVisualizationFilter(type);
  };

  const handleDeletAlertState = (state: boolean) => {
    setDeleteAlertState(oldValue => ({
      ...oldValue,
      isOpen: state,
    }));
  };

  const handleDeleteTask = useCallback(
    (id: string) => {
      const canSendEmail = !!allTasks.find(
        task =>
          task.id === id &&
          task.isProcessTask === false &&
          task.contacts.length > 0 &&
          task.status !== 'Não agendada',
      );
      setDeleteAlertState({
        isOpen: true,
        taskId: id,
        canSendEmail,
      });
    },
    [allTasks],
  );

  useEffect(() => {
    if (calendarFilters !== undefined && calendarTasks) {
      const newTasks = [...calendarTasks];
      const { account, status, startDateRange, endDateRange } = calendarFilters;
      const filteredTasks = newTasks.filter(task => {
        const taskData = task.resource;
        return (
          (account === undefined ||
            (account !== undefined &&
              !!account.find(a => a.id === taskData.account.id))) &&
          (status === undefined ||
            (status !== undefined &&
              !!status.find(a => a.id === taskData.status))) &&
          (startDateRange === undefined ||
            ((startDateRange.from || startDateRange.to) &&
              isDateWithinDateRange(task.start as Date, startDateRange))) &&
          (endDateRange === undefined ||
            ((endDateRange.from || endDateRange.to) &&
              isDateWithinDateRange(task.end as Date, endDateRange)))
        );
      });
      setFilteredCalendarTasks(filteredTasks || []);
    } else {
      setFilteredCalendarTasks(calendarTasks);
    }
  }, [calendarFilters, calendarTasks]);

  const uniqueAccounts: objectWithLabelAndValue[] = tableTasks.reduce(
    (uniqueList: objectWithLabelAndValue[], currentTask) => {
      const existingAccountIndex = uniqueList.findIndex(
        item => item.id === currentTask.account.id,
      );

      if (existingAccountIndex === -1) {
        uniqueList.push({
          value: currentTask.account.name,
          label: currentTask.account.name,
          id: currentTask.account.id,
        });
      }

      return uniqueList;
    },
    [],
  );

  const uniqueContacts: objectWithLabelAndValue[] = tableTasks.reduce(
    (uniqueList: objectWithLabelAndValue[], currentTask) => {
      if (currentTask.contacts.length > 0) {
        currentTask.contacts.forEach(contact => {
          const existingUserIndex = uniqueList.findIndex(
            item => item.id === contact.id,
          );

          if (existingUserIndex === -1 && contact?.name) {
            uniqueList.push({
              value: contact.name,
              label: contact.name,
              id: contact.id,
            });
          }
        });
      }

      return uniqueList;
    },
    [],
  );

  const columnsToFilter = [
    {
      columnId: 'Situação',
      title: 'Situação',
      options: statuses,
    },
    {
      columnId: 'Tipo',
      title: 'Tipo',
      options: types,
    },
    {
      columnId: 'Origem',
      title: 'Origem',
      options: taskOrigin,
    },
    {
      columnId: 'Conta',
      title: 'Conta',
      options: uniqueAccounts,
    },
    {
      columnId: 'Contato',
      title: 'Contato',
      options: uniqueContacts,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowStyles = useCallback((row: Row<any>) => {
    if (row.original?.isGuestTask) {
      return {
        background: 'rgb(248 250 252)',
      };
    }
    return undefined;
  }, []);

  const handleSetNewTaskDate = (data: CalendarTaskData[]) => {
    setFilteredCalendarTasks(data);
  };

  const filteredTableTasks = visualizationFilter
    ? handleFilterByVisualization(visualizationFilter)
    : tableTasks;

  return (
    <>
      <TaskFilters
        visualizationFilter={visualizationFilter}
        setVisualizationFilter={handleChangeVisualizationFilter}
        userOptions={userOptions}
        handleClickNewTask={handleAddNewTask}
        uniqueAccounts={
          uniqueAccounts
            ? uniqueAccounts.map(ac => ({ id: ac.id, name: ac.value }))
            : []
        }
        setCalendarFilters={setCalendarFilters}
      />
      <TaskModalContext.Provider
        value={{
          handleOpenModal: handleOpenTaskModal,
          handleDeleteTask,
        }}
      >
        {visualizationFilter !== 'CALENDAR' && (
          <div className="p-1 box-border">
            <MemoizedDataTable
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              columns={taskColumns as any}
              data={filteredTableTasks}
              showTableToolbar
              onClickRow={handleOpenTaskModal}
              showGlobalSearchInput
              showColumnVisibility
              showFilters
              columnsToFilter={columnsToFilter}
              initiallyInvisibleColumns={['Tipo', 'Origem', 'Criação']}
              localStorageKey="@Brasao-widget-table-v1:columnVisibility"
              firstDateColumnId="Inicio"
              secondDateColumnId="Fim"
              thirdDateColumnId="Criação"
              classNameTableDiv="max-h-[300px]"
              includeButtonText="Tarefa"
              getRowStyles={getRowStyles}
            />
          </div>
        )}
        {visualizationFilter === 'CALENDAR' && (
          <TaskDnDCalendar
            calendarTasks={filteredCalendarTasks}
            handleOpenTaskModal={handleOpenTaskModal}
            handleAddNewTask={handleAddNewTask}
            handleSetNewTaskDate={handleSetNewTaskDate}
          />
        )}
      </TaskModalContext.Provider>
      {selectTypeModalOpen && (
        <SelectTaskTypeModal
          open={selectTypeModalOpen}
          onClose={handleCloseSelectModal}
          onConfirm={handleConfirmSelectModal}
        />
      )}

      {taskModalState.isOpen && (
        <ModalDefaultWidthLg
          closeModal={handleCloseTaskModal}
          open={taskModalState.isOpen}
          title="Tarefa"
          maxWidth="md"
          height="calc(100vh - 50px)"
          contentWidth="800px"
          titleBackgroundColor="#DFDFDF"
        >
          <TaskModal
            task_id={taskModalState.selectedTaskId}
            closeModal={handleCloseTaskModal}
            newTaskType={taskModalState.newTaskType}
            isProcessTask={taskModalState.isProcessTask}
            card_id={
              taskModalState.isProcessTask ? taskModalState?.card_id : undefined
            }
            dateRangeSuggestion={taskModalState.dateRangeSuggestion}
          />
        </ModalDefaultWidthLg>
      )}

      {deleteAlertState.isOpen && deleteAlertState.taskId && (
        <TaskDeletionDialog
          open={deleteAlertState.isOpen}
          setOpen={handleDeletAlertState}
          taskId={deleteAlertState.taskId}
          handleRefreshTaskList={handleRefreshTaskList}
          canSendEmail={deleteAlertState.canSendEmail}
        />
      )}
    </>
  );
};
