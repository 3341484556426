/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useFormik } from 'formik';

import { toast } from 'react-toastify';
import api from 'src/services/api';
import { validateCsvRow } from './validateCsvRow';
import { ModalCSV } from './ModalCSV';
import { CsvErrors } from './CsvErrors';
import { CsvPreview } from './CsvPreview';
import Button from '../Button';
import { ExpandIcon } from '../ExpandIcon';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      height: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      padding: '10px',
      maxWidth: '845px',
    },
    bodyContent: {
      paddingRight: '2px',
      margin: 0,
      width: '100%',
      height: '100%',
      maxWidth: '845px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    buttonSubmit: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    selectedFile: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '16px',
      overflow: 'hidden',
      marginLeft: '2rem',
    },
    importExample: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      overflowX: 'auto',
      marginTop: '0.5rem',
    },
  }),
);

interface IImportProps {
  openModal: boolean;
  closeModal: () => void;
}

interface IParseCSV {
  header: string[];
  data: Array<string[]>;
}

interface ICSVValidationErrors {
  row: number;
  errors: string[];
}

interface ICSVRowError {
  row: number;
  error: string;
}

export function ModalImportAccounts({
  openModal,
  closeModal,
}: IImportProps): JSX.Element {
  const classes = useStyles();

  const inputFile = useRef<HTMLInputElement>(null);

  const [openInfos, setOpenInfos] = useState<boolean>(true);
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [importErrors, setImportErros] = useState('');
  const [csvImportErrors, setCSVImportErrors] = useState<ICSVRowError[] | null>(
    null,
  );
  const [csvPreview, setCsvPreview] = useState<IParseCSV | null>(null);

  const fileReader = new FileReader();

  const parseCSV = (text: string) => {
    const result: IParseCSV = {
      header: [
        'CPF/CNPJ',
        'Nome',
        'Razão Social',
        'CEP',
        'UF',
        'Rua',
        'Número',
        'Complemento',
        'Bairro',
        'Código IBGE',
        'Nome do contato',
        'E-mail do contato',
      ],
      data: [],
    };
    const content = Array.from(text.split('\n'));

    const maxCols = 12;
    const csvErrors: any = [];
    const formattedCsvRowErrors: any = [];

    content.forEach((item, index) => {
      const row = item.split(',').slice(0, maxCols);
      const formattedRow = row.map(col =>
        col.replace(/(?:\\[rn]|[\r\n]+)+/g, ''),
      );

      if (formattedRow.every(col => col === '')) {
        csvErrors.push({
          row: index,
          errors: ['Linha vazia'],
        });
        return;
      }

      const errors = validateCsvRow(formattedRow);

      if (errors && errors.length > 0 && errors !== 'sem erro') {
        csvErrors.push({
          row: index,
          errors,
        });
      }

      result.data.push(formattedRow);
    });

    csvErrors.forEach((rowError: ICSVValidationErrors) => {
      rowError.errors.forEach(err => {
        formattedCsvRowErrors.push({
          row: rowError.row,
          error: err,
        });
      });
    });

    if (formattedCsvRowErrors.length > 0) {
      toast.error('Arquivo com dados inválidos. Verifique a tabela de erros.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    }

    setCSVImportErrors(formattedCsvRowErrors);
    setCsvPreview(result);
    return result;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImportErros('');

    if (!e.target.files) {
      return;
    }

    if (e.target.files && e.target.files[0]?.type !== 'text/csv') {
      toast.error('Arquivo inválido. Informe um arquivo com extensão csv.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 4000,
      });

      setFile(undefined);
      setFileName('');
      setCSVImportErrors(null);
      setCsvPreview(null);
    } else {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0]?.name || '');
      formik.setFieldValue('fileFormik', e.target.files[0]);

      if (e.target.files[0] !== undefined) {
        fileReader.readAsText(e.target.files[0]);
        fileReader.onloadend = () => {
          parseCSV(fileReader.result as string);
        };
      }
    }
  };

  const initialValues: any = {
    fileFormik: undefined,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (file) {
          const formData = new FormData();
          formData.append('csv_file', file, fileName);
          await api.post(`/accounts/import`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setSubmitting(true);
          setImportErros('');
          toast.success('Arquivo enviado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
      } catch (error: any) {
        setSubmitting(false);
        setImportErros(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onButtonClick = (e: any) => {
    // e.preventDefault();
    // `current` points to the mounted file input element
    inputFile.current && inputFile.current.click();
  };

  return (
    <ModalCSV
      title="Importar Contas"
      open={openModal}
      closeModal={closeModal}
      // maxWidth="lg"
    >
      <Box className={classes.content}>
        <Box className={classes.bodyContent}>
          <form onSubmit={formik.handleSubmit}>
            <Box style={{ marginTop: '10px' }}>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="nowrap"
                justify-content="space-between"
                alignItems="center"
                // alignContent="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="nowrap"
                  width="400px"
                >
                  <Button
                    onClick={e => onButtonClick(e)}
                    startIcon={<CloudUploadIcon />}
                  >
                    Selecionar CSV
                  </Button>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    accept=".csv"
                    onChange={handleOnChange}
                  />
                </Box>
                {fileName && (
                  <Typography className={classes.selectedFile}>
                    Arquivo: {fileName}
                  </Typography>
                )}
              </Box>

              <Box
                mt="25px"
                display="flex"
                flexWrap="nowrap"
                justifyContent="space-between"
                width="100%"
                onClick={() => setOpenInfos(!openInfos)}
              >
                <Typography variant="subtitle2">
                  Orientações para importação do arquivo CSV
                </Typography>
                <ExpandIcon
                  expanded={openInfos}
                  toggleBox={() => setOpenInfos(!openInfos)}
                />
              </Box>

              {openInfos && (
                <>
                  <Typography variant="subtitle2" style={{ marginTop: '25px' }}>
                    Exemplo de formato aceito na importação (dados fictícios):
                  </Typography>
                  <Box className={classes.importExample}>
                    <Box>
                      <pre>
                        "10999126077","Abigail Gusmão",,"89000000","SC","Av. XV
                        de Novembro","100",,"República","4202404",,
                        <br />
                        "09693992008882","AGRO MAURI ALFA PF","AGRO MAURI ALFA
                        PF","88000150","SC","Rua dos Caçadores","2134","Em
                        frente aos bombeiros","Centro","4202156","Sr.
                        Mauri","agrotop@email.com.br"
                      </pre>
                    </Box>
                  </Box>
                  <Box maxWidth="100%" style={{ marginTop: '15px' }}>
                    <Typography variant="subtitle2">
                      Ordem obrigatória dos campos:
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ marginTop: '0.5rem', wordBreak: 'break-word' }}
                    >
                      CPF/CNPJ, Nome, Razão Social, CEP, UF, Rua, Número,
                      Complemento, Bairro, Código IBGE, Nome do contato, E-mail
                      do contato
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: '15px' }}>
                    <Typography variant="subtitle2">Observações:</Typography>
                    <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
                      CPF/CNPJ e Nome são obrigatórios e não podem estar vazios,
                      os demais são opcionais.
                      <br />
                      Cada contato poder ter um <strong>e-mail único</strong>,
                      contatos de uma mesma conta com o mesmo e-mail serão
                      sobrescritos.
                      <br />
                      <strong>Todos os campos</strong> devem ser preenchidos com
                      aspas duplas e separados por vírgula.
                      <br />
                      O campo UF deve ser preenchido apenas com a sigla do
                      estado, exemplo "SC", "PR".
                      <br />
                    </Typography>
                  </Box>
                </>
              )}

              <Divider
                style={{
                  width: '100%',
                  marginTop: '0.2rem',
                  marginBottom: '0.2rem',
                }}
              />

              {csvImportErrors && csvImportErrors.length > 0 && (
                <CsvErrors csvImportErrors={csvImportErrors} />
              )}

              {csvPreview &&
                csvPreview.header &&
                csvPreview.data.length > 0 && (
                  <CsvPreview csvPreview={csvPreview} />
                )}

              <Box
                style={{
                  // position: 'absolute',
                  bottom: '0',
                  marginTop: '1rem',
                  marginBottom: '25px',
                }}
              >
                <ButtonMaterialUI
                  className={classes.buttonSubmit}
                  size="large"
                  variant="contained"
                  color="inherit"
                  onClick={closeModal}
                >
                  Voltar
                </ButtonMaterialUI>
                <Button className={classes.buttonSubmit} type="submit">
                  Enviar
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </ModalCSV>
  );
}
