import React from 'react';
import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import { styles } from './styles';
import { ExpandIcon } from '../../../components/ExpandIcon';
import { BooleanField } from './InputFields/BooleanField';
import { StringOrNumericField } from './InputFields/StringOrNumericField';
import { TimestampField } from './InputFields/TimestampField';
import { IntegratedField } from './InputFields/IntegratedField';
import { TableField } from './InputFields/TableField';
import { DocumentField } from './InputFields/DocumentField';
import { FileField } from './InputFields/FileField';
import { PredefinedField } from './InputFields/PredefinedField';
import { useCardDataContext } from '../../../context/CardDataContext';
import { CardCustomFieldPhaseProps, ICustomField } from '../Card.i';

const useStyles = makeStyles(() => createStyles(styles()));

export const CardCustomFieldPhase: React.FC<CardCustomFieldPhaseProps> = ({
  cardCustomFieldPhase,
  phaseIndex,
}: CardCustomFieldPhaseProps) => {
  const classes = useStyles();
  const { cardCurrentPhase, toggleBox } = useCardDataContext();

  function handleRenderTypes(
    customField: ICustomField,
    customFieldIndex: number,
  ) {
    if (customField.type === 'BOOL') {
      return (
        <Grid item xs={6} key={customField.id}>
          <BooleanField
            customField={customField}
            customFieldIndex={customFieldIndex}
            cardCustomFieldPhaseId={cardCustomFieldPhase.id}
            phaseIndex={phaseIndex}
          />
        </Grid>
      );
    }
    if (
      (customField.type === 'STRING' || customField.type === 'NUMERIC') &&
      !customField.table_id
    ) {
      return (
        <Grid
          item
          xs={customField.isBigString === true ? 12 : 6}
          key={customField.id}
        >
          <StringOrNumericField
            customField={customField}
            customFieldIndex={customFieldIndex}
            cardCustomFieldPhaseId={cardCustomFieldPhase.id}
            phaseIndex={phaseIndex}
          />
        </Grid>
      );
    }
    if (customField.type === 'TIMESTAMP' && !customField.table_id) {
      return (
        <Grid item xs={6} key={customField.id}>
          <TimestampField
            customField={customField}
            phaseIndex={phaseIndex}
            customFieldIndex={customFieldIndex}
            cardCustomFieldPhaseId={cardCustomFieldPhase.id}
          />
        </Grid>
      );
    }
    if (
      customField.type === 'PREDEFINED_STRINGS' &&
      customField.predefinedValues !== undefined &&
      !customField.table_id
    ) {
      return (
        <Grid item xs={6} key={customField.id}>
          <PredefinedField
            customField={customField}
            customFieldIndex={customFieldIndex}
            cardCustomFieldPhaseId={cardCustomFieldPhase.id}
            phaseIndex={phaseIndex}
          />
        </Grid>
      );
    }
    if (customField.type === 'INTEGRATED' && !customField.table_id) {
      return (
        <Grid item xs={6} key={customField.id}>
          <IntegratedField
            customField={customField}
            phaseIndex={phaseIndex}
            customFieldIndex={customFieldIndex}
            isInCurrentCardPhase={
              cardCustomFieldPhase.id === cardCurrentPhase?.id
            }
          />
        </Grid>
      );
    }
    if (customField.type === 'TABLE') {
      return (
        <Grid item xs={12} key={customField.id}>
          <Typography
            align="left"
            gutterBottom
            className={classes.tableInputContainerTitle}
          >
            {customField.name}
          </Typography>
          <TableField
            customField={customField}
            phaseIndex={phaseIndex}
            customFieldIndex={customFieldIndex}
            isInCurrentCardPhase={
              cardCustomFieldPhase.id === cardCurrentPhase?.id
            }
          />
        </Grid>
      );
    }
    if (customField && customField.type === 'DOCUMENT') {
      return (
        <Grid item xs={6} key={customField.id}>
          <DocumentField
            customField={customField}
            phaseIndex={phaseIndex}
            customFieldIndex={customFieldIndex}
          />
        </Grid>
      );
    }
    if (customField.type === 'FILE') {
      return (
        <Grid item xs={6} key={customField.id}>
          <FileField
            customField={customField}
            phaseIndex={phaseIndex}
            customFieldIndex={customFieldIndex}
            customFieldValueJson={customField.valueJSON}
          />
        </Grid>
      );
    }
    return (
      <Typography className={classes.msg}>
        Campos personalizados não definidos
      </Typography>
    );
  }

  return (
    <>
      <Box className={classes.titleSection}>
        <Typography className={classes.phaseName}>
          {cardCustomFieldPhase.name}
        </Typography>
        <ExpandIcon
          expanded={cardCustomFieldPhase.FieldOpenSection}
          toggleBox={() =>
            toggleBox(
              phaseIndex,
              !cardCustomFieldPhase.FieldOpenSection,
              'field',
            )
          }
        />
      </Box>
      {cardCustomFieldPhase.FieldOpenSection ? (
        <Box key={cardCustomFieldPhase.id}>
          {cardCustomFieldPhase.customFields.length === 0 ? (
            <Typography className={classes.msg}>
              Campos personalizados não definidos
            </Typography>
          ) : (
            <Grid container spacing={2}>
              {cardCustomFieldPhase.customFields.map(
                // eslint-disable-next-line array-callback-return
                (customField, customFieldIndex) =>
                  !customField.isHidden
                    ? handleRenderTypes(customField, customFieldIndex)
                    : null,
              )}
            </Grid>
          )}
        </Box>
      ) : null}
      <Divider
        style={{
          marginTop: '2rem',
          marginBottom: '30px',
          width: '100%',
        }}
      />
    </>
  );
};
