/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Box, TextField } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { LinkedCardFields } from 'src/components/LinkedCardFields';
import { TaskTypes } from 'src/utils/taskTypes.constants';
import { TLinkedCardFields } from 'src/interface/ILinkedCardFields';
import { InputNumberMask } from 'src/components/InputNumberMask';
import { ITaskActionProps } from '../../Trigger.i';

export const TaskAction: React.FC<ITaskActionProps> = ({
  formik,
  customFields,
  linkedCardFields,
}: ITaskActionProps) => {
  const taskTypeOptions = Object.values(TaskTypes).sort(
    (a: { name: string }, b: { name: string }) => -b.name.localeCompare(a.name),
  );

  const handleSetLinkedCardFields = (
    newLinkedCardFields: TLinkedCardFields,
  ) => {
    formik.setFieldValue('linkedCardFields', newLinkedCardFields);
  };

  return (
    <Box>
      <Autocomplete
        id="taskData.type"
        getOptionLabel={type => type?.name}
        getOptionSelected={(type, value) => type.id === value.id}
        options={taskTypeOptions}
        onChange={(e, value) => {
          formik.setFieldValue('taskData.type', value.id);
        }}
        disableClearable
        value={
          taskTypeOptions[
            taskTypeOptions.findIndex(
              a => a.id === formik.values?.taskData?.type,
            )
          ] || null
        }
        loadingText="Carregando"
        fullWidth
        renderInput={rest => (
          <TextField
            {...rest}
            id="taskData.type"
            label="Tipo de tarefa"
            margin="dense"
            name="taskData.type"
            variant="outlined"
            onKeyDown={e => {
              e.key === 'Enter' && e.preventDefault();
            }}
            InputProps={{
              ...rest.InputProps,
            }}
            error={
              formik.touched?.taskData?.type &&
              Boolean(formik?.errors.taskData?.type)
            }
            helperText={
              formik.touched?.taskData?.type && formik.errors?.taskData?.type
            }
          />
        )}
      />
      <TextField
        label="Nome da tarefa"
        name="taskData.name"
        margin="dense"
        variant="outlined"
        fullWidth
        multiline
        autoComplete="off"
        onChange={formik.handleChange}
        value={formik.values?.taskData?.name || ''}
        error={
          formik.touched.taskData?.name &&
          Boolean(formik.errors?.taskData?.name)
        }
        helperText={
          formik.touched?.taskData?.name && formik.errors?.taskData?.name
        }
      />
      <TextField
        label="Descrição da tarefa"
        name="taskData.description"
        margin="dense"
        variant="outlined"
        fullWidth
        multiline
        minRows={1}
        maxRows={3}
        autoComplete="off"
        onChange={formik.handleChange}
        value={formik.values?.taskData?.description || ''}
        error={
          formik.touched?.taskData?.description &&
          Boolean(formik.errors?.taskData?.description)
        }
        helperText={
          formik.touched.taskData?.description &&
          formik.errors.taskData?.description
        }
      />
      <TextField
        id="taskData.deadline"
        label="Prazo (horas)"
        variant="outlined"
        name="taskData.deadline"
        autoComplete="off"
        margin="dense"
        type="number"
        inputProps={{
          step: 1,
          min: 0,
        }}
        onChange={e =>
          formik.setFieldValue(
            'taskData.deadline',
            Number(e.target.value) > 0 ? Number(e.target.value) : 0,
          )
        }
        value={formik.values?.taskData?.deadline || 0}
        error={
          !!(
            formik.touched?.taskData?.deadline &&
            formik.errors?.taskData?.deadline
          )
        }
        helperText={
          formik.touched?.taskData?.deadline &&
          formik.errors?.taskData?.deadline
        }
      />
      {customFields && customFields.length > 0 ? (
        <LinkedCardFields
          isNotApprovalType={formik.values?.taskData?.type !== 'APPROVAL'}
          isEditingTask={false}
          cardFields={customFields as any}
          linkedCardFields={formik.values?.linkedCardFields || []}
          handleSetLinkedCardFields={handleSetLinkedCardFields}
        />
      ) : (
        <span className="italic p-2">
          Não há campos personalizados cadastrados nessa área
        </span>
      )}
    </Box>
  );
};
