export const zoomHandler = (
  event: React.WheelEvent<HTMLDivElement>,
  scale: number,
  setScale: (scale: number) => void,
  minScale: number = 0.1,
  maxScale: number = 2.0,
) => {
  event.preventDefault();
  const delta = event.deltaY > 0 ? -0.1 : 0.1;
  const newScale = Math.min(maxScale, Math.max(minScale, scale + delta));
  setScale(newScale);
};
