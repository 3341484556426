/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import MaskedInput from 'react-text-mask';

interface InputZipCodeMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const InputZipCodeMask = (props: InputZipCodeMaskProps) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      // placeholderChar={'\u2000'}
      showMask={false}
      keepCharPositions={false}
      guide={false}
    />
  );
};
export { InputZipCodeMask };
