import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { InputNumberMask } from 'src/components/InputNumberMask';

interface ITextInput {
  fieldId: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isMultiline?: boolean;
  minRows?: number | string;
  maxRows?: number | string;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  error?: boolean;
  helperText?: string;
  type?: string;
  valueProperty?: 'value' | 'valueJSON';
  handleOnBlur?: (
    field: string,
    newValue: string | null,
    valueProperty?: string,
  ) => void;
  handleOnBlurTable?: (
    columnCustomFieldId: string,
    tableRowIndex: number,
    value: string | string[] | null | object | object[],
    valueProperty: 'value' | 'valueJSON',
  ) => void;
  fullWidth?: boolean;
  tableRowIndex?: number;
}

export const TextInput = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  isDisabled = false,
  isRequired = false,
  margin = 'none',
  handleOnBlur = () => {},
  handleOnBlurTable = () => {},
  isMultiline = false,
  minRows,
  maxRows,
  error,
  helperText,
  type,
  valueProperty,
  fullWidth = true,
  tableRowIndex,
}: ITextInput): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const isTableColumn = tableRowIndex !== undefined;

  useEffect(() => {
    setInputValue(fieldValue);
  }, [fieldValue]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    if (isTableColumn) {
      handleOnBlurTable(fieldId, tableRowIndex, inputValue || '', 'value');
    } else {
      handleOnBlur(fieldId, inputValue || null, valueProperty);
    }
  };

  return (
    <TextField
      id={fieldId}
      name={fieldName}
      value={inputValue}
      label={fieldLabel && !isTableColumn ? fieldLabel : undefined}
      onChange={handleInputChange}
      onBlur={handleBlur}
      fullWidth={fullWidth}
      margin={margin}
      autoComplete="off"
      required={isRequired}
      disabled={isDisabled}
      multiline={isMultiline}
      minRows={isMultiline && minRows && !isTableColumn ? minRows : 1}
      maxRows={isMultiline && maxRows && !isTableColumn ? maxRows : 1}
      onKeyDown={e => {
        if (e.key === 'Enter' && !isMultiline) e.preventDefault();
      }}
      error={error}
      helperText={helperText}
      InputLabelProps={{
        shrink: isMultiline && !isTableColumn ? true : undefined,
      }}
      InputProps={{
        disableUnderline: isTableColumn ? true : undefined,
        inputProps:
          type === 'NUMERIC' && isTableColumn
            ? {
                style: {
                  textAlign: 'right',
                  minWidth: '5rem',
                },
              }
            : type === 'STRING' && isTableColumn
            ? {
                style: {
                  textAlign: 'left',
                  minWidth: '10rem',
                },
              }
            : undefined,
        inputComponent:
          type === 'NUMERIC'
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (InputNumberMask as any)
            : undefined,
      }}
    />
  );
};
