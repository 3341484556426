/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { IUserAll, userApi } from 'src/services/userApi';
import api from '../services/api';

interface HookReturn {
  users: IUserAll[];
  loadingUsers: boolean;
}

export function useUsersAll(): HookReturn {
  const [users, setUsers] = useState<IUserAll[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

  useEffect(() => {
    setLoadingUsers(true);
    userApi
      .getUsersAll()
      .then((response: { data: IUserAll[] }) => {
        setUsers(response.data);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Falha ao buscar lista de usuários. ${errMessage || ''}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  }, []);

  return {
    users,
    loadingUsers,
  } as HookReturn;
}
