import { StyleRules } from '@material-ui/core';

export const booleanStyles = (): StyleRules => ({
  checkBox: {
    alignItems: 'start',
  },
  boolField: {
    display: 'flex',
    flexDirection: 'column',
  },
  boolFieldTitle: {
    display: 'block',
  },
  boolFieldOptions: {
    display: 'flex',
  },
  boolFieldOption: {
    display: 'flex',
    alignItems: 'center',
  },

  // table
  tableBooleanField: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
  },
});
