/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

import NumberFormat from 'react-number-format';

const useStyles = makeStyles(() =>
  createStyles({
    input: { marginTop: 0 },
  }),
);
interface NumberFormatCustomProps {
  inputRef: React.Ref<NumberFormat<number>>;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InputPercentageMask = (props: NumberFormatCustomProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      suffix="%"
      decimalScale={2}
      maxLength={7}
      min={0}
      max={100}
      fixedDecimalScale
      // isNumericString
      inputMode="decimal"
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
};
export { InputPercentageMask };
