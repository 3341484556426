/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DigitalSignatureIcon(props: any): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 500 512.22"
    >
      <path
        fill="currentColor"
        d="m414.86 206.15 48.95 47.13-74.58 78.33-59.92 16.07c-2.15.42-3-.44-2.65-2.46l13.58-60.74 74.62-78.33zM295.7 347.57c7.32-.2 13.44 5.59 13.64 12.91.2 7.32-5.59 13.43-12.91 13.63-13.43.37-22.78 7.36-26.7 15.62-1.59 3.35-2.26 6.89-1.9 10.12.31 2.74 1.45 5.31 3.5 7.34 5.93 5.9 18.8 8.48 40.55 3.21 3.44-.84 10.38-3.16 19.08-6.07 41.29-13.81 117.15-39.18 128.97-3.93 2.31 6.94-1.43 14.48-8.38 16.8-6.94 2.32-14.48-1.43-16.79-8.37-3.38-10.09-62.95 9.83-95.38 20.67-9.29 3.11-16.71 5.6-21.26 6.7-32.22 7.81-53.66 1.63-65.52-10.18-6.58-6.55-10.24-14.68-11.2-23.26-.92-8.09.59-16.57 4.29-24.36 7.77-16.38 25.36-30.15 50.01-30.83zM103.57 225.85c-7.07 0-12.8-5.73-12.8-12.8 0-7.06 5.73-12.79 12.8-12.79h161.17c7.07 0 12.8 5.73 12.8 12.79 0 7.07-5.73 12.8-12.8 12.8H103.57zm0 82.69c-7.07 0-12.8-5.72-12.8-12.79 0-7.07 5.73-12.8 12.8-12.8h147.39c7.07 0 12.79 5.73 12.79 12.8s-5.72 12.79-12.79 12.79H103.57zm0 82.7c-7.07 0-12.8-5.73-12.8-12.8 0-7.06 5.73-12.79 12.8-12.79h87.51c7.06 0 12.79 5.73 12.79 12.79 0 7.07-5.73 12.8-12.79 12.8h-87.51zM246.01 36.73v43.24c1 13.08 5.56 23.36 13.56 30.2 8.31 7.09 20.71 11.07 37.13 11.36l37.27-.04-87.96-84.76zm96.71 110.34-46.22-.05c-22.76-.36-40.67-6.48-53.52-17.45-13.38-11.44-20.92-27.68-22.45-47.78l-.11-1.76V25.59H63.61c-20.94 0-38.02 17.08-38.02 38.02V448.6c0 20.85 17.16 38.02 38.02 38.02h241.11c15.7 0 30.03-9.98 35.58-24.65 2.47-6.59 9.85-9.92 16.44-7.45 6.59 2.48 9.92 9.85 7.44 16.44-9.32 24.59-33.11 41.26-59.46 41.26H63.61C28.69 512.22 0 483.51 0 448.6V63.61C0 28.67 28.67 0 63.61 0h175.94c3.79 0 7.21 1.65 9.54 4.28l115.27 111.06c2.6 2.5 3.91 5.85 3.91 9.2l.04 74c0 7.07-5.73 12.8-12.79 12.8-7.07 0-12.8-5.73-12.8-12.8v-51.47zm120.87 24.5c-2.27-2.18-4.92-3.2-7.96-3.16-3.03.05-5.62 1.24-7.77 3.48l-17.46 18.18 49 47.3 17.64-18.36c2.11-2.13 2.99-4.9 2.96-7.95-.05-3-1.13-5.72-3.26-7.78l-33.15-31.71zm-89.91 157.2-36.75 9.85c-1.33.26-1.85-.26-1.62-1.5l8.32-37.26 30.05 28.91z"
      />
    </SvgIcon>
  );
}
