import React from 'react';

import { Paper, Box, TextField, Divider } from '@material-ui/core/';
import { Autocomplete } from '@material-ui/lab';
import { CustomRenderGreenTags } from './CustomRenderGreenTags';

interface IAccount {
  id: string;
  name: string;
}

interface IContact {
  id: string;
  name: string;
  account?: {
    id: string;
  };
}

interface IUser {
  id: string;
  name: string;
}

interface IPaperProps {
  paperProps: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>;
  type: 'IAccount' | 'IContact' | 'IUser';
  optionsValues: IAccount[] | IContact[] | IUser[];
  selectedValues: IAccount[] | IContact[] | IUser[];
  handleChange: (
    value: IAccount[] | IContact[] | IUser[],
    type: 'IAccount' | 'IContact' | 'IUser',
  ) => void;
}

export function CustomPaperComponent({
  paperProps,
  type,
  optionsValues,
  selectedValues,
  handleChange,
}: IPaperProps): JSX.Element {
  return (
    <>
      <Paper square={false} color="#fafafa" elevation={3} {...paperProps}>
        {selectedValues?.length > 0 && (
          <Box
            {...paperProps}
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            width="100%"
            margin={0}
            paddingLeft={1}
            paddingRight={1}
          >
            <Autocomplete
              multiple
              fullWidth
              value={selectedValues || ''}
              options={optionsValues}
              size="small"
              disableCloseOnSelect
              popupIcon=""
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name || ''}
              onChange={(e, value) => {
                handleChange(value, type);
              }}
              renderTags={(tagValue, getTagProps) => {
                return (
                  <CustomRenderGreenTags
                    tagValue={tagValue}
                    getTagProps={getTagProps}
                  />
                );
              }}
              renderInput={rest => (
                <TextField
                  {...rest}
                  id="area"
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                  InputProps={{
                    ...rest.InputProps,
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              )}
            />
            <Divider />
          </Box>
        )}
        <Box {...paperProps} width="100%" marginTop={0}>
          {paperProps.children}
        </Box>
      </Paper>
    </>
  );
}
