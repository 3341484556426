/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { Box, Typography, TextField } from '@material-ui/core';
import { Edit, Delete, ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  // Button as MaterialButton,
  TableContainer,
  Container,
  TablePagination,
  Button as ButtonMaterialUI,
  TableFooter,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal_cards';
import TablePaginationActions from '../../../components/TablePaginationActions';
import AccountFieldConfiguration from './AccountFieldConfiguration';
import { IAccountField, typeAccountFields } from './AccountConfiguration.i';
import {
  AccountFixedFields,
  useAccountStyles,
} from './accountConfigurationHelpers';

const AccountConfiguration: React.FC = () => {
  const classes = useAccountStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [accountFields, setAccountFields] = useState<IAccountField[]>([]);
  const [firstOrder, setFirstOrder] = useState<number>(0);
  const [lastOrder, setLastOrder] = useState<number>(0);
  const [accountFieldsFiltered, setAccountFieldsFiltered] = useState<
    IAccountField[]
  >([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 20,
  );
  const [findName, setFindName] = useState('');

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, accountFields.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    handleRefreshList();
  }, []);

  useEffect(() => {
    setAccountFieldsFiltered(accountFields);
  }, [accountFields]);

  useEffect(() => {
    const accountsF = accountFields.filter(
      account =>
        account.name
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );

    setPage(0);
    setAccountFieldsFiltered(accountsF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findName]);

  const handleEdit = useCallback(
    (accountField_id: string) => {
      history.push(`/accountFields/${accountField_id}`);
    },
    [history],
  );

  const handleRefreshList = useCallback(() => {
    api.get('/accountFields').then(response => {
      const orderedFields = response.data.sort(
        (a: any, b: any) => a.order - b.order,
      );

      if (orderedFields.length > 0) {
        setFirstOrder(orderedFields[0].order);
        setLastOrder(orderedFields[orderedFields.length - 1].order);
      }

      const combinedFields = [...AccountFixedFields, ...orderedFields];
      setAccountFields(combinedFields);
    });
  }, []);

  const handleDelete = useCallback((accountField_id: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/accountFields/${accountField_id}`);
          handleRefreshList();
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  }, []);

  const handleAddAccount = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    handleRefreshList();
  };

  const handleChangeOrder = (fieldId: string, changeTo: 'up' | 'down') => {
    api
      .patch(`/accountFields/${fieldId}/${changeTo}`)
      .then(response => {
        const orderedFields = response.data.sort(
          (a: any, b: any) => a.order - b.order,
        );
        setFirstOrder(orderedFields[0].order);
        setLastOrder(orderedFields[orderedFields.length - 1].order);
        const combinedFields = [...AccountFixedFields, ...orderedFields];
        setAccountFields(combinedFields);
      })
      .catch(error => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      });
  };

  const goBack = () => {
    setOpenModal(false);
    history.goBack();
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.headerTitle}
            >
              Campos de Conta
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAccount}
                className={classes.button}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            style={{ marginTop: 15 }}
          >
            Utilize está pagina para criar e editar campos personalizados para
            as contas.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginBottom: '20px',
            }}
          >
            <ButtonMaterialUI
              className={classes.buttonSubmit}
              size="large"
              variant="contained"
              color="inherit"
              style={{ maxWidth: '100px', marginTop: '15px' }}
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <TextField
              label="Pesquisar"
              name="find"
              margin="dense"
              variant="outlined"
              fullWidth
              value={findName}
              style={{ maxWidth: '300px' }}
              onChange={value => {
                setFindName(value.target.value);
              }}
            />
          </div>
        </Box>
        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Campos de Contas">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions}>
                    Padrão
                  </TableCell>
                  <TableCell className={classes.columnActions} />
                  <TableCell className={classes.columnActions} />
                  <TableCell className={classes.columnActions} />
                  <TableCell>Campo</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Apelido</TableCell>
                  <TableCell className={classes.columnActions}>Ativo</TableCell>
                  <TableCell className={classes.columnActions}>
                    Obrigatório
                  </TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? accountFieldsFiltered.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : accountFieldsFiltered
                ).map(accountField => (
                  <TableRow
                    key={accountField.id}
                    className={
                      accountField.id.includes('fixed') ? classes.fixedRow : ''
                    }
                  >
                    <TableCell component="th" scope="row">
                      {accountField.id.includes('fixed') ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell
                      className={classes.columnActions}
                      scope="row"
                      size="small"
                    >
                      {!accountField.id.includes('fixed') && (
                        <IconButton
                          className={classes.smallIcon}
                          aria-label="Aumentar ordem"
                          size="small"
                          onClick={() =>
                            handleChangeOrder(accountField.id, 'up')
                          }
                          disabled={accountField.order === firstOrder}
                          hidden={accountField.id.includes('fixed')}
                        >
                          <ArrowUpward />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.columnActions}
                      size="small"
                      scope="row"
                    >
                      {!accountField.id.includes('fixed') && (
                        <IconButton
                          className={classes.smallIcon}
                          aria-label="Diminuir ordem"
                          size="small"
                          onClick={() =>
                            handleChangeOrder(accountField.id, 'down')
                          }
                          disabled={accountField.order === lastOrder}
                        >
                          <ArrowDownward />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {!accountField.id.includes('fixed') && (
                        <IconButton
                          className={classes.smallIcon}
                          aria-label="Editar"
                          onClick={() => handleEdit(accountField.id)}
                          disabled={accountField.id.includes('fixed')}
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {accountField.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {typeAccountFields.find(
                        type => type.id === accountField.type,
                      )?.label || accountField.type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {accountField.slug}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {accountField.isInactive ? 'Não' : 'Sim'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {accountField.isRequired ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {!accountField.id.includes('fixed') && (
                        <IconButton
                          className={classes.smallIcon}
                          aria-label="Excluir"
                          size="small"
                          onClick={() => handleDelete(accountField.id)}
                          disabled={accountField.id.includes('fixed')}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 10]}
                    colSpan={10}
                    count={accountFieldsFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': '' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar Campo de Conta"
          titleLeft=""
        >
          <AccountFieldConfiguration closeModal={handleCloseModal} />
        </Modal>
      )}
    </Container>
  );
};

export default AccountConfiguration;
