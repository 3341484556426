import { StyleRules, Theme } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const styles = (theme: Theme): StyleRules => ({
  content: { maxWidth: '1200px' },
  boxTable: {
    maxWidth: '1050px',
  },
  columnActions: {
    width: '60px',
  },
  titulo: {
    color: 'black',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
    marginBottom: '30px',
  },
  headerTitle: { fontSize: '30px', fontWeight: 'bold' },
  btnLoading: {
    color: '#fff !important',
  },
  btnIcon: {
    marginRight: '10px',
  },
  swalAlert: {
    zIndex: 99999,
  },
});
