import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../styles/colors';

export const useAccountStyles = makeStyles({
  content: { maxWidth: '950px' },
  boxTable: {
    maxWidth: '1000px',
  },
  smallIcon: {
    padding: 0,
    margin: 0,
  },
  columnActions: {
    width: '10px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    marginTop: '30px',
  },
  headerTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginRight: '30px',
    marginBottom: 0,
  },
  btnIcon: {
    marginRight: '10px',
  },
  fixedRow: {
    backgroundColor: '#f2f2f2',
  },
  buttonSubmit: {
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
  },
  importPopover: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    width: '135px',
  },
  button: {
    height: '35px',
    width: '90px',
    fontSize: '14px',
    background: 'linear-gradient(to left, #fafafa, #fafafa)',
    color: COLORS.BLUE,
    margin: 0,
    marginLeft: 15,
  },
});

export const AccountFixedFields = [
  {
    id: 'fixed-1',
    name: 'Nome',
    type: 'Alfanumérico',
    slug: 'NOME_CONTA',
    isRequired: true,
  },
  {
    id: 'fixed-2',
    name: 'CPF/CNPJ',
    type: 'Numérico',
    slug: 'CPF_CNPJ_CONTA',
    isRequired: true,
  },
  {
    id: 'fixed-3',
    name: 'Razão Social',
    type: 'Alfanumérico',
    slug: 'RAZAO_SOCIAL',
  },
  {
    id: 'fixed-4',
    name: 'Telefone',
    type: 'Numérico',
    slug: 'TELEFONE',
  },
  {
    id: 'fixed-5',
    name: 'CEP',
    type: 'Numérico',
    slug: 'CEP',
  },
  {
    id: 'fixed-6',
    name: 'Código IBGE',
    type: 'Alfanumérico',
    slug: 'CODIGO_IBGE',
  },
  {
    id: 'fixed-7',
    name: 'Rua',
    type: 'Alfanumérico',
    slug: 'RUA',
  },
  {
    id: 'fixed-8',
    name: 'Bairro',
    type: 'Alfanumérico',
    slug: 'BAIRRO',
  },
  {
    id: 'fixed-9',
    name: 'Estado',
    type: 'Alfanumérico',
    slug: 'ESTADO',
  },
  {
    id: 'fixed-10',
    name: 'Número',
    type: 'Numérico',
    slug: 'NUMERO_ENDERECO',
  },
  {
    id: 'fixed-11',
    name: 'Complemento',
    type: 'Alfanumérico',
    slug: 'COMPLEMENTO',
  },
];
