/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  CircularProgress,
  Container,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { HelpCircleIcon } from 'lucide-react';
import { LightTooltip } from 'src/components/Tooltip/LightToolTip';
import api from '../../services/api';
import Button from '../../components/Button';

import {
  IFormData,
  IBusiness,
  IDefaultUser,
  IArea,
  IAreaParams,
  IAreaProps,
} from './Area.i';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Area: React.FC<IAreaProps> = ({ closeModal = (value: any) => null }) => {
  const classes = useStyles();

  const history = useHistory();

  const params = useParams<IAreaParams>();
  const { area_id } = params;

  const goBack = () => {
    if (!area_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  const [area, setArea] = useState<IArea>();
  const [openBusiness] = useState(false);
  const [openUser] = useState(false);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [users, setUsers] = useState<IDefaultUser[]>([]);

  const loadingBusiness = openBusiness && businesses.length === 0;
  const loadingUserDefault = openUser && users.length === 0;

  useEffect(() => {
    api.get('/businesses').then(response => setBusinesses(response.data));
  }, []);

  useEffect(() => {
    api.get(`/users/admins`).then(response => {
      setUsers(response.data);
    });
  }, []);

  useEffect(() => {
    if (area_id) {
      api.get(`/areas/${area_id}`).then(response => {
        setArea(response.data);
      });
      api.get(`/areas/${area_id}/users`).then(response => {
        setUsers(response.data);
      });
    }
  }, [area_id]);

  const initialValues: IFormData = {
    name: area ? area.name : '',
    description: area ? area.description : '',
    business_id: area ? area.business.name : '',
    defaultUser_id: area ? area.defaultUser.id : '',
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Nome é Obrigatório')
      .min(2, 'O nome deve ter pelo menos 2 caracteres'),
    description: Yup.string().required('Descrição é Obrigatório'),
    business_id: area_id
      ? Yup.string()
      : Yup.string().uuid().required('Unidade de negócio é Obrigatório'),
    defaultUser_id: Yup.string().required('Usuário padrão é Obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (area_id) {
          await api.put(`/areas/${area_id}`, {
            name: values.name,
            description: values.description,
            defaultUser_id: values.defaultUser_id,
          });
          toast.success('Área editada com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } else {
          await api.post('/areas', {
            name: values.name,
            description: values.description,
            business_id: values.business_id,
            defaultUser_id: values.defaultUser_id,
          });
          toast.success('Área salva com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }

        setTimeout(() => {
          closeModal(true);
        }, 2000);
      } catch (error: any) {
        setSubmitting(false);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  const DescriptionLabel = (
    <>
      Descrição
      <LightTooltip
        placement="right-start"
        title={
          <>
            <p>
              Descrever a finalidade do processo, agentes envolvidos e etapas
              que serão consideradas no resultado do processo.
            </p>
            <br />
            <p className="italic font-normal">
              <strong>Exemplo:</strong> Esse processo é um pipeline de vendas,
              que organiza as etapas do processo de vendas, desde a prospecção
              de clientes até o fechamento do negócio. Ele serve para otimizar a
              gestão de oportunidades, proporcionando visibilidade e eficiência
              para a equipe de vendas, além de permitir a previsão de receitas e
              a identificação de gargalos no processo.
            </p>
            <br />
            <p className="italic font-normal">
              Composto por etapas como prospecção, qualificação, apresentação,
              proposta, negociação, fechamento e pós-venda, o pipeline envolve
              agentes como a equipe de vendas, gestores, marketing e os próprios
              clientes. Esse processo é essencial para maximizar a conversão de
              leads em clientes e garantir o sucesso comercial da empresa.
            </p>
          </>
        }
      >
        <IconButton size="small" style={{ marginLeft: 4 }}>
          <HelpCircleIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    </>
  );

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {area_id ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar Área
          </Typography>
        ) : (
          <div />
        )}

        <Box style={{ marginTop: '25px' }}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              id="name"
              label="Nome"
              name="name"
              margin="normal"
              autoFocus
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="description"
              label={DescriptionLabel}
              name="description"
              margin="normal"
              fullWidth
              multiline
              minRows={4}
              maxRows={4}
              onChange={formik.handleChange}
              value={formik.values.description}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />

            {!area_id ? (
              <Autocomplete
                id="business_id"
                getOptionSelected={(business, value) =>
                  business.id === value.id
                }
                getOptionLabel={business => business.name}
                options={businesses}
                onChange={(e, value) => {
                  formik.setFieldValue('business_id', value ? value.id : '');
                }}
                loading={loadingBusiness}
                loadingText="Carregando"
                renderInput={props => (
                  <TextField
                    {...props}
                    label="Unidade de negócio"
                    margin="normal"
                    error={
                      formik.touched.business_id &&
                      Boolean(formik.errors.business_id)
                    }
                    helperText={
                      formik.touched.business_id && formik.errors.business_id
                    }
                    InputProps={{
                      ...props.InputProps,
                      endAdornment: (
                        <>
                          {loadingBusiness ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {props.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <TextField
                id="business"
                label="Unidade de Negócio"
                name="business"
                margin="normal"
                fullWidth
                disabled
                value={formik.values.business_id}
              />
            )}

            {users.length > 0 && (
              <Autocomplete
                id="defaultUser_id"
                getOptionSelected={(user, value) => user.id === value.id}
                getOptionLabel={user => user.name}
                options={users}
                onChange={(e, value) => {
                  formik.setFieldValue('defaultUser_id', value ? value.id : '');
                }}
                value={
                  users[
                    users.findIndex(
                      user => user.id === formik.values.defaultUser_id,
                    )
                  ] || null
                }
                loading={loadingUserDefault}
                loadingText="Carregando"
                renderInput={props => (
                  <TextField
                    {...props}
                    label="Usuário padrão"
                    margin="normal"
                    error={
                      formik.touched.defaultUser_id &&
                      Boolean(formik.errors.defaultUser_id)
                    }
                    helperText={
                      formik.touched.defaultUser_id &&
                      formik.errors.defaultUser_id
                    }
                    InputProps={{
                      ...props.InputProps,
                      endAdornment: (
                        <>
                          {loadingUserDefault ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {props.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}

            <ButtonMaterialUI
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </form>
        </Box>
        {/*  <Divider className={classes.divider} />

        {area_id && <ListPhases area_id={area_id} />} */}
      </Box>
    </Container>
  );
};

export default Area;
