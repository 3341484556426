/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Box,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IGetCardFieldApi, cardFieldApi } from 'src/services/cardFieldApi';
import { Button } from 'src/components/ui/button';
import { TLinkedCardFields } from 'src/interface/ILinkedCardFields';
import { LinkedCardFields } from 'src/components/LinkedCardFields';
import api from '../../services/api';
import { InputNumberMask } from '../../components/InputNumberMask';
import { IFormData, ITask, ITaskProps, ITaskParams } from './Task.i';
import { styles } from './styles';
import { TaskTypes } from '../../utils/taskTypes.constants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const Task: React.FC<ITaskProps> = ({
  closeModal = (value: any) => null,
  area,
  phase_id_prop,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const params = useParams<ITaskParams>();
  const taskTypeOptions = Object.values(TaskTypes).sort(
    (a: { name: string }, b: { name: string }) => -b.name.localeCompare(a.name),
  );
  const area_id: string | undefined =
    (history.location.state as any) || area?.id;
  let { phase_id } = params;
  if (phase_id_prop) {
    phase_id = phase_id_prop;
  }
  const { task_id } = params;
  const [phase, setPhase] = useState<string>(phase_id);
  const [task, setTask] = useState<ITask>();
  const [cardFields, setCardFields] = useState<IGetCardFieldApi[]>([]);

  useEffect(() => {
    if (task_id)
      api
        .get(`/cardTasks/${task_id}`)
        .then(response => {
          setTask(response.data);
        })
        .catch(err => {
          toast.error(
            `Falha ao buscar dados da tarefa. ${err.response.data.message}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
        });
  }, [task_id]);

  useEffect(() => {
    const customFields = async (areaId: string) => {
      try {
        const response = await cardFieldApi.getCardFieldsByArea(areaId);
        const filteredFields: IGetCardFieldApi[] = response.data.filter(
          (cf: IGetCardFieldApi) => cf.phaseOrder >= 0,
        );
        setCardFields(filteredFields);
      } catch (err: any) {
        toast.error(`Falha ao buscar campos personalizados${err?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        setCardFields([]);
      }
    };
    if (area_id) {
      customFields(area_id);
    }
  }, [area_id]);

  const goBack = () => {
    if (!task_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  const initialValues: IFormData = {
    name: task ? task.name : '',
    description: task ? task.description : '',
    type: task ? task?.type : '',
    deadline: task ? task.deadline : 1,
    isInactive: task ? task.isInactive : false,
    isRequired: task ? task.isRequired : false,
    weight: task ? task.weight : 1,
    instructions: task && task.instructions !== null ? task.instructions : '',
    linkedCardTaskFields:
      task && task?.linkedCardTaskFields ? task.linkedCardTaskFields : [],
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Nome é Obrigatório')
      .min(2, 'O nome deve ter pelo menos 2 caracteres'),
    type: Yup.string().required('Tipo é Obrigatório'),
    description: Yup.string().required('Descrição é Obrigatório'),
    deadline: Yup.number()
      .moreThan(0, 'Prazo deve ser maior que zero.')
      .required('Tempo para realizar a tarefa é Obrigatório'),
    instructions: Yup.string().nullable().optional(),
    weight: Yup.number().required('Peso deve ser um número.'),
    linkedCardTaskFields: Yup.array().nullable().optional(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      const fieldValues = values.linkedCardTaskFields;
      const fieldValuesLength = fieldValues.length;
      const hasIsCriteriaField = fieldValues.find(fv => fv.isApprovalCriterion);
      const numberOfCriterionField = fieldValues.filter(
        fv => fv.isApprovalCriterion,
      );
      if (
        values.type === 'APPROVAL' &&
        (fieldValuesLength === 0 ||
          (fieldValuesLength > 0 && !hasIsCriteriaField) ||
          (fieldValuesLength > 0 && numberOfCriterionField.length > 1))
      ) {
        const message =
          numberOfCriterionField.length > 1
            ? 'Apenas um campo deve ser marcado como "Critério de Aprovação"'
            : 'Tarefa do tipo "Aprovação" precisa contar ao menos um campo como "Critério de Aprovação"';
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        return;
      }

      try {
        setSubmitting(true);
        if (!task_id) {
          await api.post('/cardTasks', {
            name: values.name,
            type: values.type,
            description: values.description,
            deadline: values.deadline,
            isInactive: values.isInactive,
            isRequired: values.isRequired,
            weight: values.weight,
            phase_id: phase,
            instructions: values.instructions || '',
            linkedCardFields: values.linkedCardTaskFields,
          });

          toast.success('Tarefa salva com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setTimeout(() => {
            closeModal(true);
          }, 2000);
        } else {
          await api.put(`/cardTasks/${task_id}`, {
            name: values.name,
            description: values.description,
            deadline: values.deadline,
            isInactive: values.isInactive,
            isRequired: values.isRequired,
            weight: values.weight,
            phase_id: phase,
            instructions: values.instructions || '',
            linkedCardFields: values.linkedCardTaskFields,
          });

          toast.success('Tarefa editada com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSetLinkedCardFields = (linkedCardFields: TLinkedCardFields) => {
    formik.setFieldValue('linkedCardTaskFields', linkedCardFields);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: task_id ? 35 : undefined,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {task_id ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar tarefa
          </Typography>
        ) : (
          <div />
        )}

        {phase ? (
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" alignItems="start">
              {area && (
                <div style={{ width: '100%' }}>
                  <Autocomplete
                    fullWidth
                    options={area.phases}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => {
                      if (value) setPhase(value.id);
                    }}
                    value={area.phases.find(p => p.id === phase)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Fase"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                </div>
              )}
              <TextField
                id="name"
                label="Nome"
                name="name"
                margin="normal"
                autoFocus
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Autocomplete
                fullWidth
                disabled={!!task?.id}
                options={taskTypeOptions}
                getOptionLabel={option => option.name}
                onChange={(_, value) => {
                  formik.setFieldValue('type', value?.id ?? '');
                }}
                value={
                  taskTypeOptions.find(
                    option => option.id === formik.values.type,
                  ) || null
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    name="type"
                    label="Tipo"
                    margin="normal"
                    fullWidth
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                )}
              />
              <TextField
                id="description"
                label="Descrição"
                name="description"
                margin="normal"
                fullWidth
                multiline
                minRows={1}
                maxRows={4}
                onChange={formik.handleChange}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <TextField
                id="deadline"
                label="Tempo para realizar a tarefa (horas)"
                name="deadline"
                margin="normal"
                onChange={formik.handleChange}
                value={formik.values.deadline || ''}
                error={
                  formik.touched.deadline && Boolean(formik.errors.deadline)
                }
                helperText={formik.touched.deadline && formik.errors.deadline}
                InputProps={{
                  inputComponent: InputNumberMask as any,
                }}
                fullWidth
              />
              <TextField
                id="weight"
                label="Peso"
                name="weight"
                margin="normal"
                onChange={formik.handleChange}
                value={formik.values.weight}
                error={formik.touched.weight && Boolean(formik.errors.weight)}
                helperText={formik.touched.weight && formik.errors.weight}
                InputProps={{
                  inputComponent: InputNumberMask as any,
                }}
                fullWidth
              />
              <TextField
                id="instructions"
                label="Instruções"
                name="instructions"
                type="text"
                margin="dense"
                autoComplete="off"
                // autoFocus
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
                onChange={formik.handleChange}
                value={formik.values.instructions}
                error={
                  formik.touched.instructions &&
                  Boolean(formik.errors.instructions)
                }
                helperText={
                  formik.touched.instructions && formik.errors.instructions
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isRequired"
                    onChange={formik.handleChange}
                    checked={formik.values.isRequired}
                  />
                }
                label="Obrigatório?"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isInactive"
                    onChange={formik.handleChange}
                    checked={formik.values.isInactive}
                  />
                }
                label="Inativar"
              />
              {formik.values.type !== '' && cardFields?.length >= 0 && (
                <LinkedCardFields
                  isNotApprovalType={formik.values.type !== 'APPROVAL'}
                  isEditingTask={!!task_id}
                  cardFields={cardFields}
                  linkedCardFields={formik.values.linkedCardTaskFields}
                  handleSetLinkedCardFields={handleSetLinkedCardFields}
                />
              )}
              <div className="flex flex-row self-end items-center gap-2 my-4 mr-2">
                <Button
                  type="reset"
                  onClick={goBack}
                  className="w-fit bg-zinc-400 hover:bg-opacity-90 hover:bg-zinc-400"
                  size="lg"
                  disabled={formik.isSubmitting}
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  className="w-fit"
                  size="lg"
                  disabled={formik.isSubmitting}
                >
                  Salvar
                </Button>
              </div>
            </Box>
          </form>
        ) : (
          <>
            {area && (
              <>
                Selecione a fase onde a tarefa será adicionada:
                <Autocomplete
                  options={area.phases}
                  getOptionLabel={option => option.name}
                  onChange={(e, value) => {
                    if (value) setPhase(value.id);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Fase"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </>
            )}
            {!area && (
              <Typography variant="h4" component="h5">
                Selecione uma área
              </Typography>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default Task;
