import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Button as ButtonMaterialUI, ButtonProps } from '@material-ui/core/';

const MyButton = styled(ButtonMaterialUI)({
  background: 'linear-gradient(to right, #007dc6, #2f98fa)',
  border: 0,
  borderRadius: 3,
  // boxShadow: '0 3px 5px 2px #8c9ea0',
  marginTop: '10px',
  color: 'white',
  height: 42,
  padding: '0 30px',
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Button({ children, ...rest }: ButtonProps) {
  return (
    <MyButton {...rest} size="large" variant="contained" color="primary">
      {children}
    </MyButton>
  );
}
