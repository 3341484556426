/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React from 'react';

import { useFormik } from 'formik';
import {
  Container,
  createStyles,
  makeStyles,
  Typography,
  Theme,
} from '@material-ui/core';

import { toast } from 'react-toastify';
import api from '../../../../services/api';
import Button from '../../../../components/Button';
import { useTrigger } from '../../../../context/TriggerContext';

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IDispatchTypeProps {
  cardId?: string;
  selectedType: IDispatchType | null;
  onClose: () => void;
  onDispatchCard: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30,
    },
    input: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(2),
      margin: theme.spacing(1),
      background: '#2E8A16',
      '&:hover': { background: '#456733' },
    },
    submit: {
      marginTop: theme.spacing(2),
      margin: theme.spacing(1),
      background: '#434544',
      '&:hover': { background: '#4F4F4F' },
    },
    titulo: {
      alignItems: 'center',
      fontSize: '1.0rem',
      fontWeight: 'bolder',
      color: 'red',
    },
    titulo2: {
      alignItems: 'center',
      fontSize: '.80rem',
      fontWeight: 400,
      color: '#676767',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
  }),
);

const DispatchTypeCardFinish: React.FC<IDispatchTypeProps> = ({
  cardId,
  selectedType,
  onClose,
  onDispatchCard,
}) => {
  const { updateTriggers } = useTrigger();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (value, { setSubmitting }) => {
      const finishCard = toast.loading(`Finalizando o card.`);
      document.body.style.cursor = 'progress';
      try {
        setSubmitting(true);
        await api
          .post(`/cards/${cardId}/dispatchTypes/${selectedType?.id}`)
          .then(response => {
            const card = response?.data?.card;
            const triggers = response?.data?.triggers;
            const id = card?.id;
            const key_card = card?.key_card;
            const accountName = card?.account.name;
            const triggerDateTime = new Date();

            if (
              id &&
              key_card &&
              triggers &&
              Object.keys(triggers).length > 0
            ) {
              updateTriggers({
                card_id: id,
                key_card,
                triggers,
                accountName,
                triggerDateTime,
              });
            }

            toast.update(finishCard, {
              render: `Card finalizado com sucesso.`,
              type: 'success',
              isLoading: false,
              autoClose: 2000,
              theme: 'colored',
              position: toast.POSITION.TOP_RIGHT,
            });
            document.body.style.cursor = 'auto';

            onDispatchCard();
            onClose();
          });
      } catch (error: any) {
        setSubmitting(false);
        document.body.style.cursor = 'auto';
        toast.update(finishCard, {
          render: `Ocorreu um erro ao finalizar o card. ${error.response.data.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  return (
    <Container className={classes.container}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Typography className={classes.titulo}>
          Deseja realmente Finalizar ?
        </Typography>
        <Typography className={classes.titulo2}>
          Depois de efetuada a finalização do Card, não poderá mais ser
          revertida a operação.
        </Typography>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="default"
            type="button"
            className={classes.submit}
            onClick={onClose}
          >
            voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Finalizar
          </Button>
        </div>
      </form>
    </Container>
  );
};

export { DispatchTypeCardFinish };
