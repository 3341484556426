import { StyleRules } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (): StyleRules => ({
  goBackButton: {
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
    fontSize: '12px',
    marginRight: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  addButton: {
    fontSize: '12px',
    color: COLORS.WHITE,
    background: `linear-gradient(to right, ${COLORS.BLUE}, #2f98fa)`,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
});
