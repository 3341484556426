import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'src/components/ui/hover-card';
import { InfoIcon } from 'lucide-react';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { FunnelChart } from 'react-funnel-pipeline';
import { ResponsiveFunnel } from '@nivo/funnel';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { FunnelFiltersButton } from '../components/funnelFilters';
import { FunnelData } from '../types';
import 'react-funnel-pipeline/dist/index.css';

export interface FunnelWidgetData {
  readonly data: FunnelData | undefined;
}

const listOf10Blue = [
  '#cde3f7',
  '#b9d6f2',
  '#a4c8ed',
  '#90bbe8',
  '#7baee3',
  '#5b97d8',
  '#4b7ebd',
  '#3b659e',
  '#2a4c7f',
  '#1a335f',
];

export interface FunnelWidgetProps extends FunnelWidgetData {
  readonly userOptions: { id: string; name: string }[];
}

export const CutAndAddEllipsis = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

const FunnelWidget: React.FC<FunnelWidgetProps> = ({ data, userOptions }) => {
  const { graphType } = useWorkspaceFilters();
  const [funnel, setFunnel] = useState<{
    data: FunnelData | undefined;
    wasCleared: boolean;
  }>({
    data,
    wasCleared: true,
  });

  useEffect(() => {
    if (!funnel.wasCleared || data === undefined) {
      setFunnel({
        data: undefined,
        wasCleared: true,
      });
    } else {
      setFunnel({
        data,
        wasCleared: false,
      });
    }
  }, [data]);

  useEffect(() => {
    if (
      (funnel?.data === undefined && data !== undefined) ||
      (funnel?.data !== undefined && data === undefined)
    ) {
      setFunnel({
        data,
        wasCleared: false,
      });
    }
  }, [funnel?.data, data]);

  const totalCards = funnel?.data?.total || 0;

  return (
    <div className="h-full w-full">
      <div className="flex flex-row justify-between px-3 pb-3 items-center max-h-[20%]">
        <div className="flex flex-row justify-between items-center gap-3">
          <p className="text-lg font-bold">Cards por fase</p>
          <HoverCard openDelay={500}>
            <HoverCardTrigger>
              <InfoIcon size={20} className="cursor-help" />
            </HoverCardTrigger>
            <HoverCardContent>
              {funnel !== undefined &&
              funnel?.data !== undefined &&
              funnel?.data.phaseCounts.length > 0 ? (
                <>
                  <p>Total de cards: {totalCards > 0 ? totalCards : '-'} </p>
                  <span>
                    Convertidos para a fase{' '}
                    {funnel?.data.phaseCounts[
                      funnel?.data.phaseCounts.length - 1
                    ]?.phase?.name || ''}
                    :
                  </span>
                  <span>
                    {totalCards === 0
                      ? '-'
                      : (
                          (funnel?.data.phaseCounts[
                            funnel?.data.phaseCounts.length - 1
                          ].cardCount /
                            funnel?.data.total) *
                          100
                        ).toFixed(2)}
                    %
                  </span>
                </>
              ) : (
                <p>Sem dados</p>
              )}
            </HoverCardContent>
          </HoverCard>
        </div>
        <div className="flex flex-row gap-2">
          <FunnelFiltersButton users={userOptions} />
        </div>
      </div>
      {funnel !== undefined &&
      funnel?.data !== undefined &&
      funnel.data.phaseCounts.length > 0 ? (
        <div className="overflow-auto w-full h-fit max-h-[85%]">
          <div className="flex flex-row justify-center w-full">
            <div className="flex flex-column flex-wrap justify-end max-w-[100px] overflow-hidden">
              {graphType === 'funnel' && (
                <>
                  {funnel.data.phaseCounts.map(phaseData => (
                    <div
                      style={{
                        height: `${90 / funnel!.data!.phaseCounts.length}%`,
                      }}
                      key={phaseData.phase.id}
                      className="py-2 pl-3 truncate max-w-full"
                    >
                      <TooltipWrapper
                        value={`${phaseData.phase.name}: ${
                          phaseData.cardPercentage?.toFixed(2) || '0'
                        }%`}
                        className="font-medium text-xs min-w-fit"
                      >
                        <span className="cursor-help truncate">
                          {phaseData.phase.name}
                        </span>
                      </TooltipWrapper>
                    </div>
                  ))}
                </>
              )}
              {graphType === 'fixed-funnel' &&
                funnel.data.phaseCounts.map((phaseData, index) => {
                  return (
                    <div
                      style={{
                        height: `${90 / funnel!.data!.phaseCounts.length}%`,
                        display: `${index > 6 ? 'none' : 'block'}`,
                      }}
                      key={phaseData.phase.id}
                      className="my-2 pl-3 box-content truncate flex min-h-6 h-fit max-w-full max-h-full"
                    >
                      <TooltipWrapper
                        value={`${phaseData.phase.name}: ${
                          phaseData.cardPercentage?.toFixed(2) || '0'
                        }%`}
                        className="font-medium text-xs min-w-fit"
                      >
                        <span className="cursor-help">
                          {phaseData.phase.name}
                        </span>
                      </TooltipWrapper>
                    </div>
                  );
                })}
            </div>
            <div className="w-full grow z-10 min-h-[250px] items-center overflow-hidden">
              {graphType === 'pie' && (
                <ResponsivePie
                  data={funnel.data.phaseCounts.map(phaseData => ({
                    id: `${CutAndAddEllipsis(phaseData.phase.name, 10)} - ${
                      phaseData.cardPercentage?.toFixed(2) || '0'
                    }%`,
                    value: phaseData.cardCount,
                    label: CutAndAddEllipsis(phaseData.phase.name, 10),
                  }))}
                  margin={{ right: 60, bottom: 15, left: 10, top: 10 }}
                  colors={{ scheme: 'blues' }}
                  innerRadius={0}
                  padAngle={1}
                  cornerRadius={3}
                  activeOuterRadiusOffset={5}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                  enableArcLabels
                  enableArcLinkLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 4]],
                  }}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: -50,
                      translateY: funnel.data.phaseCounts.length > 10 ? 130 : 0,
                      itemsSpacing: 5,
                      itemWidth: 5,
                      itemHeight: 18,
                      itemDirection: 'left-to-right',
                      symbolSize: 18,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                />
              )}

              {graphType === 'fixed-funnel' && (
                <div className="flex flex-col items-center">
                  <FunnelChart
                    style={{
                      minWidth: '250px',
                      width: '100%',
                      maxHeight: '250px',
                      maxWidth: '350px',
                      padding: '10px',
                    }}
                    data={funnel.data.phaseCounts.map((phaseData, index) => ({
                      value: phaseData.cardCount,
                      name: phaseData.phase.name,
                      backgroundColor: listOf10Blue[index],
                    }))}
                    showNames={false}
                    showRunningTotal={false}
                  />
                </div>
              )}

              {graphType === 'funnel' && (
                <ResponsiveFunnel
                  data={funnel.data.phaseCounts.map(phaseData => ({
                    id: `${CutAndAddEllipsis(phaseData.phase.name, 10)} - ${
                      phaseData.cardPercentage?.toFixed(2) || '0'
                    }%`,
                    value: phaseData.cardCount,
                    label: `${CutAndAddEllipsis(phaseData.phase.name, 10)} - ${
                      phaseData.cardPercentage?.toFixed(2) || '0'
                    }%`,
                  }))}
                  margin={{ right: 15, bottom: 15, left: 15, top: 10 }}
                  colors={{ scheme: 'blues' }}
                  labelColor={{ from: 'color', modifiers: [['darker', 8]] }}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
                  fillOpacity={0.9}
                  borderWidth={5}
                  shapeBlending={0.2}
                  enableLabel
                  valueFormat=">(.2"
                  animate={false}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>Sem dados</div>
      )}
    </div>
  );
};

export default FunnelWidget;
