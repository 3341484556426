/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from 'react';

import {
  Box,
  createStyles,
  makeStyles,
  Popover,
  Theme,
} from '@material-ui/core';

import { SketchPicker } from 'react-color';

import { styles } from './styles';

import { IBgColorPicker } from '../Form.i';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const BgColorPicker: React.FC<IBgColorPicker> = ({
  bgColor,
  labelName,
  fieldName,
  setFieldValue,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);

  const open = Boolean(anchorEl);
  const id = open ? `${fieldName}simple-popover` : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" maxWidth="100%" alignItems="center" flex="1px">
      <Box flex="1">
        <span className={classes.colorPickerTitle}>{labelName}</span>
      </Box>

      <Box mt={0.5} mb={1} width="50%" minWidth="50px" maxWidth="100px">
        <Box
          className={classes.colorPickerSwatch}
          onClick={e => setAnchorEl(e.currentTarget)}
          role="button"
        >
          <Box
            style={{
              backgroundColor: bgColor,
              width: 'inherit',
              height: '20px',
              borderRadius: '2px',
            }}
          />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.colorPickerPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            className={classes.colorPickerCover}
            onClick={() => setAnchorEl(null)}
          />
          <SketchPicker
            color={bgColor}
            onChange={(color: any) => setFieldValue(fieldName, color.hex)}
            disableAlpha
          />
        </Popover>
      </Box>
    </Box>
  );
};
