/* eslint-disable react/require-default-props */
import React from 'react';

import { Chip, Typography } from '@material-ui/core/';

interface IDefaultTag {
  id: string;
  name: string;
}

interface IDefaultTagWithLabel extends IDefaultTag {
  label?: string;
  email?: string;
}

interface ISituation {
  id: string;
  name?: string;
  label?: string;
  email?: string;
}

interface IAutoCompleteTagProps {
  tagValue: IDefaultTagWithLabel[] | ISituation[];
  getTagProps: ({ index }: { index: number }) => Record<string, unknown>;
  size: 'small' | 'medium' | undefined;
  countColor?: string;
  showEmail?: boolean;
}

export function CustomRenderInputTags({
  tagValue,
  getTagProps,
  size,
  countColor = '#53a546',
  showEmail = false,
}: IAutoCompleteTagProps): JSX.Element {
  const numTags = tagValue?.length || 0;
  const limitTags = 1;
  // const total = numTags - limitTags;

  return (
    <>
      {numTags > limitTags && (
        <Typography
          style={{
            fontSize: '14px',
            color: '#fafafa',
            backgroundColor: countColor,
            borderRadius: '50%',
            padding: '0 3px',
          }}
          component="span"
        >
          {numTags < 10 ? `0${numTags}` : numTags}
        </Typography>
      )}
      {tagValue.slice(0, limitTags).map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          key={
            typeof option !== 'string' ? option?.name || option?.label : option
          }
          label={
            (showEmail && `${option?.name} - ${option?.email || ''}`) ||
            option?.name ||
            option?.label ||
            option
          }
          size={size}
          onDelete={undefined}
          style={{
            margin: 0,
            padding: 0,
            background: 'none',
            fontSize: '14px',
          }}
        />
      ))}
    </>
  );
}
