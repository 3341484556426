/* eslint-disable camelcase */
import React from 'react';

import { Box, createStyles, DialogTitle, makeStyles } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

interface ModalProps {
  open: boolean;
  closeModal: (open: boolean) => void;
  title?: string;
  titleColor?: string;
  titleLeft?: string;
  titleRight?: string;
  titleTopRight?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  isPinned?: boolean;
  isHighlighted?: boolean;
  noTitle?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: '800px',
      height: 'auto',
      minHeight: '90%',
      maxHeight: '100%',
      overflow: 'auto',
      boxSizing: 'border-box',
    },
    children: {
      padding: 0,
      margin: 0,
      height: 'calc(100vh - 70px)',
      width: '100%',
      overflow: 'auto',
      // alignItems: 'center',
      // justifyContent: 'center',
    },
    titleRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '0.9rem',
    },
  }),
);

export const ModalWithTitle: React.FC<ModalProps> = ({
  open,
  closeModal,
  noTitle = false,
  title = '',
  titleColor = '#53a546',
  titleLeft = '',
  titleRight = '',
  titleTopRight = '',
  children,
  maxWidth = 'lg',
  isPinned = false,
  isHighlighted = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      onClose={closeModal}
      aria-labelledby={title}
      maxWidth={maxWidth}
      onKeyDown={e => {
        if (open && e.key === 'Escape') {
          e.stopPropagation();
          closeModal(false);
        }
      }}
      disableEnforceFocus
      // disableAutoFocus
      {...rest}
    >
      {!noTitle && (
        <DialogTitle id={title}>
          <Box
            display="flex"
            flex-direction="row"
            justifyContent="space-between"
          >
            <Box justifyContent="space-between">
              <Box height="22px" color={titleColor}>
                <span>{title}</span>
              </Box>
              <Box
                display="flex"
                flex-direction="row"
                alignItems="center"
                marginTop="5px"
              >
                {(isPinned || isHighlighted) && (
                  <Box height="22px" width="15px" mr="4px">
                    {isPinned && (
                      <Box
                        height={isPinned && isHighlighted ? '50%' : '100%'}
                        style={{
                          backgroundColor: '#53a546',
                          borderRadius: '2px',
                        }}
                      />
                    )}
                    {isHighlighted && (
                      <Box
                        height={isPinned && isHighlighted ? '50%' : '100%'}
                        style={{
                          backgroundColor: '#FFE000',
                          borderRadius: '2px',
                        }}
                      />
                    )}
                  </Box>
                )}
                <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              {titleTopRight && (
                <span
                  style={{ paddingBottom: '0px' }}
                  className={classes.titleRight}
                >
                  {titleTopRight}
                </span>
              )}
              {titleRight && (
                <span className={classes.titleRight}>{titleRight}</span>
              )}
            </Box>
          </Box>
        </DialogTitle>
      )}
      <DialogContent className={classes.children}>{children}</DialogContent>
    </Dialog>
  );
};
