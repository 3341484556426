import React from 'react';
import { useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import SignerForm from './SignerForm';

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#007dc6',
      dark: '#004071',
      contrastText: '#fafafa',
    },
    secondary: {
      main: '#53a546',
    },
    background: {
      default: '#eaeeed',
      paper: '#fafafa',
    },
    text: {
      primary: '#212121',
      secondary: '#676767',
    },
  },
});

export interface ISignerParams {
  signer_id: string;
}

const SignDocument: React.FC = () => {
  const params = useParams<ISignerParams>();
  const { signer_id } = params;

  return (
    <ThemeProvider theme={lightTheme}>
      <SignerForm signer_id={signer_id} />
    </ThemeProvider>
  );
};

export default SignDocument;
