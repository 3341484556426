/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { useFormik } from 'formik';

import {
  Box,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from 'src/components/ui/button';
import { useCardDataContext } from '../../../context/CardDataContext';
import { IOccurrenceProps } from '../Card.i';
import api from '../../../services/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 800,
      },
      maxWidth: 428,
    },
    list: {
      backgroundColor: theme.palette.background.paper,
      padding: '0',
      marginLeft: '10px',
    },
    inline: {
      fontSize: '0.9rem',
      fontWeight: 400,
      margin: '10px 5px',
      display: 'flex',
    },
    listItem: {
      padding: '0',
    },
    divider: {
      marginTop: '8px',
      marginBottom: '8px',
    },
    boxForm: {
      zIndex: theme.zIndex.drawer + 1,
    },
  }),
);

interface IFormData {
  observation: string;
}
const Occurrence: React.FC<IOccurrenceProps> = ({
  occurrences,
  observation,
  setObservation,
  loadOccurrences,
}) => {
  const classes = useStyles();
  const { handleSubmitCard, card_id } = useCardDataContext();

  const initialValues: IFormData = {
    observation,
  };

  const formSchema = Yup.object().shape({
    observation: Yup.string().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (values, { resetForm }) => {
      if (observation.length > 0) {
        try {
          await api.post(`/cards/${card_id}/occurrences`, {
            observation,
          });

          loadOccurrences();
          setObservation('');

          toast.success('Ocorrência salva com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
        resetForm();
      }
    },
  });

  return (
    <Box className={classes.content}>
      <Box className={classes.boxForm}>
        <form noValidate>
          <Box display="flex" flexDirection="column">
            <TextField
              id="observation"
              label="Ocorrência"
              name="observation"
              margin="normal"
              autoFocus
              fullWidth
              onChange={e => {
                formik.handleChange(e);
                setObservation(e.target.value);
              }}
              value={observation}
              error={
                formik.touched.observation && Boolean(formik.errors.observation)
              }
              helperText={
                formik.touched.observation && formik.errors.observation
              }
            />
            <Button
              type="submit"
              className="self-end"
              onClick={e => {
                formik.handleSubmit();
                handleSubmitCard(e);
              }}
              disabled={formik.isSubmitting}
            >
              Adicionar
            </Button>
          </Box>
        </form>
      </Box>
      <Box style={{ marginTop: 50 }}>
        <List className={classes.list}>
          {occurrences.map(occurrence => (
            <div key={occurrence.id}>
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <Box mr={1} width={130}>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    noWrap
                  >
                    {occurrence.hourFormatted}
                  </Typography>
                </Box>
                <Box borderRadius={10} padding={0} maxWidth={460}>
                  <ListItemText
                    secondary={
                      <Typography
                        component="span"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {occurrence.observation}
                      </Typography>
                    }
                  />
                </Box>
              </ListItem>
              <Divider className={classes.divider} />
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Occurrence;
