/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useMemo, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Calendar, SlotInfo, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import {
  splitTaskAndCardIds,
  taskEmailConfirmationDialogMessage,
  validateTaskCurrentStatus,
} from 'src/utils/taskUtils';
import api from 'src/services/api';
import { TaskConfirmationDialog } from 'src/components/TaskModal/components/TaskConfirmationDialog';
import { CalendarEventWrapper } from './CalendarEventWrapper';
import { CustomEvent } from './CustomEvent';
import { CalendarTaskData, TaskData } from '../../types';

export interface TaskCalendarProps {
  readonly calendarTasks: CalendarTaskData[];
  readonly handleOpenTaskModal: (id: string) => void;
  readonly handleAddNewTask: (dateRange?: { start: Date; end: Date }) => void;
  handleSetNewTaskDate: (newValue: CalendarTaskData[]) => void;
}

const DnDCalendar = withDragAndDrop(Calendar as any);

const locales = {
  'pt-BR': ptBR,
};

const culture = {
  week: 'Semana',
  work_week: 'Semana de trabalho',
  day: 'Dia',
  month: 'Mês',
  previous: 'Anterior',
  next: 'Próximo',
  today: 'Hoje',
  agenda: 'Agenda',
  yesterday: 'Ontem',
  tomorrow: 'Amanhã',
  allDay: 'Dia inteiro',
  date: 'Data',
  time: 'Hora',
  event: 'Tarefa',
  showMore: (total: number) => `+${total} tarefas`,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const TaskDnDCalendar: React.FC<TaskCalendarProps> = ({
  calendarTasks,
  handleOpenTaskModal,
  handleAddNewTask,
  handleSetNewTaskDate,
}) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [lastChangedEvent, setLastChangedEvent] = useState<
    | {
        uniqueId: string;
        start: Date;
        end: Date;
        isProcessTask: boolean;
      }
    | undefined
  >(undefined);

  const handleOpenCalendarEvent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: CalendarTaskData, e: React.SyntheticEvent<HTMLElement, Event>) => {
      const taskId = event.resource.id;
      if (taskId) handleOpenTaskModal(taskId);
    },
    [handleOpenTaskModal],
  );

  const handleUpdateArrayOfEvents = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: TaskData, start, end, isAllDay) => {
      const newArray = calendarTasks.map((ev: CalendarTaskData) => {
        if (ev.resource.id === event.id) {
          return {
            ...ev,
            start,
            end,
            allDay: isAllDay,
            title: ev.title,
            resource: {
              ...ev.resource,
              startsAt: start,
              endsAt: end,
              status: validateTaskCurrentStatus(
                !!event.completedAt,
                new Date(start as Date).toISOString(),
                new Date(end as Date).toISOString(),
              ),
            },
          };
        }
        return ev;
      });
      handleSetNewTaskDate(newArray);
    },
    [calendarTasks, handleSetNewTaskDate],
  );

  const handleUpdateTaskSchedule = useCallback(
    (
      uniqueId: string,
      start: Date,
      end: Date,
      isProcessTask: boolean,
      sendEmail: boolean,
    ) => {
      const [taskId, cardId] = splitTaskAndCardIds(uniqueId);
      const newStartDate = start ? new Date(start).toISOString() : null;
      const newEndDate = end ? new Date(end).toISOString() : null;
      const url =
        taskId && isProcessTask && cardId
          ? `/cards/${cardId}/cardTasks/${taskId}`
          : `/singleTasks/${taskId}`;

      api
        .put(url, {
          startsAt: newStartDate,
          endsAt: newEndDate,
          shouldSendEmail: sendEmail,
        })
        .then()
        .catch(e => {
          toast.error(`Ocorreu um erro. ${e.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    },
    [],
  );

  const handleSendEmail = (sendEmail: boolean) => {
    setIsEmailModalOpen(false);
    if (lastChangedEvent !== undefined) {
      const { uniqueId, start, end, isProcessTask } = lastChangedEvent;
      handleUpdateTaskSchedule(uniqueId, start, end, isProcessTask, sendEmail);
    }
  };

  const handleOpenEmailConfirmationDialogOrUpdateTask = useCallback(
    (
      uniqueId: string,
      start: Date,
      end: Date,
      isProcessTask: boolean,
      taskTypeId: string,
      hasContacts: boolean,
    ) => {
      if (
        hasContacts &&
        (taskTypeId === 'ONLINE_MEETING' || taskTypeId === 'IN_PERSON_MEETING')
      ) {
        setLastChangedEvent({
          uniqueId,
          start,
          end,
          isProcessTask,
        });
        setIsEmailModalOpen(true);
      } else {
        handleUpdateTaskSchedule(uniqueId, start, end, isProcessTask, false);
      }
    },
    [],
  );

  const handleClickSlot = useCallback(
    (slotInfo: SlotInfo) => {
      const { start, end } = slotInfo;
      const isDifferentDay = start.getDate() !== end.getDate();
      const selectedDate = {
        start,
        end: isDifferentDay ? start : end,
      };
      handleAddNewTask(selectedDate);
    },
    [handleAddNewTask],
  );

  const scrollToTime = useMemo(() => {
    const date = new Date();
    date.setHours(6, 0, 0, 0);

    return date;
  }, []);

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      let isAllDay = event?.allDay || false;
      const eventResource: TaskData = event.resource;
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        isAllDay = true;
      }
      if (allDay && !droppedOnAllDaySlot) {
        isAllDay = false;
      }

      handleUpdateArrayOfEvents(eventResource, start, end, isAllDay);
      handleOpenEmailConfirmationDialogOrUpdateTask(
        eventResource.id,
        start,
        end,
        eventResource.isProcessTask,
        eventResource.typeId,
        eventResource?.contacts.length > 0,
      );
    },
    [handleUpdateArrayOfEvents],
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      const eventResource: TaskData = event.resource;
      handleUpdateArrayOfEvents(eventResource, start, end, false);
      handleOpenEmailConfirmationDialogOrUpdateTask(
        eventResource.id,
        start,
        end,
        eventResource.isProcessTask,
        eventResource.typeId,
        eventResource?.contacts.length > 0,
      );
    },
    [handleUpdateArrayOfEvents],
  );

  return (
    <div className="mb-2 p-1 overflow-auto box-border">
      <DnDCalendar
        draggableAccessor={event => event?.resource?.completedAt === null}
        localizer={localizer}
        culture="pt-BR"
        messages={culture}
        events={(calendarTasks as any) || undefined}
        style={{ height: 500 }}
        onSelectEvent={(event, e) => handleOpenCalendarEvent(event as any, e)}
        selectable
        onSelectSlot={handleClickSlot}
        onEventResize={resizeEvent}
        onEventDrop={moveEvent}
        handleDragStart={() => {}}
        defaultView="month"
        components={{
          month: {
            event: CustomEvent as any,
          },
          week: {
            event: CustomEvent as any,
          },
          day: {
            event: CustomEvent as any,
          },
          eventWrapper: CalendarEventWrapper,
        }}
        popup
        resourceIdAccessor={(e: any) => e?.resource?.id}
        scrollToTime={scrollToTime as Date}
        resizable
        tooltipAccessor={null}
      />
      {isEmailModalOpen && (
        <TaskConfirmationDialog
          open={isEmailModalOpen}
          setOpen={setIsEmailModalOpen}
          handleReturnSelectedOption={handleSendEmail}
          message={taskEmailConfirmationDialogMessage}
        />
      )}
    </div>
  );
};
