import { StyleRules } from '@material-ui/core';

export const tableStyles = (): StyleRules => ({
  dialog: {
    width: '90%',
    height: 'auto',
    maxHeight: '100vh',
    overflow: 'auto',
    maxWidth: '1024px',
  },
  children: {
    padding: 5,
    margin: 0,
    marginBottom: 2,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  addButton: {
    paddingLeft: '0.7rem',
    paddingRight: '0.75rem',
  },
  tableContent: {
    width: '100%',
    marginBottom: '1rem',
  },
  tableContainer: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  table: {
    minWidth: '300px',
    maxWidth: '100%',
    '& .MuiTableCell-sizeSmall': {
      padding: '0.1rem 0.5rem',
    },
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableRowSelected: {
    cursor: 'pointer',
  },
  notIngratedField: {
    width: 'auto',
    overflow: 'hidden',
    '&& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  deleteIconColumn: {
    width: '50px',
  },
});
