/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  Button as MaterialButton,
  TableContainer,
  Container,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';

import { toast } from 'react-toastify';
import { ModalImportAccounts } from 'src/components/ModalImportAccounts';
import { useAccounts } from 'src/hooks/useAccounts';
import api from '../../../services/api';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal_cards';

import Account from '..';
import TablePaginationActions from '../../../components/TablePaginationActions';
import { COLORS } from '../../../styles/colors';

const useStyles = makeStyles({
  content: { maxWidth: '650px' },
  boxTable: {
    maxWidth: '650px',
  },
  columnActions: {
    width: '60px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    marginTop: '30px',
  },
  headerTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginRight: '30px',
    marginBottom: 0,
  },
  btnIcon: {
    marginRight: '10px',
  },
  importPopover: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    width: '135px',
  },
  button: {
    height: '35px',
    width: '90px',
    fontSize: '14px',
    background: 'linear-gradient(to left, #fafafa, #fafafa)',
    color: COLORS.BLUE,
    margin: 0,
    marginLeft: 15,
  },
  swalAlert: {
    zIndex: 99999,
  },
});

interface IContact {
  id: string;
  name: string;
}

const List: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { accounts, setRefreshAccount } = useAccounts();
  const [openModal, setOpenModal] = useState(false);
  const [openModalImportAccountsModal, setModalOpenImportAccountsModal] =
    useState(false);
  const [accountsFiltered, setAccountsFiltered] = useState<IContact[]>([]);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );
  const [findName, setFindName] = useState('');

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, accounts.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setAccountsFiltered(accounts);
  }, [accounts]);

  useEffect(() => {
    const accountsF = accounts.filter(
      account =>
        account.name
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );

    setPage(0);
    setAccountsFiltered(accountsF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findName]);

  const handleEdit = useCallback(
    (account_id: string) => {
      history.push(`/accounts/${account_id}`);
    },
    [history],
  );

  const handleDelete = useCallback((account_id: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/accounts/${account_id}`).then(() => {
            toast.success('Conta excluída com sucesso.', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 2500,
            });
          });
          setRefreshAccount(oldValue => !oldValue);
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  }, []);

  const handleAddAccount = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleImportAccounts = useCallback(() => {
    setModalOpenImportAccountsModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setRefreshAccount(oldValue => !oldValue);
  };

  const handleCloseImportModal = () => {
    setModalOpenImportAccountsModal(false);
    setRefreshAccount(oldValue => !oldValue);
  };

  const handleIntegration = async () => {
    setLoading(true);
    try {
      await api.post('integrations/moments/load_accounts');
      setLoading(false);
      toast.success('Integração com o ERP efetuada com sucesso.', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setTimeout(() => {
        history.go(0);
      }, 50);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccountConfiguration = () => {
    history.push('/accountFields');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.headerTitle}
            >
              Contas
            </Typography>
            <Box>
              <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                className={classes.button}
              >
                Carregar
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box className={classes.importPopover}>
                  <MaterialButton
                    onClick={handleIntegration}
                    disabled={loading}
                    size="small"
                  >
                    {loading ? (
                      <>
                        <CircularProgress color="inherit" size={19} />
                        Carregando
                      </>
                    ) : (
                      <>Integração</>
                    )}
                  </MaterialButton>
                  <MaterialButton onClick={handleImportAccounts} size="small">
                    Arquivo CSV
                  </MaterialButton>
                </Box>
              </Popover>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAccount}
                className={classes.button}
              >
                Adicionar
              </Button>
              <Button
                style={{ width: '150px' }}
                variant="contained"
                color="primary"
                onClick={handleAccountConfiguration}
                className={classes.button}
              >
                Configurações
              </Button>
            </Box>
          </Box>
          <TextField
            label="Pesquisar"
            name="find"
            margin="dense"
            variant="outlined"
            fullWidth
            value={findName}
            style={{ marginTop: 30, marginBottom: 15, maxWidth: '300px' }}
            onChange={value => {
              setFindName(value.target.value);
            }}
          />
        </Box>

        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Contas">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell>Nome da conta</TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? accountsFiltered.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : accountsFiltered
                ).map(account => (
                  <TableRow key={account.name}>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Editar"
                        onClick={() => handleEdit(account.id)}
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {account.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Excluir"
                        size="small"
                        onClick={() => handleDelete(account.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15]}
                    colSpan={3}
                    count={accountsFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': '' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar Conta"
          titleLeft=""
        >
          <Account closeModal={handleCloseModal} />
        </Modal>
      )}
      {openModalImportAccountsModal && (
        <ModalImportAccounts
          openModal={openModalImportAccountsModal}
          closeModal={handleCloseImportModal}
        />
      )}
    </Container>
  );
};

export default List;
