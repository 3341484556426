/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconButton from '@material-ui/core/IconButton';
import { Box, Typography, TextField } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  TableContainer,
  Container,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal_cards';

import TablePaginationActions from '../../../components/TablePaginationActions';
import Email from '..';

import { IEmail } from '../Email.i';

const useStyles = makeStyles({
  content: { maxWidth: '650px' },
  boxTable: {
    maxWidth: '650px',
  },
  columnActions: {
    width: '60px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  headerTitle: { fontSize: '30px', fontWeight: 'bold' },
  btnIcon: {
    marginRight: '10px',
  },
  importPopover: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    width: '135px',
  },
});

const ListEmails: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<IEmail[]>([]);

  const [page, setPage] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );
  const [findEmail, setFindEmail] = useState('');
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, emails.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    api.get('/emails').then(response => {
      setEmails(response.data);
    });
  }, []);

  useEffect(() => {
    setFilteredEmails(emails);
  }, [emails]);

  useEffect(() => {
    const emailsF = emails.filter(
      email =>
        email.email
          .toUpperCase()
          .trim()
          .indexOf(findEmail.toUpperCase().trim()) >= 0,
    );

    setPage(0);
    setFilteredEmails(emailsF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findEmail]);

  const handleEdit = useCallback(
    (email_id: string) => {
      history.push(`/email/${email_id}`);
    },
    [history],
  );

  const handleDelete = useCallback((email_id: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/emails/${email_id}`);
          await api.get('/emails').then(response => setEmails(response.data));
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  }, []);

  const handleAddEmail = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    api.get('/emails').then(response => setEmails(response.data));
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.headerTitle}
            >
              Emails
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEmail}
                style={{ marginLeft: '1rem', width: '135px' }}
              >
                Adicionar
              </Button>
            </Box>
          </Box>
          <TextField
            label="Pesquisar"
            name="find"
            margin="dense"
            fullWidth
            value={findEmail}
            onChange={value => {
              setFindEmail(value.target.value);
            }}
          />
        </Box>

        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Contas">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell>E-mail</TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredEmails.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : filteredEmails
                ).map(email => (
                  <TableRow key={email.id}>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Editar"
                        onClick={() => handleEdit(email.id)}
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {email.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Excluir"
                        size="small"
                        onClick={() => handleDelete(email.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15]}
                    colSpan={3}
                    count={filteredEmails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': '' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar E-mail"
          titleLeft=""
        >
          <Email closeModal={handleCloseModal} />
        </Modal>
      )}
    </Container>
  );
};

export default ListEmails;
