/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React, { useCallback, useState, useRef } from 'react';
import {
  CardContent,
  Box,
  Card as MaterialUICard,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ICard } from '../../interface/IPhaseCards';
import { useFilters } from '../../context/FilterContext';
import DispatchMenu from '../../pages/Card/Dispatch/dispatchMenu';
import { Card } from '../../pages/Card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      position: 'relative',
      width: '200px',
      '& .MuiContainer-maxWidthLg': {
        padding: '5px 0px',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        background: 'linear-gradient(to right, #eaeeed, #53a546)',
      },
      '& .MuiLinearProgress-colorPrimary': {
        background: 'linear-gradient(to right, #fff, #ffff)',
      },
    },
    card: {
      width: '200px',
    },
    cardFlags: {
      top: '5px',
      left: '0.25px',
      width: '15px',
      height: '15px',
      padding: '0px',
      position: 'absolute',
      borderRadius: '7px',
    },
    cardMenuButtonBox: {
      top: '5px',
      right: '1px',
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      position: 'absolute',
    },
    cardMenuButton: {
      top: '0px',
      color: '#676767',
      backgroundColor: 'none',
      right: '1px',
      width: '32px',
      height: '32px',
      borderRadius: '5px',
      padding: '2px',
      position: 'absolute',
      fontSize: '32px',
    },
    cardMenu: {
      height: 'auto',
      borderRadius: '5px',
      fontWeight: 400,
      fontSize: '.8rem',
      cursor: 'pointer',
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgb(33, 33, 33)',
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    background: {
      color: '#000',
      '&:hover': { backgroundColor: '#dfdfdf' },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    nameContact: {
      fontSize: '.8rem',
      fontStyle: 'italic',
      fontWeight: 'bolder',
    },
    nameTask: { fontSize: '.7rem', fontWeight: 'bolder' },
    keyCard: {
      height: '19px',
      display: 'flex',
      fontSize: '.65rem',
      marginTop: '-10px',
      fontWeight: 'bolder',
      color: '#000',
    },
    keyCard2: {
      marginTop: '6px',
      fontWeight: 600,
      width: '100%',
      fontSize: '.60rem',
      color: '#000',
    },
    cardDescription: {
      fontSize: '.65rem',
      fontWeight: 'bolder',
      color: '#000',
    },
    nameAccount: {
      fontSize: '0.9rem',
      color: '#007dc6',
      fontWeight: 'bolder',
    },
    isDragging: {
      border: '2px dashed rgba(0,0,0,0.2)',
      paddingTop: '22px',
      borderRadius: '0',
      backgroundColor: 'transparent',
      color: 'transparent',
      background: 'transparent',
      boxShadow: 'none',
      cursor: 'grabbing',
    },
  }),
);

interface CardProps {
  cardItem: ICard;
  dispatchTypes: IDispatchType[];
  phase: { id: string; name: string };
  moveItem?: (card_id: string, dispatchType_id: string) => void;
  onDispatchCard: () => void;
  customClass: string;
}

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

export const PanelCard: React.FC<CardProps> = ({
  cardItem,
  dispatchTypes = [],
  phase,
  moveItem,
  onDispatchCard,
  customClass = '',
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [cardIdSelected, setCardIdSelected] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openPopper = Boolean(anchorEl);
  const id = openPopper ? 'simple-popper' : undefined;

  const openBox = () => {
    setOpen(!open);
  };

  function onMouseEnter(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget) {
      // setAnchorEl(anchorEl ? null : event.currentTarget);
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  }

  function onMouseLeave() {
    setAnchorEl(null);
    setOpen(false);
  }

  const { handleFilters } = useFilters();
  const ref = useRef(null);

  const [{ isDragging }, dragRef] = useDrag({
    type: 'CARD',
    item: { ...cardItem },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    drop(card: ICard, monitor) {
      if (monitor.didDrop()) moveItem && moveItem(card.id, phase.id);
    },
    canDrop: (item: ICard) => {
      return item.phaseId !== phase.id;
    },
  });

  const handleCloseModal = useCallback(() => {
    handleFilters({ refreshFilter: true });
    setOpenModal(false);
  }, [handleFilters]);

  dragRef(dropRef(ref));

  return (
    <Box className={`${customClass} ${isDragging ? classes.isDragging : ''}`}>
      <Paper
        ref={ref}
        className={classes.paper}
        elevation={2}
        onClick={() => setCardIdSelected(cardItem.id)}
      >
        <Box className={classes.cardFlags}>
          {cardItem.isPinned && (
            <Box
              height="50%"
              style={{ backgroundColor: '#53a546', borderRadius: '2px' }}
            />
          )}
          {cardItem.isHighlighted && (
            <Box
              height="50%"
              style={{ backgroundColor: '#FFE000', borderRadius: '2px' }}
            />
          )}
        </Box>
        <Box
          className={classes.cardMenuButtonBox}
          onMouseEnter={e => onMouseEnter(e)}
          onMouseLeave={onMouseLeave}
        >
          <IconButton className={classes.cardMenuButton}>
            <ArrowDropDownIcon />
          </IconButton>

          <Popper
            id={id}
            open={openPopper}
            anchorEl={anchorEl}
            transition
            placement="bottom"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            style={{
              width: 'fit-content',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <Box className={classes.cardMenu}>
              <DispatchMenu
                card={cardItem}
                dispatchTypes={dispatchTypes}
                closeModal={openBox}
                onDispatchCard={onDispatchCard}
              />
            </Box>
          </Popper>
        </Box>
        <Box display="flex" onClick={() => setOpenModal(true)}>
          <MaterialUICard className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Tooltip arrow title={cardItem.account.name || ''}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.nameAccount}
                  noWrap
                >
                  {cardItem.account.name}
                </Typography>
              </Tooltip>
              <Tooltip arrow title={cardItem.account.contact.name || ''}>
                <Typography
                  component="span"
                  gutterBottom
                  className={classes.nameContact}
                  noWrap
                >
                  {cardItem.account.contact.name}
                </Typography>
              </Tooltip>
              <Tooltip arrow title={cardItem.task.name}>
                <Typography
                  color="textSecondary"
                  component="span"
                  className={classes.nameTask}
                  noWrap
                >
                  {cardItem.task.name}
                </Typography>
              </Tooltip>
            </CardContent>
            <Box
              alignItems="center"
              justifyContent="space-between"
              pr={2}
              pl={2}
            >
              <div>
                <Typography
                  color="textSecondary"
                  component="span"
                  className={classes.keyCard}
                  noWrap
                >
                  <LinearProgress
                    variant="determinate"
                    value={cardItem.percentage}
                    className={classes.keyCard2}
                  />
                  {cardItem.percentage}%
                </Typography>
              </div>
              <Tooltip arrow title={cardItem.description || ''}>
                <Typography
                  color="textSecondary"
                  component="span"
                  className={classes.cardDescription}
                  noWrap
                >
                  {cardItem.description}
                </Typography>
              </Tooltip>
            </Box>
          </MaterialUICard>
        </Box>
        {openModal && (
          <Card
            cardId={cardIdSelected}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </Paper>
    </Box>
  );
};
