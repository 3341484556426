// usePhaseActions.js
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core';
import { IPhase } from '../pages/Phase/Phase.i';
import api from '../services/api';

const useStyles = makeStyles({
  swalAlert: {
    zIndex: 99999,
  },
});

const usePhaseActions = (area_id: string) => {
  const history = useHistory();
  const classes = useStyles();

  const fetchPhases = async () => {
    try {
      const response = await api.get(`/areas/${area_id}/phases/all`);
      return response.data;
    } catch (error) {
      toast.error('Error fetching phases', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
      return [];
    }
  };

  const handleDeletePhase = useCallback(
    // eslint-disable-next-line consistent-return
    async phase_idSelected => {
      const result = await Swal.fire({
        title: 'Deseja excluir?',
        text: 'Essa opção não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não',
        customClass: {
          container: classes.swalAlert,
        },
      });

      if (result.isConfirmed) {
        try {
          await api.delete(`/phases/${phase_idSelected}`);
          const phases = await fetchPhases();
          return phases;
        } catch (error: unknown) {
          if (error instanceof AxiosError && error.response) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          }
          return [];
        }
      }
    },
    [area_id],
  );

  const handleEditPhase = useCallback(
    phase_idSelected => {
      history.push(`/areas/${area_id}/phases/${phase_idSelected}`);
    },
    [area_id, history],
  );

  const handleChangePhaseUp = async (
    phaseSelected: IPhase,
    phases: IPhase[],
  ): Promise<IPhase[]> => {
    const findPhase = phases.find(phase => phase.order < phaseSelected.order);

    if (findPhase) {
      try {
        const response = await api.patch(`/phases/${phaseSelected.id}/up`);
        return response.data;
      } catch (error) {
        toast.error('Erro alterando ordem da fase', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        return phases;
      }
    } else {
      return phases;
    }
  };

  const handleChangePhaseDown = async (
    phaseSelected: IPhase,
    phases: IPhase[],
  ): Promise<IPhase[]> => {
    const findPhase = phases.find(phase => phase.order > phaseSelected.order);

    if (findPhase) {
      try {
        const response = await api.patch(`/phases/${phaseSelected.id}/down`);
        return response.data;
      } catch (error) {
        toast.error('Erro alterando ordem da fase', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        return phases;
      }
    } else {
      return phases;
    }
  };

  return {
    handleDeletePhase,
    handleEditPhase,
    fetchPhases,
    handleChangePhaseUp,
    handleChangePhaseDown,
  };
};

export default usePhaseActions;
