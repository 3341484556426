import React, { useState, ChangeEvent } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core';
import { CompactPicker } from 'react-color';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../Button';

export interface Table {
  id: string;
  name: string;
  columns: string[];
}

interface TableStyleModalProps {
  isTableModalOpen: boolean;
  setIsTableModalOpen: (isOpen: boolean) => void;
  tables: Table[];
  tableStyles: DocumentTableStyles;
  setTableStyles: (styles: DocumentTableStyles) => void;
}

export interface Style {
  color: string;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  width?: string;
}

interface ColumnStyle {
  default: Style;
  [columnName: string]: Style;
}

export interface TableStyle {
  header: ColumnStyle;
  row: ColumnStyle;
  table: {
    borderColor: string;
    borderWidth: number;
  };
}

export interface DocumentTableStyles {
  [tableId: string]: TableStyle;
}

function TableStyleModal({
  isTableModalOpen,
  setIsTableModalOpen,
  tables,
  tableStyles,
  setTableStyles,
}: TableStyleModalProps): React.ReactElement {
  const [selectedTable, setSelectedTable] = useState<Table>(tables?.[0]);
  const defaultStyle: Style = {
    color: '#000000',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: '#000000',
    width: 'auto',
  };
  const [currentColumn, setCurrentColumn] = useState<string>('default');

  // eslint-disable-next-line consistent-return
  const handleColumnChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newColumn = event.target.value as string;
    setCurrentColumn(newColumn);
    // If selectedTable is undefined, return previous styles
    if (!selectedTable) return tableStyles;

    // Get previous styles for the selected table, or initialize with defaults
    const prevTableStyles = tableStyles[selectedTable.id] || {
      header: { default: defaultStyle },
      row: { default: defaultStyle },
      table: {
        borderColor: defaultStyle.borderColor,
        borderWidth: defaultStyle.borderWidth,
      },
    };

    // Update header styles for the new column
    const updatedHeaderStyles = {
      ...prevTableStyles.header,
      [newColumn]: prevTableStyles.header[newColumn] || defaultStyle,
    };

    // Update row styles for the new column
    const updatedRowStyles = {
      ...prevTableStyles.row,
      [newColumn]: prevTableStyles.row[newColumn] || defaultStyle,
    };

    // Combine updated styles for the selected table
    const updatedTableStyles: TableStyle = {
      ...prevTableStyles,
      header: updatedHeaderStyles,
      row: updatedRowStyles,
    };

    setTableStyles({
      ...tableStyles,
      [selectedTable.id]: updatedTableStyles,
    });
  };

  const handleChangeTable = (event: ChangeEvent<{ value: unknown }>) => {
    const clickedTable = tables.find(
      table => table.id === (event.target.value as string),
    );
    if (!clickedTable) return;
    setCurrentColumn('default');
    setSelectedTable(clickedTable);
    if (!tableStyles[clickedTable.id]) {
      setTableStyles({
        ...tableStyles,
        [clickedTable.id]: {
          header: { default: defaultStyle },
          row: { default: defaultStyle },
          table: {
            borderColor: defaultStyle.borderColor,
            borderWidth: Number(defaultStyle.borderWidth),
          },
        },
      });
    }
  };

  const handleStyleChange = (
    tableId: string,
    section: 'header' | 'row',
    field: keyof Style,
    value: string | number,
  ) => {
    const prevStyles = tableStyles[tableId] || {
      header: { default: defaultStyle },
      row: { default: defaultStyle },
      table: {
        borderColor: defaultStyle.borderColor,
        borderWidth: Number(defaultStyle.borderWidth),
      },
    };

    const updatedStyles = {
      ...prevStyles,
      [section]: {
        ...prevStyles[section],
        [currentColumn]: {
          ...prevStyles[section][currentColumn],
          [field]: value,
        },
      },
    };

    setTableStyles({ ...tableStyles, [tableId]: updatedStyles });
  };

  return (
    <>
      <Dialog
        open={isTableModalOpen}
        onClose={() => setIsTableModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ marginBottom: '-10px', paddingBottom: '0px' }}>
          Selecione uma tabela e edite seu estilo
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Tabela</InputLabel>
            <Select
              style={{ marginBottom: '5px' }}
              value={selectedTable ? selectedTable.id : ''}
              onChange={handleChangeTable}
            >
              {tables.map(table => (
                <MenuItem key={table.id} value={table.id}>
                  {table.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel style={{ marginTop: '5px' }}>Coluna</InputLabel>
            <Select
              style={{ marginBottom: '5px' }}
              value={currentColumn}
              onChange={handleColumnChange}
            >
              <MenuItem value="default">Padrão</MenuItem>
              {selectedTable
                ? selectedTable.columns.map(col => (
                    <MenuItem key={col} value={col}>
                      {col}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          {/* Whole Table Border */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Estilos da Tabela</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                <div>
                  <Typography variant="subtitle1">
                    Cor da Borda da Tabela
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable
                        ? tableStyles[selectedTable.id]?.table?.borderColor ||
                          ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      setTableStyles({
                        ...tableStyles,
                        [selectedTable.id]: {
                          ...tableStyles[selectedTable.id],
                          table: {
                            borderColor: color.hex,
                            borderWidth:
                              tableStyles[selectedTable.id]?.table
                                ?.borderWidth || 0,
                          },
                        },
                      });
                    }}
                  />
                </div>
                <div style={{ maxWidth: '45%' }}>
                  <Typography variant="subtitle1">
                    Espessura da Borda da Tabela (em pixels)
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={
                      selectedTable
                        ? tableStyles[selectedTable.id]?.table?.borderWidth || 0
                        : 0
                    }
                    onChange={e => {
                      if (!selectedTable) return;
                      setTableStyles({
                        ...tableStyles,
                        [selectedTable.id]: {
                          ...tableStyles[selectedTable.id],
                          table: {
                            borderColor:
                              tableStyles[selectedTable.id]?.table
                                ?.borderColor || '',
                            borderWidth: Number(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* Header Styles */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Estilos de Cabeçalho</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                <div>
                  <Typography variant="subtitle1">
                    Cor do Texto do Cabeçalho
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable && selectedTable.id
                        ? tableStyles[selectedTable.id]?.header?.[currentColumn]
                            ?.color || ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'header',
                        'color',
                        color.hex,
                      );
                    }}
                  />
                </div>
                <div>
                  <Typography variant="subtitle1">
                    Cor de Fundo do Cabeçalho
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable && selectedTable.id
                        ? tableStyles[selectedTable.id]?.header?.[currentColumn]
                            ?.backgroundColor || ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'header',
                        'backgroundColor',
                        color.hex,
                      );
                    }}
                  />
                </div>
                <div>
                  <Typography variant="subtitle1">
                    Cor da Borda do Cabeçalho
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable && selectedTable.id
                        ? tableStyles[selectedTable.id]?.header?.[currentColumn]
                            ?.borderColor || ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'header',
                        'borderColor',
                        color.hex,
                      );
                    }}
                  />
                </div>
                <div style={{ maxWidth: '45%' }}>
                  <Typography variant="subtitle1">
                    Espessura da Borda do Cabeçalho (em pixels)
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={
                      selectedTable
                        ? tableStyles[selectedTable.id]?.header?.[currentColumn]
                            ?.borderWidth
                        : 0
                    }
                    onChange={e => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'header',
                        'borderWidth',
                        Number(e.target.value),
                      );
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Row Styles */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Estilos da Linha</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <div>
                  <Typography variant="subtitle1">
                    Cor do Texto da Linha
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable && selectedTable.id
                        ? tableStyles[selectedTable.id]?.row?.[currentColumn]
                            ?.color || ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'row',
                        'color',
                        color.hex,
                      );
                    }}
                  />
                </div>
                <div>
                  <Typography variant="subtitle1">
                    Cor de Fundo da Linha
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable && selectedTable.id
                        ? tableStyles[selectedTable.id]?.row?.[currentColumn]
                            ?.backgroundColor || ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'row',
                        'backgroundColor',
                        color.hex,
                      );
                    }}
                  />
                </div>
                <div>
                  <Typography variant="subtitle1">
                    Cor da Borda da Linha
                  </Typography>
                  <CompactPicker
                    color={
                      selectedTable && selectedTable.id
                        ? tableStyles[selectedTable.id]?.row?.[currentColumn]
                            ?.borderColor || ''
                        : ''
                    }
                    onChangeComplete={color => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'row',
                        'borderColor',
                        color.hex,
                      );
                    }}
                  />
                </div>
                <div style={{ maxWidth: '45%' }}>
                  <Typography variant="subtitle1">
                    Espessura da Borda da Linha (em pixels)
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={
                      selectedTable
                        ? tableStyles[selectedTable.id]?.row?.[currentColumn]
                            ?.borderWidth
                        : 0
                    }
                    onChange={e => {
                      if (!selectedTable) return;
                      handleStyleChange(
                        selectedTable.id,
                        'row',
                        'borderWidth',
                        Number(e.target.value),
                      );
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Preview */}
          <h3>Visualização</h3>
          {selectedTable ? (
            <Table
              style={{
                marginTop: 20,
                borderColor: tableStyles[selectedTable.id]?.table?.borderColor,
                borderWidth: `${Number(
                  tableStyles[selectedTable.id]?.table?.borderWidth,
                )}px`,
                borderStyle: 'solid',
              }}
            >
              <TableHead>
                <TableRow>
                  {tables
                    .find(t => t.id === selectedTable.id)
                    ?.columns.map(col => (
                      <TableCell
                        key={col}
                        style={{
                          ...(tableStyles[selectedTable.id]?.header?.default ||
                            {}),
                          ...(tableStyles[selectedTable.id]?.header?.[col] ||
                            {}),
                          borderWidth: `${
                            tableStyles[selectedTable.id]?.header?.[col]
                              ?.borderWidth || 0
                          }px`,
                          borderColor:
                            tableStyles[selectedTable.id]?.header?.[col]
                              ?.borderColor ||
                            tableStyles[selectedTable.id]?.header?.default
                              ?.borderColor,
                          borderStyle: 'solid',
                        }}
                      >
                        {col}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {tables
                    .find(t => t.id === selectedTable.id)
                    ?.columns.map(col => (
                      <TableCell
                        key={col}
                        style={{
                          ...(tableStyles[selectedTable.id]?.row?.default ||
                            {}),
                          ...(tableStyles[selectedTable.id]?.row?.[col] || {}),
                          borderWidth: `${
                            tableStyles[selectedTable.id]?.row?.[col]
                              ?.borderWidth || 0
                          }px`,
                          borderColor:
                            tableStyles[selectedTable.id]?.row?.[col]
                              ?.borderColor ||
                            tableStyles[selectedTable.id]?.row?.default
                              ?.borderColor,
                          borderStyle: 'solid',
                        }}
                      >
                        {col}
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <p>Selecione uma tabela para editar</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTableModalOpen(false)} color="primary">
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TableStyleModal;
