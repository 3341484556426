/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Button as ButtonMaterialUI,
  createStyles,
  Typography,
  Hidden,
  makeStyles,
  Theme,
  Box,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Button from '../../components/Button';

import logo from '../../assets/logo-brasao-bpm-branco.svg';

import api from '../../services/api';

import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface IFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const goBack = () => {
    const path = '/';
    history.push(path);
  };

  const initialValues: IFormData = {
    email: '',
  };

  const formSchema = Yup.object().shape({
    email: Yup.string().email('E-mail inválido').required('Obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async values => {
      try {
        await api.post(`/password/forgot`, {
          email: values.email,
        });
        toast.success(
          'E-mail enviado com sucesso, por favor verifique seu e-mail.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          },
        );
        setTimeout(() => {
          history.push('/');
        }, 2600);
      } catch (error) {
        toast.error(
          'Occorreu um erro ao enviar o link de recuperação da senha',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      }
    },
  });

  return (
    <Grid container className={classes.root}>
      <Hidden mdDown>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-13px',
                marginTop: '149px',
              }}
            />
          </div>
          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Esqueceu a senha?
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="email"
                label="Seu e-mail"
                name="email"
                autoComplete="email"
                margin="normal"
                fullWidth
                autoFocus
                InputLabelProps={{ style: { color: '#007dc6' } }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Box className={classes.buttonsBox}>
                <ButtonMaterialUI
                  className={classes.buttonSubmit}
                  disabled={formik.isSubmitting}
                  size="large"
                  variant="contained"
                  color="inherit"
                  fullWidth
                  onClick={goBack}
                >
                  Voltar
                </ButtonMaterialUI>
                <Button
                  className={classes.submit}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting}
                >
                  Enviar
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-20px',
                marginTop: '50px',
              }}
            />
          </div>

          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Recuperar conta
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="email"
                label="Seu e-mail"
                name="email"
                autoComplete="email"
                margin="normal"
                fullWidth
                autoFocus
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Box className={classes.buttonsBox}>
                <ButtonMaterialUI
                  className={classes.buttonSubmit}
                  disabled={formik.isSubmitting}
                  size="large"
                  variant="contained"
                  color="inherit"
                  fullWidth
                  onClick={goBack}
                >
                  Voltar
                </ButtonMaterialUI>
                <Button
                  className={classes.submit}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting}
                >
                  Enviar
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default ForgotPassword;
