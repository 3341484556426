/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SearchMultipleFilesIcon(props: any): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 110.94 122.88"
    >
      <g>
        <path d="M19.26,40.53a2.74,2.74,0,0,1-2.59-2.82,2.69,2.69,0,0,1,2.59-2.82H63.41A2.72,2.72,0,0,1,66,37.71a2.68,2.68,0,0,1-2.58,2.82ZM79.41,66a24.82,24.82,0,0,1,20.78,38.41l10.75,11.71-7.42,6.77-10.36-11.4A24.82,24.82,0,1,1,79.41,66Zm13.2,11.62a18.66,18.66,0,1,0,5.47,13.2,18.66,18.66,0,0,0-5.47-13.2Zm-73.32-22a2.73,2.73,0,0,1-2.58-2.82A2.68,2.68,0,0,1,19.29,50H41.37A2.74,2.74,0,0,1,44,52.84a2.68,2.68,0,0,1-2.58,2.82ZM82.76,17.14H92a6.64,6.64,0,0,1,6.61,6.61V55.18c-.2,2.07-5.27,2.1-5.7,0V23.75a.92.92,0,0,0-.94-.94H82.73V55.18c-.49,1.88-4.72,2.16-5.67,0V6.61a.92.92,0,0,0-.94-.94H6.58a1,1,0,0,0-.68.27,1,1,0,0,0-.26.67V85.18a1,1,0,0,0,.26.67,1,1,0,0,0,.68.27H43.36c2.86.29,3,5.23,0,5.67H21.5v10.53a.92.92,0,0,0,.94.94H43.36c2.07.23,2.74,4.94,0,5.67H22.48a6.62,6.62,0,0,1-6.61-6.61V91.79H6.61a6.49,6.49,0,0,1-4.66-2A6.56,6.56,0,0,1,0,85.18V6.61A6.49,6.49,0,0,1,2,2,6.55,6.55,0,0,1,6.61,0H76.16a6.51,6.51,0,0,1,4.66,2,6.54,6.54,0,0,1,1.94,4.66V17.14ZM19.26,25.4a2.74,2.74,0,0,1-2.59-2.82,2.69,2.69,0,0,1,2.59-2.82H63.41A2.73,2.73,0,0,1,66,22.58a2.69,2.69,0,0,1-2.58,2.82Z" />
      </g>
    </SvgIcon>
  );
}
