/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

interface ITimestampInput {
  fieldId: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  dateType: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  error?: boolean;
  helperText?: string;
  valueProperty?: 'value' | 'valueJSON';
  handleChangeValue?: (id: string, value: any, valueProperty?: any) => void;
  handleChangeTableValue?: (
    id: string,
    tableRowIndex: number,
    value: any,
    valueProperty: any,
  ) => void;
  tableRowIndex?: number;
}

export const TimestampField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  dateType,
  isDisabled = false,
  isRequired = false,
  margin,
  error,
  helperText,
  valueProperty,
  handleChangeValue = () => {},
  handleChangeTableValue = () => {},
  tableRowIndex,
}: ITimestampInput): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const isTableColumn = tableRowIndex !== undefined;

  useEffect(() => {
    if (fieldValue) setInputValue(fieldValue);
  }, [fieldValue]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    if (isTableColumn) {
      handleChangeTableValue(fieldId, tableRowIndex, inputValue, valueProperty);
    } else {
      handleChangeValue(fieldId, inputValue, valueProperty);
    }
  };

  return (
    <TextField
      key={fieldId}
      id={fieldId}
      name={fieldName}
      type={dateType}
      label={!isTableColumn ? fieldLabel : undefined}
      autoComplete="off"
      margin={margin}
      fullWidth
      required={isRequired}
      disabled={isDisabled}
      onChange={handleInputChange}
      onBlur={handleBlur}
      value={inputValue}
      InputLabelProps={{
        shrink: true,
        style: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
      InputProps={{
        disableUnderline: isTableColumn ? true : undefined,
        margin: isTableColumn ? 'none' : undefined,
        inputProps: {
          style: {
            textAlign: isTableColumn ? 'center' : undefined,
            width: isTableColumn ? 'auto' : undefined,
            overflow: isTableColumn ? 'hidden' : undefined,
            fontSize: isTableColumn ? '14px' : undefined,
          },
          inputformat: dateType === 'DATE' ? 'dd/mm/yyyy' : 'hh:mm',
          pattern:
            dateType === 'DATE'
              ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
              : '[0-9]{2}:[0-5]{1}[0-9]{1}',
          min: dateType === 'DATE' ? '1900-05-01' : '00:00',
          max: dateType === 'DATE' ? '2999-12-31' : '23:59',
        },
      }}
    />
  );
};
