export interface IFormData {
  name: string;
  cpfCnpj: string;
  cityCodeIBGE: string;
  district: string;
  zipCode: string;
  phone: string;
  street: string;
  numeral: string;
  complement: string;
  state: string;
  corporateName: string;
  localidade: string;
}

export interface IAccountField {
  id: string;
  name: string;
  type: string;
  dateType?: string;
  isRequired?: boolean;
  isInactive?: boolean;
  isBigString?: boolean;
  predefinedValues?: string[];
  slug: string;
  order: number;
}

export interface IAccountFieldParams {
  accountField_id: string;
}

export interface IState {
  value: string;
  label: string;
}

export interface IAccountFieldProps {
  closeModal?: (open: boolean) => void;
}

export const typeAccountFields = [
  {
    id: 'STRING',
    label: 'Alfanumérico',
  },
  {
    id: 'NUMERIC',
    label: 'Numérico',
  },
  {
    id: 'BOOL',
    label: 'Sim/Não',
  },
  {
    id: 'TIMESTAMP',
    label: 'Data/Hora',
  },
  {
    id: 'PREDEFINED_STRINGS',
    label: 'Valores Predefinidos',
  },
  // {
  //   id: 'FILE',
  //   label: 'Arquivo',
  // },
];

export const DateTypes = [
  {
    id: 'DATE',
    label: 'Data',
  },
  {
    id: 'TIME',
    label: 'Hora',
  },
];
