/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

interface ICsvPreviewProps {
  csvPreview: IParseCSV;
}

interface IParseCSV {
  header: string[];
  data: Array<string[]>;
}

export function CsvPreview({ csvPreview }: ICsvPreviewProps): JSX.Element {
  const [showPreview, setShowPreview] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 5,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        mt="15px"
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        width="100%"
        onClick={() => setShowPreview(!showPreview)}
      >
        <Typography variant="subtitle2">Preview:</Typography>
        {showPreview ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>

      {showPreview && (
        <Paper
          style={{
            backgroundColor: '#eaeeed',
            width: '99%',
            padding: 0,
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
          }}
        >
          <TableContainer
            // component={Paper}
            style={{ backgroundColor: '#eaeeed', width: '100%' }}
          >
            <Table
              size="small"
              aria-label="Preview de importação do CSV"
              style={{ width: '700px', overflow: 'auto' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" key="linha">
                    Linha
                  </TableCell>
                  {csvPreview.header.map(rowInfo => (
                    <TableCell align="center" key={rowInfo}>
                      {rowInfo}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? csvPreview.data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : csvPreview.data
                ).map((dataPreview, index) => (
                  <TableRow key={dataPreview[index] + index}>
                    <TableCell
                      scope="row"
                      align="center"
                      style={{ width: '30px' }}
                    >
                      {index + 1}
                    </TableCell>
                    {dataPreview.map((data, previewIndex) => (
                      <TableCell
                        key={`${data}-${index}-${previewIndex}`}
                        scope="row"
                        align="center"
                        style={{ width: '30px' }}
                      >
                        {data}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, { label: 'Todos', value: -1 }]}
            component="div"
            count={csvPreview.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': '' },
              native: true,
              style: { margin: 0, padding: 0, backgroundColor: '#eaeeed' },
            }}
            labelRowsPerPage="Linhas por página"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            padding="none"
            size="small"
            align="right"
          />
        </Paper>
      )}

      <Divider
        style={{
          width: '100%',
          marginTop: '0.2rem',
          marginBottom: '0.2rem',
        }}
      />
    </>
  );
}
