export interface ITypeField {
  id: string;
  label: string;
}

export const triggerPhaseTypes: ITypeField[] = [
  {
    id: 'true',
    label: 'Ao entrar',
  },
  {
    id: 'false',
    label: 'Ao sair',
  },
];

export const triggerDispatchTypes: ITypeField[] = [
  {
    id: 'area',
    label: 'Ao trocar área',
  },
  {
    id: 'user',
    label: 'Ao trocar usuário',
  },
  {
    id: 'finish',
    label: 'Ao finalizar',
  },
  {
    id: 'subordinate',
    label: 'Ao criar subordinado',
  },
  {
    id: 'suspend',
    label: 'Ao suspender',
  },
];

export const fillRuleTypeFields: ITypeField[] = [
  {
    id: 'customField',
    label: 'Campo do Card',
  },
  {
    id: 'accountField',
    label: 'Campo da Conta',
  },
  {
    id: 'task',
    label: 'Tarefa',
  },
  {
    id: 'phase',
    label: 'Fase',
  },
];

export const instantTriggerTypeFields: ITypeField[] = [
  {
    id: 'customField',
    label: 'Campo do Card',
  },
  {
    id: 'accountField',
    label: 'Campo da Conta',
  },
  {
    id: 'task',
    label: 'Tarefa',
  },
  {
    id: 'progress',
    label: 'Progresso',
  },
  {
    id: 'dispatch',
    label: 'Avanço',
  },
  {
    id: 'phase',
    label: 'Fase',
  },
];

export const scheduledTriggerTypeFields: ITypeField[] = [
  {
    id: 'customField',
    label: 'Campo do Card',
  },
  {
    id: 'accountField',
    label: 'Campo da Conta',
  },
  {
    id: 'task',
    label: 'Tarefa',
  },
  {
    id: 'progress',
    label: 'Progresso',
  },
  {
    id: 'overdueTask',
    label: 'Tarefa atrasada',
  },
  {
    id: 'staleCard',
    label: 'Card parado',
  },
];
