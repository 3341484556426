import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { taskModalStyles } from '../taskModalStyles';
import { AutocompleteField } from '../../TaskInputFields/AutocompleteField';
import { TaskTypes } from '../../../utils/taskTypes.constants';
import { IObject, TaskMainDataProps } from '../TaskModal.i';
import { TextInput } from '../../TaskInputFields/TextInput';
import { useFormikErrorContext } from '../../../context/FormikErrorProvider';
import { InstructionsModal } from '../InstructionsModal';

export const TaskMainData: React.FC<TaskMainDataProps> = ({
  handleTextValue,
  handleObjectValue,
  taskTypeId,
  taskUser,
  users,
  name,
  description,
  instructions,
  done,
  isProcessTask,
  storedUser,
  isUserAdmin,
  isUserGuest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = taskModalStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { errors, hasError } = useFormikErrorContext();
  const [openInstructions, setOpenInstructions] = useState(false);
  const userOptions = isUserAdmin
    ? users || []
    : users.filter(u => u.id === storedUser.id) || [];

  useEffect(() => {
    if (!isUserAdmin && !taskUser && userOptions?.length === 1) {
      handleObjectValue('user', userOptions[0]);
    }
  }, [userOptions, taskUser, isUserAdmin]);

  const handleCloseInstrunctions = () => {
    setOpenInstructions(false);
  };

  const taskTypeOptions = Object.values(TaskTypes).sort(
    (a: IObject, b: IObject) => -b.name.localeCompare(a.name),
  );

  return (
    <>
      <Box width="100%" className={classes.groupLabel}>
        Dados
      </Box>
      <Box className={classes.groupBoxWithBorder}>
        {instructions?.length > 0 && (
          <Box
            mt="-1.5rem"
            display="flex"
            justifyItems="end"
            className="bg-white w-fit self-end"
          >
            <Tooltip title="Instruções da tarefa" arrow placement="left">
              <IconButton
                size="small"
                onClick={() => setOpenInstructions(true)}
              >
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <AutocompleteField
              fieldId="type"
              fieldName="Tipo Tarefa"
              fieldValue={
                taskTypeOptions.find(option => option.id === taskTypeId) || null
              }
              fieldOptions={taskTypeOptions || []}
              isDisabled
              isRequired
              margin="none"
              handleChangeValue={handleObjectValue}
              error={hasError('type')}
              helperText={hasError('type') ? errors.type : ''}
            />
          </Grid>
          <Grid item xs>
            <AutocompleteField
              fieldId="user"
              fieldName="Usuário Resp."
              fieldValue={taskUser || null}
              fieldOptions={userOptions}
              isDisabled={(!isUserAdmin && !!taskUser) || done || isProcessTask}
              isRequired
              margin="none"
              handleChangeValue={handleObjectValue}
              error={hasError('user')}
              helperText={hasError('user') ? errors.user : ''}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs>
            <TextInput
              fieldId="name"
              fieldName="name"
              fieldLabel="Nome"
              fieldValue={name}
              margin="dense"
              handleOnBlur={handleTextValue}
              isDisabled={
                done || isProcessTask || (isUserGuest && !isUserAdmin)
              }
              error={hasError('name')}
              helperText={hasError('name') ? errors.name : ''}
            />
          </Grid>
          <Grid item xs>
            <TextInput
              fieldId="description"
              fieldName="description"
              fieldLabel="Descrição"
              fieldValue={description}
              margin="dense"
              handleOnBlur={handleTextValue}
              isMultiline
              minRows={1}
              maxRows={3}
              isDisabled={
                done || isProcessTask || (isUserGuest && !isUserAdmin)
              }
              error={hasError('description')}
              helperText={hasError('description') ? errors.description : ''}
            />
          </Grid>
        </Grid>
      </Box>
      {openInstructions && (
        <InstructionsModal
          open={openInstructions}
          closeModal={handleCloseInstrunctions}
          title="Instruções"
          titleLeft=""
          taskInstructions={instructions || ''}
        />
      )}
    </>
  );
};
