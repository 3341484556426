import {
  ICardData,
  ICustomField,
  IHandleFillCustomField,
  IValueJSON,
} from '../../Card.i';
import {
  findCustomFieldById,
  findPhaseAndCustomFieldIndexes,
} from '../cardUtils';

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Rollback the fillRule action that was executed
 * @param {string} fieldiD - the customField id
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {IHandleFillCustomField} handleFillCustomField - function that update cardFields state
 * @returns The previous state of the customField
 */
export function handleRestoreActionOptions(
  fieldId: string,
  cardFields: ICardData[],
  handleFillCustomField: IHandleFillCustomField,
): void {
  const customField = findCustomFieldById(cardFields, fieldId);
  const { phaseIndex, customFieldIndex } = findPhaseAndCustomFieldIndexes(
    fieldId,
    cardFields,
  );
  let newValue: string | undefined;
  let newValueJSON: string[] | undefined;
  const hideField = false;
  const blockField = false;
  const predefinedActiveOptions = customField?.predefinedValues || [];

  if (
    customField &&
    !customField?.valueJSON &&
    customField?.type !== 'TABLE' &&
    customField?.type !== 'PREDEFINED_STRINGS' &&
    customField?.type !== 'INTEGRATED' &&
    customField?.type !== 'FILE'
  ) {
    newValue = customField?.value;
  } else if (
    customField &&
    !customField.valueJSON &&
    (customField.type === 'TABLE' ||
      customField.type === 'PREDEFINED_STRINGS' ||
      customField.type === 'FILE')
  ) {
    newValueJSON = [];
  } else if (
    customField &&
    !customField.valueJSON &&
    customField.type === 'INTEGRATED'
  ) {
    newValueJSON = {} as any;
  } else {
    newValueJSON = customField?.valueJSON;
  }

  handleFillCustomField(
    phaseIndex,
    customFieldIndex,
    newValue,
    newValueJSON as any,
    fieldId,
    hideField,
    predefinedActiveOptions ?? undefined,
    blockField,
  );
}

/**
 * Rollback the fillRule action that was executedin a table column customField
 * @param {string} fieldiD - the customField id
 * @param {ICardData[]} cardFields - the array of cardFields
 * @param {IHandleFillCustomField} handleFillCustomField - function that update cardFields state
 * @param {string} belongsToTable_id - the ID of the customField table that this column belongs
 * @returns The previous state of the customField
 */
export function handleRestoreTableColumnOptions(
  fieldId: string,
  cardFields: ICardData[],
  handleFillCustomField: IHandleFillCustomField,
  belongsToTable_id: string,
): void {
  const table: ICustomField | null = findCustomFieldById(
    cardFields,
    belongsToTable_id,
  );
  const { phaseIndex, customFieldIndex } = findPhaseAndCustomFieldIndexes(
    belongsToTable_id,
    cardFields,
  );
  const tableValueJSON: IValueJSON[] = table?.valueJSON || [];
  const tableColumns: ICustomField[] = table?.tableColumns || [];
  const fieldColumnIndex: number = tableColumns.findIndex(
    f => f.id === fieldId,
  );

  if (!table && !tableColumns && fieldColumnIndex < 0) return;

  if (tableColumns[fieldColumnIndex].predefinedActiveOptions) {
    tableColumns[fieldColumnIndex].predefinedActiveOptions =
      tableColumns[fieldColumnIndex]?.predefinedValues || [];
  }

  if (tableColumns[fieldColumnIndex].isHidden) {
    tableColumns[fieldColumnIndex].isHidden = false;
  }

  if (tableColumns[fieldColumnIndex].isDisabled) {
    tableColumns[fieldColumnIndex].isDisabled = false;
  }

  handleFillCustomField(
    phaseIndex,
    customFieldIndex,
    undefined,
    tableValueJSON as any,
    belongsToTable_id,
    undefined,
    undefined,
    undefined,
    tableColumns,
  );
}
