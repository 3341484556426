import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { addMinutes } from 'date-fns';
import { taskModalStyles } from '../taskModalStyles';
import { TaskSchedulingDataProps } from '../TaskModal.i';
import { MuiDateTimeField } from '../../TaskInputFields/MuiDateTimeField';
import {
  returnColorByTaskStatus,
  validateTaskCurrentStatus,
} from '../../../utils/taskUtils';
import { useFormikErrorContext } from '../../../context/FormikErrorProvider';

export const TaskSchedulingData: React.FC<TaskSchedulingDataProps> = ({
  handleChangeValue,
  startsAt,
  endsAt,
  done,
  editPermissionDisabledForUser,
  isApprovalType,
  wasInitiallyDone,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = taskModalStyles();
  const { errors, hasError } = useFormikErrorContext();
  const currentStatus = validateTaskCurrentStatus(done, startsAt, endsAt);
  const currentStatusColor = returnColorByTaskStatus(currentStatus);
  const editFieldDisabled = done || editPermissionDisabledForUser;
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString();
  const currentDateStringPlusOneMin = addMinutes(currentDate, 1).toISOString();

  return (
    <>
      <Box width="100%" className={classes.groupLabel}>
        Agendamento
      </Box>
      <Box className={classes.groupBoxWithBorder}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <MuiDateTimeField
              fieldId="startsAt"
              fieldName="startsAt"
              fieldLabel="Início"
              fieldValue={startsAt || null}
              handleChangeValue={handleChangeValue}
              margin="dense"
              isDisabled={editFieldDisabled}
              error={hasError('startsAt')}
              helperText={hasError('startsAt') ? errors.startsAt : ''}
            />
            <MuiDateTimeField
              fieldId="endsAt"
              fieldName="endsAt"
              fieldLabel="Fim"
              minDate={startsAt || undefined}
              fieldValue={endsAt || null}
              handleChangeValue={handleChangeValue}
              margin="dense"
              isDisabled={editFieldDisabled}
              error={hasError('endsAt')}
              helperText={hasError('endsAt') ? errors.endsAt : ''}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                lineHeight: '2.75rem',
                paddingLeft: '0.75rem',
                marginTop: '1rem',
                fontWeight: '500',
              }}
            >
              <Typography>Status:</Typography>
              <Typography
                style={{
                  marginLeft: '0.5rem',
                  fontWeight: '500',
                  color: '#FFF',
                  backgroundColor: currentStatusColor,
                  padding: '0.125rem 0.5rem',
                  borderRadius: '5px',
                }}
              >
                {currentStatus}
              </Typography>
            </Box>
            <FormControlLabel
              value={done}
              style={{ margin: 0 }}
              control={
                <Checkbox
                  disabled={
                    (isApprovalType && done && wasInitiallyDone) ||
                    editPermissionDisabledForUser
                  }
                  color="secondary"
                  name="done"
                  checked={done}
                  onChange={(_, checked) => {
                    handleChangeValue('done', checked);
                    if (!startsAt)
                      handleChangeValue('startsAt', currentDateString);
                    if (!endsAt) {
                      setTimeout(() => {
                        handleChangeValue(
                          'endsAt',
                          currentDateStringPlusOneMin,
                        );
                      }, 500);
                    }
                  }}
                />
              }
              label="Tarefa Concluída"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
