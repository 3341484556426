import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NestedObject = { [key: string]: any };

const flattenData = (data: NestedObject[]): NestedObject[] => {
  const result: NestedObject[] = [];

  data.forEach(item => {
    const keys = Object.keys(item);
    const flattened: NestedObject = {};

    keys.forEach(key => {
      const value = item[key];

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        Object.keys(value).length > 0
      ) {
        const nested = flattenData([value]);

        nested.forEach(nestedItem => {
          Object.keys(nestedItem).forEach(nestedKey => {
            flattened[nestedKey] = nestedItem[nestedKey];
          });
        });
      } else if (Array.isArray(value)) {
        if (value.every(subItem => typeof subItem === 'string')) {
          flattened[key] = value.join(', ');
        } else {
          value.forEach((subItem: NestedObject, index) => {
            const flattenedSubItem = flattenData([subItem])[0];
            Object.keys(flattenedSubItem).forEach(nestedKey => {
              flattened[`${key}_${index + 1}_${nestedKey}`] =
                flattenedSubItem[nestedKey];
            });
          });
        }
      } else {
        flattened[key] = value;
      }
    });

    result.push(flattened);
  });

  return result;
};

export const exportToXLSX = (jsonData: NestedObject[], fileName: string) => {
  const flattenedData = flattenData(jsonData);

  if (flattenedData.length === 0) {
    return;
  }

  const fullFileName = `${fileName}.xlsx`;

  const ws = XLSX.utils.json_to_sheet(flattenedData, {
    header: Object.keys(flattenedData[0]),
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([wbout], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  saveAs(blob, fullFileName);
};
