/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { Checkbox } from '../ui/checkbox';

('use client');

interface CheckboxWithTextProps {
  id: string;
  labelText: string;
  infoText?: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

export function CheckboxWithText({
  id,
  labelText,
  infoText,
  checked,
  onCheckedChange,
}: CheckboxWithTextProps) {
  return (
    <div className="items-top flex items-center space-x-2">
      <Checkbox id={id} checked={checked} onCheckedChange={onCheckedChange} />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor={id}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {labelText}
        </label>
        {infoText && (
          <p className="text-sm text-muted-foreground">{infoText}</p>
        )}
      </div>
    </div>
  );
}
