import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';

interface PermissionComponentProps {
  role: string;
}

const PermissionComponent: React.FC<PermissionComponentProps> = ({
  role,
  children,
}) => {
  const { user } = useAuth();

  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (role && user && user.role) {
      const findRole = role.split(',').includes(user.role.name);

      setHasPermission(findRole);
    }
  }, [role, user]);

  return <>{hasPermission && children}</>;
};

export default PermissionComponent;
