import React from 'react';

import { Popper, PopperProps } from '@material-ui/core/';

export function CustomPopper(props: PopperProps): JSX.Element {
  return (
    <Popper
      {...props}
      style={{
        minWidth: '265px',
        width: 'fit-content',
        maxWidth: '350px',
        padding: 0,
        margin: 0,
      }}
      placement="bottom"
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window',
        },
      }}
    />
  );
}
