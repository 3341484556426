/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';

import {
  Button as ButtonMaterialUI,
  Container,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import * as Yup from 'yup';

import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';

import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { toast } from 'react-toastify';
import api from '../../services/api';
import PermissionComponent from '../../components/PermissionComponent';
import Button from '../../components/Button';

import {
  IFormData,
  IBusiness,
  IUserParams,
  IUserProps,
  IArea,
  IUserCRUD,
  IEmail,
} from './User.i';
import { useAuth } from '../../context/AuthContext';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const User: React.FC<IUserProps> = ({ closeModal = value => null }) => {
  const classes = useStyles();

  const history = useHistory();

  const userAuth = useAuth();

  const [user, setUser] = useState<IUserCRUD>();
  const [areas, setAreas] = useState<IArea[]>([] as IArea[]);
  const [businesses, setBusinesses] = useState<IBusiness[]>([] as IBusiness[]);
  const params = useParams<IUserParams>();

  const [emails, setEmails] = useState<IEmail[]>([]);
  const [userEmails, setUserEmails] = useState<IEmail[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<IEmail[]>([]);
  const [defaultEmailChecked, setDefaultEmailChecked] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 5,
  );

  /* const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, emails.length - page * rowsPerPage); */

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { user_id } = params;

  const goBack = () => {
    if (!user_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  const loadingBusiness = businesses.length === 0;
  const loadingArea = areas.length === 0;

  const handleSelectEmail = (
    email: IEmail,
    checkboxType: string,
    checked: boolean,
  ) => {
    const emailIndex = filteredEmails.findIndex(f => f.id === email.id);

    if (emailIndex !== -1) {
      const newSelectedEmail = { ...filteredEmails[emailIndex] };

      if (checkboxType === 'default') {
        if (email.isAssociated && email.isDefault && !checked) {
          newSelectedEmail.isDefault = checked;
          setDefaultEmailChecked(false);
        } else if (
          email.isAssociated &&
          !email.isDefault &&
          checked &&
          defaultEmailChecked === false
        ) {
          newSelectedEmail.isDefault = checked;
          setDefaultEmailChecked(true);
        } else {
          newSelectedEmail.isDefault = email.isDefault;
          toast.warning('Já existe um e-mail marcado como padrão.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }

      if (checkboxType === 'associated') {
        newSelectedEmail.isAssociated = checked;
        newSelectedEmail.isDefault = !checked ? false : email.isDefault;
        if (
          !newSelectedEmail.isAssociated &&
          email.isDefault &&
          defaultEmailChecked
        ) {
          setDefaultEmailChecked(false);
        }
      }

      const newFilteredEmails = [...filteredEmails];
      newFilteredEmails[emailIndex] = newSelectedEmail;

      setFilteredEmails(newFilteredEmails);
    }
  };

  useEffect(() => {
    api.get('/emails').then(response => {
      const data = response?.data;
      const formattedEmails = data.map((email: IEmail) => {
        return {
          id: email.id,
          email: email.email,
          isDefault: false,
          isAssociated: false,
        };
      });
      setEmails(formattedEmails);
    });
  }, []);

  useEffect(() => {
    if (user_id)
      api.get(`/users/${user_id}`).then(response => {
        setUser(response.data);
        const responseEmails = response.data?.emails || [];
        if (responseEmails.length > 0) {
          const formattedEmails = responseEmails.map((email: IEmail) => {
            return {
              id: email.id,
              email: email.email,
              isDefault: email.isDefault,
              isAssociated: true,
            };
          });
          setUserEmails(formattedEmails);
        }
      });
  }, [user_id]);

  useEffect(() => {
    if (emails.length > 0 && userEmails.length > 0) {
      const tempEmails: IEmail[] = emails.map((email: IEmail) => {
        const item: IEmail = userEmails.find(
          (i: IEmail) => i.id === email.id,
        ) as IEmail;

        return {
          id: email.id,
          email: email.email,
          isDefault: item?.isDefault || false,
          isAssociated: item?.isAssociated || false,
        };
      });

      const sortedEmails = tempEmails.sort((a, b) =>
        a.isDefault && !b.isDefault
          ? -1
          : a.isAssociated && !b.isAssociated
          ? -1
          : -b.email.localeCompare(a.email),
      );

      setDefaultEmailChecked(sortedEmails.filter(f => f.isDefault).length > 0);
      setFilteredEmails(sortedEmails);
    } else {
      setFilteredEmails(emails);
    }
  }, [emails, userEmails]);

  useEffect(() => {
    api.get('/areas').then(response => setAreas(response.data));
  }, []);

  useEffect(() => {
    if (userAuth.user.role.name === 'ROLE_ADMIN') {
      api.get('/businesses').then(response => setBusinesses(response.data));
    }
  }, [userAuth]);

  useEffect(() => {
    let active = true;

    if (!loadingArea) {
      return undefined;
    }

    (async () => {
      const response = await api.get('/areas');

      const formattedAreas = (await response.data) as IArea[];

      if (active) {
        setAreas(formattedAreas);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingArea]);

  const initialValues: IFormData = {
    name: user ? user.name : '',
    email: user ? user.email : '',
    old_password: '',
    password: '',
    confirm_password: '',
    area: user ? user.area : null,
    business: user ? user.business : null,
    is_supervisor: user ? user.is_supervisor : false,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string()
      .email('Digite um email válido')
      .required('Campo obrigatório'),
    area: Yup.object().nullable().required('Campo obrigatório'),
    business: Yup.object().nullable().required('Campo obrigatório'),

    old_password: Yup.string(),
    password: Yup.string()
      .min(6, 'Senha deve conter no mínimo 6 caracteres')
      .when('old_password', {
        is: (val: string) => !!val,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      }),

    confirm_password: Yup.string()
      .when('old_password', {
        is: (val: string) => !!val,
        then: Yup.string().required('Campo obrigatório'),
        otherwise: Yup.string(),
      })
      .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formattedEmails = filteredEmails
          .filter(email => email.isAssociated || email.isDefault)
          .map(e => {
            return { id: e.id, isDefault: e.isDefault };
          });

        if (user_id) {
          await api.put(`/users/${user_id}`, {
            name: values.name,
            email: values.email,
            business_id: values.business?.id,
            area_id: values.area?.id,
            is_supervisor: values.is_supervisor,
            emails: formattedEmails,

            ...(values.password
              ? {
                  old_password: values.old_password,
                  password: values.password,
                }
              : {}),
          });

          toast.success('Usuário editado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });

          if (
            !values.is_supervisor &&
            userAuth.user.role.name === 'ROLE_ADMIN' &&
            user_id === userAuth.user.id
          ) {
            toast.success(
              'Editado com sucesso. Será deslogado devido alteração nas permissões.',
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              },
            );
            setTimeout(() => {
              userAuth.signOut();
            }, 3000);
          }
        } else {
          await api.post('/users', {
            name: values.name,
            email: values.email,
            password: values.password,
            business_id: values.business?.id,
            area_id: values.area?.id,
            is_supervisor: values.is_supervisor,
            emails: formattedEmails,
          });
          toast.success('Usuário salvo com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            onClose: () => history.go(0),
          });
        }
        setSubmitting(true);
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  return (
    <Container
      maxWidth={false}
      className={
        user_id ? classes.editableUserContainer : classes.newUserContainer
      }
    >
      <Box className={classes.content}>
        {user_id ? (
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar Usuário
          </Typography>
        ) : (
          <div />
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            className={
              user_id ? classes.editableUserFields : classes.newUserModalFields
            }
            display="flex"
            flexDirection="column"
            alignItems="start"
            component={user_id ? Paper : undefined}
          >
            <TextField
              id="name"
              label="Nome"
              name="name"
              autoComplete="name"
              margin="normal"
              autoFocus
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              margin="normal"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            {user_id && (
              <TextField
                id="old_password"
                label="Senha Atual"
                name="old_password"
                type="password"
                autoComplete="current-password"
                margin="normal"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.old_password}
                error={
                  formik.touched.old_password &&
                  Boolean(formik.errors.old_password)
                }
                helperText={
                  formik.touched.old_password && formik.errors.old_password
                }
              />
            )}
            <TextField
              id="password"
              label={user_id ? 'Nova Senha' : 'Senha'}
              name="password"
              margin="normal"
              type="password"
              autoComplete="new-password"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              id="confirm_password"
              label={user_id ? 'Confirme Nova Senha' : 'Confirmação da Senha'}
              name="confirm_password"
              margin="normal"
              type="password"
              autoComplete="new-password"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.confirm_password}
              error={
                formik.touched.confirm_password &&
                Boolean(formik.errors.confirm_password)
              }
              helperText={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
            />
            <PermissionComponent role="ROLE_ADMIN">
              <Autocomplete
                id="business"
                getOptionLabel={business => business?.name}
                getOptionSelected={(business, value) =>
                  business.id === value.id
                }
                options={businesses}
                loading={loadingBusiness}
                value={formik.values.business || null}
                onChange={(e, value) => {
                  formik.setFieldValue(
                    'business',
                    value !== null ? value : initialValues.business,
                  );
                }}
                loadingText="Carregando"
                fullWidth
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="business"
                    label="Unidade de negócio"
                    margin="normal"
                    error={
                      formik.touched.business && Boolean(formik.errors.business)
                    }
                    helperText={
                      formik.touched.business && formik.errors.business
                    }
                    InputProps={{
                      ...rest.InputProps,
                      endAdornment: (
                        <>
                          {loadingArea ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <Autocomplete
                id="area"
                getOptionLabel={area => area.name}
                getOptionSelected={(area, value) => area.id === value.id}
                options={areas}
                loading={loadingArea}
                value={formik.values.area || null}
                onChange={(e, value) => {
                  formik.setFieldValue(
                    'area',
                    value !== null ? value : initialValues.area,
                  );
                }}
                loadingText="Carregando"
                fullWidth
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="area"
                    label="Área"
                    margin="normal"
                    error={formik.touched.area && Boolean(formik.errors.area)}
                    helperText={formik.touched.area && formik.errors.area}
                    InputProps={{
                      ...rest.InputProps,
                      endAdornment: (
                        <>
                          {loadingArea ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="is_supervisor"
                    checked={formik.values.is_supervisor}
                    onChange={formik.handleChange}
                  />
                }
                label="Supervisor"
              />
              <Box width="100%" className={classes.tableTitle}>
                E-mails
              </Box>
              <Box className={classes.boxTable}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="lista de E-mails">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="center" padding="none">
                          Vincular
                        </TableCell>
                        <TableCell align="center" padding="none">
                          Padrão
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? filteredEmails.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                        : filteredEmails
                      ).map(email => (
                        <TableRow key={email.id}>
                          <TableCell
                            scope="row"
                            align="left"
                            style={{ maxWidth: '50%' }}
                          >
                            {email.email}
                          </TableCell>
                          <TableCell scope="row" align="center" padding="none">
                            <Checkbox
                              name="isAssociated"
                              checked={email.isAssociated || false}
                              inputProps={{ 'aria-labelledby': email.id }}
                              onChange={event =>
                                handleSelectEmail(
                                  email,
                                  'associated',
                                  event.target.checked,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell scope="row" align="center" padding="none">
                            <Checkbox
                              name="isDefault"
                              checked={email.isDefault || false}
                              disabled={
                                !email.isAssociated ||
                                (email.isAssociated &&
                                  defaultEmailChecked &&
                                  !email.isDefault)
                              }
                              inputProps={{ 'aria-labelledby': email.id }}
                              onChange={event =>
                                handleSelectEmail(
                                  email,
                                  'default',
                                  event.target.checked,
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            { label: 'Todos', value: -1 },
                          ]}
                          colSpan={3}
                          count={emails.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { 'aria-label': '' },
                            native: true,
                            style: { margin: 0 },
                          }}
                          labelRowsPerPage="Linhas por página"
                          onPageChange={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          padding="none"
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            </PermissionComponent>
            <Box>
              <ButtonMaterialUI
                className={classes.buttonSubmit}
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                color="inherit"
                onClick={goBack}
              >
                Voltar
              </ButtonMaterialUI>
              <Button
                className={classes.buttonSubmit}
                disabled={formik.isSubmitting}
                type="submit"
                color="primary"
                variant="contained"
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default User;
