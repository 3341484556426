/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useState } from 'react';

import {
  Box,
  createStyles,
  FormControlLabel,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from 'src/components/ui/button';
import { useAuth } from 'src/context/AuthContext';
import { EmailSenderModal } from '../../../components/EmailSenderModal';
import { COLORS } from '../../../styles/colors';
import { AccountCustomFields } from '../../Account/components/AccountCustomFields';
import { useCardDataContext } from '../../../context/CardDataContext';
import { IMainProps } from '../Card.i';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      color: '#007dc6',
      maxWidth: '100%',
      '& label.Mui-focused': {
        color: COLORS.BLUE,
      },
      '& .MuiInputLabel-shrink': {
        color: COLORS.BLUE,
        fontWeight: 500,
        '& fieldset': {
          borderColor: COLORS.BLUE,
        },
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: COLORS.BLUE,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: COLORS.BLUE,
        },
      },
      '& .MuiFilledInput-root': {
        pointerEvents: 'none',
        backgroundColor: '#ffffff',
      },
    },
    checkBoxesDiv: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormControlLabel-root': {
        color: '#676767',
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    editButton: {
      fontSize: '0.9rem',
      padding: '5px 10px',
    },
  }),
);

const Main: React.FC<IMainProps> = ({ card, setCard }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { handleSubmitCard, card_id } = useCardDataContext();

  const [openModal, setOpenModal] = useState(false);

  const handleSendEmail = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleSendWhatsApp = useCallback(() => {
    if (card && card.contact.phone && card.contact.name) {
      const phone = card.contact.phone.replace(/\D/g, '');
      window.open(
        `https://api.whatsapp.com/send?phone=55${phone}&text=Olá, ${card.contact.name}!`,
        '_blank',
      );
    } else {
      toast.warn(
        'Não foi possível enviar mensagem via WhatsApp. Confira os dados do contato.',
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        },
      );
    }
  }, [card]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (card) {
      if (event.target.name === 'isPinned') {
        setCard({
          ...card,
          isPinned: event.target.checked,
        });
      } else {
        setCard({
          ...card,
          isHighlighted: event.target.checked,
        });
      }
    }
  };

  const handleSaveCard = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    handleSubmitCard(e);
  };

  const accountReponsibles =
    card?.account.responsibleUsers.map(r => r.id) || [];
  const disabledAccountAccess =
    user.role.name !== 'ROLE_ADMIN' &&
    accountReponsibles.length > 0 &&
    !accountReponsibles.includes(user.id);

  return (
    <Box className={classes.content}>
      {card && (
        <>
          <Paper
            elevation={2}
            style={{
              marginTop: '-10px',
              marginBottom: '10px',
              padding: '10px 10px',
              boxSizing: 'border-box',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography style={{ marginTop: '-10px', fontWeight: 700 }}>
                Dados da Conta
              </Typography>
              {!disabledAccountAccess && (
                <Tooltip title="Editar">
                  <Link target="_blank" href={`/accounts/${card.account.id}`}>
                    <IconButton>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
            </Box>

            <TextField
              size="small"
              variant="filled"
              label="Nome"
              value={card.account.name || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              size="small"
              variant="filled"
              label="Telefone"
              value={card.account.phone || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              size="small"
              variant="filled"
              label="CPF/CNPJ"
              value={card.account.cpfCnpj || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Campos Adicionais</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: '100%' }}>
                <AccountCustomFields
                  accountFields={card.account.fieldValues.map(
                    fieldValue => fieldValue.field,
                  )}
                  fieldValues={card.account.fieldValues.map(fieldValue => {
                    return {
                      id: fieldValue.field.id,
                      value: fieldValue.value,
                      valueJSON: fieldValue.valueJSON,
                    };
                  })}
                  setFieldValues={() => {}}
                  viewOnly
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Paper
            elevation={2}
            style={{
              marginBottom: '10px',
              padding: '10px',
              boxSizing: 'border-box',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography style={{ marginTop: '-10px', fontWeight: 700 }}>
                Dados do Contato
              </Typography>
              <Box>
                <Tooltip title="Enviar mensagem via WhatsApp">
                  <IconButton onClick={handleSendWhatsApp}>
                    <WhatsAppIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enviar E-mail">
                  <IconButton onClick={handleSendEmail}>
                    <MailOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {!disabledAccountAccess && (
                  <Tooltip title="Editar">
                    <Link
                      href={`/accounts/${card.account.id}/contacts/${card.contact.id}`}
                      target="_blank"
                    >
                      <IconButton disabled={disabledAccountAccess}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <TextField
              size="small"
              variant="filled"
              label="Contato"
              value={card.contact.name || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              size="small"
              variant="filled"
              label="Telefone"
              value={card.contact.phone || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              size="small"
              variant="filled"
              label="E-mail"
              value={card.contact.email || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              size="small"
              variant="filled"
              label="Usuário"
              value={card.user.name || ''}
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Paper>
          <Paper
            elevation={2}
            style={{
              padding: '10px',
              marginTop: '0px',
            }}
          >
            <TextField
              style={{ marginTop: '0px' }}
              id="description"
              label="Descrição do card"
              name="email"
              margin="normal"
              autoComplete="off"
              value={card.description || ''}
              onChange={e => setCard({ ...card, description: e.target.value })}
              fullWidth
            />

            <Box className={classes.checkBoxesDiv}>
              <FormControlLabel
                // eslint-disable-next-line prettier/prettier
                control={
                  <Checkbox
                    id="isHighlighted"
                    checked={card.isHighlighted}
                    onChange={handleChangeCheckbox}
                    name="isHighlighted"
                  />
                }
                label="Destaque"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="isPinned"
                    checked={card.isPinned}
                    onChange={handleChangeCheckbox}
                    name="isPinned"
                  />
                }
                label="Fixo"
                labelPlacement="end"
              />
            </Box>
          </Paper>
          <Button
            type="submit"
            className="absolute bottom-4 right-8 z-10"
            size="lg"
            onClick={e => {
              handleSaveCard(e);
            }}
          >
            Salvar
          </Button>

          {openModal && (
            <EmailSenderModal
              open={openModal}
              closeModal={handleCloseModal}
              title="Envio de E-mail"
              titleLeft=""
              receiver={card.contact.email}
              cardId={card_id || ''}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Main;
