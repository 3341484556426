import React from 'react';
import { ICardCustomField } from '../FillForm.i';

export const handleFillAccountAndCustomFields = (
  setFieldList: (value: React.SetStateAction<ICardCustomField[]>) => void,
  fieldList: ICardCustomField[],
  emptyRequiredTables: string[] | null,
  setEmptyRequiredTables: (
    value: React.SetStateAction<string[] | null>,
  ) => void,
  fieldId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  type: string,
  dateType?: string,
) => {
  if (typeof value !== 'undefined') {
    const tempCustomFields = [...fieldList];
    const isInArrayOfValues = tempCustomFields.findIndex(
      field => field.id === fieldId,
    );

    if (
      type !== 'PREDEFINED_STRINGS' &&
      type !== 'TABLE' &&
      isInArrayOfValues < 0
    ) {
      tempCustomFields.push({
        id: fieldId,
        value,
        type,
        dateType: dateType || undefined,
      });
    }

    if (
      type !== 'PREDEFINED_STRINGS' &&
      type !== 'TABLE' &&
      isInArrayOfValues >= 0
    ) {
      tempCustomFields[isInArrayOfValues] = {
        id: fieldId,
        value,
        type,
        dateType: dateType || undefined,
      };
    }

    if (
      (type === 'PREDEFINED_STRINGS' || type === 'TABLE') &&
      isInArrayOfValues < 0
    ) {
      tempCustomFields.push({
        id: fieldId,
        valueJSON: value,
        type,
      });
    }

    if (
      (type === 'PREDEFINED_STRINGS' || type === 'TABLE') &&
      isInArrayOfValues >= 0
    ) {
      tempCustomFields[isInArrayOfValues] = {
        id: fieldId,
        valueJSON: value,
        type,
      };
    }

    if (
      type === 'TABLE' &&
      emptyRequiredTables !== null &&
      emptyRequiredTables &&
      emptyRequiredTables.includes(fieldId) &&
      value
    ) {
      const tempEmptyTables = [...emptyRequiredTables].filter(
        v => v !== fieldId,
      );
      setEmptyRequiredTables(tempEmptyTables || null);
    }

    setFieldList(tempCustomFields);
  }
};

export const simplifyFieldArray = (
  fieldList: ICardCustomField[],
  type: 'account' | 'card',
) => {
  const simplifiedCardCustomFields = fieldList.map(field => {
    if (field.value !== undefined) {
      return {
        id: field.id,
        value: field.value,
      };
    }
    if (field.valueJSON !== undefined && type === 'card') {
      return {
        id: field.id,
        valueJSON: field.valueJSON,
      };
    }
    if (field.valueJSON !== undefined && type === 'account') {
      return {
        id: field.id,
        value: field.valueJSON,
        valueJSON: undefined,
      };
    }
    return null;
  });
  return simplifiedCardCustomFields;
};
