import { createStyles, makeStyles } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const taskModalStyles: any = makeStyles(() =>
  createStyles({
    taskContent: {
      boxSizing: 'border-box',
      padding: 0,
      minHeight: '600px',
      // height: '100%',
      // maxHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    groupBoxStyle: {
      boxSizing: 'border-box',
      padding: '0.25rem',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: '0.5rem',
      justifyContent: 'space-between',
    },
    groupBoxWithBorder: {
      border: `1px solid ${COLORS.BORDER}`,
      borderRadius: '5px',
      padding: '0.5rem',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      width: '100%',
    },
    label: {
      transform: 'translate(0, 1.5px) scale(0.75)',
    },
    groupLabel: {
      WebkitBackgroundClip: 'border-box',
      backgroundColor: COLORS.WHITE,
      color: COLORS.BLACK,
      fontSize: '0.8rem',
      fontWeight: 500,
      width: 'fit-content',
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      marginBottom: '-1.05rem',
      marginLeft: '0.35rem',
      position: 'relative',
      zIndex: 1,
    },
  }),
);
