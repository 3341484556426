import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiIbge = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/',
});

export const apiViaCep = axios.create({
  baseURL: 'https://viacep.com.br/ws/',
});

export default api;
