import { toast } from 'react-toastify';

export const showToast = (
  message: string,
  type: 'error' | 'success' = 'error',
) => {
  toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    theme: 'colored',
    autoClose: 4000,
    closeOnClick: true,
  });
};
