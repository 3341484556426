/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ArrowUpRightFromSquareIcon, SquarePenIcon } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { autocompleteStyles } from './autocompleteStyles';
import { CustomRenderInputTags } from '../../AutoCompleteCustomComponents/CustomRenderInputTags';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => createStyles(autocompleteStyles()));

interface ValueProps {
  id: string;
  name: string;
  email?: string;
  [key: string]: any;
}

type InputType = ValueProps | ValueProps[] | null;

interface AutocompleteProps {
  fieldId: string;
  fieldName: string;
  fieldValue: InputType;
  isMultipleOptions?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  margin?: string;
  fieldOptions: Array<ValueProps>;
  error?: boolean | undefined;
  helperText?: string | boolean | undefined;
  fieldSize?: 'medium' | 'small' | undefined;
  showOptionsCheckbox?: boolean;
  optionToGroupBy?: string;
  fullWidth?: boolean;
  showStartLinkAdornment?: boolean;
  startAdornmentTooltip?: string;
  startLinkAdornmentOnClick?: () => void;
  showStartAdornmentEditIcon?: boolean;
  handleChangeValue: (id: string, value: any) => void;
}

// autocomplete to use with options containing at least ID and NAME attribute
export const AutocompleteField: React.FC<AutocompleteProps> = ({
  fieldId,
  fieldName,
  fieldValue,
  fieldOptions,
  fieldSize = 'small',
  isMultipleOptions = false,
  isDisabled = false,
  isRequired = false,
  margin = 'normal',
  error = undefined,
  helperText = undefined,
  handleChangeValue,
  showOptionsCheckbox = false,
  fullWidth = true,
  optionToGroupBy,
  showStartLinkAdornment = false,
  startAdornmentTooltip = '',
  startLinkAdornmentOnClick = () => {},
  showStartAdornmentEditIcon = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}: AutocompleteProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<InputType>(
    isMultipleOptions ? [] : null,
  );

  useEffect(() => {
    setInputValue(fieldValue);
  }, [fieldValue]);

  const handleInputChange = (value: ValueProps | null) => {
    setInputValue(value);
    if (!isMultipleOptions) handleChangeValue(fieldId, value);
  };

  const handleBlur = () => {
    if (isMultipleOptions) handleChangeValue(fieldId, inputValue);
  };

  return (
    <Autocomplete
      id={fieldId}
      multiple={isMultipleOptions}
      disableCloseOnSelect={isMultipleOptions}
      limitTags={isMultipleOptions ? 1 : undefined}
      disabled={isDisabled}
      // options={fieldOptions && Array.isArray(fieldOptions) ? fieldOptions : []}
      options={fieldOptions || []}
      groupBy={
        optionToGroupBy
          ? option => option[optionToGroupBy as string]
          : undefined
      }
      getOptionLabel={option =>
        (isMultipleOptions ? `${option.name} ${option?.email}` : option.name) ||
        ''
      }
      getOptionSelected={(option, value) => option?.id === value?.id}
      value={
        fieldOptions && inputValue !== undefined && inputValue !== null
          ? inputValue
          : isMultipleOptions
          ? []
          : null
      }
      onChange={(_, value) => {
        handleInputChange(value as any);
      }}
      fullWidth
      renderOption={
        isMultipleOptions || showOptionsCheckbox
          ? (option, { selected }) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  className={classes.optionsCheckBox}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.name || ''} ${option?.email || ''}`}
              </>
            )
          : undefined
      }
      renderTags={
        isMultipleOptions || showOptionsCheckbox
          ? (tagValue, getTagProps) => {
              return (
                <CustomRenderInputTags
                  tagValue={tagValue}
                  getTagProps={getTagProps}
                  size="small"
                  showEmail={isMultipleOptions && fieldId === 'contacts'}
                />
              );
            }
          : undefined
      }
      loadingText="Carregando"
      noOptionsText="Sem opções"
      renderInput={(rest: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...rest}
          autoComplete="off"
          required={isRequired}
          disabled={isDisabled}
          name={fieldId}
          label={fieldName}
          size={fieldSize}
          margin={`${margin}` as any}
          onBlur={() => handleBlur()}
          error={error}
          helperText={helperText}
          fullWidth={fullWidth}
          InputProps={{
            ...rest.InputProps,
            style: {
              display: 'flex',
              flexWrap: 'nowrap',
            },
            startAdornment:
              showStartLinkAdornment && !!startLinkAdornmentOnClick ? (
                <InputAdornment position="start">
                  <Tooltip title={startAdornmentTooltip} arrow>
                    <Button
                      size="icon"
                      type="button"
                      variant="link"
                      aria-label={startAdornmentTooltip}
                      onClick={startLinkAdornmentOnClick}
                      className="m-0 p-0 w-fit h-fit"
                    >
                      {showStartAdornmentEditIcon ? (
                        <SquarePenIcon size="1.25rem" />
                      ) : (
                        <ArrowUpRightFromSquareIcon size="1.25rem" />
                      )}
                    </Button>
                  </Tooltip>
                </InputAdornment>
              ) : (
                rest.InputProps?.startAdornment
              ),
          }}
        />
      )}
    />
  );
};
