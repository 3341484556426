/* eslint-disable camelcase */
import { Box } from '@material-ui/core';
import React from 'react';

import { useDrop } from 'react-dnd';
import { ICard } from '../../interface/IPhaseCards';

interface DropWrapperProps {
  onDrop: (item: string, phase_id: string) => void;
  phase_id: string;
}
const DropWrapper: React.FC<DropWrapperProps> = ({
  onDrop,
  phase_id,
  children,
}) => {
  const [, drop] = useDrop({
    accept: 'CARD',

    drop: (item: ICard) => {
      onDrop(item.id, phase_id);
    },
    canDrop: (item: ICard) => {
      return item.phaseId !== phase_id;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop}>
      <Box>{children}</Box>
    </div>
  );
};

export default DropWrapper;
