/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, createStyles, Grid, Hidden, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFilters } from '../../context/FilterContext';
import { useTrigger } from '../../context/TriggerContext';
import api from '../../services/api';
import DropWrapper from './DropWrapper';
import { Phase } from './phase';

const useStyles = makeStyles(theme =>
  createStyles({
    boxColumns: {
      display: 'flex',
      gap: '5px',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      marginBottom: 15,
    },
    phaseColumn: {
      border: 'solid',
      fontWeight: 'bolder',
      borderColor: '#dfdfdf',
      background: '#fafafa',
      borderRadius: 5,
      borderWidth: '1px',
      borderTop: 'none',
      height: '100%',
      '& > div': {
        height: '100%',
        '& > div': {
          height: '100%',
        },
      },
    },
  }),
);

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

const PanelCards: React.FC = () => {
  const classes = useStyles();
  const { updateTriggers } = useTrigger();
  const { filterArea, phases, handleFilters, filterTotalizer } = useFilters();
  // const { showLoading, hideLoading } = useLoader();
  const [dispatchTypes, setDispatchTypes] = useState<IDispatchType[]>([]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (filterArea)
      api.get(`/dispatchTypes/area/${filterArea}`).then(response => {
        setDispatchTypes(response.data);
      });
  }, [filterArea]);

  const dispatchCard = async (card_id: string, phase_id: string) => {
    const dispatchingCard = toast.loading(`Trocando card de fase.`);
    document.body.style.cursor = 'progress';

    api
      .post(`/cards/${card_id}/dispatchTypes/${phase_id}`)
      .then(response => {
        const card = response?.data?.card;
        const triggers = response?.data?.triggers;
        const id = card?.id;
        const key_card = card?.key_card;
        const accountName = card?.account.name;
        const triggerDateTime = new Date();

        if (id && key_card && triggers && Object.keys(triggers).length > 0) {
          updateTriggers({
            card_id: id,
            key_card,
            triggers,
            accountName,
            triggerDateTime,
          });
        }

        toast.update(dispatchingCard, {
          render: `Card trocado de fase.`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
        document.body.style.cursor = 'auto';
      })
      .catch(error => {
        document.body.style.cursor = 'auto';
        toast.update(dispatchingCard, {
          render: `Ocorreu um erro ao trocar de fase. ${error.response.data.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        handleFilters({ refreshFilter: true });
      });
  };

  const handleOnDispatchCard = () => {
    handleFilters({ refreshFilter: true });
  };

  const onDrop = (card_id: string, phase_id: string) => {
    dispatchCard(card_id, phase_id);
  };

  const moveItem = async (card_id: string, dispatchType_id: string) => {
    dispatchCard(card_id, dispatchType_id);
  };

  useEffect(() => {
    if (phases) {
      let cardCounter = 0;
      phases.forEach(phase => {
        if (phase?.cards && phase.cards.length > cardCounter)
          cardCounter = phase.cards.length;
      });
      const columnHeight = cardCounter * 135 + 120;
      setHeight(columnHeight);
    }
  }, [phases]);

  return (
    <Box className={classes.boxColumns}>
      <Hidden smUp>
        <Grid container>
          {phases.map((phase, index) => (
            <Grid item xs={12} key={phase.id}>
              <Phase
                filterTotalizer={filterTotalizer}
                phase={phase}
                dispatchTypes={dispatchTypes.filter(
                  dispatchType => dispatchType.id !== phase.id,
                )}
                color={index % 1 === 0 ? '#212121' : ''}
                moveItem={moveItem}
                onDispatchCard={handleOnDispatchCard}
              />
            </Grid>
          ))}
        </Grid>
      </Hidden>

      <Hidden xsDown>
        {phases.map((phase, index) => (
          <div
            key={phase.id}
            className={classes.phaseColumn}
            style={{ height: `${height}px` }}
          >
            <DropWrapper onDrop={onDrop} phase_id={phase.id}>
              <Phase
                phase={phase}
                dispatchTypes={dispatchTypes.filter(
                  dispatchType => dispatchType.id !== phase.id,
                )}
                color={index % 1 === 0 ? '#212121' : ''}
                moveItem={moveItem}
                onDispatchCard={handleOnDispatchCard}
                filterTotalizer={filterTotalizer}
              />
            </DropWrapper>
          </div>
        ))}
      </Hidden>
    </Box>
  );
};

export default PanelCards;
