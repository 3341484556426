/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */

'use client';

import React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  getFacetedRowModel,
  Row,
} from '@tanstack/react-table';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { getFacetedUniqueValues } from '@tanstack/react-table';
import { isWithinRange } from 'src/utils/dateFormatAndComparisonUtils';
import { cn } from 'src/lib/utils';
import { DataTablePagination } from './data-table-pagination';
import { ColumnsToFilterType, DataTableToolbar } from './data-table-toolbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';
import { ScrollArea, ScrollBar } from './scroll-area';

declare module '@tanstack/react-table' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
    dateBetweenFilterFn: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  showTableToolbar?: boolean;
  pageSize?: number;
  showFilters?: boolean;
  columnsToFilter?: ColumnsToFilterType[];
  onClickRow?: (id: string) => void;
  showGlobalSearchInput?: boolean;
  showColumnVisibility?: boolean;
  handleClickAddNewButton?: () => void;
  initiallyInvisibleColumns?: string[];
  localStorageKey?: string;
  firstDateColumnId?: string;
  secondDateColumnId?: string;
  thirdDateColumnId?: string;
  fillEmptyRowsPerPage?: boolean;
  classNameTableDiv?: string;
  includeButtonText?: string;
  getRowStyles?: (row: Row<any>) => object | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export function DataTable<TData, TValue>({
  columns,
  data,
  showTableToolbar = false,
  onClickRow,
  pageSize,
  showGlobalSearchInput = false,
  showColumnVisibility = false,
  showFilters = false,
  columnsToFilter,
  handleClickAddNewButton,
  initiallyInvisibleColumns,
  localStorageKey,
  firstDateColumnId,
  secondDateColumnId,
  thirdDateColumnId,
  fillEmptyRowsPerPage = false,
  classNameTableDiv = '',
  includeButtonText,
  getRowStyles,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: pageSize || 10,
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      dateBetweenFilterFn: isWithinRange,
    },
    state: {
      sorting,
      pagination: pageSize ? pagination : undefined,
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    globalFilterFn: fuzzyFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: pageSize ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    /* initialState: {
      pagination: {
        pageIndex: 0,
        pageSize,
      },
    }, */
  });

  const handleRowClick = (rowData: TData) => {
    if (
      onClickRow &&
      rowData !== null &&
      typeof rowData === 'object' &&
      'id' in rowData
    ) {
      onClickRow(rowData?.id as string);
    }
  };

  const handleSetGlobalFilter = (value: string) => {
    setGlobalFilter(String(value));
  };

  return (
    <div className="space-y-4">
      {showTableToolbar && (
        <DataTableToolbar
          table={table}
          globalFilter={globalFilter ?? ''}
          setGlobalFilter={handleSetGlobalFilter}
          showGlobalSearchInput={showGlobalSearchInput}
          showColumnVisibility={showColumnVisibility}
          handleClickAddNewButton={handleClickAddNewButton}
          initiallyInvisibleColumns={initiallyInvisibleColumns || []}
          localStorageKey={localStorageKey || ''}
          showFilters={showFilters}
          columnsToFilter={columnsToFilter}
          firstDateColumnId={firstDateColumnId}
          secondDateColumnId={secondDateColumnId}
          thirdDateColumnId={thirdDateColumnId}
          includeButtonText={includeButtonText}
        />
      )}
      <div
        className={cn(
          'rounded-md border shadow overflow-auto box-border',
          classNameTableDiv,
        )}
      >
        <Table className="w-full h-auto">
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead
                      key={header.id}
                      className="px-2 py-0.5 h-fit w-fit font-semibold"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => handleRowClick(row.original)}
                  className={
                    onClickRow !== undefined ? 'cursor-pointer' : undefined
                  }
                  style={
                    typeof getRowStyles === 'function' ? getRowStyles(row) : {}
                  }
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell key={cell.id} className="py-0.5 px-2">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="text-center"
                  style={
                    fillEmptyRowsPerPage && pageSize
                      ? { height: `${pageSize * 2}rem` }
                      : undefined
                  }
                >
                  Sem resultados.
                </TableCell>
              </TableRow>
            )}
            {/* Fill empty spaces with multiple rows */}
            {fillEmptyRowsPerPage &&
              table.getRowModel().rows?.length > 0 &&
              pagination.pageSize &&
              [
                ...Array(
                  Math.max(
                    pagination.pageSize -
                      (table.getRowModel().rows?.length || 0),
                    0,
                  ),
                ),
              ].map((_, index) => (
                <TableRow key={`empty-row-${index}`}>
                  <TableCell colSpan={columns.length} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {pageSize && <DataTablePagination table={table} pageSize={pageSize} />}
    </div>
  );
}

export const MemoizedDataTable = React.memo(DataTable);
