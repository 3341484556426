/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { TextInput } from 'src/components/TaskInputFields/TextInput';
import { PredefinedField } from 'src/components/TaskInputFields/PredefinedField';
import { BooleanField } from 'src/components/TaskInputFields/BooleanField';
import { TimestampField } from 'src/components/TaskInputFields/TimestampField';
import { ModalIntegratedCustomFields } from 'src/components/ModalIntegratedCustomFields';
import { IntegratedField } from 'src/components/TaskInputFields/IntegratedField';
import { DocumentField } from 'src/components/TaskInputFields/DocumentField';
import { FileField } from 'src/components/TaskInputFields/FileField';
import { useFormikErrorContext } from 'src/context/FormikErrorProvider';
import {
  ITableProps,
  TableFieldModal,
} from 'src/components/TaskInputFields/TableFieldModal';
import { ICustomField } from 'src/interface/ICardFields';
import {
  IIntegratedModalData,
  ILinkedCardFieldValues,
  TaskLinkedCardFieldsProps,
} from '../TaskModal.i';

const ErrorText: JSX.Element = (
  <div>
    <span className="text-red-500 text-xs">Campo obrigatório</span>
  </div>
);

export const TaskLinkedCardFields: React.FC<TaskLinkedCardFieldsProps> = ({
  linkedCardFieldValues,
  handleChangeCardFieldValue,
  cardId,
  areaId,
  done,
  cardFieldsErrorList,
}) => {
  const { errors, hasError } = useFormikErrorContext();
  const [openModal, setOpenModal] = useState(false);
  const [integratedModalData, setIntegratedModalData] =
    useState<IIntegratedModalData | null>(null);
  const [tableModalData, setTableModalData] = useState<ITableProps | null>(
    null,
  );

  const handleOpenIntegratedFieldModal = (
    cardField: ILinkedCardFieldValues,
    table?: {
      id: string;
      tableRowIndex: number;
    },
  ) => {
    const newModalData = {
      cardField,
      cardFieldId: cardField.id,
      table,
    };
    setIntegratedModalData(newModalData);
    setOpenModal(true);
  };

  const handleFillIntegratedField = useCallback(
    (value: Record<string, unknown> | undefined, fieldId?: string) => {
      if (integratedModalData && integratedModalData.cardFieldId && !fieldId) {
        handleChangeCardFieldValue(
          integratedModalData.cardFieldId,
          value,
          'valueJSON',
        );
        setOpenModal(false);
      }

      if (fieldId) {
        handleChangeCardFieldValue(fieldId, value, 'valueJSON');
        setOpenModal(false);
      }
    },
    [
      integratedModalData,
      handleChangeCardFieldValue,
      setOpenModal,
      linkedCardFieldValues,
    ],
  );

  const handleFillFileField = useCallback(
    (
      value: {
        name: string;
        url: string;
        size: number;
      }[],
      fieldId?: string,
    ) => {
      if (fieldId) {
        handleChangeCardFieldValue(fieldId, value, 'valueJSON');
      }
    },
    [handleChangeCardFieldValue],
  );

  const handleCloseIntagratedModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenTableModal = (
    title: string,
    customFieldId: string,
    tableColumns: ICustomField[],
  ) => {
    setTableModalData({
      open: true,
      title,
      customFieldId,
      tableColumns,
    });
  };

  const handleCloseModalTable = () => {
    if (tableModalData && tableModalData !== null && tableModalData?.open) {
      setTableModalData(prevState => ({
        ...prevState!,
        open: false,
      }));
    } else {
      setTableModalData(null);
    }
  };

  function handleRenderCardFields(cardField: ILinkedCardFieldValues) {
    switch (cardField.type) {
      case 'BOOL':
        return (
          <Grid item xs={6} key={cardField.id}>
            <BooleanField
              fieldId={cardField.id}
              fieldName={cardField.name}
              fieldLabel={cardField.name}
              fieldValue={cardField?.value || ''}
              handleChangeValue={handleChangeCardFieldValue}
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
              valueProperty="value"
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      case 'STRING':
      case 'NUMERIC':
        return (
          <Grid
            item
            xs={cardField.isBigString === true ? 12 : 6}
            key={cardField.id}
          >
            <TextInput
              fieldId={cardField.id}
              fieldName={cardField.name}
              fieldLabel={cardField.name}
              fieldValue={cardField?.value || ''}
              type={cardField.type}
              valueProperty="value"
              margin="none"
              handleOnBlur={handleChangeCardFieldValue as any}
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
              isMultiline={cardField.isBigString}
              minRows={1}
              maxRows={cardField.isBigString ? 3 : 1}
              error={hasError(`linkedCardFieldValues.${cardField.name}`)}
              helperText={
                hasError(`linkedCardFieldValues.${cardField.name}`)
                  ? errors[`linkedCardFieldValues.${cardField.name}`]
                  : ''
              }
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      case 'TIMESTAMP':
        return (
          <Grid item xs={6} key={cardField.id}>
            <TimestampField
              fieldId={cardField.id}
              fieldName={cardField.name}
              fieldLabel={cardField.name}
              fieldValue={cardField?.value || ''}
              dateType={cardField.dateType as string}
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
              margin="none"
              valueProperty="value"
              handleChangeValue={handleChangeCardFieldValue}
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      case 'PREDEFINED_STRINGS':
        if (!cardField.predefinedValues) return null;
        return (
          <Grid item xs={6} key={cardField.id}>
            <PredefinedField
              fieldId={cardField.id}
              fieldName={cardField.name}
              fieldValue={cardField?.valueJSON || []}
              fieldOptions={cardField.predefinedValues}
              valueProperty="valueJSON"
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
              margin="none"
              handleChangeValue={handleChangeCardFieldValue}
              isMultipleOptions
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      case 'INTEGRATED':
        return (
          <Grid item xs={6} key={cardField.id}>
            <IntegratedField
              customField={cardField}
              handleOpenModal={handleOpenIntegratedFieldModal as any}
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
              handleFillIntegratedField={handleFillIntegratedField}
              selectedIntegratedItem={cardField?.valueJSON || {}}
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      case 'TABLE':
        return (
          <Grid item xs={6} key={cardField.id}>
            <div style={{ marginBottom: 0 }}>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={() =>
                  handleOpenTableModal(
                    cardField.name,
                    cardField.id,
                    cardField.tableColumns,
                  )
                }
              >
                {`${cardField.name} ${cardField.isRequired ? '*' : ''}`}
              </Button>
              {cardFieldsErrorList[cardField.id] && ErrorText}
            </div>
          </Grid>
        );

      case 'DOCUMENT':
        return (
          <Grid item xs={6} key={cardField.id}>
            <DocumentField
              customField={cardField as any}
              card_id={cardId}
              area_id={areaId}
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
              handleFillCardField={handleChangeCardFieldValue as any}
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      case 'FILE':
        return (
          <Grid item xs={6} key={cardField.id}>
            <FileField
              customField={cardField as any}
              card_id={cardId}
              fieldValueJson={cardField?.valueJSON || []}
              handleFillCardField={handleFillFileField}
              isDisabled={done || !cardField.isEditable}
              isRequired={cardField.isRequired || cardField.isApprovalCriterion}
            />
            {cardFieldsErrorList[cardField.id] && ErrorText}
          </Grid>
        );

      default:
        return <Typography>Campo personalizado não definido</Typography>;
    }
  }

  return (
    <>
      {linkedCardFieldValues.length > 0 &&
        linkedCardFieldValues
          .sort((a, b) => {
            if (a.isApprovalCriterion !== b.isApprovalCriterion) {
              return a.isApprovalCriterion ? 1 : -1;
            }

            if (a.phase.order !== b.phase.order) {
              return a.phase.order - b.phase.order;
            }
            return a.phaseOrder - b.phaseOrder;
          })
          .map(field => handleRenderCardFields(field))}
      {openModal && integratedModalData && (
        <ModalIntegratedCustomFields
          openModal={openModal}
          handleCloseModal={handleCloseIntagratedModal}
          onSelectedIntegratedItem={handleFillIntegratedField}
          customFields={[]}
          taskLinkedCardFields={linkedCardFieldValues}
          customField_id={integratedModalData.cardField.id}
          customField_name={integratedModalData.cardField.name}
          format={integratedModalData.cardField.format}
          dynamicFormat={integratedModalData.cardField.dynamicFormat}
          key={integratedModalData.cardField.id}
          primaryKeyColumnName={
            integratedModalData.cardField.primaryKeyColumnName
          }
        />
      )}
      {tableModalData && tableModalData.open && (
        <TableFieldModal
          open={tableModalData.open}
          closeModal={handleCloseModalTable}
          title={tableModalData.title}
          customFieldId={tableModalData.customFieldId}
          tableColumns={tableModalData.tableColumns}
          handleFillCustomField={handleChangeCardFieldValue}
          tableValueJSON={
            linkedCardFieldValues[
              linkedCardFieldValues.findIndex(
                i => i.id === tableModalData.customFieldId,
              )
            ]?.valueJSON || []
          }
          done={done}
        />
      )}
    </>
  );
};
