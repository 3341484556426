/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ConditionsExpression,
  IResponseFillRuleCondition,
} from 'src/components/Conditions/Condition.i';
import { IActionTypeField } from '../Trigger.i';

export const actionTypes: IActionTypeField[] = [
  {
    id: 'alert',
    label: 'Alerta',
    isDispatch: false,
  },
  {
    id: 'create_single_task',
    label: 'Criar tarefa avulsa',
    isDispatch: false,
  },
  {
    id: 'email',
    label: 'Enviar e-mail',
    isDispatch: false,
  },
  {
    id: 'suspend',
    label: 'Suspender',
    isDispatch: true,
  },
  {
    id: 'finish',
    label: 'Finalizar',
    isDispatch: true,
  },
  {
    id: 'user',
    label: 'Trocar usuário',
    isDispatch: true,
  },
  {
    id: 'area',
    label: 'Trocar área',
    isDispatch: true,
  },
  {
    id: 'phase',
    label: 'Trocar fase',
    isDispatch: true,
  },
  {
    id: 'subordinate',
    label: 'Criar subordinado',
    isDispatch: true,
  },
];

export function handleFormatTriggerResponseExpression(
  expression: ConditionsExpression,
  conditions: IResponseFillRuleCondition[],
  setStateFunction: (value: ConditionsExpression) => void,
): void {
  if (conditions.length > 0 && expression.length > 0) {
    const formattedExp = expression.map((exp: any) => {
      if (Array.isArray(exp)) {
        const itemArray = exp.map(item => {
          if (item !== 'OR' && item !== 'AND') {
            const condition = conditions.find(i => i?.id === item);

            condition !== undefined &&
              Object.keys(condition).forEach((key: string) => {
                if (condition[key] === null || key === 'id') {
                  delete condition[key];
                }
                if (
                  key === 'progressPercentage' &&
                  condition[key] !== null &&
                  (typeof condition[key] === 'string' ||
                    typeof condition[key] === 'number')
                ) {
                  condition[key] = Number(condition[key]);
                }
              });

            return condition;
          }
          return item;
        });
        return itemArray;
      }

      return exp;
    });

    setStateFunction(formattedExp as ConditionsExpression);
  }
}
