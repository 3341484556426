import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { booleanStyles } from './booleanStyles';
import { useCardDataContext } from '../../../../../context/CardDataContext';
import { IDefaultFieldProps } from '../../../Card.i';

const useStyles = makeStyles(() => createStyles(booleanStyles()));

export const BooleanField: React.FC<IDefaultFieldProps> = ({
  customField,
  customFieldIndex,
  cardCustomFieldPhaseId,
  phaseIndex,
  handleFillTableValue,
  tableColumn,
  isInCurrentCardPhase,
}: IDefaultFieldProps) => {
  const classes = useStyles();
  const { handleFillCustomField, cardCurrentPhase } = useCardDataContext();

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    const newValue =
      customField.value || (tableColumn && tableColumn.value) || '';
    if (newValue !== inputValue) setInputValue(newValue as string);
  }, [customField.value, tableColumn?.value]);

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);

      if (tableColumn && handleFillTableValue) {
        handleFillTableValue(customField.id, tableColumn.tableRowIndex, value);
      } else {
        handleFillCustomField(
          phaseIndex,
          customFieldIndex,
          value,
          undefined,
          customField.id,
        );
      }
    },
    [
      customField.id,
      tableColumn,
      handleFillTableValue,
      handleFillCustomField,
      customFieldIndex,
      phaseIndex,
    ],
  );

  return (
    <Box className={`${classes.customField} ${classes.boolField}`}>
      {!tableColumn && !handleFillTableValue && (
        <Box className={classes.boolFieldTitle}>
          <Typography>{customField.name}</Typography>
        </Box>
      )}
      <Box className={classes.boolFieldOptions}>
        <Box className={classes.boolFieldOption}>
          <Checkbox
            className={classes.checkBox}
            checked={inputValue === 'true'}
            required={
              customField.isRequired &&
              inputValue !== 'false' &&
              (cardCurrentPhase?.id === cardCustomFieldPhaseId ||
                isInCurrentCardPhase)
            }
            disabled={customField.isInactive || customField.isDisabled}
            onChange={() => {
              handleInputChange('true');
            }}
          />
          <Typography>Sim</Typography>
        </Box>
        <Box className={classes.boolFieldOption}>
          <Checkbox
            className={classes.checkBox}
            checked={inputValue === 'false'}
            required={
              customField.isRequired &&
              inputValue !== 'true' &&
              (cardCurrentPhase?.id === cardCustomFieldPhaseId ||
                isInCurrentCardPhase)
            }
            disabled={customField.isInactive || customField.isDisabled}
            onChange={() => {
              handleInputChange('false');
            }}
          />
          <Typography>Não</Typography>
        </Box>
      </Box>
    </Box>
  );
};
