import React from 'react';
import {
  FileSpreadsheetIcon,
  MoreHorizontalIcon,
  PlusCircleIcon,
  SparklesIcon,
} from 'lucide-react';
import { Button } from 'src/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { useAuth } from 'src/context/AuthContext';
import { useHistory } from 'react-router-dom';
import { Vision } from '..';
import { VisionDeletionDialog } from '../components/VisionDeletionDialog';
import { useVisionList } from '../hooks/useVisionList';
import { VisionAIDialog } from '../components/VisionAIDialog';
import { VisionReportDialog } from '../components/VisionReportDialog';

export const ListVisions = () => {
  const { company } = useAuth();
  const history = useHistory();
  const envAllowedCompanies = process.env?.REACT_APP_ALLOWED_COMPANY_IDS || '';
  const allowedCompanies: string[] =
    envAllowedCompanies.length > 0 ? envAllowedCompanies.split(',') : [];

  if (
    allowedCompanies.length > 0 &&
    company.id &&
    !allowedCompanies.includes(company.id)
  ) {
    history.push('/workspace');
  }

  const {
    isVisionDialogOpen,
    selectedVisionId,
    visions,
    search,
    deleteAlertState,
    executeVision,
    handleDeleteVision,
    handleOpenVisionDialog,
    handleOpenDeleteDialog,
    handleCloseDeletionDialog,
    handleCloseVisionDialog,
    handleFilterVisions,
    handleExecuteVision,
    handleCloseExecutionDialog,
  } = useVisionList();

  const filteredVisions =
    search.length > 0
      ? visions.filter(vision => {
          const formattedSearch = search.toUpperCase().trim();
          return (
            vision.name.toUpperCase().trim().indexOf(formattedSearch) >= 0 ||
            vision.description.toUpperCase().trim().indexOf(formattedSearch) >=
              0
          );
        })
      : visions;

  return (
    <div className="w-full h-full px-6 overflow-auto">
      <div className="space-y-1 my-6">
        <div className="flex flex-row items-center rounded-md mb-2 box-border">
          <h1 className="text-2xl font-bold leading-none mr-2">Visões</h1>
          <TooltipWrapper
            value="Nova visão"
            side="right"
            className="text-sm font-medium"
          >
            <Button
              onClick={() => {
                handleOpenVisionDialog();
              }}
              className="flex gap-1 items-center w-fit h-fit p-2 shadow-lg hover:shadow-md"
            >
              <PlusCircleIcon size={16} />
              Criar
            </Button>
          </TooltipWrapper>
        </div>
        <p className="text-sm text-muted-foreground">
          Cadastre visões que serão utilizadas para análise e extração de dados
          do processo BPM
        </p>
      </div>
      <div className="flex flex-col grow mb-4 ml-0.5">
        <Label htmlFor="name">Pesquisa</Label>
        <Input
          id="search"
          name="search"
          required
          className="mt-2 border border-gray-300 rounded-md w-[400px] max-[768px]:w-full"
          placeholder="Pesquise por nome ou descrição"
          onChange={handleFilterVisions}
          autoComplete="off"
        />
      </div>
      {filteredVisions.length > 0 && (
        <Card className="flex flex-row flex-wrap justify-start max-xl:justify-around gap-4 w-full h-fit max-w-[1200px] p-3">
          {filteredVisions.map(vision => (
            <Card
              key={vision.id}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleExecuteVision(vision.id as string, vision.type);
              }}
              className="overflow-hidden h-[100px] w-[380px] max-[768px]:w-full max-lg:w-[340px] bg-BPM_BGCOLORMENU shadow-zinc-600 border cursor-pointer px-2 pb-1 hover:shadow-zinc-500 hover:border-2 hover:border-primary"
            >
              <CardHeader className="p-0">
                <CardTitle className="flex flex-row justify-between items-center w-full">
                  <span className="text-base truncate mr-2">{vision.name}</span>
                  <div className="w-fit p-0">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          className="p-2 m-0 rounded-full w-fit h-fit"
                        >
                          <span className="sr-only">Open menu</span>
                          <MoreHorizontalIcon className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="start">
                        <DropdownMenuLabel>Ações</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleOpenVisionDialog(vision.id);
                          }}
                        >
                          Editar
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenDeleteDialog(vision.id as string);
                          }}
                        >
                          Excluir
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardContent className="flex gap-2 rounded-md pb-2 w-full items-center">
                {vision.type === 'ANALYSIS' ? (
                  <SparklesIcon
                    size="30"
                    className="min-w-7 min-h-7 self-start text-BPM_GREY"
                    strokeWidth={1.5}
                  />
                ) : (
                  <FileSpreadsheetIcon
                    size="30"
                    className="min-w-7 min-h-7 self-start text-BPM_GREY"
                    strokeWidth={1.5}
                  />
                )}
                <p className="text-sm text-muted-foreground text-ellipsis line-clamp-2">
                  {vision.description}
                </p>
              </CardContent>
            </Card>
          ))}
        </Card>
      )}
      {isVisionDialogOpen && (
        <Vision
          open={isVisionDialogOpen}
          vision_id={selectedVisionId}
          handleCloseDialog={handleCloseVisionDialog}
        />
      )}
      {deleteAlertState.isOpen && deleteAlertState.visionId && (
        <VisionDeletionDialog
          open={deleteAlertState.isOpen}
          setOpen={handleCloseDeletionDialog}
          visionId={deleteAlertState.visionId}
          handleDeleteVision={handleDeleteVision}
        />
      )}
      {executeVision.isOpen &&
        executeVision.visionId &&
        executeVision.visionType === 'ANALYSIS' && (
          <VisionAIDialog
            open={executeVision.isOpen}
            handleCloseDialog={handleCloseExecutionDialog}
            vision_id={executeVision.visionId}
            visionName={executeVision.visionName}
          />
        )}
      {executeVision.isOpen &&
        executeVision.visionId &&
        executeVision.visionType === 'REPORT' && (
          <VisionReportDialog
            open={executeVision.isOpen}
            handleCloseDialog={handleCloseExecutionDialog}
            vision_id={executeVision.visionId}
            visionName={executeVision.visionName}
          />
        )}
    </div>
  );
};
