import { StyleRules } from '@material-ui/core';
import { COLORS } from '../../../styles/colors';

export const styles = (): StyleRules => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  content: {
    width: '100%',
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    },
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  visible: {
    opacity: 1,
  },
  loadingMessage: {
    opacity: 1,
    transition: 'opacity 0.5s ease-out 0.5s', // Add a 0.5s delay
  },
  customFieldPhases: {
    opacity: 1,
    transition: 'opacity 1s ease-in',
  },
  checkBox: {
    alignItems: 'start',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
  phaseName: {
    flex: 1,
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  customFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5rem',
  },
  customFieldsTable: {
    marginTop: '-157px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5rem',
  },
  titleTable: {
    marginLeft: '182px',
    fontWeight: 700,
  },
  tooltip: {
    fontSize: '14px',
  },
  textField: {
    order: 2,
    '&:last-child': {
      marginBottom: '1.5rem',
    },
  },
  tableTextField: {
    // order: 2,
  },
  isBigStringDiv: {
    display: 'grid',
    order: 6,
  },
  isBigStringContent: {
    marginTop: '1.5rem',
    '&:last-child': {
      marginBottom: '1.5rem',
    },
  },
  msg: {
    marginTop: '10px',
    fontWeight: 500,
  },
  customField: {
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  dateTimeDiv: {
    order: 3,
  },
  dateTimeContent: {
    height: '48px',
    '&:last-child': {
      marginBottom: '1.5rem',
    },
    '&& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  dateTimeNoContent: {
    height: '48px',
    '&:last-child': {
      marginBottom: '1.5rem',
    },
    '&& .MuiInputLabel-shrink': {
      color: '#959595',
      fontWeight: 500,
      '& fieldset': {
        borderColor: '#959595',
      },
    },
  },
  dateTimeSelected: {
    height: '48px',
    '&:last-child': {
      marginBottom: '1.5rem',
    },
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
    },
    '& fieldset': {
      borderColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  predefinedDiv: {
    order: 4,
  },
  predefinedContent: {
    '&:last-child': {
      marginBottom: '1.5rem',
    },
  },
  typeIntegrated: {
    order: 5,
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
    marginTop: '1.5rem',
    justifyContent: 'space-between',
    borderBottom: '1.6px solid #999999',
  },
  typeIntegratedButton: {
    width: '2rem',
    height: '2.5rem',
  },
  customFieldIntegrationTypeContainer: {
    color: COLORS.BLACK,
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    height: '2.5rem',
  },
  integratedFieldPrimaryKey: {
    color: COLORS.BLUE,
    marginRight: '0.2rem',
  },
  integratedFieldDisplayColumnName: {
    // maxWidth: '12rem',
    // width: 'auto',
    // overflow: 'hidden',
  },
  customFieldIntegrationTypeName: {
    marginTop: '12px',
    color: '#6d6d6d',
  },
  integratedFieldButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },

  modalHeader: {
    width: '100%',
    marginBottom: '0.75rem',
    padding: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    top: '0',
  },
  input: {
    marginLeft: '6px',
    width: '300px',

    '&:nth-child(1)': {
      // width: '70%',
    },
  },
  headerButtons: {
    marginTop: '11px',
    marginLeft: '196px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.75rem',
  },
  tableFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    width: '100%',
    height: '1rem',
    bottom: '0',
  },
  buttonSubmit: {
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
    marginTop: '10px',
    // marginRight: theme.spacing(2),
  },
  integrationTypeRow: {
    cursor: 'pointer',
    '&:hover :not($integrationTypeSelected)': {
      backgroundColor: COLORS.BLUE100,
    },
  },
  integrationTypeSelected: {
    backgroundColor: COLORS.BLUE,
    '& td, & th': {
      color: '#FFF',
      fontWeigth: 'bold',
    },
  },

  tableContent: {
    width: '100%',
    marginBottom: '1rem',
  },
  tableContainer: {
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  table: {
    minWidth: '300px',
    maxWidth: '100%',
    '& .MuiTableCell-sizeSmall': {
      padding: '0.1rem 0.5rem',
      // fontSize: '0.5rem',
    },
  },
  tableRowSelected: {
    cursor: 'pointer',
  },
  tableInputContainer: {
    // marginTop: '2rem',
  },
  tableInputContainerTitle: {
    fontWeight: 'bold',
    color: COLORS.BLUE,
  },
  addButton: {
    paddingLeft: '0.7rem',
    paddingRight: '0.75rem',
  },
  /* notIngratedField: {
    width: 'auto',
    overflow: 'hidden',
    '&& .MuiInputBase-input': {
      fontSize: '14px',
    },
  }, */
  deleteIconColumn: {
    // maxWidth: '50px',
    width: '50px',
  },
  integratedFieldDummyTextField: {
    caretColor: 'transparent',
    width: '100%',
    '&& .MuiFormLabel-root': {
      color: '#959595',
    },
  },
  integratedLabel: {
    fontSize: '12px',
    fontWeight: 500,
    color: COLORS.BLUE,
  },
});
