/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FormEvent, useEffect, useState } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import api from '../../services/api';
import Button from '../../components/Button';
import {
  IArea,
  IPhase,
  IIntegrationRuleParams,
  IIntegrationRuleProps,
  IFieldAssociation,
  IIntegrationType,
  IIntegrationTypeField,
  IIntegrationFieldValue,
  IIntegrationRuleMoment,
  IIntegrationRule,
  KeyValue,
  DynamicColumnFormat,
} from './IntegrationRules.i';
import { styles } from './styles';
import IntegrationTypeFields from './IntegrationTypeFields';
import IntegrationMoments from './IntegrationMoments';
import { DynamicFieldAssociations } from './DynamicFieldAssociations';
import { DynamicReturnFormat } from './DynamicReturnFormat';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const IntegrationRule: React.FC<IIntegrationRuleProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeModal = (value: boolean) => null,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<IIntegrationRuleParams>();
  const { integrationRule_id } = params;

  const goBack = () => {
    if (!integrationRule_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  const [areas, setAreas] = useState<IArea[]>([]);
  const [area, setArea] = useState<IArea>({} as IArea);
  const [areaRequired, setAreaRequired] = useState(false);
  const [loadingArea, setLoadingArea] = useState(false);
  const [loadingPhase, setLoadingPhase] = useState(false);
  const [filterAccountFieldOnly, setFilterAccountFieldOnly] = useState(false);
  const [areaPhases, setAreaPhases] = useState<IPhase[]>();
  const [integrationType, setIntegrationType] = useState<IIntegrationType>(
    {} as IIntegrationType,
  );
  const [activeMoments, setActiveMoments] = useState<IIntegrationRuleMoment[]>(
    [],
  );
  const [integrationMoments, setIntegrationMoments] = useState<
    IIntegrationRuleMoment[]
  >([]);
  const [integrationRule, setIntegrationRule] = useState<IIntegrationRule>();
  const [integrationFieldValues, setIntegrationFieldValues] = useState<
    IIntegrationFieldValue[]
  >([]);
  const [integrationTypeFields, setIntegrationTypeFields] = useState<
    IIntegrationTypeField[]
  >([]);
  const [integrationTypes, setIntegrationTypes] = useState<IIntegrationType[]>(
    [],
  );
  const [accountDefaultFields, setAccountDefaultFields] = useState<
    IFieldAssociation[]
  >([]);
  const [accountCustomFields, setAccountCustomFields] = useState<
    IFieldAssociation[]
  >([]);
  const [customFields, setCustomFields] = useState<IFieldAssociation[]>([]);
  const [loadingCustomFields, setLoadingCustomFields] = useState(false);
  const [associatedFieldOptions, setAssociatedFieldOptions] = useState<
    IFieldAssociation[]
  >([]);

  const showToastError = (message: string) => {
    toast.error(`${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
      autoClose: 4000,
      closeOnClick: true,
    });
  };

  const removeCardFieldFromAssociation = () => {
    const hasCardFieldValue = integrationFieldValues.find(
      iv => iv.isCustomField === true,
    );
    if (hasCardFieldValue) {
      const removeValuesWithCardField = integrationFieldValues.filter(
        fieldValue => fieldValue.isCustomField !== true,
      );
      setIntegrationFieldValues(removeValuesWithCardField);
      toast.warning(
        `Obs.: Associações com campos personalizados de card foram removidas. Revisar campos associados.`,
        {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 4000,
          closeOnClick: true,
        },
      );
    }
  };

  // load areas, integration types and defaultAccountFields
  useEffect(() => {
    setLoadingArea(true);
    const areasPromise = api
      .get('/areas')
      .then(response => setAreas(response.data))
      .catch((err: AxiosError) => {
        showToastError(`Falha ao carregar áreas. ${err?.message || ''}`);
      })
      .finally(() => setLoadingArea(false));

    const integrationTypesPromise = api
      .get('/integrationtypes')
      .then(response => {
        setIntegrationTypes(response.data);
      })
      .catch((err: AxiosError) => {
        showToastError(
          `Falha ao carregar tipos de integração. ${err?.message || ''}`,
        );
      });

    const defaultAccountFieldsPromise = api
      .get('/integrationfieldsassociations')
      .then(response => {
        const data = response.data.map((field: IFieldAssociation) => {
          const { id, name } = field;

          return {
            id,
            name,
            type: '',
            fieldType: 'defaultField',
            tableName: '',
            tableId: '',
            phase: 'Conta',
          };
        });
        setAccountDefaultFields(data);
      })
      .catch((err: AxiosError) => {
        showToastError(
          `Falha ao carregar campos padrão de conta. ${err?.message || ''}`,
        );
      });

    const accountCustomFieldsPromise = api
      .get('/accountFields')
      .then(response => {
        if (response.data && response.data.length > 0) {
          const data = response.data.map((field: IFieldAssociation) => {
            const { id, name, type } = field;
            return {
              id,
              name,
              type,
              fieldType: 'accountField',
              tableName: '',
              tableId: '',
              phase: 'Conta - Personalizado',
            };
          });
          setAccountCustomFields(data);
        }
      })
      .catch((err: AxiosError) => {
        showToastError(
          `Falha ao carregar campos personalizados de conta. ${
            err?.message || ''
          }`,
        );
      });

    Promise.allSettled([
      areasPromise,
      integrationTypesPromise,
      defaultAccountFieldsPromise,
      accountCustomFieldsPromise,
    ]);
  }, []);

  // load integrationrule
  useEffect(() => {
    if (integrationRule_id) {
      api
        .get(`/integrationsrules/${integrationRule_id}`)
        .then(response => {
          const respIntegrationRule = response.data;
          if (respIntegrationRule) {
            setIntegrationRule(respIntegrationRule);
            setAreaRequired(respIntegrationRule.integrationType.areaRequired);
            if (respIntegrationRule.area !== undefined) {
              setArea(respIntegrationRule.area);
            }
            setIntegrationType(respIntegrationRule.integrationType);
            setActiveMoments(respIntegrationRule.activeMoments);

            const intTypeMoments =
              respIntegrationRule.integrationType.integrationMoments;
            const tempActiveMoments = respIntegrationRule.activeMoments;
            const hasNoPhaseMoment = intTypeMoments.find(
              (it: { id: string; hasPhase: boolean }) =>
                it.hasPhase === false &&
                !!tempActiveMoments.find(
                  (am: { id: string }) => it.id === am.id,
                ),
            );
            if (hasNoPhaseMoment) {
              setFilterAccountFieldOnly(true);
            }

            const fieldsValues = respIntegrationRule.integrationFieldValues.map(
              (field: IIntegrationFieldValue) => {
                const {
                  integrationTypeFieldId,
                  fieldId,
                  fixedValue,
                  isCustomField,
                  isAccountField,
                  integratedColumnName,
                } = field;

                if (integratedColumnName && integratedColumnName.length > 0) {
                  return {
                    integrationTypeFieldId,
                    fieldId: fieldId || '',
                    fixedValue: fixedValue || '',
                    isCustomField,
                    integratedColumnName,
                  };
                }

                return {
                  integrationTypeFieldId,
                  fieldId: fieldId || '',
                  fixedValue: fixedValue || '',
                  isCustomField,
                  isAccountField,
                };
              },
            );
            setIntegrationFieldValues(fieldsValues);
          }
        })
        .catch((err: AxiosError) => {
          showToastError(
            `Falha ao carregar regra de integração. ${err?.message || ''}`,
          );
        });
    }
  }, [integrationRule_id]);

  // load customFields
  useEffect(() => {
    if (area?.id && integrationType?.allowAssociatedCustomFields === true) {
      setLoadingCustomFields(true);
      api
        .get(`/areas/${area.id}/customFields`)
        .then(response => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data = response.data
            .filter(
              (field: any) =>
                field.type !== 'FILE' && field.type !== 'DOCUMENT',
            )
            .map((field: any) => {
              const { id, name, type, table, phase } = field;

              return {
                id,
                name,
                type,
                fieldType: 'customField',
                tableName: table?.name ? table.name : '',
                tableId: table?.id ? table.id : '',
                phase: phase.name,
              };
            });
          setCustomFields(data);
        })
        .catch((err: AxiosError) => {
          showToastError(
            `Falha ao carregar campos personalizados. ${err?.message || ''}`,
          );
        })
        .finally(() => {
          setLoadingCustomFields(false);
        });
    }
  }, [area, integrationType]);

  // concat customFields and accountDefaultFields
  useEffect(() => {
    if (filterAccountFieldOnly === true) {
      const concatArrays = [...accountDefaultFields, ...accountCustomFields];
      setAssociatedFieldOptions(concatArrays);
    } else {
      const concatArrays = [
        ...accountDefaultFields,
        ...accountCustomFields,
        ...customFields,
      ];
      setAssociatedFieldOptions(concatArrays);
    }
  }, [
    accountDefaultFields,
    customFields,
    accountCustomFields,
    filterAccountFieldOnly,
  ]);

  // remove associated cardField if there are active moment without phase
  useEffect(() => {
    if (integrationType && activeMoments.length > 0) {
      const intTypeMoments = integrationType.integrationMoments;
      const hasNoPhase = intTypeMoments.find(
        (it: { id: string; hasPhase: boolean }) =>
          it.hasPhase === false &&
          !!activeMoments.find((am: { id: string }) => it.id === am.id),
      );
      if (hasNoPhase) {
        if (integrationFieldValues && integrationFieldValues.length > 0) {
          removeCardFieldFromAssociation();
        }
        setFilterAccountFieldOnly(true);
      } else {
        setFilterAccountFieldOnly(false);
      }
    } else {
      setFilterAccountFieldOnly(false);
    }
  }, [activeMoments, integrationType]);

  // load phases from selected area
  useEffect(() => {
    let isApiLoading = true;

    if (!areaRequired) {
      setAreaPhases([]);
    }

    if (isApiLoading && areaRequired && area.id) {
      setLoadingPhase(true);

      api
        .get(`areas/${area.id}/phases`)
        .then(response => {
          setAreaPhases(response.data);
        })
        .catch((err: AxiosError) => {
          showToastError(
            `Falha ao carregar fases da área. ${err?.message || ''}`,
          );
        })
        .finally(() => {
          setLoadingPhase(false);
        });
    }
    isApiLoading = false;
  }, [areaRequired, area]);

  // load integrationType fields
  useEffect(() => {
    if (integrationType === undefined) {
      setIntegrationTypeFields([]);
      return;
    }

    if (integrationType.id) {
      api
        .get(`/integrationtypefields/integrationType/${integrationType.id}`)
        .then(response => {
          const typeFieldArray: IIntegrationTypeField[] = [];
          response.data.forEach((typeField: IIntegrationTypeField) => {
            const {
              id,
              name,
              isRequired,
              table,
              isTable,
              requiresFullIntegratedItem,
              parentName,
            } = typeField;

            if (!isTable && !table) {
              typeFieldArray.unshift({
                id,
                name,
                isRequired,
                isTable,
                tableName: '',
                tableId: '',
                requiresFullIntegratedItem,
                parentName: parentName || '',
              });
            }

            if (isTable) {
              typeFieldArray.push({
                id,
                name,
                isRequired,
                isTable,
                tableName: '',
                tableId: '',
                requiresFullIntegratedItem,
                parentName: parentName || '',
              } as IIntegrationTypeField);

              response.data
                .filter(
                  (field: IIntegrationTypeField) =>
                    id === field.table?.id && !field.isTable,
                )
                .forEach((filtered: IIntegrationTypeField) => {
                  typeFieldArray.push({
                    id: filtered.id,
                    name: filtered.name,
                    isRequired: filtered.isRequired,
                    isTable: filtered.isTable,
                    tableName: filtered.table?.name || '',
                    tableId: filtered.table?.id || '',
                    requiresFullIntegratedItem:
                      filtered.requiresFullIntegratedItem,
                    parentName: filtered.parentName || '',
                  });
                });
            }
          });
          setIntegrationTypeFields(typeFieldArray);
          // return typeFieldArray;
        })
        .catch((err: AxiosError) => {
          showToastError(
            `Falha ao carregar campos do tipo de integração. ${
              err?.message || ''
            }`,
          );
        });
    }
  }, [integrationType]);

  // format moments adding phases from the current area
  useEffect(() => {
    if (integrationType.id) {
      const integrationTypeFind: IIntegrationType =
        integrationTypes[
          integrationTypes.findIndex(i => i.id === integrationType.id)
        ];
      if (
        integrationTypeFind &&
        integrationTypeFind?.integrationMoments &&
        integrationTypeFind?.integrationMoments.length > 0
      ) {
        const newIntegrationMoments =
          integrationTypeFind.integrationMoments.map(
            (moment: IIntegrationRuleMoment) => {
              const activeMoment = activeMoments.find(f => f.id === moment.id);
              if (activeMoment) {
                moment.isChecked = true;
                if (activeMoment.phase_id && areaPhases) {
                  const filteredPhase =
                    areaPhases.find(
                      phase => phase.id === activeMoment.phase_id,
                    ) || null;
                  moment.phase = filteredPhase;
                  moment.phases = areaPhases;
                }
              }
              return moment;
            },
          );
        setIntegrationMoments(newIntegrationMoments);
      } else {
        setIntegrationMoments([]);
      }
    }
  }, [activeMoments, areaPhases, integrationType, integrationTypes]);

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Valida os Integration Moments
    const invalidMoments: IIntegrationRuleMoment[] = [];
    const newIntegrationMoments: IIntegrationRuleMoment[] = [
      ...integrationMoments,
    ];

    for (let i = 0; i < integrationMoments.length; i++) {
      const moment = integrationMoments[i];
      if (moment.hasPhase && moment.isChecked && !moment.phase) {
        moment.error = true;
        newIntegrationMoments[i] = moment;
        invalidMoments.push(moment);
      }
    }

    if (invalidMoments.length === 0) {
      setIntegrationMoments(newIntegrationMoments);
    }

    formik.handleSubmit(event);
  };

  const initialValues: IIntegrationRule = {
    id: '',
    area: {
      id: '',
      name: '',
    },
    area_id: '',
    description: integrationRule ? integrationRule.description : '',
    base: integrationRule ? integrationRule.base : '',
    urlBase: integrationRule ? integrationRule.urlBase : '',
    user: integrationRule ? integrationRule.user : '',
    password: integrationRule ? integrationRule.password : '',
    isActive: integrationRule ? integrationRule.isActive : false,
    integrationType_id: integrationRule
      ? integrationRule.integrationType.id
      : '',
    integrationType: {
      id: '',
      integrationMoments: [],
      name: '',
      areaRequired: false,
      allowAssociatedCustomFields: false,
    },
    integrationFieldValues: integrationRule ? integrationFieldValues : [],
    activeMoments: integrationRule ? integrationRule.activeMoments : [],
    dynamicFieldAssociations: integrationRule?.dynamicFieldAssociations || {},
    dynamicFormat: integrationRule?.dynamicFormat || {},
  };

  const formSchema = Yup.object().shape({
    description: Yup.string()
      .min(5, `A descrição tem que conter pelo menos 5 caracteres`)
      .required('Descrição é obrigatório'),
    area_id:
      areaRequired && !area?.id
        ? Yup.string().required('Área obrigatória para o tipo selecionado')
        : Yup.string().optional(),
    urlBase: Yup.string()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^http(s)?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?$/,
        'Url Base é obrigatório',
      )
      .required('Url Base é obrigatório'),
    base: Yup.string().required('Base é obrigatório'),
    user: Yup.string().required('Usuário é obrigatório'),
    password: Yup.string().required('Senha é obrigatório'),
    isActive: Yup.string().optional().nullable(),
    integrationType_id: integrationType?.id
      ? Yup.string()
      : Yup.string().required('Tipo de integração é obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async values => {
      try {
        // Verifica se há erros no integratioMoments
        for (let i = 0; i < integrationMoments.length; i++) {
          const moment = integrationMoments[i];
          if (moment.hasPhase && moment.isChecked && !moment.phase)
            throw new Error('Verifique os campos obrigatórios');
        }

        // Formata os activeMoments
        const newActiveMoments = [];
        for (let i = 0; i < activeMoments.length; i++) {
          const activeMoment: { id?: string; phase_id?: string } = {};
          activeMoment.id = activeMoments[i].id;
          if (activeMoments[i].phase_id) {
            activeMoment.phase_id = activeMoments[i].phase_id;
          }
          newActiveMoments.push(activeMoment);
        }

        const newIntegrationFieldValues = integrationFieldValues.map(
          integrationField => {
            const {
              integrationTypeFieldId,
              fieldId,
              fixedValue,
              isCustomField,
              isAccountField,
              integratedColumnName,
            } = integrationField;

            if (
              fieldId &&
              integratedColumnName &&
              integratedColumnName.length > 0
            ) {
              return {
                integrationTypeFieldId,
                fieldId: fieldId || '',
                fixedValue: fixedValue || '',
                isCustomField,
                isAccountField,
                integratedColumnName,
              };
            }

            return {
              integrationTypeFieldId,
              fieldId: fieldId || '',
              fixedValue: fixedValue || '',
              isCustomField,
              isAccountField,
            };
          },
        );

        const newDynamicFormat =
          values.dynamicFormat !== null &&
          typeof values.dynamicFormat === 'object' &&
          !Array.isArray(values.dynamicFormat) &&
          Object.keys(values.dynamicFormat).length > 0
            ? JSON.parse(JSON.stringify(values.dynamicFormat))
            : null;

        if (
          integrationType.hasDynamicFieldAssociations &&
          newDynamicFormat === null
        ) {
          toast.error(
            'É obrigatório a parametrização do arquivo de configuração e de retorno',
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
          return;
        }

        if (newDynamicFormat !== null && typeof newDynamicFormat === 'object') {
          let numberOfKeyColumns = 0;
          let numberOfDisplayColumns = 0;

          Object.keys(newDynamicFormat).forEach(key => {
            if (newDynamicFormat[key]?.isPrimaryKeyColumn)
              numberOfKeyColumns += 1;
            if (newDynamicFormat[key]?.isDisplayColumn)
              numberOfDisplayColumns += 1;
          });

          if (numberOfKeyColumns !== 1 || numberOfDisplayColumns !== 1) {
            toast.error(
              'No arquivo de retorno, é obrigatório definir campo chave e campo descrição',
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
                autoClose: 5000,
              },
            );
            return;
          }
        }

        if (integrationRule_id) {
          await api.put(`/integrationsrules/${integrationRule_id}`, {
            description: values.description,
            isActive: values.isActive,
            base: values.base,
            urlBase: values.urlBase,
            user: values.user,
            password: values.password,
            activeMoments: newActiveMoments,
            integrationFieldValues: newIntegrationFieldValues,
            dynamicFieldAssociations:
              values.dynamicFieldAssociations || undefined,
            dynamicFormat: newDynamicFormat,
          });
          toast.success('Regra de integração editada com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } else {
          await api.post(`/integrationsrules`, {
            description: values.description,
            isActive: values.isActive,
            base: values.base,
            urlBase: values.urlBase,
            user: values.user,
            password: values.password,
            activeMoments: newActiveMoments,
            area_id: area?.id ? values.area_id : undefined,
            integrationType_id: integrationType.id,
            integrationFieldValues: newIntegrationFieldValues,
            dynamicFieldAssociations:
              values.dynamicFieldAssociations || undefined,
            dynamicFormat: newDynamicFormat,
          });

          toast.success('Regra de integração cadastrada com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setTimeout(() => {
            closeModal(true);
            history.go(0);
          }, 1000);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  const handleUpdateDynamicFieldAssociations = (value: KeyValue) => {
    formik.setFieldValue('dynamicFieldAssociations', value);
  };

  const handleUpdateDynamicReturnFormat = (
    value: Record<string, DynamicColumnFormat>,
  ) => {
    formik.setFieldValue('dynamicFormat', value);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <form onSubmit={handleOnSubmit}>
        <Box className={classes.content}>
          {integrationRule_id ? (
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Editar Regra da Integração
            </Typography>
          ) : (
            <div />
          )}
          {(!integrationRule_id || integrationRule) && (
            <Box className={classes.table1}>
              <Box
                component={Paper}
                display="flex"
                flexWrap="nowrap"
                justifyContent="space-between"
                padding="10px"
              >
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  flexGrow="1"
                >
                  <TextField
                    id="description"
                    label="Descrição"
                    name="description"
                    margin="dense"
                    autoComplete="off"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    fullWidth
                  />
                  {integrationTypes.length > 0 && (
                    <Autocomplete
                      id="type"
                      fullWidth
                      getOptionLabel={item => item.name}
                      getOptionSelected={(item, value) => item.id === value.id}
                      options={integrationTypes}
                      disabled={!!integrationRule_id}
                      disableClearable
                      defaultValue={
                        integrationTypes[
                          integrationTypes.findIndex(i => {
                            return i.id === integrationRule?.integrationType.id;
                          })
                        ]
                      }
                      onChange={(e, value) => {
                        if (value) {
                          setIntegrationType(value);
                          setAreaRequired(value.areaRequired as boolean);
                          setArea({} as IArea);
                          formik.setFieldValue('area_id', '');
                        }
                      }}
                      loadingText="Carregando"
                      renderInput={rest => (
                        <TextField
                          {...rest}
                          label="Tipo"
                          fullWidth
                          value={formik.values.integrationType_id}
                          margin="dense"
                          InputProps={{
                            ...rest.InputProps,
                          }}
                          error={
                            formik.touched.integrationType_id &&
                            Boolean(formik.errors.integrationType_id)
                          }
                          helperText={
                            formik.touched.integrationType_id &&
                            formik.errors.integrationType_id
                          }
                        />
                      )}
                    />
                  )}
                  {areaRequired && (
                    <Autocomplete
                      id="area_id"
                      fullWidth
                      getOptionLabel={option => option.name || ''}
                      getOptionSelected={(option, value) => option === value}
                      disableClearable
                      options={[area, ...areas]}
                      filterOptions={() => areas}
                      disabled={!!integrationRule_id}
                      onChange={(e, value) => {
                        if (value) {
                          setArea(value);
                          formik.setFieldValue('area_id', value?.id);
                        }
                      }}
                      loading={loadingArea}
                      value={(area as IArea) || ({} as IArea)}
                      loadingText="Carregando"
                      renderInput={rest => (
                        <TextField
                          {...rest}
                          id="area_id"
                          label="Área"
                          margin="dense"
                          name="area_id"
                          fullWidth
                          InputProps={{
                            ...rest.InputProps,
                          }}
                          error={
                            formik.touched.area_id &&
                            Boolean(formik.errors.area_id)
                          }
                          helperText={
                            formik.touched.area_id && formik.errors.area_id
                          }
                        />
                      )}
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isActive"
                        color="secondary"
                        checked={formik.values.isActive}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Ativa?"
                  />
                </Box>
                <Box
                  height="100%"
                  flexDirection="column"
                  flexGrow="1"
                  marginLeft="10px"
                  display="flex"
                >
                  <TextField
                    id="urlBase"
                    label="URL Base"
                    name="urlBase"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    value={formik.values.urlBase}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.urlBase && Boolean(formik.errors.urlBase)
                    }
                    helperText={formik.touched.urlBase && formik.errors.urlBase}
                  />
                  <TextField
                    id="base"
                    label="Base"
                    name="base"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    value={formik.values.base}
                    onChange={formik.handleChange}
                    error={formik.touched.base && Boolean(formik.errors.base)}
                    helperText={formik.touched.base && formik.errors.base}
                  />
                  <TextField
                    id="user"
                    label="Usuário"
                    name="user"
                    margin="dense"
                    fullWidth
                    autoComplete="off"
                    value={formik.values.user}
                    onChange={formik.handleChange}
                    error={formik.touched.user && Boolean(formik.errors.user)}
                    helperText={formik.touched.user && formik.errors.user}
                  />
                  <TextField
                    id="password"
                    name="password"
                    margin="dense"
                    type="password"
                    label="Senha"
                    fullWidth
                    autoComplete="off"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
          {(integrationType.hasDynamicFieldAssociations ||
            integrationRule?.dynamicFieldAssociations ||
            integrationRule?.dynamicFormat) && (
            <>
              <DynamicFieldAssociations
                dynamicFieldAssociations={
                  integrationRule?.dynamicFieldAssociations || {}
                }
                handleUpdateDynamicFieldAssociations={
                  handleUpdateDynamicFieldAssociations
                }
              />
              <DynamicReturnFormat
                dynamicFormatProp={integrationRule?.dynamicFormat || {}}
                handleUpdateDynamicReturnFormat={
                  handleUpdateDynamicReturnFormat
                }
              />
            </>
          )}
          {areaRequired && areaPhases && integrationMoments.length > 0 && (
            <IntegrationMoments
              loadingPhase={loadingPhase}
              areaPhases={areaPhases as IPhase[]}
              integrationMoments={integrationMoments}
              activeMoments={activeMoments}
              setActiveMoments={setActiveMoments}
              setIntegrationMoments={setIntegrationMoments}
            />
          )}
          {areaRequired &&
            area?.id &&
            loadingCustomFields === false &&
            integrationTypeFields &&
            integrationTypeFields.length > 0 && (
              <IntegrationTypeFields
                integrationTypeFields={integrationTypeFields}
                integrationFieldValues={integrationFieldValues}
                associatedFieldOptions={associatedFieldOptions}
                customFields={customFields}
                setIntegrationFieldValues={setIntegrationFieldValues}
              />
            )}
        </Box>
        <Box marginLeft="10px" marginBottom="1rem">
          <ButtonMaterialUI
            className={classes.buttonSubmit}
            // disabled={formik.isSubmitting}
            size="large"
            variant="contained"
            color="inherit"
            onClick={goBack}
          >
            Voltar
          </ButtonMaterialUI>
          <Button
            className={classes.buttonSubmit}
            type="submit"
            disabled={formik.isSubmitting}
          >
            Salvar
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default IntegrationRule;
