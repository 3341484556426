/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { areaApi, IArea } from 'src/services/areaApi';

interface HookReturn {
  areas: IArea[];
  loadingAreas: boolean;
}

export function useAreas(): HookReturn {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [loadingAreas, setLoadingAreas] = useState<boolean>(false);

  useEffect(() => {
    setLoadingAreas(true);
    areaApi
      .getAreas()
      .then((response: { data: IArea[] }) => {
        const sorted =
          response.data?.length > 0
            ? response.data.sort(
                (a: IArea, b: IArea) => -b.name.localeCompare(a.name),
              )
            : [];
        setAreas(sorted);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Falha ao buscar lista de áreas. ${errMessage || ''}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoadingAreas(false);
      });
  }, []);

  return {
    areas,
    loadingAreas,
  } as HookReturn;
}
