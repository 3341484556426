/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IPhase } from '../../Phase/Phase.i';
import usePhaseActions from '../../../context/PhaseActionsHook';
import { EllipsisOptionsDropdown } from '../../../components/EllipsisOptionsDropdown/EllipsisOptionsDropdown';

interface ListPhasesProps {
  initialPhases: IPhase[];
  area_id: string;
  handleEditPhase: (area_id: string, phase_id: string) => void;
  handleClickPhaseRow: (phase_id: string) => void;
}

const ListPhases: React.FC<ListPhasesProps> = ({
  initialPhases,
  area_id,
  handleEditPhase,
  handleClickPhaseRow,
}) => {
  const [phases, setPhases] = useState(initialPhases);
  const { handleDeletePhase, handleChangePhaseDown, handleChangePhaseUp } =
    usePhaseActions(area_id);

  useEffect(() => {
    setPhases(initialPhases);
  }, [initialPhases]);

  const onMovePhaseUp = async (phase: IPhase) => {
    try {
      const updatedPhases = await handleChangePhaseUp(phase, phases);
      setPhases(updatedPhases);
    } catch (error) {
      toast.error('Não foi possível mover a fase para cima');
    }
  };

  const onMovePhaseDown = async (phase: IPhase) => {
    try {
      const updatedPhases = await handleChangePhaseDown(phase, phases);
      setPhases(updatedPhases);
    } catch (error) {
      toast.error('Não foi possível mover a fase para baixo');
    }
  };

  const onDeletePhase = async (phase_id: string) => {
    const updatedPhases = await handleDeletePhase(phase_id);
    if (updatedPhases) {
      setPhases(updatedPhases);
    }
  };

  const sortedPhases = phases.sort((a, b) => a.order - b.order);

  return (
    <Table
      size="small"
      padding="none"
      style={{ backgroundColor: '#eee', padding: '3px', borderRadius: '5px' }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Nome</TableCell>
          <TableCell> Ativa </TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedPhases.map(phase => (
          <TableRow
            key={phase.id}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleClickPhaseRow(phase.id);
            }}
            className="cursor-pointer"
          >
            <TableCell component="th" scope="row">
              {phase.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {phase.isInactive ? 'Não' : 'Sim'}
            </TableCell>
            <TableCell
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <EllipsisOptionsDropdown
                options={{
                  'Mover fase para cima': () => {
                    onMovePhaseUp(phase);
                  },
                  'Mover fase para baixo': () => {
                    onMovePhaseDown(phase);
                  },
                  'Editar fase': () => {
                    handleEditPhase(area_id, phase.id);
                  },
                  'Excluir fase': () => {
                    onDeletePhase(phase.id);
                  },
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default ListPhases;
