/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import {
  Box,
  Checkbox,
  TextField,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { InputNumberMask } from 'src/components/InputNumberMask';

interface IAccountCustomField {
  id: string;
  name: string;
  type: string;
  dateType: string;
  isRequired: boolean;
  isInactive: boolean;
  order: number;
  isBigString: boolean;
  slug: string;
  predefinedValues: string[];
}

interface IAccountCustomFieldsProps {
  accountFields: IAccountCustomField[];
  fieldValues: any[];
  setFieldValues: (
    fieldValues: {
      id: string;
      type: string;
      value: any;
      valueJSON?: string[];
    }[],
  ) => void;
  viewOnly?: boolean;
}

export const AccountCustomFields: React.FC<IAccountCustomFieldsProps> = ({
  accountFields,
  fieldValues,
  setFieldValues,
  viewOnly = false,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      customField: {
        padding: '0 0 10px 0',
      },
    }),
  );

  const classes = useStyles();

  const handleInputChange = useCallback(
    (fieldId, value) => {
      if (fieldValues.find(fv => fv.id === fieldId) !== undefined) {
        const newFieldValues = fieldValues.map(fv =>
          fv.id === fieldId
            ? {
                ...fv,
                valueJSON: fv.type === 'PREDEFINED_STRINGS' ? value : undefined,
                value: fv.type !== 'PREDEFINED_STRINGS' ? value : undefined,
              }
            : fv,
        );
        setFieldValues(newFieldValues);
      } else {
        setFieldValues([
          ...fieldValues,
          {
            id: fieldId,
            type: accountFields.find(f => f.id === fieldId)?.type || '',
            value:
              accountFields.find(f => f.id === fieldId)?.type ===
              'PREDEFINED_STRINGS'
                ? undefined
                : value,
            valueJSON:
              accountFields.find(f => f.id === fieldId)?.type ===
              'PREDEFINED_STRINGS'
                ? value
                : undefined,
          },
        ]);
      }
    },
    [fieldValues, setFieldValues],
  );

  const renderField = (field: IAccountCustomField) => {
    let fieldValue = fieldValues.find(fv => fv.id === field.id)?.value || '';

    if (field.type === 'PREDEFINED_STRINGS') {
      fieldValue = fieldValues.find(fv => fv.id === field.id)?.valueJSON || [];
    }

    switch (field.type) {
      case 'NUMERIC':
      case 'STRING':
        return (
          <TextField
            name={field.name}
            label={field.name}
            required={field.isRequired}
            disabled={field.isInactive}
            value={fieldValue}
            onChange={e => handleInputChange(field.id, e.target.value)}
            InputProps={{
              readOnly: viewOnly,
              inputComponent:
                field.type !== 'NUMERIC' ? undefined : (InputNumberMask as any),
            }}
            fullWidth
          />
        );
      case 'TIMESTAMP':
        return (
          <TextField
            key={field.id}
            id={field.name}
            name={field.name}
            type={field.dateType}
            label={field.name}
            autoComplete="off"
            fullWidth
            disabled={field.isInactive}
            required={field.isRequired}
            onChange={e => handleInputChange(field.id, e.target.value)}
            value={fieldValue}
            InputLabelProps={{
              shrink: true,
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            InputProps={{
              readOnly: viewOnly,
              inputProps: {
                style: { textAlign: 'left' },
                inputformat: field.dateType === 'DATE' ? 'dd/mm/yyyy' : 'hh:mm',
                pattern:
                  field.dateType === 'DATE'
                    ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
                    : '[0-9]{2}:[0-5]{1}[0-9]{1}',
                min: field.dateType === 'DATE' ? '1900-05-01' : '00:00',
                max: field.dateType === 'DATE' ? '2999-12-31' : '23:59',
              },
            }}
          />
        );
      case 'PREDEFINED_STRINGS':
        return (
          <Autocomplete
            multiple
            options={field.predefinedValues || []}
            disabled={field.isInactive || viewOnly}
            value={fieldValue || []}
            onChange={(e, value) => handleInputChange(field.id, value)}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </>
            )}
            disableCloseOnSelect
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={field.name}
                placeholder={field.name}
                required={field.isRequired}
              />
            )}
          />
        );
      case 'BOOL':
        return (
          <Box className={`${classes.customField}`}>
            <Typography>{field.name}: </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100px',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography>Sim</Typography>
                <Checkbox
                  checked={fieldValue === 'true'}
                  required={field.isRequired}
                  disabled={field.isInactive || viewOnly}
                  onChange={() => handleInputChange(field.id, 'true')}
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography>Não</Typography>
                <Checkbox
                  checked={fieldValue === 'false'}
                  required={field.isRequired}
                  disabled={field.isInactive || viewOnly}
                  onChange={() => handleInputChange(field.id, 'false')}
                />
              </Box>
            </Box>
          </Box>
        );
      default:
        return (
          <div>
            <Typography>Campo não suportado: {field.name}</Typography>
          </div>
        );
    }
  };

  return (
    <Box style={{ width: '100%' }}>
      {accountFields.map(field => (
        <Box key={field.id} className={classes.customField}>
          {renderField(field)}
        </Box>
      ))}
    </Box>
  );
};
