import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { SignersTable } from './SignersTable';
import { SignedDocumentLog } from './LogTable';
import { LightTooltip } from '../Tooltip/LightToolTip';
import { ModalProps } from './Signature.i';
import { useSignature } from './hooks/useSignature';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: '100%',
      height: 'auto',
      minHeight: '50%',
      maxHeight: '100%',
      overflow: 'auto',
    },
    goBackButton: {
      textDecoration: 'none',
      fontSize: '12px',
      marginRight: '0.5rem',
    },
    defaultButton: {
      height: '35px',
      fontSize: '12px',
      color: '#FFF',
      margin: '0 0.5rem',
    },
    blueButton: {
      background: 'linear-gradient(to right, #007dc6, #2f98fa)',
      '&:disabled': { background: '#dfdfdf' },
    },
    greenButton: {
      background: '#28a745',
      '&:hover': { background: '#28a745' },
    },
    redButton: {
      background: 'red',
      '&:hover': { background: 'red' },
    },
  }),
);

export const Signature: React.FC<ModalProps> = ({
  open,
  closeModal,
  title,
  card_id,
  signature_id,
  cardField_id,
  hasFilesOrTemplate,
  setSignaturesCollectionStarted,
  setSignatureId,
  ...rest
}) => {
  const classes = useStyles();
  const {
    signedDocument,
    expiryDate,
    minDate,
    setExpiryDate,
    handleChangeSignatureProcess,
    handleShowSignatures,
    resendEmail,
    resendDocument,
    fetchSignedDocument,
    allSignersCompleted,
  } = useSignature(
    card_id,
    cardField_id,
    signature_id,
    setSignatureId,
    setSignaturesCollectionStarted,
  );

  const handleFetchDocument = () => {
    if (
      (!!signedDocument?.hashWithSignature &&
        signedDocument.hashWithSignature.length > 0) ||
      (signedDocument?.collectionStarted && allSignersCompleted())
    )
      fetchSignedDocument();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={closeModal}
      maxWidth="lg"
      {...rest}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <DialogTitle id={title} style={{ maxWidth: '70%', overflow: 'hidden' }}>
          <Box height="22px">
            <span>{title}</span>
          </Box>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box style={{ width: '100%', padding: '1rem' }}>
          <Box display="flex" flexDirection="row" mb={2} alignItems="center">
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="expiryDate"
                name="expiryDate"
                label="Data de expiração"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                size="small"
                margin="dense"
                value={expiryDate}
                invalidDateMessage="Formato de data inválido."
                disabled={signedDocument?.collectionStarted}
                InputLabelProps={{ shrink: true }}
                minDate={minDate}
                minDateMessage="Deve ser maior que a data atual."
                onChange={date => {
                  if (date && date.toISOString)
                    setExpiryDate(date.toISOString());
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>

          <SignersTable
            handleFetchSignatures={handleShowSignatures}
            hasSignatureEnded={
              !!(
                signedDocument?.hashWithSignature &&
                signedDocument.hashWithSignature.length > 0
              ) ||
              (signedDocument?.collectionStarted && allSignersCompleted())
            }
            signedDocumentId={signedDocument?.id || ''}
            signers={signedDocument?.signers ?? []}
            collectionStarted={signedDocument?.collectionStarted ?? false}
            card_id={card_id}
            signature_id={signedDocument?.id}
            expiryDate={expiryDate || ''}
            resendEmail={resendEmail}
            resendDocument={resendDocument}
            hasSignerConcluded={() => false}
          />

          {signedDocument?.id &&
            signedDocument?.log &&
            signedDocument.log.length > 0 && (
              <SignedDocumentLog
                signedDocumentId={signedDocument.id}
                signedDocumentLog={signedDocument.log}
              />
            )}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            mt={2}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Button
                className={classes.goBackButton}
                size="medium"
                variant="contained"
                onClick={() => closeModal(!open)}
              >
                Voltar
              </Button>
              {!!(
                signedDocument?.hashWithSignature &&
                signedDocument.hashWithSignature.length > 0
              ) ||
                (signedDocument?.collectionStarted && allSignersCompleted() && (
                  <Typography className="ml-4">
                    <a
                      href="#"
                      className="text-blue-500 hover:underline hover:decoration-blue-500"
                      onClick={() => handleFetchDocument()}
                    >
                      Acessar documento assinado
                    </a>
                  </Typography>
                ))}
            </Box>

            <Box marginLeft="auto">
              <LightTooltip
                title={
                  !hasFilesOrTemplate ||
                  (hasFilesOrTemplate &&
                    (!signedDocument?.signers ||
                      (signedDocument?.signers &&
                        signedDocument.signers.length <= 0)))
                    ? 'Para iniciar a coleta é obrigatório ao menos um signatário e um documento PDF selecionado.'
                    : ''
                }
              >
                <Button
                  className={`${classes.defaultButton} ${
                    signedDocument?.collectionStarted
                      ? classes.redButton
                      : classes.greenButton
                  }`}
                  size="medium"
                  variant="contained"
                  onClick={() =>
                    handleChangeSignatureProcess(
                      signedDocument?.collectionStarted
                        ? 'cancelCollection'
                        : 'startCollection',
                    )
                  }
                  disabled={
                    !hasFilesOrTemplate ||
                    (hasFilesOrTemplate &&
                      (!signedDocument?.signers ||
                        (signedDocument?.signers &&
                          signedDocument.signers.length <= 0))) ||
                    !!(
                      signedDocument?.hashWithSignature &&
                      signedDocument.hashWithSignature.length > 0
                    ) ||
                    (signedDocument?.collectionStarted && allSignersCompleted())
                  }
                >
                  {signedDocument?.collectionStarted
                    ? 'Cancelar Coleta'
                    : 'Iniciar Coleta'}
                </Button>
              </LightTooltip>
              <Button
                className={`${classes.defaultButton} ${classes.blueButton}`}
                size="medium"
                variant="contained"
                disabled={signedDocument?.collectionStarted}
                onClick={() => handleChangeSignatureProcess()}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
