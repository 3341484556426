import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Button,
  Container,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CopyrightBrasao } from 'src/components/CopyrightBrasao';
import { zoomHandler } from 'src/utils/zoomHandler';
import logo from '../../../assets/logo-brasao-bpm.svg';
import usePdfDocument from './hooks/usePdfDocuments';
import useStyles from './styles';

interface IPdfDocumentProps {
  base64Pdf: string;
  signer_id: string;
  signerName: string;
  token: string;
  hash: string;
}

const PdfDocument: React.FC<IPdfDocumentProps> = ({
  base64Pdf,
  signer_id,
  signerName,
  token,
  hash,
}) => {
  const classes = useStyles();
  const [, setSignerLocation] = useState<string>('');
  const [scale, setScale] = useState<number>(1.0);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const {
    pageNumber,
    numberOfPages,
    alreadySigned,
    topAppBarRef,
    bottomAppBarRef,
    appBarsHeight,
    previousPage,
    nextPage,
    onDocumentLoadSuccess,
    handleOnSignDocument,
  } = usePdfDocument(signer_id, token, hash, setSignerLocation);

  useEffect(() => {
    const pdfContainer = pdfContainerRef.current;
    if (pdfContainer) {
      const onWheel = (event: WheelEvent) => {
        zoomHandler(
          event as unknown as React.WheelEvent<HTMLDivElement>,
          scale,
          setScale,
        );
      };
      pdfContainer.addEventListener('wheel', onWheel, { passive: false });
      return () => {
        pdfContainer.removeEventListener('wheel', onWheel);
      };
    }
    return undefined;
  }, [scale]);

  return (
    <div>
      <Container maxWidth={false} className={classes.content}>
        <AppBar ref={topAppBarRef} position="sticky" className={classes.appBar}>
          <Toolbar>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '180px',
                height: '50px',
              }}
            />
            <Typography
              variant="h6"
              color="textPrimary"
              style={{
                marginTop: '10px',
                marginLeft: '1rem',
              }}
              noWrap
            >
              {`Assinatura Eletrônica ${signerName ? `- ${signerName}` : ''}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            overflow: 'auto',
            height: `calc(100vh - ${
              appBarsHeight > 0 ? appBarsHeight : 128
            }px)`,
            boxSizing: 'border-box',
            width: '100%',
            flexGrow: 1,
          }}
          ref={pdfContainerRef}
        >
          <Document file={base64Pdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              onWheel={(event: React.WheelEvent<HTMLDivElement>) =>
                zoomHandler(event, scale, setScale)
              }
              scale={scale}
              pageNumber={pageNumber}
            />
          </Document>
        </div>
        <AppBar
          ref={bottomAppBarRef}
          position="sticky"
          className={`${classes.appBar} ${classes.appBarBottom}`}
        >
          <Toolbar
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '0.5rem',
              boxSizing: 'border-box',
            }}
          >
            <Box className={classes.buttonsBox}>
              <Typography
                variant="body2"
                color="textPrimary"
                align="center"
                noWrap
              >
                Página {pageNumber || (numberOfPages ? 1 : '--')} de{' '}
                {numberOfPages || '--'}
              </Typography>
              <Box margin="0" padding="0" boxSizing="border-box">
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  style={{ marginRight: '0.5rem' }}
                >
                  Anterior
                </Button>
                {numberOfPages && pageNumber >= numberOfPages ? (
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    disabled={alreadySigned}
                    onClick={handleOnSignDocument}
                  >
                    Assinar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={!!(numberOfPages && pageNumber >= numberOfPages)}
                    onClick={nextPage}
                  >
                    Próxima
                  </Button>
                )}
              </Box>
            </Box>
            <CopyrightBrasao />
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
};

export default PdfDocument;
