/* eslint-disable camelcase */
import React from 'react';

import {
  Box,
  Button,
  createStyles,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { RichTextEditor } from '../RichTextEditor/ckeditor';
import { IRichTextVariable } from '../../utils/customFieldUtils';

interface ModalProps {
  open: boolean;
  closeModal: (open: boolean) => void;
  title?: string;
  template: string;
  setTemplate: (template: string) => void;
  handleRestoreTemplate: () => void;
  suggestionsCF: IRichTextVariable[];
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: '100%',
      height: '100%',
      minHeight: '100vh',
      minWidth: '300px',
      overflow: 'auto',
    },
    children: {
      padding: 5,
      margin: 0,
      height: '100%',
      width: '100%',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'auto',
      boxSizing: 'border-box',
    },
    restoreButton: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      marginLeft: '15px',
      fontSize: '12px',
    },
    goBackButton: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      fontSize: '12px',
    },
  }),
);

export const ModalDocumentField: React.FC<ModalProps> = ({
  open,
  closeModal,
  title,
  template,
  setTemplate,
  handleRestoreTemplate,
  suggestionsCF,
  ...rest
}) => {
  const classes = useStyles();

  const removeTabIndex = (node: HTMLElement, isAppearing: boolean) => {
    if (node && isAppearing) node.removeAttribute('tabIndex');
  };

  return (
    <Dialog
      id="documentDialog"
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={closeModal}
      maxWidth="xl"
      PaperProps={{ tabIndex: undefined }}
      TransitionProps={{
        onEntered: removeTabIndex,
      }}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogTitle
          id={title}
          style={{ maxWidth: '80%', overflow: 'hidden', paddingBottom: '10px' }}
        >
          <Box height="33px">
            <span>{title}</span>
            <Button
              className={classes.restoreButton}
              size="medium"
              variant="contained"
              onClick={() => handleRestoreTemplate()}
            >
              Restaurar
            </Button>
          </Box>
        </DialogTitle>
        <Box mr="25px" pt="16px" pb="10px">
          <Button
            className={classes.goBackButton}
            size="medium"
            variant="contained"
            onClick={() => closeModal(!open)}
          >
            Voltar
          </Button>
        </Box>
      </Box>
      <DialogContent className={classes.children}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          <RichTextEditor
            propData={template && template.length > 0 ? template : ''}
            setPropData={setTemplate}
            suggestionsProps={suggestionsCF.length > 0 ? suggestionsCF : []}
            small={false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
