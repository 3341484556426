import React, { ReactNode } from 'react';

import { AuthProvider } from './AuthContext';
import { LoaderProvider } from './LoaderContext';
import { TriggerProvider } from './TriggerContext';

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => (
  <LoaderProvider>
    <AuthProvider>
      <TriggerProvider>{children}</TriggerProvider>
    </AuthProvider>
  </LoaderProvider>
);

export default AppProvider;
