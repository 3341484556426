/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FileListIcon(props: any): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 512 434.98"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      clipRule="evenodd"
      fillRule="nonzero"
    >
      <g>
        <path d="M99.67 32.07c-7.95 0-14.41-6.52-14.41-14.56 0-8.03 6.46-14.55 14.41-14.55h397.92c7.95 0 14.41 6.52 14.41 14.55 0 8.04-6.46 14.56-14.41 14.56H99.67zM17.35 434.98C7.77 434.98 0 427.13 0 417.46c0-9.66 7.77-17.51 17.35-17.51h.56c9.58 0 17.35 7.85 17.35 17.51 0 9.67-7.77 17.52-17.35 17.52h-.56zm0-132.4C7.77 302.58 0 294.73 0 285.06s7.77-17.51 17.35-17.51h.56c9.58 0 17.35 7.84 17.35 17.51 0 9.67-7.77 17.52-17.35 17.52h-.56zm0-135.14C7.77 167.44 0 159.59 0 149.92s7.77-17.51 17.35-17.51h.56c9.58 0 17.35 7.84 17.35 17.51 0 9.67-7.77 17.52-17.35 17.52h-.56zm83.4 132.17c-6.99 0-12.66-6.51-12.66-14.55 0-8.03 5.67-14.55 12.66-14.55h395.77c6.99 0 12.66 6.52 12.66 14.55 0 8.04-5.67 14.55-12.66 14.55H100.75zm0-135.14c-6.99 0-12.66-6.51-12.66-14.55 0-8.03 5.67-14.55 12.66-14.55h395.77c6.99 0 12.66 6.52 12.66 14.55 0 8.04-5.67 14.55-12.66 14.55H100.75zm0 267.55c-7.88 0-14.26-6.52-14.26-14.56 0-8.03 6.38-14.55 14.26-14.55h395.77c7.87 0 14.26 6.52 14.26 14.55 0 8.04-6.39 14.56-14.26 14.56H100.75zM17.35 35.03C7.77 35.03 0 27.18 0 17.51 0 7.85 7.77 0 17.35 0h.56c9.58 0 17.35 7.85 17.35 17.51 0 9.67-7.77 17.52-17.35 17.52h-.56z" />
      </g>
    </SvgIcon>
  );
}
