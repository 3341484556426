import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { conditionTypes } from 'src/utils/conditionOperators';
import { IOperatorProps } from '../Condition.i';

export function OuterLogicOperator({
  item,
  groupIndex,
  itemIndex,
  handleConditionChange,
  isGroupCondition = false,
}: IOperatorProps) {
  return (
    <Autocomplete
      id="conditionItem"
      getOptionLabel={opt => opt.label}
      getOptionSelected={(opt, value) => opt.id === value.id}
      options={conditionTypes}
      value={
        conditionTypes[conditionTypes.findIndex(field => field.id === item)] ||
        null
      }
      onChange={(e, value) =>
        handleConditionChange(
          isGroupCondition ? 'groupCondition' : 'condition',
          value.id,
          groupIndex,
          itemIndex,
        )
      }
      disableClearable
      size="small"
      loadingText="Carregando"
      renderInput={rest => (
        <TextField
          {...rest}
          id="conditionItem"
          label=""
          margin="dense"
          name={isGroupCondition ? 'groupConditionItem' : 'conditionItem'}
          variant="outlined"
          size="small"
          InputProps={{
            ...rest.InputProps,
            style: {
              margin: '0 0 0 5px',
              padding: '2px',
              fontSize: '14px',
              backgroundColor: 'white',
              width: '60px',
            },
          }}
        />
      )}
    />
  );
}
