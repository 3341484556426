/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  CircularProgress,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  IconButton,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { toast } from 'react-toastify';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AxiosError } from 'axios';
import { RichTextEditor } from '../../components/RichTextEditor/ckeditor';
import api from '../../services/api';
import Button from '../../components/Button';

import {
  IForm,
  IObject,
  IFormParams,
  IFormProps,
  ICustomField,
  IFormData,
} from './Form.i';
import { styles } from './styles';
import { FormFieldsTable } from './FormFieldsTable';
import { AccountFields } from './AccountFields';
import { BgColorPicker } from './BgColorPicker';
import { LightTooltip } from '../../components/Tooltip/LightToolTip';
import { FixedAccountFields } from './FixedAccountFields';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const Form: React.FC<IFormProps> = ({
  closeModal = (value: any) => null,
}) => {
  const classes = useStyles();
  const urlOrigin = window.location.origin;

  const history = useHistory();

  const params = useParams<IFormParams>();
  const { form_id } = params;

  const goBack = () => {
    if (!form_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  const [form, setForm] = useState<IForm>();

  const [selectedArea, setSelectedArea] = useState<IObject | null>(null);
  const [areaPhases, setAreaPhases] = useState<IObject[]>([]);
  const [areas, setAreas] = useState<IObject[]>([] as IObject[]);
  const [selectedPhase, setSelectedPhase] = useState<IObject | null>(null);
  const [users, setUsers] = useState<IObject[]>([]);
  const [description, setDescription] = useState<string>('');

  const [loadingArea, setLoadingArea] = useState(false);
  const [loadingPhase, setLoadingPhase] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingCustomFields, setLoadingCustomFields] = useState(false);

  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  const [formFields, setFormFields] = useState<ICustomField[]>([]);
  const [selectedCustomField, setSelectedCustomField] =
    useState<ICustomField | null>(null);
  const [accountFields, setAccountFields] = useState<ICustomField[]>([]);
  const [fixedAccountFields, setFixedAccountFields] = useState<string[]>([]);

  useEffect(() => {
    if (form_id?.length > 0) {
      api
        .get(`/forms/${form_id}`)
        .then(response => {
          setForm(response.data);
          setDescription(response.data.description);
          setSelectedPhase(response.data.phase);
          setFormFields(response.data?.customFields || []);
          setAccountFields(response.data?.accountFields || []);
          setFixedAccountFields(response.data?.fixedAccountFields || []);
        })
        .catch((err: AxiosError) => {
          toast.error(`Falha ao carregar dados da integração. ${err.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 4000,
            closeOnClick: true,
          });
        });
    }
  }, [form_id]);

  useEffect(() => {
    (async () => {
      setLoadingArea(true);
      api
        .get('/areas')
        .then(response => setAreas(response.data))
        .finally(() => setLoadingArea(false));
    })();
  }, []);

  useEffect(() => {
    setFormFields([]);
    setSelectedPhase(null);
  }, [selectedArea]);

  useEffect(() => {
    let active = true;

    if (form?.area || selectedArea) {
      const area = form?.area || selectedArea;

      (async () => {
        setLoadingPhase(true);

        api
          .get(`areas/${area?.id}/phases`)
          .then(response => {
            setAreaPhases(response.data);
          })
          .finally(() => setLoadingPhase(false));
      })();

      (async () => {
        setLoadingUser(true);

        api
          .get(`/areas/${area?.id}/users`)
          .then(response => setUsers(response.data))
          .finally(() => setLoadingUser(false));
      })();

      (async () => {
        setLoadingCustomFields(true);

        api
          .get(`/areas/${area?.id}/customFields`)
          .then(response => {
            setCustomFields(response.data);
          })
          .finally(() => setLoadingCustomFields(false));
      })();
    }

    return () => {
      active = false;
    };
  }, [selectedArea, form?.area]);

  const initialValues: IFormData = {
    id: form && form.id ? form.id : undefined,
    area: form && form.area ? form.area : ({} as IObject),
    phase_id: form && form.phase ? form.phase.id : '',
    user: form && form.user ? form.user : ({} as IObject),
    isActive: form ? form.isActive : true,
    canCreateAccounts: form ? form.canCreateAccounts : false,
    canEditAccounts: form ? form.canEditAccounts : false,
    canCreateContacts:
      form && form.canCreateAccounts && !form.canCreateContacts
        ? true
        : form && form.canCreateContacts
        ? form.canCreateContacts
        : false,
    canEditContacts: form ? form.canEditContacts : false,
    usesNameForAccountAndContact: form
      ? form.usesNameForAccountAndContact
      : false,
    title: form ? form.title : '',
    description: form ? form.description : '',
    urlTitleImage: form ? form.urlTitleImage : '',
    customFields: form ? form.customFields : [],
    footer: form ? form.footer : '',
    styles:
      form && form.styles
        ? form.styles
        : {
            color: {
              primary: '#007dc6',
              secondary: '#53a546',
              header: '#DEE6F0',
              body: '#eaeeed',
              content: '#fafafa',
              footer: '#DEE6F0',
              border: '#dfdfdf',
            },
          },
  };

  const formSchema = Yup.object().shape({
    area: Yup.object()
      .shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
        description: Yup.string().required(),
      })
      .required('Área é obrigatório'),
    phase_id: Yup.string().required('Fase é obrigatório'),
    user: Yup.object()
      .shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
      .required('Usuário é obrigatório'),
    isActive: Yup.boolean(),
    title: Yup.string()
      .min(1)
      .max(150, 'Número máximo de caracteres é 150')
      .required('Título é obrigatório'),
    canCreateAccounts: Yup.boolean(),
    canEditAccounts: Yup.boolean(),
    canCreateContacts: Yup.boolean(),
    canEditContacts: Yup.boolean(),
    usesNameForAccountAndContact: Yup.boolean(),
    description: Yup.string().required('Descrição é obrigatório'),
    urlTitleImage: Yup.string()
      .url()
      .required('Caminho da imagem é obrigatório'),
    footer: Yup.string().nullable().optional(),
    customFields: Yup.array().optional(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formattedCustomFields = formFields.map(f => {
          return {
            id: f.id,
            order: f.order,
          };
        });
        const formattedAccountFields = accountFields.map(f => {
          return {
            id: f.id,
            order: f.order,
          };
        });

        if (form_id) {
          await api.put(`/forms/${form_id}`, {
            title: values.title,
            description,
            urlTitleImage: values.urlTitleImage,
            isActive: values.isActive,
            canCreateAccounts: values.canCreateAccounts,
            canEditAccounts: values.canEditAccounts,
            canCreateContacts: values.canCreateContacts,
            canEditContacts: values.canEditContacts,
            usesNameForAccountAndContact: values.usesNameForAccountAndContact,
            user_id: values.user.id,
            area_id: values.area.id,
            phase_id: values.phase_id,
            customFields: formattedCustomFields,
            fixedAccountFields,
            accountFields: formattedAccountFields,
            styles: values.styles,
            footer: values.footer ? values.footer : null,
          });
          toast.success('Formulário editado com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } else {
          await api.post('/forms', {
            title: values.title,
            description,
            urlTitleImage: values?.urlTitleImage,
            isActive: values?.isActive,
            canCreateAccounts: values.canCreateAccounts,
            canEditAccounts: values.canEditAccounts,
            canCreateContacts: values.canCreateContacts,
            canEditContacts: values.canEditContacts,
            usesNameForAccountAndContact: values.usesNameForAccountAndContact,
            user_id: values.user.id,
            area_id: values.area.id,
            phase_id: values.phase_id,
            customFields: formattedCustomFields,
            fixedAccountFields,
            accountFields: formattedAccountFields,
            styles: values.styles,
            footer: values.footer ? values.footer : undefined,
          });
          toast.success('Formulário cadastrado com sucesso', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });

          history.go(0);
        }
      } catch (error: any) {
        toast.error(
          `Não foi possível cadastrar o formulário. ${error.response.data.message}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      }
    },
  });

  useEffect(() => {
    const tempValue =
      description.length > 0 && description.trim() !== '<p></p>'
        ? description
        : '';
    formik.setFieldValue('description', tempValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const allowAccountOrContactFields =
    (formik.values.canCreateAccounts || formik.values.canEditAccounts) &&
    (formik.values.canCreateContacts || formik.values.canEditContacts);

  const createOrEditAccounts =
    formik.values.canCreateAccounts || formik.values.canEditAccounts;
  const createOrEditContacts =
    formik.values.canCreateContacts || formik.values.canEditContacts;

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: 'auto',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {form_id ? (
          <Box mt="20px" display="flex" flexDirection="column">
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Editar Formulário{' '}
              {urlOrigin && (
                <LightTooltip title="Copiar link">
                  <IconButton
                    aria-label="Copiar link do formulário"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${urlOrigin}/fillForm/${form_id}`,
                      );
                    }}
                  >
                    <LinkRoundedIcon />
                  </IconButton>
                </LightTooltip>
              )}
            </Typography>
            {urlOrigin && (
              <Box mb={1}>
                <Link
                  to={`/fillForm/${form_id}`}
                  style={{ color: '#3C7CC6', fontSize: '14px' }}
                >
                  {`${urlOrigin}/fillForm/${form_id}`}
                </Link>
              </Box>
            )}
          </Box>
        ) : (
          <div />
        )}
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box width="100%">
            <Box component={Paper} className={classes.formDataContainer}>
              <Box className={classes.formDataBox1}>
                <Autocomplete
                  id="area"
                  fullWidth
                  getOptionLabel={opt => opt.name || ''}
                  getOptionSelected={(opt, value) =>
                    opt.id === value.id || false
                  }
                  options={areas || []}
                  value={formik.values.area?.id ? formik.values.area : null}
                  onChange={(e, value) => {
                    setSelectedArea(value);
                    formik.setFieldValue('area', value);
                  }}
                  disableCloseOnSelect={false}
                  disabled={!!form_id}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="area"
                      label="Área"
                      margin="dense"
                      name="area"
                      fullWidth
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      helperText={
                        formik.touched.area && Boolean(formik.errors.area)
                      }
                      InputProps={{
                        ...rest.InputProps,
                        endAdornment: (
                          <>
                            {loadingArea ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {rest.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="phase"
                  fullWidth
                  getOptionLabel={phase => phase.name || ''}
                  getOptionSelected={(phase, value) =>
                    phase.id === value.id || false
                  }
                  options={areaPhases || []}
                  value={selectedPhase || null}
                  onChange={(e, value) => {
                    setSelectedPhase(value || null);
                    formik.setFieldValue('phase_id', value?.id || '');
                  }}
                  disableCloseOnSelect={false}
                  disabled={!!form_id}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="phase"
                      label="Fase"
                      margin="dense"
                      name="phase"
                      fullWidth
                      error={
                        formik.touched.phase_id &&
                        Boolean(formik.errors.phase_id)
                      }
                      helperText={
                        formik.touched.phase_id &&
                        Boolean(formik.errors.phase_id)
                      }
                      InputProps={{
                        ...rest.InputProps,
                        endAdornment: (
                          <>
                            {loadingPhase ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {rest.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="user"
                  fullWidth
                  getOptionLabel={user => user?.name || ''}
                  getOptionSelected={(user, value) =>
                    user.id === value.id || false
                  }
                  options={users || []}
                  value={formik.values.user?.id ? formik.values.user : null}
                  onChange={(e, value) => {
                    formik.setFieldValue('user', value);
                  }}
                  disableCloseOnSelect={false}
                  disabled={!!form_id}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="user"
                      label="Usuário"
                      margin="dense"
                      name="user"
                      fullWidth
                      error={formik.touched.user && Boolean(formik.errors.user)}
                      helperText={
                        formik.touched.user && Boolean(formik.errors.user)
                      }
                      InputProps={{
                        ...rest.InputProps,
                        endAdornment: (
                          <>
                            {loadingUser ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {rest.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="template"
                  fullWidth
                  getOptionLabel={opt => opt}
                  getOptionSelected={(opt, value) => opt === value}
                  options={['Padrão']}
                  defaultValue="Padrão"
                  disabled
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="template"
                      label="Template"
                      margin="dense"
                      name="template"
                      fullWidth
                      InputProps={{
                        ...rest.InputProps,
                      }}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Ativo?"
                />
                <Box>
                  <LightTooltip title="Opções para definir criação e edição de contas através do formulário.">
                    <Typography variant="body1">
                      Permissão de contas:
                    </Typography>
                  </LightTooltip>
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        name="canCreateAccounts"
                        checked={formik.values.canCreateAccounts}
                        onChange={event => {
                          formik.setFieldValue(
                            'canCreateAccounts',
                            event.target.checked,
                          );
                          if (event.target.checked) {
                            formik.setFieldValue(
                              'canCreateContacts',
                              event.target.checked,
                            );
                            if (
                              event.target.checked &&
                              !formik.values.usesNameForAccountAndContact &&
                              (!fixedAccountFields.includes('accountName') ||
                                !fixedAccountFields.includes('contactName'))
                            ) {
                              const tempFields = [...fixedAccountFields];
                              if (!fixedAccountFields.includes('accountName'))
                                tempFields.push('accountName');
                              if (!fixedAccountFields.includes('contactName'))
                                tempFields.push('contactName');
                              setFixedAccountFields(tempFields);
                            }
                          } else {
                            if (!formik.values.canEditAccounts) {
                              formik.setFieldValue(
                                'usesNameForAccountAndContact',
                                event.target.checked,
                              );
                            }
                            if (!formik.values.canEditAccounts) {
                              const tempFields = fixedAccountFields.filter(
                                field => field === 'contactName',
                              );
                              setFixedAccountFields(tempFields);
                            }
                          }
                        }}
                      />
                    }
                    label="Criar"
                  />
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        name="canEditAccounts"
                        checked={formik.values.canEditAccounts}
                        onChange={event => {
                          formik.setFieldValue(
                            'canEditAccounts',
                            event.target.checked,
                          );
                          if (
                            !event.target.checked &&
                            !formik.values.canCreateAccounts
                          ) {
                            const tempFields = fixedAccountFields.filter(
                              field => field === 'contactName',
                            );
                            setFixedAccountFields(tempFields);
                            if (!formik.values.canCreateAccounts) {
                              formik.setFieldValue(
                                'usesNameForAccountAndContact',
                                event.target.checked,
                              );
                            }
                          }
                        }}
                      />
                    }
                    label="Editar"
                  />
                </Box>
                <Box>
                  <LightTooltip title="Opções para definir criação e edição de contatos através do formulário.">
                    <Typography variant="body1">
                      Permissão de contatos:
                    </Typography>
                  </LightTooltip>
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        name="canCreateContacts"
                        checked={formik.values.canCreateContacts}
                        disabled={formik.values.canCreateAccounts}
                        onChange={event => {
                          formik.setFieldValue(
                            'canCreateContacts',
                            event.target.checked,
                          );
                          if (event.target.checked) {
                            if (
                              !fixedAccountFields.includes('contactName') &&
                              !formik.values.usesNameForAccountAndContact
                            ) {
                              const tempFields = [...fixedAccountFields];
                              tempFields.push('contactName');
                              setFixedAccountFields(tempFields);
                            }
                          } else {
                            if (
                              fixedAccountFields.includes('contactName') &&
                              !formik.values.canEditContacts
                            ) {
                              const tempFields = fixedAccountFields.filter(
                                field => field !== 'contactName',
                              );
                              setFixedAccountFields(tempFields);
                            }

                            if (!formik.values.canEditContacts) {
                              formik.setFieldValue(
                                'usesNameForAccountAndContact',
                                event.target.checked,
                              );
                            }
                          }
                        }}
                      />
                    }
                    label="Criar"
                  />
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        name="canEditContacts"
                        checked={formik.values.canEditContacts}
                        onChange={event => {
                          formik.setFieldValue(
                            'canEditContacts',
                            event.target.checked,
                          );
                          if (
                            !event.target.checked &&
                            !formik.values.canCreateContacts
                          ) {
                            formik.setFieldValue(
                              'usesNameForAccountAndContact',
                              event.target.checked,
                            );

                            const tempFields = fixedAccountFields.filter(
                              field => field !== 'contactName',
                            );
                            setFixedAccountFields(tempFields);
                          }
                        }}
                      />
                    }
                    label="Editar"
                  />
                </Box>
                {allowAccountOrContactFields && (
                  <LightTooltip title="Ao marcar esta opção, no preenchimento do formulário aparecerá apenas o campo 'Nome', que será utilizado para atualizar o nome da conta e do contato.">
                    <FormControlLabel
                      control={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Checkbox
                          name="usesNameForAccountAndContact"
                          checked={formik.values.usesNameForAccountAndContact}
                          onChange={event => {
                            formik.setFieldValue(
                              'usesNameForAccountAndContact',
                              event.target.checked,
                            );
                            if (
                              event.target.checked &&
                              (fixedAccountFields.includes('accountName') ||
                                fixedAccountFields.includes('contactName'))
                            ) {
                              const tempFields = fixedAccountFields.filter(
                                field =>
                                  field !== 'accountName' &&
                                  field !== 'contactName',
                              );
                              setFixedAccountFields(tempFields);
                            } else if (!event.target.checked) {
                              const tempFields = [...fixedAccountFields];
                              if (
                                !fixedAccountFields.includes('accountName') &&
                                formik.values.canCreateAccounts
                              )
                                tempFields.push('accountName');
                              if (
                                !fixedAccountFields.includes('contactName') &&
                                formik.values.canCreateContacts
                              )
                                tempFields.push('contactName');
                              setFixedAccountFields(tempFields);
                            }
                          }}
                        />
                      }
                      label="Usar campo único para nome"
                    />
                  </LightTooltip>
                )}
                <Box mt={1}>
                  <BgColorPicker
                    bgColor={formik.values.styles.color.header}
                    labelName="Cabeçalho"
                    fieldName="styles.color.header"
                    setFieldValue={formik.setFieldValue}
                  />
                  <BgColorPicker
                    bgColor={formik.values.styles.color.body}
                    labelName="Conteúdo"
                    fieldName="styles.color.body"
                    setFieldValue={formik.setFieldValue}
                  />
                  <BgColorPicker
                    bgColor={formik.values.styles.color.content}
                    labelName="Área dos campos"
                    fieldName="styles.color.content"
                    setFieldValue={formik.setFieldValue}
                  />
                  <BgColorPicker
                    bgColor={formik.values.styles.color.footer}
                    labelName="Rodapé"
                    fieldName="styles.color.footer"
                    setFieldValue={formik.setFieldValue}
                  />
                  <BgColorPicker
                    bgColor={formik.values.styles.color.border}
                    labelName="Borda"
                    fieldName="styles.color.border"
                    setFieldValue={formik.setFieldValue}
                  />
                  <BgColorPicker
                    bgColor={formik.values.styles.color.primary}
                    labelName="Primária (campos)"
                    fieldName="styles.color.primary"
                    setFieldValue={formik.setFieldValue}
                  />
                  <BgColorPicker
                    bgColor={formik.values.styles.color.secondary}
                    labelName="Secundária (botão enviar)"
                    fieldName="styles.color.secondary"
                    setFieldValue={formik.setFieldValue}
                  />
                </Box>
              </Box>
              <Box className={classes.formDataBox2}>
                <TextField
                  id="title"
                  label="Titulo"
                  name="title"
                  margin="dense"
                  autoComplete="off"
                  value={formik.values.title}
                  inputProps={{ minLength: 1, maxLength: 150 }}
                  onChange={formik.handleChange}
                  fullWidth
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={
                    formik.touched.title && Boolean(formik.errors.title)
                  }
                />
                <TextField
                  id="urlTitleImage"
                  label="URL da Imagem (Título)"
                  name="urlTitleImage"
                  margin="dense"
                  autoComplete="off"
                  value={formik.values.urlTitleImage}
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.touched.urlTitleImage &&
                    Boolean(formik.errors.urlTitleImage)
                  }
                  helperText={
                    formik.touched.urlTitleImage &&
                    Boolean(formik.errors.urlTitleImage)
                  }
                />
                <Box width="100%">
                  <FormHelperText
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    component="span"
                  >
                    {formik.touched.description &&
                    Boolean(formik.errors.description) ? (
                      <span style={{ marginTop: '1px' }}>
                        Descrição é obrigatório.
                      </span>
                    ) : (
                      <span className={classes.editorTitle}>Descrição</span>
                    )}
                  </FormHelperText>
                </Box>
                <Box
                  style={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                      ? { border: '1px solid #f54436', borderRadius: '5px' }
                      : undefined
                  }
                >
                  <RichTextEditor
                    propData={description}
                    setPropData={setDescription}
                    suggestionsProps={[]}
                    useMentions={false}
                    small
                    toolbarHidden={false}
                  />

                  <TextField
                    id="footer"
                    label="Rodapé"
                    name="footer"
                    margin="dense"
                    autoComplete="off"
                    value={formik.values.footer}
                    onChange={formik.handleChange}
                    fullWidth
                    error={
                      formik.touched.footer && Boolean(formik.errors.footer)
                    }
                    helperText={
                      formik.touched.footer && Boolean(formik.errors.footer)
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {(createOrEditAccounts || createOrEditContacts) && (
            <FixedAccountFields
              fixedAccountFields={fixedAccountFields}
              setFixedAccountFields={setFixedAccountFields}
              canCreateAccounts={formik.values.canCreateAccounts}
              canEditAccounts={formik.values.canEditAccounts}
              canCreateContacts={formik.values.canCreateContacts}
              canEditContacts={formik.values.canEditContacts}
              usesNameForAccountAndContact={
                formik.values.usesNameForAccountAndContact
              }
            />
          )}

          {(createOrEditAccounts || createOrEditContacts) && (
            <AccountFields
              accountFields={accountFields.sort(
                (entryA, entryB) => entryA.order - entryB.order,
              )}
              setAccountFields={setAccountFields}
            />
          )}

          <Box component={Paper} padding="10px" mt={2}>
            <Box width="100%" className={classes.tableTitle}>
              Campos personalizados de card
            </Box>
            <Box display="flex" flexDirection="row" mb={1}>
              <Autocomplete
                id="customFields"
                style={{ width: '45%' }}
                getOptionLabel={opt => opt.name || ''}
                getOptionSelected={(opt, value) => opt.id === value.id}
                options={
                  customFields
                    ? customFields.filter(
                        (c: ICustomField) =>
                          !formFields.find(ff => ff.id === c.id) &&
                          c.type !== 'INTEGRATED' &&
                          c.type !== 'DOCUMENT' &&
                          c.tableOrder === null,
                      )
                    : []
                }
                onChange={(e, value) => {
                  setSelectedCustomField(value);
                }}
                disabled={!customFields}
                value={selectedCustomField || null}
                loadingText="Carregando"
                size="small"
                noOptionsText="Sem campos disponíveis."
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="customFields"
                    label="Campos disponíveis"
                    margin="dense"
                    size="small"
                    name="customFields"
                    variant="outlined"
                    value={formik.values.customFields}
                    fullWidth
                    error={
                      formik.touched.customFields &&
                      Boolean(formik.errors.customFields)
                    }
                    helperText={
                      formik.touched.customFields &&
                      Boolean(formik.errors.customFields)
                    }
                    InputProps={{
                      ...rest.InputProps,
                      endAdornment: (
                        <>
                          {loadingCustomFields ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {rest.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <ButtonMaterialUI
                onClick={() => {
                  if (selectedCustomField !== null) {
                    selectedCustomField.order = formFields.length;
                    setFormFields(oldValues => [
                      ...oldValues,
                      selectedCustomField,
                    ]);
                    setSelectedCustomField(null);
                  }
                }}
                color="primary"
                size="medium"
                className={classes.addButton}
              >
                + Adicionar
              </ButtonMaterialUI>
            </Box>
            <FormFieldsTable
              formFields={formFields.sort(
                (entryA, entryB) => entryA.order - entryB.order,
              )}
              setFormFields={setFormFields}
            />
          </Box>

          <Box>
            <ButtonMaterialUI
              className={classes.buttonSubmit}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
