import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      boxSizing: 'border-box',
      width: '100%',
      margin: 0,
      padding: 0,
      backgroundColor: '#eaeeed',

      '& .react-pdf__Document': {
        backgroundColor: 'rgba(103,103,103,0.5)',
      },
      '& .react-pdf__Page': {
        border: '1px solid darkgrey',
        borderRight: '0px',
        backgroundColor: 'transparent',
      },
      '& .react-pdf__Page__annotations.annotationLayer': {
        display: 'none',
      },
      '& .react-pdf__Page__canvas': {
        margin: '0 auto',
      },
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
      boxSizing: 'border-box',
      minHeight: '64px',
    },
    appBarBottom: {
      top: 'auto',
      bottom: 0,
    },
    buttonsBox: {
      display: 'flex',
      gap: '0.5rem',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignSelf: 'flex-end',
      alignItems: 'center',
      boxSizing: 'border-box',
      '@media (max-width:600px)': {
        alignSelf: 'center',
        flexDirection: 'column',
        marginBottom: '0.5rem',
      },
    },
    swalAlert: {
      zIndex: 99999,
    },
  }),
);

export default useStyles;
