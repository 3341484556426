import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from 'src/styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  dialog: {
    width: '700px',
    minWidth: '300px',
    height: '90%',
    maxHeight: '800px',
    paddingBottom: 10,
    boxSizing: 'border-box',
  },
  children: {
    padding: 0,
    margin: 0,
    display: 'flex',
    overflow: 'auto',
    height: 'fit-content',
  },
  instructionContent: {
    border: `2px solid ${COLORS.BORDER}`,
    fontSize: '14px',
    boxSizing: 'border-box',
    width: '100%',
    height: '550px',
    padding: 15,
    whiteSpace: 'pre-line',
    borderRadius: '3px',
    overflow: 'auto',
    marginBottom: 10,
    '@media (max-height: 650px )': {
      height: '300px',
    },
    '@media (max-height: 400px )': {
      height: '150px',
    },
  },
  muiInputSearch: {
    marginBottom: 10,
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
  instructionsLabel: {
    backgroundColor: 'white',
    WebkitBackgroundClip: 'border-box',
    fontSize: '12px',
    fontWeight: 400,
    color: COLORS.BLUE,
    width: 'fit-content',
    height: '13px',
    paddingLeft: '2px',
    paddingRight: '2px',
    marginBottom: '-5px',
    marginLeft: 13,
    borderRadius: '5px',
    position: 'relative',
    zIndex: 1,
  },
  modalButtons: {
    fontSize: '14px',
    marginTop: 0,
    marginLeft: theme.spacing(2),
    '&:hover': { textDecoration: 'none' },
  },
});
