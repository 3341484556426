/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { areaApi, IAreaAll } from 'src/services/areaApi';

interface HookReturn {
  areas: IAreaAll[];
  loadingAreas: boolean;
}

export function useAreasAll(): HookReturn {
  const [areas, setAreas] = useState<IAreaAll[]>([]);
  const [loadingAreas, setLoadingAreas] = useState<boolean>(false);

  useEffect(() => {
    setLoadingAreas(true);
    areaApi
      .getAreasAll()
      .then((response: { data: IAreaAll[] }) => {
        setAreas(response.data);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Falha ao buscar lista de áreas. ${errMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoadingAreas(false);
      });
  }, []);

  return {
    areas,
    loadingAreas,
  } as HookReturn;
}
