import React, { createContext, useContext, useEffect, useState } from 'react';
import api from 'src/services/api';
import { useAuth } from './AuthContext';

interface FiltersData {
  funnel?: {
    startDate?: string;
    endDate?: string;
    user_id?: string;
    area_id?: string;
  };
  column?: {
    field_ids: string[];
    startDate?: string;
    endDate?: string;
    user_id?: string;
    area_id?: string;
  };
  cards?: {
    startDate?: string;
    endDate?: string;
    user_id?: string;
    area_ids?: string[];
    situations?: string[];
  };
  tasks?: {
    user_id?: string;
  };
}
interface WorkspaceFiltersContextType {
  filtersData: FiltersData;
  setFiltersData: React.Dispatch<React.SetStateAction<FiltersData>>;
  shouldReloadData: boolean;
  setShouldReloadData: React.Dispatch<React.SetStateAction<boolean>>;
  areas: { id: string; name: string; value: string }[];
  graphType: string;
  setGraphType: React.Dispatch<
    React.SetStateAction<'pie' | 'funnel' | 'fixed-funnel'>
  >;
  phase_ids: string[];
  setPhaseIds: React.Dispatch<React.SetStateAction<string[]>>;
  account_ids: string[];
  setAccountIds: React.Dispatch<React.SetStateAction<string[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  keycard: string;
  setKeycard: React.Dispatch<React.SetStateAction<string>>;
}

const WorkspaceFiltersContext = createContext<
  WorkspaceFiltersContextType | undefined
>({
  filtersData: {
    funnel: {},
    column: { field_ids: [] },
    cards: {},
    tasks: {},
  },
  setFiltersData: () => {},
  shouldReloadData: false,
  setShouldReloadData: () => {},
  areas: [],
  graphType: 'pie',
  setGraphType: () => {},
  phase_ids: [],
  setPhaseIds: () => {},
  account_ids: [],
  setAccountIds: () => {},
  loading: true,
  setLoading: () => {},
  keycard: '',
  setKeycard: () => {},
});

export const WorkspaceFiltersProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const userId = user.id;
  const workspaceFilterStorageKey = `@Brasao:WorkspaceFiltersData:v1:User[${userId}]`;
  const workspaceGraphTypeStorageKey = `@Brasao:WorkspaceGraphType:v1:User[${userId}]`;
  const loadInitialFiltersData = () => {
    const savedData = localStorage.getItem(workspaceFilterStorageKey);
    try {
      return savedData
        ? JSON.parse(savedData)
        : {
            funnel: {},
            column: { field_ids: [] },
            cards: {},
            tasks: {},
          };
    } catch (e) {
      return {
        funnel: {},
        column: { field_ids: [] },
        cards: {},
        tasks: {},
      };
    }
  };

  const [filtersData, setFiltersData] = useState<FiltersData>(
    loadInitialFiltersData(),
  );
  const [areas, setAreas] = useState<
    { id: string; name: string; value: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [phase_ids, setPhaseIds] = useState<string[]>([]);
  const [account_ids, setAccountIds] = useState<string[]>([]);
  const [shouldReloadData, setShouldReloadData] = useState(false);
  const [graphType, setGraphType] = useState<'funnel' | 'pie' | 'fixed-funnel'>(
    'pie',
  );
  const [keycard, setKeycard] = useState('');

  useEffect(() => {
    const savedGraphType = localStorage.getItem(workspaceGraphTypeStorageKey);
    setGraphType(
      savedGraphType
        ? (JSON.parse(savedGraphType) as 'funnel' | 'pie' | 'fixed-funnel')
        : 'pie',
    );
    const savedData = localStorage.getItem(workspaceFilterStorageKey);
    if (savedData !== null && savedData !== undefined) {
      const parseData = JSON.parse(savedData);
      const newFilter = {
        ...parseData,
        tasks: {},
      };
      setFiltersData(newFilter);
    }
  }, []);

  useEffect(() => {
    api.get('/areas').then(response => {
      setAreas(
        response.data.map(
          (area: { id: string; name: string; description: string }) => ({
            id: area.id,
            name: area.name,
            value: area.id,
          }),
        ),
      );
    });
  }, []);

  useEffect(() => {
    const newFilter = {
      ...filtersData,
      tasks: {},
    };
    localStorage.setItem(workspaceFilterStorageKey, JSON.stringify(newFilter));
    localStorage.setItem(
      workspaceGraphTypeStorageKey,
      JSON.stringify(graphType),
    );
  }, [filtersData]);

  return (
    <WorkspaceFiltersContext.Provider
      value={{
        filtersData,
        setFiltersData,
        shouldReloadData,
        setShouldReloadData,
        areas,
        graphType,
        setGraphType,
        phase_ids,
        setPhaseIds,
        account_ids,
        setAccountIds,
        loading,
        setLoading,
        keycard,
        setKeycard,
      }}
    >
      {children}
    </WorkspaceFiltersContext.Provider>
  );
};

export const useWorkspaceFilters = (): {
  filtersData: FiltersData;
  setFiltersData: React.Dispatch<React.SetStateAction<FiltersData>>;
  shouldReloadData: boolean;
  setShouldReloadData: React.Dispatch<React.SetStateAction<boolean>>;
  areas: { id: string; name: string; value: string }[];
  graphType: string;
  setGraphType: React.Dispatch<
    React.SetStateAction<'pie' | 'funnel' | 'fixed-funnel'>
  >;
  phase_ids: string[];
  setPhaseIds: React.Dispatch<React.SetStateAction<string[]>>;
  account_ids: string[];
  setAccountIds: React.Dispatch<React.SetStateAction<string[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  keycard: string;
  setKeycard: React.Dispatch<React.SetStateAction<string>>;
} => {
  const filtersData = useContext(WorkspaceFiltersContext);
  if (!filtersData) {
    throw new Error(
      'useWorkspaceFilters must be used within a WorkspaceFiltersProvider',
    );
  }
  return filtersData;
};
