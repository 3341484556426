/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

import { IFormFields } from '../Form.i';
import { handleChangeFieldDown, handleChangeFieldUp } from '../utils/formUtils';

export const FormFieldsTable: React.FC<IFormFields> = ({
  formFields,
  setFormFields,
}: IFormFields) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleDeleteField(index: number) {
    const tempFields = [...formFields];
    tempFields.splice(index, 1);
    setFormFields(tempFields);
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="lista de campos personalizados">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nome do campo</TableCell>
            <TableCell align="center">Obrigatório</TableCell>
            <TableCell align="center">Excluir</TableCell>
            <TableCell colSpan={2} align="center">
              {' '}
              Ordenar{' '}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? formFields.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : formFields
          ).map((field, index) => (
            <TableRow key={field.id}>
              <TableCell
                component="th"
                scope="row"
                align="left"
                style={{ maxWidth: '300px', overflow: 'hidden' }}
              >
                {field.name}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {field.isRequired ? 'Sim' : 'Não'}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                align="center"
                style={{ width: '40px' }}
              >
                <IconButton
                  aria-label="Excluir campo"
                  size="small"
                  onClick={() => handleDeleteField(index)}
                >
                  <ClearRoundedIcon />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" style={{ width: '40px' }}>
                <IconButton
                  aria-label="Campo para cima"
                  size="small"
                  disabled={formFields[0].id === field.id}
                  onClick={() =>
                    handleChangeFieldUp(field, formFields, setFormFields)
                  }
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row" style={{ width: '40px' }}>
                <IconButton
                  aria-label="Campo para baixo"
                  size="small"
                  disabled={formFields[formFields.length - 1].id === field.id}
                  onClick={() =>
                    handleChangeFieldDown(field, formFields, setFormFields)
                  }
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow style={{ paddingLeft: 0, paddingRight: 0 }}>
            <TablePagination
              rowsPerPageOptions={[10, 15, { label: 'Todos', value: -1 }]}
              colSpan={5}
              count={formFields.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Linhas por página"
              SelectProps={{
                inputProps: { 'aria-label': 'Linhas por Página' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
