/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { IAreaAll } from 'src/services/areaApi';
import { IUserAll } from 'src/services/userApi';
import { PendingReassignments } from '../../Account.i';
import { useReponsibleHook } from '../../hooks/useReponsibleHook';

interface ResponsibleUserDialogProps {
  open: boolean;
  handleCloseResponsibleUserDialog: () => void;
  responsibleUsers: IUserAll[];
  users: IUserAll[];
  areas: IAreaAll[];
  pendingReassignments: PendingReassignments;
  account_id: string;
}

export const ResponsibleUserEditDialog: React.FC<
  ResponsibleUserDialogProps
> = ({
  open,
  handleCloseResponsibleUserDialog,
  responsibleUsers,
  users,
  areas,
  pendingReassignments,
  account_id,
}) => {
  const {
    reassignments,
    renderRemovedUsers,
    handleSubmitReassignments,
    isSubmitting,
  } = useReponsibleHook({
    handleCloseResponsibleUserDialog,
    responsibleUsers,
    users,
    areas,
    pendingReassignments,
    account_id,
  });

  return (
    <Dialog open={open} onOpenChange={undefined} modal>
      <DialogOverlay className="z-[1500]" />
      <DialogContent
        autoFocus
        onKeyDown={e => {
          if (open && e.key === 'Escape') {
            e.stopPropagation();
            e.preventDefault();
            // handleCloseResponsibleUserDialog();
          }
        }}
        className="z-[1500] w-full min-w-fit max-w-2xl box-border"
      >
        <DialogHeader>
          <DialogTitle className="flex flex-row items-center">
            Troca de responsável
          </DialogTitle>
          <DialogDescription>
            Você está trocando o responsável da conta. Ao confirmar a ação, os
            cards e tarefas do antigo serão transferidos ao novo responsável
            associado.
          </DialogDescription>
        </DialogHeader>
        {(reassignments.withCard.length > 0 ||
          reassignments.withoutCard.length > 0) && (
          <div className="w-full overflow-auto mb-8 box-border">
            <strong>Responsáveis removidos:</strong>
            <ul className="w-full box-border">
              {reassignments.withCard.length > 0 &&
                renderRemovedUsers('withCard')}
              {reassignments.withoutCard.length > 0 &&
                renderRemovedUsers('withoutCard')}
            </ul>
          </div>
        )}
        <DialogFooter>
          <Button
            onClick={handleCloseResponsibleUserDialog}
            variant="secondary"
            disabled={isSubmitting}
          >
            Fechar
          </Button>
          <Button onClick={handleSubmitReassignments} disabled={isSubmitting}>
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
