import { TaskFieldTypes } from './fieldTypes.constants';

const TASK_TYPE = {
  CALL: 'CALL',
  ONLINE_MEETING: 'ONLINE_MEETING',
  IN_PERSON_MEETING: 'IN_PERSON_MEETING',
  DOCUMENT_SENDING: 'DOCUMENT_SENDING',
  ACTIVITY: 'ACTIVITY',
  APPROVAL: 'APPROVAL',
} as const;

export type TaskTypesIds = keyof typeof TASK_TYPE;

export const TaskStatus = Object.freeze({
  UNSCHEDULED: { id: 'UNSCHEDULED', name: 'Não agendada' },
  SCHEDULED: { id: 'SCHEDULED', name: 'Agendada' },
  SCHEDULE_EXPIRED: { id: 'SCHEDULE_EXPIRED', name: 'Agendamento vencido' },
  COMPLETED: { id: 'COMPLETED', name: 'Concluída' },
});

export const TaskAssociatedFields = Object.freeze({
  CONTACT_PHONE: 'CONTACT_PHONE',
  GUEST_USERS: 'GUEST_USERS',
  CONTACTS: 'CONTACTS',
});

export const TaskFields = Object.freeze({
  SUBJECT: {
    id: 'SUBJECT',
    name: 'Assunto',
    type: TaskFieldTypes.STRING,
  },
  MEETING_URL: {
    id: 'MEETING_URL',
    name: 'URL da Reunião',
    type: TaskFieldTypes.STRING,
  },
  MEETING_ADDRESS: {
    id: 'MEETING_ADDRESS',
    name: 'Endereço da Reunião',
    type: TaskFieldTypes.STRING,
  },
  APPROVAL: {
    id: 'APPROVAL',
    name: 'Aprovação',
    type: TaskFieldTypes.STRING,
  },
});

type ITaskType = {
  id: string;
  name: string;
  fields: { id: string; name: string; type: string }[];
  associatedFields?: string[];
};

type ITaskTypesMap = {
  [key: string]: ITaskType;
};

export const TaskTypes: ITaskTypesMap = Object.freeze({
  CALL: {
    id: 'CALL',
    name: 'Ligação',
    fields: [],
    associatedFields: [],
  },
  ONLINE_MEETING: {
    id: 'ONLINE_MEETING',
    name: 'Reunião Online',
    fields: [TaskFields.SUBJECT, TaskFields.MEETING_URL],
    associatedFields: [
      TaskAssociatedFields.GUEST_USERS,
      TaskAssociatedFields.CONTACTS,
    ],
  },
  IN_PERSON_MEETING: {
    id: 'IN_PERSON_MEETING',
    name: 'Reunião Presencial',
    fields: [TaskFields.SUBJECT, TaskFields.MEETING_ADDRESS],
    associatedFields: [
      TaskAssociatedFields.GUEST_USERS,
      TaskAssociatedFields.CONTACTS,
    ],
  },
  DOCUMENT_SENDING: {
    id: 'DOCUMENT_SENDING',
    name: 'Envio de Documento',
    fields: [],
  },
  ACTIVITY: {
    id: 'ACTIVITY',
    name: 'Atividade',
    fields: [],
    associatedFields: [TaskAssociatedFields.GUEST_USERS],
  },
  APPROVAL: {
    id: 'APPROVAL',
    name: 'Aprovação',
    fields: [],
  },
} as ITaskTypesMap);
