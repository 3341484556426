/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

interface ICsvErrorsProps {
  csvImportErrors: ICSVRowError[];
}

interface ICSVRowError {
  row: number;
  error: string;
}

export function CsvErrors({ csvImportErrors }: ICsvErrorsProps): JSX.Element {
  const [showErrors, setShowErrors] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 5,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box
        mt="15px"
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        width="100%"
        onClick={() => setShowErrors(!showErrors)}
      >
        <Typography variant="subtitle2">Erros encontrados:</Typography>
        {showErrors ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>

      {showErrors && (
        <Box width="99%" marginTop="0.5rem" marginBottom="0.5rem">
          <TableContainer
            component={Paper}
            style={{ backgroundColor: '#eaeeed' }}
          >
            <Table
              size="small"
              aria-label="lista de erros de importação do CSV"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: '30px' }}>
                    Linha
                  </TableCell>
                  <TableCell align="left" width="100%">
                    Descrição
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? csvImportErrors.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : csvImportErrors
                ).map((error, index) => (
                  <TableRow key={error.row + index}>
                    <TableCell
                      scope="row"
                      align="center"
                      style={{ width: '30px' }}
                    >
                      {error.row + 1}
                    </TableCell>
                    <TableCell scope="row" align="left" width="100%">
                      {error.error}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, { label: 'Todos', value: -1 }]}
                    colSpan={2}
                    count={csvImportErrors.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': '' },
                      native: true,
                      style: { margin: 0 },
                    }}
                    labelRowsPerPage="Linhas por página"
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    padding="none"
                    size="small"
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Divider
        style={{
          width: '100%',
          marginTop: '0.2rem',
          marginBottom: '0.2rem',
        }}
      />
    </>
  );
}
