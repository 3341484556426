import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogOverlay,
} from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import ReactMarkdown from 'react-markdown';
import { useResponseChat } from '../hooks/useResponseChat';
import Table from './Table';
import LoadingMessage from './LoadingMessage';
import { VisionIADialogProps } from '../Visions.i';
import '../styles/VisionAIDialogStyles.css';

export const VisionAIDialog: React.FC<VisionIADialogProps> = ({
  open,
  handleCloseDialog,
  vision_id,
  visionName,
}) => {
  const { combinedContent, loading, exportContentToPDF } =
    useResponseChat(vision_id);

  return (
    <Dialog
      open={open}
      onOpenChange={isOpen => {
        if (!isOpen) handleCloseDialog();
      }}
    >
      <DialogOverlay className="z-[1400]" />
      <DialogContent className="z-[1500] overflow-auto max-h-[80vh] w-full min-w-fit max-w-5xl box-border p-6">
        <DialogHeader />

        <div id="pdf-content" className="m-2 space-y-6">
          <div>
            <div className="flex justify-between items-center">
              <DialogTitle className="text-3xl font-bold text-black mb-4">
                {visionName}
              </DialogTitle>
            </div>

            {loading ? (
              <LoadingMessage />
            ) : combinedContent.length > 0 ? (
              combinedContent.map(item => (
                <div key={item.content.id || Math.random()} className="mb-4">
                  {item.type === 'text' ? (
                    <div>
                      <h2 className="text-2xl font-bold text-black">
                        <ReactMarkdown>{item.content.title}</ReactMarkdown>
                      </h2>
                      <p className="italic mb-4 ml-1 text-gray-400">
                        Análise gerada por Inteligência Artificial
                      </p>
                      <ReactMarkdown>{item.content.description}</ReactMarkdown>
                      <ReactMarkdown>{item.content.resume}</ReactMarkdown>
                      <ReactMarkdown>
                        {item.content.important_points}
                      </ReactMarkdown>
                      <ReactMarkdown>{item.content.missing_data}</ReactMarkdown>
                      <ReactMarkdown>{item.content.notes}</ReactMarkdown>
                    </div>
                  ) : (
                    <Table
                      title={item.content.title || ''}
                      columns={item.content.jsonTable.columns || []}
                      data={item.content.jsonTable.data || []}
                    />
                  )}
                </div>
              ))
            ) : (
              <DialogDescription className="text-lg text-gray-600 mb-4">
                Ops, parece que tivemos um pequeno problema ao analisar a visão.
                Tente novamente.
              </DialogDescription>
            )}
          </div>
        </div>

        {!loading && combinedContent.length > 0 && (
          <div className="flex justify-end mt-4">
            <Button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => exportContentToPDF()}
            >
              Baixar em PDF
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VisionAIDialog;
