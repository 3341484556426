import { useState, useRef, useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import { showToast } from 'src/utils/showToast';

interface IStorageLog {
  signerId: string;
  documentViewed: boolean;
  pagesViewed: number[];
  signed: boolean;
}

const usePdfDocument = (
  signer_id: string,
  token: string,
  hash: string,
  setSignerLocation: (location: string) => void,
) => {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number | null>(null);
  const [alreadySigned, setAlreadySigned] = useState<boolean>(false);
  const [documentStorageLog, setDocumentStorageLog] = useState<IStorageLog>({
    signerId: signer_id,
    documentViewed: true,
    pagesViewed: [],
    signed: false,
  });

  const topAppBarRef = useRef<HTMLDivElement | null>(null);
  const bottomAppBarRef = useRef<HTMLDivElement | null>(null);
  const [appBarsHeight, setAppBarsHeight] = useState<number>(0);

  const resizeHeight = useCallback(() => {
    if (topAppBarRef.current && bottomAppBarRef.current) {
      setAppBarsHeight(
        topAppBarRef.current.offsetHeight +
          bottomAppBarRef.current.offsetHeight,
      );
    }
  }, [bottomAppBarRef, topAppBarRef]);

  useEffect(() => {
    window.addEventListener('resize', resizeHeight);
    return () => {
      window.removeEventListener('resize', resizeHeight);
    };
  }, [resizeHeight]);

  const onDocumentLoadSuccess = ({ numPages = 1 }: { numPages: number }) => {
    setNumberOfPages(numPages);
    setPageNumber(1);
    handleUpdateSignerLocation();

    const documentInfo = localStorage.getItem(
      `@BPM-Signature${signer_id}${token}:documentInfo`,
    );

    if (documentInfo) {
      setDocumentStorageLog(JSON.parse(documentInfo));
    } else {
      api
        .post(`/publicSignatures/signers/${signer_id}/logs`, {
          action: `Página 1 visualizada.`,
        })
        .then(response => {
          const newLogValue = {
            signerId: signer_id,
            documentViewed: true,
            pagesViewed: [1],
            signed: false,
          };

          if (response.data) {
            localStorage.setItem(
              `@BPM-Signature${signer_id}${token}:documentInfo`,
              JSON.stringify(newLogValue),
            );
            setDocumentStorageLog(newLogValue);
          }
        });
    }
  };

  function changePage(offset: number) {
    const currentPage = pageNumber + offset;
    if (
      documentStorageLog &&
      'pagesViewed' in documentStorageLog &&
      !documentStorageLog?.pagesViewed.includes(currentPage)
    ) {
      const tempLog = documentStorageLog;
      tempLog.pagesViewed.push(currentPage);

      api
        .post(`/publicSignatures/signers/${signer_id}/logs`, {
          action: `Página ${currentPage} visualizada.`,
        })
        .then(response => {
          if (response.data) {
            localStorage.setItem(
              `@BPM-Signature${signer_id}${token}:documentInfo`,
              JSON.stringify(tempLog),
            );
            setDocumentStorageLog(tempLog);
          }
        });
    }
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleUpdateSignerLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setSignerLocation(`${latitude} / ${longitude}`);
        },
        () => {
          setSignerLocation('');
        },
      );
    } else {
      setSignerLocation('');
    }
  };

  const handleOnSignDocument = () => {
    Swal.fire({
      title: 'Observação',
      text: 'Para garantir a integridade do processo, algumas informações serão coletadas e mantidas em nosso sistema para assegurar a validade da assinatura. Sendo o IP, geolocalização e data/hora no momento da confirmação.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#53a546',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      setAlreadySigned(true);

      if (result.isConfirmed) {
        const sendingSignature = toast.loading(
          `Enviando coleta de assinatura.`,
        );

        try {
          const response = await api.post(
            `/publicSignatures/signers/${signer_id}/sign`,
            {
              hash,
              userInfo: {
                Geolocation: localStorage.getItem('signerLocation') || '',
              },
            },
          );

          if (response.status === 204 || response.status === 200) {
            localStorage.removeItem(
              `@BPM-Signature${signer_id}${token}:documentInfo`,
            );

            toast.update(sendingSignature, {
              render: `Assinatura concluída com sucesso.`,
              type: 'success',
              isLoading: false,
              theme: 'colored',
              autoClose: 2000,
              onClose: () => history.push(`/sign/${signer_id}/completed`),
            });
          } else {
            throw new Error('Unexpected response status');
          }
        } catch (e) {
          showToast(`Falha ao assinar documento. Tente novamente.`, 'error');
          toast.update(sendingSignature, {
            isLoading: false,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      } else {
        setAlreadySigned(false);
      }
    });
  };

  return {
    pageNumber,
    numberOfPages,
    alreadySigned,
    topAppBarRef,
    bottomAppBarRef,
    appBarsHeight,
    previousPage,
    nextPage,
    onDocumentLoadSuccess,
    handleOnSignDocument,
  };
};

export default usePdfDocument;
