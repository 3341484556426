/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import './style.css';
import api from '../../services/api';

type MentionItem = {
  id: string;
  name: string;
};

interface IEditorProps {
  propData: string;
  setPropData: (data: string) => void;
  suggestionsProps: MentionItem[];
  toolbarHidden?: boolean;
  small: boolean;
  suggestOnlyCustomFields?: boolean;
  useMentions?: boolean;
}

const mentionItems: MentionItem[] = [
  { id: '@NOME_CONTA', name: 'Conta - Nome' },
  { id: '@CPF_CNPJ_CONTA', name: 'Conta - CPF/CNPJ' },
  { id: '@RAZAO_SOCIAL', name: 'Conta - Razão social' },
  { id: '@MUNICIPIO', name: 'Conta - Município' },
  { id: '@CODIGO_IBGE', name: 'Conta - Código IBGE' },
  { id: '@CEP', name: 'Conta - CEP' },
  { id: '@TELEFONE', name: 'Conta - Telefone' },
  { id: '@BAIRRO', name: 'Conta - Bairro' },
  { id: '@RUA', name: 'Conta - Rua' },
  { id: '@NUMERO_ENDERECO', name: 'Conta - Número' },
  { id: '@COMPLEMENTO', name: 'Conta - Complemento' },
  { id: '@ESTADO', name: 'Conta - Estado' },
  { id: '@NOME_CONTATO', name: 'Contato - Nome' },
  { id: '@EMAIL_CONTATO', name: 'Contato - E-mail' },
  { id: '@FONE_CONTATO', name: 'Contato - Telefone' },
  { id: '@NOME_USUARIO', name: 'Usuário - Nome' },
  { id: '@EMAIL_USUARIO', name: 'Usuário - E-mail' },
  { id: '@NOME_COMPANHIA', name: 'Companhia - Nome' },
  { id: '@CNPJ_COMPANHIA', name: 'Companhia - CNPJ' },
  { id: '@NOME_UNIDADE', name: 'Empresa - Nome' },
  { id: '@NOME_AREA', name: 'Área - Nome' },
  { id: '@NOME_FASE', name: 'Fase - Nome' },
  { id: '@DATA_ATUAL', name: 'Data atual' },
  { id: '@CODIGO_CARD', name: 'Card - Key' },
];

export const RichTextEditor: React.FC<IEditorProps> = ({
  propData,
  setPropData,
  suggestionsProps,
  toolbarHidden,
  small,
  suggestOnlyCustomFields = false,
  useMentions = true,
}) => {
  const [editorConfig, setEditorConfig] = React.useState<any>();

  async function getMentionConfig() {
    if (!useMentions) return {};

    let accountFields: MentionItem[] = [];
    if (suggestOnlyCustomFields === false) {
      const promise = await api.get('/accountFields');

      const customAccountFields = promise.data.map((field: any) => ({
        id: `@${field.slug}`,
        name: `Conta - ${field.name}`,
      }));

      accountFields = [...customAccountFields];
    }

    const mentions: MentionItem[] = [
      ...accountFields,
      ...(suggestionsProps || []),
      ...(suggestOnlyCustomFields === false ? mentionItems : []),
    ].sort((a, b) => -b.name.localeCompare(a.name));

    return {
      feeds: [
        {
          feed: mentions,
          mentions,
          dropdownLimit: 100,
          marker: '@',
          minimumCharacters: 0,
          itemRenderer: (item: any) => `${item.name}`,
        },
      ],
    };
  }

  async function buildEditorConfig() {
    const baseConfig = { ...Editor.defaultConfig };
    baseConfig.initialData = propData;
    if (useMentions) {
      const mentions = await getMentionConfig();
      baseConfig.mention = mentions;
    }
    return baseConfig;
  }

  useEffect(() => {
    const result = buildEditorConfig();
    result.then(config => {
      setEditorConfig(config);
    });
  }, [useMentions, suggestionsProps]);

  return (
    <div className={small ? 'document-editor-small' : 'document-editor'}>
      {!toolbarHidden && <div className="document-editor__toolbar" />}
      <div
        className={
          small
            ? 'document-editor__editable-container-small'
            : 'document-editor__editable-container'
        }
      >
        {editorConfig && (
          <CKEditor
            editor={Editor}
            data={propData}
            onReady={(editor: any) => {
              if (!toolbarHidden) {
                const toolbarContainer = document.querySelector(
                  '.document-editor__toolbar',
                );
                toolbarContainer!.appendChild(editor.ui.view.toolbar.element);
              }
            }}
            onBlur={(event: any, editor: any) => {
              setPropData(editor.getData());
            }}
            config={editorConfig}
          />
        )}
      </div>
    </div>
  );
};
