import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  children: {
    padding: 0,
    margin: 0,
    paddingBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
    },
    '& fieldset': {
      borderColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  dialog: {
    width: '450px',
    maxHeight: '50vh',
    minHeight: '85vh',
  },
  buttonSubmit: {
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  content: {
    maxWidth: 500,
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
});
