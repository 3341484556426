import { StyleRules } from '@material-ui/core';

export const autocompleteStyles = (): StyleRules => ({
  optionsCheckBox: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
});
