import React from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import { styles } from './styles';
import { ICustomField } from './Search.i';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface IntegratedInputProps {
  customField: ICustomField;
  customFieldIndex: number;
  handleOpenIntegratedItemsModal: (
    customField: ICustomField,
    customFieldIndex: number,
  ) => void;
  handleSetCustomField: (cf: ICustomField, selectedfinish: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const IntegratedSearchInput = ({
  customField,
  customFieldIndex,
  handleOpenIntegratedItemsModal,
  handleSetCustomField,
}: IntegratedInputProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.typeIntegrated}>
      <Box className={classes.customFieldIntegrationTypeContainer}>
        {customField.valueJSON &&
        Object.keys(customField.valueJSON).length !== 0 ? (
          <>
            <Typography
              component="span"
              className={classes.integratedFieldPrimaryKey}
            >
              {
                customField.valueJSON[
                  customField.primaryKeyColumnName
                ] as string
              }
            </Typography>
            <Typography
              align="left"
              component="span"
              className={classes.integratedFieldDisplayColumnName}
            >
              {`|
                ${customField.valueJSON[customField.displayColumnName]}`}
            </Typography>
          </>
        ) : (
          <TextField
            className={classes.integratedFieldDummyTextField}
            contentEditable={false}
            autoComplete="off"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              disableUnderline: true,
              onKeyDown: (e: { preventDefault: () => void }) => {
                e.preventDefault();
                return false;
              },
            }}
          />
        )}
      </Box>
      <Box className={classes.integratedFieldButtons}>
        {customField.valueJSON &&
          Object.keys(customField.valueJSON).length !== 0 && (
            <IconButton
              aria-label="Excluir"
              size="small"
              className={classes.typeIntegratedButton}
              onClick={() => {
                const newCustomField = { ...customField };
                newCustomField.valueJSON = {};
                handleSetCustomField(newCustomField, false);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
        <IconButton
          aria-label="Procurar campo integrado"
          size="small"
          className={classes.typeIntegratedButton}
          onClick={() =>
            handleOpenIntegratedItemsModal(customField, customFieldIndex)
          }
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
