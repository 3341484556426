/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Button as ButtonMaterialUI,
  createStyles,
  makeStyles,
  Theme,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { Edit } from '@material-ui/icons';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { IActionsProps, ITriggerAction } from '../Trigger.i';
import { styles } from '../styles';
import { ActionModal } from './ActionModal';
import { actionTypes } from '../utils/triggerUtil';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const Actions: React.FC<IActionsProps> = ({
  triggerActions,
  setTriggerActions,
  areas,
  phases,
  currentAreaId,
  currentPhaseId,
  customFields,
  filesToSubmit,
  setFilesToSubmit,
  triggerType,
}: IActionsProps) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState<ITriggerAction | undefined>(undefined);
  const [actionIndex, setActionIndex] = useState<number | undefined>(undefined);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 5,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleEditAction(index: number) {
    const sortActions = triggerActions.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const findAction = sortActions[index];
    setAction(findAction);
    setActionIndex(index);
    setOpenModal(true);
  }

  function handleAddAction() {
    setAction(undefined);
    setActionIndex(undefined);
    setOpenModal(true);
  }

  function handleDeleteAction(index: number) {
    const tempActions = [...triggerActions];
    tempActions.splice(index, 1);
    setTriggerActions(tempActions);
  }

  return (
    <>
      <Box width="100%" className={classes.tableTitle}>
        Ações
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        component={Paper}
        className={classes.conditionsBox}
      >
        <TableContainer component={Paper}>
          <Table size="small" aria-label="lista de gatilhos">
            <TableHead>
              <TableRow>
                <TableCell align="left">Descrição</TableCell>
                <TableCell align="left">Tipo</TableCell>
                <TableCell align="center">Editar</TableCell>
                <TableCell align="center">Excluir</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? triggerActions.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : triggerActions
              )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((action, index) => (
                  <TableRow key={action.id || index + action.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ maxWidth: '300px', overflow: 'hidden' }}
                    >
                      {action.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {
                        actionTypes[
                          actionTypes.findIndex(a => a.id === action.type)
                        ].label
                      }
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ width: '40px' }}
                    >
                      <IconButton
                        aria-label="Editar ação"
                        size="small"
                        onClick={() => handleEditAction(index)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ width: '40px' }}
                    >
                      <IconButton
                        aria-label="Excluir ação"
                        size="small"
                        onClick={() => handleDeleteAction(index)}
                      >
                        <ClearRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow style={{ paddingLeft: 0, paddingRight: 0 }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, { label: 'Todos', value: -1 }]}
                  colSpan={4}
                  count={triggerActions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Linhas por página"
                  SelectProps={{
                    inputProps: { 'aria-label': 'Linhas por Página' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {openModal && (
          <ActionModal
            open={openModal}
            closeModal={handleCloseModal}
            title={action && actionIndex ? 'Editar Ação' : 'Adicionar Ação'}
            titleLeft=""
            actionIndex={actionIndex}
            action={action}
            triggerActions={triggerActions}
            setTriggerActions={setTriggerActions}
            areas={areas}
            phases={phases}
            currentAreaId={currentAreaId}
            currentPhaseId={currentPhaseId}
            customFields={customFields}
            filesToSubmit={filesToSubmit}
            setFilesToSubmit={setFilesToSubmit}
            setAction={setAction}
            triggerType={triggerType}
          />
        )}
        <ButtonMaterialUI
          onClick={handleAddAction}
          size="small"
          color="primary"
          className={classes.addButton}
        >
          + Adicionar Ação
        </ButtonMaterialUI>
      </Box>
    </>
  );
};
