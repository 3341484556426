/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';
import { IPhaseCards } from '../interface/IPhaseCards';
import { useAuth } from './AuthContext';

interface IFilter {
  area_id?: string;
  account_ids?: string[];
  contact_ids?: string[];
  user_ids?: string[];
  card_key?: string;
  refreshFilter?: boolean;
  filterTotalizer?: boolean;
}

interface FilterContextData {
  handleFilters(area_id: IFilter): Promise<void>;
  phases: IPhaseCards[];
  filterArea: string;
  storedAccountIds: string[];
  storedContactIds: string[];
  storedUserIds: string[];
  storedCardKey: string;
  filterTotalizer: boolean;
}

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

const FilterProvider: React.FC = ({ children }) => {
  const [phases, setPhases] = useState<IPhaseCards[]>([]);
  const [storedAreaId, setStoredAreaId] = useState('');
  const [storedAccountIds, setStoredAccountIds] = useState<string[]>([]);
  const [storedContactIds, setStoredContactIds] = useState<string[]>([]);
  const [storedUserIds, setStoredUserIds] = useState<string[]>([]);
  const [storedCardKey, setStoredCardKey] = useState('');
  const [filterTotalizer, setFilterTotalizer] = useState(false);
  const { user } = useAuth();

  const handleFilters = useCallback(
    async ({
      area_id,
      account_ids,
      contact_ids,
      user_ids,
      card_key,
      refreshFilter,
    }: IFilter) => {
      let newPhases: IPhaseCards[];
      if (area_id) {
        setStoredAreaId(area_id);
        const response = await api.get(`areas/${area_id}/phases/cards`);
        newPhases = response.data;
      } else if (storedAreaId) {
        const response = await api.get(`areas/${storedAreaId}/phases/cards`);
        newPhases = response.data;
      } else {
        const userAreaId = user.areaId;
        setStoredAreaId(userAreaId);
        const response = await api.get(`areas/${userAreaId}/phases/cards`);
        newPhases = response.data;
      }

      let accountsToInclude: string[] = [];
      let contactsToInclude: string[] = [];
      let usersToInclude: string[] = [];
      let cardToInclude = '';

      if (refreshFilter) {
        accountsToInclude = storedAccountIds;
        contactsToInclude = storedContactIds;
        usersToInclude = storedUserIds;
        cardToInclude = storedCardKey;
      } else {
        setFilterTotalizer(false);
        if (typeof user_ids !== 'undefined') {
          setStoredUserIds(user_ids);
          usersToInclude = user_ids;
        }

        if (typeof account_ids !== 'undefined') {
          setStoredAccountIds(account_ids);
          accountsToInclude = account_ids;
        }

        if (typeof contact_ids !== 'undefined') {
          setStoredContactIds(contact_ids);
          contactsToInclude = contact_ids;
        }

        if (typeof card_key !== 'undefined') {
          setStoredCardKey(card_key);
          cardToInclude = card_key;
        }
      }

      if (
        accountsToInclude.length > 0 ||
        contactsToInclude.length > 0 ||
        usersToInclude.length > 0 ||
        cardToInclude.length > 0
      ) {
        setFilterTotalizer(true);
        newPhases = newPhases.map(phase => {
          return {
            ...phase,
            cards: phase.cards.filter(card => {
              let shouldIncludeCard = true;

              if (cardToInclude.length > 0) {
                shouldIncludeCard =
                  shouldIncludeCard &&
                  cardToInclude === card.keyCard.toString();
                return shouldIncludeCard;
              }
              if (accountsToInclude.length > 0) {
                shouldIncludeCard =
                  shouldIncludeCard &&
                  accountsToInclude.includes(card.account.id);
              }
              if (contactsToInclude.length > 0) {
                shouldIncludeCard =
                  shouldIncludeCard &&
                  contactsToInclude.includes(card.account.contact.id);
              }
              if (usersToInclude.length > 0) {
                shouldIncludeCard =
                  shouldIncludeCard && usersToInclude.includes(card.user.id);
              }
              return shouldIncludeCard;
            }),
          };
        });
      }

      setPhases(newPhases);
    },
    [
      storedAreaId,
      storedAccountIds,
      storedContactIds,
      storedUserIds,
      storedCardKey,
      user.areaId,
    ],
  );

  useEffect(() => {
    localStorage.setItem('@Brasao:area', storedAreaId);
  }, [storedAreaId]);

  return (
    <FilterContext.Provider
      value={{
        handleFilters,
        phases,
        filterArea: storedAreaId,
        storedAccountIds,
        filterTotalizer,
        storedContactIds,
        storedUserIds,
        storedCardKey,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

function useFilters(): FilterContextData {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilters must be used within an FilterProvider');
  }
  return context;
}

export { FilterProvider, useFilters };
