import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    paddingBottom: 15,
    margin: 0,
    padding: 0,
    paddingLeft: 35,
    paddingRight: 35,
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: { fontSize: '30px', fontWeight: 'bold', marginTop: '30px' },
  editableTriggerContainer: {
    width: '100%',
    maxWidth: '1000px',
    height: '100%',
    marginBottom: 35,
  },
  newTriggerContainer: {
    width: '100%',
    height: '100%',
  },
  defaultTriggerData: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 30,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    },
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  editableTriggerFields: {
    marginTop: '20px',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  newTriggerModalFields: {
    width: '100%',
    margin: 0,
    marginTop: '20px',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  boxTable: {
    width: '100%',
    maxHeight: '100%',
    marginTop: 5,
    marginBottom: 5,
  },
  tableTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.BLUE,
    width: '100%',
    height: '22px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionsBox: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginRight: theme.spacing(1),
    fontSize: '12px',
    fontWeight: 500,
    color: '#676767',
    margin: '3px 0',
    borderRadius: '5px',
  },
  conditionsBox: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  conditionGroup: {
    backgroundColor: '#fafafa',
    borderRadius: '5px',
    width: '100%',
    height: 'auto',
    padding: '0 5px',
  },
  conditionGroupHeader: {
    width: '100%',
  },
  conditionGroupTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#676767',
    width: '100%',
    height: '22px',
  },
  conditionItems: {
    flexDirection: 'column',
    width: '100%',
    borderRadius: '5px',
    padding: '0 5px',
  },
  conditionItemRow: {
    backgroundColor: 'white',
    padding: '0 5px',
    borderRadius: '5px',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLACK,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLACK,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLACK,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  conditionField: {
    marginRight: '3px',
  },
});
