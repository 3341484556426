/* eslint-disable camelcase */

import React from 'react';
import { DispatchTypeArea } from './area';
// import { DispatchTypeDefault } from './default';
import { DispatchTypeCardFinish } from './finish';
import { DispatchTypeCardSubodinate } from './subordinate';
import { DispatchTypeSuspend } from './suspend';
import { DispatchTypeUser } from './user';
import { DispatchTypeContact } from './contact';
import { IUser } from '../dispatchOptions';

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IDispatchTypesProps {
  users: IUser[] | null;
  type: IDispatchType | null;
  cardId?: string;
  onClose: () => void;
  onDispatchCard: () => void;
}

interface IContentType {
  [key: string]: React.ReactElement;
}

const DispatchTypes: React.FC<IDispatchTypesProps> = ({
  cardId,
  users,
  type,
  onClose = () => null,
  onDispatchCard = () => null,
}) => {
  const ContentsTypes: IContentType = {
    suspend: (
      <DispatchTypeSuspend
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ),
    finish: (
      <DispatchTypeCardFinish
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ),
    add_card_subordinate: (
      <DispatchTypeCardSubodinate
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ),
    change_area: (
      <DispatchTypeArea
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ),
    change_user: (
      <DispatchTypeUser
        users={users}
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ),
    change_contact: (
      <DispatchTypeContact
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ),
    /* default: (
      <DispatchTypeDefault
        cardId={cardId}
        selectedType={type}
        onClose={onClose}
        onDispatchCard={onDispatchCard}
      />
    ), */
  };

  const RenderType = () => {
    return (
      <div className="dispatch-type">
        {type?.key && type.key !== ''
          ? ContentsTypes[type?.key.toLowerCase()] || ''
          : ContentsTypes.default}
      </div>
    );
  };

  return (
    <div>
      <RenderType />
    </div>
  );
};

export { DispatchTypes };
