/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useState, useEffect } from 'react';

import {
  Box,
  Fade,
  List,
  ListItem,
  makeStyles,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ITriggersTimelineProps, ITriggersLog } from './TriggersTimeline.i';
import { useTrigger } from '../../context/TriggerContext';
import api from '../../services/api';
import { Card } from '../../pages/Card';

const useStyles = makeStyles(theme => ({
  contentBox: {
    height: 'fit-content',
    maxHeight: '400px',
    width: '435px',
    padding: '4px',
    borderRadius: '5px',
    overflowY: 'auto',
    '@media (max-width: 400px)': {
      width: '350px',
    },
  },
  defaultTextProps: {
    fontSize: '13px',
    margin: 0,
    padding: 0,
  },
}));

const locales = 'pt-BR';

const options = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const TriggersTimeline: React.FC<ITriggersTimelineProps> = ({
  isOpen,
  setIsOpen,
  anchorEl,
}) => {
  const classes = useStyles();
  const { triggersData } = useTrigger();
  const [triggersLog, setTriggersLog] = useState<ITriggersLog[]>([]);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [cardIdSelected, setCardIdSelected] = useState<string>('');
  const [keyCard, setKeyCard] = useState<string>('');
  const [accountName, setAccountName] = useState<string>('');
  const [cardPhaseName, setCardPhaseName] = useState<string>('');

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenCardModal(false);
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardOpen = useCallback(
    (id: string, key: string, account: string) => {
      api.get(`/cards/${id}`).then(response => {
        setOpenCardModal(true);
        setCardIdSelected(id);
        setKeyCard(key);
        setAccountName(account);
        setCardPhaseName(response.data.phase || '');
      });
    },
    [],
  );

  function truncate(input: string) {
    if (input && input.length > 16) {
      return `${input.substring(0, 16)}... - `;
    }
    return `${input} - `;
  }

  useEffect(() => {
    const tempTriggers: ITriggersLog[] = [];

    if (triggersData && triggersData.length > 0) {
      triggersData.forEach(item => {
        const { card_id, key_card } = item;
        const date = new Date(item.triggerDateTime).toLocaleString(
          locales,
          options as any,
        );

        const account = item.accountName;

        [item.triggers].forEach(itemTrigger => {
          itemTrigger.success.forEach(itemSuccess => {
            tempTriggers.push({
              card_id,
              key_card,
              triggerDateTime: date,
              accountName: account,
              log: { type: 'success', info: itemSuccess.actionName },
            });
          });

          itemTrigger.errors.forEach(itemError => {
            tempTriggers.push({
              card_id,
              key_card,
              triggerDateTime: date,
              accountName: account,
              log: { type: 'error', info: itemError.message },
            });
          });
        });
      });

      tempTriggers.sort(
        (a, b) =>
          -a.triggerDateTime
            .toLocaleString(locales, options as any)
            .localeCompare(
              b.triggerDateTime.toLocaleString(locales, options as any),
            ),
      );

      setTriggersLog(tempTriggers);
    }
  }, [locales, options, triggersData]);

  const getPopperStyle = useCallback(() => {
    const anchorRect = anchorEl?.getBoundingClientRect();
    const popperHeight = 400;
    const spaceBelow = viewportHeight - (anchorRect?.bottom ?? 0);

    if (spaceBelow < popperHeight) {
      return {
        borderRadius: '8px',
        zIndex: 1300,
        marginTop: 56,
      };
    }
    return {
      borderRadius: '8px',
      zIndex: 1300,
    };
  }, [anchorEl, viewportHeight]);

  return (
    <>
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        style={getPopperStyle()}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box className={classes.contentBox} component={Paper}>
              {triggersLog && triggersLog.length > 0 ? (
                <List dense disablePadding>
                  {triggersLog.map((trigger, index) => {
                    return (
                      <ListItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={trigger.triggerDateTime + trigger.card_id + index}
                        dense
                        disableGutters
                        button
                        divider
                        onClick={() =>
                          handleCardOpen(
                            trigger.card_id,
                            trigger.key_card,
                            trigger.accountName,
                          )
                        }
                      >
                        <Alert
                          severity={trigger.log.type}
                          style={{ width: '100%', padding: '2px' }}
                        >
                          <Typography
                            variant="body2"
                            component="span"
                            className={`${classes.defaultTextProps}`}
                          >
                            {`${trigger.triggerDateTime} - `}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className={`${classes.defaultTextProps}`}
                          >
                            {`Card ${trigger.key_card} - `}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className={classes.defaultTextProps}
                          >
                            {truncate(trigger.accountName)}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className={`${classes.defaultTextProps}`}
                          >
                            {trigger.log.info}
                          </Typography>
                        </Alert>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <>
                  <span>Sem registros.</span>
                </>
              )}
            </Box>
          </Fade>
        )}
      </Popper>
      {openCardModal && cardIdSelected && cardPhaseName && (
        <Card
          cardId={cardIdSelected}
          openModal={openCardModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};
