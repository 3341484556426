/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  createStyles,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Clear, AddCircleRounded } from '@material-ui/icons';
import { ICustomField } from 'src/interface/ICardFields';
import { X } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import {
  IIntegratedModalData,
  ILinkedCardFieldValues,
} from 'src/components/TaskModal/TaskModal.i';
import { CardFieldValueTypes } from 'src/utils/fieldTypes.constants';
import { isFieldValueEmpty } from 'src/utils/customFieldUtils';
import { toast } from 'react-toastify';
import { ModalIntegratedCustomFields } from 'src/components/ModalIntegratedCustomFields';
import rfdc from 'rfdc';
import { tableStyles } from './tableStyles';
import { IntegratedField } from '../IntegratedField';
import { BooleanField } from '../BooleanField';
import { TextInput } from '../TextInput';
import { TimestampField } from '../TimestampField';
import { PredefinedField } from '../PredefinedField';

export interface ITableProps {
  open: boolean;
  title: string;
  customFieldId: string;
  tableColumns: ICustomField[];
}

export interface IValueJSON {
  [key: string]: {
    value?: string | null;
    valueJSON?: string | string[] | null | object | object[];
  };
}

interface RowEmptyFields {
  [rowIndex: string]: string[];
}

export interface TableModalProps {
  open: boolean;
  closeModal: () => void;
  title: string;
  customFieldId: string;
  tableColumns: ICustomField[];
  handleFillCustomField: (
    fieldId: string,
    newValue: string | string[] | null | object | object[],
    valueProperty: 'value' | 'valueJSON',
  ) => void;
  tableValueJSON: IValueJSON[];
  done?: boolean;
}

const useStyles = makeStyles(() => createStyles(tableStyles()));

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const clone = rfdc();

export const TableFieldModal: React.FC<TableModalProps> = ({
  open,
  closeModal,
  title,
  customFieldId,
  tableColumns,
  handleFillCustomField,
  tableValueJSON,
  done = false,
  ...rest
}) => {
  const classes = useStyles();
  const [tableState, setTableState] = useState<IValueJSON[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [integratedModalData, setIntegratedModalData] =
    useState<IIntegratedModalData | null>(null);
  const [emptyRequiredFields, setEmptyRequiredFields] =
    useState<RowEmptyFields>({});

  useEffect(() => {
    setTableState(clone(tableValueJSON));
  }, [tableValueJSON]);

  const handleFillTableValue = (
    columnCustomFieldId: string,
    tableRowIndex: number,
    value: string | string[] | null | object | object[],
    valueProperty: 'value' | 'valueJSON',
  ) => {
    setTableState(oldValue => {
      const newValueJSON = [...oldValue] as IValueJSON[];
      if (!newValueJSON[tableRowIndex][columnCustomFieldId]) {
        newValueJSON[tableRowIndex][columnCustomFieldId] = {};
      }
      if (valueProperty === 'value') {
        newValueJSON[tableRowIndex][columnCustomFieldId].value = value as
          | string
          | null;
      } else if (valueProperty === 'valueJSON') {
        newValueJSON[tableRowIndex][columnCustomFieldId].valueJSON = value;
      }
      return newValueJSON;
    });
    if (
      emptyRequiredFields[tableRowIndex] &&
      emptyRequiredFields[tableRowIndex].includes(columnCustomFieldId)
    ) {
      setEmptyRequiredFields(oldValue => {
        return {
          ...oldValue,
          [tableRowIndex.toString()]: oldValue[tableRowIndex.toString()].filter(
            fieldId => fieldId !== columnCustomFieldId,
          ),
        };
      });
    }
  };

  const handleAddTableRow = () => {
    if (tableColumns) {
      setTableState(oldValue => {
        const newTableRow = tableColumns.reduce((row, currentColumn) => {
          return {
            ...row,
            [currentColumn.id]:
              currentColumn.type === 'INTEGRATED'
                ? {
                    valueJSON: {},
                  }
                : currentColumn.type === 'PREDEFINED_STRINGS'
                ? {
                    valueJSON: [],
                  }
                : {
                    value: '',
                  },
          };
        }, {});
        let newValueJSON: IValueJSON[] = [];
        const cardCfTableValue: IValueJSON[] = [...oldValue];

        if (cardCfTableValue) {
          newValueJSON = cardCfTableValue;
          newValueJSON.push(newTableRow);
        } else {
          newValueJSON.push(newTableRow);
        }
        return newValueJSON;
      });
    }
  };

  const handleDeleteRow = (tableRowIndex: number) => {
    setTableState(oldValue => {
      const newValueJSON = [...oldValue] as IValueJSON[];
      newValueJSON.splice(tableRowIndex, 1);
      return newValueJSON;
    });
  };

  const handleOpenIntegratedFieldModal = (
    cardField: ILinkedCardFieldValues,
    table?: {
      id: string;
      tableRowIndex: number;
    },
  ) => {
    const newModalData = {
      cardField,
      cardFieldId: cardField.id,
      table,
    };
    setIntegratedModalData(newModalData);
    setOpenModal(true);
  };

  const handleFillIntegratedField = useCallback(
    (value: Record<string, unknown> | undefined, fieldId?: string) => {
      if (integratedModalData && integratedModalData.cardFieldId) {
        const { cardFieldId } = integratedModalData;
        if (integratedModalData?.table !== undefined && value) {
          const tableRowIndex = integratedModalData.table?.tableRowIndex;
          handleFillTableValue(
            fieldId || cardFieldId,
            tableRowIndex,
            value,
            'valueJSON',
          );
        }
        setOpenModal(false);
      }
    },
    [integratedModalData, setOpenModal, tableState],
  );

  const handleSaveTable = useCallback(() => {
    const hasRequiredColumns = tableColumns.find(column => column.isRequired);
    if (tableState.length > 0 && hasRequiredColumns) {
      let requiredColumnsEmpty = false;

      tableState.forEach((row, tableRowIndex) => {
        const emptyFieldIds: string[] = [];

        tableColumns.forEach(column => {
          const isValue = CardFieldValueTypes.includes(column.type);
          const value = isValue
            ? row[column.id]?.value
            : row[column.id]?.valueJSON;

          if (column.isRequired && isFieldValueEmpty(value)) {
            requiredColumnsEmpty = true;
            emptyFieldIds.push(column.id);
          }
        });

        if (emptyFieldIds.length > 0) {
          // emptyRequiredFields[tableRowIndex.toString()] = emptyFieldIds;
          setEmptyRequiredFields(oldValue => {
            return {
              ...oldValue,
              [tableRowIndex.toString()]: emptyFieldIds,
            };
          });
        }
      });

      if (requiredColumnsEmpty) {
        toast.error('Preencha todas as colunas obrigatórias', {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
          theme: 'colored',
        });
      } else {
        handleFillCustomField(customFieldId, tableState, 'valueJSON');
        closeModal();
      }
    } else {
      handleFillCustomField(customFieldId, tableState, 'valueJSON');
      closeModal();
    }
  }, [tableColumns, tableState]);

  function renderTableColumns(
    tableColumn: ICustomField,
    tableRow: IValueJSON,
    tableRowIndex: number,
  ) {
    switch (tableColumn.type) {
      case 'BOOL':
        return (
          <Box className={`${classes.customField} ${classes.boolField}`}>
            <BooleanField
              fieldId={tableColumn.id}
              fieldName={tableColumn.name}
              fieldLabel={tableColumn.name}
              fieldValue={tableRow[tableColumn.id].value || ''}
              handleFillTableValue={handleFillTableValue}
              isDisabled={done}
              isRequired={
                tableColumn.isRequired || tableColumn.isApprovalCriterion
              }
              valueProperty="value"
              tableRowIndex={tableRowIndex}
            />
          </Box>
        );

      case 'STRING':
      case 'NUMERIC':
        return (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={tableRow[tableColumn.id]?.value || ''}
            enterDelay={1500}
            enterNextDelay={1000}
            disableFocusListener
            disableTouchListener
            arrow
            PopperProps={{
              placement: 'bottom-start',
              popperOptions: {
                positionFixed: true,
              },
            }}
          >
            <Box className={classes.notIngratedField}>
              <TextInput
                fieldId={tableColumn.id}
                fieldName={tableColumn.name}
                fieldLabel={tableColumn.name}
                fieldValue={tableRow[tableColumn.id]?.value || ''}
                handleOnBlurTable={handleFillTableValue}
                isDisabled={tableColumn.isInactive || done}
                isRequired={tableColumn.isRequired}
                type={tableColumn.type}
                fullWidth
                valueProperty="value"
                tableRowIndex={tableRowIndex}
              />
            </Box>
          </Tooltip>
        );

      case 'TIMESTAMP':
        return (
          <TimestampField
            fieldId={tableColumn.id}
            fieldName={tableColumn.name}
            fieldLabel={tableColumn.name}
            fieldValue={tableRow[tableColumn.id]?.value || ''}
            dateType={tableColumn.dateType}
            isDisabled={tableColumn.isInactive || done}
            isRequired={tableColumn.isRequired}
            margin="dense"
            valueProperty="value"
            handleChangeTableValue={handleFillTableValue}
            tableRowIndex={tableRowIndex}
          />
        );

      case 'PREDEFINED_STRINGS':
        if (!tableColumn.predefinedValues) return null;
        return (
          <PredefinedField
            fieldId={tableColumn.id}
            fieldName={tableColumn.name}
            fieldValue={
              (tableRow[tableColumn.id]?.valueJSON as string | string[]) || []
            }
            handleFillTableValue={handleFillTableValue}
            isDisabled={tableColumn.isInactive || done}
            isRequired={tableColumn.isRequired}
            fieldOptions={tableColumn.predefinedValues}
            valueProperty="valueJSON"
            margin="dense"
            tableRowIndex={tableRowIndex}
          />
        );

      case 'INTEGRATED':
        return (
          <IntegratedField
            customField={tableColumn as any}
            table={{
              id: customFieldId,
              tableRowIndex,
            }}
            handleFillTableValue={handleFillTableValue}
            isRequired={tableColumn.isRequired}
            isDisabled={tableColumn.isInactive || done}
            handleOpenModal={handleOpenIntegratedFieldModal}
            selectedIntegratedItem={
              tableRow[tableColumn.id]?.valueJSON === undefined
                ? undefined
                : (tableRow[tableColumn.id].valueJSON as any)
            }
          />
        );

      default:
        return null;
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={(event: React.SyntheticEvent) => {
        event?.preventDefault();
        closeModal();
      }}
      maxWidth="xl"
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <DialogTitle
          id={title}
          className="w-full overflow-hidden mx-2"
          style={{ width: '100%', overflow: 'hidden', padding: '16px 16px' }}
        >
          <div className="h-6 flex justify-between items-center">
            <span>{title}</span>
            <Button
              size="sm"
              type="button"
              variant="ghost"
              aria-label="Fechar modal"
              onClick={closeModal}
            >
              <X size="1.25rem" />
            </Button>
          </div>
        </DialogTitle>
      </Box>
      <DialogContent className={classes.children}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            mb={4}
            width="100%"
            padding="10px"
          >
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                size="small"
                aria-label="sticky table"
                stickyHeader
              >
                <TableHead>
                  <StyledTableRow>
                    {tableColumns?.length !== 0 && (
                      <TableCell
                        component="th"
                        scope="col"
                        align="center"
                        className={classes.deleteIconColumn}
                      >
                        <Typography variant="inherit" noWrap>
                          Excluir
                        </Typography>
                      </TableCell>
                    )}
                    {tableColumns &&
                      tableColumns.map(tableColumn => (
                        <TableCell
                          component="th"
                          scope="col"
                          align={
                            tableColumn.type === 'NUMERIC'
                              ? 'right'
                              : // eslint-disable-next-line no-constant-condition
                              tableColumn.type === 'BOOL' ||
                                tableColumn.type === 'DATE' ||
                                tableColumn.type === 'TIME'
                              ? 'center'
                              : 'left'
                          }
                          key={tableColumn.id}
                        >
                          <Typography variant="inherit" noWrap>
                            {`${tableColumn.name} ${
                              tableColumn.isRequired ? '*' : ''
                            }`}
                          </Typography>
                        </TableCell>
                      ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tableState &&
                    tableState.map(
                      (tableRow: IValueJSON, tableRowIndex: number) => (
                        <StyledTableRow
                          className={classes.tableRowSelected}
                          key={Object.keys(tableRow)[tableRowIndex]}
                          hover
                        >
                          <TableCell component="td" scope="row" align="center">
                            <IconButton
                              edge="start"
                              color="inherit"
                              size="small"
                              aria-label="Remover linha"
                              onClick={() => handleDeleteRow(tableRowIndex)}
                              disabled={done}
                            >
                              <Clear />
                            </IconButton>
                          </TableCell>
                          {tableRow &&
                            tableColumns &&
                            tableColumns.map(tableColumn => (
                              <TableCell
                                component="td"
                                key={`${tableColumn.id}.${
                                  Object.keys(tableRow)[tableRowIndex]
                                }`}
                                scope="row"
                                align={
                                  tableColumn.type === 'NUMERIC'
                                    ? 'right'
                                    : tableColumn.type === 'BOOL'
                                    ? 'center'
                                    : 'left'
                                }
                                style={
                                  emptyRequiredFields[tableRowIndex] &&
                                  emptyRequiredFields[tableRowIndex].includes(
                                    tableColumn.id,
                                  )
                                    ? {
                                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                      }
                                    : {}
                                }
                              >
                                {renderTableColumns(
                                  tableColumn,
                                  tableRow,
                                  tableRowIndex,
                                )}
                              </TableCell>
                            ))}
                        </StyledTableRow>
                      ),
                    )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell
                      align="left"
                      colSpan={tableColumns ? tableColumns.length + 1 : 1}
                    >
                      <IconButton
                        className={classes.addButton}
                        edge="start"
                        color="secondary"
                        size="small"
                        aria-label="Adicionar linha"
                        onClick={handleAddTableRow}
                        disabled={done}
                      >
                        <AddCircleRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width="100%"
            mt={2}
            mb={2}
            pr={2}
          >
            <Button className="mr-1" onClick={closeModal} variant="secondary">
              Voltar
            </Button>
            <Button
              className="px-4 w-fit"
              color="secondary"
              type="submit"
              onClick={handleSaveTable}
            >
              Aplicar
            </Button>
          </Box>
        </Box>
        {openModal && integratedModalData && (
          <ModalIntegratedCustomFields
            openModal={openModal}
            handleCloseModal={() => setOpenModal(false)}
            onSelectedIntegratedItem={handleFillIntegratedField}
            customFields={[]}
            taskLinkedCardFields={tableState}
            customField_id={integratedModalData.cardField.id}
            customField_name={integratedModalData.cardField.name}
            format={integratedModalData.cardField.format}
            dynamicFormat={integratedModalData.cardField.dynamicFormat}
            key={integratedModalData.cardField.id}
            primaryKeyColumnName={
              integratedModalData.cardField.primaryKeyColumnName
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
