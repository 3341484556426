import React from 'react';
import { useStyles } from './styles';
import logo from '../../assets/logo-brasao-bpm.svg';
import { useLoader } from '../../context/LoaderContext';

const Loading: React.FC = () => {
  const classes = useStyles();
  const { loading, loadingMessage } = useLoader();

  return (
    <div className={`${classes.container} ${loading ? 'show' : ''}`}>
      <div className={classes.logo}>
        <img src={logo} alt="logo" className={classes.logo} />
      </div>
      <div className={classes.message}>{loadingMessage}</div>
    </div>
  );
};

export { Loading };
