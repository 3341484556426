/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { ChangeEvent } from 'react';

import {
  Box,
  Button,
  createStyles,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { mask as masker, unMask } from 'remask';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { toast } from 'react-toastify';
import { Autocomplete } from '@material-ui/lab';
import { AxiosError } from 'axios';
import { cpfCnpjPattern, isCPFValid } from 'src/utils/cpfCnpjUtils';
import api from 'src/services/api';
import { COLORS } from 'src/styles/colors';
import { NewSignerModalProps, ISigner } from '../Signature.i';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles: any = makeStyles(() =>
  createStyles({
    dialog: {
      width: '90%',
      height: 'auto',
      overflow: 'auto',
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#53a546',
      },
      '& label.Mui-focused': {
        color: COLORS.BLUE,
      },
      '& .MuiInputLabel-shrink': {
        color: COLORS.BLUE,
        fontWeight: 500,
        '& fieldset': {
          borderColor: COLORS.BLUE,
        },
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: COLORS.BLUE,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: COLORS.BLUE,
        },
      },
    },
    children: {
      padding: 5,
      margin: 0,
      marginBottom: 2,
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
    },
    goBackButton: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      fontSize: '12px',
      marginRight: '0.5rem',
    },
    addButton: {
      height: '35px',
      width: '90px',
      fontSize: '12px',
      color: '#FFF',
      background: 'linear-gradient(to right, #007dc6, #2f98fa)',
      margin: 0,
    },
  }),
);

export const SignerModal: React.FC<NewSignerModalProps> = ({
  open,
  closeModal,
  title,
  signers,
  signature_id,
  currentSigner,

  handleFetchSignatures,
  ...rest
}) => {
  const classes = useStyles();

  const customHandleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = unMask(ev.target.value);
    formik.setFieldValue('cpf', masker(value, cpfCnpjPattern[0]));
  };
  const handleAddNewSigner = async (
    signature_id: string | undefined,
    name: string,
    email: string,
    cpf: string,
    birthDate: string,
    type: string,
  ) => {
    const result = await api
      .post(`/signatures/${signature_id}/signers`, {
        name,
        email,
        cpf,
        birthDate,
        type,
      })
      .then(() => {
        handleFetchSignatures();
      })
      .then(() => {
        toast.success('Signatário adicionado com sucesso.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      })
      .catch((error: AxiosError) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      });

    return result;
  };

  const initialValues: ISigner = {
    id: currentSigner ? currentSigner.id : '',
    name: currentSigner ? currentSigner.name : '',
    cpf: currentSigner ? masker(currentSigner.cpf, cpfCnpjPattern[0]) : '',
    email: currentSigner ? currentSigner.email : '',
    token: currentSigner ? currentSigner.token : '',
    birthDate: currentSigner ? currentSigner.birthDate : '',
    type: currentSigner ? currentSigner.type : 'Signatário',
    log: currentSigner ? currentSigner.log : [],
    signed_document_id: currentSigner ? currentSigner.signed_document_id : '',
    signed: currentSigner ? currentSigner.signed : false,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().min(1).max(200).required('Nome é obrigatório.'),
    email: Yup.string()
      .email('Digite um email válido.')
      .required('E-mail é obrigatório.'),
    cpf: Yup.string()
      .test('cpf', 'CPF inválido', value => {
        const cleanedValue = value?.replace(/[^\d]/g, '') || '';
        const isCPF = cleanedValue?.length === 11 && isCPFValid(cleanedValue);

        return isCPF;
      })
      .required('CPF é obrigatório.'),
    birthDate: Yup.string()
      .nullable()
      .required('Data de nascimento é obrigatório.'),
    type: Yup.string().required('Tipo é obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);

        const emailIsInArray = signers.find(
          (signer: ISigner) => signer.email === values.email,
        );
        const cpfIsInArray = signers.find(
          (signer: ISigner) => signer.cpf === values.cpf,
        );

        if (!currentSigner && (emailIsInArray || cpfIsInArray)) {
          toast.error('E-mail ou CPF iguais já constam na lista.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
          setSubmitting(false);
          return;
        }

        if (
          currentSigner &&
          ((cpfIsInArray && values.cpf !== currentSigner.cpf) ||
            (emailIsInArray && values.email !== currentSigner.email))
        ) {
          toast.error('E-mail e/ou CPF iguais já constam na lista.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
          setSubmitting(false);
          return;
        }

        if (!signature_id) {
          await handleAddNewSigner(
            signature_id,
            values.name,
            values.email,
            values.cpf,
            values.birthDate.toLocaleString('pt-BR'),
            values.type,
          );
        }

        if (!currentSigner) {
          await handleAddNewSigner(
            signature_id,
            values.name,
            values.email,
            values.cpf,
            values.birthDate.toLocaleString('pt-BR'),
            values.type,
          ).then(() => {
            resetForm();
            setSubmitting(false);
            closeModal();
          });
        } else {
          api
            .put(`/signatures/${signature_id}/signers/${values.id}`, {
              name: values.name,
              email: values.email,
              cpf: values.cpf,
              birthDate: values.birthDate.toLocaleString('pt-BR'),
              type: values.type,
            })
            .then(() => {
              handleFetchSignatures();
            })
            .then(() => {
              toast.success('Signatário alterado com sucesso.', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              });
              resetForm();
              closeModal();
            });
        }
        setSubmitting(false);
      } catch (error: any) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={closeModal}
      maxWidth="xs"
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <DialogTitle id={title} style={{ maxWidth: '70%', overflow: 'hidden' }}>
          <Box height="22px">
            <span>{title}</span>
          </Box>
        </DialogTitle>
      </Box>
      <DialogContent className={classes.children}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            // component={Paper}
            mb={4}
            width="100%"
          >
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{ width: '100%', padding: '10px' }}
            >
              <TextField
                id="name"
                label="Nome"
                name="name"
                margin="dense"
                variant="outlined"
                autoFocus
                fullWidth
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                id="email"
                label="E-mail"
                name="email"
                margin="dense"
                fullWidth
                variant="outlined"
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                id="cpf"
                label="CPF"
                name="CPF"
                margin="dense"
                autoComplete="off"
                variant="outlined"
                fullWidth
                onChange={customHandleChange}
                value={formik.values.cpf}
                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                helperText={formik.touched.cpf && formik.errors.cpf}
              />
              <TextField
                id="birthDate"
                name="birthDate"
                type="date"
                label="Data de nascimento"
                autoComplete="off"
                margin="dense"
                variant="outlined"
                value={formik.values.birthDate || ''}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                inputProps={{
                  inputformat: 'dd/mm/yyyy',
                  pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
                  min: '1900-05-01',
                }}
                error={
                  formik.touched.birthDate && Boolean(formik.errors.birthDate)
                }
                helperText={formik.touched.birthDate && formik.errors.birthDate}
                fullWidth
              />
              <Autocomplete
                id="type"
                options={['Signatário', 'Testemunha']}
                getOptionLabel={opt => opt}
                getOptionSelected={(opt, value) => opt === value}
                fullWidth
                value={formik.values.type || 'Signatário'}
                disableClearable
                onChange={formik.handleChange}
                loadingText="Carregando"
                size="small"
                renderInput={restProps => (
                  <TextField
                    {...restProps}
                    name="type"
                    label="Tipo"
                    margin="dense"
                    type="text"
                    variant="outlined"
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    InputProps={{
                      ...restProps.InputProps,
                    }}
                  />
                )}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mt={2}
              >
                <Button
                  className={classes.goBackButton}
                  size="medium"
                  variant="contained"
                  onClick={() => closeModal()}
                >
                  Voltar
                </Button>
                <Button
                  className={classes.addButton}
                  size="medium"
                  variant="contained"
                  color="inherit"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Salvar
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
