import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

interface EllipsisOptionsDropdownProps {
  options: { [key: string]: () => void };
  refreshDataAfterAction?: () => void;
}

export const EllipsisOptionsDropdown: React.FC<
  EllipsisOptionsDropdownProps
> = ({ options, refreshDataAfterAction }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleOpenDropdown(e);
        }}
        style={{ padding: '5px' }}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
      >
        {Object.keys(options).map(optionKey => (
          <MenuItem
            key={optionKey}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              options[optionKey]();
              handleCloseDropdown();
              if (refreshDataAfterAction) {
                refreshDataAfterAction();
              }
            }}
          >
            {optionKey}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
