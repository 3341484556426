import React from 'react';
import { Skeleton } from '@material-ui/lab';

export const TaskSkeleton: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexShrink: 1,
        flexDirection: 'column',
        height: '100%',
        gap: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '6rem',
        }}
      >
        <div style={{ gap: '0.5rem', display: 'flex', maxHeight: '3rem' }}>
          <Skeleton height="4rem" width="50%" />
          <Skeleton height="4rem" width="50%" />
        </div>
        <div style={{ gap: '0.5rem', display: 'flex', maxHeight: '3rem' }}>
          <Skeleton height="4rem" width="50%" />
          <Skeleton height="4rem" width="50%" />
        </div>
      </div>
      <Skeleton variant="rect" height="150px" />
      <Skeleton variant="rect" height="150px" />
      <Skeleton variant="rect" height="150px" />
      <Skeleton height="4rem" width="8rem" style={{ alignSelf: 'end' }} />
    </div>
  );
};
