import React from 'react';

interface TableProps {
  title?: string;
  columns: string[];
  data: { [key: string]: string | number | boolean | null | undefined }[];
}

const Table: React.FC<TableProps> = ({ title, columns = [], data = [] }) => {
  const isNumericString = (value: string) => {
    const numericPattern = /^-?\d+(\.\d+)?(,\d+)?%?$/;
    return numericPattern.test(value.replace(/[.,]/g, ''));
  };

  return (
    <div className="overflow-x-auto mb-4">
      {title && <h3 className="text-lg font-bold mb-2">{title}</h3>}
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            {columns.map(column => (
              <th
                key={column}
                className="py-2 px-4 border-b border-gray-200 text-left"
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            const rowKey = columns.map(col => row[col]).join('-');
            return (
              <tr key={rowKey || rowIndex} className="hover:bg-gray-100">
                {columns.map(column => {
                  const cellValue = row[column];
                  const isNumeric =
                    typeof cellValue === 'number' ||
                    (typeof cellValue === 'string' &&
                      isNumericString(cellValue));
                  return (
                    <td
                      key={`${rowKey}-${column}`}
                      className={`py-2 px-4 border-b border-gray-200 ${
                        isNumeric ? 'text-right' : 'text-left'
                      }`}
                    >
                      {cellValue}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
