import { StyleRules } from '@material-ui/core';

export const predefinedStyles = (): StyleRules => ({
  optionsCheckBox: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  tablePredefinedInput: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '&& div.MuiAutocomplete-endAdornment': {
      position: 'relative',
      order: -1,
      display: 'flex',
      flexWrap: 'nowrap',
    },
  },
});
