import React, { useState, useRef, useEffect } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';

interface TextWithEllipsisAndTooltipProps {
  text: string;
  tooltipContent?: string;
  className?: string;
}

export const TextWithEllipsisAndTooltip: React.FC<
  TextWithEllipsisAndTooltipProps
> = ({ text, tooltipContent = text, className }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkIfTruncated = () => {
      const element = textRef.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    window.addEventListener('resize', checkIfTruncated);
    checkIfTruncated();

    return () => window.removeEventListener('resize', checkIfTruncated);
  }, [text]);

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <div
          ref={textRef}
          className={`truncate ${className} ${isTruncated && 'cursor-help'}`}
        >
          {text}
        </div>
      </Tooltip.Trigger>
      {isTruncated && (
        <Tooltip.Content
          className="bg-white shadow-sm rounded-md p-2 text-sm z-[1300] border-2"
          side="top"
          align="center"
        >
          {tooltipContent}
        </Tooltip.Content>
      )}
    </Tooltip.Root>
  );
};
