import { StyleRules } from '@material-ui/core';
import { COLORS } from '../../../../../../styles/colors';

export const integratedTableInputStyles = (): StyleRules => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  integratedType: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  customFieldIntegrationTypeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  integratedFieldPrimaryKey: {
    color: COLORS.BLUE,
    marginRight: '0.2rem',
    fontSize: '14px',
  },
  integratedFieldDisplayColumnName: {
    maxWidth: '15rem',
    overflow: 'hidden',
    fontSize: '14px',
  },
  integratedFieldButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  button: {
    margin: 0,
    padding: 0,
  },
  integratedFieldDummyTextField: {
    caretColor: 'transparent',
    width: '100%',
  },
});
