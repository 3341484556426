import React from 'react';
import { makeStyles } from '@material-ui/core';
import { WorkspaceFiltersProvider } from 'src/context/WorkspaceFiltersContext';
import { isMobile } from 'react-device-detect';
import { HeaderMenu } from '../../../components/HeaderMenu';
import { Menu } from '../../../components/Menu';
import { MenuProvider } from '../../../context/MenuContext';
import { COLORS } from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  root: {
    background: COLORS.BACKGROUND,
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 70,
    marginBottom: isMobile ? 70 : undefined,
    background: COLORS.BACKGROUND,
    boxSizing: 'border-box',
  },
}));

const DefaultLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <MenuProvider>
      <WorkspaceFiltersProvider>
        <div className={classes.root}>
          <HeaderMenu />
          <div className={classes.content}>
            <Menu />
            {children}
          </div>
        </div>
      </WorkspaceFiltersProvider>
    </MenuProvider>
  );
};

export default DefaultLayout;
