/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import { TableRow } from '../Visions.i';
import { exportToPDF } from '../utils/exportToPDF';

interface CombinedContentText {
  type: 'text';
  content: {
    id: string;
    title: string;
    description: string;
    resume: string;
    important_points: string;
    missing_data: string;
    notes: string;
  };
}

interface CombinedContentTable {
  type: 'table';
  content: {
    title: string;
    id: string;
    jsonTable: {
      columns: string[];
      data: TableRow[];
    };
  };
}

type CombinedContent = CombinedContentText | CombinedContentTable;

interface PDFExportOptions {
  margin?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

export const useResponseChat = (vision_id: string | null) => {
  const [combinedContent, setCombinedContent] = useState<CombinedContent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchVisionAIResponse = async () => {
      if (!vision_id) return;

      setLoading(true);

      try {
        const response = await api.post(`/visions/${vision_id}/execute`);
        const { data } = response;

        const combined: CombinedContent[] = [];

        const texts = data.texts || [];
        const tables = data.tablesJson || [];

        texts.forEach((text: any, index: number) => {
          combined.push({
            type: 'text',
            content: {
              id: `text-${index}`,
              title: text.title || '',
              description: text.description || '',
              resume: text.resume || '',
              important_points: text.important_points || '',
              missing_data: text.missing_data || '',
              notes: text.notes || '',
            },
          });
        });

        tables.forEach((table: any) => {
          const columns = Object.keys(table.data[0] || {});
          const { data } = table;

          combined.push({
            type: 'table',
            content: {
              title: table.title,
              id: table.id,
              jsonTable: { columns, data },
            },
          });
        });

        setCombinedContent(combined);

        toast.success('Relatório IA gerado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message || 'Erro ao gerar o relatório IA.';
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchVisionAIResponse();
  }, [vision_id]);

  const exportContentToPDF = () => {
    const options: PDFExportOptions = {
      margin: { top: 40, bottom: 40, left: 40, right: 40 },
    };

    exportToPDF('pdf-content', options, 'document.pdf');
  };

  return { combinedContent, loading, exportContentToPDF };
};
