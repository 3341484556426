/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  Container,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import Button from 'src/components/Button';
import { useTrigger } from 'src/context/TriggerContext';

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IDispatchTypeProps {
  cardId?: string;
  selectedType: IDispatchType | null;
  onClose: () => void;
  onDispatchCard: () => void;
}

interface IFormData {
  card_id: string;
  dispatchType_id: string;
  observation: string;
  date_suspended?: Date;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30,
    },
    input: {
      width: '100%',
    },
    submit: {
      marginTop: theme.spacing(2),
    },
    date: {
      marginTop: '15px',
    },
  }),
);

const DispatchTypeSuspend: React.FC<IDispatchTypeProps> = ({
  cardId,
  selectedType,
  onClose = () => null,
  onDispatchCard = () => null,
}) => {
  const { updateTriggers } = useTrigger();
  const classes = useStyles();

  const initialValues: IFormData = {
    card_id: cardId || '',
    dispatchType_id: selectedType?.id || '',
    observation: '',
    date_suspended: new Date(),
  };

  const formSchema = Yup.object().shape({
    dispatchType_id: Yup.string()
      .uuid()
      .required('Selecione um tipo de avanço'),
    date_suspended: Yup.date()
      .required('Data de suspensão é obrigatória')
      .nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (
      { card_id, dispatchType_id, date_suspended, observation },
      { setSubmitting },
    ) => {
      const suspendingCard = toast.loading(`Suspendendo o card.`);
      document.body.style.cursor = 'progress';
      try {
        setSubmitting(true);
        await api
          .post(`/cards/${card_id}/dispatchTypes/${dispatchType_id}`, {
            observation,
            date_suspended,
          })
          .then(response => {
            const card = response?.data?.card;
            const triggers = response?.data?.triggers;
            const id = card?.id;
            const key_card = card?.key_card;
            const accountName = card?.account.name;
            const triggerDateTime = new Date();

            if (
              id &&
              key_card &&
              triggers &&
              Object.keys(triggers).length > 0
            ) {
              updateTriggers({
                card_id: id,
                key_card,
                triggers,
                accountName,
                triggerDateTime,
              });
            }

            toast.update(suspendingCard, {
              render: `Card foi suspenso com sucesso.`,
              type: 'success',
              isLoading: false,
              autoClose: 2000,
              theme: 'colored',
              position: toast.POSITION.TOP_RIGHT,
            });
            document.body.style.cursor = 'auto';

            onDispatchCard();
            onClose();
          });
      } catch (error: any) {
        setSubmitting(false);

        document.body.style.cursor = 'auto';
        toast.update(suspendingCard, {
          render: `Ocorreu um erro ao suspender o card. ${error.response.data.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  useEffect(() => {
    if (formik) formik.setFieldValue('card_id', cardId);
  }, [cardId]);

  return (
    <Container className={classes.container}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          id="business"
          className={classes.input}
          label="Tipo de avanço"
          margin="normal"
          value={selectedType?.value}
          disabled
        />
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="date_suspended"
            name="date_suspended"
            label="Suspender até a data "
            className={classes.date}
            format="dd/MM/yyyy"
            invalidDateMessage="Data informada incorreta"
            value={formik.values.date_suspended}
            onChange={value =>
              formik.setFieldValue('date_suspended', value, true)
            }
            error={
              formik.touched.date_suspended &&
              Boolean(formik.errors.date_suspended)
            }
            helperText={
              formik.touched.date_suspended && formik.errors.date_suspended
            }
            fullWidth
            disablePast
            KeyboardButtonProps={{
              'aria-label': 'suspender até a data',
            }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          id="observation"
          label="Observação"
          name="observation"
          margin="normal"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.observation}
          error={
            formik.touched.observation && Boolean(formik.errors.observation)
          }
          helperText={formik.touched.observation && formik.errors.observation}
        />
        <Button
          type="submit"
          className={classes.submit}
          disabled={formik.isSubmitting}
        >
          Avançar
        </Button>
      </form>
    </Container>
  );
};

export { DispatchTypeSuspend };
