import React, { useState, useEffect } from 'react';

import {
  Typography,
  createStyles,
  makeStyles,
  Tooltip,
  Box,
} from '@material-ui/core';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IPhaseCards } from '../../interface/IPhaseCards';

const useStyles = makeStyles(() =>
  createStyles({
    customFields: {
      fontSize: '0.65rem',
      maxWidth: '121px',
    },
    tooltipButton: {
      color: '#53a546',
      cursor: 'pointer',
    },
    tooltip: {
      backgroundColor: '#fafafa',
      // eslint-disable-next-line prettier/prettier
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    tooltipHeader: {
      color: '#007dc6',
      fontWeight: 'bolder',
      fontSize: '0.7rem',
    },
    tooltipDiv: {
      color: '#212121',
      display: 'grid',
      gridTemplateColumns: '120px repeat(2, 80px)',
      textAlign: 'right',
    },
  }),
);

interface IPhaseTooltipProps {
  phase: IPhaseCards;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  totalizer: any;
  loading: boolean;
}

export function PhaseTooltip({
  phase,
  totalizer,
  loading,
}: IPhaseTooltipProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(loading);
  }, [loading]);

  const formatNumber = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <Tooltip
        key={phase.id}
        classes={{ tooltip: classes.tooltip }}
        PopperProps={{
          disablePortal: false,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
          },
        }}
        title={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            {open ? (
              <Typography>Calculando...</Typography>
            ) : (
              <>
                <Box className={classes.tooltipDiv}>
                  <Typography
                    className={classes.tooltipHeader}
                    style={{ textAlign: 'left' }}
                  >
                    Nome
                  </Typography>
                  <Typography className={classes.tooltipHeader}>
                    Total
                  </Typography>
                  <Typography className={classes.tooltipHeader}>
                    Média
                  </Typography>
                </Box>

                {totalizer.map(
                  (customField: {
                    name: string | null | undefined;
                    total: number;
                  }) => (
                    <Box
                      key={phase.id + customField.name}
                      className={classes.tooltipDiv}
                    >
                      <Typography
                        className={classes.customFields}
                        style={{ textAlign: 'left' }}
                        noWrap
                      >
                        {customField.name}
                      </Typography>
                      <Typography className={classes.customFields}>
                        {formatNumber.format(Number(customField.total))}
                      </Typography>
                      <Typography className={classes.customFields}>
                        {formatNumber.format(
                          Number(customField.total / phase.cards.length),
                        )}
                      </Typography>
                    </Box>
                  ),
                )}
              </>
            )}
          </>
        }
      >
        <InfoOutlinedIcon className={classes.tooltipButton} />
      </Tooltip>
    </>
  );
}
