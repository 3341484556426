import React from 'react';
import { Card } from 'src/components/ui/card';

export const Widget: React.FC = ({ children }) => {
  return (
    <Card className="p-3 min-h-[300px] min-w-[300px] w-full md:max-w-1/2 max-h-[350px] grow lg:mb-2">
      {children}
    </Card>
  );
};
