/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Swal from 'sweetalert2';
import Box from '@material-ui/core/Box';
import { IconButton, TextField, CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import {
  TableContainer,
  Container,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Autocomplete } from '@material-ui/lab';

import { toast } from 'react-toastify';

import TablePaginationActions from '../../../components/TablePaginationActions';
import api from '../../../services/api';

import { ModalWithTitle } from '../../../components/ModalWithTitle';
import { Trigger } from '../index';

import { styles } from './styles';
import { ITrigger, IArea, IPhase } from '../Trigger.i';
import HeaderPage from '../../../components/TitleAndButton';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const ListTrigger: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const [triggers, setTriggers] = useState<ITrigger[]>([]);
  const [filteredTriggers, setFilteredTriggers] = useState<ITrigger[]>([]);

  const [selectedArea, setSelectedArea] = useState<IArea | null>(null);
  const [areas, setAreas] = useState<IArea[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [areaPhases, setAreaPhases] = useState<IPhase[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingArea, setLoadingArea] = useState(false);
  const [loadingPhase, setLoadingPhase] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );
  const [findName, setFindName] = useState('');

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, triggers.length - page * rowsPerPage);

  useEffect(() => {
    api.get('/triggers').then(response => setTriggers(response.data));
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingArea(true);
      api
        .get('/areas')
        .then(response => setAreas(response.data))
        .finally(() => setLoadingArea(false));
    })();
  }, []);

  useEffect(() => {
    if (selectedArea && selectedArea?.id) {
      (async () => {
        setLoadingPhase(true);
        api
          .get(`areas/${selectedArea.id}/phases`)
          .then(response => setAreaPhases(response.data))
          .finally(() => setLoadingPhase(false));
      })();
    }
  }, [selectedArea]);

  useEffect(() => {
    setFilteredTriggers(triggers);
  }, [triggers]);

  useEffect(() => {
    const filtered = triggers.filter(
      trigger =>
        (selectedArea?.id === trigger?.area?.id || selectedArea === null) &&
        trigger.name
          .toUpperCase()
          .trim()
          .indexOf(findName.toUpperCase().trim()) >= 0,
    );

    setPage(0);
    setFilteredTriggers(filtered);
  }, [findName, selectedArea]);

  const handleEdit = useCallback(
    (triggerId: string) => {
      history.push(`/triggers/${triggerId}`);
    },
    [history],
  );

  const handleDelete = useCallback((triggerId: string) => {
    Swal.fire({
      title: 'Deseja excluir?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        await api
          .delete(`/triggers/${triggerId}`)
          .then(onfulfilled => {
            if (onfulfilled)
              toast.success('Gatilho excluído.', {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
              });
          })
          .catch(error => {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          })
          .finally(() => {
            api.get('/triggers').then(response => setTriggers(response.data));
          });
      }
    });
  }, []);

  const handleAddTrigger = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    api.get('/triggers').then(response => setTriggers(response.data));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box>
          <HeaderPage title="Gatilhos" handleAdd={handleAddTrigger} />
          <TextField
            label="Pesquisar"
            name="find"
            margin="dense"
            variant="outlined"
            fullWidth
            value={findName}
            style={{ maxWidth: '300px' }}
            onChange={value => {
              setFindName(value.target.value);
            }}
          />
          <Autocomplete
            id="area"
            fullWidth
            getOptionLabel={area => area.name || ''}
            getOptionSelected={(area, value) => area.id === value.id}
            options={areas}
            onChange={(e, value) => {
              setSelectedArea(value || null);
            }}
            disableCloseOnSelect={false}
            loadingText="Carregando"
            renderInput={rest => (
              <TextField
                {...rest}
                id="area"
                label="Área"
                margin="dense"
                name="area"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...rest.InputProps,
                  style: { marginBottom: 15, maxWidth: '300px' },
                  endAdornment: (
                    <>
                      {loadingPhase ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {rest.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="lista de gatilhos">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell align="left">Gatilho</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Área</TableCell>
                  <TableCell align="center">Fase</TableCell>
                  <TableCell align="center">Ativo</TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredTriggers.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : filteredTriggers
                ).map(trigger => (
                  <TableRow key={trigger.id}>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Editar"
                        size="small"
                        onClick={() => handleEdit(trigger.id)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {trigger.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {trigger?.type === 'instant' ? 'Instantâneo' : 'Agendado'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {trigger?.area?.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {trigger?.phase?.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{ maxWidth: '20px' }}
                    >
                      {trigger.isActive ? 'Sim' : 'Não'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Editar"
                        size="small"
                        onClick={() => handleDelete(trigger.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, { label: 'Todos', value: -1 }]}
                    colSpan={7}
                    count={filteredTriggers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': 'Linhas por Página' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <ModalWithTitle
          open
          closeModal={handleCloseModal}
          title="Adicionar Gatilho"
          titleColor="#000000"
        >
          <Trigger closeModal={handleCloseModal} />
        </ModalWithTitle>
      )}
    </Container>
  );
};
