import { useState } from 'react';

interface TempState<T> {
  tempState: T;
  setTempState: (value: T) => void;
  applyTempState: () => void;
  resetTempState: () => void;
  mainState: T;
}

export function useTempState<T>(initialState: T): TempState<T> {
  const [tempState, setTempStateInternal] = useState<T>(initialState);
  const [mainState, setMainState] = useState<T>(initialState);

  const setTempState = (value: T) => {
    setTempStateInternal(value);
  };

  const applyTempState = () => {
    setMainState(tempState);
  };

  const resetTempState = () => {
    setTempStateInternal(initialState);
    setMainState(initialState);
  };

  return {
    tempState,
    setTempState,
    applyTempState,
    resetTempState,
    mainState,
  };
}
