import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { CheckboxWithText } from 'src/components/CheckboxWithText';
import { TaskTypes, TaskTypesIds } from '../../../utils/taskTypes.constants';
import { IObject } from '../TaskModal.i';
import { AutocompleteField } from '../../TaskInputFields/AutocompleteField';

interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (value: TaskTypesIds, associateCard?: boolean) => void;
  showCardCheckbox?: boolean;
}

export const SelectTaskTypeModal: React.FC<SimpleDialogProps> = ({
  open,
  onClose,
  onConfirm,
  showCardCheckbox = false,
}) => {
  const taskTypeOptions = Object.values(TaskTypes)
    .sort((a: IObject, b: IObject) => -b.name.localeCompare(a.name))
    .filter(type => type.id !== 'APPROVAL');

  const [selectedType, setSelectedType] = useState<IObject | null>(null);
  const [checked, setChecked] = useState(false);

  const handleChangeType = (_type: string, value: IObject | null) => {
    setSelectedType(value);
  };

  const handleOnCheckChange = (value: boolean) => {
    setChecked(value);
  };

  const handleConfirmType = () => {
    if (selectedType?.id) {
      onConfirm(
        selectedType?.id as TaskTypesIds,
        showCardCheckbox ? !checked : undefined,
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      style={{ minHeight: '280px', maxWidth: '350px', margin: '0 auto' }}
    >
      <DialogTitle>Nova Tarefa</DialogTitle>
      <DialogContent
        style={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <AutocompleteField
          fieldId="type"
          fieldName="Escolha o tipo"
          fieldValue={selectedType}
          fieldOptions={taskTypeOptions}
          isRequired
          margin="none"
          handleChangeValue={handleChangeType}
        />
        {showCardCheckbox && (
          <CheckboxWithText
            id="associateCard"
            labelText="Não associar ao Card"
            checked={checked}
            onCheckedChange={handleOnCheckChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          onClick={handleConfirmType}
          color="secondary"
          disabled={selectedType === null}
          style={{ margin: '1rem' }}
          fullWidth
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
