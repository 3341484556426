import { AlertCircleIcon } from 'lucide-react';
import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';

interface ResponsibleUserAlertProps {
  open: boolean;
  handleReturnSelectedOption: (value: boolean) => void;
}

export const ResponsibleUserAlert: React.FC<ResponsibleUserAlertProps> = ({
  open,
  handleReturnSelectedOption,
}) => {
  const handleCancelClick = () => {
    handleReturnSelectedOption(false);
  };
  const handleConfirmClick = () => {
    handleReturnSelectedOption(true);
  };

  return (
    <AlertDialog open={open} onOpenChange={undefined}>
      <AlertDialogOverlay className="z-[1500]">
        <AlertDialogContent
          autoFocus
          onKeyDown={e => {
            if (open && e.key === 'Escape') {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row items-center">
              Atenção
              <AlertCircleIcon
                className="h-[22px] w-[22px] ml-1 text-muted-foreground"
                fill="#FF3838"
                strokeWidth={1.8}
                color="#fff"
              />
            </AlertDialogTitle>
            <AlertDialogDescription>
              Um ou mais usuário responsável foi removido. Deseja transferir os
              Cards e Tarefas Avulsas com status em Aberto para outro usuário
              responsável?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelClick}>
              Não
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClick}>
              Sim
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
