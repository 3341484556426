import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { toast } from 'react-toastify';
import { Card, CardContent, CardHeader } from 'src/components/ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { IntegrationDynamicFieldsProps, KeyValue } from '../IntegrationRules.i';
import { parseFileContentToObject } from '../utils/integrationRuleUtils';

export const DynamicFieldAssociations: React.FC<
  IntegrationDynamicFieldsProps
> = ({ dynamicFieldAssociations, handleUpdateDynamicFieldAssociations }) => {
  const [parsedValues, setParsedValues] = useState<KeyValue>(
    dynamicFieldAssociations,
  );

  const handleChangeInputFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (!file) return;

      const allowedTypes = ['text/plain', 'text/csv'];
      if (!allowedTypes.includes(file.type)) {
        toast.warning('Selecione um arquivo com extensão .txt ou .csv', {
          position: 'top-center',
          theme: 'dark',
        });
        e.target.value = '';
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result as string;
        const parsedFile = parseFileContentToObject(content);
        setParsedValues(parsedFile);
      };
      reader.readAsText(file);
    },
    [],
  );

  const handleChange = useCallback(
    (key: string, newValue: string) => {
      setParsedValues(prevState => ({
        ...prevState,
        [key]: newValue,
      }));
    },
    [parsedValues],
  );

  useEffect(() => {
    if (parsedValues !== null || parsedValues !== undefined)
      handleUpdateDynamicFieldAssociations(parsedValues);
  }, [parsedValues]);

  const renderRows = useCallback(() => {
    const sortedkeys = Object.keys(parsedValues).sort();
    const sortedValues: Record<string, string> = {};
    sortedkeys.forEach(key => {
      sortedValues[key] = parsedValues[key];
    });
    return Object.entries(sortedValues).map(([key, value]) => {
      if (key === '"Hash"' || key === 'Hash') {
        return null;
      }
      return (
        <TableRow key={key}>
          <TableCell className="py-2">{key}</TableCell>
          <TableCell className="py-2">
            <Input
              value={value}
              onChange={e => handleChange(key, e.target.value)}
            />
          </TableCell>
        </TableRow>
      );
    });
  }, [parsedValues]);

  return (
    <Card className="my-4 shadow-md">
      <Accordion type="single" collapsible defaultValue="configFile">
        <AccordionItem value="configFile" className="border-b-0">
          <AccordionTrigger className="px-1">
            <CardHeader>
              <Label htmlFor="configFile" className="pl-1">
                Parâmetros da consulta
              </Label>
            </CardHeader>
          </AccordionTrigger>
          <AccordionContent>
            <CardContent>
              <Input
                id="configFile"
                type="file"
                accept=".txt,.csv"
                multiple={false}
                onChange={handleChangeInputFile}
                className="max-w-[50%] my-2 hover:cursor-pointer"
              />
              <div className="rounded-md border shadow overflow-auto box-border max-h-[400px]">
                <Table className="w-full h-full">
                  <TableHeader>
                    <TableRow>
                      <TableHead>Campo</TableHead>
                      <TableHead>Valor Fixo</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>{renderRows()}</TableBody>
                </Table>
              </div>
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
