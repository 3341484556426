import { Theme, Tooltip, withStyles } from '@material-ui/core';
import { COLORS } from 'src/styles/colors';

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: COLORS.WHITE,
    color: '#212121',
    boxShadow: theme.shadows[2],
    fontSize: 12,
  },
}))(Tooltip);
