/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Container,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../../../services/api';
import Button from '../../../../components/Button';
import { useTrigger } from '../../../../context/TriggerContext';

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IFormData {
  card_id: string;
  dispatchType_id: string;
  observation: string;
  date_suspended?: Date;
  area?: IArea | null;
  area_id: string;
}

interface IArea {
  id: string;
  name: string;
}

interface IDispatchTypeProps {
  cardId?: string;
  selectedType: IDispatchType | null;
  onClose: () => void;
  onDispatchCard: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30,
    },
    input: {
      width: '100%',
    },
    submit: {
      marginTop: theme.spacing(2),
    },
  }),
);

const DispatchTypeArea: React.FC<IDispatchTypeProps> = ({
  cardId,
  selectedType,
  onClose,
  onDispatchCard,
}) => {
  const { updateTriggers } = useTrigger();
  const [openArea] = useState(false);
  const [areas, setAreas] = useState<IArea[]>([]);

  const loadingArea = openArea && areas !== null && areas?.length === 0;

  const classes = useStyles();

  const initialValues: IFormData = {
    card_id: cardId || '',
    dispatchType_id: selectedType?.id || '',
    observation: '',
    area: null,
    area_id: '',
  };

  useEffect(() => {
    api.get('/areas').then(response => setAreas(response.data));
  }, []);

  const formSchema = Yup.object().shape({
    dispatchType_id: Yup.string()
      .uuid()
      .required('Selecione um tipo de avanço'),
    area_id: Yup.string().required('Área é obrigatória').nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (
      { card_id, dispatchType_id, area_id, observation },
      { setSubmitting },
    ) => {
      const changingArea = toast.loading(`Processando troca de área.`);
      document.body.style.cursor = 'progress';
      try {
        setSubmitting(true);

        await api
          .post(`/cards/${card_id}/dispatchTypes/${dispatchType_id}`, {
            observation,
            area_id,
          })
          .then(response => {
            const card = response?.data?.card;
            const triggers = response?.data?.triggers;
            const id = card?.id;
            const key_card = card?.key_card;
            const accountName = card?.account.name;
            const triggerDateTime = new Date();

            if (
              id &&
              key_card &&
              triggers &&
              Object.keys(triggers).length > 0
            ) {
              updateTriggers({
                card_id: id,
                key_card,
                triggers,
                accountName,
                triggerDateTime,
              });
            }

            toast.update(changingArea, {
              render: `Card foi trocado de área com sucesso.`,
              type: 'success',
              isLoading: false,
              autoClose: 2000,
              theme: 'colored',
              position: toast.POSITION.TOP_RIGHT,
            });
            document.body.style.cursor = 'auto';

            onDispatchCard();
            onClose();
          });
      } catch (error: any) {
        setSubmitting(false);
        const responseError = error.response.data.message || '';
        document.body.style.cursor = 'auto';
        toast.update(changingArea, {
          render: `Ocorreu um erro ao trocar área do card. ${responseError}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  return (
    <Container className={classes.container}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          id="business"
          className={classes.input}
          label="Tipo de avanço"
          margin="normal"
          value={selectedType?.value}
          disabled
        />
        <Autocomplete
          id="area"
          getOptionLabel={area => area.name}
          getOptionSelected={(area, value) => area.id === value.id}
          options={areas}
          loading={loadingArea}
          value={formik.values.area || null}
          onChange={(e, value) => {
            formik.setFieldValue('area_id', value !== null ? value.id : '');
            formik.setFieldValue('area', value !== null ? value : '');
          }}
          loadingText="Carregando"
          fullWidth
          renderInput={rest => (
            <TextField
              {...rest}
              id="area"
              label="Área"
              margin="normal"
              error={formik.touched.area_id && Boolean(formik.errors.area_id)}
              helperText={formik.touched.area_id && formik.errors.area_id}
              InputProps={{
                ...rest.InputProps,
                endAdornment: (
                  <>
                    {loadingArea ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {rest.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <TextField
          id="observation"
          label="Observação"
          name="observation"
          margin="normal"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.observation}
          error={
            formik.touched.observation && Boolean(formik.errors.observation)
          }
          helperText={formik.touched.observation && formik.errors.observation}
        />

        <Button
          type="submit"
          className={classes.submit}
          disabled={formik.isSubmitting}
        >
          Avançar
        </Button>
      </form>
    </Container>
  );
};

export { DispatchTypeArea };
