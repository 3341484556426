import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import * as Tooltip from '@radix-ui/react-tooltip';
import AppProvider from './context';
import Routes from './routes';
import './styles/global.css';
import { Loading } from './components/Loading';
import { TriggerAlert } from './components/TriggerAlert';
import 'react-toastify/dist/ReactToastify.css';

// export const COLORS = {
//   BLACK: 'rgba(0, 0, 0, 0.87)',
//   GREEN: 'rgb(83, 165, 70)',
//   BLUE: '#007dc6',
//   BLUE100: '#007dc61a',
//   PRIMARYBLUE: '#004071',
//   BGCOLORMENU: '#eaeeed',
//   BACKGROUND: '#fafafa',
//   BGCOLORCARD: '#fafafa',
//   BORDER: '#dfdfdf',
// };

const App: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#004071',
      },
      secondary: {
        main: '#007dc6',
      },
      background: {
        default: '#fafafa',
      },
    },
    typography: {
      fontFamily: 'Roboto',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1366,
        xl: 1536,
      },
    },
  });

  return (
    <BrowserRouter>
      <Tooltip.Provider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <AppProvider>
              <Routes />
              <Loading />
              <TriggerAlert />
              <ToastContainer autoClose={2000} />
            </AppProvider>
          </DndProvider>
        </ThemeProvider>
      </Tooltip.Provider>
    </BrowserRouter>
  );
};

export default App;
