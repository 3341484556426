import React from 'react';
import { EventProps } from 'react-big-calendar';
import { statuses } from 'src/utils/taskFilterColumnsUtils';
import { CalendarTaskData } from '../../types';

export function CustomEvent({ event }: EventProps<CalendarTaskData>) {
  const status = statuses.find(st => st.value === event.resource.status);
  const statusIcon = status?.icon;
  const statusFillColor = status?.fillColor;

  const IconComponent = statusIcon as React.FC<React.SVGProps<SVGSVGElement>>;

  return (
    <div className="overflow-hidden flex flex-nowrap truncate items-center">
      <IconComponent
        className="h-[16px] w-[16px] min-h-[16px] min-w-[16px] mr-0.5 text-muted-foreground"
        fill={statusFillColor}
        strokeWidth={1.8}
        color="#fff"
      />
      <span>{event.title}</span>
    </div>
  );
}
