/* eslint-disable camelcase */
import React from 'react';

import { Box, createStyles, DialogTitle, makeStyles } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

interface ModalProps {
  open: boolean;
  closeModal: (open: boolean) => void;
  title: string;
  titleLeft: string;
  titleRight?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    children: {
      padding: 0,
      margin: 0,
      paddingBottom: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialog: {
      width: '330px',
      maxHeight: '90vh',
      minHeight: '40vh',
    },
    titleRight: {
      display: 'flex',
      marginTop: '-29px',
      justifyContent: 'flex-end',
      fontSize: '0.9rem',
    },
  }),
);

const Modal: React.FC<ModalProps> = ({
  open,
  closeModal,
  title,
  titleLeft,
  titleRight = '',
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      onClose={closeModal}
      aria-labelledby={title}
      maxWidth="lg"
      {...rest}
    >
      <DialogTitle id={title}>
        <Box marginTop="10px" justifyContent="space-between">
          <Box height="22px" color="#53a546">
            <span>{title}</span>
          </Box>
          <Box>
            <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
          </Box>
          <span className={classes.titleRight}>{titleRight}</span>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
