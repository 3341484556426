import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: 650,
    paddingBottom: 15,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    },
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: { fontSize: '30px', fontWeight: 'bold', marginTop: '30px' },
  editableUserContainer: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 15,
  },
  newUserContainer: {
    width: '100%',
    height: '100%',
    margin: 0,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 15,
  },
  editableUserFields: {
    marginTop: '20px',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
  },
  newUserModalFields: {
    width: '100%',
    margin: 0,
    marginTop: '20px',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  boxTable: {
    width: '100%',
    maxHeight: '100%',
    marginTop: 5,
    marginBottom: 5,
  },
  tableTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.BLUE,
    width: '100%',
    height: '22px',
  },
});
