/* eslint-disable @typescript-eslint/no-unused-vars */
import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  dialog: {
    width: '600px',
    // maxHeight: '50vh',
    minHeight: '70vh',
    overflow: 'hidden',
  },
  children: {
    padding: 0,
    margin: 0,
    marginTop: 10,
    paddingBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  content: {
    maxWidth: 500,
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
});
