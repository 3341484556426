/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import { IUser, userApi } from 'src/services/userApi';
import api from '../services/api';

interface HookReturn {
  users: IUser[];
  loadingUsers: boolean;
  setRefreshUsers: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useUsers(): HookReturn {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [refreshUsers, setRefreshUsers] = useState<boolean>(false);

  useEffect(() => {
    setLoadingUsers(true);
    userApi
      .getUsers()
      .then((response: { data: IUser[] }) => {
        setUsers(response.data || []);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const errMessage = error.response?.data?.message || error.message;
        toast.error(`Falha ao buscar lista de usuários. ${errMessage || ''}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  }, [refreshUsers]);

  return {
    users,
    loadingUsers,
    setRefreshUsers,
  } as HookReturn;
}
