import React from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SearchIcon from '@material-ui/icons/Search';
import { integratedTableInputStyles } from './integratedTableInputStyles';
import { IntegratedTableInputProps } from '../../../CardCustomField.i';
import { useCardCustomFieldContext } from '../../../../../../context/CardCustomFieldContext';

const useStyles = makeStyles(() => createStyles(integratedTableInputStyles()));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const IntegratedTableInput = ({
  customField,
  phaseIndex,
  customFieldIndex,
  tableRowIndex,
  handleFillTableValue,
  selectedIntegratedItem,
  isInCurrentCardPhase,
}: IntegratedTableInputProps) => {
  const classes = useStyles();
  const { handleOpenModal } = useCardCustomFieldContext();

  return (
    // <Box className={`${classes.customField} ${classes.boolField}`}>
    <Box className={classes.integratedType}>
      <Box>
        {selectedIntegratedItem &&
        Object.keys(selectedIntegratedItem).length !== 0 ? (
          <Box className={classes.customFieldIntegrationTypeContainer}>
            <Typography
              component="span"
              className={classes.integratedFieldPrimaryKey}
            >
              {`${selectedIntegratedItem[customField.primaryKeyColumnName]}`}
            </Typography>
            <Typography
              align="left"
              component="span"
              noWrap
              className={classes.integratedFieldDisplayColumnName}
            >
              {`|
                ${selectedIntegratedItem[customField.displayColumnName]}`}
            </Typography>
          </Box>
        ) : (
          <TextField
            className={classes.integratedFieldDummyTextField}
            contentEditable={false}
            autoComplete="off"
            disabled={customField.isInactive || customField.isDisabled}
            required={customField.isRequired && isInCurrentCardPhase}
            InputLabelProps={{ shrink: false, style: { width: '100%' } }}
            InputProps={{
              disableUnderline: true,
              onKeyDown: (e: { preventDefault: () => void }) => {
                e.preventDefault();
                return false;
              },
            }}
          />
        )}
      </Box>
      <Box className={classes.integratedFieldButtons}>
        {selectedIntegratedItem &&
          Object.keys(selectedIntegratedItem).length !== 0 && (
            <IconButton
              aria-label="Excluir"
              size="small"
              disabled={customField.isInactive || customField.isDisabled}
              onClick={() =>
                handleFillTableValue(
                  customField.id,
                  tableRowIndex,
                  undefined,
                  {},
                )
              }
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
        <IconButton
          aria-label="Procurar campo integrado"
          size="small"
          disabled={customField.isInactive || customField.isDisabled}
          onClick={() =>
            handleOpenModal(
              customField,
              customFieldIndex,
              phaseIndex,
              tableRowIndex,
            )
          }
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
