import React, { useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button as ButtonMaterialUI,
  CircularProgress,
  Container,
  createStyles,
  makeStyles,
  Box,
  TextField,
  Theme,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { useAccounts } from 'src/hooks/useAccounts';
import api from '../../services/api';
import { useFilters } from '../../context/FilterContext';
import { useTrigger } from '../../context/TriggerContext';
import Button from '../Button';
import Modal from '../Modal_cards';
import Account from '../../pages/Account';
import Contact from '../../pages/Contact';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    addNewButtons: {
      height: '30px',
      width: '60px',
      fontSize: '12px',
      marginLeft: theme.spacing(1),
    },
    goBackButton: {
      marginRight: '9px',
      marginTop: theme.spacing(2),
    },
    submit: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface IFormData {
  area: IArea | undefined;
  account: IAccount | undefined;
  contact: IContact | undefined;
}

interface IArea {
  id: string;
  name: string;
}

interface IAccount {
  id: string;
  name: string;
}

interface IPhase {
  id: string;
  name: string;
}

interface IContact {
  id: string;
  name: string;
  contact_default?: boolean;
  email?: string;
  phone?: string;
  birth_date?: string;
  account: { id: string };
}

interface IContactProps {
  closeModal?: (open: boolean) => void;
}

export const CreateCard: React.FC<IContactProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeModal = value => null,
}) => {
  const classes = useStyles();
  const { handleFilters } = useFilters();
  const { setShouldReloadData } = useWorkspaceFilters();
  const { updateTriggers } = useTrigger();
  const { accounts, loadingAccounts, setRefreshAccount } = useAccounts();

  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [areaSelected, setAreaSelected] = useState<IArea>();
  const [phase, setPhase] = useState<IPhase>();
  const [areaSession, setAreaSession] = useState('');
  const [areas, setAreas] = useState<IArea[]>([]);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [contactSelected, setContactSelected] = useState<IContact>();
  const [accountSelected, setAccountSelected] = useState<IAccount>();
  const [isPinned, setIsPinned] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const goBack = () => {
    closeModal(true);
  };

  const loadingArea = areas.length === 0;

  const handleAccountModal = useCallback(() => {
    setIsAccountModalOpen(true);
  }, []);

  const handleContactModal = useCallback(() => {
    setIsContactModalOpen(true);
  }, []);

  const handleCloseAccountModal = () => {
    setIsAccountModalOpen(false);
    setRefreshAccount(oldValue => !oldValue);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
    if (accountSelected)
      api
        .get(`/accounts/${accountSelected.id}/contact`)
        .then(response => setContacts(response.data));
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'isPinned') {
      setIsPinned(event.target.checked);
    } else {
      setIsHighlighted(event.target.checked);
    }
  };

  useEffect(() => {
    api.get('/areas').then(response => setAreas(response.data));
  }, [loadingArea]);

  useEffect(() => {
    const id = localStorage.getItem('@Brasao:area') || '';
    setAreaSession(id);
  }, []);

  useEffect(() => {
    setAreaSelected(areas.find(item => item.id === areaSession));
  }, [areaSession, areas]);

  useEffect(() => {
    if (areaSelected)
      api
        .get(`/areas/${areaSelected.id}/phase`)
        .then(response => setPhase(response.data));
  }, [areaSelected]);

  useEffect(() => {
    if (accountSelected)
      api
        .get(`/accounts/${accountSelected.id}/contact`)
        .then(response => setContacts(response.data));
  }, [accountSelected]);

  useEffect(() => {
    if (
      contacts.length > 0 &&
      accountSelected &&
      accountSelected.id === contacts[0].account?.id
    ) {
      if (contacts.length === 1) {
        setContactSelected(contacts[0]);
      } else {
        let findContactDefault = false;

        contacts.forEach(contact => {
          if (contact.contact_default === true && !findContactDefault) {
            findContactDefault = true;
            setContactSelected(contact);
          }
        });

        if (!findContactDefault) {
          setContactSelected(contacts[0]);
        }
      }
    } else {
      setContactSelected(undefined);
    }
  }, [contacts, accountSelected]);

  const initialValues: IFormData = {
    area: areaSelected || undefined,
    account: accountSelected || undefined,
    contact: contactSelected || undefined,
  };

  const formSchema = Yup.object().shape({
    area: Yup.object().required('Área é obrigatório'),
    account: Yup.object().required('Conta é obrigatório'),
    contact: Yup.object().required('Contato é obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      if (phase && values.area && values.account && values.contact)
        try {
          await api
            .post('/cards', {
              area_id: values.area.id,
              account_id: values.account.id,
              contact_id: values.contact.id,
              isPinned,
              isHighlighted,
            })
            .then(response => {
              const card = response?.data?.card;
              const triggers = response?.data?.triggers;
              const id = card?.id;
              const key_card = card?.key_card;
              const accountName = card?.account.name;
              const triggerDateTime = new Date();

              if (
                id &&
                key_card &&
                triggers &&
                Object.keys(triggers).length > 0
              ) {
                updateTriggers({
                  card_id: id,
                  key_card,
                  triggers,
                  accountName,
                  triggerDateTime,
                });
              }
            });

          if (handleFilters) handleFilters({ refreshFilter: true });
          if (setShouldReloadData) setShouldReloadData(oldValue => !oldValue);

          setTimeout(() => {
            closeModal(true);
          }, 1000);

          toast.success('Card criado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setSubmitting(true);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          setSubmitting(false);
          const { message } = error?.response?.data || error || '';

          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      else {
        toast.error('Primeira fase não encontrada.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      }
    },
  });

  return (
    <Container>
      <Box className={classes.content}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Autocomplete
            id="area"
            disableClearable
            options={areas}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={(formik.values.area as IArea) || null}
            onChange={(_, value) => {
              if (value) {
                formik.setFieldValue('area', value);
                setAreaSelected(value);
              }
            }}
            fullWidth
            loadingText="Carregando"
            renderInput={rest => (
              <TextField
                {...rest}
                id="area"
                name="area"
                label="Área"
                margin="normal"
                fullWidth
                error={formik.touched.area && Boolean(formik.errors.area)}
                helperText={formik.touched.area && formik.errors.area}
                InputProps={{
                  ...rest.InputProps,
                  endAdornment: (
                    <>
                      {loadingArea ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {rest.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <TextField
            id="phase"
            placeholder="Fase"
            name="phase"
            margin="normal"
            value={phase?.name || ''}
            disabled
            fullWidth
          />
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="nowrap"
            alignItems="center"
          >
            <Autocomplete
              id="account"
              disableClearable
              options={accounts}
              getOptionLabel={account => account.name || ''}
              getOptionSelected={(account, value) => account.id === value.id}
              value={(accountSelected as IAccount) || null}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('account', value);
                  setAccountSelected(value);
                }
              }}
              fullWidth
              loadingText="Carregando"
              renderInput={params => (
                <TextField
                  {...params}
                  label="Contas"
                  margin="normal"
                  name="account"
                  error={
                    formik.touched.account && Boolean(formik.errors.account)
                  }
                  helperText={formik.touched.account && formik.errors.account}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingAccounts ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <ButtonMaterialUI
              className={classes.addNewButtons}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={handleAccountModal}
            >
              Incluir
            </ButtonMaterialUI>
          </Box>
          {isAccountModalOpen ? (
            <Modal
              open={isAccountModalOpen}
              closeModal={handleCloseAccountModal}
              title="Adicionar Conta"
              titleLeft=""
            >
              <Account closeModal={handleCloseAccountModal} />
            </Modal>
          ) : null}

          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="nowrap"
            alignItems="center"
          >
            <Autocomplete
              id="contact"
              disableClearable
              options={contacts}
              disabled={contacts.length === 0}
              getOptionLabel={contact => contact.name || ''}
              getOptionSelected={(contact, value) => contact.id === value.id}
              value={(contactSelected as IContact) || null}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('contact', value);
                  setContactSelected(value);
                }
              }}
              loadingText="Carregando"
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label="Contatos"
                  margin="normal"
                  name="contact"
                  error={
                    formik.touched.contact && Boolean(formik.errors.contact)
                  }
                  helperText={formik.touched.contact && formik.errors.contact}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingAccounts ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <ButtonMaterialUI
              disabled={formik.isSubmitting || !accountSelected}
              className={classes.addNewButtons}
              size="large"
              variant="contained"
              color="inherit"
              onClick={handleContactModal}
            >
              Incluir
            </ButtonMaterialUI>
          </Box>

          {isContactModalOpen ? (
            <Modal
              open={isContactModalOpen}
              closeModal={handleCloseContactModal}
              title="Adicionar Contato"
              titleLeft=""
            >
              <Contact
                account={accountSelected?.id}
                closeModal={handleCloseContactModal}
              />
            </Modal>
          ) : null}

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  id="isHighlighted"
                  checked={isHighlighted}
                  onChange={handleChangeCheckbox}
                  name="isHighlighted"
                />
              }
              label="Destaque"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="isPinned"
                  checked={isPinned}
                  onChange={handleChangeCheckbox}
                  name="isPinned"
                />
              }
              label="Fixo"
              labelPlacement="end"
            />
          </Box>

          <ButtonMaterialUI
            className={classes.goBackButton}
            disabled={formik.isSubmitting}
            size="large"
            variant="contained"
            color="inherit"
            onClick={goBack}
            // onClick={() => closeModal(true)}
          >
            Voltar
          </ButtonMaterialUI>
          <Button
            className={classes.submit}
            disabled={formik.isSubmitting}
            type="submit"
          >
            Salvar
          </Button>
        </form>
      </Box>
    </Container>
  );
};
