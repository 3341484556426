// const taskStatusOptions = Object.values(TaskStatus);

import { isBefore, isValid } from 'date-fns';
import { TaskData } from 'src/pages/Workspace/types';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { COLORS } from '../styles/colors';
import { TaskTypes } from './taskTypes.constants';
import { convertISODateToStringFormat } from './dateFormatAndComparisonUtils';

export const taskEmailConfirmationDialogMessage =
  'Informações de agendamento ou contato foram alteradas. Deseja enviar e-mail com aviso aos contatos?';

/**
 * Generic comparison function for sorting objects by two date properties.
 * @param {boolean} isDone. The info if the task is completed
 * @param {string | Date | null} startDate The first date property to compare.
 * @param {string | Date | null} endDate The second date property to compare.
 * @returns The current task STATUS
 */
export function validateTaskCurrentStatus(
  isDone: boolean,
  startDate: Date | string | null,
  endDate: Date | string | null,
): string {
  const formattedStartDate = new Date(startDate ?? '');
  const formattedEndDate = new Date(endDate ?? '');
  const currentDate = new Date();

  // if (isDone && isValid(formattedStartDate) && isValid(formattedEndDate)) {
  if (isDone) return 'Concluída';

  if (!isValid(formattedStartDate) || !isValid(formattedEndDate)) {
    return 'Não agendada';
  }

  if (isValid(formattedStartDate) && isValid(formattedEndDate)) {
    if (
      // isEqual(formattedEndDate, formattedStartDate) ||
      isBefore(formattedEndDate, currentDate)
    ) {
      return 'Agendamento vencido';
    }
    return 'Agendada';
  }

  return 'Não agendada';
}

/**
 * Returns the color based on the task status
 * @param {string} status - the task status
 * @returns HEX color string
 */
export function returnColorByTaskStatus(status: string): string {
  switch (status) {
    case 'Concluída':
      return COLORS.GREEN;
    case 'Agendada':
      return COLORS.BLUE;
    case 'Agendamento vencido':
      return '#FF3838';
    default:
      return '#FFB302';
  }
}

/**
 * A function that will search the task name by the ID param
 * @param {string} id - the task ID
 * @returns A string with the taskType name
 */
export function returnTaskTypeName(id: string) {
  const taskTypeOptions = Object.values(TaskTypes);
  const taskName = taskTypeOptions.find(t => t.id === id)?.name || '';
  return taskName;
}

/**
 * A function that split a string by "+" into two different values
 * @param {string | null} value - string containing two concatenated info 'taskId+cardId' concatenated
 * @returns A array of [string, string | undefined]
 */
export const splitTaskAndCardIds = (
  value: string | null,
): [string, string | undefined] => {
  if (value !== null) {
    const parts = value.split('+');
    if (parts.length === 2) {
      return [parts[0], parts[1]];
    }
    return [parts[0], undefined];
  }
  return ['', undefined];
};

/**
 * Format function that format dates, status and add another values into the object
 * @param {TaskData} task object type used in the widget of tasks
 * @param {boolean} isProcessTask - if task is from process or not.
 * @param {boolean} isGuestTask - if the user is guest in this task
 * @param {boolean} convertDatesToDateObjects - format date values to Javascript Date
 * @returns A comparison value suitable for use with the `sort()` method.
 */
export const formatWidgetTaskObject = (
  task: TaskData,
  isProcessTask: boolean,
  isGuestTask: boolean,
  convertDatesToDateObjects?: boolean,
) => {
  const formatttedCreatedAt = task.createdAt
    ? convertDatesToDateObjects
      ? new Date(task.createdAt)
      : convertISODateToStringFormat(task.createdAt)
    : '';
  const formattedStartsAt = task.startsAt
    ? convertDatesToDateObjects
      ? new Date(task.startsAt)
      : convertISODateToStringFormat(task.startsAt)
    : '';
  const formattedEndsAt = task.endsAt
    ? convertDatesToDateObjects
      ? new Date(task.endsAt)
      : convertISODateToStringFormat(task.endsAt)
    : '';

  const newTask = {
    ...task,
    taskId: task.id,
    id:
      isProcessTask && task?.id && task?.card && task.card?.id
        ? `${task.id}+${task.card.id}`
        : task.id,
    createdAt: formatttedCreatedAt,
    startsAt: formattedStartsAt,
    endsAt: formattedEndsAt,
    status: validateTaskCurrentStatus(
      !!task.completedAt,
      task.startsAt,
      task.endsAt,
    ),
    type: returnTaskTypeName(task.type),
    typeId: task.type,
    isProcessTask,
    isGuestTask,
    origin: isProcessTask ? 'P' : 'A',
  };
  return newTask;
};

export const deleteSingleTask = (
  id: string,
  shouldSendEmail: boolean,
  handleRefreshTaskList?: () => void,
) => {
  const deleteRequest = shouldSendEmail
    ? `/singleTasks/${id}?shouldSendEmail=true`
    : `/singleTasks/${id}`;
  api
    .delete(deleteRequest)
    .then(() => {
      toast.success(`Tarefa excluída com sucesso`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    })
    .then(() => {
      if (handleRefreshTaskList) handleRefreshTaskList();
    })
    .catch((error: AxiosError) => {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    });
};
