/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  Chip,
  createStyles,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Clear, AddCircleRounded } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { IValueJSON, TableModalProps } from '../../FillForm.i';
import { InputNumberMask } from '../../../../components/InputNumberMask';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const PopperMy = (props: any) => {
  return (
    <Popper
      {...props}
      style={{ maxWidth: 'fit-content' }}
      placement="bottom-start"
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles: any = makeStyles(() =>
  createStyles({
    dialog: {
      width: '90%',
      height: 'auto',
      maxHeight: '100vh',
      overflow: 'auto',
    },
    children: {
      padding: 5,
      margin: 0,
      marginBottom: 2,
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
    },
    goBackButton: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      fontSize: '12px',
      marginRight: '0.5rem',
    },
    addButton: {
      paddingLeft: '0.7rem',
      paddingRight: '0.75rem',
    },
    tableContent: {
      width: '100%',
      marginBottom: '1rem',
    },
    tableContainer: {
      maxHeight: '500px',
      overflowY: 'auto',
    },
    table: {
      minWidth: '300px',
      maxWidth: '100%',
      '& .MuiTableCell-sizeSmall': {
        padding: '0.1rem 0.5rem',
      },
      '& > *': {
        borderBottom: 'unset',
      },
    },
    tableRowSelected: {
      cursor: 'pointer',
    },
    notIngratedField: {
      width: 'auto',
      overflow: 'hidden',
      '&& .MuiInputBase-input': {
        fontSize: '14px',
      },
    },
    deleteIconColumn: {
      width: '50px',
    },
    predefinedOptionsCheckBox: {
      alignItems: 'start',
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 0,
    },
    predefinedInput: {
      display: 'flex',
      flexWrap: 'nowrap',
      '&& div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: -1,
        display: 'flex',
        flexWrap: 'nowrap',
      },
    },
    boolField: {
      display: 'flex',
      alignItems: 'center',
      order: 1,
      '&:last-child': {
        // gridColumnStart: 1,
        // gridColumnEnd: 3,
      },
    },
    boolFieldTitle: {
      display: 'block',
    },
    boolFieldOptions: {
      display: 'flex',
    },
    boolFieldOption: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const TableModal: React.FC<TableModalProps> = ({
  open,
  closeModal,
  title,
  customFieldId,
  tableColumns,
  handleFillCustomField,
  cardCfValueJson,
  ...rest
}) => {
  const classes = useStyles();

  const handleFillTableValue = (
    columnCustomFieldId: string,
    tableRowIndex: number,
    value?: string,
    valueJSON?: Record<string, unknown>,
  ) => {
    const newValueJSON = [...cardCfValueJson] as IValueJSON[];
    if (!newValueJSON[tableRowIndex][columnCustomFieldId]) {
      newValueJSON[tableRowIndex][columnCustomFieldId] = {};
    }

    if (typeof value !== 'undefined') {
      newValueJSON[tableRowIndex][columnCustomFieldId].value = value;
    } else {
      newValueJSON[tableRowIndex][columnCustomFieldId].valueJSON = valueJSON;
    }

    handleFillCustomField(customFieldId, newValueJSON as IValueJSON[], 'TABLE');
  };

  const handleAddTableRow = () => {
    if (tableColumns) {
      const newTableRow = tableColumns.reduce((row, currentColumn) => {
        return {
          ...row,
          [currentColumn.id]:
            currentColumn.type === 'INTEGRATED'
              ? {
                  valueJSON: {},
                }
              : currentColumn.type === 'PREDEFINED_STRINGS'
              ? {
                  valueJSON: [],
                }
              : {
                  value: '',
                },
        };
      }, {});
      let newValueJSON: IValueJSON[] = [];
      const cardCfTableValue: IValueJSON[] = [...cardCfValueJson];

      if (cardCfTableValue) {
        newValueJSON = cardCfTableValue;
        newValueJSON.push(newTableRow);
      } else {
        newValueJSON.push(newTableRow);
      }

      handleFillCustomField(
        customFieldId,
        newValueJSON as IValueJSON[],
        'TABLE',
      );
    }
  };

  const handleDeleteRow = (tableRowIndex: number) => {
    const newValueJSON = [...cardCfValueJson] as IValueJSON[];
    newValueJSON.splice(tableRowIndex, 1);
    handleFillCustomField(customFieldId, newValueJSON as IValueJSON[], 'TABLE');
  };

  const handleSubmitTable = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeModal();
        }
      }}
      disableEscapeKeyDown
      maxWidth="xl"
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
      >
        <DialogTitle id={title} style={{ maxWidth: '70%', overflow: 'hidden' }}>
          <Box height="22px">
            <span>{title}</span>
          </Box>
        </DialogTitle>
      </Box>
      <DialogContent className={classes.children}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
          }}
        >
          <form name="tableForm" onSubmit={handleSubmitTable}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              mb={4}
              width="100%"
              padding="10px"
            >
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="sticky table"
                  stickyHeader
                >
                  <TableHead>
                    <StyledTableRow>
                      {tableColumns?.length !== 0 && (
                        <TableCell
                          component="th"
                          scope="col"
                          align="center"
                          className={classes.deleteIconColumn}
                        >
                          <Typography variant="inherit" noWrap>
                            Excluir
                          </Typography>
                        </TableCell>
                      )}
                      {tableColumns &&
                        tableColumns.map(tableColumn => (
                          <TableCell
                            component="th"
                            scope="col"
                            align={
                              tableColumn.type === 'NUMERIC'
                                ? 'right'
                                : // eslint-disable-next-line no-constant-condition
                                tableColumn.type === 'BOOL' ||
                                  tableColumn.type === 'DATE' ||
                                  tableColumn.type === 'TIME'
                                ? 'center'
                                : 'left'
                            }
                            key={tableColumn.id}
                          >
                            <Typography variant="inherit" noWrap>
                              {tableColumn.name}{' '}
                              {tableColumn.isRequired ? '*' : ''}
                            </Typography>
                          </TableCell>
                        ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {cardCfValueJson &&
                      cardCfValueJson.map(
                        (tableRow: IValueJSON, tableRowIndex: number) => (
                          <StyledTableRow
                            className={classes.tableRowSelected}
                            key={Object.keys(tableRow)[tableRowIndex]}
                            hover
                          >
                            <TableCell
                              component="td"
                              scope="row"
                              align="center"
                            >
                              <IconButton
                                edge="start"
                                color="inherit"
                                size="small"
                                aria-label="Remover linha"
                                onClick={() => handleDeleteRow(tableRowIndex)}
                              >
                                <Clear />
                              </IconButton>
                            </TableCell>
                            {tableRow &&
                              tableColumns &&
                              tableColumns.map(tableColumn => (
                                <TableCell
                                  component="td"
                                  key={`${tableColumn.id}.${
                                    Object.keys(tableRow)[tableRowIndex]
                                  }`}
                                  scope="row"
                                  // align="center"
                                  align={
                                    tableColumn.type === 'NUMERIC'
                                      ? 'right'
                                      : tableColumn.type === 'BOOL'
                                      ? 'center'
                                      : 'left'
                                  }
                                >
                                  {tableColumn.type === 'NUMERIC' ||
                                  tableColumn.type === 'STRING' ? (
                                    <Box className={classes.notIngratedField}>
                                      <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        title={
                                          tableRow[tableColumn.id]?.value || ''
                                        }
                                        enterDelay={1500}
                                        enterNextDelay={1000}
                                        disableFocusListener
                                        disableTouchListener
                                        arrow
                                        PopperProps={{
                                          placement: 'bottom-start',
                                          popperOptions: {
                                            positionFixed: true,
                                          },
                                        }}
                                      >
                                        <TextField
                                          className={`${classes.customField} ${classes.tableTextField}`}
                                          name={tableColumn.name}
                                          autoComplete="off"
                                          required={tableColumn.isRequired}
                                          disabled={tableColumn.isInactive}
                                          fullWidth
                                          type="text"
                                          onChange={e => {
                                            handleFillTableValue(
                                              tableColumn.id,
                                              tableRowIndex,
                                              e.target.value,
                                              undefined,
                                            );
                                          }}
                                          value={
                                            tableRow[tableColumn.id] &&
                                            tableRow[tableColumn.id].value
                                              ? tableRow[tableColumn.id].value
                                              : ''
                                          }
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps:
                                              tableColumn.type === 'NUMERIC'
                                                ? {
                                                    style: {
                                                      textAlign: 'right',
                                                      minWidth: '5rem',
                                                    },
                                                  }
                                                : {
                                                    style: {
                                                      textAlign: 'left',
                                                      minWidth: '10rem',
                                                    },
                                                  },
                                            inputComponent:
                                              tableColumn.type === 'NUMERIC'
                                                ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                  (InputNumberMask as any)
                                                : undefined,
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  ) : tableColumn.type === 'BOOL' ? (
                                    <Box
                                      className={`${classes.customField} ${classes.boolField}`}
                                    >
                                      <Box>
                                        {/* <Box className={classes.boolFieldTitle}>
                                    <Typography>{tableColumn.name}</Typography>
                                  </Box> */}
                                        <Box
                                          className={classes.boolFieldOptions}
                                        >
                                          <Box
                                            className={classes.boolFieldOption}
                                          >
                                            <Checkbox
                                              className={classes.checkBox}
                                              checked={
                                                typeof tableRow[
                                                  tableColumn.id
                                                ] !== 'undefined' &&
                                                tableRow[tableColumn.id]
                                                  .value === 'true'
                                              }
                                              required={tableColumn.isRequired}
                                              disabled={tableColumn.isInactive}
                                              onChange={() => {
                                                handleFillTableValue(
                                                  tableColumn.id,
                                                  tableRowIndex,
                                                  'true',
                                                  undefined,
                                                );
                                              }}
                                            />
                                            <Typography>Sim</Typography>
                                          </Box>
                                          <Box
                                            className={classes.boolFieldOption}
                                          >
                                            <Checkbox
                                              className={classes.checkBox}
                                              checked={
                                                typeof tableRow[
                                                  tableColumn.id
                                                ] !== 'undefined' &&
                                                tableRow[tableColumn.id]
                                                  .value === 'false'
                                              }
                                              required={tableColumn.isRequired}
                                              disabled={tableColumn.isInactive}
                                              onChange={() => {
                                                handleFillTableValue(
                                                  tableColumn.id,
                                                  tableRowIndex,
                                                  'false',
                                                  undefined,
                                                );
                                              }}
                                            />
                                            <Typography>Não</Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  ) : tableColumn.type === 'TIMESTAMP' ? (
                                    <TextField
                                      className={classes.notIngratedField}
                                      name={tableColumn.name}
                                      required={tableColumn.isRequired}
                                      disabled={tableColumn.isInactive}
                                      type={tableColumn.dateType}
                                      onChange={e => {
                                        handleFillTableValue(
                                          tableColumn.id,
                                          tableRowIndex,
                                          e.target.value,
                                          undefined,
                                        );
                                      }}
                                      value={
                                        tableRow[tableColumn.id] &&
                                        tableRow[tableColumn.id].value
                                          ? tableRow[tableColumn.id].value
                                          : ''
                                      }
                                      InputProps={{
                                        disableUnderline: true,
                                        margin: 'none',
                                        inputProps: {
                                          style: {
                                            textAlign: 'center',
                                          },
                                          inputformat:
                                            tableColumn.dateType === 'DATE'
                                              ? 'dd/mm/yyyy'
                                              : 'hh:mm',
                                          pattern:
                                            tableColumn.dateType === 'DATE'
                                              ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
                                              : '[0-9]{2}:[0-5]{1}[0-9]{1}',
                                          min:
                                            tableColumn.dateType === 'DATE'
                                              ? '1900-05-01'
                                              : '00:00',
                                          max:
                                            tableColumn.dateType === 'DATE'
                                              ? '2999-12-31'
                                              : '23:59',
                                        },
                                      }}
                                    />
                                  ) : tableColumn.type ===
                                    'PREDEFINED_STRINGS' ? (
                                    <Autocomplete
                                      multiple
                                      className={classes.predefinedInput}
                                      disableClearable
                                      disableCloseOnSelect
                                      PopperComponent={PopperMy}
                                      options={
                                        tableColumn.predefinedValues as string[]
                                      }
                                      disabled={tableColumn.isInactive}
                                      fullWidth
                                      getOptionLabel={predefinedValues =>
                                        predefinedValues as string
                                      }
                                      getOptionSelected={(
                                        predefinedValues,
                                        value,
                                      ) => predefinedValues === value}
                                      value={
                                        tableRow[tableColumn.id] &&
                                        tableRow[tableColumn.id].valueJSON
                                          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            (tableRow[tableColumn.id]
                                              .valueJSON as any)
                                          : []
                                      }
                                      onChange={(e, value) => {
                                        handleFillTableValue(
                                          tableColumn.id,
                                          tableRowIndex,
                                          undefined,
                                          value as unknown as Record<
                                            string,
                                            unknown
                                          >,
                                        );
                                      }}
                                      renderOption={(option, { selected }) => (
                                        <>
                                          <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            className={
                                              classes.predefinedOptionsCheckBox
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option}
                                        </>
                                      )}
                                      renderTags={(
                                        value: string[],
                                        getTagProps,
                                      ) => {
                                        const numTags = value.length;
                                        const limitTags = 1;

                                        return (
                                          <>
                                            {value
                                              .slice(0, limitTags)
                                              .map(
                                                (
                                                  option: string,
                                                  index: number,
                                                ) => (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`${index}${option}`}
                                                    label={option}
                                                    size="small"
                                                  />
                                                ),
                                              )}

                                            {numTags > limitTags &&
                                              ` +${numTags - limitTags}`}
                                          </>
                                        );
                                      }}
                                      loadingText="Carregando"
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          name={tableColumn.name}
                                          required={
                                            !!(
                                              Object.values(
                                                tableRow[tableColumn.id]
                                                  ?.valueJSON || {},
                                              ).length === 0 &&
                                              tableColumn.isRequired
                                            )
                                          }
                                          InputProps={{
                                            ...params.InputProps,
                                            // readOnly: true,
                                            disableUnderline: true,
                                            margin: 'none',
                                            style: {
                                              display: 'flex',
                                              flexWrap: 'nowrap',
                                              padding: 0,
                                              margin: 0,
                                              fontSize: '14px',
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  ) : null}
                                </TableCell>
                              ))}
                          </StyledTableRow>
                        ),
                      )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        align="left"
                        colSpan={tableColumns ? tableColumns.length + 1 : 1}
                      >
                        <IconButton
                          className={classes.addButton}
                          edge="start"
                          color="secondary"
                          size="small"
                          aria-label="Adicionar linha"
                          onClick={handleAddTableRow}
                        >
                          <AddCircleRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              width="100%"
              mt={2}
              mb={2}
              pr={2}
            >
              <Button
                className={classes.goBackButton}
                size="medium"
                variant="contained"
                onClick={() => closeModal()}
                disabled={cardCfValueJson.length > 0}
              >
                Voltar
              </Button>
              <Button
                className={classes.addButton}
                size="medium"
                variant="contained"
                color="secondary"
                type="submit"
              >
                Salvar
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
