/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TooltipPortal, TooltipProvider } from '@radix-ui/react-tooltip';
import React, { useState } from 'react';
import { EventWrapperProps } from 'react-big-calendar';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { CalendarTooltipContent } from './CalendarTooltipContent';

export const CalendarEventWrapper: React.FC<EventWrapperProps> = ({
  event,
  children,
}) => {
  const [auxTooltipVisible, setAuxTooltipVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number | string;
    left: number | string;
  } | null>(null);

  const handleMouseEnter = (e: any) => {
    const eventDiv = e.currentTarget.querySelector('.rbc-event');
    if (eventDiv) {
      const { top, left } = eventDiv.getBoundingClientRect();
      const containerRect = e.currentTarget.getBoundingClientRect();
      const adjustedTop = top - containerRect.top - 70;
      const adjustedLeft = left - containerRect.left - 100;
      setTooltipPosition({ top: adjustedTop, left: adjustedLeft });
    }
    setAuxTooltipVisible(true);
  };

  return (
    <div onMouseEnter={handleMouseEnter}>
      <TooltipProvider>
        <Tooltip
          open={tooltipVisible}
          onOpenChange={open => {
            if (open && auxTooltipVisible) {
              setTooltipVisible(true);
            } else {
              setTooltipVisible(false);
            }
          }}
        >
          <TooltipTrigger asChild>
            <div>{children}</div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent
              style={{
                position: 'absolute',
                top: tooltipPosition?.top ?? 0,
                left: tooltipPosition?.left ?? 0,
                zIndex: 9999,
                visibility: tooltipVisible ? 'visible' : 'hidden',
              }}
            >
              {tooltipVisible && (
                <CalendarTooltipContent event={event as any} />
              )}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
