export const COLORS = {
  BLACK: 'rgba(0, 0, 0, 0.87)',
  GREEN: 'rgb(83, 165, 70)',
  LIGHTGREEN: 'rgba(83, 165, 70, 0.1)',
  BLUE: '#007dc6',
  BLUE100: '#007dc61a',
  PRIMARYBLUE: '#004071',
  BGCOLORMENU: '#eaeeed',
  BACKGROUND: '#fafafa',
  BGCOLORCARD: '#fafafa',
  BORDER: '#dfdfdf',
  WHITE: '#fff',
};
