import React from 'react';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { operators } from 'src/utils/conditionOperators';
import { ICustomField } from 'src/interface/ICardFields';
import { IGetAccountCustomFieldApi } from 'src/services/accountCustomFieldApi';
import { FixedAccountField } from 'src/utils/fixedAccountFields';
import { styles } from '../styles';
import { IOperatorProps } from '../Condition.i';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface ItemProps {
  id?: string;
  type?: string;
  operator?: string;
  customField_id?: string;
  accountField_id?: string;
  accountFieldName?: string;
  value?: string;
}

const getAutocompleteOptions = (
  customFields:
    | ICustomField[]
    | IGetAccountCustomFieldApi[]
    | FixedAccountField[],
  item: ItemProps,
) => {
  const fieldType =
    customFields[
      customFields.findIndex(
        (field: { id: string }) =>
          field.id === item.customField_id ||
          field.id === item.accountField_id ||
          field.id === item.accountFieldName,
      )
    ]?.type;

  if (
    fieldType === 'INTEGRATED' ||
    fieldType === 'STRING' ||
    fieldType === 'PREDEFINED_STRINGS' ||
    item?.type === 'phase'
  ) {
    return operators.filter(o => o.id === '=' || o.id === '!=');
  }
  if (fieldType === 'FILE') {
    return operators.filter(o => o.id === '∃' || o.id === '!∃');
  }
  return operators.filter(o => o.id !== '∃' && o.id !== '!∃') || null;
};

const isAutocompleteDisabled = (
  customFields:
    | ICustomField[]
    | IGetAccountCustomFieldApi[]
    | FixedAccountField[],
  item: ItemProps,
  isTriggerCondition: boolean,
) => {
  const fieldType =
    customFields[
      customFields.findIndex(
        (field: { id: string }) =>
          field.id === item.customField_id ||
          field.id === item.accountField_id ||
          field.id === item.accountFieldName,
      )
    ]?.type;

  return (
    (item.type === 'customField' && fieldType === 'BOOL') ||
    item.type === 'task' ||
    (item.type === 'phase' && isTriggerCondition) ||
    item.type === 'dispatch'
  );
};

export const OptionOperator: React.FC<IOperatorProps> = ({
  customFields = [],
  item,
  handleConditionChange,
  groupIndex,
  itemIndex,
  isTriggerCondition = false,
}: IOperatorProps) => {
  const classes = useStyles();
  const options = getAutocompleteOptions(customFields, item);
  const disabled = isAutocompleteDisabled(
    customFields,
    item,
    isTriggerCondition,
  );

  return (
    <Autocomplete
      id="operator"
      getOptionLabel={opt => opt.label}
      getOptionSelected={(opt, value) => opt.id === value.id}
      options={options}
      value={
        operators[operators.findIndex(field => field.id === item.operator)] ||
        null
      }
      onChange={(e, value) =>
        handleConditionChange('operator', value.id, groupIndex, itemIndex)
      }
      disableClearable
      disabled={disabled}
      size="small"
      className={classes.conditionField}
      renderInput={rest => (
        <TextField
          {...rest}
          id="operator"
          label="Condição"
          margin="dense"
          name="operator"
          variant="outlined"
          size="small"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          InputProps={{
            ...rest.InputProps,
            style: {
              fontSize: '14px',
              width: '150px',
            },
          }}
        />
      )}
    />
  );
};
