import { StyleRules } from '@material-ui/core';

export const styles = (): StyleRules => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  documentType: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottom: '1px solid #949494',
    marginTop: '18px',
    marginBottom: '8px',
  },
});
