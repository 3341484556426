/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';

import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import {
  AddCircleRounded,
  Delete,
  Edit,
  ArrowUpward,
  ArrowDownward,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { ICustomField } from 'src/interface/ICardFields';
import {
  accountCustomFieldApi,
  IGetAccountCustomFieldApi,
} from 'src/services/accountCustomFieldApi';
import { IFillRule, IFillRulesList, ITask } from '../FillRules.i';
import { COLORS } from '../../../styles/colors';
import { ModalDefaultWidthLg } from '../../ModalDefaultWidthLg';
import {
  getActionTypeLabel,
  mapCustomFieldsToNameAndSlugAsID,
  mapCustomFieldsToSlugAndUUID,
  replaceUUIDsWithSlugs,
} from '../../../utils/fillRuleUtils';
import api from '../../../services/api';
import { LightTooltip } from '../../Tooltip/LightToolTip';
import { FillRule } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    swalAlert: {
      zIndex: 99999,
    },
    truncateCellText: {
      boxSizing: 'border-box',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const FillRuleList: React.FC<IFillRulesList> = ({
  customField,
  handleRefreshCf,
  phases,
  handleSubmitWrapper,
}) => {
  const classes = useStyles();

  const [fillRuleOpen, setFillRuleOpen] = useState<boolean>(false);
  const [selectedFillRule, setSelectedFillRule] = useState<string>('');
  const [customFields, setCustomFields] = useState<ICustomField[]>([]);
  const [accountCustomFields, setAccountCustomFields] = useState<
    IGetAccountCustomFieldApi[]
  >([]);
  const [numericFields, setNumericFields] = useState<ICustomField[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);

  const [page, setPage] = useState(0);
  const rowsPerPage = Number(process.env.ROWS_PER_PAGE) || 10;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    const getAccountCustomFields = async () => {
      try {
        const response = await accountCustomFieldApi.getCustomFields();
        setAccountCustomFields(response?.data || []);
      } catch (err: unknown) {
        let errorMessage = 'Falha ao buscar campos personalizados';
        if (err instanceof Error) {
          errorMessage += `: ${err.message}`;
        }
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        setAccountCustomFields([]);
      }
    };
    getAccountCustomFields();
  }, []);

  useEffect(() => {
    if (customField.areaId) {
      api.get(`/cardTasks/area/${customField.areaId}`).then(response => {
        if (response.data && response.data.length > 0) setTasks(response.data);
      });
    }
  }, [customField.areaId]);

  useEffect(() => {
    if (customField.areaId && customField.id) {
      api.get(`/areas/${customField.areaId}/customFields`).then(response => {
        const sortedResponse = response.data.sort(
          (a: ICustomField, b: ICustomField) => {
            if (a.phase.name.toLowerCase() === b.phase.name.toLowerCase()) {
              return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            }
            return a.phase.name.toLowerCase() > b.phase.name.toLowerCase()
              ? 1
              : -1;
          },
        );
        const phasesCustomFields = sortedResponse.filter(
          (field: ICustomField) =>
            customField.phase.order >= field.phase.order &&
            field.id !== customField.id &&
            (field.type !== 'TABLE' || !field.tableOrder) &&
            field.type !== 'DOCUMENT' &&
            field.tableOrder === null,
        );
        const phasesNumericFields = sortedResponse.filter(
          (field: ICustomField) =>
            customField.phase.order >= field.phase.order &&
            field.id !== customField.id &&
            field.type === 'NUMERIC',
        );
        setNumericFields(phasesNumericFields);
        setCustomFields(phasesCustomFields);
      });
    }
  }, [customField.areaId, customField.id]);

  const handleEditFillRule = async (fillRule_id: string) => {
    await handleSubmitWrapper();
    setFillRuleOpen(true);
    setSelectedFillRule(fillRule_id);
  };

  const handleAddNewRule = async () => {
    setSelectedFillRule('');
    await handleSubmitWrapper();
    setFillRuleOpen(true);
  };

  const handleCloseFillRuleModal = () => {
    setFillRuleOpen(false);
  };

  const handleChangeOrder = (fillRuleId: string, changeTo: 'up' | 'down') => {
    api
      .patch(`/fillRules/${fillRuleId}/${changeTo}`)
      .then(() => {
        handleRefreshCf();
      })
      .catch((error: AxiosError) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      });
  };

  const handleDeleteFillRule = (fillRuleId: string) => {
    Swal.fire({
      title: 'Deseja remover essa regra?',
      text: 'Essa opção não poderá ser revertida.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: COLORS.GREEN,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        api
          .delete(`/fillRules/${fillRuleId}`)
          .then(() => {
            toast.success(`Regra excluída com sucesso`, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
            });
          })
          .then(() => {
            handleRefreshCf();
          })
          .catch((error: AxiosError) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          });
      }
    });
  };

  const sortedFillRulesByOrder = customField.fillRules
    .slice()
    .sort((a, b) => a.order - b.order);

  const orderHint =
    'Ao encontrar a primeira regra como verdadeira, o sistema não avaliará as demais.';

  const accountNumericFields =
    accountCustomFields.length > 0
      ? mapCustomFieldsToNameAndSlugAsID(
          accountCustomFields.filter(field => field.type === 'NUMERIC'),
        )
      : [];
  const cardNumericFields = numericFields
    ? mapCustomFieldsToNameAndSlugAsID(numericFields)
    : [];
  const numericCustomFields = [...cardNumericFields, ...accountNumericFields];
  const uuidToSlugMap = [
    ...mapCustomFieldsToSlugAndUUID(numericFields),
    ...mapCustomFieldsToSlugAndUUID(
      accountCustomFields.filter(field => field.type === 'NUMERIC'),
    ),
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      maxWidth="1000px"
      width="100%"
    >
      <TableContainer component={Paper} elevation={2}>
        <Table size="small" aria-label="lista de regras">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                padding="none"
                style={{ paddingLeft: '8px', width: '55px' }}
              >
                Editar
              </TableCell>
              <TableCell align="center" padding="none">
                <LightTooltip title={orderHint} placement="top-start">
                  <span>Ordenar</span>
                </LightTooltip>
              </TableCell>
              <TableCell align="center">Tipo de ação</TableCell>
              <TableCell align="center">Valor a ser preenchido</TableCell>
              <TableCell align="center">Possui condições?</TableCell>
              <TableCell
                align="center"
                padding="none"
                style={{ maxWidth: '70px', paddingRight: '1rem' }}
              >
                Remover
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? sortedFillRulesByOrder.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : sortedFillRulesByOrder
            ).map((fillRule: IFillRule) => (
              <React.Fragment key={fillRule.id}>
                <StyledTableRow>
                  <TableCell
                    scope="row"
                    align="center"
                    padding="none"
                    style={{ paddingLeft: '8px', width: '50px' }}
                  >
                    <IconButton
                      aria-label="Editar regra"
                      size="small"
                      onClick={() => handleEditFillRule(fillRule.id)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell scope="row" align="center" padding="none">
                    <Box display="flex" flexWrap="nowrap">
                      <IconButton
                        aria-label="Aumentar ordem"
                        size="small"
                        onClick={() => handleChangeOrder(fillRule.id, 'up')}
                        disabled={
                          !customField.fillRules.find(
                            cfr => cfr.order < fillRule.order,
                          )
                        }
                      >
                        <ArrowUpward />
                      </IconButton>
                      <IconButton
                        aria-label="Diminuir ordem"
                        size="small"
                        onClick={() => handleChangeOrder(fillRule.id, 'down')}
                        disabled={
                          !customField.fillRules.find(
                            cfr => cfr.order > fillRule.order,
                          )
                        }
                      >
                        <ArrowDownward />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    {getActionTypeLabel(fillRule.action.type)}
                  </TableCell>
                  <TableCell scope="row" align="center" padding="none">
                    {fillRule.action?.boolToAssign === true ? (
                      'Sim'
                    ) : fillRule.action?.boolToAssign === false ? (
                      'Não'
                    ) : fillRule.action?.predefinedValuesToAssign ? (
                      fillRule.action?.predefinedValuesToAssign?.toString()
                    ) : (
                      <Box className={classes.truncateCellText}>
                        {replaceUUIDsWithSlugs(
                          fillRule.action?.arithmeticExpression || '',
                          uuidToSlugMap,
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell scope="row" align="center" padding="none">
                    {fillRule.expression && fillRule.expression.length > 0
                      ? 'Sim'
                      : 'Não'}
                  </TableCell>
                  <TableCell
                    align="center"
                    scope="row"
                    padding="none"
                    style={{ maxWidth: '70px', paddingRight: '1rem' }}
                  >
                    <IconButton
                      aria-label="Deletar regra"
                      size="small"
                      onClick={() =>
                        handleDeleteFillRule(fillRule.id as string)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
            <TableRow>
              <TableCell align="left" colSpan={7}>
                <IconButton
                  edge="start"
                  size="small"
                  aria-label="Adicionar Regra de Preenchimento"
                  style={{
                    color: COLORS.GREEN,
                    marginLeft: 1,
                  }}
                  onClick={() => {
                    handleAddNewRule();
                  }}
                >
                  <Tooltip title="Adicionar nova regra">
                    <AddCircleRounded />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                colSpan={7}
                count={sortedFillRulesByOrder.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': '' },
                  native: true,
                  style: { margin: 0 },
                }}
                labelRowsPerPage="Linhas por página"
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                padding="none"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {fillRuleOpen && (
        <ModalDefaultWidthLg
          open={fillRuleOpen}
          closeModal={handleCloseFillRuleModal}
          title={`${customField.name} - Regra de preenchimento`}
          showGoBackButton={false}
          disableEscapeKeyDown={false}
          disablebackdropClick={false}
          maxWidth="lg"
          contentWidth="960px"
        >
          <FillRule
            closeModal={handleCloseFillRuleModal}
            handleRefreshCf={handleRefreshCf}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            customField={customField as any}
            fillRuleIdToEdit={selectedFillRule}
            customFields={customFields}
            accountCustomFields={accountCustomFields}
            numericCustomFields={numericCustomFields}
            uuidToSlugMap={uuidToSlugMap}
            fieldFillRules={customField.fillRules || []}
            phases={phases}
            tasks={tasks}
          />
        </ModalDefaultWidthLg>
      )}
    </Box>
  );
};
