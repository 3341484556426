/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import { Checkbox, Box, Typography, Button } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { ICustomFieldsProps, ICustomField, ITypeField } from '../FillForm.i';
import { InputNumberMask } from '../../../components/InputNumberMask';
import { CustomRenderInputTags } from '../../../components/AutoCompleteCustomComponents/CustomRenderInputTags';
import { StyledTextField } from '../FormTemplates/DefaultTemplate';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FillFormCustomFields: React.FC<ICustomFieldsProps> = ({
  customFields,
  handleFillCustomField,
  cardCustomFields,
  handleOpenTableModal,
  emptyRequiredTables,
}: ICustomFieldsProps) => {
  const [boolType] = useState<ITypeField[]>([
    {
      id: 'true',
      label: 'Sim',
    },
    {
      id: 'false',
      label: 'Não',
    },
  ]);

  const handleFileUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldId: string,
  ) => {
    if (e.target.files && e.target.files.length > 10) {
      // eslint-disable-next-line no-alert
      alert('Você pode enviar um máximo de 10 arquivos.');
      return;
    }

    const files = Array.from(e.target.files || []).map((file: File) => {
      const newFile = new File([file], `${fieldId}_cf_${file.name}`, {
        type: file.type,
      });
      return newFile;
    });

    handleFillCustomField(fieldId, files, 'FILE');
  };

  if (customFields.length === 0) return null;

  return (
    <>
      {customFields
        ? customFields.map((field: ICustomField) =>
            (field.type === 'STRING' && !field.isBigString) ||
            field.type === 'NUMERIC' ? (
              <StyledTextField
                key={field.id}
                id={field.id}
                label={field.name}
                name={field.id + field.name}
                margin="dense"
                autoComplete="off"
                variant="outlined"
                onChange={e =>
                  handleFillCustomField(field.id, e.target.value, field.type)
                }
                fullWidth
                required={field.isRequired}
                onKeyDown={e => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                type={field.type === 'STRING' ? 'text' : undefined}
                InputProps={{
                  inputComponent:
                    field.type === 'NUMERIC'
                      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (InputNumberMask as any)
                      : undefined,
                  inputProps:
                    field.type !== 'STRING'
                      ? {
                          minLength: 4,
                          maxLength: 10,
                        }
                      : undefined,
                }}
              />
            ) : field.type === 'STRING' && field.isBigString ? (
              <StyledTextField
                key={field.id}
                id={field.id}
                label={field.name}
                name={field.id + field.name}
                margin="dense"
                autoComplete="off"
                variant="outlined"
                onChange={e =>
                  handleFillCustomField(field.id, e.target.value, field.type)
                }
                fullWidth
                required={field.isRequired}
                multiline
                minRows={6}
                maxRows={6}
              />
            ) : field.type === 'TIMESTAMP' ? (
              <StyledTextField
                key={field.id}
                id={field.id}
                label={field.name}
                name={field.id + field.name}
                required={field.isRequired}
                type={field.dateType}
                margin="dense"
                autoComplete="off"
                variant="outlined"
                onChange={e =>
                  handleFillCustomField(
                    field.id,
                    e.target.value,
                    field.type,
                    field.dateType,
                  )
                }
                fullWidth
                onKeyDown={e => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  inputformat:
                    field.dateType === 'DATE' ? 'dd/mm/yyyy' : 'hh:mm',
                  pattern:
                    field.dateType === 'DATE'
                      ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
                      : '[0-9]{2}:[0-5]{1}[0-9]{1}',
                  min: field.dateType === 'DATE' ? '1900-05-01' : '00:00',
                  max: field.dateType === 'DATE' ? '2999-12-31' : '23:59',
                }}
              />
            ) : field.type === 'PREDEFINED_STRINGS' &&
              field.predefinedValues !== undefined &&
              field.predefinedValues !== null ? (
              <Autocomplete
                key={field.id}
                id={field.id}
                multiple
                disableCloseOnSelect
                limitTags={1}
                options={field.predefinedValues}
                disabled={field.predefinedValues.length === 0}
                getOptionLabel={predefinedValues => predefinedValues}
                getOptionSelected={(predefinedValues, value) =>
                  predefinedValues === value
                }
                value={
                  cardCustomFields[
                    cardCustomFields.findIndex(i => i.id === field.id)
                  ]?.valueJSON || []
                }
                onChange={(e, value) => {
                  handleFillCustomField(field.id, value, 'PREDEFINED_STRINGS');
                }}
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                      style={{
                        alignItems: 'start',
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingBottom: 0,
                      }}
                    />
                    {option}
                  </>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return (
                    <CustomRenderInputTags
                      tagValue={tagValue as any[]}
                      getTagProps={getTagProps}
                      size="small"
                    />
                  );
                }}
                loadingText="Carregando"
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label={field.name}
                    name={field.id + field.name}
                    required={
                      field.isRequired &&
                      (cardCustomFields[
                        cardCustomFields.findIndex(i => i.id === field.id)
                      ]?.valueJSON.length === 0 ||
                        !cardCustomFields[
                          cardCustomFields.findIndex(i => i.id === field.id)
                        ])
                    }
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            ) : field.type === 'FILE' ? (
              <Box key={field.id} mt={1}>
                <Typography variant="subtitle1">{field.name}:</Typography>
                <input
                  type="file"
                  required={field.isRequired}
                  multiple
                  accept="image/*,application/pdf"
                  onChange={e => handleFileUpload(e, field.id)}
                />
              </Box>
            ) : field.type === 'BOOL' ? (
              <Autocomplete
                key={field.id}
                id={field.id}
                disableClearable
                options={boolType}
                getOptionLabel={option => option.label}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, value) => {
                  handleFillCustomField(field.id, value.id, 'BOOL');
                }}
                loadingText="Carregando"
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    id={field.id}
                    label={field.name}
                    name={field.id + field.name}
                    required={field.isRequired}
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            ) : field.type === 'TABLE' ? (
              <div
                key={field.id}
                style={
                  emptyRequiredTables && emptyRequiredTables.includes(field.id)
                    ? {
                        border: '2px solid #f44436',
                        borderRadius: '5px',
                        padding: '5px',
                        marginBottom: '5px',
                      }
                    : { marginBottom: '5px' }
                }
              >
                <Typography
                  align="left"
                  gutterBottom
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  {field.name}
                  {field.isRequired ? '*' : ''}:
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    fontSize: '14px',
                  }}
                  fullWidth
                  onClick={() =>
                    handleOpenTableModal(
                      field.name,
                      field.id,
                      field.tableColumns.filter(tc => tc.type !== 'INTEGRATED'),
                    )
                  }
                >
                  Editar Tabela
                </Button>
                {emptyRequiredTables &&
                  emptyRequiredTables.includes(field.id) && (
                    <Typography
                      style={{
                        marginTop: '1px',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                        paddingLeft: '14px',
                        fontSize: '12px',
                      }}
                      color="error"
                    >
                      Campo obrigatório
                    </Typography>
                  )}
              </div>
            ) : null,
          )
        : null}
    </>
  );
};
