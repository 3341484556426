import { AlertCircleIcon } from 'lucide-react';
import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';

interface VisionDeletionDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  visionId: string;
  handleDeleteVision: (task_id: string) => void;
}

export const VisionDeletionDialog: React.FC<VisionDeletionDialogProps> = ({
  open,
  setOpen,
  visionId,
  handleDeleteVision,
}) => {
  const handleConfirmClick = () => {
    handleDeleteVision(visionId);
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogOverlay className="z-[1500]">
        <AlertDialogContent
          autoFocus
          onKeyDown={e => {
            if (open && e.key === 'Escape') setOpen(false);
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row items-center">
              Atenção
              <AlertCircleIcon
                className="h-[22px] w-[22px] ml-1 text-muted-foreground"
                fill="#FF3838"
                strokeWidth={1.8}
                color="#fff"
              />
            </AlertDialogTitle>
            <AlertDialogDescription>
              Esta ação não pode ser desfeita e resultará na exclusão permanente
              de todas as informações. Deseja continuar?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClick}>
              Continuar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
