/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { ChangeEvent, useState } from 'react';

import {
  createTheme,
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';
import {
  Button as ButtonMaterialUI,
  TextField,
  Container,
  Typography,
} from '@material-ui/core';

import { unMask } from 'remask';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

import {
  ITemplateProps,
  ICardCustomField,
  IFixedAccountFieldValue,
  ITableProps,
  ICustomField,
} from '../../FillForm.i';
import { styles } from './styles';
import { FillFormCustomFields } from '../../FillFormCustomFields';
import { TableModal } from '../../FillFormCustomFields/TableModal';
import { FillFormFixedAccountFields } from '../../FillFormFixedAccountFields';
import {
  handleFillAccountAndCustomFields,
  simplifyFieldArray,
} from '../../utils/fillFormUtils';
import {
  formatCpfCnpj,
  isCNPJValid,
  isCPFValid,
} from '../../../../utils/cpfCnpjUtils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const StyledTextField = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#fafafa',
      backgroundClip: 'padding-box',
      borderRadius: '5px',
    },
  }),
)(TextField);

export const DefaultTemplate: React.FC<ITemplateProps> = ({
  form_id,
  title,
  description,
  urlTitleImage,
  customFields,
  usesNameForAccountAndContact,
  accountFields,
  fixedAccountFields,
  backgroundColors,
  templateFooter,
}) => {
  const classes = useStyles();

  const templateTheme = createTheme({
    palette: {
      primary: {
        main: backgroundColors.primary,
      },
      secondary: {
        main: backgroundColors.secondary,
      },
    },
    overrides: {
      MuiInputLabel: {
        root: {
          '&$focused': {
            color: backgroundColors.primary,
          },
          '&$filled': {
            color: backgroundColors.primary,
          },
        },
      },
    },
  });

  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cardCustomFields, setCardCustomFields] = useState<ICardCustomField[]>(
    [],
  );
  const [accountFieldValues, setAccountFieldValues] = useState<
    ICardCustomField[]
  >([]);
  const [fixedAccountFieldValues, setFixedAccountFieldValues] = useState<
    IFixedAccountFieldValue[]
  >([]);

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [tableModalData, setTableModalData] = useState<ITableProps | null>(
    null,
  );
  const [emptyRequiredTables, setEmptyRequiredTables] = useState<
    string[] | null
  >(null);

  const customHandleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = unMask(ev.target.value);
    const formattedValue = formatCpfCnpj(value);
    setCpfCnpj(formattedValue);
  };

  const handleFillCustomField = (
    fieldId: string,
    value: any,
    type: string,
    dateType?: string,
  ) => {
    handleFillAccountAndCustomFields(
      setCardCustomFields,
      cardCustomFields,
      emptyRequiredTables,
      setEmptyRequiredTables,
      fieldId,
      value,
      type,
      dateType,
    );
  };

  const handleFillAccountField = (
    fieldId: string,
    value: any,
    type: string,
    dateType?: string,
  ) => {
    handleFillAccountAndCustomFields(
      setAccountFieldValues,
      accountFieldValues,
      emptyRequiredTables,
      setEmptyRequiredTables,
      fieldId,
      value,
      type,
      dateType,
    );
  };

  const handleCloseModalTable = () => {
    if (tableModalData && tableModalData !== null && tableModalData?.open) {
      setTableModalData(prevState => ({
        ...prevState!,
        open: false,
      }));
    } else {
      setTableModalData(null);
    }
  };

  const handleOpenTableModal = (
    title: string,
    customFieldId: string,
    tableColumns: ICustomField[],
  ) => {
    setTableModalData({
      open: true,
      title,
      customFieldId,
      tableColumns,
    });
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const cpfCnpjUnmasked = unMask(cpfCnpj);
    const cleanedCpfCnpj = cpfCnpjUnmasked?.replace(/[^\d]/g, '') || '';
    let isValidCpfCnpj = false;
    if (cleanedCpfCnpj?.length === 11) {
      isValidCpfCnpj = isCPFValid(cleanedCpfCnpj);
    }
    if (cleanedCpfCnpj?.length === 14) {
      isValidCpfCnpj = isCNPJValid(cleanedCpfCnpj);
    }
    if (!isValidCpfCnpj) {
      toast.error(`Campo CPF/CNPJ com formato inválido`, {
        autoClose: 4000,
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const tableNames: string[] = [];
    const tableIds: string[] = [];
    const filteredTableFields = customFields.filter(
      field =>
        field.type === 'TABLE' &&
        field.isRequired &&
        (!cardCustomFields.find(value => value.id === field.id) ||
          cardCustomFields.find(
            value =>
              value.id === field.id &&
              value.valueJSON !== null &&
              Array.isArray(value.valueJSON) &&
              value.valueJSON.length === 0,
          )),
    );

    filteredTableFields.forEach(table => {
      tableNames.push(table.name);
      tableIds.push(table.id);
    });

    if (tableNames.length > 0 && tableIds.length > 0) {
      setEmptyRequiredTables(tableIds);
      toast.error(
        `Campos do tipo tabela obrigatórios precisam conter ao menos uma linha de preenchimento. Conferir: ${tableNames}`,
        {
          isLoading: false,
          autoClose: 4000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        },
      );
      return;
    }

    document.body.style.cursor = 'progress';
    const submitingForm = toast.loading(`Enviando formulário...`);
    setSendingForm(true);

    const formattedFixedAccountFields: IFixedAccountFieldValue[] = [];
    fixedAccountFieldValues.forEach(field => {
      if (field.value && field?.value.length > 0) {
        formattedFixedAccountFields.push({
          name: field.name,
          value: field.value,
        });
      }
    });

    const formData = new FormData();

    if (usesNameForAccountAndContact) formData.append('name', name);

    formData.append('cpfCnpj', cpfCnpjUnmasked);

    formData.append('email', email);

    const filteredCardCustomFields = cardCustomFields.filter(
      field => field.type !== 'FILE',
    );

    const simplifiedCardCustomFields = simplifyFieldArray(
      filteredCardCustomFields,
      'card',
    );
    const simplifiedAccountFields = simplifyFieldArray(
      accountFieldValues,
      'account',
    );

    formData.append(
      'cardCustomFields',
      JSON.stringify(simplifiedCardCustomFields),
    );

    formData.append(
      'accountFieldValues',
      JSON.stringify(simplifiedAccountFields),
    );

    formData.append(
      'fixedAccountFields',
      JSON.stringify(formattedFixedAccountFields),
    );

    cardCustomFields.forEach(field => {
      if (field.type === 'FILE' && Array.isArray(field.value)) {
        field.value.forEach(file => {
          formData.append('files', file, file.name);
        });
      }
    });

    try {
      await api.post(`publicForms/${form_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.update(submitingForm, {
        render: `Formulário enviado.`,
        type: 'success',
        isLoading: false,
        autoClose: 2000,
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
        onClose: () => {
          handleReloadPage();
        },
      });
      document.body.style.cursor = 'auto';

      setSendingForm(false);
      document.body.style.cursor = 'auto';
    } catch (error: any) {
      const responseDataMessage = error.response.data.message || error?.message;

      toast.update(submitingForm, {
        render: `Ocorreu um erro. ${responseDataMessage}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
        theme: 'colored',
        position: toast.POSITION.TOP_RIGHT,
      });

      setSendingForm(false);
      document.body.style.cursor = 'auto';
    }
  };

  return (
    <ThemeProvider theme={templateTheme}>
      <Container
        style={{ backgroundColor: backgroundColors.body }}
        className={classes.templateRoot}
        component={Paper}
      >
        <Box
          className={classes.templateHeader}
          style={{ backgroundColor: backgroundColors.header }}
        >
          <Box>
            <img
              src={urlTitleImage}
              className={classes.imgTitle}
              alt="Imagem de título"
            />
          </Box>
          <Typography
            component="h1"
            variant="h1"
            className={classes.headerTitle}
          >
            {title}
          </Typography>
        </Box>
        <Box
          className={classes.templateDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <form
          name="mainForm"
          onSubmit={handleSubmit}
          style={{ padding: '15px', boxSizing: 'border-box' }}
        >
          <Paper
            style={{ backgroundColor: backgroundColors.content }}
            className={classes.formFields}
            elevation={2}
            variant="outlined"
          >
            <Typography component="h3">Preencha o formulário:</Typography>
            {usesNameForAccountAndContact && (
              <StyledTextField
                id="name"
                label="Nome"
                name="name"
                margin="dense"
                autoComplete="off"
                variant="outlined"
                value={name || ''}
                onChange={e => setName(e.target.value)}
                fullWidth
                required
                inputProps={{ minLength: 4, maxLength: 60 }}
                onKeyDown={e => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            )}
            <StyledTextField
              id="email"
              label="E-mail"
              name="email"
              margin="dense"
              autoComplete="off"
              variant="outlined"
              value={email || ''}
              onChange={e => {
                const isValid = emailRegex.test(e.target.value);
                setEmail(e.target.value);
                if (!isValid) {
                  setInvalidEmail(true);
                } else {
                  setInvalidEmail(false);
                }
              }}
              fullWidth
              required
              type="email"
              inputProps={{ maxLength: 90 }}
              onKeyDown={e => {
                e.key === 'Enter' && e.preventDefault();
              }}
              error={invalidEmail}
              helperText={
                invalidEmail ? 'Preencha um e-mail válido' : undefined
              }
            />
            <StyledTextField
              id="cpfCNPJ"
              label="CPF/CNPJ"
              name="cpfCNPJ"
              margin="dense"
              autoComplete="off"
              variant="outlined"
              value={cpfCnpj || ''}
              onChange={customHandleChange}
              fullWidth
              required
              onKeyDown={e => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
            {fixedAccountFields && fixedAccountFields.length > 0 && (
              <FillFormFixedAccountFields
                fixedAccountFields={fixedAccountFields}
                fixedAccountFieldValues={fixedAccountFieldValues}
                setFixedAccountFieldValues={setFixedAccountFieldValues}
              />
            )}
            {accountFields && accountFields.length > 0 && (
              <FillFormCustomFields
                customFields={accountFields}
                handleFillCustomField={handleFillAccountField}
                cardCustomFields={accountFieldValues}
                handleOpenTableModal={handleOpenTableModal}
                emptyRequiredTables={emptyRequiredTables || []}
              />
            )}
            {customFields && customFields.length > 0 && (
              <FillFormCustomFields
                customFields={customFields}
                handleFillCustomField={handleFillCustomField}
                cardCustomFields={cardCustomFields}
                handleOpenTableModal={handleOpenTableModal}
                emptyRequiredTables={emptyRequiredTables || []}
              />
            )}
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <ButtonMaterialUI
                style={{
                  fontSize: '14px',
                  marginRight: 10,
                  width: '90px',
                  marginTop: '10px',
                }}
                size="small"
                variant="contained"
                type="reset"
                onClick={handleReloadPage}
              >
                Limpar
              </ButtonMaterialUI>
              <ButtonMaterialUI
                color="secondary"
                variant="contained"
                type="submit"
                style={{
                  fontSize: '14px',
                  width: '90px',
                  marginTop: '10px',
                }}
                disabled={sendingForm}
              >
                Enviar
              </ButtonMaterialUI>
            </Box>
          </Paper>
        </form>
        {tableModalData && tableModalData.open && (
          <TableModal
            open={tableModalData.open}
            closeModal={handleCloseModalTable}
            title={tableModalData.title}
            customFieldId={tableModalData.customFieldId}
            tableColumns={tableModalData.tableColumns}
            handleFillCustomField={handleFillCustomField}
            cardCfValueJson={
              cardCustomFields[
                cardCustomFields.findIndex(
                  i => i.id === tableModalData.customFieldId,
                )
              ]?.valueJSON || []
            }
          />
        )}
        <Box
          className={classes.templateFooter}
          style={{ backgroundColor: backgroundColors.footer }}
        >
          <Box>{templateFooter}</Box>
          <Box>Brasão Sistemas - BPM © 2023.</Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
