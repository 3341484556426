import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: 1050,
    height: 'auto',
    padding: 10,
    paddingLeft: 0,
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    },
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    maxWidth: 650,
  },
  tableTitle: {
    fontSize: '14px',
    fontWeight: 500,
    width: '100%',
    height: '22px',
    marginBottom: theme.spacing(1),
  },
  editorTitle: {
    fontSize: '12px',
    height: '22px',
    width: '100%',
    fontWeight: 500,
    marginTop: '5px',
    color: COLORS.BLUE,
  },
  addButton: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#676767',
    borderRadius: '5px',
    marginLeft: theme.spacing(1),
    maxHeight: '40px',
    marginTop: '8px',
  },
  divider: {
    marginTop: '20px',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  checkBox: {
    alignItems: 'start',
  },
  titleTask: {
    fontSize: '16px',
    alignItems: 'center',
  },
  formDataContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '10px',
    '@media (max-width:763px)': {
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
  formDataBox1: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '45%',
    '@media (max-width:763px)': {
      maxWidth: '100%',
    },
  },
  formDataBox2: {
    height: '100%',
    maxWidth: '70%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: '10px',
    '@media (max-width:763px)': {
      marginLeft: '0px',
      maxWidth: '100%',
    },
  },
});
