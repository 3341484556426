/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Box from '@material-ui/core/Box';
import { IconButton, TextField } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  TableContainer,
  Container,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { toast } from 'react-toastify';
import { useAuth } from 'src/context/AuthContext';
import { useUsers } from 'src/hooks/useUsers';
import { IUser } from 'src/services/userApi';
import TablePaginationActions from '../../../components/TablePaginationActions';
import api from '../../../services/api';

import Modal from '../../../components/Modal_cards';
import User from '..';

import { styles } from './styles';
import HeaderPage from '../../../components/TitleAndButton';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const ListUser: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const { users, setRefreshUsers } = useUsers();
  const isNotAdmin = user.role.name !== 'ROLE_ADMIN';
  const [openModal, setOpenModal] = useState(false);
  const [usersFiltered, setUsersFiltered] = useState<IUser[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );
  const [findName, setFindName] = useState('');

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  useEffect(() => {
    setUsersFiltered(users);
  }, [users]);

  useEffect(() => {
    const usersF = users.filter(
      user =>
        user.name.toUpperCase().trim().indexOf(findName.toUpperCase().trim()) >=
        0,
    );

    setPage(0);
    setUsersFiltered(usersF);
  }, [findName]);

  const handleEdit = useCallback(
    (user_id: string) => {
      history.push(`/users/${user_id}`);
    },
    [history],
  );

  const handleDelete = useCallback((user_id: string) => {
    Swal.fire({
      title: 'Deseja realmente excluir esse usuário?',
      text: 'Essa opção não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/users/${user_id}`).then(() => {
            toast.success('Usuário excluído com sucesso.', {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 2500,
            });
          });
          setRefreshUsers(oldValue => !oldValue);
        } catch (error: any) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  }, []);

  const handleAddUser = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setRefreshUsers(oldValue => !oldValue);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        <Box>
          <HeaderPage title="Usuários" handleAdd={handleAddUser} />
          <TextField
            label="Pesquisar"
            name="findUser"
            margin="dense"
            variant="outlined"
            fullWidth
            value={findName}
            style={{ marginBottom: 15, maxWidth: '300px' }}
            onChange={value => {
              setFindName(value.target.value);
            }}
          />
        </Box>
        <Box className={classes.boxTable}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="lista de usuários">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnActions} />
                  <TableCell>Nome do Usuário</TableCell>
                  <TableCell className={classes.columnActions} />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? usersFiltered.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : usersFiltered
                ).map(user => (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Edit user"
                        size="small"
                        onClick={() => handleEdit(user.id)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        aria-label="Delete user"
                        size="small"
                        onClick={() => handleDelete(user.id)}
                        disabled={isNotAdmin}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, { label: 'Todos', value: -1 }]}
                    colSpan={3}
                    count={usersFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Linhas por página"
                    SelectProps={{
                      inputProps: { 'aria-label': 'Linhas por Página' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar Usuário"
          titleLeft=""
        >
          <User closeModal={handleCloseModal} />
        </Modal>
      )}
    </Container>
  );
};

export default ListUser;
