/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';

import api from '../services/api';

export interface IStoredUser {
  id: string;
  name: string;
  email: string;
  areaId: string;
  role: {
    id: string;
    name: string;
  };
}
interface AuthState {
  token: string;
  user: IStoredUser;
  company: {
    id: string;
    name: string;
    fileSize: number;
  };
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IStoredUser;
  company: {
    id: string;
    name: string;
    fileSize: number;
  };
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IStoredUser): void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const company = localStorage.getItem('@Brasao:company');
    const token = localStorage.getItem('@Brasao:token');
    const user = localStorage.getItem('@Brasao:user');

    if (token && user && company) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user), company: JSON.parse(company) };
    }

    return {} as AuthState;
  });

  // axios intercept avalia token expirado error 401 jwt expired
  useEffect(() => {
    try {
      const token = localStorage.getItem('@Brasao:token');
      if (token) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        api.interceptors.response.use(undefined, (error: any) => {
          if (error.response) {
            const status = error.response.status || '';
            const statusText = error.response.statusText || '';
            const message = error.response.data.message || '';
            if (status === 401) {
              if (
                message === 'Invalid JWT token' ||
                statusText === 'Invalid JWT token'
              ) {
                signOut();
                history.push('/');
              }
              /* if (
              message === 'Não autorizado.' ||
              statusText === 'Unauthorized'
            ) {
              history.push('/Dashboard');
              return error;
            } */
            }
          }
          return Promise.reject(error);
          // throw error;
        });
      }
    } catch (err) {
      console.error(err);
      history.push('/');
      localStorage.removeItem('@Brasao:token');
      localStorage.removeItem('@Brasao:user');
      localStorage.removeItem('@Brasao:company');
      localStorage.removeItem('@Brasao:area');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const response = await api.post('sessions', {
          email,
          password,
        });

        const { token, user, company } = response.data;

        localStorage.setItem('@Brasao:token', token);
        localStorage.setItem('@Brasao:user', JSON.stringify(user));
        localStorage.setItem('@Brasao:company', JSON.stringify(company));

        api.defaults.headers.authorization = `Bearer ${token}`;
        setData({ token, user, company });
      } catch (error) {
        console.error('Falha no login: ', error);
      }
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Brasao:token');
    localStorage.removeItem('@Brasao:user');
    localStorage.removeItem('@Brasao:company');
    localStorage.removeItem('@Brasao:area');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: IStoredUser) => {
      localStorage.setItem('@Brasao:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
        company: data.company,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user as IStoredUser,
        company: data.company as {
          id: string;
          name: string;
          fileSize: number;
        },
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
