import React, { useEffect, useState } from 'react';

import {
  Button as ButtonMaterialUI,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Box,
  TextField,
  makeStyles,
  Theme,
  createStyles,
  TablePagination,
} from '@material-ui/core';

import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import {
  GridArrowDownwardIcon,
  GridArrowUpwardIcon,
} from '@material-ui/data-grid';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { styles } from '../styles';
import { IAccountFields, ICustomField } from '../Form.i';
import api from '../../../services/api';
import { handleChangeFieldDown, handleChangeFieldUp } from '../utils/formUtils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const AccountFields: React.FC<IAccountFields> = ({
  accountFields,
  setAccountFields,
}: IAccountFields) => {
  const classes = useStyles();
  const [selectedAccountField, setSelectedAccountField] =
    useState<ICustomField | null>(null);
  const [accountFieldOptions, setAccountFieldOptions] = useState<
    ICustomField[]
  >([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(process.env.ROWS_PER_PAGE) || 10,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    api
      .get('/accountFields')
      .then(response => {
        if (response.data) {
          setAccountFieldOptions(response.data);
        }
      })
      .catch((err: AxiosError) => {
        toast.error(
          `Falha ao carregar campos personalizados de conta. ${err.message}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 4000,
            closeOnClick: true,
          },
        );
      });
  }, []);

  function handleDeleteField(index: number) {
    const tempFields = [...accountFields];
    tempFields.splice(index, 1);
    setAccountFields(tempFields);
  }

  return (
    <Box component={Paper} padding="10px" mt={2}>
      <Box width="100%" className={classes.tableTitle}>
        Campos personalizados de conta
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Autocomplete
          id="accountFieldOptions"
          style={{ width: '45%' }}
          getOptionLabel={opt => opt.name}
          getOptionSelected={(opt, value) => opt.id === value.id}
          options={accountFieldOptions.filter(
            (optionField: ICustomField) =>
              !accountFields.find(field => field.id === optionField.id),
          )}
          onChange={(e, value) => {
            setSelectedAccountField(value);
          }}
          value={selectedAccountField}
          loadingText="Carregando"
          size="small"
          noOptionsText="Sem campos disponíveis."
          renderInput={rest => (
            <TextField
              {...rest}
              id="accountFieldOptions"
              label="Campos disponíveis"
              margin="dense"
              size="small"
              name="accountFieldOptions"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <ButtonMaterialUI
          onClick={() => {
            if (selectedAccountField !== null) {
              const tempFields = [...accountFields];
              tempFields.push(selectedAccountField);
              setAccountFields(tempFields);
              setSelectedAccountField(null);
            }
          }}
          color="primary"
          size="medium"
          className={classes.addButton}
        >
          + Adicionar
        </ButtonMaterialUI>
      </Box>
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="lista de campos personalizados da conta"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Nome do campo</TableCell>
              <TableCell align="center">Obrigatório</TableCell>
              <TableCell align="center">Excluir</TableCell>
              <TableCell colSpan={2} align="center">
                {' '}
                Ordenar{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountFieldOptions.length > 0 &&
              accountFields &&
              accountFields.length > 0 &&
              accountFields.map((field, index) => (
                <TableRow key={field.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{ maxWidth: '300px', overflow: 'hidden' }}
                  >
                    {accountFieldOptions[
                      accountFieldOptions.findIndex(
                        (item: ICustomField) => item.id === field.id,
                      )
                    ]?.name || ''}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {field.isRequired ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ width: '40px' }}
                  >
                    <IconButton
                      aria-label="Excluir Campo"
                      size="small"
                      onClick={() => handleDeleteField(index)}
                    >
                      <ClearRoundedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ width: '40px' }}
                  >
                    <IconButton
                      aria-label="Campo para cima"
                      size="small"
                      disabled={accountFields[0].id === field.id}
                      onClick={() =>
                        handleChangeFieldUp(
                          field,
                          accountFields,
                          setAccountFields,
                        )
                      }
                    >
                      <GridArrowUpwardIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ width: '40px' }}
                  >
                    <IconButton
                      aria-label="Campo para baixo"
                      size="small"
                      disabled={
                        accountFields[accountFields.length - 1].id === field.id
                      }
                      onClick={() =>
                        handleChangeFieldDown(
                          field,
                          accountFields,
                          setAccountFields,
                        )
                      }
                    >
                      <GridArrowDownwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow style={{ paddingLeft: 0, paddingRight: 0 }}>
              <TablePagination
                rowsPerPageOptions={[10, 15, { label: 'Todos', value: -1 }]}
                colSpan={5}
                count={accountFields.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Linhas por página"
                SelectProps={{
                  inputProps: { 'aria-label': 'Linhas por Página' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
