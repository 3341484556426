/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  IActionFormData,
  IFile,
  ITriggerAction,
  IAttachment,
  TriggerActionTaskData,
  LinkedCardField,
} from '../../Trigger.i';
import { actionTypes } from '../../utils/triggerUtil';

export function formikValidation(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any,
  actionIndex: number | undefined,
  emailTo: string[],
  selectedType: string | undefined,
  triggerActions: ITriggerAction[],
  setTriggerActions: (value: ITriggerAction[]) => void,
  closeModal: { (open: boolean): void; (arg0: boolean): void },
  newFiles: File[],
  selectedFiles: IFile[],
  setSelectedFiles: (files: IFile[]) => void,
  setCkInputsError: (value: { subject: boolean; message: boolean }) => void,
) {
  const handleSetFormDataFiles = (
    newFilesProp: File[] | null,
    actionName: string,
  ) => {
    // Append the file to formData
    const tempActionFiles: IFile[] = [...selectedFiles];
    const actionAlreadyHasFiles = !!tempActionFiles.find(
      (af: IFile) => af.actionName === actionName,
    );
    if (newFilesProp && newFilesProp.length > 0) {
      if (actionAlreadyHasFiles) {
        const newActionFiles = tempActionFiles.map(actionFiles => {
          return {
            actionName,
            files:
              actionFiles.actionName === actionName
                ? newFilesProp
                : actionFiles.files,
          };
        });
        setSelectedFiles(newActionFiles);
      } else {
        tempActionFiles.push({
          actionName,
          files: newFilesProp,
        });
        setSelectedFiles(tempActionFiles);
      }
    } else if (
      (!newFilesProp || newFilesProp.length === 0) &&
      actionAlreadyHasFiles
    ) {
      const removeActionFiles = tempActionFiles.filter(
        (af: IFile) => af.actionName === actionName,
      );
      setSelectedFiles(removeActionFiles);
    }
  };

  const initialValues: IActionFormData = {
    name: action ? action.name : '',
    type: action ? action.type : '',
    emailSubject: action ? action?.emailSubject : '',
    sendTo: action
      ? action?.emailTo?.map((e: string) =>
          e === 'cardUser'
            ? 'Usuário do Card'
            : e === 'cardContact'
            ? 'Contato do Card'
            : e,
        )
      : emailTo,
    sender_id:
      action && (action?.emailFrom_id || action?.emailFromVariable)
        ? action?.emailFrom_id || action?.emailFromVariable
        : '',
    phase_id: action ? action.phase_id : '',
    area_id: action ? action?.area_id : '',
    user_id: action ? action?.user_id : '',
    message: action ? action?.message : '',
    suspendDuration: action ? Number(action?.suspendDuration) : 0,
    id: action ? action?.id : '',
    newReceiver: '',
    customFields: action ? action?.customFields : [],
    attachments: action && action.attachments ? action.attachments : [],
    taskData:
      action && action.taskData
        ? action.taskData
        : {
            name: '',
            type: '',
            description: '',
            deadline: 0,
            userIsLinkedCardOwner: false,
          },
    linkedCardFields:
      action && action.linkedCardFields ? action.linkedCardFields : undefined,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Descrição é obrigatório.'),
    type: Yup.string().required('Tipo é obrigatório.'),
    suspendDuration:
      selectedType === 'suspend'
        ? Yup.number()
            .min(1, 'Tempo deve ser maior que zero.')
            .required('Tempo de suspensão é obrigatório.')
        : Yup.number().nullable().optional(),
    message:
      selectedType === 'alert'
        ? Yup.string().required('Mensagem é obrigatório.')
        : Yup.string().nullable().optional(),
    phase_id:
      selectedType === 'phase'
        ? Yup.string().required('Fase é obrigatório.')
        : Yup.string().nullable().optional(),
    area_id:
      selectedType === 'area'
        ? Yup.string().required('Área é obrigatório.')
        : Yup.string().nullable().optional(),
    sender_id:
      selectedType === 'email'
        ? Yup.string().required('Remetente é obrigatório.')
        : Yup.string().nullable().optional(),
    sendTo:
      selectedType === 'email'
        ? Yup.array().required().min(1, 'Destinatário não pode ser vazio.')
        : Yup.array().nullable().optional(),
    newReceiver: Yup.string().email('Digite um email válido.').optional(),
    customFields: Yup.array().nullable().optional(),
    attachments: Yup.array().nullable().optional(),
    taskData: Yup.object({
      name: Yup.string().when('$type', {
        is: 'create_single_task',
        then: Yup.string().required('Name is required').default(''),
        otherwise: Yup.string().notRequired().default(''),
      }),
      type: Yup.string().when('$type', {
        is: 'create_single_task',
        then: Yup.string().required('Type is required').default(''),
        otherwise: Yup.string().notRequired().default(''),
      }),
      description: Yup.string().when('$type', {
        is: 'create_single_task',
        then: Yup.string().required('Description is required').default(''),
        otherwise: Yup.string().notRequired().default(''),
      }),
      deadline: Yup.number().when('$type', {
        is: 'create_single_task',
        then: Yup.number().required('Deadline is required').default(0),
        otherwise: Yup.number().notRequired().default(0),
      }),
      userIsLinkedCardOwner: Yup.boolean().when('$type', {
        is: 'create_single_task',
        then: Yup.boolean().optional().default(false),
        otherwise: Yup.boolean().optional().default(false),
      }),
    })
      .nullable()
      .default({}),
    linkedCardFields: Yup.array().nullable().optional().default([]),
  });

  return {
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: false,

    onSubmit: async (
      values: {
        type: string;
        name: string;
        message: string;
        suspendDuration: number;
        emailSubject: string;
        sendTo: string[];
        sender_id: string;
        phase_id: string;
        area_id: string;
        user_id: string;
        customFields: string[];
        attachments: IAttachment[];
        taskData?: TriggerActionTaskData;
        linkedCardFields?: LinkedCardField[];
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { setSubmitting }: any,
    ) => {
      setSubmitting(true);

      const fieldValues = values?.linkedCardFields || [];
      const fieldValuesLength = fieldValues.length;
      const hasIsCriteriaField = fieldValues.find(fv => fv.isApprovalCriterion);
      const numberOfCriterionField = fieldValues.filter(
        fv => fv.isApprovalCriterion,
      );
      if (
        selectedType === 'create_single_task' &&
        values?.taskData?.type === 'APPROVAL' &&
        (fieldValuesLength === 0 ||
          (fieldValuesLength > 0 && !hasIsCriteriaField) ||
          (fieldValuesLength > 0 && numberOfCriterionField.length > 1))
      ) {
        const message =
          numberOfCriterionField.length > 1
            ? 'Apenas um campo deve ser marcado como "Critério de Aprovação"'
            : 'Tarefa do tipo "Aprovação" precisa contar ao menos um campo como "Critério de Aprovação"';
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        setSubmitting(false);
        return;
      }

      if (
        selectedType === 'email' &&
        (!values.emailSubject || !values.message)
      ) {
        setCkInputsError({
          subject: !values.emailSubject,
          message: !values.message,
        });
        return;
      }

      const tempActions = [...triggerActions];
      const hasActionWithTheSameName = !!tempActions.find(
        t => t.name === values.name && action?.name !== values.name,
      );
      const hasDispatchAction = tempActions.find(t =>
        actionTypes.find(
          (a: { id: string; isDispatch: boolean }) =>
            a.id === t.type && a.isDispatch === true,
        ),
      );
      const isActionDispatch = !!actionTypes.find(
        (a: { id: string; isDispatch: boolean }) =>
          a.id === action?.type && a.isDispatch === true,
      );
      const isValueDispatch = !!actionTypes.find(
        (a: { id: string; isDispatch: boolean }) =>
          a.id === values.type && a.isDispatch === true,
      );

      // verifica se triggerActions tem alerta, a ação em edição, e nova ação
      const hasAlertAction = tempActions.find(t =>
        actionTypes.find(
          (a: { id: string }) => a.id === t.type && a.id === 'alert',
        ),
      );
      const isActionAlert = !!actionTypes.find(
        (a: { id: string }) => a.id === action?.type && a.id === 'alert',
      );
      const isValueAlert = !!actionTypes.find(
        (a: { id: string }) => a.id === values.type && a.id === 'alert',
      );

      if (action && actionIndex !== undefined) {
        if (action.name !== values.name && hasActionWithTheSameName) {
          const conflictingAction = tempActions.find(
            t => t.name === values.name,
          );
          toast.error(
            `Já existe uma ação com esse nome: ${conflictingAction?.name}.`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
          setSubmitting(false);
          return;
        }

        if (
          values.type !== action?.type &&
          !isActionDispatch &&
          isValueDispatch &&
          hasDispatchAction
        ) {
          toast.error(
            'Já existe outra ação de gatilho registrada para alterar este Card.',
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
          setSubmitting(false);
          return;
        }

        if (
          values.type !== action?.type &&
          !isActionAlert &&
          isValueAlert &&
          hasAlertAction
        ) {
          toast.error('Já existe uma ação do tipo alerta.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
          setSubmitting(false);
          return;
        }

        tempActions[actionIndex] = {
          name: values.name,
          type: values.type,
          message: values.message || '',
          suspendDuration: values.suspendDuration || 0,
          emailSubject: values.emailSubject || '',
          emailTo: values.sendTo?.map(e =>
            e === 'Usuário do Card'
              ? 'cardUser'
              : e === 'Contato do Card'
              ? 'cardContact'
              : e,
          ),
          emailFrom_id: values.sender_id || '',
          phase_id: values.phase_id || '',
          area_id: values.area_id || '',
          user_id: values.user_id || '',
          customFields: values.customFields || [],
          attachments:
            !newFiles || newFiles.length === 0 ? values.attachments || [] : [],
          taskData:
            values?.taskData !== undefined && values?.taskData !== null
              ? values?.taskData
              : undefined,
          linkedCardFields:
            values?.linkedCardFields && values.linkedCardFields?.length > 0
              ? values?.linkedCardFields
              : [],
        };
        setTriggerActions(tempActions);
        handleSetFormDataFiles(newFiles, values.name);

        setSubmitting(false);
        closeModal(true);
      } else {
        if (tempActions.length >= 3) {
          toast.error(
            'Limite de 3 ações foi atingido. Remova ou edite uma ação existente para adicionar uma nova.',
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
          setSubmitting(false);
          return;
        }

        if (tempActions.length > 0 && hasDispatchAction && isValueDispatch) {
          const conflictingDispatchActions = tempActions.filter(t =>
            actionTypes.find(a => a.id === t.type && a.isDispatch),
          );
          const actionNames = conflictingDispatchActions
            .map(a => a.name)
            .join(', ');
          toast.error(
            `Já existe outra ação registrada para alterar este Card. Conflito com: ${actionNames}.`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
          setSubmitting(false);
          return;
        }

        if (tempActions.length > 0 && hasAlertAction && isValueAlert) {
          toast.error('Já existe uma ação do tipo alerta.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
          setSubmitting(false);
          return;
        }

        if (tempActions.length > 0 && hasActionWithTheSameName) {
          const conflictingAction = tempActions.find(
            t => t.name === values.name,
          );
          toast.error(
            `Já existe uma ação com esse nome: ${conflictingAction?.name}.`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            },
          );
          setSubmitting(false);
          return;
        }

        tempActions.push({
          name: values.name,
          type: values.type,
          message: values.message || '',
          suspendDuration: values.suspendDuration || 0,
          emailSubject: values.emailSubject || '',
          emailTo: values.sendTo?.map(e =>
            e === 'Usuário do Card'
              ? 'cardUser'
              : e === 'Contato do Card'
              ? 'cardContact'
              : e,
          ),
          emailFrom_id: values.sender_id || '',
          phase_id: values.phase_id || '',
          area_id: values.area_id || '',
          user_id: values.user_id || '',
          customFields: values.customFields || [],
          attachments:
            !newFiles || newFiles.length === 0 ? values.attachments || [] : [],
          taskData:
            values?.taskData !== undefined && values?.taskData !== null
              ? values?.taskData
              : undefined,
          linkedCardFields:
            values?.linkedCardFields && values.linkedCardFields?.length > 0
              ? values?.linkedCardFields
              : [],
        });
        setTriggerActions(tempActions);
        handleSetFormDataFiles(newFiles, values.name);

        setSubmitting(false);
        closeModal(true);
      }
    },
  };
}
