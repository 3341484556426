'use client';

import React, { useEffect } from 'react';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { Table } from '@tanstack/react-table';

import { SlidersHorizontalIcon } from 'lucide-react';
import { Button } from './button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from './dropdown-menu';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
  initiallyInvisibleColumns: string[];
  localStorageKey: string;
}

export function DataTableViewOptions<TData>({
  table,
  initiallyInvisibleColumns,
  localStorageKey,
}: DataTableViewOptionsProps<TData>) {
  const saveColumnVisibility = (columnId: string, isVisible: boolean) => {
    const visibilityMap: { [key: string]: boolean } = {};
    table.getAllColumns().forEach(column => {
      visibilityMap[column.id] =
        column.id === columnId ? isVisible : column.getIsVisible();
    });
    if (localStorageKey.length > 0) {
      localStorage.setItem(localStorageKey, JSON.stringify(visibilityMap));
    }
  };

  // Load visibility state from localStorage
  useEffect(() => {
    const savedColumnVisibility =
      localStorageKey.length > 0
        ? localStorage.getItem(localStorageKey)
        : false;
    if (savedColumnVisibility) {
      const visibilityMap = JSON.parse(savedColumnVisibility);
      table.getAllColumns().forEach(column => {
        if (visibilityMap[column.id] !== undefined) {
          column.toggleVisibility(visibilityMap[column.id]);
        }
      });
    } else {
      table.getAllColumns().forEach(column => {
        if (initiallyInvisibleColumns.includes(column.id)) {
          column.toggleVisibility(false);
        }
      });
    }
  }, []);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="default"
          className="ml-auto h-8 lg:flex"
        >
          <SlidersHorizontalIcon className="mr-2 h-4 w-4" />
          Colunas
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[180px]">
        <DropdownMenuLabel>Visualização</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter(
            column =>
              typeof column.accessorFn !== 'undefined' && column.getCanHide(),
          )
          .map(column => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={value => {
                  column.toggleVisibility(value);
                  saveColumnVisibility(column.id, value);
                }}
                onSelect={e => e.preventDefault()}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
