/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Input } from 'src/components/ui/input';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import { Button } from 'src/components/ui/button';
import { AxiosError } from 'axios';
import { TaskLogProps } from '../TaskModal.i';

interface ILog {
  message: string;
}

export const TaskLog: React.FC<TaskLogProps> = ({
  logs,
  isProcessTask,
  card_id,
  taskId,
  handleAddNewLog,
}) => {
  const initialValues: ILog = {
    message: '',
  };

  const formSchema = Yup.object().shape({
    message: Yup.string().required('Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      let request: Promise<any>;
      const newLog = {
        message: values.message,
      };

      if (taskId && isProcessTask && card_id) {
        request = api.post(
          `/cards/${card_id}/cardTasks/${taskId}/logs`,
          newLog,
        );
      } else {
        request = api.post(`/singleTasks/${taskId}/logs`, newLog);
      }

      request
        .then(response => {
          if (response?.data) handleAddNewLog(response.data);
          toast.success('Registro criado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          resetForm();
        })
        .catch((e: AxiosError) => {
          toast.error(`Ocorreu um erro. ${e?.message || ''}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const isButtonDisabled = !taskId || !formik.values.message;

  return (
    <div className="w-full h-fit border rounded-md px-2 pb-2 mt-2 mb-20">
      <span className="-mt-2.5 block px-1 w-fit font-medium text-sm bg-white text-black">
        Registro de ocorrências
      </span>
      <div className="flex flex-row gap-2 mt-2">
        <Input
          id="message"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          type="text"
          placeholder="Novo registro"
          className="text-sm h-8 border lg:w-[100%]"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          autoComplete="off"
          disabled={!taskId}
        />
        <Button
          type="submit"
          onClick={handleFormSubmit}
          disabled={isButtonDisabled}
          className={`h-8 ${isButtonDisabled && 'disabled:bg-slate-500'}`}
        >
          Incluir
        </Button>
      </div>
      <div className="rounded-md border shadow max-h-[200px] overflow-auto mt-2">
        <Table className="w-full">
          <TableHeader>
            <TableRow>
              <TableHead className="text-left px-2 py-1 w-fit h-fit font-semibold">
                Data
              </TableHead>
              <TableHead className="text-left px-2 py-1 w-fit h-fit font-semibold">
                Usuário
              </TableHead>
              <TableHead className="text-left px-2 py-1 w-fit h-fit font-semibold">
                Mensagem
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {logs.length > 0 ? (
              logs.map(log => (
                <TableRow key={log.id}>
                  <TableCell className="text-left tex py-1 px-1.5 truncate">
                    {format(
                      parseISO(log.createdAt.toString()),
                      'dd/MM/yy HH:mm',
                      {
                        locale: ptBR,
                      },
                    )}
                  </TableCell>
                  <TableCell className="text-left py-1 px-1.5 max-w-[130px] truncate">
                    {log.user.name}
                  </TableCell>
                  <TableCell className="text-left py-1 px-1.5">
                    {log.message}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} className="h-4 p-1 text-center">
                  Sem resultados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
