/* eslint-disable @typescript-eslint/no-explicit-any */
export type TConditionOperator =
  | '>'
  | '>='
  | '<'
  | '<='
  | '='
  | '!='
  | '∃'
  | '!∃';

export type TComparisonOperator = {
  [K in TConditionOperator]: (a: any, b: any) => boolean;
};

export type TEqualityOperand = string | number | boolean;

export interface ITypeField {
  id: string;
  label: string;
}

export const comparisonOperator: TComparisonOperator = {
  '>': (a: number, b: number) => a > b,
  '>=': (a: number, b: number) => a >= b,
  '=': (a: TEqualityOperand, b: TEqualityOperand) => a === b,
  '!=': (a: TEqualityOperand, b: TEqualityOperand) => a !== b,
  '<': (a: number, b: number) => a < b,
  '<=': (a: number, b: number) => a <= b,
  '∃': (a: any) => a !== null && typeof a !== 'undefined' && a !== '',
  '!∃': (a: any) => a === null || typeof a === 'undefined' || a === '',
};

export const operators: ITypeField[] = [
  {
    id: '=',
    label: 'Igual',
  },
  {
    id: '!=',
    label: 'Diferente',
  },
  {
    id: '>',
    label: 'Maior que',
  },
  {
    id: '>=',
    label: 'Maior ou igual',
  },
  {
    id: '<',
    label: 'Menor que',
  },
  {
    id: '<=',
    label: 'Menor ou igual',
  },
  {
    id: '∃',
    label: 'Preenchido',
  },
  {
    id: '!∃',
    label: 'Não preenchido',
  },
];

export const conditionTypes: ITypeField[] = [
  {
    id: 'AND',
    label: 'E',
  },
  {
    id: 'OR',
    label: 'OU',
  },
];
