/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Container,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from 'src/services/api';
import Button from 'src/components/Button';

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IFormData {
  card_id: string;
  dispatchType_id: string;
  observation: string;
  date_suspended?: Date;
  contact_id: string;
}

interface IDispatchTypeProps {
  cardId?: string;
  selectedType: IDispatchType | null;
  onClose: () => void;
  onDispatchCard: () => void;
}

interface IContact {
  id: string;
  name: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30,
    },
    input: {
      width: '100%',
    },
    submit: {
      marginTop: theme.spacing(2),
    },
  }),
);

const DispatchTypeContact: React.FC<IDispatchTypeProps> = ({
  cardId,
  selectedType,
  onClose,
  onDispatchCard,
}) => {
  const [contacts, setContacts] = useState<IContact[]>([
    {
      id: '',
      name: '',
    },
  ]);

  const loadingContact = contacts && contacts.length === 0;

  const classes = useStyles();

  const initialValues: IFormData = {
    card_id: cardId || '',
    dispatchType_id: selectedType?.id || '',
    contact_id: '',
    observation: '',
  };

  useEffect(() => {
    api.get(`/cards/${cardId}/account/contacts`).then(response => {
      setContacts(response.data);
    });
  }, []);

  const formSchema = Yup.object().shape({
    dispatchType_id: Yup.string()
      .uuid()
      .required('Selecione um tipo de avanço'),
    contact_id: Yup.string().required('Contato é obrigatório').nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (
      { card_id, dispatchType_id, contact_id, observation },
      { setSubmitting },
    ) => {
      const changingContact = toast.loading(`Processando troca de contato.`);
      document.body.style.cursor = 'progress';
      try {
        setSubmitting(true);
        await api.post(`/cards/${card_id}/dispatchTypes/${dispatchType_id}`, {
          observation,
          contact_id,
        });

        toast.update(changingContact, {
          render: `Contato do card foi trocado com sucesso.`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
        document.body.style.cursor = 'auto';

        onDispatchCard();
        onClose();
      } catch (error: any) {
        setSubmitting(false);
        document.body.style.cursor = 'auto';
        toast.update(changingContact, {
          render: `Ocorreu um erro ao trocar contato do card. ${error.response.data.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  return (
    <Container className={classes.container}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          id="business"
          className={classes.input}
          label="Tipo de avanço"
          margin="normal"
          value={selectedType?.value}
          disabled
        />
        <Autocomplete
          id="contact"
          getOptionLabel={contact => (contact ? contact.name : '')}
          getOptionSelected={(contact, value) => contact.id === value.id}
          options={contacts}
          loading={loadingContact}
          // value={formik.values.user}
          onChange={(e, value) => {
            formik.setFieldValue('contact_id', value !== null ? value.id : '');
            formik.setFieldValue('contact', value !== null ? value : '');
          }}
          loadingText="Carregando"
          fullWidth
          renderInput={rest => (
            <TextField
              {...rest}
              id="contact"
              label="Contato"
              margin="normal"
              error={
                formik.touched.contact_id && Boolean(formik.errors.contact_id)
              }
              helperText={formik.touched.contact_id && formik.errors.contact_id}
              InputProps={{
                ...rest.InputProps,
                endAdornment: (
                  <>
                    {loadingContact ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {rest.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <TextField
          id="observation"
          label="Observação"
          name="observation"
          margin="normal"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          onChange={formik.handleChange}
          value={formik.values.observation}
          error={
            formik.touched.observation && Boolean(formik.errors.observation)
          }
          helperText={formik.touched.observation && formik.errors.observation}
        />
        <Button
          type="submit"
          className={classes.submit}
          disabled={formik.isSubmitting}
        >
          Avançar
        </Button>
      </form>
    </Container>
  );
};

export { DispatchTypeContact };
