/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';

import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ISignedDocumentLog, ILog } from '../Signature.i';

export const SignedDocumentLog: React.FC<ISignedDocumentLog> = ({
  signedDocumentId,
  signedDocumentLog,
}) => {
  const [isLogOpen, setIsLogOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = Number(process.env.ROWS_PER_PAGE) || 10;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      flexWrap="nowrap"
      mt={2}
      mb={1}
      width="100%"
    >
      <Divider style={{ width: '100%' }} />
      <Box
        mt={1}
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        onClick={() => setIsLogOpen(!isLogOpen)}
      >
        <Typography variant="h6" component="div">
          Histórico do documento
        </Typography>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setIsLogOpen(!isLogOpen)}
        >
          {isLogOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>
      {!isLogOpen && <Divider style={{ width: '100%' }} />}
      <Collapse
        in={isLogOpen}
        timeout="auto"
        unmountOnExit
        style={{ width: '100%' }}
      >
        <TableContainer
          component={Paper}
          elevation={2}
          style={{ minWidth: '100%' }}
        >
          <Table size="small" aria-label="Logs">
            <TableHead>
              <TableRow>
                <TableCell align="center">Data / Hora</TableCell>
                <TableCell align="left" style={{ width: '80%' }}>
                  Descrição
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signedDocumentLog
                .sort((a, b) => {
                  return Date.parse(b.datetime) - Date.parse(a.datetime);
                })
                .map((log: ILog) => (
                  <StyledTableRow key={`${signedDocumentId} + ${log.datetime}`}>
                    <TableCell component="th" scope="row" align="center">
                      {new Date(log.datetime).toLocaleString()}
                    </TableCell>
                    <TableCell align="left">{log.action}</TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: 'Todos', value: -1 }]}
                  colSpan={2}
                  count={signedDocumentLog.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': '' },
                    native: true,
                    style: { margin: 0 },
                  }}
                  labelRowsPerPage="Linhas por página"
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                  padding="none"
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  );
};
