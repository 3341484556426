import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Card } from 'src/pages/Card';
import { useAuth } from 'src/context/AuthContext';
import { taskModalStyles } from '../taskModalStyles';
import { TaskCardDataProps } from '../TaskModal.i';
import { AutocompleteField } from '../../TaskInputFields/AutocompleteField';
import { useFormikErrorContext } from '../../../context/FormikErrorProvider';

export const TaskCardData: React.FC<TaskCardDataProps> = ({
  handleChangeValue,
  taskArea,
  taskPhase,
  taskCard,
  areas,
  phases,
  cards,
  done,
  isProcessTask,
  editPermissionDisabledForUser,
  isUserNotAdminAndDifferentArea,
  wasCreatedByTrigger,
  isUserNotAccountResponsible,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = taskModalStyles();
  const { user } = useAuth();
  const { errors, hasError } = useFormikErrorContext();
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const taskUserId =
    cards.find(card => card.id === taskCard?.id)?.user?.id || '';
  const loggedUserSameAsCardUser = taskUserId === user.id;
  const editFieldDisabled =
    done ||
    isProcessTask ||
    editPermissionDisabledForUser ||
    isUserNotAdminAndDifferentArea ||
    wasCreatedByTrigger;

  const handleOpenCardModal = () => {
    setIsCardModalOpen(true);
  };

  const handleCloseCardModal = () => {
    setIsCardModalOpen(false);
  };

  return (
    <>
      <Box width="100%" className={classes.groupLabel}>
        Card Associado
      </Box>
      <Box className={classes.groupBoxWithBorder}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs>
            <AutocompleteField
              fieldId="area"
              fieldName="Área"
              fieldValue={taskArea ?? null}
              fieldOptions={
                areas.length > 0 && !isUserNotAdminAndDifferentArea
                  ? areas
                  : taskArea?.id
                  ? [taskArea]
                  : []
              }
              margin="none"
              handleChangeValue={handleChangeValue}
              isDisabled={editFieldDisabled}
              error={hasError('area')}
              helperText={hasError('area') ? errors.area : ''}
            />
          </Grid>
          <Grid item xs>
            <AutocompleteField
              fieldId="card"
              fieldName="Card"
              fieldValue={taskCard ?? null}
              fieldOptions={
                cards.length > 0 && !isUserNotAdminAndDifferentArea
                  ? cards
                  : taskCard?.id
                  ? [taskCard]
                  : []
              }
              margin="none"
              handleChangeValue={handleChangeValue}
              isDisabled={editFieldDisabled}
              error={hasError('card')}
              helperText={hasError('card') ? errors.card : ''}
              optionToGroupBy="phaseName"
              showStartLinkAdornment={
                (taskCard?.id &&
                  !isUserNotAdminAndDifferentArea &&
                  (!isUserNotAccountResponsible || loggedUserSameAsCardUser)) ||
                undefined
              }
              startAdornmentTooltip="Visualizar Card"
              startLinkAdornmentOnClick={
                taskCard?.id ? handleOpenCardModal : undefined
              }
            />
          </Grid>
          <Grid item xs>
            <AutocompleteField
              fieldId="phase"
              fieldName="Fase da Tarefa"
              fieldValue={taskPhase ?? null}
              fieldOptions={
                phases.length > 0 && !isUserNotAdminAndDifferentArea
                  ? phases
                  : taskPhase?.id
                  ? [taskPhase]
                  : []
              }
              margin="none"
              handleChangeValue={handleChangeValue}
              isDisabled={editFieldDisabled}
              error={hasError('phase')}
              helperText={hasError('phase') ? errors.phase : ''}
            />
          </Grid>
        </Grid>
      </Box>
      {isCardModalOpen && taskCard && (
        <Card
          cardId={taskCard.id}
          openModal={isCardModalOpen}
          handleCloseModal={handleCloseCardModal}
        />
      )}
    </>
  );
};

export const MemoizedTaskCardData = React.memo(TaskCardData);
