import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  ListboxComponent,
  renderGroup,
  VirtualizationPopper,
} from 'src/components/AutoCompleteVirtualization';
import { CustomPaperComponent } from 'src/components/AutoCompleteCustomComponents/CustomPaperComponent';
import { CustomRenderCheckboxOption } from 'src/components/AutoCompleteCustomComponents/CustomRenderCheckboxOption';
import { CustomRenderInputTags } from 'src/components/AutoCompleteCustomComponents/CustomRenderInputTags';
import { styles } from '../style';
import { EntityType } from '../../Filter.i';

const useStyles = makeStyles(styles);

interface CustomAutoCompleteProps {
  options: EntityType[];
  loading: boolean;
  selectedOptions: EntityType[];
  setCardKey: (key: string) => void;
  handleChange: (
    value: EntityType[],
    type: 'IAccount' | 'IContact' | 'IUser',
  ) => void;
  label: string;
  type: 'IAccount' | 'IContact' | 'IUser';
}

const CustomAutoComplete = ({
  options,
  loading,
  selectedOptions,
  setCardKey,
  handleChange,
  label,
  type,
}: CustomAutoCompleteProps) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id={`custom-autocomplete-${label.toLowerCase()}`}
      getOptionLabel={(option: EntityType) => option.name}
      getOptionSelected={(option: EntityType, value: EntityType) =>
        option.id === value.id
      }
      loading={loading}
      multiple
      value={selectedOptions || []}
      options={options}
      className={
        selectedOptions.length > 0 ? classes.muiInputSelected : classes.muiInput
      }
      disableCloseOnSelect
      onChange={(e, value: EntityType[]) => {
        setCardKey('');
        if (value) {
          handleChange(value, type);
        }
      }}
      loadingText="Carregando"
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      renderGroup={renderGroup}
      groupBy={(option: EntityType) => option.name[0].toUpperCase()}
      PopperComponent={VirtualizationPopper}
      PaperComponent={props => (
        <CustomPaperComponent
          paperProps={props}
          type={type}
          optionsValues={options}
          selectedValues={selectedOptions}
          handleChange={handleChange}
        />
      )}
      renderOption={(option: EntityType, { selected }) => (
        <CustomRenderCheckboxOption option={option.name} selected={selected} />
      )}
      renderTags={(tagValue, getTagProps) => (
        <CustomRenderInputTags
          tagValue={tagValue}
          getTagProps={getTagProps}
          size="small"
        />
      )}
      renderInput={rest => (
        <TextField
          {...rest}
          id={`custom-autocomplete-input-${label.toLowerCase()}`}
          label={label}
          margin="dense"
          variant="outlined"
          InputProps={{
            ...rest.InputProps,
            style: { display: 'flex', flexWrap: 'nowrap' },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {rest.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomAutoComplete;
