/* eslint-disable @typescript-eslint/no-unused-vars */
import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  colorPickerTitle: {
    fontSize: '12px',
    height: '22px',
    fontWeight: 500,
    marginTop: '5px',
    color: COLORS.BLUE,
  },
  colorPickerSwatch: {
    padding: '5px',
    background: '#fff',
    border: '1px solid #eaeeed',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'flex',
    width: '100%',
    maxWidth: '150px',
  },
  colorPickerPopover: {
    position: 'absolute',
    zIndex: 2,
  },
  colorPickerCover: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
  },
});
