import { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { mask as masker, unMask } from 'remask';
import { isCPFValid } from 'src/utils/cpfCnpjUtils';
import ReCAPTCHA from 'react-google-recaptcha';
import { showToast } from 'src/utils/showToast';
import api from 'src/services/api';
import { DocumentInfo } from 'src/@types/signatureTypes';
import { formatDateToBrazilian } from 'src/utils/formatDateToBrazilian';

const useSignerForm = (
  signer_id: string,
  setDocumentInfo: (value: DocumentInfo) => void,
  setStep: (step: number) => void,
  setUserData: (data: { name: string; cpf: string; birthDate: string }) => void,
) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [codeInputs, setCodeInputs] = useState(Array(6).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [timer, setTimer] = useState(5 * 60);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [canResend, setCanResend] = useState(false);
  let countdown: NodeJS.Timeout;
  useEffect(() => {
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true);
      setShowRecaptcha(true);
      clearInterval(countdown);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  const resendToken = async () => {
    if (!recaptchaToken) {
      showToast('Por favor, complete o reCAPTCHA novamente');
      return;
    }

    try {
      const cleanedValue = formik.values.cpf.replace(/[^\d]/g, '');
      await api.post(
        `/publicSignatures/validate/${signer_id}/cpf`,
        { cpf: cleanedValue },
        {
          headers: {
            'g-recaptcha-response': recaptchaToken,
          },
        },
      );
      setTimer(5 * 60);
      setShowRecaptcha(false);
      setCanResend(false);
      showToast('Novo token enviado.', 'success');
    } catch (error) {
      showToast(
        'Erro ao enviar novo token. Complete o captcha e tente novamente.',
      );
    }
  };

  const formik = useFormik({
    initialValues: { cpf: '', signerName: '' },
    validationSchema: Yup.object({
      cpf: Yup.string()
        .required('CPF é obrigatório')
        .test('is-valid-cpf', 'CPF inválido', value => {
          const cleanedValue = value ? value.replace(/[^\d]/g, '') : '';
          return cleanedValue.length === 11 && isCPFValid(cleanedValue);
        }),
    }),
    onSubmit: async values => {
      try {
        const token = recaptchaToken;
        if (!token) {
          showToast('Por favor, complete o reCAPTCHA');
          return;
        }
        const cleanedValue = values.cpf.replace(/[^\d]/g, '');
        if (cleanedValue.length !== 11 || !isCPFValid(cleanedValue)) {
          formik.setErrors({ cpf: 'CPF inválido' });
          showToast('CPF inválido');
          return;
        }
        const response = await api.post(
          `/publicSignatures/validate/${signer_id}/cpf`,
          { cpf: cleanedValue },
          {
            headers: {
              'g-recaptcha-response': token,
            },
          },
        );
        setUserData({
          name: response.data.name,
          cpf: response.data.cpf,
          birthDate: formatDateToBrazilian(response.data.birthDate),
        });
        formik.setFieldValue('signerName', response.data.name);
        setStep(1);
      } catch (error) {
        formik.setErrors({ cpf: 'CPF inválido' });
        showToast('Não foi possível solicitar serviço');
        formik.resetForm();
      }
    },
    enableReinitialize: true,
  });

  const handleCpfChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = unMask(ev.target.value);
    formik.setFieldValue('cpf', masker(value, ['999.999.999-99']));
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const codeFormik = useFormik({
    initialValues: {
      codigoConfirmacao: '',
    },
    validationSchema: Yup.object({
      codigoConfirmacao: Yup.string()
        .required('Código de Confirmação é obrigatório')
        .length(6, 'Código deve ter 6 dígitos'),
    }),
    onSubmit: async () => {
      await validateToken();
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      const newCodeInputs = [...codeInputs];
      newCodeInputs[index] = value;
      setCodeInputs(newCodeInputs);
      if (index < 5 && value) {
        const nextInput = inputRefs.current[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      }
      codeFormik.setFieldValue('codigoConfirmacao', newCodeInputs.join(''));
    }
  };

  const handleInputKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace') {
      if (codeInputs[index] === '') {
        if (index > 0) {
          const prevInput = inputRefs.current[index - 1];
          if (prevInput) {
            prevInput.focus();
          }
        }
      } else {
        const newCodeInputs = [...codeInputs];
        newCodeInputs[index] = '';
        setCodeInputs(newCodeInputs);
        codeFormik.setFieldValue('codigoConfirmacao', newCodeInputs.join(''));
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const paste = e.clipboardData.getData('text');
    const cleanedPaste = paste.replace(/\D/g, '');
    if (cleanedPaste.length < 6) {
      showToast('O código deve conter 6 dígitos');
      return;
    }
    const newCodeInputs = cleanedPaste.slice(0, 6).split('');
    newCodeInputs.forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }
    });
    setCodeInputs(newCodeInputs);
    codeFormik.setFieldValue('codigoConfirmacao', newCodeInputs.join(''));
    inputRefs.current[5].focus();
    e.preventDefault();
  };

  const validateCpf = async (e: React.FormEvent) => {
    e.preventDefault();
    await formik.submitForm();
  };

  const isCodeComplete = codeInputs.every(input => input !== '');

  const validateToken = async () => {
    try {
      const response = await api.post(
        `/publicSignatures/validate/${signer_id}/token`,
        { token: codeFormik.values.codigoConfirmacao },
      );
      setDocumentInfo({
        authenticated: true,
        fileBase64: `data:application/pdf;base64,${response.data.fileBase64}`,
        signerName: formik.values.signerName,
        token: codeFormik.values.codigoConfirmacao,
        hash: response.data.hash,
      });
      setStep(2);
    } catch (error) {
      showToast('Não foi possível validar o token');
      codeFormik.setErrors({ codigoConfirmacao: 'Token inválido' });
    }
  };

  return {
    formik,
    validateCpf,
    handleCpfChange,
    codeFormik,
    recaptchaRef,
    handleRecaptchaChange,
    recaptchaToken,
    codeInputs,
    handleInputChange,
    handleInputKeyDown,
    inputRefs,
    isCodeComplete,
    handlePaste,
    validateToken,
    timer,
    showRecaptcha,
    canResend,
    resendToken,
  };
};

export default useSignerForm;
