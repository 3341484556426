/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Signature } from 'src/components/Signature';
import { ModalFileField } from 'src/components/ModalFileField';
import {
  IResponseType,
  IValueType,
} from 'src/components/TaskInputFields/FileField';
import { fileStyles } from './fileStyles';
import { DigitalSignatureIcon } from '../../../../../assets/DigitalSignatureIcon';
import { SearchMultipleFilesIcon } from '../../../../../assets/SearchMultipleFilesIcon';
import { SearchFileIcon } from '../../../../../assets/SearchFileIcon';
import { FileListIcon } from '../../../../../assets/FileListIcon';
import { useAuth } from '../../../../../context/AuthContext';
import api from '../../../../../services/api';
import { useCardDataContext } from '../../../../../context/CardDataContext';
import { IFileFieldProps } from '../../CardCustomField.i';

const useStyles = makeStyles(() => createStyles(fileStyles()));

export const FileField = ({
  customField,
  phaseIndex,
  customFieldIndex,
  customFieldValueJson,
}: IFileFieldProps): JSX.Element => {
  const classes = useStyles();
  const { card_id, handleFillCustomField } = useCardDataContext();
  const {
    company: { fileSize },
  } = useAuth();
  const MAX_FILE_SIZE = (fileSize ?? 50) * 1000;
  const { REACT_APP_STORAGE_MAX_FILES_PER_BUNDLE = 10 } = process.env;

  const [openModalSignature, setOpenModalSignature] = useState<boolean>(false);
  const [openModalFileList, setOpenModalFileList] = useState<boolean>(false);
  const [signaturesCollectionStarted, setSignaturesCollectionStarted] =
    useState<boolean>(customField.file.signatureCollectionStarted);
  const [signatureId, setSignatureId] = useState<string | null>(
    customField.file.signature_id || null,
  );
  const [selectedFiles, setSelectedFiles] = useState<FileList | File[] | null>(
    null,
  );
  const [filesData, setFilesData] = useState<IValueType[]>(
    customField.valueJSON,
  );
  const [filesNames, setFilesName] = useState<string[] | string>('');

  const allowedMimeTypes = [
    'application/msword',
    'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
    'application/pdf',
    'application/x-zip-compressed',
    'application/zip',
    'application/ogg',
    'application/xml',
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'text/csv',
    'text/plain',
    'text/xml',
    'audio/mp4',
    'audio/mpeg',
    'audio/x-wav',
  ];

  const handleCloseModalSignature = () => {
    setOpenModalSignature(false);
  };

  const handleCloseModalFilesList = () => {
    setOpenModalFileList(false);
  };

  const handleFillCustomFieldOnDeleteFile = (valueJSON: IValueType[]) => {
    handleFillCustomField(
      phaseIndex,
      customFieldIndex,
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      valueJSON as any,
      customField.id,
    );
  };

  const handleSubmitFiles = (filesToSubmit: FileList | File[]) => {
    const sendingFiles = toast.loading(
      `Enviando arquivos ${customField.name}...`,
    );
    document.body.style.cursor = 'progress';

    const formData = new FormData();
    if (filesToSubmit) {
      if (!customField.file.allowsMultiple) {
        formData.append(`files`, filesToSubmit[0]);
      } else {
        for (let i = 0; i < filesToSubmit.length; i++) {
          formData.append(`files`, filesToSubmit[i]);
        }
      }
    }

    api
      .put(`/cards/${card_id}/customFields/${customField.id}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        if (response.data) {
          const respData: IResponseType = response.data;
          handleFillCustomField(
            phaseIndex,
            customFieldIndex,
            undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            respData as any,
            customField.id,
          );

          if (respData.files.length > 0) {
            setFilesData(respData.files);
          }

          if (respData?.warning && respData?.warning.length > 0) {
            toast.warning(respData.warning, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'dark',
              autoClose: 4000,
            });
          }
        }

        toast.update(sendingFiles, {
          render: `Arquivos '${customField.name}' salvos.`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const responseError = error.response?.data.message || error.message;

        if (filesData) {
          const oldFilesData = filesData.filter((oldFile: IValueType) =>
            Object.values(filesToSubmit).find(
              (file: File) => file.name !== oldFile.name,
            ),
          );
          setFilesData(oldFilesData);
        }

        toast.update(sendingFiles, {
          render: `Falha ao salvar arquivos '${customField.name}'. ${responseError}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setSelectedFiles({} as FileList);
        document.body.style.cursor = 'auto';
      });
  };

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setSelectedFiles({} as FileList);
      return;
    }

    let invalidFiles = false;

    Object.values(e.target.files).forEach((f: File) => {
      const notValidType = !allowedMimeTypes.includes(f.type);
      const fileSizeKiloBytes = f.size / 1000;
      const overLimitSize = fileSizeKiloBytes > MAX_FILE_SIZE;

      if (notValidType || overLimitSize) {
        const message =
          notValidType && overLimitSize
            ? `Extensão inválida e tamanho além do permitido (${fileSize}Mb).`
            : notValidType && !overLimitSize
            ? `Extensão do tipo ${f.type} não é permitido.`
            : !notValidType && overLimitSize
            ? `Tamanho do arquivo além do permitido. (${fileSize}Mb).`
            : 'Arquivo inválido.';

        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });

        invalidFiles = true;
        setSelectedFiles({} as FileList);

        return;
      }

      if (customField.file.isRequiredToSign && f.type !== 'application/pdf') {
        toast.error(
          'Com assinatura eletrônica apenas arquivos do tipo PDF são permitidos.',
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );

        invalidFiles = true;
        setSelectedFiles([]);
      }
    });

    if (!invalidFiles && e.target.files && e.target.files.length > 0) {
      const formattedTargetFiles: IValueType[] = [];
      const tempTargetFiles = Object.values(e.target.files).map((f: File) => {
        formattedTargetFiles.push({ name: f.name, url: '', size: f.size });

        return f;
      });

      if (
        tempTargetFiles &&
        tempTargetFiles.length > Number(REACT_APP_STORAGE_MAX_FILES_PER_BUNDLE)
      ) {
        toast.error(
          `Selecione um número máximo de até ${REACT_APP_STORAGE_MAX_FILES_PER_BUNDLE} arquivos.`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
        return;
      }

      if (
        tempTargetFiles &&
        tempTargetFiles.length > 0 &&
        tempTargetFiles.length <= Number(REACT_APP_STORAGE_MAX_FILES_PER_BUNDLE)
      ) {
        setFilesData(formattedTargetFiles);
        setSelectedFiles(tempTargetFiles);
        handleSubmitFiles(tempTargetFiles);
      }
    }
  };

  useEffect(() => {
    if (filesData && filesData.length > 0) {
      const tempValues = filesData;
      const formattedValues: string[] = Object.values(tempValues).map(
        (file: IValueType) => file.name,
      );

      setFilesName(formattedValues);
    } else {
      setFilesName([]);
    }
  }, [filesData]);

  const fileInputFieldValue =
    filesNames && filesNames.length > 1 && customField.file.allowsMultiple
      ? `${filesNames.length} arquivos`
      : filesNames && filesNames.length === 1 && customField.file.allowsMultiple
      ? filesNames[0]
      : filesNames.length > 0 && !customField.file.allowsMultiple
      ? filesNames[0]
      : '';

  return (
    <Box className={classes.documentType}>
      <TextField
        contentEditable={false}
        label={customField.name}
        autoComplete="off"
        required={customField.isRequired}
        value={fileInputFieldValue}
        InputLabelProps={{
          shrink: filesNames.length > 0,
          style: {
            maxWidth: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            paddingBottom: '1px',
          },
        }}
        style={{
          flex: '1',
          padding: 0,
          margin: 0,
        }}
        fullWidth
        margin="dense"
        InputProps={{
          disableUnderline: true,
          onKeyDown: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            return false;
          },
        }}
      />
      <Box
        mt={1}
        mb={0}
        pb={0}
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Tooltip
          title={`Seleção de arquivo${
            customField.file.allowsMultiple ? 's' : ''
          }`}
        >
          <div>
            <IconButton
              color="inherit"
              component="label"
              style={{
                padding: '0',
                marginLeft: '1px',
                marginRight: '3px',
              }}
              disabled={signaturesCollectionStarted || customField?.isDisabled}
            >
              <input
                type="file"
                id={customField.id}
                multiple={customField.file.allowsMultiple}
                onChange={handleOnFileChange}
                hidden
                accept={allowedMimeTypes.toString()}
              />
              {customField.file.allowsMultiple ? (
                <SearchMultipleFilesIcon htmlColor="#757575" />
              ) : (
                <SearchFileIcon
                  htmlColor={
                    signaturesCollectionStarted ? '#dfdfdf' : '#757575'
                  }
                />
              )}
            </IconButton>{' '}
          </div>
        </Tooltip>

        <IconButton
          aria-label="Definir assinaturas"
          size="small"
          disabled={
            !customField.file.isRequiredToSign || customField?.isDisabled
          }
          onClick={() => setOpenModalSignature(true)}
        >
          <Tooltip title="Assinaturas">
            <div style={{ margin: 0, padding: 0 }}>
              <DigitalSignatureIcon />
            </div>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="Arquivos armazenados"
          size="small"
          onClick={() => setOpenModalFileList(true)}
          disabled={customField?.isDisabled}
        >
          <Tooltip title="Arquivos armazenados">
            <div style={{ margin: 0, padding: 0 }}>
              <FileListIcon htmlColor="black" />
            </div>
          </Tooltip>
        </IconButton>
      </Box>
      {openModalSignature && (
        <Signature
          title={`Assinaturas - ${customField.name}`}
          open={openModalSignature}
          closeModal={handleCloseModalSignature}
          card_id={card_id}
          signature_id={signatureId}
          setSignatureId={setSignatureId}
          cardField_id={customField.id}
          hasFilesOrTemplate={
            !!(
              selectedFiles ||
              (customFieldValueJson && customFieldValueJson.length > 0)
            )
          }
          setSignaturesCollectionStarted={setSignaturesCollectionStarted}
        />
      )}
      {openModalFileList && (
        <ModalFileField
          title={`${customField.name}`}
          open={openModalFileList}
          closeModal={handleCloseModalFilesList}
          card_id={card_id}
          customFieldId={customField.id}
          allowedFileTypes={allowedMimeTypes.toString()}
          allowsMultipleFiles={customField.file.allowsMultiple}
          filesData={filesData}
          setFilesData={setFilesData}
          handleFillCustomFieldOnDeleteFile={handleFillCustomFieldOnDeleteFile}
          handleOnFileChange={handleOnFileChange}
          hasSignaturesCollectionStarted={signaturesCollectionStarted}
        />
      )}
    </Box>
  );
};
