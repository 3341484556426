/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Tooltip, Typography } from '@material-ui/core';
import api from '../../../services/api';
import { Card } from '..';

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    '& .MuiTreeItem-content': {
      marginTop: '10px',
      padding: '10px 0px',
      backgroundColor: '#eeee',
      border: 'solid',
      background: '#eeee',
      borderColor: '#dfdfdf',
      borderWidth: '2px',
      borderRadius: '5px',
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
      {
        backgroundColor: '#eeee',
      },
    '& .MuiTypography-body1': {
      // marginLeft: '4px',
    },
    '& .MuiTypography-body2': {
      background: '#eeee',
    },
    '& .MuiTreeItem-iconContainer svg': {
      marginLeft: '7px',
    },
    '& .MuiTreeItem-label': {
      background: '#eeee',
    },
    '& .MuiTreeItem-iconContainer': {
      marginRight: '1px',
    },
  },
});

interface IFamily {
  card_id: string;
  key_card: number;
  description: string;
  area: {
    name: string;
    percentage: number;
  };
  phase: {
    id: string;
    name: string;
    percentage: number;
  };
  account: string;
  task: string;
  hierarchy: Array<IFamily>;
}

interface IHierarchyProps {
  card_id: string;
}

const Hierarchy: React.FC<IHierarchyProps> = ({ card_id }) => {
  const classes = useStyles();
  const [family, setFamily] = useState<IFamily>();
  const [openModal, setOpenModal] = useState(false);
  const [cardIdSelected, setCardIdSelected] = useState('');
  const [collectionKeyCards, setCollectionKeyCards] = useState<string[]>([]);
  const arr: string[] = [];

  const findKeyCard = (familyItem: IFamily[]) => {
    familyItem.forEach(f => {
      if (f.hierarchy.length > 0) {
        findKeyCard(f.hierarchy);
        arr.push(String(f.key_card));
        setCollectionKeyCards(arr);
      }
    });
  };

  useEffect(() => {
    api
      .get(`cards/${card_id}/listChildren`)
      .then(response => setFamily(response.data));
  }, [card_id]);

  useEffect(() => {
    if (family) {
      arr.push(String(family.key_card));
      setCollectionKeyCards(arr);
      findKeyCard(family.hierarchy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family]);

  const renderTree = (nodes: IFamily) => (
    <TreeItem
      key={nodes.card_id}
      nodeId={String(nodes.key_card)}
      label={
        <>
          <Tooltip
            title={
              <div>
                <div>
                  {nodes.phase
                    ? `Fase: ${nodes.phase?.name} - ${nodes.phase?.percentage}%`
                    : 'Fase não definida'}
                </div>
                <div>{`Tarefa: ${nodes.task}`}</div>
              </div>
            }
          >
            <Typography
              variant="body2"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onClick={() => {
                setCardIdSelected(nodes.card_id);
                setOpenModal(true);
              }}
            >
              <Typography
                style={{ display: '-webkit-inline-box', color: '#007dc6' }}
              >
                {`${nodes.key_card}`}
                <Typography
                  noWrap={false}
                  style={{
                    color: 'black',
                    marginLeft: '10px',
                    maxWidth: '80%',
                  }}
                >
                  {`${nodes.area.name} ${
                    nodes.description.length > 0 ? '-' : ''
                  } ${nodes.description}`}
                </Typography>
              </Typography>
              <Typography style={{ marginRight: '10px' }}>
                {`${nodes.area.percentage}%`}
              </Typography>
            </Typography>
          </Tooltip>
        </>
      }
    >
      {Array.isArray(nodes.hierarchy)
        ? nodes.hierarchy.map(node => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>
      {family && collectionKeyCards.length > 0 && (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={collectionKeyCards}
        >
          {renderTree(family)}
        </TreeView>
      )}

      {openModal && family && (
        <Card
          cardId={cardIdSelected}
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export { Hierarchy };
