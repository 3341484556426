/* eslint-disable @typescript-eslint/no-unused-vars */
import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: '650px',
    maxHeight: '100%',
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  boxTable: {
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 15,
  },
  columnActions: {
    width: '60px',
  },
  swalAlert: {
    zIndex: 99999,
  },
});
