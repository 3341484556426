import React from 'react';

import { Box } from '@material-ui/core';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <div>
      <Box>{children}</Box>
    </div>
  );
};

export default AuthLayout;
