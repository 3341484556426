import React from 'react';

import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  fillRuleTypeFields,
  instantTriggerTypeFields,
  scheduledTriggerTypeFields,
} from 'src/utils/conditionTypes';
import { styles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface ITriggerProps {
  handleChange: (
    field: string,
    value: string,
    index: number,
    itemIndex: number,
    customFieldId?: string,
  ) => void;
  groupIndex: number;
  itemIndex: number;
  itemId: string;
  isScheduledTrigger?: boolean;
  isInstantTrigger?: boolean;
}

export const OptionType: React.FC<ITriggerProps> = ({
  handleChange,
  groupIndex,
  itemIndex,
  itemId,
  isScheduledTrigger = false,
  isInstantTrigger = false,
}) => {
  const classes = useStyles();
  const typeFields =
    !isScheduledTrigger && !isInstantTrigger
      ? fillRuleTypeFields
      : isScheduledTrigger
      ? scheduledTriggerTypeFields
      : isInstantTrigger
      ? instantTriggerTypeFields
      : [];

  return (
    <Autocomplete
      id="type"
      getOptionLabel={type => type.label}
      getOptionSelected={(type, value) => type.id === value.id}
      options={typeFields}
      onChange={(e, value) =>
        handleChange('type', value.id, groupIndex, itemIndex)
      }
      disableClearable
      value={
        typeFields[typeFields.findIndex(field => field.id === itemId)] || null
      }
      loadingText="Carregando"
      size="small"
      className={classes.conditionField}
      renderInput={rest => (
        <TextField
          {...rest}
          id="type"
          label="Tipo"
          margin="dense"
          name="type"
          variant="outlined"
          size="small"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          InputProps={{
            ...rest.InputProps,
            style: {
              fontSize: '14px',
              width: '180px',
            },
          }}
        />
      )}
    />
  );
};
