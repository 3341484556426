/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState } from 'react';

import Select, { OptionType } from 'src/components/Select';
import { Separator } from 'src/components/ui/separator';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Checkbox } from 'src/components/ui/checkbox';
import { deepClone } from 'src/utils/deepCloneObject';
import { toast } from 'react-toastify';
import { toastLoadingUpdate } from 'src/utils/toastifyUtils';
import api from 'src/services/api';
import { IAreaAll } from 'src/services/areaApi';
import { IUserAll } from 'src/services/userApi';
import {
  OptionWithArea,
  OptionWithoutArea,
  PendingReassignments,
  UpdateUserOptions,
} from '../Account.i';

export interface HookProps {
  handleCloseResponsibleUserDialog: () => void;
  responsibleUsers: IUserAll[];
  users: IUserAll[];
  areas: IAreaAll[];
  pendingReassignments: PendingReassignments;
  account_id: string;
}

interface NewUserSelect {
  removedUser_id: string;
  isCheckboxChecked: boolean;
  newResponsibleUser_id?: string;
  area_id?: string;
}

interface HookReturn {
  reassignments: PendingReassignments;
  renderRemovedUsers: (type: 'withCard' | 'withoutCard') => JSX.Element[];
  handleSubmitReassignments: () => void;
  isSubmitting: boolean;
}

export function useReponsibleHook({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCloseResponsibleUserDialog,
  responsibleUsers,
  users,
  areas,
  pendingReassignments,
  account_id,
}: HookProps): HookReturn {
  const [reassignments, setReassignments] = useState<PendingReassignments>(
    deepClone(pendingReassignments),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const remainingResponsibleUsers: IUserAll[] = useMemo(() => {
    const newResponsibleUsers = responsibleUsers.map(responsibleUser => {
      const user = users.find(user => user.id === responsibleUser.id);

      if (user) return user;
      return responsibleUser;
    });

    return newResponsibleUsers;
  }, [responsibleUsers, users]);

  const handleUpdateUserOptions = ({
    removedUser_id,
    checked,
    area_id,
    newResponsibleUser_id,
  }: UpdateUserOptions) => {
    setReassignments(prevState => {
      const key = area_id ? 'withCard' : 'withoutCard';

      const updatedOptions = prevState[key].map(option => {
        const isChecked = checked !== undefined ? checked : option.setNewUser;
        const newUserId =
          newResponsibleUser_id !== undefined && isChecked
            ? newResponsibleUser_id
            : undefined;
        const isMatchingOption = area_id
          ? option.removedUser_id === removedUser_id &&
            'area_id' in option &&
            option.area_id === area_id
          : option.removedUser_id === removedUser_id;

        if (isMatchingOption) {
          return {
            ...option,
            setNewUser: isChecked,
            newResponsibleUser_id: newUserId,
          };
        }

        return option;
      });

      return {
        ...prevState,
        [key]: updatedOptions,
      };
    });
  };

  const handleSubmitReassignments = useCallback(() => {
    setIsSubmitting(true);
    const sendingReassignments = toast.loading(`Salvando regra`, {
      autoClose: 5000,
    });

    let checkedWithoutNewUser = false;

    const formattedReassignments = {
      withCard: reassignments.withCard
        .filter(option => option.setNewUser)
        .map(option => {
          checkedWithoutNewUser = !option?.newResponsibleUser_id
            ? true
            : checkedWithoutNewUser;
          return {
            removedUser_id: option.removedUser_id,
            newResponsibleUser_id: option.newResponsibleUser_id,
            area_id: option.area_id,
          };
        }),
      withoutCard: reassignments.withoutCard
        .filter(option => option.setNewUser)
        .map(option => {
          checkedWithoutNewUser = !option?.newResponsibleUser_id
            ? true
            : checkedWithoutNewUser;
          return {
            removedUser_id: option.removedUser_id,
            newResponsibleUser_id: option.newResponsibleUser_id,
          };
        }),
    };

    if (checkedWithoutNewUser) {
      toastLoadingUpdate(
        sendingReassignments,
        `Selecione um novo responsável para cada opçõe marcada`,
        'error',
        5000,
      );
      setIsSubmitting(false);
      return undefined;
    }

    return api
      .put(`accounts/${account_id}/reassignments`, {
        reassignments: formattedReassignments,
      })
      .then(() => {
        toastLoadingUpdate(
          sendingReassignments,
          `Troca de responsável concluída com sucesso`,
          'success',
          2000,
          handleCloseResponsibleUserDialog,
        );
      })
      .catch(err => {
        toastLoadingUpdate(
          sendingReassignments,
          `Ocorreu um erro. ${err.response?.data?.message}`,
          'error',
          5000,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [reassignments, handleCloseResponsibleUserDialog, account_id]);

  const filterAvailableUsers = useCallback(
    (
      oldResponsible_id: string,
      newReponsibleUser_id?: string,
      area_id?: string,
    ) => {
      const areaResponsibleUsers = remainingResponsibleUsers.filter(
        responsibleUser =>
          responsibleUser?.area?.id === area_id ||
          responsibleUser.is_supervisor,
      );
      const newUserOptions =
        areaResponsibleUsers?.length > 0
          ? areaResponsibleUsers
          : users.filter(
              user => user.id !== oldResponsible_id && user.is_supervisor,
            );
      const newUser = newUserOptions.find(
        newUser => newUser.id === newReponsibleUser_id,
      );

      return {
        newUserOptions,
        newUser,
      };
    },
    [users, remainingResponsibleUsers],
  );

  const renderNewResponsibleUser = ({
    removedUser_id,
    isCheckboxChecked,
    newResponsibleUser_id,
    area_id,
  }: NewUserSelect) => {
    const { newUserOptions, newUser } = filterAvailableUsers(
      removedUser_id,
      newResponsibleUser_id,
      area_id,
    );

    const handleSelectNewResponsibleUser = (selectedItems: OptionType[]) => {
      const selectedUser = selectedItems[0] || [];

      handleUpdateUserOptions({
        removedUser_id,
        area_id,
        newResponsibleUser_id: selectedUser.id,
      });
    };

    return (
      <div className="w-fit" key={removedUser_id + (area_id || '')}>
        <Select
          disabled={!isCheckboxChecked || newUserOptions.length === 0}
          items={newUserOptions.map(user => ({
            id: user.id,
            name: user.name,
          }))}
          btnClassName="w-44 max-sm:w-24 max-sm:justify-start p-1 max-sm:truncate"
          selectedItems={
            newUser
              ? [
                  {
                    id: newUser.id,
                    name: newUser.name,
                  },
                ]
              : []
          }
          setSelectedItems={handleSelectNewResponsibleUser}
          placeholder={
            newUserOptions.length === 0
              ? 'Sem opções disponíveis'
              : 'Novo responsável'
          }
          maxItems={1}
          closeOnSelect
        />
      </div>
    );
  };

  const renderRemovedUsers = useCallback(
    (type: 'withCard' | 'withoutCard') => {
      return reassignments[type].map(
        (option: OptionWithArea | OptionWithoutArea) => {
          const areaId = 'area_id' in option ? option.area_id : undefined;
          const currentUserName =
            users[users.findIndex(user => user.id === option.removedUser_id)]
              ?.name || '';
          const currentAreaName =
            'area_id' in option
              ? areas[areas.findIndex(area => area.id === option.area_id)]
                  ?.name || 'Área não encontrada'
              : 'Sem card';
          const { newUserOptions } = filterAvailableUsers(
            option.removedUser_id,
            option?.newResponsibleUser_id,
            areaId,
          );

          return (
            <li
              key={option.removedUser_id + { areaId }}
              className="flex flex-row items-center justify-between space-x-2 mb-1"
            >
              <TooltipWrapper value={currentUserName}>
                <span className="text-left truncate min-w-14 w-28 flex-1">
                  {currentUserName}
                </span>
              </TooltipWrapper>
              <Separator orientation="vertical" className="h-6 w-[2px]" />
              <TooltipWrapper value={currentAreaName}>
                <span className="text-left truncate min-w-14 w-28 flex-1">
                  {currentAreaName}
                </span>
              </TooltipWrapper>
              <Separator orientation="vertical" className="h-6 w-[2px]" />
              <div className="flex items-center space-x-2 min-w-fit w-14 flex-1 justify-center">
                <Checkbox
                  id="terms"
                  disabled={newUserOptions?.length === 0}
                  checked={option.setNewUser}
                  onCheckedChange={checked => {
                    handleUpdateUserOptions({
                      removedUser_id: option.removedUser_id,
                      checked: checked as boolean,
                      area_id: areaId,
                    });
                  }}
                />
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Alterar
                </label>
              </div>
              <Separator orientation="vertical" className="h-6 w-[2px]" />
              {renderNewResponsibleUser({
                removedUser_id: option.removedUser_id,
                area_id: areaId,
                newResponsibleUser_id: option?.newResponsibleUser_id,
                isCheckboxChecked: option?.setNewUser || false,
              })}
            </li>
          );
        },
      );
    },
    [
      areas,
      users,
      renderNewResponsibleUser,
      reassignments,
      handleUpdateUserOptions,
    ],
  );

  return {
    reassignments,
    renderRemovedUsers,
    handleSubmitReassignments,
    isSubmitting,
  } as HookReturn;
}
