import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';
import logoBackground from '../../assets/bg-login.jpg';
import logoBackground2 from '../../assets/bg-login2.jpg';

export const styles = (theme: Theme): StyleRules => ({
  root: {
    display: 'flex',
    marginTop: '-7%',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '60.3vh',
    backgroundColor: '#333',
    backgroundImage: `url("${logoBackground2}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('xs')]: {
      backgroundImage: `url("${logoBackground}")`,
      width: '99.9%',
      height: '55rem',
    },
    [theme.breakpoints.up('sm')]: {
      width: '99.9%',
      height: '115.3vh',
    },
  },
  form: {
    marginTop: '42px',
    marginLeft: '40px',
    margin: '20px',
    padding: theme.spacing(4),
    maxWidth: '500px',
    [theme.breakpoints.up('xs')]: {
      marginTop: '34px',
      marginLeft: '43px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '124px',
      marginLeft: '100px',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#000000',
  },
  rodaTitle: {
    fontWeight: 500,
    // marginTop: '-15px',
    // marginLeft: '320px',
    fontSize: '0.8rem',
    color: COLORS.BLUE,
    marginTop: theme.spacing(2),
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  submit: {
    background: COLORS.BLUE,
    marginTop: theme.spacing(2),
    width: '30%',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  logo: {
    width: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
