import React from 'react';
import { Column } from '@tanstack/react-table';

import { cn } from 'src/lib/utils';
import {
  ArrowDownIcon,
  ArrowDownUpIcon,
  ArrowUpIcon,
  EyeOffIcon,
} from 'lucide-react';
import { Button } from './button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './dropdown-menu';

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  disabledSortOption?: boolean;
  disableHideOption?: boolean;
  localStorageKey?: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
  disableHideOption = false,
  disabledSortOption = false,
  localStorageKey,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  const updateLocalStorage = () => {
    const columnVisibility = localStorage.getItem(localStorageKey || '');
    const visibilityMap = columnVisibility ? JSON.parse(columnVisibility) : {};
    visibilityMap[title] = false;
    localStorage.setItem(localStorageKey || '', JSON.stringify(visibilityMap));
  };

  const handleToggleVisibility = () => {
    const newVisibility = false;
    column.toggleVisibility(newVisibility);
    if (localStorageKey) updateLocalStorage();
  };

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-accent m-0 p-0 px-1"
          >
            <span className={`${title && 'mr-1'}`}>{title}</span>
            {!disabledSortOption &&
              (column.getIsSorted() === 'desc' ? (
                <ArrowDownIcon className="h-4 w-4" />
              ) : column.getIsSorted() === 'asc' ? (
                <ArrowUpIcon className="h-4 w-4" />
              ) : (
                <ArrowDownUpIcon className="h-4 w-4" />
              ))}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {!disabledSortOption && (
            <>
              <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
                <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                Asc
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
                <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                Desc
              </DropdownMenuItem>
            </>
          )}
          {!disableHideOption && (
            <>
              {!disabledSortOption && <DropdownMenuSeparator />}
              <DropdownMenuItem onClick={handleToggleVisibility}>
                <EyeOffIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
                Esconder
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
