import React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { ICardFilterRepository } from './Search.i';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface ResultsTableProps {
  cards: ICardFilterRepository[];
  setOpenModal: (value: boolean) => void;
  setCardIdSelected: (cardId: string) => void;
  setTitleCard: (titleCard: string) => void;
  setTitleRightCard: (titleRightCard: string) => void;
  setTitleLeftCard: (titleLeftCard: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ResultsTable = ({
  cards,
  setOpenModal,
  setTitleRightCard,
  setTitleLeftCard,
  setTitleCard,
  setCardIdSelected,
}: ResultsTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="lista de áreas">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>Chave</TableCell>
            <TableCell className={classes.tableCell}>
              Unidade de Negócio
            </TableCell>
            <TableCell className={classes.tableCell}>Área</TableCell>
            <TableCell className={classes.tableCell}>Conta</TableCell>
            <TableCell className={classes.tableCell}>Contato</TableCell>
            <TableCell className={classes.tableCell}>Fase</TableCell>
            <TableCell className={classes.tableCell}>Tarefa</TableCell>
            <TableCell className={classes.tableCell}>Usuário</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {cards ? (
            cards.map(card => (
              <TableRow
                key={card.id}
                onClick={() => {
                  setOpenModal(true);
                  setTitleRightCard(card.key);
                  setCardIdSelected(card.id);
                  setTitleCard(`${card.phase}`);
                  setTitleLeftCard(
                    `${card.account} ${
                      card.description ? ` - ${card.description}` : ''
                    }
                `,
                  );
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  {card?.isPinned || card?.isHighlighted ? (
                    <Box
                      display="flex"
                      flexWrap="nowrap"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Box className={classes.cardFlags}>
                        {card?.isPinned && (
                          <Box
                            height="50%"
                            style={{
                              backgroundColor: '#53a546',
                              borderRadius: '2px',
                            }}
                          />
                        )}
                        {card?.isHighlighted && (
                          <Box
                            height="50%"
                            style={{
                              backgroundColor: '#FFE000',
                              borderRadius: '2px',
                            }}
                          />
                        )}
                      </Box>
                      <Box ml="2px">{card.key}</Box>
                    </Box>
                  ) : (
                    <>{card.key}</>
                  )}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <Tooltip arrow title={card.business}>
                    <Typography className={classes.tableCell} noWrap>
                      {card.business}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  {card.area}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <Tooltip arrow title={card.account}>
                    <Typography className={classes.tableCell} noWrap>
                      {card.account}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <Tooltip arrow title={card.contact}>
                    <Typography className={classes.tableCell} noWrap>
                      {card.contact}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <Tooltip arrow title={card.phase}>
                    <Typography className={classes.tableCell} noWrap>
                      {card.phase}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <Tooltip arrow title={card.task}>
                    <Typography className={classes.tableCell} noWrap>
                      {card.task}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <Tooltip arrow title={card.user}>
                    <Typography className={classes.tableCell} noWrap>
                      {card.user}
                    </Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Box style={{ color: 'green' }}>
              <p>nada</p>
            </Box>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
