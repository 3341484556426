import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: 650,
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: '#007dc6',
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    maxWidth: 650,
  },
  columnActions: {
    width: '60px',
  },

  divider: {
    marginTop: '20px',
  },
  title: { fontSize: '30px', fontWeight: 'bold', marginTop: '30px' },
});
