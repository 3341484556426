import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  templateRoot: {
    minWidth: '300px',
    maxWidth: '1080px',
    minHeight: '100%',
    height: 'fit-content',
    fontSize: '14px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '24px',
    color: COLORS.BLACK,
    padding: 0,
    borderRadius: '5px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `
      "header header header"
      "description description form"
      "description description form"
      "footer footer footer"
    `,
    '@media (max-width: 760px)': {
      overflow: 'auto',
      gridTemplateColumns: '100%',
      gridTemplateAreas: `
        "header"
        "description"
        "form"
        "footer"`,
    },
    /* '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.primary,
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.primary,
      fontWeight: 500,
      borderRadius: '5px',
      '& fieldset': {
        borderColor: theme.palette.primary,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff',
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: theme.palette.primary,
      },
    }, */
  },
  templateHeader: {
    boxSizing: 'border-box',
    gridArea: 'header',
    padding: '10px',
    display: 'flex',
    maxHeight: '150px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 760px)': {
      height: '100%',
      maxHeight: '100%',
      marginBottom: theme.spacing(1),
      flexDirection: 'column-reverse',
    },
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 700,
    width: 'fit-content',
    maxWidth: '580px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 760px)': {
      marginBottom: theme.spacing(2),
      height: '100%',
      maxHeight: '100%',
    },
  },
  imgTitle: {
    height: 'auto',
    width: 'auto',
    maxWidth: '150px',
    maxHeight: '150px',
    objectFit: 'scale-down',
    borderRadius: '5px',
    '@media (max-width: 760px)': {
      marginBottom: theme.spacing(2),
      maxWidth: '300px',
      maxHeight: '300px',
    },
  },
  templateDescription: {
    boxSizing: 'border-box',
    gridArea: 'description',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'space-evenly',
    // overflowX: 'visible',
    height: '100%',
    '@media (max-width: 760px)': {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  descriptionTextEditor: {
    width: '100%',
    height: '100%',
    // maxWidth: '580px',
    backgroundColor: 'inherit',
    overflow: 'visible',
    boxSizing: 'border-box',
  },
  descriptionTextDefault: {
    width: '100%',
    maxWidth: '580px',
    fontSize: '18px',
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    boxSizing: 'border-box',
    // overflowWrap: 'break-word',
  },
  formFields: {
    gridArea: 'form',
    minWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '800px',
    boxSizing: 'border-box',
    overflowY: 'auto',
    padding: '10px',
    borderRadius: '5px',
    '@media (max-width: 760px)': {
      maxHeight: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  templateFooter: {
    justifySelf: 'flex-end',
    gridArea: 'footer',
    width: '100%',
    height: 'auto',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
