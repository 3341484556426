import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

export const CopyrightBrasao: React.FC = () => {
  return (
    <Typography
      variant="body2"
      align="center"
      style={{ marginTop: 8, marginBottom: 4 }}
    >
      Copyright ©
      <Link
        color="inherit"
        target="_blank"
        style={{ marginLeft: '2px', marginRight: '2px' }}
        to="https://brasaosistemas.com.br/"
      >
        Brasão Sistemas BPM
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
};
