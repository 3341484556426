/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Button from '../../components/Button';

import api from '../../services/api';
import { InputPhoneMask } from '../../components/InputPhoneMask';

import {
  IFormData,
  IContactParams,
  IContactProps,
  IContact,
} from './Contact.i';
import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const Contact: React.FC<IContactProps> = ({
  account,
  contactId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeModal = (_value: any) => null,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const goBack = () => {
    if (!account_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  const params = useParams<IContactParams>();
  const { contact_id, account_id } = params;
  const [contact, setContact] = useState<IContact>();

  const initialValues: IFormData = {
    name: contact ? contact.name : '',
    email: contact && contact?.email ? contact.email : '',
    phone: contact ? contact.phone : '',
    birth_date: contact ? contact.birth_date : null,
    contact_default: contact ? contact.contact_default : false,
  };

  useEffect(() => {
    if (contact_id || contactId) {
      const id = contact_id || contactId;
      api
        .get(`/contacts/${id}`)
        .then(response => {
          setContact(response.data);
        })
        .catch((error: AxiosError) => {
          toast.error(`Falha ao buscar dados do contato. ${error?.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    }
  }, [contact_id, contactId]);

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[ 0-9a-zA-ZÀ-ú\b\-.']+$/g,
        'Apenas letras e números são aceitos.',
      )
      .min(4, 'O nome deve conter acima de 4 caracteres.')
      .max(60, 'O nome ultrapassou o limite de caracteres permitido.')
      .required('Nome é obrigatório.'),
    email: Yup.string().nullable().email().optional(),
    // .typeError('Email é obrigatório'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (contact_id || contactId) {
          const id = contact_id || contactId;
          await api.put(`/contacts/${id}`, {
            name: values.name,
            email:
              values.email && values.email.length > 0 ? values.email : null,
            phone: values.phone,
            birth_date: values.birth_date,
            contact_default: values.contact_default,
          });

          toast.success('Contato editado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } else {
          await api.post('/contacts', {
            name: values.name,
            email:
              values.email && values.email.length > 0
                ? values.email
                : undefined,
            phone: values.phone,
            birth_date: values.birth_date,
            contact_default: values.contact_default,
            account_id: account_id || account,
          });

          toast.success('Contato salvo com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });

          setTimeout(() => {
            closeModal(true);
          }, 1000);
        }
        setSubmitting(true);
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {contact_id ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar Contato
          </Typography>
        ) : (
          <div />
        )}
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              id="name"
              label="Nome"
              name="name"
              margin="normal"
              autoFocus
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.name || ''}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              margin="normal"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.email || ''}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              id="phone"
              label="Fone"
              name="phone"
              margin="normal"
              type="tel"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.phone || ''}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                inputComponent: InputPhoneMask as any,
              }}
            />

            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="birth_date"
                name="birth_date"
                label="Data de Nascimento"
                className={classes.date}
                format="dd/MM/yyyy"
                invalidDateMessage="Data informada incorreta"
                value={formik.values.birth_date}
                InputLabelProps={{
                  shrink:
                    (formik.values.birth_date !== null &&
                      formik.values.birth_date.toString().length > 0) ||
                    undefined,
                }}
                onChange={value =>
                  formik.setFieldValue('birth_date', value, true)
                }
                error={
                  formik.touched.birth_date && Boolean(formik.errors.birth_date)
                }
                helperText={
                  formik.touched.birth_date && formik.errors.birth_date
                }
                fullWidth
                disableFuture
                KeyboardButtonProps={{
                  'aria-label': 'Data de aniversário',
                }}
              />
            </MuiPickersUtilsProvider>

            <Box display="flex" alignItems="center" marginTop="10px">
              <FormControlLabel
                control={
                  <Checkbox
                    name="contact_default"
                    checked={formik.values.contact_default}
                    onChange={formik.handleChange}
                  />
                }
                label="Contato Padrão"
              />
            </Box>
            <ButtonMaterialUI
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default Contact;
