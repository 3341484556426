/* eslint-disable react/require-default-props */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { styles } from './styles';
import { ModalFileList } from './ModalFileList';
import { SearchMultipleFilesIcon } from '../../assets/SearchMultipleFilesIcon';
import { FileListIcon } from '../../assets/FileListIcon';
import { useAuth } from '../../context/AuthContext';

const useStyles = makeStyles(() => createStyles(styles()));

interface IEmailFileFieldProps {
  id: string;
  name: string;
  allowsMultiple: boolean;
  setNewFiles: (newFiles: File[]) => void;
  actionName: string;
  attachments?: {
    name: string;
    size: number;
    url: string;
  }[];
  filesNamesToSubmit: string[];
  handleDeleteActionAttachments: () => void;
}

interface IValueType {
  name: string;
  url: string;
  size: number;
}

export const allowedMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformatsofficedocument.wordprocessingml.document',
  'application/pdf',
  'application/x-zip-compressed',
  'application/zip',
  'application/ogg',
  'application/xml',
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/svg+xml',
  'text/csv',
  'text/plain',
  'text/xml',
  'audio/mp4',
  'audio/mpeg',
  'audio/x-wav',
];

export const EmailFileField = ({
  id,
  name,
  allowsMultiple,
  setNewFiles,
  actionName,
  attachments = [],
  filesNamesToSubmit,
  handleDeleteActionAttachments,
}: IEmailFileFieldProps): JSX.Element => {
  const classes = useStyles();
  const { company } = useAuth();

  const MAX_FILE_SIZE = (company.fileSize ?? 50) * 1000;
  const MAX_FILES_PER_BUNDLE = 3;

  const [openModalFileList, setOpenModalFileList] = useState<boolean>(false);

  const [filesData, setFilesData] = useState<IValueType[]>([]);
  const [filesNames, setFilesName] = useState<string[] | string>('');

  const handleCloseModalFilesList = () => {
    setOpenModalFileList(false);
  };

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setNewFiles([]);
      return;
    }

    let invalidFiles = false;

    Object.values(e.target.files).forEach((f: File) => {
      const notValidType = !allowedMimeTypes.includes(f.type);
      const fileSizeKiloBytes = f.size / 1000;
      const overLimitSize = fileSizeKiloBytes > MAX_FILE_SIZE;

      if (notValidType || overLimitSize) {
        const message =
          notValidType && overLimitSize
            ? `Extensão inválida e tamanho além do permitido (${company.fileSize}Mb).`
            : notValidType && !overLimitSize
            ? `Extensão do tipo ${f.type} não é permitido.`
            : !notValidType && overLimitSize
            ? `Tamanho do arquivo além do permitido. (${company.fileSize}Mb).`
            : 'Arquivo inválido.';

        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });

        invalidFiles = true;
        setNewFiles([]);
      }
    });

    if (!invalidFiles && e.target.files && e.target.files.length > 0) {
      const formattedTargetFiles: IValueType[] = [];
      const tempTargetFiles = Object.values(e.target.files).map((f: File) => {
        formattedTargetFiles.push({ name: f.name, url: '', size: f.size });

        return f;
      });

      if (
        tempTargetFiles &&
        tempTargetFiles.length > Number(MAX_FILES_PER_BUNDLE)
      ) {
        toast.error(
          `Selecione um número máximo de até ${MAX_FILES_PER_BUNDLE} arquivos.`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
        return;
      }

      if (
        tempTargetFiles &&
        tempTargetFiles.length > 0 &&
        tempTargetFiles.length <= Number(MAX_FILES_PER_BUNDLE)
      ) {
        /* if (attachments && attachments.length > 0) {
          handleDeleteActionAttachments();
        } */
        setFilesData(formattedTargetFiles);
        setNewFiles(tempTargetFiles);
      }
    } else {
      setNewFiles([]);
      setFilesData([]);
    }
  };

  const handleDeleteFiles = () => {
    setFilesData([]);
    handleDeleteActionAttachments();
  };

  useEffect(() => {
    if (filesData && filesData.length > 0) {
      const tempValues = filesData;
      const formattedValues: string[] = Object.values(tempValues).map(
        (file: IValueType) => file.name,
      );

      setFilesName(formattedValues);
    } else if (filesNamesToSubmit) {
      setFilesName(filesNamesToSubmit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesData, filesNamesToSubmit]);

  return (
    <Box className={classes.documentType}>
      <TextField
        contentEditable={false}
        label={name}
        autoComplete="off"
        value={filesNames.toString()}
        multiline
        minRows={1}
        maxRows={5}
        InputLabelProps={{
          shrink: filesNames.length > 0,
        }}
        style={{
          flex: '1',
          padding: 0,
          margin: 0,
        }}
        fullWidth
        margin="dense"
        InputProps={{
          disableUnderline: true,
          onKeyDown: (e: { preventDefault: () => void }) => {
            e.preventDefault();
            return false;
          },
        }}
      />
      <Box mt={1} mb={0} pb={0}>
        <Tooltip title={`Seleção de arquivo${allowsMultiple ? 's' : ''}`}>
          <IconButton
            color="inherit"
            component="label"
            style={{
              padding: '0',
              marginBottom: 3,
              marginLeft: '1px',
              marginRight: '3px',
            }}
            disabled={!actionName || actionName.length === 0}
          >
            <input
              type="file"
              id={id}
              multiple={allowsMultiple}
              onChange={handleOnFileChange}
              hidden
              accept={allowedMimeTypes.toString()}
            />
            <SearchMultipleFilesIcon
              htmlColor={
                !actionName || actionName.length === 0 ? '#dfdfdf' : '#757575'
              }
            />
          </IconButton>
        </Tooltip>

        <IconButton
          aria-label="Arquivos armazenados"
          size="small"
          onClick={() => setOpenModalFileList(true)}
          disabled={attachments.length === 0}
        >
          <Tooltip title="Arquivos armazenados">
            <div style={{ margin: 0, padding: 0 }}>
              <FileListIcon
                htmlColor={attachments.length === 0 ? '#dfdfdf' : '#757575'}
              />
            </div>
          </Tooltip>
        </IconButton>

        <IconButton
          aria-label="Remover anexos"
          size="small"
          onClick={() => handleDeleteFiles()}
          disabled={filesNames.length === 0}
        >
          <Tooltip title="Remover anexos">
            <div style={{ margin: 0, padding: 0 }}>
              <ClearRoundedIcon
                htmlColor={filesNames.length === 0 ? '#dfdfdf' : undefined}
              />
            </div>
          </Tooltip>
        </IconButton>
      </Box>
      {openModalFileList && (
        <ModalFileList
          title={`${name}`}
          open={openModalFileList}
          closeModal={handleCloseModalFilesList}
          id={id}
          allowedFileTypes={allowedMimeTypes.toString()}
          allowsMultipleFiles={allowsMultiple}
          filesData={filesData}
          setFilesData={setFilesData}
          handleOnFileChange={handleOnFileChange}
          attachments={attachments}
        />
      )}
    </Box>
  );
};
