import api from './api';

export interface IArea {
  id: string;
  name: string;
  phases: {
    id: string;
    name: string;
    area: {
      id: string;
    };
  }[];
}

export interface IAreaAll {
  id: string;
  name: string;
  description: boolean;
}

export const areaApi = {
  getAreas: () => api.get<IArea[]>(`/areas`),
  getAreasAll: () => api.get<IAreaAll[]>(`/areas/all`),
};
