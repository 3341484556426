import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { InfoIcon } from 'lucide-react';
import React from 'react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'src/components/ui/hover-card';
import { COLORS } from 'src/styles/colors';
import { ColumnData } from '../types';
import { ColumnsFilter } from '../components/columnsFilter';
import { CutAndAddEllipsis } from './funnel-graph';

export interface OptionType {
  id: string;
  name: string;
}

export interface ColumnWidgetProps {
  readonly userOptions: { id: string; name: string }[];
  readonly fieldOptions: OptionType[];
  readonly data: ColumnData | undefined;
}

const calculatePhaseData = (data: ColumnData): BarDatum[] => {
  return Object.entries(data.phasesData).map(([, values]) => {
    const fieldValues = Object.entries(values).map(([key, value]) => {
      if (key === 'name') return {};
      const fieldName = data.fields.find(field => field.id === key)?.name;
      const shortFieldName = CutAndAddEllipsis(fieldName || '', 10);
      if (!shortFieldName) return {};
      return {
        [shortFieldName]: value,
      };
    });
    const localData = Object.assign({}, ...fieldValues);
    return {
      phase: values.name,
      ...localData,
    };
  });
};

const ColumnWidget: React.FC<ColumnWidgetProps> = ({
  data,
  userOptions,
  fieldOptions,
}) => {
  return (
    <div className="h-full w-full">
      <div className="flex flex-row justify-between px-3 items-center">
        <div className="flex flex-row justify-between items-center gap-3">
          <p className="text-lg font-bold">Valores por fase</p>
          {data && (
            <HoverCard openDelay={500}>
              <HoverCardTrigger>
                <InfoIcon size={20} className="cursor-help" />
              </HoverCardTrigger>
              <HoverCardContent>
                <p className="font-bold">Total</p>
                {data.fields.length ? (
                  <ul>
                    {data.fields.map(field => (
                      <li key={field.id}>
                        {field.name}:{' '}
                        {data.total[field.id]
                          ? data.total[field.id].toLocaleString('pt-BR')
                          : '-'}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Nenhum campo selecionado</p>
                )}
              </HoverCardContent>
            </HoverCard>
          )}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <ColumnsFilter users={userOptions} fieldOptions={fieldOptions} />
        </div>
      </div>
      <div className="h-[300px] p-3 z-10">
        {data && data?.fields?.length > 0 ? (
          <ResponsiveBar
            data={calculatePhaseData(data)}
            keys={data.fields.map(field => CutAndAddEllipsis(field.name, 10))}
            indexBy="phase"
            margin={{ right: 20, bottom: 60, left: 50, top: 15 }}
            padding={0.3}
            enableLabel={false}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            colors={[COLORS.BLUE, COLORS.GREEN, COLORS.PRIMARYBLUE]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 20,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 20,
              truncateTickAt: 10,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -40,
              truncateTickAt: 10,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 2,
                itemWidth: 120,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                    },
                  },
                ],
              },
            ]}
            ariaLabel="Gráfico de valores por fase"
            barAriaLabel={e =>
              `${e.id}: ${e.formattedValue} na fase: ${e.indexValue}`
            }
          />
        ) : (
          <div>Sem dados</div>
        )}
      </div>
    </div>
  );
};

export default ColumnWidget;
