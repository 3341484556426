import { StyleRules } from '@material-ui/core';
import { COLORS } from 'src/styles/colors';

export const integratedStyles = (): StyleRules => ({
  outerDiv: {
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
    marginTop: '4px',
    justifyContent: 'space-between',
    borderBottom: '1.6px solid #999999',
  },
  tableOuterDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  typeIntegratedButton: {
    width: '2rem',
    height: '2.5rem',
  },
  integratedFieldButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  integratedDataDiv: {
    color: COLORS.BLACK,
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    height: '2.5rem',
  },
  tableIntegratedDataDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  integratedFieldPrimaryKey: {
    color: COLORS.BLUE,
    marginRight: '0.2rem',
  },
  tablePrimaryKey: {
    color: COLORS.BLUE,
    marginRight: '0.2rem',
    fontSize: '14px',
  },
  integratedFieldDisplayColumnName: {
    maxWidth: '12rem',
    width: 'auto',
    overflow: 'hidden',
  },
  tableDisplayColumnName: {
    maxWidth: '15rem',
    overflow: 'hidden',
    fontSize: '14px',
  },
  integratedFieldDummyTextField: {
    caretColor: 'transparent',
    width: '100%',
    '&& .MuiFormLabel-root': {
      color: '#959595',
    },
  },
  integratedLabel: {
    fontSize: '12px',
    fontWeight: 500,
    color: COLORS.BLUE,
  },
});
