import api from './api';

export interface IGetAccountCustomFieldApi {
  id: string;
  name: string;
  type: string;
  dateType: string;
  isRequired: boolean;
  isInactive: boolean;
  order: number;
  slug: string;
  isBigString: boolean;
  predefinedValues: Array<string>;
}

export const accountCustomFieldApi = {
  getCustomFields: () => api.get<IGetAccountCustomFieldApi[]>('/accountFields'),
};
