import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Container, Divider } from '@material-ui/core';
import PanelCards from '../../components/PanelCards';

import FilterCards from '../../components/FilterCards';
import { FilterProvider } from '../../context/FilterContext';

const Dashboard: React.FC = () => {
  return (
    <FilterProvider>
      <Box
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 999,
            width: '100%',
          }}
        >
          <FilterCards />
          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        </div>
        <PanelCards />
      </Box>
    </FilterProvider>
  );
};

export default Dashboard;
