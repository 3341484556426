/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  Button,
  Box,
  Container,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReCAPTCHA from 'react-google-recaptcha';
import { CopyrightBrasao } from 'src/components/CopyrightBrasao';
import logo from '../../assets/logo-brasao-bpm.svg';
import { SearchFileIcon } from '../../assets/SearchFileIcon';
import api from '../../services/api';
import { COLORS } from '../../styles/colors';

const allowedMimeTypes = ['application/pdf'];

const useStyles: any = makeStyles(() =>
  createStyles({
    button: {
      marginTop: '15px',
      color: '#FFF',
      background: 'linear-gradient(to right, #007dc6, #2f98fa)',
      margin: 0,
      '&:disabled': { background: '#dfdfdf' },
    },
    resultBox: {
      margin: '2rem auto',
      border: `1px solid ${COLORS.GREEN}`,
      borderRadius: '5px',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignContent: 'center',
      maxWidth: '600px',
      gap: '1rem',
    },
  }),
);

export const ValidateDocument: React.FC = () => {
  const classes = useStyles();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const captchaKey = (process.env.REACT_APP_CAPTCHA_SITE_KEY as string) || '';
  const [isCaptchaChecked, setIsCaptchaChecked] = useState<boolean>(false);
  const MAX_FILE_SIZE = 50 * 1000;
  const [files, setFiles] = useState<FileList | File[] | null>();
  const [filesNames, setFilesNames] = useState<string[] | string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [hash, setHash] = useState<string>('');

  function handleCaptchaChange(token: string | null) {
    setIsCaptchaChecked(!!token);
  }

  async function handleValidateDocument() {
    // eslint-disable-next-line no-restricted-globals, @typescript-eslint/no-non-null-assertion
    event!.preventDefault();
    let captchaToken: string | null = '';

    if (captchaRef.current) {
      captchaToken = captchaRef.current.getValue();
      captchaRef.current.reset();
      setIsCaptchaChecked(false);
    }

    const sendingFiles = toast.loading(`Verificando o arquivo.`);
    document.body.style.cursor = 'progress';

    const formData = new FormData();
    if (files) formData.append(`file`, files[0]);

    api
      .post(`/publicSignatures/authenticate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'g-recaptcha-response': captchaToken,
        },
      })
      .then(response => {
        if (response.data) {
          setIsValid(true);
          setHash(response?.data?.hash);
          toast.update(sendingFiles, {
            render: `Documento válido.`,
            type: 'success',
            isLoading: false,
            autoClose: 3000,
            theme: 'colored',
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const responseError = error.response?.data.message || error.message;

        toast.update(sendingFiles, {
          render: `Falha ao validar documento. ${responseError}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        document.body.style.cursor = 'auto';
      });
  }

  function handleOnFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      setIsValid(false);
      setFiles({} as FileList);
      return;
    }

    let invalidFiles = false;

    Object.values(e.target.files).forEach((f: File) => {
      const notValidType = !allowedMimeTypes.includes(f.type);
      const fileSizeKiloBytes = f.size / 1000;
      const overLimitSize = fileSizeKiloBytes > MAX_FILE_SIZE;

      if (notValidType || overLimitSize) {
        const message =
          notValidType && overLimitSize
            ? `Extensão inválida e tamanho além do permitido (50Mb).`
            : notValidType && !overLimitSize
            ? `Extensão do tipo ${f.type} não é permitido.`
            : !notValidType && overLimitSize
            ? `Tamanho do arquivo além do permitido. (50Mb).`
            : 'Arquivo inválido.';

        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });

        invalidFiles = true;
        setFiles({} as FileList);
      }
    });

    if (!invalidFiles && e.target.files && e.target.files.length > 0) {
      const tempFiles: FileList | File[] | null = e.target.files;

      if (tempFiles && tempFiles.length > 1) {
        toast.error(`Selecione no máximo um documento PDF.`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
        return;
      }

      if (tempFiles && tempFiles.length === 1) {
        setIsValid(false);
        setFiles(tempFiles);
      }
    }
  }

  useEffect(() => {
    if (files && files.length > 0) {
      const tempValues = files;
      const formattedValues: string[] = Object.values(tempValues).map(
        (file: File) => file.name,
      );

      setFilesNames(formattedValues);
    } else {
      setFilesNames([]);
    }
  }, [files]);

  return (
    <Container
      maxWidth={false}
      style={{
        backgroundColor: '#eaeeed',
        height: '100vh',
        width: '100%',
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        style={{
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
        }}
        component="main"
      >
        <img
          src={logo}
          alt="Logo da Empresa Brasão Sistemas"
          style={{
            width: '250px',
            marginTop: '50px',
          }}
        />
        <Typography variant="h5" style={{ marginTop: '15px' }}>
          Verificação de documento
        </Typography>
        <Box mt={5} mb={2} fontWeight={500}>
          <Typography variant="body1" align="justify">
            Selecione um documento PDF no qual o processo de coleta de
            assinatura tenha sido realizado eletronicamente através do Brasão
            BPM e clique em validar para conferir sua autenticidade.
          </Typography>
          <form noValidate onSubmit={handleValidateDocument}>
            <FormControl
              style={{ marginTop: '20px' }}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="pdfDocument">Documento</InputLabel>
              <OutlinedInput
                id="pdfDocument"
                labelWidth={85}
                contentEditable={false}
                autoComplete="off"
                required
                value={filesNames[0] || ''}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="inherit" component="label" edge="end">
                      <input
                        type="file"
                        multiple={false}
                        onChange={handleOnFileChange}
                        hidden
                        accept={allowedMimeTypes.toString()}
                      />
                      <SearchFileIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box
              mt="15px"
              display="flex"
              justifyContent="center"
              // style={{ transform: 'scale(1.10)', transformOrigin: '0 0' }}
            >
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={captchaKey}
                onChange={handleCaptchaChange}
                size="normal"
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!files || !files.length || !isCaptchaChecked || isValid}
            >
              Validar
            </Button>
          </form>
        </Box>
      </Box>
      {isValid && (
        <Box className={classes.resultBox}>
          <Typography variant="h6" style={{ margin: '0 auto' }}>
            Resultado da validação
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            style={{ gap: '1rem' }}
          >
            <VerifiedUserOutlinedIcon
              style={{ color: COLORS.GREEN }}
              fontSize="large"
            />
            <Typography variant="body1">
              Documento assinado eletronicamente através do Brasão BPM.
            </Typography>
          </Box>
          <Typography variant="body1" align="justify">
            Comparamos o documento enviado com as informações salvas em nosso
            banco de dados através de criptografia, e verificamos que este é um
            documento autêntico e com assinaturas coletadas através do Brasão
            BPM.
          </Typography>
          <Typography variant="body2" align="left">
            Hash do documento original (SHA256): <br />
            {hash}
          </Typography>
        </Box>
      )}
      <CopyrightBrasao />
    </Container>
  );
};
