/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  Button as ButtonMaterialUI,
  createStyles,
  Typography,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Button from '../../components/Button';

import logo from '../../assets/logo-brasao-bpm-branco.svg';

import api from '../../services/api';

import { styles } from './styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

interface IFormData {
  password: string;
  password_confirmation: string;
}

interface ParamTypes {
  token: string;
}

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
  }
  const query = useQuery();
  const token = query.get('token');

  const history = useHistory();

  const goBack = () => {
    const path = '/';
    history.push(path);
  };

  const initialValues: IFormData = {
    password: '',
    password_confirmation: '',
  };

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Senha deve conter no mínimo 6 caracteres')
      .required('Campo obrigatório'),
    password_confirmation: Yup.string()
      .required('Campo obrigatório')
      .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async values => {
      try {
        await api.post(`/password/reset`, {
          token,
          password: values.password,
          password_confirmation: values.password_confirmation,
        });
        toast.success('Senha Alterada com sucesso.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
        setTimeout(() => {
          history.push('/');
        }, 2300);
      } catch (error) {
        toast.error('Occorreu um erro ao atualizar a senha', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  return (
    <Grid container className={classes.root}>
      <Hidden mdDown>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-20px',
                marginTop: '149px',
              }}
            />
          </div>
          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Redefinir senha
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="password"
                label="Nova senha"
                name="password"
                margin="normal"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                id="password_confirmation"
                label="Confirmação da nova senha"
                name="password_confirmation"
                margin="normal"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.password_confirmation}
                error={
                  formik.touched.password_confirmation &&
                  Boolean(formik.errors.password_confirmation)
                }
                helperText={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
              />
              <ButtonMaterialUI
                className={classes.buttonSubmit}
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                color="inherit"
                onClick={goBack}
              >
                Voltar
              </ButtonMaterialUI>
              <Button
                className={classes.submit}
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                disabled={formik.isSubmitting}
              >
                Enviar
              </Button>
            </form>
          </Paper>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-98px',
                marginTop: '50px',
              }}
            />
          </div>

          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Redefinir senha
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="password"
                label="Nova Senha"
                name="password"
                margin="normal"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <TextField
                id="password_confirmation"
                label="Confirme Nova Senha"
                name="password_confirmation"
                margin="normal"
                type="password"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.password_confirmation}
                error={
                  formik.touched.password_confirmation &&
                  Boolean(formik.errors.password_confirmation)
                }
                helperText={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
              />
              <ButtonMaterialUI
                className={classes.buttonSubmit}
                disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                color="inherit"
                onClick={goBack}
              >
                Voltar
              </ButtonMaterialUI>
              <Button
                className={classes.submit}
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                disabled={formik.isSubmitting}
              >
                Enviar
              </Button>
            </form>
          </Paper>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default ResetPassword;
