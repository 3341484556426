/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import {
  Button as ButtonMaterialUI,
  createStyles,
  makeStyles,
  Theme,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
  TextField,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { useFormik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { ICustomField } from 'src/interface/ICardFields';
import { EmailFileField } from '../../../../components/EmailFileField';
import {
  IActionModalProps,
  IUser,
  IEmail,
  IAttachment,
  IFile,
} from '../../Trigger.i';
import { styles } from './styles';
import Button from '../../../../components/Button';
import { InputIntegerMask } from '../../../../components/InputIntegerMask';
import api from '../../../../services/api';
import { CustomRenderCheckboxOption } from '../../../../components/AutoCompleteCustomComponents/CustomRenderCheckboxOption';
import { formikValidation } from './formikConfig';
import { RichTextEditor } from '../../../../components/RichTextEditor/ckeditor';
import {
  IRichTextVariable,
  formatCfToRichTextVariables,
} from '../../../../utils/customFieldUtils';
import { actionTypes } from '../../utils/triggerUtil';
import { TaskAction } from './TaskAction';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const FieldRequiredMessage: React.FunctionComponent = () => (
  <Typography style={{ paddingLeft: '15px', fontSize: '12px' }} color="error">
    Campo obrigatório
  </Typography>
);

export const ActionModal: React.FC<any> = ({
  open,
  closeModal,
  title,
  titleLeft,
  actionIndex,
  action,
  triggerActions,
  setTriggerActions,
  areas,
  phases,
  currentAreaId,
  currentPhaseId,
  customFields,
  filesToSubmit,
  setFilesToSubmit,
  triggerType,
}: IActionModalProps) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState<string>();
  const [users, setUsers] = useState<IUser[]>([] as IUser[]);
  const [emails, setEmails] = useState<IEmail[]>([] as IEmail[]);
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [newEmailTo, setNewEmailTo] = useState('');
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [selectedCustomFields, setSelectedCustomFields] = useState<
    ICustomField[]
  >([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [filesNamesToSubmit, setFilesNamesToSubmit] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<IRichTextVariable[]>([]);
  const [ckInputsError, setCkInputsError] = useState<{
    subject: boolean;
    message: boolean;
  }>({ subject: false, message: false });

  useEffect(() => {
    if (action) {
      setSelectedType(action.type);
      if (action.attachments && action.attachments.length > 0) {
        const tempAttachments: IAttachment[] = [];
        const tempAttachmentsNames: string[] = [];

        action.attachments.forEach((attachment: IAttachment) => {
          tempAttachments.push(attachment);
        });
        setAttachments(tempAttachments);

        tempAttachments.forEach((attachment: IAttachment) => {
          const originalFilename = attachment.name;
          const filenameParts = attachment.name.split('__');
          const filename =
            filenameParts.length > 1 ? filenameParts[1] : originalFilename;

          tempAttachmentsNames.push(filename);
        });
        // setAttachmentsNames(tempAttachmentsNames);
        setFilesNamesToSubmit(tempAttachmentsNames);
      }

      if (action?.customFields && action.customFields.length > 0) {
        setSelectedCustomFields(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          customFields.filter(cf => action.customFields!.includes(cf.id)),
        );
      }
      setEmailTo(
        action?.emailTo?.map(e =>
          e === 'cardUser'
            ? 'Usuário do Card'
            : e === 'cardContact'
            ? 'Contato do Card'
            : e,
        ) || [],
      );
    }

    const filteredFields = customFields.filter(
      (field: { id: string; name: string; type: string; phaseOrder: number }) =>
        field?.type !== 'DOCUMENT' &&
        field?.type !== 'FILE' &&
        field?.phaseOrder >= 0,
    );
    const formattedSuggestions = formatCfToRichTextVariables(filteredFields);
    setSuggestions(formattedSuggestions);
  }, [action, customFields]);

  useEffect(() => {
    if (
      (selectedType === 'user' || selectedType === 'create_single_task') &&
      currentAreaId
    ) {
      api
        .get(`/areas/${currentAreaId}/users`)
        .then(response => setUsers(response.data));
    }
  }, [selectedType, currentAreaId]);

  useEffect(() => {
    if (selectedType === 'email') {
      setLoadingEmail(true);
      api
        .get(`/emails`)
        .then(response => {
          const tempEmails = [...response.data];
          tempEmails.unshift({
            id: 'cardUser',
            email: 'Usuário do Card',
          });
          setEmails(tempEmails);
        })
        .catch(err => {
          setEmails([
            {
              id: 'cardUser',
              email: 'Usuário do Card',
            },
          ]);
        });
      setLoadingEmail(false);
    }
  }, [selectedType]);

  const goBack = () => {
    closeModal(true);
  };

  const formik = useFormik(
    formikValidation(
      action,
      actionIndex,
      emailTo,
      selectedType,
      triggerActions.sort((a, b) => a.name.localeCompare(b.name)),
      setTriggerActions,
      closeModal,
      newFiles,
      filesToSubmit,
      setFilesToSubmit,
      setCkInputsError,
    ),
  );

  const handleDeleteActionAttachments = () => {
    formik.setFieldValue('attachments', []);
    setAttachments([]);
    setNewFiles([]);
    setFilesNamesToSubmit([]);
  };
  const handleSubjectChange = (subject: string) => {
    formik.setFieldValue('emailSubject', subject);
    setCkInputsError(oldValue => ({
      ...oldValue,
      subject: !subject.length,
    }));
  };
  const handleMessageChange = (message: string) => {
    formik.setFieldValue('message', message);
    setCkInputsError(oldValue => ({
      ...oldValue,
      message: !message.length,
    }));
  };

  useEffect(() => {
    if (filesToSubmit.length > 0) {
      const tempFilesToSubmit: IFile | undefined = filesToSubmit.find(
        af =>
          af.actionName === formik.values.name ||
          af.actionName === action?.name,
      );
      if (tempFilesToSubmit && tempFilesToSubmit.files.length > 0) {
        const tempFilesNames: string[] = [];
        tempFilesToSubmit.files.forEach((file: File) => {
          tempFilesNames.push(file.name);
        });
        setFilesNamesToSubmit(tempFilesNames);
      }
    }
  }, [action?.name, filesToSubmit, formik.values.name]);

  const userOptions =
    selectedType === 'create_single_task'
      ? [
          {
            id: 'userIsLinkedCardOwner',
            name: 'Usuário do Card',
          },
          ...users,
        ]
      : users;

  const isUserLinkedCardOwner = action?.taskData?.userIsLinkedCardOwner;

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll={undefined}
      onClose={closeModal}
      aria-labelledby={title}
      maxWidth="lg"
    >
      <DialogTitle>
        <Box marginTop="10px" justifyContent="space-between">
          <Box height="22px">
            <span>{title}</span>
          </Box>
          <Box>
            <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>
        <Container>
          <form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
            <Box>
              <TextField
                id="name"
                label="Descrição da Ação"
                name="name"
                autoComplete="off"
                margin="dense"
                autoFocus
                fullWidth
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.name || ''}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Autocomplete
                id="type"
                getOptionLabel={type => type.label}
                getOptionSelected={(type, value) => type.id === value.id}
                options={
                  triggerType === 'instant'
                    ? actionTypes
                    : actionTypes.filter(at => at.id !== 'alert')
                }
                onChange={(e, value) => {
                  formik.setFieldValue('type', value.id);
                  formik.setFieldValue('message', '');
                  setSelectedType(value.id);
                }}
                disableClearable
                value={
                  actionTypes[
                    actionTypes.findIndex(a => a.id === formik.values.type)
                  ] || null
                }
                loadingText="Carregando"
                fullWidth
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="type"
                    label="Tipo"
                    margin="dense"
                    name="type"
                    variant="outlined"
                    onKeyDown={e => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    InputProps={{
                      ...rest.InputProps,
                    }}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                )}
              />
              {selectedType && selectedType === 'suspend' && (
                <TextField
                  label="Tempo de suspensão (dias)"
                  name="suspendDuration"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  value={formik.values.suspendDuration || 0}
                  error={
                    formik.touched.suspendDuration &&
                    Boolean(formik.errors.suspendDuration)
                  }
                  helperText={
                    formik.touched.suspendDuration &&
                    formik.errors.suspendDuration
                  }
                  InputProps={{
                    inputComponent: InputIntegerMask as any,
                  }}
                />
              )}

              {selectedType &&
                (selectedType === 'area' || selectedType === 'subordinate') && (
                  <Autocomplete
                    id="area_id"
                    fullWidth
                    getOptionLabel={area => area.name || ''}
                    getOptionSelected={(area, value) => area.id === value.id}
                    options={areas.filter(area => area.id !== currentAreaId)}
                    value={
                      areas[
                        areas.findIndex(a => a.id === formik.values.area_id)
                      ] || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue('area_id', value?.id || '');
                    }}
                    loadingText="Carregando"
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="area_id"
                        label="Área destino"
                        margin="dense"
                        name="area_id"
                        variant="outlined"
                        value={formik.values.area_id}
                        fullWidth
                        error={
                          formik.touched.area_id &&
                          Boolean(formik.errors.area_id)
                        }
                        helperText={
                          formik.touched.area_id && formik.errors.area_id
                        }
                      />
                    )}
                  />
                )}

              {selectedType &&
                (selectedType === 'phase' ||
                  selectedType === 'create_single_task') && (
                  <Autocomplete
                    id="phase_id"
                    fullWidth
                    getOptionLabel={phase => phase.name || ''}
                    getOptionSelected={(phase, value) => phase.id === value.id}
                    options={
                      selectedType === 'phase'
                        ? phases.filter(phase => phase.id !== currentPhaseId)
                        : phases
                    }
                    value={
                      phases[
                        phases.findIndex(a => a.id === formik.values.phase_id)
                      ] || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue('phase_id', value?.id || '');
                    }}
                    loadingText="Carregando"
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="phase_id"
                        label={
                          selectedType === 'phase' ? 'Fase destino' : 'Fase'
                        }
                        margin="dense"
                        name="phase_id"
                        variant="outlined"
                        value={formik.values.phase_id}
                        fullWidth
                        error={
                          formik.touched.phase_id &&
                          Boolean(formik.errors.phase_id)
                        }
                        helperText={
                          formik.touched.phase_id && formik.errors.phase_id
                        }
                      />
                    )}
                  />
                )}
              {selectedType &&
                (selectedType === 'user' ||
                  selectedType === 'create_single_task') && (
                  <Autocomplete
                    id="user_id"
                    fullWidth
                    getOptionLabel={user => user.name || ''}
                    getOptionSelected={(user, value) => user.id === value.id}
                    options={userOptions}
                    value={
                      isUserLinkedCardOwner
                        ? userOptions.find(
                            a => a.id === 'userIsLinkedCardOwner',
                          ) || null
                        : userOptions[
                            userOptions.findIndex(
                              a => a.id === formik.values.user_id,
                            )
                          ] || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue('user_id', value?.id || '');
                      if (selectedType === 'create_single_task') {
                        formik.setFieldValue(
                          'taskData.userIsLinkedCardOwner',
                          false,
                        );
                      }
                    }}
                    loadingText="Carregando"
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="user_id"
                        label="Usuário"
                        margin="dense"
                        name="user_id"
                        variant="outlined"
                        value={formik.values.user_id}
                        fullWidth
                        error={
                          formik.touched.user_id &&
                          Boolean(formik.errors.user_id)
                        }
                        helperText={
                          formik.touched.user_id && formik.errors.user_id
                        }
                      />
                    )}
                  />
                )}

              {selectedType && selectedType === 'email' && (
                <>
                  <Autocomplete
                    id="sender_id"
                    fullWidth
                    getOptionLabel={email => email.email || ''}
                    getOptionSelected={(email, value) => email.id === value.id}
                    options={emails}
                    value={
                      emails[
                        emails.findIndex(a => a.id === formik.values.sender_id)
                      ] || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue('sender_id', value?.id || '');
                    }}
                    loading={loadingEmail}
                    loadingText="Carregando"
                    autoComplete={false}
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="sender_id"
                        label="Remetente"
                        margin="dense"
                        name="sender_id"
                        variant="outlined"
                        autoComplete="new-field-sender"
                        value={formik.values.sender_id}
                        fullWidth
                        error={
                          formik.touched.sender_id &&
                          Boolean(formik.errors.sender_id)
                        }
                        helperText={
                          formik.touched.sender_id && formik.errors.sender_id
                        }
                        InputProps={{
                          ...rest.InputProps,
                          endAdornment: (
                            <>
                              {loadingEmail ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {rest.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />

                  <Autocomplete
                    id="sendTo"
                    multiple
                    freeSolo
                    limitTags={2}
                    options={[
                      'Contato do Card',
                      'Usuário do Card',
                      ...emailTo.filter(
                        e => e !== 'Contato do Card' && e !== 'Usuário do Card',
                      ),
                    ]}
                    getOptionSelected={(emails, value) => emails === value}
                    fullWidth
                    onChange={(e, value) => {
                      setEmailTo(value);
                      setNewEmailTo('');
                      formik.setFieldValue('sendTo', value);
                    }}
                    value={emailTo}
                    inputValue={newEmailTo}
                    onInputChange={(e, value, reason) => {
                      if (reason === 'input') {
                        setNewEmailTo(value);
                        formik.setFieldValue('newReceiver', value);
                      }
                    }}
                    disableCloseOnSelect
                    loadingText="Carregando"
                    renderOption={(option, { selected }) => (
                      <CustomRenderCheckboxOption
                        option={option}
                        selected={selected}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option}
                          label={option}
                          size="small"
                          style={{
                            fontSize: '12px',
                            margin: '1px',
                          }}
                        />
                      ));
                    }}
                    onKeyDown={e => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    renderInput={rest => (
                      <TextField
                        {...rest}
                        id="sendTo"
                        name="sendTo"
                        label="Destinatário(os)"
                        margin="dense"
                        type="text"
                        placeholder="Inserir um novo e-mail..."
                        variant="outlined"
                        autoComplete="new-receiver"
                        value={formik.values.newReceiver}
                        error={
                          (formik.touched.sendTo &&
                            Boolean(formik.errors.sendTo)) ||
                          (formik.touched.newReceiver &&
                            Boolean(formik.errors.newReceiver))
                        }
                        helperText={
                          (formik.touched.sendTo && formik.errors.sendTo) ||
                          (formik.touched.newReceiver &&
                            formik.errors.newReceiver)
                        }
                        InputProps={{
                          ...rest.InputProps,
                        }}
                      />
                    )}
                  />
                  <Typography
                    variant="caption"
                    style={
                      ckInputsError.subject
                        ? { color: '#f44436', marginLeft: '15px' }
                        : { color: '#007dc6', marginLeft: '15px' }
                    }
                  >
                    Assunto do e-mail *
                  </Typography>
                  <div
                    style={
                      ckInputsError.subject
                        ? {
                            border: '2px solid #f44436',
                            borderRadius: '5px',
                            padding: '5px',
                          }
                        : undefined
                    }
                  >
                    <RichTextEditor
                      propData={formik.values.emailSubject || ''}
                      setPropData={handleSubjectChange}
                      suggestionsProps={suggestions}
                      toolbarHidden
                      small
                    />
                  </div>
                  {ckInputsError.subject && <FieldRequiredMessage />}

                  <Typography
                    variant="caption"
                    style={
                      ckInputsError.message
                        ? { color: '#f44436', marginLeft: '15px' }
                        : { color: '#007dc6', marginLeft: '15px' }
                    }
                  >
                    Corpo do e-mail *
                  </Typography>
                  <div
                    style={
                      ckInputsError.message
                        ? {
                            border: '2px solid #f44436',
                            borderRadius: '5px',
                            padding: '5px',
                          }
                        : undefined
                    }
                  >
                    <RichTextEditor
                      propData={formik.values.message || ''}
                      setPropData={handleMessageChange}
                      suggestionsProps={suggestions}
                      // toolbarHidden
                      small
                    />
                  </div>
                  {ckInputsError.message && <FieldRequiredMessage />}
                  <EmailFileField
                    id="attachments"
                    name="Anexos"
                    allowsMultiple
                    setNewFiles={setNewFiles}
                    actionName={formik.values.name || ''}
                    attachments={attachments}
                    filesNamesToSubmit={filesNamesToSubmit}
                    handleDeleteActionAttachments={
                      handleDeleteActionAttachments
                    }
                  />
                  <Tooltip
                    arrow
                    title="Limite máximo de 5 documentos e 3 arquivos"
                  >
                    <Autocomplete
                      id="customFields"
                      multiple
                      limitTags={3}
                      options={customFields
                        .filter(
                          cf => cf.type === 'DOCUMENT' || cf.type === 'FILE',
                        )
                        .sort((a, b) => a.type.localeCompare(b.type))}
                      groupBy={option =>
                        option.type === 'FILE' ? 'Arquivo' : 'Documento'
                      }
                      getOptionLabel={field => field.name || ''}
                      getOptionSelected={(field, value) =>
                        field.id === value.id
                      }
                      getOptionDisabled={option => {
                        if (
                          formik.values.customFields &&
                          formik.values.customFields.length > 0 &&
                          !formik.values.customFields.includes(option.id)
                        ) {
                          if (
                            (option.type === 'FILE' &&
                              selectedCustomFields.filter(
                                cf => cf.type === 'FILE',
                              ).length === 3) ||
                            (option.type === 'DOCUMENT' &&
                              selectedCustomFields.filter(
                                cf => cf.type === 'DOCUMENT',
                              ).length === 5)
                          ) {
                            return true;
                          }
                        }
                        return false;
                      }}
                      fullWidth
                      onChange={(e, value) => {
                        setSelectedCustomFields(value);
                        formik.setFieldValue(
                          'customFields',
                          value.map(v => v.id),
                        );
                      }}
                      value={selectedCustomFields}
                      disableCloseOnSelect
                      loadingText="Carregando"
                      renderOption={(option, { selected }) => (
                        <CustomRenderCheckboxOption
                          option={option.name}
                          selected={selected}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.id}
                            label={option.name}
                            size="small"
                            style={{
                              fontSize: '12px',
                              margin: '1px',
                            }}
                          />
                        ));
                      }}
                      onKeyPress={e => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                      renderInput={rest => (
                        <TextField
                          {...rest}
                          id="customFields"
                          name="customFields"
                          label="Anexos de campos personalizados"
                          margin="dense"
                          type="text"
                          placeholder="Campos personalizados"
                          variant="outlined"
                          error={
                            formik.touched.customFields &&
                            Boolean(formik.errors.customFields)
                          }
                          helperText={
                            formik.touched.customFields &&
                            formik.errors.customFields
                          }
                          InputProps={{
                            ...rest.InputProps,
                          }}
                        />
                      )}
                    />
                  </Tooltip>
                </>
              )}

              {selectedType && selectedType === 'alert' && (
                <TextField
                  label="Mensagem"
                  name="message"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={15}
                  maxRows={15}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  value={formik.values.message || ''}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              )}

              {selectedType && selectedType === 'create_single_task' && (
                <TaskAction
                  formik={formik}
                  customFields={customFields.filter(cf => cf.phaseOrder >= 0)}
                  linkedCardFields={formik.values?.linkedCardFields || []}
                />
              )}

              <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
                <ButtonMaterialUI
                  style={{
                    // height: '35px',
                    fontSize: '14px',
                    marginRight: 10,
                    width: '90px',
                    marginTop: '10px',
                  }}
                  size="small"
                  variant="contained"
                  onClick={goBack}
                >
                  Fechar
                </ButtonMaterialUI>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{
                    // height: '35px',
                    fontSize: '14px',
                    width: '90px',
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
