/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import * as Yup from 'yup';

import { Box, Container, createStyles, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import api from '../../../services/api';
import { useFilters } from '../../../context/FilterContext';

import { DispatchTypes } from './DispatchTypes';
// import { useLoader } from '../../../context/LoaderContext';
import Modal from '../../../components/Modal_phase';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },

    item: {
      '&:hover': {
        background: '#eaeaea',
      },
    },
  }),
);

const DISPATCH_TYPES_CONTENT = [
  'SUSPEND',
  'FINISH',
  'ADD_CARD_SUBORDINATE',
  'CHANGE_USER',
  'CHANGE_AREA',
  'CHANGE_CONTACT',
];

interface IFormData {
  card_id: string;
  dispatchType_id: string;
  observation: string;
  date_suspended?: Date;
  area?: IArea | null;
  area_id: string;
  user?: IUser | null;
  user_id: string;
  contact?: IDispatchContact | null;
  contact_id: string;
}

interface IContact {
  name: string;
}

interface IAccount {
  id: string;
  name: string;
  contact: IContact;
}

export interface ICard {
  id: string;
  phaseId: string;
  account: IAccount;
  task: {
    name: string;
    dateDeadlineFormat: string;
  };
  keyCard: number;
  percentage: number;
  description: string;
}

interface IDispatchProps {
  card: ICard;
  dispatchTypes: IDispatchType[];
  closeModal: (open: boolean) => void;
  onDispatchCard: () => void;
}

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IArea {
  id: string;
  name: string;
}

interface IUser {
  id: string;
  name: string;
}

interface IDispatchContact {
  id: string;
  name: string;
}

const DispatchMenu: React.FC<IDispatchProps> = ({
  card,
  dispatchTypes = [],
  closeModal,
  onDispatchCard = () => null,
}) => {
  const classes = useStyles();
  // const { showLoading, hideLoading } = useLoader();

  const [openDispatchCard, setOpenDispatchCard] = useState(false);
  const [key] = useState('');
  const [openType, setOpenType] = useState<IDispatchType | null>(null);
  const [users, setUsers] = useState<IUser[] | null>(null);
  const { filterArea } = useFilters();

  const initialValues: IFormData = {
    card_id: '',
    dispatchType_id: '',
    observation: '',
    date_suspended: new Date(),
    area: null,
    area_id: '',
    user_id: '',
    user: null,
    contact: null,
    contact_id: '',
  };

  useEffect(() => {
    if (key === 'CHANGE_USER')
      api.get(`/areas/${filterArea}/users`).then(response => {
        setUsers(response.data);
      });
  }, [key]);

  useEffect(() => {
    if (key === 'CHANGE_AREA') {
      if (users?.length === 0 || users === null) {
        toast.error('Não foi encontrado nenhum usuário nessa área.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    }
  }, [users]);

  const formSchema = Yup.object().shape({
    dispatchType_id: Yup.string()
      .uuid()
      .required('Selecione um tipo de avanço'),
    date_suspended:
      key === 'SUSPEND'
        ? Yup.date().required('Data de suspensão é obrigatória').nullable()
        : Yup.date().nullable(),
    area_id:
      key === 'ADD_CARD_SUBORDINATE' || key === 'CHANGE_AREA'
        ? Yup.string().required('Área é obrigatória').nullable()
        : Yup.string().nullable(),
    user_id:
      key === 'CHANGE_USER' || key === 'CHANGE_AREA'
        ? Yup.string().required('Usuário é obrigatório').nullable()
        : Yup.string().nullable(),
    contact_id:
      key === 'CHANGE_CONTACT'
        ? Yup.string().required('Contato é obrigatório')
        : Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,

    onSubmit: async (
      {
        dispatchType_id,
        date_suspended,
        area_id,
        user_id,
        contact_id,
        observation,
      },
      { setSubmitting },
    ) => {
      setSubmitting(true);
      try {
        if (key === 'ADD_CARD_SUBORDINATE') {
          await api.post(`/cards/${card?.id}/createChildren`, {
            parent_card_id: card?.id,
            area_id,
          });
        } else {
          await api.post(
            `/cards/${card?.id}/dispatchTypes/${dispatchType_id}`,
            {
              observation,
              ...(key === 'SUSPEND' ? { date_suspended } : { undefined }),
              ...(key === 'CHANGE_USER' ? { user_id } : { undefined }),
              ...(key === 'CHANGE_AREA' ? { area_id } : { undefined }),
              ...(key === 'CHANGE_AREA' ? { user_id } : { undefined }),
              ...(key === 'CHANGE_CONTACT' ? { contact_id } : { undefined }),
            },
          );
        }
        closeModal(true);
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  const handleOpenDispatchCard = (item: IDispatchType) => {
    if (DISPATCH_TYPES_CONTENT.includes(item?.key)) {
      setOpenType(item);
      setOpenDispatchCard(true);
    } else {
      try {
        // showLoading();
        api
          .post(`/cards/${card?.id}/dispatchTypes/${item.id}`)
          .then(() => {
            onDispatchCard();
          })
          .catch(err => {
            // hideLoading();
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          });
      } catch (err) {
        // hideLoading();
      }
    }
  };

  const handleDispatchClose = () => {
    setOpenDispatchCard(false);
  };

  return (
    <Container style={{ padding: '2px', margin: 0 }}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box className={classes.content}>
          {dispatchTypes.map(
            item =>
              (isMobile || item.key) && (
                <div
                  key={item.key}
                  onClick={() => handleOpenDispatchCard(item)}
                  className={classes.item}
                  style={{ padding: '6px 5px', borderRadius: '5px' }}
                  aria-hidden="true"
                >
                  {item.value}
                </div>
              ),
          )}
        </Box>
      </form>

      {openDispatchCard && (
        <Modal
          title={openType?.value || ''}
          titleLeft={card?.account?.name || ''}
          closeModal={() => {
            setOpenType(null);
            setOpenDispatchCard(false);
            closeModal(false);
          }}
          open={openDispatchCard}
        >
          <DispatchTypes
            cardId={card?.id}
            type={openType}
            onClose={handleDispatchClose}
            onDispatchCard={onDispatchCard}
          />
        </Modal>
      )}
    </Container>
  );
};

export default DispatchMenu;
