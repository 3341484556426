import React, { ReactNode, createContext, useContext, useState } from 'react';

interface LoaderContextData {
  loading: boolean;
  loadingMessage: string;
  showLoading(message?: string): void;
  hideLoading(): void;
}

const LoaderContext = createContext<LoaderContextData>({} as LoaderContextData);

interface LoaderProviderProps {
  children: ReactNode;
}

const LoaderProvider: React.FC<LoaderProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Aguarde...');

  const showLoading = (message: string) => {
    if (message) setLoadingMessage(message);
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };

  return (
    <LoaderContext.Provider
      value={{ loading, loadingMessage, showLoading, hideLoading }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

function useLoader(): LoaderContextData {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within an LoaderProvider');
  }
  return context;
}

export { LoaderProvider, LoaderContext, useLoader };
