import React, { useState } from 'react';
import { FormikProvider } from 'formik';
import { TextField, Stepper, Step, StepLabel } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { DocumentInfo } from 'src/@types/signatureTypes';
import { CopyrightBrasao } from 'src/components/CopyrightBrasao';
import { Button } from 'src/components/ui/button';
import logo from '../../../assets/logo-brasao-bpm.svg';
import useSignerForm from './hooks/useSignerForm';
import 'react-toastify/dist/ReactToastify.css';
import PdfDocument from '../PdfDocument';

interface SignerFormProps {
  signer_id: string;
}

const SignerForm: React.FC<SignerFormProps> = ({ signer_id }) => {
  const [step, setStep] = useState(0);
  const [userData, setUserData] = useState({
    name: '',
    cpf: '',
    birthDate: '',
  });
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo | null>(null);

  const {
    formik,
    validateCpf,
    handleCpfChange,
    codeFormik,
    recaptchaRef,
    handleRecaptchaChange,
    recaptchaToken,
    codeInputs,
    handleInputChange,
    handleInputKeyDown,
    inputRefs,
    isCodeComplete,
    handlePaste,
    timer,
    showRecaptcha,
    canResend,
    resendToken,
  } = useSignerForm(signer_id, setDocumentInfo, setStep, setUserData);

  const steps = ['Validar CPF', 'Confirmar Código', 'Visualizar Documento'];

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    return `${minutes}:${seconds % 60 < 10 ? '0' : ''}${seconds % 60}`;
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-200 p-0 m-0">
      <div
        className={`flex flex-col lg:flex-row items-center w-full ${
          step === 2 ? '' : 'lg:max-w-4xl'
        } `}
      >
        {step === 2 && documentInfo && (
          <div className="w-full h-full justify-center items-center">
            <PdfDocument
              base64Pdf={documentInfo.fileBase64}
              signer_id={signer_id}
              signerName={documentInfo.signerName}
              token={documentInfo.token}
              hash={documentInfo.hash}
            />
          </div>
        )}
        {step !== 2 && (
          <img
            src={logo}
            alt="Logo da Empresa Brasão Sistemas"
            className="w-64 mb-4 lg:mb-0 lg:mr-4"
          />
        )}
        {step !== 2 && (
          <div className="flex flex-col items-center w-full lg:w-3/4 bg-white rounded-lg shadow-lg p-6">
            <h5 className="mt-4 text-xl text-center">Assinatura Eletrônica</h5>
            <Stepper activeStep={step} className="w-full mt-4">
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="w-full">
              {step === 0 && (
                <FormikProvider value={formik}>
                  <form noValidate onSubmit={validateCpf} className="w-full">
                    <div className="mt-2 p-2">
                      <TextField
                        name="cpf"
                        label="CPF"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        onChange={handleCpfChange}
                        value={formik.values.cpf}
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                        onBlur={formik.handleBlur}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        className="w-full mb-4"
                      />
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}
                        onChange={handleRecaptchaChange}
                        className="mb-4"
                      />
                      <Button
                        type="submit"
                        disabled={!formik.isValid || !recaptchaToken}
                        className="mt-4 mb-2 w-full p-2 bg-blue-600 text-white rounded"
                      >
                        Acessar Documento
                      </Button>
                    </div>
                  </form>
                </FormikProvider>
              )}
              {step === 1 && (
                <FormikProvider value={codeFormik}>
                  <form
                    noValidate
                    onSubmit={e => {
                      e.preventDefault();
                      codeFormik.handleSubmit();
                    }}
                    className="w-full"
                  >
                    <div className="mt-2 p-2">
                      <div className="text-left">
                        <h5 className="text-gray-600 pb-2">
                          Dados da Assinatura
                        </h5>
                        <p className="font-medium">
                          <em>Nome do usuário: {userData.name}</em>
                        </p>
                        <p className="font-medium">
                          <em>CPF: {userData.cpf}</em>
                        </p>
                        <p className="font-medium">
                          <em>Data de nascimento: {userData.birthDate}</em>
                        </p>
                      </div>
                      <div className="text-left mt-4 text-gray-600">
                        <p>
                          Código de confirmação enviado para o e-mail
                          cadastrado. Para prosseguir, insira o código abaixo:
                        </p>
                      </div>
                      <div className="flex justify-between mt-2 space-x-2">
                        {codeInputs.map((_, index) => (
                          <TextField
                            // eslint-disable-next-line react/no-array-index-key
                            key={`digit-${index}`}
                            name={`digit-${index}`}
                            variant="outlined"
                            margin="normal"
                            inputProps={{
                              maxLength: 1,
                              style: { textAlign: 'center' },
                              'aria-label': `Digit ${index + 1}`,
                              autoComplete: 'off',
                              onPaste: handlePaste,
                            }}
                            inputRef={el => {
                              inputRefs.current[index] = el!;
                            }}
                            onChange={e =>
                              handleInputChange(
                                e as React.ChangeEvent<HTMLInputElement>,
                                index,
                              )
                            }
                            onKeyDown={e =>
                              handleInputKeyDown(
                                e as React.KeyboardEvent<HTMLInputElement>,
                                index,
                              )
                            }
                            value={codeInputs[index]}
                            className="w-16 lg:w-12"
                          />
                        ))}
                      </div>
                      {showRecaptcha && (
                        <div className="text-left mt-4">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={
                              process.env.REACT_APP_CAPTCHA_SITE_KEY || ''
                            }
                            onChange={handleRecaptchaChange}
                            className="mb-4"
                          />
                        </div>
                      )}
                      <div className="text-left mt-4">
                        <a
                          href="#"
                          className={`text-blue-600 hover:underline ${
                            canResend ? '' : 'pointer-events-none text-gray-400'
                          }`}
                          onClick={resendToken}
                        >
                          Caso não tenha recebido o código, clique aqui para
                          gerar novamente.
                        </a>
                      </div>
                      <div className="text-left mt-4">
                        <p>
                          Tempo restante para expiração do código:{' '}
                          {formatTime(timer)}
                        </p>
                      </div>
                      <Button
                        type="submit"
                        className="mt-4 mb-2 w-full p-2 bg-blue-600 text-white rounded"
                        disabled={!isCodeComplete}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </form>
                </FormikProvider>
              )}
            </div>
            <CopyrightBrasao />
          </div>
        )}
      </div>
    </div>
  );
};

export default SignerForm;
