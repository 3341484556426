import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  tableTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: COLORS.BLUE,
    width: '100%',
    height: '22px',
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginRight: theme.spacing(1),
    fontSize: '12px',
    fontWeight: 500,
    color: '#676767',
    margin: '3px 0',
    borderRadius: '5px',
  },
  conditionsBox: {
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  conditionGroup: {
    backgroundColor: '#fafafa',
    borderRadius: '5px',
    width: '100%',
    height: 'auto',
    padding: '0 5px',
  },
  conditionGroupHeader: {
    width: '100%',
  },
  conditionGroupTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#676767',
    width: '100%',
    height: '22px',
  },
  conditionItems: {
    flexDirection: 'column',
    width: '100%',
    borderRadius: '5px',
    padding: '0 5px',
  },
  conditionItemRow: {
    backgroundColor: 'white',
    padding: '0 5px',
    borderRadius: '5px',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLACK,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLACK,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLACK,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  conditionField: {
    marginRight: '3px',
  },
});
