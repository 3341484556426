import React, { useEffect, useState } from 'react';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { Card } from 'src/components/ui/card';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useUsersAll } from 'src/hooks/useUsersAll';
import { useAuth } from 'src/context/AuthContext';
import UserCards from './Widgets/user-cards';
import { UserTasks } from './Widgets/user-tasks';
import FunnelGraph from './Widgets/funnel-graph';
import ColumnGraph from './Widgets/column-graph';
import api from '../../services/api';
import { Widget } from './components/widget';
import {
  WorkspaceData,
  FunnelData,
  WorkspaceTaskData,
  AreaPhaseProps,
  DispatchPhasesByArea,
} from './types';

const Workspace = () => {
  const { filtersData, shouldReloadData, setLoading } = useWorkspaceFilters();
  const { users: userOptions } = useUsersAll();
  const { user: loggedUser } = useAuth();
  const [workspaceData, setWorkspaceData] = useState<WorkspaceData>();
  const [funnelData, setFunnelData] = useState<FunnelData>();
  const [areasPhases, setAreasPhases] = useState<DispatchPhasesByArea | null>(
    null,
  );

  useEffect(() => {
    if (
      (filtersData.cards?.area_ids && filtersData.cards?.area_ids.length > 0) ||
      loggedUser.areaId
    ) {
      const filteredAreaIds = Array.isArray(filtersData.cards?.area_ids)
        ? (filtersData.cards?.area_ids as string[])
        : [];
      const areaIds =
        filteredAreaIds.length > 0 ? [...filteredAreaIds] : [loggedUser.areaId];

      api
        .post('/phases/areas', {
          area_ids: areaIds,
          listInactive: false,
        })
        .then(response => {
          const formattedData = response.data.reduce(
            (acc: DispatchPhasesByArea, item: AreaPhaseProps) => {
              const { area, id, name } = item;
              const { id: areaId } = area;
              if (!acc[areaId]) {
                acc[areaId] = [];
              }
              acc[areaId].push({ id, value: name, key: '' });
              return acc;
            },
            {} as DispatchPhasesByArea,
          );
          setAreasPhases(formattedData);
        });
    } else {
      setAreasPhases(null);
    }
  }, [filtersData.cards?.area_ids]);

  useEffect(() => {
    setLoading(true);
    api
      .post<WorkspaceData>('/workspace', filtersData)
      .then(response => {
        const newWorkspaceData = response.data;
        setWorkspaceData(newWorkspaceData);
        if (newWorkspaceData.funnel) {
          setFunnelData({
            ...newWorkspaceData.funnel,
            phaseCounts: newWorkspaceData.funnel.phaseCounts.reverse(),
          });
        }
      })
      .catch((err: AxiosError) => {
        let errorMessage = `Falha ao carregar dados da área de trabalho.`;
        if (axios.isAxiosError(err) && err.response?.data) {
          const errorData = err.response.data as { message?: string };
          errorMessage += ` ${errorData.message || err.message || ''}`;
        } else {
          errorMessage += ` ${err.message || ''}`;
        }
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      })
      .finally(() => setLoading(false));
  }, [filtersData, shouldReloadData]);

  const handleRefreshTaskList = () => {
    api
      .post<WorkspaceData>('/workspace', {
        tasks: {
          user_id: filtersData.tasks?.user_id,
        },
      })
      .then(response => {
        const newTasks: WorkspaceTaskData | undefined = response.data?.tasks;
        if (newTasks)
          setWorkspaceData(oldValue => ({ ...oldValue, tasks: newTasks }));
      });
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-full grow gap-2 px-2 overflow-auto max-w-full my-0 mx-auto">
      <div className="flex flex-col lg:flex-col lg:w-4/5 gap-2 max-w-full">
        <Card className="flex flex-col gap-4 p-3 grow">
          <UserTasks
            tasks={workspaceData?.tasks}
            userOptions={userOptions!}
            handleRefreshTaskList={handleRefreshTaskList}
          />
        </Card>
        <div className="flex flex-col lg:flex-row gap-2.5 flex-grow justify-center">
          <Widget>
            <FunnelGraph userOptions={userOptions!} data={funnelData} />
          </Widget>
          <Widget>
            <ColumnGraph
              data={workspaceData?.column}
              userOptions={userOptions!}
              fieldOptions={workspaceData?.column?.selectableFields || []}
            />
          </Widget>
        </div>
      </div>
      <Card className="flex flex-col gap-4 p-3 min-w-[240px] lg:w-1/5">
        <UserCards
          cards={workspaceData?.cards || []}
          userOptions={userOptions!}
          areasPhases={areasPhases}
        />
      </Card>
    </div>
  );
};

export default Workspace;
