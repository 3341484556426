import React from 'react';

import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TextField,
  Theme,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  IIntegrationFieldValue,
  IIntegrationTypeFieldsProps,
} from '../IntegrationRules.i';
import { styles } from '../styles';
import { CustomPopper } from '../../../components/AutoCompleteCustomComponents/CustomPopper';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const IntegrationTypeFields: React.FC<IIntegrationTypeFieldsProps> = ({
  integrationTypeFields,
  integrationFieldValues,
  associatedFieldOptions,
  customFields,
  setIntegrationFieldValues,
}) => {
  const classes = useStyles();

  const handleIntegrationFieldValue = (
    typeFieldId: string,
    isTable: boolean,
    requiresFullIntegratedItem: boolean,
    valueId: string,
    valueType: string,
  ) => {
    const newFieldValue = integrationFieldValues.find(
      field => field.integrationTypeFieldId === typeFieldId,
    );

    if (!valueId && newFieldValue) {
      const removeEmptyField = integrationFieldValues.filter(
        field => field.integrationTypeFieldId !== typeFieldId,
      );
      setIntegrationFieldValues(removeEmptyField);
      return;
    }

    if (newFieldValue) {
      const newIntegrationFieldValues = integrationFieldValues.map(
        (field: IIntegrationFieldValue) => {
          if (field.integrationTypeFieldId === typeFieldId) {
            return {
              integrationTypeFieldId: typeFieldId,
              fieldId: valueId,
              fixedValue: '',
              isCustomField: valueType === 'customField',
              isAccountField: valueType === 'accountField',
              integratedColumnName:
                valueType === 'INTEGRATED' &&
                requiresFullIntegratedItem === false
                  ? 'Codigo'
                  : '',
            };
          }

          return field;
        },
      ) as IIntegrationFieldValue[];

      if (isTable) {
        const tableTypeFieldsIds = integrationTypeFields
          .filter(typeField => typeField.tableId === typeFieldId)
          .map(t => t.id);
        const tableFieldsValues = newIntegrationFieldValues.filter(tField => {
          if (!tableTypeFieldsIds.includes(tField.integrationTypeFieldId))
            return true;

          return false;
        });
        setIntegrationFieldValues(tableFieldsValues);
      } else {
        setIntegrationFieldValues(newIntegrationFieldValues);
      }
    } else {
      setIntegrationFieldValues((oldState: IIntegrationFieldValue[]) => [
        ...oldState,
        {
          integrationTypeFieldId: typeFieldId,
          fieldId: valueId,
          fixedValue: '',
          isCustomField: valueType === 'customField',
          isAccountField: valueType === 'accountField',
          integratedColumnName:
            valueType === 'INTEGRATED' && requiresFullIntegratedItem === false
              ? 'Codigo'
              : '',
        },
      ]);
    }
  };

  const handleIntegrationRulesFixedValue = (
    typeFieldId: string,
    value: string,
  ) => {
    const newFieldValue = integrationFieldValues.find(
      field => field.integrationTypeFieldId === typeFieldId,
    );

    if (!value && newFieldValue) {
      const removeEmptyField = integrationFieldValues.filter(
        field => field.integrationTypeFieldId !== typeFieldId,
      );
      setIntegrationFieldValues(removeEmptyField);
      return;
    }

    if (newFieldValue) {
      const newIntegrationFieldValues = integrationFieldValues.map(
        (field: IIntegrationFieldValue) => {
          if (field.integrationTypeFieldId === typeFieldId) {
            return {
              integrationTypeFieldId: typeFieldId,
              fieldId: '',
              fixedValue: value,
              isCustomField: false,
              isAccountField: false,
            };
          }

          return field;
        },
      ) as IIntegrationFieldValue[];
      setIntegrationFieldValues(newIntegrationFieldValues);
    } else {
      setIntegrationFieldValues((oldState: IIntegrationFieldValue[]) => [
        ...oldState,
        {
          integrationTypeFieldId: typeFieldId,
          fieldId: '',
          fixedValue: value,
          isCustomField: false,
          isAccountField: false,
        },
      ]);
    }
  };

  return (
    <Box marginTop="20px">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="lista de campos">
          <TableHead>
            <TableRow>
              <TableCell>Campo ERP</TableCell>
              <TableCell>Campo a associar</TableCell>
              <TableCell>Valor Fixo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrationTypeFields.map(integrationTypeField => (
              <TableRow key={integrationTypeField.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={
                    integrationTypeField.tableId ? classes.hasTable : undefined
                  }
                >
                  {integrationTypeField.tableId
                    ? `- ${integrationTypeField.name}`
                    : integrationTypeField.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {associatedFieldOptions.length > 0 && (
                    <Autocomplete
                      id={integrationTypeField.name}
                      fullWidth
                      style={{ minWidth: '200px' }}
                      getOptionLabel={i =>
                        i.tableName
                          ? `${i.name} - ${i.tableName}`
                          : i.name || ''
                      }
                      getOptionSelected={(i, v) => i?.id === v?.id}
                      options={(integrationTypeField.isTable
                        ? associatedFieldOptions.filter(
                            option => option.type === 'TABLE',
                          )
                        : !integrationTypeField.isTable &&
                          integrationTypeField.tableId
                        ? associatedFieldOptions.filter(option => {
                            const tableFieldValue = integrationFieldValues.find(
                              tField =>
                                integrationTypeField.tableId ===
                                  tField.integrationTypeFieldId &&
                                tField.fieldId,
                            );

                            if (
                              tableFieldValue &&
                              !integrationTypeField.requiresFullIntegratedItem
                            ) {
                              return option.tableId === tableFieldValue.fieldId;
                            }

                            if (
                              tableFieldValue &&
                              integrationTypeField.requiresFullIntegratedItem
                            ) {
                              return (
                                option.type === 'INTEGRATED' &&
                                option.tableId === tableFieldValue.fieldId
                              );
                            }

                            return false;
                          })
                        : integrationTypeField.requiresFullIntegratedItem
                        ? associatedFieldOptions.filter(
                            option =>
                              option.type === 'INTEGRATED' && !option.tableId,
                          )
                        : associatedFieldOptions.filter(
                            option =>
                              option.type !== 'TABLE' && !option.tableId,
                          )
                      ).sort((a, b) => -b.phase.localeCompare(a.phase))}
                      groupBy={option => (option.phase as string) ?? 'Contas'}
                      PopperComponent={CustomPopper}
                      value={
                        associatedFieldOptions[
                          associatedFieldOptions.findIndex(
                            field =>
                              integrationFieldValues.find(
                                integrationFieldValue =>
                                  integrationFieldValue.integrationTypeFieldId ===
                                  integrationTypeField.id,
                              )?.fieldId === field.id,
                          )
                        ] || null
                      }
                      onChange={(e, value) => {
                        handleIntegrationFieldValue(
                          integrationTypeField.id,
                          integrationTypeField.isTable,
                          value
                            ? integrationTypeField.requiresFullIntegratedItem
                            : false,
                          value ? value.id : '',
                          value ? value.fieldType : '',
                        );
                      }}
                      noOptionsText={
                        !integrationTypeField.isTable &&
                        integrationTypeField.tableId &&
                        customFields
                          ? 'Sem opções ou tabela não associada.'
                          : 'Sem opções.'
                      }
                      loadingText="Carregando"
                      renderInput={rest => (
                        <TextField
                          {...rest}
                          // fullWidth
                          required={
                            integrationTypeField.isRequired &&
                            !integrationFieldValues.find(
                              integrationFieldValue =>
                                integrationFieldValue.integrationTypeFieldId ===
                                integrationTypeField.id,
                            )?.fixedValue
                          }
                          label="Associação"
                          margin="dense"
                          InputProps={{
                            ...rest.InputProps,
                          }}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  <TextField
                    label="Valor Fixo"
                    fullWidth
                    onChange={value =>
                      handleIntegrationRulesFixedValue(
                        integrationTypeField.id,
                        value.target.value,
                      )
                    }
                    value={
                      integrationFieldValues.find(
                        integrationFieldValue =>
                          integrationFieldValue.integrationTypeFieldId ===
                          integrationTypeField.id,
                      )?.fixedValue ?? ''
                    }
                    disabled={
                      integrationTypeField.isTable ||
                      integrationTypeField.requiresFullIntegratedItem
                    }
                    margin="dense"
                    required={
                      integrationTypeField.isRequired &&
                      !integrationTypeField.isTable &&
                      !associatedFieldOptions[
                        associatedFieldOptions.findIndex(
                          field =>
                            integrationFieldValues.find(
                              integrationFieldValue =>
                                integrationFieldValue.integrationTypeFieldId ===
                                integrationTypeField.id,
                            )?.fieldId === field.id,
                        )
                      ]
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IntegrationTypeFields;
