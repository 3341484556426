/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Card } from 'src/components/ui/card';
import { cn } from 'src/lib/utils';
import { WorkspaceCardData } from 'src/pages/Workspace/types';
import api from 'src/services/api';
import { useTrigger } from 'src/context/TriggerContext';
import { toast } from 'react-toastify';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { Card as CardModal } from 'src/pages/Card';
import { InfoIcon, CheckCircle2Icon, ClockIcon } from 'lucide-react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { TextWithEllipsisAndTooltip } from '../TextWithEllipsisAndTooltip';
import DispatchOptions, { IDispatchProps } from './dispatchOptions';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../ui/hover-card';
import { Progress } from '../ui/progress';

type CardProps = React.ComponentProps<typeof Card>;

interface CardBPMProps extends CardProps {
  readonly data: WorkspaceCardData;
  readonly dispatchTypes: {
    id: string;
    value: string;
    key: string;
  }[];
}

export function CardBPM({
  className,
  data,
  dispatchTypes,
  ...props
}: CardBPMProps) {
  const { setShouldReloadData } = useWorkspaceFilters();
  const [modalOpen, setModalOpen] = useState(false);
  const { updateTriggers } = useTrigger();

  const dispatchCardPhaseChange = async (card_id: string, phase_id: string) => {
    const dispatchingCard = toast.loading(`Trocando card de fase.`);
    document.body.style.cursor = 'progress';

    api
      .post(`/cards/${card_id}/dispatchTypes/${phase_id}`)
      .then(response => {
        const card = response?.data?.card;
        const triggers = response?.data?.triggers;
        const id = card?.id;
        const key_card = card?.key_card;
        const accountName = card?.account.name;
        const triggerDateTime = new Date();

        if (id && key_card && triggers && Object.keys(triggers).length > 0) {
          updateTriggers({
            card_id: id,
            key_card,
            triggers,
            accountName,
            triggerDateTime,
          });
        }

        toast.update(dispatchingCard, {
          render: `Card trocado de fase.`,
          type: 'success',
          isLoading: false,
          autoClose: 2000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
        document.body.style.cursor = 'auto';
        // handleCardList();
        setShouldReloadData(oldValue => !oldValue);
      })
      .catch(error => {
        document.body.style.cursor = 'auto';
        toast.update(dispatchingCard, {
          render: `Ocorreu um erro ao trocar de fase. ${error.response.data.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleCloseModal = () => {
    setShouldReloadData(oldValue => !oldValue);
    setModalOpen(false);
  };

  function openCardModal() {
    setModalOpen(true);
  }

  const cardStatus = data.date_finally
    ? 'Finalizado'
    : data.date_suspended_until &&
      new Date(data.date_suspended_until) > new Date()
    ? 'Suspenso'
    : 'Ativo';

  const withStopPropagation = <P extends object>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<P> => {
    const ComponentWithStopPropagation: React.FC<P> = (props: P) => {
      const stopPropagation = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        event.stopPropagation();
      };

      return (
        <div onClick={stopPropagation}>
          <WrappedComponent {...props} />
        </div>
      );
    };

    ComponentWithStopPropagation.displayName = `withStopPropagation(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    return ComponentWithStopPropagation;
  };

  const DispatchWithoutPropagation =
    withStopPropagation<IDispatchProps>(DispatchOptions);

  return (
    <>
      <Card
        className={cn('w-full cursor-pointer shadow-md', className)}
        onClick={e => {
          e.stopPropagation();
          openCardModal();
        }}
        {...props}
      >
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <TextWithEllipsisAndTooltip
              className="font-bold"
              text={data.account.name}
            />
            <DispatchWithoutPropagation
              card={data}
              dispatchTypes={dispatchTypes.filter(
                item => item.value !== data.phase.name,
              )}
              closeModal={() => {}}
              onChangePhaseDispatch={dispatchCardPhaseChange}
            />
          </div>
          <div className="flex flex-row gap-2 justify-between">
            <TextWithEllipsisAndTooltip
              className="text-slate-500"
              text={data.contact.name}
            />
            <TextWithEllipsisAndTooltip text={String(data.keyCard)} />
          </div>
          <div className="flex flex-row gap-2 justify-between align-center">
            <div className="flex felx-row justify-center align-center gap-2 mt-[2px]">
              <HoverCard openDelay={500}>
                <HoverCardTrigger>
                  <InfoIcon size={18} className="cursor-help" />
                </HoverCardTrigger>
                <HoverCardContent className="w-[300px]">
                  <div className="flex flex-row gap-1">
                    <TextWithEllipsisAndTooltip
                      className="font-bold"
                      text={data.area.name}
                    />
                    <p>/</p>
                    <TextWithEllipsisAndTooltip
                      className="font-bold"
                      text={data.phase.name}
                    />
                    <p>/</p>
                    <TextWithEllipsisAndTooltip
                      className="font-bold"
                      text={String(data.keyCard)}
                    />
                  </div>
                  <div className="flex flex-row justify-between">
                    <p>{data.user.name}</p>
                    <p>
                      {data.createdAt
                        ? new Date(data.createdAt).toLocaleDateString('pt-BR')
                        : 'Não informada'}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p> {data.nextTask.name}</p>
                    <p>{cardStatus}</p>
                  </div>
                  <Progress value={Number(data.percentage)} />
                </HoverCardContent>
              </HoverCard>
              {cardStatus === 'Finalizado' ? (
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <CheckCircle2Icon
                      size={22}
                      className="text-white fill-green-500 mt-[-1.5px]"
                    />
                  </Tooltip.Trigger>
                  <Tooltip.Content side="top" align="center">
                    <Card>Finalizado</Card>
                  </Tooltip.Content>
                </Tooltip.Root>
              ) : (
                <>
                  {cardStatus === 'Suspenso' ? (
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <ClockIcon
                          size={22}
                          className="text-white fill-yellow-500 mt-[-1.5px]"
                        />
                      </Tooltip.Trigger>
                      <Tooltip.Content side="top" align="center">
                        <Card>Suspenso</Card>
                      </Tooltip.Content>
                    </Tooltip.Root>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <TextWithEllipsisAndTooltip text={data.phase.name} />
          </div>
        </div>
      </Card>
      {modalOpen && (
        <CardModal
          openModal={modalOpen}
          handleCloseModal={handleCloseModal}
          cardId={data.id}
        />
      )}
    </>
  );
}
