import React, { useState, useEffect } from 'react';

const LoadingMessage: React.FC = () => {
  const loadingMessages = [
    'Estamos trabalhando para entregar a melhor experiência.',
    'Processando dados para você, por favor aguarde.',
    'Preparando insights poderosos, isso pode levar um momento.',
  ];

  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessage(prevMessage => {
        const currentIndex = loadingMessages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % loadingMessages.length;
        return loadingMessages[nextIndex];
      });
    }, 4000);

    return () => clearInterval(messageInterval);
  }, [loadingMessages]);

  return (
    <div className="flex flex-col items-center">
      <div className="flex justify-start m-8 gap-2 items-center">
        <div className="w-2 h-2 rounded-full animate-grow-shrink bg-gray-600" />
        <div className="w-2 h-2 rounded-full animate-grow-shrink animate-grow-shrink-delay-200 bg-gray-600" />
        <div className="w-2 h-2 rounded-full animate-grow-shrink animate-grow-shrink-delay-400 bg-gray-600" />
      </div>
      <div className="flex justify-center">
        <p className="text-base">{currentMessage}</p>
      </div>
    </div>
  );
};

export default LoadingMessage;
