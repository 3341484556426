import { DynamicColumnFormat } from '../IntegrationRules.i';

export const parseFileContentToObject = (
  content: string,
): Record<string, string> => {
  const lines = content.split('\n');
  const object: Record<string, string> = {};

  lines.forEach(line => {
    const parts = line.split('=');
    if (parts.length >= 2) {
      const key = parts[0].trim();
      let value = parts.slice(1).join('=').trim(); // Joining parts in case there are equal signs in the value
      const match = value.match(/"(.*)";$/); // Match the value within quotes followed by a semicolon at the end
      if (match) {
        // eslint-disable-next-line prefer-destructuring
        value = match[1]; // Get the matched value without the quotes and semicolon
      }
      value = value.replace(/"/g, ''); // Remove double quotes
      value = value.replace(/;/g, ''); // Remove semicolons
      // value = decodeURIComponent(value); // Decode the value
      object[key] = value;
    }
  });

  return object;
};

export const generateDynamicFormat = (
  headerRow: string[],
): Record<string, DynamicColumnFormat> => {
  const dynamicFormat: Record<string, DynamicColumnFormat> = {};
  headerRow.forEach((columnName, index) => {
    dynamicFormat[columnName] = {
      name: columnName,
      align: 'left',
      order: (index + 1).toString(),
      isDisplayColumn: false,
      isPrimaryKeyColumn: false,
      isHidden: false,
    };
  });
  return dynamicFormat;
};
