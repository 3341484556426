/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  Switch,
  TextField,
  Theme,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  IIntegrationRuleMoment,
  IIntegrationRuleMomentsProps,
  IPhase,
} from '../IntegrationRules.i';
import { styles } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const LargerFontTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

const IntegrationMoments: React.FC<IIntegrationRuleMomentsProps> = ({
  loadingPhase,
  areaPhases,
  integrationMoments,
  activeMoments,
  setActiveMoments,
  setIntegrationMoments,
}) => {
  const classes = useStyles();

  const handleChangeMoment = (
    event: React.BaseSyntheticEvent,
    moment: IIntegrationRuleMoment,
    momentIndex: number,
  ) => {
    const currentMoment = moment;
    currentMoment.isChecked = event.target.checked;
    currentMoment.isChecked
      ? addMoment(currentMoment)
      : removeMoment(currentMoment);

    const newIntegrationMoments = [...integrationMoments];
    newIntegrationMoments[momentIndex].phase = null;
    newIntegrationMoments[momentIndex].error = false;
    setIntegrationMoments(newIntegrationMoments);
  };

  const addMoment = (moment: IIntegrationRuleMoment) => {
    const findMoment = activeMoments.find(f => f.id === moment.id);
    if (!findMoment) {
      setActiveMoments([...activeMoments, moment]);
    }
  };

  const removeMoment = (moment: IIntegrationRuleMoment) => {
    const newActiveMoments = [...activeMoments];
    newActiveMoments.splice(
      newActiveMoments.findIndex(f => f.id === moment.id),
      1,
    );
    setActiveMoments(newActiveMoments);
  };

  const handleChangePhase = (
    phase: IPhase,
    moment: IIntegrationRuleMoment,
    momentIndex: number,
  ) => {
    const indexMoment = activeMoments.findIndex(f => f.id === moment.id);

    if (indexMoment > -1) {
      const newActiveMoments = [...activeMoments];
      newActiveMoments[indexMoment].phase = phase;
      newActiveMoments[indexMoment].phase_id = phase.id;
      setActiveMoments(newActiveMoments);
    }

    const newIntegrationMoments = [...integrationMoments];
    newIntegrationMoments[momentIndex].phase = phase;
    newIntegrationMoments[momentIndex].phase_id = phase.id;
    if (phase.name === '') {
      newIntegrationMoments[momentIndex].error = true;
    }
    newIntegrationMoments[momentIndex].error = false;
    setIntegrationMoments(newIntegrationMoments);
  };

  return (
    <Box className={classes.moments}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="lista de momentos">
          <TableHead>
            <TableRow>
              <TableCell>Momentos</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {integrationMoments.map((moment, momentIndex) => (
                  <Box key={moment.id}>
                    <Box className={classes.momentContainer}>
                      <LargerFontTooltip
                        title={
                          !moment.hasPhase
                            ? 'Ao ativar esse momento, apenas campos de conta poderão ser utilizados na associação'
                            : ''
                        }
                        placement="top"
                        arrow
                      >
                        <div className={classes.momentSwitch}>
                          <Switch
                            color="primary"
                            name="checkedB"
                            checked={moment.isChecked || false}
                            onChange={e =>
                              handleChangeMoment(e, moment, momentIndex)
                            }
                            inputProps={{
                              'aria-label': 'primary checkbox',
                            }}
                          />
                          <div
                            style={{
                              fontWeight: 500,
                              marginLeft: '1px',
                              display: 'unset',
                            }}
                          >
                            {`${moment.description}`}
                          </div>
                        </div>
                      </LargerFontTooltip>
                      {moment.hasPhase && areaPhases && moment.isChecked && (
                        <div className={classes.momentPhase}>
                          <Autocomplete
                            id={moment.id + moment.phase?.id}
                            disableClearable
                            getOptionLabel={phase => phase.name || ''}
                            getOptionSelected={(phase, value) =>
                              phase.id === value.id
                            }
                            value={
                              (integrationMoments[momentIndex]
                                ?.phase as IPhase) || null
                            }
                            options={areaPhases}
                            style={{ width: 220, height: 40 }}
                            onChange={(e, phase) => {
                              if (phase) {
                                handleChangePhase(phase, moment, momentIndex);
                              }
                            }}
                            loadingText="Carregando"
                            fullWidth
                            renderInput={rest => (
                              <TextField
                                {...rest}
                                label="Fase"
                                margin="dense"
                                variant="outlined"
                                error={moment.error}
                                helperText={
                                  moment.error ? 'Fase é obrigatório' : ''
                                }
                                InputProps={{
                                  ...rest.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingPhase ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {rest.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </Box>
                  </Box>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default IntegrationMoments;
