import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  root: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    },
  },
  integrationFilters: {
    boxSizing: 'border-box',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  disabledIntegrationFilters: {
    '& .MuiTypography-root': {
      color: theme.palette.text.disabled,
    },
  },
  overflowCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  tooltip: {
    fontSize: '14px',
  },
  content: {
    // border: `1px solid ${theme.palette.grey[400]}`,
    // borderRadius: '10px',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    // padding: '10px',
    width: '100%',
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: '#007dc6',
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  belongsToTableInput: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '2px',
    flexWrap: 'wrap',
  },
  buttonSubmit: {
    marginTop: theme.spacing(1.3),
    marginRight: theme.spacing(2),
  },
  columnActions: {
    width: '60px',
  },
  title: { fontSize: '30px', fontWeight: 'bold', marginTop: '20px' },
  checkBox: {
    marginTop: '5px',
  },
  table: {
    minWidth: 650,
  },
  templateTitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: COLORS.BLUE,
    width: '100%',
    height: '22px',
  },
  checkBoxAutoComplete: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
});
