/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridRowParams,
} from '@material-ui/data-grid';

// DataTable props
interface DataTableProps<T extends GridRowData> {
  columns: GridColDef[];
  rows: T[];
  loading?: boolean;
  density?: 'compact' | 'standard' | 'comfortable';
  height?: number;
  width?: number;
  onRowClick?: (params: GridRowParams, event: any, details?: any) => void;
}

export default function DataTable<T extends GridRowData>({
  columns,
  rows,
  density = 'compact',
  loading = false,
  height,
  width,
  onRowClick,
}: DataTableProps<T>) {
  return (
    <div style={{ height: height || 400, width: width || '100%' }}>
      <DataGrid
        rows={rows}
        rowCount={rows.length}
        hideFooter
        columns={columns}
        // checkboxSelection
        loading={loading}
        rowsPerPageOptions={[]}
        disableSelectionOnClick
        density={density}
        onRowClick={onRowClick || undefined}
        getRowClassName={() => 'cursor-pointer'}
      />
    </div>
  );
}
