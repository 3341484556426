/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles: any = makeStyles(theme =>
  createStyles({
    instructions: {
      margin: 0,
      padding: 0,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      fontWeight: 500,
    },
    summary: {
      '.MuiAccordionSummary-content.Mui-expanded': {
        margin: '5px 0',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: `1px solid #dfdfdf`,
    },
    info: {
      fontSize: theme.typography.pxToRem(15),
      // color: theme.palette.text.secondary,
    },
    list: {
      fontSize: theme.typography.pxToRem(15),
      // color: theme.palette.text.secondary,
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
    },
  }),
);

interface ListItemProps {
  text: string;
}

const ListItemComponent: React.FC<ListItemProps> = ({ text }) => {
  return (
    <ListItem style={{ maxWidth: 'fit-content' }}>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export const ExpressionInstructions: React.FC = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      mt={0.5}
      mb={1}
      width="100%"
    >
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        style={{ width: '100%' }}
        className={classes.summary}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Instruções gerais</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Typography className={classes.info}>- Campo obrigatório.</Typography>
          <Typography className={classes.info}>
            - A expressão pode ser apenas um número fixo.
          </Typography>
          <Typography className={classes.info}>
            {`- Fórmula exemplo com operadores e campos: "(@CAMPO_01 + @CAMPO_02) / 2".`}
          </Typography>
          <Typography className={classes.info}>
            {`- Utilize o "@" para exibir sugestões de campos personalizados 
            disponíveis, do tipo numérico.`}
          </Typography>
          <Typography className={classes.info}>
            - Deixe um espaço entre os operadores e valores/campos.
          </Typography>
          <Typography className={classes.info}>
            - É possível utilizar operadores matemáticos como:
          </Typography>
          <List dense className={classes.list}>
            <ListItemComponent text="(+) Adição" />
            <ListItemComponent text="(-) Subtração" />
            <ListItemComponent text="(*) Multiplicação" />
            <ListItemComponent text="(/) Divisão" />
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
