/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Checkbox } from 'src/components/ui/checkbox';
import {
  ICardFields,
  TLinkedCardFieldProperties,
} from 'src/interface/ILinkedCardFields';

export type TCheckBoxOptions =
  | 'isChecked'
  | 'isRequired'
  | 'isEditable'
  | 'isApprovalCriterion';

export interface ILinkedPhaseFieldsTable {
  isNotApprovalType: boolean;
  phaseFields: Array<ICardFields>;
  linkedCardsFieldValues: TLinkedCardFieldProperties;
  handleChangeFieldOptions: (
    phaseId: string,
    fieldId: string,
    propertie: TCheckBoxOptions,
    value: boolean,
  ) => void;
}

export const LinkedPhaseFields = ({
  isNotApprovalType,
  phaseFields,
  handleChangeFieldOptions,
  linkedCardsFieldValues,
}: ILinkedPhaseFieldsTable) => {
  if (!(phaseFields.length > 0)) return null;

  const sortedFields = phaseFields.sort((a, b) => {
    const aExists = linkedCardsFieldValues[a.id];
    const bExists = linkedCardsFieldValues[b.id];
    if (aExists && !bExists) {
      return -1;
    }
    if (!aExists && bExists) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  const keysWithApprovalCriterion = Object.entries(linkedCardsFieldValues)
    .filter(([, value]) => value.isApprovalCriterion)
    .map(([key]) => key);

  return (
    <TableRow className="box-border">
      <TableCell className="bg-white py-2 px-0 m-0">
        <div className="max-h-[300px] min-w-[200px] w-11/12 max-sm:max-w-[300px] my-0 mx-auto overflow-auto rounded-md border box-border shadow">
          <Table>
            <TableHeader className="z-50">
              <TableRow>
                <TableHead className="text-left p-2 h-fit" />
                <TableHead className="text-left p-2 h-fit font-semibold">
                  Campo
                </TableHead>
                <TableHead className="text-center p-2 h-fit font-semibold">
                  Obrigatório
                </TableHead>
                <TableHead className="text-center p-2 h-fit font-semibold">
                  Editável
                </TableHead>
                <TableHead
                  className={`text-center p-2 h-fit font-semibold ${
                    isNotApprovalType && 'hidden'
                  }`}
                >
                  Critério de aprovação
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedFields.map((field: any) => {
                return (
                  <TableRow key={field.id}>
                    <TableCell align="left" className="p-2">
                      <Checkbox
                        id={`checkbox-isChecked-${field.id}`}
                        checked={!!linkedCardsFieldValues?.[field.id]}
                        onCheckedChange={checked =>
                          handleChangeFieldOptions(
                            field.phase.id,
                            field.id,
                            'isChecked',
                            checked as boolean,
                          )
                        }
                        className="m-0 p-0"
                      />
                    </TableCell>
                    <TableCell className="text-left p-2">
                      {field.name}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      <Checkbox
                        id={`checkbox-isRequired-${field.id}`}
                        checked={
                          linkedCardsFieldValues[field.id]?.isRequired || false
                        }
                        onCheckedChange={checked =>
                          handleChangeFieldOptions(
                            field.phase.id,
                            field.id,
                            'isRequired',
                            checked as boolean,
                          )
                        }
                        disabled={
                          !linkedCardsFieldValues[field.id] ||
                          (linkedCardsFieldValues[field.id]?.isRequired &&
                            keysWithApprovalCriterion.includes(field.id))
                        }
                        className="m-0 p-0"
                      />
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      <Checkbox
                        id={`checkbox-isEditable-${field.id}`}
                        checked={
                          linkedCardsFieldValues[field.id]?.isEditable || false
                        }
                        onCheckedChange={checked =>
                          handleChangeFieldOptions(
                            field.phase.id,
                            field.id,
                            'isEditable',
                            checked as boolean,
                          )
                        }
                        disabled={
                          !linkedCardsFieldValues[field.id] ||
                          (linkedCardsFieldValues[field.id]?.isEditable &&
                            keysWithApprovalCriterion.includes(field.id))
                        }
                        className="m-0 p-0"
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`p-2 ${isNotApprovalType && 'hidden'}`}
                    >
                      <Checkbox
                        id={`checkbox-isApprovalCriterion-${field.id}`}
                        checked={
                          linkedCardsFieldValues[field.id]
                            ?.isApprovalCriterion || false
                        }
                        onCheckedChange={checked =>
                          handleChangeFieldOptions(
                            field.phase.id,
                            field.id,
                            'isApprovalCriterion',
                            checked as boolean,
                          )
                        }
                        disabled={
                          !linkedCardsFieldValues[field.id] ||
                          (linkedCardsFieldValues[field.id] &&
                            keysWithApprovalCriterion.length >= 1 &&
                            !keysWithApprovalCriterion.includes(field.id)) ||
                          isNotApprovalType ||
                          field.type === 'TABLE' ||
                          field.type === 'DOCUMENT' ||
                          field.type === 'FILE'
                        }
                        className="m-0 p-0"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </TableCell>
    </TableRow>
  );
};
