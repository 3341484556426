import { useState, useEffect } from 'react';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { COLORS } from 'src/styles/colors';
import { ISigner } from '../Signature.i';
import { useStyles } from '../styles';

interface UseSignatureTableProps {
  signers: ISigner[];
  handleFetchSignatures: () => void;
  signature_id: string;
}

export const useSignatureTable = ({
  signers,
  handleFetchSignatures,
  signature_id,
}: UseSignatureTableProps) => {
  const [signerLogOpen, setSignerLogOpen] = useState<
    {
      id: string;
      open: boolean;
    }[]
  >([]);
  const [signerModalOpen, setSignerModalOpen] = useState<boolean>(false);
  const [currentSigner, setCurrentSigner] = useState<ISigner | undefined>(
    undefined,
  );
  const [page, setPage] = useState(0);
  const rowsPerPage = Number(process.env.ROWS_PER_PAGE) || 10;
  const classes = useStyles();

  const canResendEmail = (
    signerId: string,
    hasSignatureEnded: boolean | undefined,
  ) => {
    const signer = signers.find(s => s.id === signerId);
    return signer ? !signer.hasSigned && hasSignatureEnded === false : false;
  };

  const canResendSignedDocument = (
    hasSignatureEnded: boolean | undefined,
    collectionStarted: boolean | undefined,
  ) => {
    return hasSignatureEnded === true && collectionStarted === true;
  };

  useEffect(() => {
    if (signers && signers.length > 0) {
      const tempSignersOpenLog: {
        id: string;
        open: boolean;
      }[] = [];
      signers.forEach((signer: ISigner) => {
        tempSignersOpenLog.push({
          id: signer.id as string,
          open: false,
        });
      });
      setSignerLogOpen(tempSignersOpenLog);
    }
  }, [signers]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleOpenLog = (signerId: string) => {
    const tempLogState = [...signerLogOpen].map(signerLog => {
      if (signerLog.id === signerId) {
        return {
          id: signerLog.id,
          open: !signerLog.open,
        };
      }
      return signerLog;
    });
    setSignerLogOpen(tempLogState);
  };

  const handleCloseNewUserModal = () => {
    setSignerModalOpen(false);
  };

  const handleDeleteSigner = async (signerId: string) => {
    Swal.fire({
      title: 'Deseja remover signatário?',
      text: 'Essa opção não poderá ser revertida.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: COLORS.GREEN,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        container: classes.swalAlert,
      },
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/signatures/${signature_id}/signers/${signerId}`);
          toast.success('Signatário removido com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          await handleFetchSignatures();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        }
      }
    });
  };

  return {
    signerLogOpen,
    signerModalOpen,
    currentSigner,
    page,
    rowsPerPage,
    setSignerModalOpen,
    setCurrentSigner,
    handleChangePage,
    handleOpenLog,
    handleCloseNewUserModal,
    handleDeleteSigner,
    canResendEmail,
    canResendSignedDocument,
  };
};
