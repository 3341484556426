import React, { useEffect, useState } from 'react';

import {
  TextField,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { InputNumberMask } from '../../../../../components/InputNumberMask';
import { styles } from '../../styles';
import { IDefaultFieldProps } from '../../../Card.i';
import { useCardDataContext } from '../../../../../context/CardDataContext';

const useStyles = makeStyles(() => createStyles(styles()));

export const StringOrNumericField: React.FC<IDefaultFieldProps> = ({
  customField,
  customFieldIndex,
  phaseIndex,
  cardCustomFieldPhaseId,
  handleFillTableValue,
  tableColumn,
  isInCurrentCardPhase,
}: IDefaultFieldProps) => {
  const classes = useStyles();
  const { cardCurrentPhase, handleFillCustomField } = useCardDataContext();
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (customField.value && !tableColumn) setInputValue(customField.value);
    if (tableColumn) setInputValue(tableColumn.value as string);
  }, [customField.value, tableColumn]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    handleFillCustomField(
      phaseIndex,
      customFieldIndex,
      inputValue,
      undefined,
      customField.id,
    );
  };

  const handleBlurTableColumn = () => {
    if (handleFillTableValue && tableColumn)
      handleFillTableValue(
        customField.id,
        tableColumn.tableRowIndex,
        inputValue,
      );
  };

  if (handleFillTableValue && tableColumn) {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={inputValue || ''}
        enterDelay={1500}
        enterNextDelay={1000}
        disableFocusListener
        disableTouchListener
        arrow
        PopperProps={{
          placement: 'bottom-start',
          popperOptions: {
            positionFixed: true,
          },
        }}
      >
        <TextField
          className={`${classes.customField} ${classes.tableTextField}`}
          name={customField.name}
          autoComplete="off"
          required={customField.isRequired && isInCurrentCardPhase}
          disabled={customField.isInactive || customField.isDisabled}
          fullWidth
          type="text"
          onChange={handleInputChange}
          onBlur={handleBlurTableColumn}
          value={inputValue}
          InputProps={{
            disableUnderline: true,
            inputProps:
              customField.type === 'NUMERIC'
                ? {
                    style: {
                      textAlign: 'right',
                      minWidth: '5rem',
                    },
                  }
                : {
                    style: {
                      textAlign: 'left',
                      minWidth: '10rem',
                    },
                  },
            inputComponent:
              customField.type === 'NUMERIC'
                ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (InputNumberMask as any)
                : undefined,
          }}
        />
      </Tooltip>
    );
  }

  return (
    <TextField
      label={customField.name}
      name={customField.name}
      margin="normal"
      required={
        customField.isRequired &&
        cardCurrentPhase?.id === cardCustomFieldPhaseId
      }
      disabled={customField.isInactive || customField.isDisabled}
      autoComplete="off"
      multiline={customField.isBigString}
      minRows={3}
      maxRows={10}
      fullWidth
      onChange={handleInputChange}
      onBlur={handleBlur}
      value={inputValue}
      onKeyDown={e => {
        if (e.key === 'Enter' && !customField.isBigString) e.preventDefault();
      }}
      InputLabelProps={{
        shrink: !!customField.isBigString || undefined,
      }}
      InputProps={{
        inputComponent:
          customField.type === 'NUMERIC'
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (InputNumberMask as any)
            : undefined,
      }}
    />
  );
};
