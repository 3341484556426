import React from 'react';

import { makeStyles, Checkbox, Box } from '@material-ui/core/';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(() => ({
  predefinedOptionsCheckBox: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IAutoCompleteOptionProps {
  option: string;
  selected: boolean;
}

export function CustomRenderCheckboxOption({
  option,
  selected,
}: IAutoCompleteOptionProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Box
        width="fit-content"
        display="flex"
        alignItems="center"
        lineHeight="normal"
        border="border-box"
      >
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          className={classes.predefinedOptionsCheckBox}
          checked={selected}
        />
        {option}
      </Box>
    </>
  );
}
