/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Label } from 'src/components/ui/label';
import { Skeleton } from 'src/components/ui/skeleton';

export const LoadingSkeleton: React.FC = () => {
  return (
    <div className="w-full overflow-auto mb-8 box-border">
      <div className="flex flex-row items-baseline gap-8">
        <div className="flex flex-col p-1 grow">
          <Label htmlFor="name" className="text-sm font-semibold">
            Nome
          </Label>
          <Skeleton className="w-full p-2 mt-2 border border-gray-300 rounded-md" />
        </div>
        <div className="flex flex-col p-1 grow">
          <Label htmlFor="type-radio-group" className="text-sm font-semibold">
            Tipo
          </Label>
          <Skeleton className="w-full p-2 mt-2 border border-gray-300 rounded-md" />
        </div>
      </div>

      <div className="flex flex-col items-start p-1">
        <Label htmlFor="description" className="text-sm font-semibold">
          Descrição
        </Label>
        <Skeleton className="w-full h-32 p-2 mt-2 border border-gray-300 rounded-md" />
      </div>
      <div className="flex flex-col items-start p-1">
        <Label htmlFor="prompts" className="text-sm font-semibold">
          Prompt
        </Label>
        <Skeleton className="w-full h-32 p-2 mt-2 border border-gray-300 rounded-md" />
      </div>
    </div>
  );
};
