/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog as ConfirmationDialog,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core'; // To use for confirmation
import {
  Box,
  Button as ButtonMaterialUI,
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import Button from '../../components/Button';
import api from '../../services/api';
import { InputNumberMask } from '../../components/InputNumberMask';
import IntegrationFilters from './IntegrationFilters';
import {
  IFormData,
  IBpmField,
  ICustomField,
  IIntegrationRule,
  ICustomFieldProps,
  ICustomFieldParams,
  IPhase,
  IFilter,
} from './CustomField.i';
import { styles } from './styles';
import { CustomRenderInputTags } from '../../components/AutoCompleteCustomComponents/CustomRenderInputTags';
import { RichTextEditor } from '../../components/RichTextEditor/ckeditor';
import TableStyleModal, {
  DocumentTableStyles,
  Table,
} from '../../components/ModalTableStyles';
import { FillRuleList } from '../../components/FillRule/FillRuleList';
import {
  IRichTextVariable,
  dateTypes,
  formatCfToRichTextVariables,
  formatSlug,
  generateSlugWithPhase,
  typeFields,
} from '../../utils/customFieldUtils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomField: React.FC<ICustomFieldProps> = ({
  closeModal = () => null,
  areaId,
  phase_id_prop,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<ICustomFieldParams>();
  const { customField_id } = params;
  let { phase_id } = params;
  if (phase_id_prop) {
    phase_id = phase_id_prop;
  }
  const area_id = history.location.state;

  const goBack = () => {
    if (!customField_id) {
      closeModal();
    } else {
      history.goBack();
    }
  };
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [customField, setCustomField] = useState<ICustomField>();
  const [typeCustomFieldSelected, setTypeCustomFieldSelected] = useState('');
  const [integrates, setIntegrates] = useState<IBpmField[]>([]);
  const [integrationRules, setIntegrationRules] = useState<any>();
  const [loadingRules, setLoadingRules] = useState<boolean>(true);
  const [phase, setPhase] = useState<{
    name: string;
    areaId: string;
  } | null>(null);
  const [areaPhases, setAreaPhases] = useState<IPhase[]>([]);
  const [selectedPhases, setSelectedPhases] = useState<IPhase[]>([]);

  const [loading, setLoading] = useState(true);
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [isFillRulesOpen, setIsFillRuleOpens] = useState(false);
  const [cfTables, setCfTables] = useState<Table[]>([]);
  const [belongsToTable, setBelongsToTable] = useState(false);
  const [selectedTable, setSelectedTable] = useState();
  const [predefinedOptions, setPredefinedOptions] = useState<string[]>([]);
  const [newPredefinedOption, setNewPredefinedOption] = useState('');
  const [fetchFieldsCompleted, setFetchFieldsCompleted] = useState(false);
  const [templateFieldOptions, setTemplateFieldOptions] = useState<
    IRichTextVariable[]
  >([] as IRichTextVariable[]);
  const [template, setTemplate] = useState<string>('');
  const [tableStyles, setTableStyles] = useState<DocumentTableStyles>({});
  const [header, setHeader] = useState<string>('');
  const [footer, setFooter] = useState<string>('');

  useEffect(() => {
    if (customField_id) {
      fetchCustomField();
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customField_id, integrationRules]);

  useEffect(() => {
    if (areaId || area_id) {
      const area = areaId || area_id;
      api.get(`/areas/${area}/customFields`).then(response => {
        const filteredFields: ICustomField[] = response.data.filter(
          (field: { slug: string; name: string; type: string }) =>
            field?.type !== 'DOCUMENT' && field?.type !== 'FILE',
        );
        const formattedSugestions = formatCfToRichTextVariables(filteredFields);
        setTemplateFieldOptions(formattedSugestions);
        setFetchFieldsCompleted(true);
        const filteredTables = response.data.filter(
          (field: { type: string }) => field.type === 'TABLE',
        );
        setCfTables(
          filteredTables.map((tableField: ICustomField) => {
            return {
              id: tableField.id,
              name: tableField.name,
              columns: response.data
                .filter(
                  (field: ICustomField) =>
                    field.table !== null && field.table.id === tableField.id,
                )
                .map((columnField: ICustomField) => columnField.name),
            };
          }),
        );
      });

      api
        .get(`areas/${area}/phases`)
        .then(response => {
          if (response.data) {
            const tempPhases = response.data
              .filter((resp: IPhase) => !resp.isInactive)
              .sort((a: IPhase, b: IPhase) => a.order < b.order);
            setAreaPhases(tempPhases);
            setUnsavedChanges(false);
          }
        })
        .catch(err => {
          toast.error(`Falha ao carregar fases. ${err.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, area_id]);

  const handleToggleBelongsToTable = () => {
    if (belongsToTable) {
      formik.setFieldValue('customFieldTable_id', '');
    }

    setBelongsToTable(!belongsToTable);
  };

  const initialValues: IFormData = {
    phase_id: customField ? customField.phase_id : '',
    name: customField ? customField.name : '',
    slug: customField ? customField.slug : '',
    typeField: customField ? customField.type : null,
    isRequired: customField ? customField.isRequired : false,
    totalize: customField ? customField.totalize : false,
    isInactive: customField ? customField.isInactive : false,
    weight: customField ? customField.weight : 1,
    tableOrder:
      customField && customField.tableOrder ? customField.tableOrder : 1,
    integrationRule: customField ? customField.integrationRule : null,
    customFieldTable: customField ? customField.table : null,
    isBigString: customField ? customField.isBigString : false,
    predefinedValues: undefined,
    integratedFilterAssociations: customField
      ? customField.integratedFilterAssociations
      : [],
    documentTemplate:
      customField && customField?.document && template ? template : '',
    documentFooter:
      customField && customField?.document && footer ? footer : '',
    documentHeader:
      customField && customField?.document && header ? header : '',
    documentTableStyles:
      customField && customField?.document ? tableStyles : {},
    isEditable:
      customField && customField?.document
        ? customField.document.isEditable
        : false,
    isEncrypted:
      customField && customField?.document
        ? customField.document.isEncrypted
        : customField && customField?.file
        ? customField?.file.isEncrypted
        : false,
    isRequiredToSign:
      customField && customField?.document
        ? customField.document.isRequiredToSign
        : customField && customField?.file
        ? customField?.file.isRequiredToSign
        : false,
    fileAllowsMultiple:
      customField && customField?.file
        ? customField.file.allowsMultiple
        : false,
    phasesToTotalize:
      customField && customField.totalize ? customField.phasesToTotalize : [],
    dateType:
      customField && customField.dateType
        ? customField.dateType
        : dateTypes[0].id,
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('Nome é obrigatório')
      .min(2, 'O nome deve ter pelo menos 2 caracteres'),
    slug: Yup.string()
      .required('Apelido é obrigatório')
      .min(
        6,
        'O apelido deve ter pelo menos 6 caracteres e não deve contar caracteres especiais',
      ),
    weight: Yup.number().required('Peso é obrigatório'),
    typeField: Yup.object().required('Tipo do campo é obrigatório').nullable(),
    dateType:
      typeCustomFieldSelected === 'TIMESTAMP'
        ? Yup.string().required('É necessário informar ao menos um valor')
        : Yup.string().optional(),
    predefinedValues:
      typeCustomFieldSelected === 'PREDEFINED_STRINGS' &&
      !customField?.predefinedValues
        ? Yup.array().required('É necessário informar ao menos um valor').min(1)
        : Yup.array().optional(),
  });

  const onSubmit = async (values: IFormData, { setSubmitting }: any) => {
    setSubmitting(true);
    try {
      const reqBody: any = {
        name: values.name,
        slug: values.slug,
        isRequired: values.isRequired,
        isInactive: values.isInactive,
        weight: values.weight,
        predefinedValues: values.predefinedValues,
      };

      if (typeCustomFieldSelected !== 'TABLE') {
        if (belongsToTable) {
          reqBody.customFieldTable_id = values.customFieldTable.id;
        } else reqBody.customFieldTable_id = '';
        if (values.tableOrder) reqBody.tableOrder = values.tableOrder;
      }
      if (typeCustomFieldSelected === 'TIMESTAMP')
        reqBody.dateType = values.dateType;

      if (typeCustomFieldSelected === 'NUMERIC')
        reqBody.totalize = values.totalize;

      if (typeCustomFieldSelected === 'NUMERIC' && values.totalize)
        reqBody.phasesToTotalize = values.phasesToTotalize;

      if (typeCustomFieldSelected === 'STRING')
        reqBody.isBigString = values.isBigString;

      if (typeCustomFieldSelected === 'PREDEFINED_STRINGS')
        reqBody.predefinedValues = values.predefinedValues;
      else reqBody.predefinedValues = undefined;

      if (
        typeCustomFieldSelected === 'INTEGRATED' &&
        values.integrationRule.id !== ''
      ) {
        if (filters) {
          reqBody.integratedFilterAssociations = filters
            .filter(filter => filter.isActive)
            .map(filter => {
              return {
                filterCode: filter.filterCode,
                associatedCustomField_id: filter.associatedCustomField_id
                  ? filter.associatedCustomField_id
                  : undefined,
                fixedValue: filter.fixedValue ? filter.fixedValue : undefined,
              };
            });
        }
      }

      if (typeCustomFieldSelected === 'DOCUMENT') {
        reqBody.documentTemplate = template;
        reqBody.documentIsEncrypted = values.isEncrypted;
        reqBody.documentIsEditable = values.isEditable;
        reqBody.documentIsRequiredToSign = values.isRequiredToSign;
        reqBody.documentTableStyles = tableStyles;
        reqBody.documentHeader = header;
        reqBody.documentFooter = footer;
      }

      if (typeCustomFieldSelected === 'FILE') {
        reqBody.fileIsEncrypted = values.isEncrypted;
        reqBody.fileAllowsMultiple = values.fileAllowsMultiple;
        reqBody.fileIsRequiredToSign = values.isRequiredToSign;
      }

      if (customField_id) {
        await api.put(`/customFields/${customField_id}`, reqBody).then(() => {
          if (typeCustomFieldSelected === 'PREDEFINED_STRINGS') {
            setCustomField((oldValue: any) => {
              return {
                ...oldValue,
                predefinedValues:
                  values.predefinedValues ?? oldValue?.predefinedValues ?? [],
              };
            });
          }
        });

        toast.success('Campo personalizado editado com sucesso.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      } else {
        reqBody.type_field = values.typeField.id;
        if (values.integrationRule) {
          reqBody.integrationRule_id = values.integrationRule.id;
        }
        await api.post(`phases/${phase_id}/customFields`, reqBody);
        toast.success('Campo personalizado salvo com sucesso.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
        setTimeout(() => {
          closeModal();
        }, 2000);
      }
      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);

      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
        autoClose: 5000,
      });
    }
  };

  const handleSubmitWrapper = async () => {
    await onSubmit(formik.values, { setSubmitting: formik.setSubmitting });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit,
    // enableReinitialize: !!customField_id,
  });

  // CONTROLE DE MUDANÇAS NÃO SALVAS
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState('');

  useEffect(() => {
    if (customField !== undefined && formik.dirty) {
      setUnsavedChanges(true);
    }
  }, [formik.values]);

  useEffect(() => {
    const unblock = history.block(location => {
      if (unsavedChanges) {
        setNextLocation(location.pathname);
        setConfirmationModalOpen(true);
        return false;
      }
      return undefined;
    });

    return () => {
      if (unblock) unblock();
    };
  }, [unsavedChanges]);

  useEffect(() => {
    if (!unsavedChanges && nextLocation) {
      history.push(nextLocation);
    }
  }, [unsavedChanges, nextLocation]);

  // FIM CONTROLE DE MUDANÇAS NÃO SALVAS

  const fetchPhaseAreaId = useCallback(async () => {
    if (phase_id !== undefined) {
      const response = await api.get(`phases/${phase_id}`);
      setPhase({
        name: response.data.name,
        areaId: response.data.area.id,
      });
    } else {
      setPhase(null);
    }
  }, [phase_id]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    fetchPhaseAreaId().catch(console.error);

    api.get('/integrationsrules/integrationtypes').then(response => {
      const newIntegrationRules: IIntegrationRule[] = response.data.filter(
        // eslint-disable-next-line no-return-assign
        (item: IIntegrationRule) =>
          item.integrationType.allowIntegratedCustomFields &&
          item.isActive &&
          (item.integrationType.areaRequired
            ? item.area_id === phase?.areaId
            : true),
      );

      setIntegrationRules(newIntegrationRules);
      setLoadingRules(false);
    });
  }, [fetchPhaseAreaId, phase?.areaId]);

  const fetchCustomFieldFillRules = useCallback(async () => {
    if (customField_id) {
      api
        .get(`customFields/${customField_id}`)
        .then(response => {
          const { fillRules } = response.data;
          setCustomField(oldValue => ({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...oldValue!,
            fillRules: fillRules || [],
          }));
        })
        .catch(err => {
          toast.error(
            `Falha atualizar regras de preenchimento. ${err.response.data.message}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 4000,
            },
          );
        });
    }
  }, [customField_id]);

  const fetchCustomField = useCallback(async () => {
    if (customField_id) {
      await api.get(`customFields/${customField_id}`).then(response => {
        setCustomField(response.data);
        if (
          response.data.predefinedValues !== undefined &&
          response.data?.predefinedValues?.length > 0
        ) {
          setPredefinedOptions(response.data.predefinedValues);
        }
        if (response.data.table) {
          setBelongsToTable(true);
        }
        if (response.data?.document) {
          setTemplate(response.data?.document.template);
          if (response.data?.document.tableStyles) {
            setTableStyles(response.data?.document.tableStyles);
          }
          if (response.data?.document.header) {
            setHeader(response.data?.document.header);
          }
          if (response.data?.document.footer) {
            setFooter(response.data?.document.footer);
          }
        }
        if (response.data?.phasesToTotalize) {
          const fieldTotalizePhases = response.data?.phasesToTotalize;
          const formattedPhases = areaPhases.filter(ap =>
            fieldTotalizePhases.includes(ap.id),
          );
          setSelectedPhases(formattedPhases);
        }

        if (
          response.data.integrationRule &&
          integrationRules &&
          response.data.integratedFilterAssociations
        ) {
          try {
            const selectedIntegrationRule = integrationRules.find(
              (element: { id: any }) =>
                element.id === response.data.integrationRule.id,
            )?.integrationType.filterFields;
            if (selectedIntegrationRule) {
              const allFilters = Object.keys(selectedIntegrationRule);
              const presetFilters = response.data.integratedFilterAssociations;
              const completeFilters = allFilters.map(filter => {
                const preset = presetFilters.find(
                  (presetFilter: { filterCode: string }) =>
                    presetFilter.filterCode === filter,
                );
                if (preset && preset.fixedValue) {
                  return {
                    isActive: true,
                    filterCode: filter,
                    fixedValue: preset.fixedValue,
                  };
                }
                if (preset && preset.associatedCustomField) {
                  return {
                    isActive: true,
                    filterCode: filter,
                    associatedCustomField_id: preset.associatedCustomField.id,
                  };
                }
                return {
                  isActive: false,
                  filterCode: filter,
                };
              });
              setFilters(completeFilters);
            }
          } catch (e) {
            console.error(e);
          }
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRules, customField_id]);

  useEffect(() => {
    fetchCustomField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customField_id, phase_id]);

  useEffect(() => {
    if (customField) {
      setTypeCustomFieldSelected(customField.type.id);
      const type = 'TABLE';
      const phaseId = customField?.phase?.id
        ? customField?.phase?.id
        : phase_id;
      api.get(`customFields/type/${type}/phases/${phaseId}`).then(response => {
        setIntegrates(response.data);
      });
    }
  }, [customField, phase_id]);

  useEffect(() => {
    if (phase_id) {
      if (belongsToTable) {
        api.get(`customFields/type/TABLE/phases/${phase_id}`).then(response => {
          const newIsInactive = response.data.filter(
            // eslint-disable-next-line no-return-assign
            (item: IBpmField) => item.isInactive === false,
          );
          setIntegrates(newIsInactive);
        });
      }
    }
  }, [belongsToTable, phase_id]);

  return (
    <Container
      maxWidth={false}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        display: 'flex',
      }}
    >
      {/* Modal de confirmação de mudanças não salvas */}
      <ConfirmationDialog
        open={confirmationModalOpen}
        onClose={() => setUnsavedChanges(false)}
      >
        <DialogTitle>Mudanças não salvas</DialogTitle>
        <DialogContent>
          <Typography>
            Você tem mudanças não salvas. Deseja sair sem salvar?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmationModalOpen(false);
            }}
          >
            Ficar
          </Button>
          <ButtonMaterialUI
            className={classes.buttonSubmit}
            disabled={formik.isSubmitting || loading}
            size="large"
            variant="contained"
            color="inherit"
            onClick={() => {
              setConfirmationModalOpen(false);
              setUnsavedChanges(false);
              if (nextLocation) {
                history.push(nextLocation);
              }
            }}
          >
            Sair sem salvar
          </ButtonMaterialUI>
        </DialogActions>
      </ConfirmationDialog>
      <Box className={classes.content}>
        {customField_id ? (
          <Box>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Editar Campo Personalizado
            </Typography>
          </Box>
        ) : (
          <div />
        )}
        <Box>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" alignItems="start">
              <TextField
                style={{
                  width: customField_id ? '80%' : undefined,
                  maxWidth: '550px',
                }}
                id="name"
                label="Nome"
                name="name"
                autoComplete="name"
                margin="normal"
                autoFocus
                fullWidth
                onChange={formik.handleChange}
                onBlur={event => {
                  const formattedValue = generateSlugWithPhase(
                    event.target.value,
                    phase?.name || '',
                  );
                  if (event.target.value && !formik.values.slug)
                    formik.setFieldValue('slug', formattedValue);
                }}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                style={{
                  width: customField_id ? '80%' : undefined,
                  maxWidth: '550px',
                }}
                id="slug"
                label="Apelido"
                name="slug"
                margin="normal"
                fullWidth
                onChange={event => {
                  const formattedValue = formatSlug(event.target.value);
                  formik.setFieldValue('slug', formattedValue);
                }}
                onKeyDown={event => {
                  if (event.key === ' ' || !/[a-zA-Z0-9_.-]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={formik.values.slug}
                error={formik.touched.slug && Boolean(formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
              />
              <TextField
                style={{
                  width: customField_id ? '80%' : undefined,
                  maxWidth: '550px',
                }}
                id="weight"
                label="Peso"
                name="weight"
                margin="normal"
                onChange={formik.handleChange}
                value={formik.values.weight}
                InputProps={{
                  inputComponent: InputNumberMask as any,
                }}
                fullWidth
              />

              <Autocomplete
                style={{
                  width: customField_id ? '80%' : undefined,
                  maxWidth: '550px',
                }}
                id="typeField"
                disableClearable
                getOptionLabel={typeField => typeField.label}
                getOptionSelected={(typeField, value) =>
                  typeField.id === value.id
                }
                options={typeFields}
                disabled={!!customField_id}
                fullWidth
                defaultValue={typeFields[0]}
                value={formik.values.typeField}
                onChange={(e, value) => {
                  setTypeCustomFieldSelected(value ? value.id : '');

                  formik.setFieldValue(
                    'typeField',
                    value !== null ? value : initialValues.typeField,
                  );
                  formik.setFieldValue('integrationRule', null);
                }}
                loadingText="Carregando"
                renderInput={rest => (
                  <TextField
                    {...rest}
                    id="typeField"
                    label="Tipos de Campo"
                    margin="normal"
                    error={
                      formik.touched.typeField &&
                      Boolean(formik.errors.typeField)
                    }
                    helperText={
                      formik.touched.typeField && formik.errors.typeField
                    }
                    InputProps={{
                      ...rest.InputProps,
                    }}
                  />
                )}
              />

              {typeCustomFieldSelected === 'TIMESTAMP' && (
                <Autocomplete
                  id="dateType"
                  style={{
                    width: customField_id ? '80%' : undefined,
                    maxWidth: '550px',
                  }}
                  disableClearable
                  getOptionLabel={opt => opt.label}
                  getOptionSelected={(opt, value) => opt.id === value.id}
                  options={dateTypes}
                  disabled={!!customField_id}
                  fullWidth
                  value={
                    dateTypes[
                      dateTypes.findIndex(
                        dt => dt.id === formik.values.dateType,
                      )
                    ]
                  }
                  onChange={(e, value) => {
                    formik.setFieldValue(
                      'dateType',
                      value !== null ? value.id : initialValues.dateType,
                    );
                  }}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="dateType"
                      label="Tipos de tempo"
                      margin="normal"
                      error={
                        formik.touched.dateType &&
                        Boolean(formik.errors.dateType)
                      }
                      helperText={
                        formik.touched.dateType && formik.errors.dateType
                      }
                      InputProps={{
                        ...rest.InputProps,
                      }}
                    />
                  )}
                />
              )}

              {typeCustomFieldSelected === 'INTEGRATED' && (
                <>
                  {loadingRules ? (
                    <Typography> Loading ... </Typography>
                  ) : (
                    <>
                      <Autocomplete
                        style={{
                          width: customField_id ? '80%' : undefined,
                          maxWidth: '550px',
                        }}
                        id="integrationRule"
                        disableClearable
                        getOptionLabel={rule => rule.description}
                        getOptionSelected={(rule, value) =>
                          rule.id === value.id
                        }
                        options={integrationRules}
                        disabled={customField && !!customField.integrationRule}
                        defaultValue={integrationRules[0]}
                        value={formik.values.integrationRule}
                        fullWidth
                        loadingText="Carregando"
                        onChange={(e, value) => {
                          if (!customField_id) {
                            const arr = Object.keys(
                              value.integrationType.filterFields,
                            );
                            const mapFilters = arr.map(element => {
                              return {
                                filterCode: element,
                                isActive: false,
                              };
                            });

                            setFilters(mapFilters);
                          }

                          formik.setFieldValue(
                            'integrationRule',
                            value !== null
                              ? value
                              : initialValues.integrationRule,
                          );
                        }}
                        renderInput={rest => (
                          <TextField
                            {...rest}
                            id="integrationRule_text"
                            label="Regra de integração"
                            margin="normal"
                            error={
                              formik.touched.integrationRule &&
                              Boolean(formik.errors.integrationRule)
                            }
                            helperText={
                              formik.touched.integrationRule &&
                              formik.errors.integrationRule
                            }
                            InputProps={{
                              ...rest.InputProps,
                            }}
                          />
                        )}
                      />
                      <Box className={classes.integrationFilters}>
                        {!customField_id ? (
                          <Typography
                            className={classes.disabledIntegrationFilters}
                            style={{ color: '#cbcbcb' }}
                          >
                            Filtros disponíveis na tela editar
                          </Typography>
                        ) : (
                          <IntegrationFilters
                            style={{
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                            selectedTable={selectedTable}
                            phase={phase_id}
                            filters={filters}
                            id={customField_id}
                            setFilters={setFilters}
                          />
                        )}
                      </Box>
                    </>
                  )}
                </>
              )}

              {typeCustomFieldSelected === 'PREDEFINED_STRINGS' && (
                <Autocomplete
                  style={{
                    width: customField_id ? '80%' : undefined,
                    maxWidth: '550px',
                  }}
                  id="predifined_strings"
                  multiple
                  freeSolo
                  limitTags={6}
                  options={predefinedOptions}
                  getOptionSelected={(predefinedOptions, value) =>
                    predefinedOptions === value
                  }
                  fullWidth
                  onChange={(e, value) => {
                    setPredefinedOptions(value);
                    setNewPredefinedOption('');
                    formik.setFieldValue(
                      'predefinedValues',
                      value !== null ? value : initialValues.predefinedValues,
                    );
                  }}
                  value={predefinedOptions}
                  inputValue={newPredefinedOption}
                  onInputChange={(e, value, reason) => {
                    if (reason === 'input') {
                      setNewPredefinedOption(value);
                    }
                  }}
                  loadingText="Carregando"
                  renderInput={rest => (
                    <TextField
                      {...rest}
                      id="predifined_strings"
                      label="Valores Predefinidos"
                      margin="normal"
                      type="text"
                      placeholder="Inserir um novo valor..."
                      error={
                        formik.touched.predefinedValues &&
                        Boolean(formik.errors.predefinedValues)
                      }
                      helperText={
                        formik.touched.predefinedValues &&
                        formik.errors.predefinedValues
                      }
                      InputProps={{
                        ...rest.InputProps,
                      }}
                    />
                  )}
                />
              )}

              {customField?.id && (
                <>
                  <Box
                    style={{
                      width: customField?.id ? '80%' : undefined,
                      maxWidth: '550px',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                      marginBottom: '10px',
                      marginRight: '30px',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => setIsFillRuleOpens(!isFillRulesOpen)}
                  >
                    <Typography>Regras de preenchimento</Typography>
                    {isFillRulesOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  </Box>
                  <Divider
                    style={{
                      width: customField?.id ? '80%' : undefined,
                      maxWidth: '550px',
                      marginBottom: '10px',
                    }}
                  />
                </>
              )}
              {isFillRulesOpen && customField?.id && (
                <>
                  <FillRuleList
                    handleRefreshCf={fetchCustomFieldFillRules}
                    customField={{
                      id: customField.id,
                      name: customField.name,
                      type: customField.type.id,
                      predefined_values: customField?.predefinedValues,
                      areaId: customField.area.id,
                      phase: {
                        id: customField.phase.id,
                        order: customField.phase.order,
                      },
                      fillRules: customField.fillRules,
                    }}
                    phases={areaPhases}
                    handleSubmitWrapper={handleSubmitWrapper}
                  />
                </>
              )}

              {typeCustomFieldSelected === 'DOCUMENT' && (
                <>
                  <Box mt={1} width="100%" className={classes.templateTitle}>
                    Template do documento
                  </Box>
                  <Accordion
                    style={{
                      width: '550px',
                      minWidth: '300px',
                      marginBottom: '10px',
                    }}
                  >
                    <AccordionSummary expandIcon={<ArrowDropDown />}>
                      Cabeçalho e Rodapé
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                        }}
                      >
                        <Typography
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            marginBottom: '10px',
                          }}
                        >
                          Cabeçalho
                        </Typography>
                        <RichTextEditor
                          propData={header}
                          setPropData={(data: string) => {
                            setHeader(data);
                            setUnsavedChanges(true);
                          }}
                          toolbarHidden
                          suggestionsProps={[]} // headerAndFooterFields
                          small
                        />
                        <Divider
                          style={{
                            width: '100%',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        />
                        <Typography
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            marginBottom: '10px',
                          }}
                        >
                          Rodapé
                        </Typography>
                        <RichTextEditor
                          propData={footer}
                          setPropData={(data: string) => {
                            setFooter(data);
                            setUnsavedChanges(true);
                          }}
                          toolbarHidden
                          suggestionsProps={[]} // headerAndFooterFields
                          small
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  {fetchFieldsCompleted ? (
                    <>
                      <RichTextEditor
                        propData={template}
                        setPropData={(data: string) => {
                          setTemplate(data);
                          setUnsavedChanges(true);
                        }}
                        suggestionsProps={
                          templateFieldOptions.length > 0
                            ? templateFieldOptions
                            : []
                        }
                        toolbarHidden={false}
                        small={false}
                      />
                      <Button
                        onClick={() => setIsTableModalOpen(!isTableModalOpen)}
                      >
                        Estilos de tabela
                      </Button>
                      <TableStyleModal
                        isTableModalOpen={isTableModalOpen}
                        setIsTableModalOpen={setIsTableModalOpen}
                        tables={cfTables}
                        tableStyles={tableStyles}
                        setTableStyles={(value: DocumentTableStyles) => {
                          setTableStyles(value);
                          setUnsavedChanges(true);
                        }}
                      />
                    </>
                  ) : (
                    <>Loading ...</>
                  )}
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    name="isRequired"
                    onChange={formik.handleChange}
                    checked={formik.values.isRequired}
                  />
                }
                label="Campo obrigatório"
              />

              {typeCustomFieldSelected !== 'TABLE' &&
                typeCustomFieldSelected !== 'FILE' &&
                typeCustomFieldSelected !== 'DOCUMENT' && (
                  <Box className={classes.belongsToTableInput}>
                    {!loading && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="tabela"
                            checked={belongsToTable}
                            onChange={handleToggleBelongsToTable}
                          />
                        }
                        label="Pertence a uma tabela"
                      />
                    )}
                    {belongsToTable && (
                      <>
                        <Autocomplete
                          style={{
                            minWidth: '200px',
                            maxWidth: '550px',
                          }}
                          disableClearable
                          id="customFieldTable"
                          getOptionLabel={chosenTable => chosenTable.name}
                          getOptionSelected={(currentTable, value) =>
                            currentTable.id === value.id
                          }
                          options={integrates}
                          value={
                            formik.values.customFieldTable
                              ? formik.values.customFieldTable
                              : customField && customField.table
                              ? customField.table
                              : null
                          }
                          onChange={(e, value) => {
                            formik.setFieldValue('customFieldTable', value);
                            setSelectedTable(value);
                          }}
                          loadingText="Carregando"
                          renderInput={rest => (
                            <TextField
                              {...rest}
                              id="customFieldTable_id"
                              label="Tabelas Disponíveis"
                              margin="dense"
                              error={
                                formik.touched.customFieldTable &&
                                Boolean(formik.errors.customFieldTable)
                              }
                              helperText={
                                formik.touched.customFieldTable &&
                                formik.errors.customFieldTable
                              }
                              InputProps={{
                                ...rest.InputProps,
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                  </Box>
                )}

              {typeCustomFieldSelected === 'NUMERIC' ? (
                <Box className={classes.belongsToTableInput}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="totalize"
                        onChange={formik.handleChange}
                        checked={formik.values.totalize}
                      />
                    }
                    label="Totalizar"
                  />
                  {formik.values.totalize && (
                    <Autocomplete
                      style={{
                        minWidth: '200px',
                        maxWidth: '350px',
                      }}
                      disableClearable
                      id="phasesToTotalize"
                      getOptionLabel={chosenPhase => chosenPhase.name}
                      getOptionSelected={(currentPhase, value) =>
                        currentPhase.id === value.id
                      }
                      options={areaPhases}
                      value={selectedPhases}
                      onChange={(e, value) => {
                        setSelectedPhases(value);
                        const formattedValue: string[] = [];

                        value.forEach(phase => {
                          formattedValue.push(phase.id);
                        });
                        formik.setFieldValue(
                          'phasesToTotalize',
                          formattedValue,
                        );
                      }}
                      multiple
                      loadingText="Carregando"
                      disableCloseOnSelect
                      limitTags={1}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            className={classes.checkBoxAutoComplete}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </>
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return (
                          <CustomRenderInputTags
                            tagValue={tagValue}
                            getTagProps={getTagProps}
                            size="medium"
                          />
                        );
                      }}
                      renderInput={rest => (
                        <TextField
                          {...rest}
                          id="phasesToTotalize"
                          label="Fases"
                          margin="normal"
                          InputProps={{
                            ...rest.InputProps,
                            style: {
                              display: 'flex',
                              flexWrap: 'nowrap',
                            },
                          }}
                          error={
                            formik.touched.phasesToTotalize &&
                            Boolean(formik.errors.phasesToTotalize)
                          }
                          helperText={
                            formik.touched.phasesToTotalize &&
                            formik.errors.phasesToTotalize
                          }
                        />
                      )}
                    />
                  )}
                </Box>
              ) : (
                <div />
              )}

              {typeCustomFieldSelected === 'DOCUMENT' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isEditable"
                      onChange={formik.handleChange}
                      checked={formik.values.isEditable}
                    />
                  }
                  label="Permite edição do template"
                />
              )}

              {(typeCustomFieldSelected === 'DOCUMENT' ||
                typeCustomFieldSelected === 'FILE') && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isEncrypted"
                        onChange={formik.handleChange}
                        checked={formik.values.isEncrypted}
                        disabled
                      />
                    }
                    label="Criptografar arquivo"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isRequiredToSign"
                        onChange={formik.handleChange}
                        checked={formik.values.isRequiredToSign}
                        disabled={formik.values.fileAllowsMultiple}
                      />
                    }
                    label="Exige assinatura eletrônica"
                  />
                </>
              )}

              {typeCustomFieldSelected === 'FILE' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fileAllowsMultiple"
                      onChange={formik.handleChange}
                      checked={formik.values.fileAllowsMultiple}
                      disabled={formik.values.isRequiredToSign}
                    />
                  }
                  label="Permite múltiplos arquivos"
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    name="isInactive"
                    onChange={formik.handleChange}
                    checked={formik.values.isInactive}
                  />
                }
                label="Inativar"
              />

              {typeCustomFieldSelected === 'STRING' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isBigString"
                      onChange={formik.handleChange}
                      checked={formik.values.isBigString}
                    />
                  }
                  label="Múltiplas linhas"
                />
              )}
              <Box mb={15}>
                <ButtonMaterialUI
                  className={classes.buttonSubmit}
                  disabled={formik.isSubmitting}
                  size="large"
                  variant="contained"
                  color="inherit"
                  onClick={goBack}
                >
                  Voltar
                </ButtonMaterialUI>
                <Button
                  className={classes.buttonSubmit}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setUnsavedChanges(false);
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default CustomField;
