/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SearchFileIcon(props: any): JSX.Element {
  return (
    <SvgIcon
      {...props}
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 109.773 122.879"
    >
      <g>
        <path d="M82.42,73.514c5.941,0,11.33,2.418,15.232,6.305c3.903,3.904,6.306,9.277,6.306,15.234c0,4.346-1.296,8.406-3.509,11.787 l9.324,10.162l-6.432,5.877l-8.992-9.891c-3.413,2.275-7.521,3.602-11.93,3.602c-5.941,0-11.33-2.416-15.233-6.305 c-3.903-3.902-6.306-9.275-6.306-15.232c0-5.941,2.418-11.33,6.306-15.234C71.09,75.916,76.462,73.514,82.42,73.514L82.42,73.514z M28.849,79.506c-1.588,0-2.876-1.318-2.876-2.906c0-1.617,1.289-2.904,2.876-2.904h18.753c1.588,0,2.876,1.316,2.876,2.904 c0,1.619-1.288,2.906-2.876,2.906H28.849L28.849,79.506z M28.849,38.645c-1.588,0-2.876-1.288-2.876-2.876 c0-1.587,1.289-2.875,2.876-2.875h38.045c1.588,0,2.876,1.288,2.876,2.875c0,1.588-1.288,2.876-2.876,2.876H28.849L28.849,38.645z M51.455,106.588c1.588,0,2.876,1.287,2.876,2.875s-1.288,2.877-2.876,2.877H6.711c-1.857,0-3.535-0.75-4.733-1.979 C0.749,109.164,0,107.486,0,105.629V6.71c0-1.857,0.749-3.535,1.978-4.733C3.206,0.749,4.854,0,6.711,0h82.292 c1.857,0,3.535,0.749,4.733,1.978c1.228,1.228,1.977,2.875,1.977,4.733v58.372c0,1.588-1.288,2.877-2.876,2.877 c-1.587,0-2.876-1.289-2.876-2.877V6.71c0-0.27-0.119-0.479-0.27-0.659c-0.18-0.18-0.419-0.27-0.658-0.27H6.741 c-0.27,0-0.479,0.12-0.659,0.27c-0.18,0.18-0.27,0.419-0.27,0.659v98.918c0,0.27,0.12,0.479,0.27,0.658 c0.18,0.18,0.419,0.27,0.659,0.27h44.744L51.455,106.588L51.455,106.588z M28.849,59.075c-1.588,0-2.876-1.288-2.876-2.875 c0-1.588,1.289-2.876,2.876-2.876h38.045c1.588,0,2.876,1.288,2.876,2.876c0,1.587-1.288,2.875-2.876,2.875H28.849L28.849,59.075z M94.811,82.66c-3.17-3.17-7.556-5.139-12.391-5.139c-4.836,0-9.222,1.969-12.392,5.139c-3.171,3.172-5.14,7.557-5.14,12.393 c0,4.834,1.969,9.221,5.14,12.391c3.17,3.17,7.556,5.139,12.392,5.139c4.835,0,9.221-1.969,12.391-5.139 c3.171-3.17,5.14-7.557,5.14-12.391C99.95,90.217,97.981,85.832,94.811,82.66L94.811,82.66L94.811,82.66z" />
      </g>
    </SvgIcon>
  );
}
