import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  createStyles,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputKeyCardMask } from 'src/components/InputKeyCardMask';
import { Button } from 'src/components/ui/button';
import api from '../../../services/api';
import { IAccount, IContact, IUser, ModalProps, EntityType } from '../Filter.i';
import { styles } from './style';
import { useFilters } from '../../../context/FilterContext';
import { useTempState } from './hooks/useTempState';
import CustomAutoComplete from './components/CustomAutocomplete';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export function FilterModal({
  open,
  closeModal,
  title,
  titleLeft,
  selectedArea,
}: ModalProps): JSX.Element {
  const classes = useStyles();

  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const {
    handleFilters,
    storedAccountIds,
    storedContactIds,
    storedUserIds,
    storedCardKey,
  } = useFilters();

  const selectedAccountsState = useTempState<IAccount[]>([]);
  const selectedContactsState = useTempState<IContact[]>([]);
  const selectedUsersState = useTempState<IUser[]>([]);
  const cardKeyState = useTempState<string>('');

  const applyFilters = () => {
    handleFilters({
      account_ids: selectedAccountsState.tempState.map(a => a.id),
      contact_ids: selectedContactsState.tempState.map(c => c.id),
      user_ids: selectedUsersState.tempState.map(u => u.id),
      card_key: cardKeyState.tempState,
    });
    closeModal(true);
  };

  const resetFilters = () => {
    selectedAccountsState.resetTempState();
    selectedContactsState.resetTempState();
    selectedUsersState.resetTempState();
    cardKeyState.resetTempState();
    handleFilters({
      account_ids: [],
      contact_ids: [],
      user_ids: [],
      card_key: '',
    });
    closeModal(true);
  };

  useEffect(() => {
    if (
      storedAccountIds.length > 0 &&
      selectedAccountsState.tempState.length === 0
    ) {
      const storedAccounts = accounts.filter(a =>
        storedAccountIds.includes(a.id),
      );
      selectedAccountsState.setTempState(storedAccounts);
    }
    if (
      storedContactIds.length > 0 &&
      selectedContactsState.tempState.length === 0 &&
      contacts.length > 0
    ) {
      const storedContacts = contacts.filter(a =>
        storedContactIds.includes(a.id),
      );
      selectedContactsState.setTempState(storedContacts);
    }
    if (storedUserIds.length > 0 && selectedUsersState.tempState.length === 0) {
      const storedUsers = users.filter(a => storedUserIds.includes(a.id));
      selectedUsersState.setTempState(storedUsers);
    }
    if (storedCardKey.length > 0 && cardKeyState.tempState.length === 0) {
      cardKeyState.setTempState(storedCardKey);
    }
  }, [accounts, contacts, users]);

  useEffect(() => {
    setLoadingAccounts(true);
    api.get('/accounts').then(response => {
      setAccounts(response.data);
      setLoadingAccounts(false);
    });
  }, []);

  useEffect(() => {
    if (
      selectedAccountsState.tempState &&
      selectedAccountsState.tempState.length > 0
    ) {
      setLoadingContacts(true);
      api
        .post(`/accounts/contacts`, {
          account_ids: selectedAccountsState.tempState.map(
            account => account.id,
          ),
        })
        .then(response => {
          setContacts(response.data || []);
          setLoadingContacts(false);
        });
    }
  }, [selectedAccountsState.tempState]);

  useEffect(() => {
    if (selectedArea) {
      setLoadingUsers(true);
      api.get(`/areas/${selectedArea.id}/users`).then(response => {
        setUsers(response.data);
        setLoadingUsers(false);
      });
    }
  }, [selectedArea]);

  function handleChange(
    value: EntityType[],
    type: 'IAccount' | 'IContact' | 'IUser',
  ) {
    if (value && type === 'IAccount') {
      selectedAccountsState.setTempState(value as IAccount[]);
      selectedContactsState.setTempState([]);
    }
    if (value && type === 'IContact') {
      selectedContactsState.setTempState(value as IContact[]);
    }
    if (value && type === 'IUser') {
      selectedUsersState.setTempState(value as IUser[]);
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      onClose={closeModal}
      aria-labelledby={title}
      maxWidth="lg"
    >
      <DialogTitle id={title}>
        <Box marginTop="10px" justifyContent="space-between">
          <Box height="22px">
            <span>{title}</span>
          </Box>
          <Box>
            <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>
        <Container>
          <Box className={classes.content}>
            <CustomAutoComplete
              options={accounts}
              loading={loadingAccounts}
              selectedOptions={selectedAccountsState.tempState}
              setCardKey={cardKeyState.setTempState}
              handleChange={handleChange}
              label="Contas"
              type="IAccount"
            />
            <CustomAutoComplete
              options={
                selectedAccountsState.tempState.length > 0 ? contacts : []
              }
              loading={loadingContacts}
              selectedOptions={selectedContactsState.tempState}
              setCardKey={cardKeyState.setTempState}
              handleChange={handleChange}
              label="Contatos"
              type="IContact"
            />
            <CustomAutoComplete
              options={users}
              loading={loadingUsers}
              selectedOptions={selectedUsersState.tempState}
              setCardKey={cardKeyState.setTempState}
              handleChange={handleChange}
              label="Usuários"
              type="IUser"
            />
            <TextField
              id="key"
              name="key"
              label="Chave"
              margin="dense"
              variant="outlined"
              autoComplete="off"
              fullWidth
              className={
                cardKeyState.tempState.length > 0
                  ? classes.muiInputSelected
                  : classes.muiInput
              }
              value={cardKeyState.tempState}
              onChange={e => {
                cardKeyState.setTempState(e.target.value);
                selectedAccountsState.setTempState([]);
                selectedContactsState.setTempState([]);
                selectedUsersState.setTempState([]);
              }}
              placeholder="2209000001"
              InputProps={{
                inputComponent: InputKeyCardMask as never,
              }}
            />
          </Box>
          <Box
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            position="absolute"
            bottom="15px"
            right="25px"
            style={{ marginTop: 15, marginBottom: 15 }}
          >
            <Button
              className={classes.modalButtons}
              variant="secondary"
              color="inherit"
              onClick={resetFilters}
            >
              Limpar
            </Button>
            <Button
              className={classes.modalButtons}
              style={{ height: '40px', width: '90px', borderRadius: '3px' }}
              onClick={applyFilters}
            >
              Aplicar
            </Button>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
