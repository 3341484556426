/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useTrigger } from '../../context/TriggerContext';

export const TriggerAlert: React.FC = () => {
  const { triggersAlert, handleClearAlert } = useTrigger();

  return (
    <>
      {triggersAlert &&
        triggersAlert.length > 0 &&
        triggersAlert.map((alert, index) => (
          <Dialog
            key={alert.trigger_id + index}
            open={alert.show}
            // onClose={() => handleClearAlert(alert.trigger_id)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(_event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleClearAlert(alert.trigger_id);
              }
            }}
            disableEscapeKeyDown
            maxWidth="md"
            style={{ zIndex: '2000' }}
          >
            <DialogTitle id="alert-dialog-title">Alerta</DialogTitle>
            <DialogContent>
              <DialogContentText component="pre" id="alert-dialog-description">
                {alert.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleClearAlert(alert.trigger_id)}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        ))}
    </>
  );
};
