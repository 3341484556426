/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';

interface DynamicTableProps {
  data: Array<Record<string, any>> | object;
  isSubTable?: boolean;
}

interface DataObject {
  [key: string]: any;
}

type DataArray = DataObject[];

type FlattenedData = DataObject | DataArray;

const flattenData = (data: DataObject | DataArray): FlattenedData => {
  if (Array.isArray(data)) {
    return data.map(item => flattenData(item)) as DataArray;
  }
  if (typeof data === 'object' && data !== null) {
    const flattenedObject: DataObject = {};

    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
        Object.assign(flattenedObject, flattenData(data[key] as DataObject));
      } else {
        flattenedObject[key] = data[key];
      }
    });

    return flattenedObject;
  }
  return data;
};

export const DynamicTable: React.FC<DynamicTableProps> = ({
  data,
  isSubTable = false,
}) => {
  const flattenedData = flattenData(data);

  if (
    (Array.isArray(flattenedData) && flattenedData.length === 0) ||
    (typeof flattenedData === 'object' &&
      Object.keys(flattenedData).length === 0)
  ) {
    return <p>Sem valores</p>;
  }

  const headers = Array.isArray(flattenedData)
    ? typeof flattenedData[0] === 'object'
      ? Object.keys(flattenedData[0])
      : Object.keys(
          Object.assign(
            {},
            ...flattenedData.map(item =>
              typeof item === 'object' ? item : {},
            ),
          ),
        )
    : Object.keys(flattenedData);

  return (
    <Table className="w-full overflow-clip border-separate border-spacing-0">
      <TableHeader className={isSubTable ? 'z-0' : 'z-10'}>
        <TableRow>
          {headers.map(header => (
            <TableCell
              key={header}
              className="border border-collapse border-gray-200 p-2 font-semibold truncate"
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.isArray(flattenedData) &&
        Object.keys(flattenedData).length > 0 ? (
          flattenedData.map((item, index) => (
            <TableRow key={index} className="hover:bg-gray-50">
              {headers.map(header => {
                const headerValue = item[header];
                return (
                  <TableCell
                    key={header}
                    className="border border-gray-200 p-1 truncate"
                  >
                    {Array.isArray(headerValue) ? (
                      headerValue.length > 0 ? (
                        typeof headerValue[0] === 'object' ? (
                          <DynamicTable data={headerValue} isSubTable />
                        ) : (
                          headerValue.join(', ')
                        )
                      ) : (
                        ''
                      )
                    ) : typeof headerValue === 'object' &&
                      headerValue !== null ? (
                      <DynamicTable data={headerValue} isSubTable />
                    ) : headerValue !== undefined && headerValue !== null ? (
                      headerValue
                    ) : (
                      ''
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
        ) : (
          <TableRow className="hover:bg-gray-50">
            {headers.map(header => {
              const rowData = flattenedData as Record<string, any>;
              const headerData = rowData[header];

              return (
                <TableCell
                  key={header}
                  className="border border-gray-200 p-1 truncate"
                >
                  {Array.isArray(headerData) ? (
                    headerData.length > 0 ? (
                      typeof headerData[0] === 'object' ? (
                        <DynamicTable data={headerData} isSubTable />
                      ) : (
                        headerData.join(', ')
                      )
                    ) : (
                      ''
                    )
                  ) : typeof headerData === 'object' && headerData !== null ? (
                    <DynamicTable data={headerData} isSubTable />
                  ) : headerData !== undefined && headerData !== null ? (
                    headerData
                  ) : (
                    ''
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
