import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';

import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { ptBR } from 'date-fns/locale';

interface DatePickerWithRangeProps {
  className?: string;
  date?: DateRange | undefined;
  setDate: (date: DateRange | undefined) => void;
  placeholder?: string;
  align?: 'start' | 'center' | 'end' | undefined;
  side?: 'bottom' | 'top' | 'right' | 'left' | undefined;
}

export function DatePickerWithRange({
  className,
  date,
  setDate,
  placeholder,
  align,
  side,
}: DatePickerWithRangeProps) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const popoverRef = React.useRef<HTMLDivElement>(null);

  const handlePopoverState = () => {
    setIsPopoverOpen(oldValue => !oldValue);
  };

  const handleClickOutside = (event: PointerEvent) => {
    const target = event.target as Element;
    const isInsidePopover = popoverRef.current?.contains(target);

    if (!isInsidePopover) {
      setIsPopoverOpen(false);
    }
  };

  return (
    <div className={cn('grid gap-2', className)} ref={popoverRef}>
      <Popover open={isPopoverOpen} onOpenChange={undefined}>
        <PopoverTrigger asChild onClick={handlePopoverState}>
          <Button
            id="date"
            variant="outline"
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(new Date(date.from), 'dd MMMM, yyyy', {
                    locale: ptBR,
                  })}{' '}
                  -{' '}
                  {format(new Date(date.to), 'dd MMMM, yyyy', { locale: ptBR })}
                </>
              ) : (
                format(new Date(date.from), 'dd MMMM, yyyy', {
                  locale: ptBR,
                })
              )
            ) : (
              <span>{placeholder || 'Selecione um período'}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0"
          align={align || 'start'}
          side={side}
          /* onPointerDownOutside={() => {
            if (isPopoverOpen) setIsPopoverOpen(false);
          }} */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onPointerDownOutside={handleClickOutside as any}
        >
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            captionLayout="dropdown-buttons"
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
