import React from 'react';
import { statuses } from 'src/utils/taskFilterColumnsUtils';
import { CalendarTaskData } from '../../types';

export function CalendarTooltipContent({ event }: { event: CalendarTaskData }) {
  const status = statuses.find(st => st.value === event.resource.status);
  const statusIcon = status?.icon;
  const statusFillColor = status?.fillColor;

  const IconComponent = statusIcon as React.FC<React.SVGProps<SVGSVGElement>>;

  return (
    <div className="flex flex-col flex-wrap items-start max-w-[300px]">
      <div className="flex flex-nowrap items-center w-full">
        <IconComponent
          className="h-[18px] w-[18px] mr-0.5 text-muted-foreground"
          fill={statusFillColor}
          strokeWidth={1.8}
          color="#fff"
        />
        <span className="truncate">
          {`${event.resource.type} - ${event.title}`}
        </span>
      </div>
      <strong className="truncate">{event.resource.account.name}</strong>
      <p className="max-h-[100px] text-wrap text-xs">
        {event.resource.description}
      </p>
    </div>
  );
}
