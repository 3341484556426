import React, { useState, useEffect } from 'react';

import {
  Box,
  Link,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { ICard, IPhaseCards } from '../../interface/IPhaseCards';

import Modal from '../Modal_cards';

import { PhaseTooltip } from './phaseTooltip';
import api from '../../services/api';
import { CreateCard } from '../CreateCard';
import { PanelCard } from './PanelCard';
import { COLORS } from '../../styles/colors';

const useStyles = makeStyles(theme =>
  createStyles({
    maisCard: {
      marginTop: '10px',
      marginLeft: '4px',
      fontSize: '0.8rem',
    },
    lengthQtd: {
      alignSelf: 'center',
      display: 'flex',
      marginTop: '-14px',
      lineHeight: 2.6,
      fontWeight: 'bolder',
      fontSize: '0.7rem',
      color: COLORS.GREEN,
    },
    verMais: {
      display: 'none',
      cursor: 'pointer',
      color: COLORS.BLUE,
      marginTop: '10px',
      textAlign: 'center',
      fontSize: '0.9rem',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    phaseBox: {
      margin: 0,
      backgroundColor: COLORS.WHITE,
      [theme.breakpoints.up('sm')]: {
        minHeight: '100%',
      },
      '&.open': {
        '& $phasesCards': {
          display: 'block',
        },
      },
    },
    phaseBoxHeader: {
      position: 'sticky',
      top: '0',
      height: '60px',
      padding: 8,
      backgroundColor: COLORS.WHITE,
      border: '1px solid #dfdfdf',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '5px 5px 0 0',
      zIndex: 990,
    },
    phaseBoxContent: {
      padding: 8,
      height: 'auto',
    },
    phasesCards: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        '&:nth-child(-n+5)': {
          display: 'block',
        },
      },
    },
    titleAlign: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxGrid: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr',
    },
  }),
);

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

interface IValues {
  id: string;
  name: string;
  totalize: boolean;
  phasesToTotalize: string[];
  type: string;
  value: string | null;
  tableColumns?: {
    id: string;
    name: string;
    totalize: boolean;
    phasesToTotalize: string[];
    type: string;
    value: string | null;
    valueJSON: null;
  }[];
  valueJSON: ITColumnValue[] | null;
}

interface ITColumnValue {
  [Key: string]: {
    value: string | null;
    valueJSON?: string[] | object[];
  };
}

interface IFieldValues {
  id: string;
  key_card: string;
  phase: {
    id: string;
  };
  fieldValues: IValues[];
}

interface PhaseProps {
  dispatchTypes: IDispatchType[];
  phase: IPhaseCards;
  color: string;
  filterTotalizer: boolean;
  moveItem: (card_id: string, dispatchType_id: string) => void;
  onDispatchCard: () => void;
}

const Phase: React.FC<PhaseProps> = ({
  dispatchTypes = [],
  phase,
  color,
  filterTotalizer,
  moveItem,
  onDispatchCard = () => null,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(filterTotalizer);
  const [openPhase, setOpenPhase] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [phaseTotalizer, setPhaseTotalizer] = useState<any>([]);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setLoading(true);

    async function handleTotalizePhase(cards: ICard[], phaseId: string) {
      if (!cards?.length) {
        return [];
      }

      const card_ids = cards.map(card => card.id);
      const cardValuesResp = await api.post(`/cards/fieldValues`, {
        card_ids,
      });
      const phaseCards: IFieldValues[] = cardValuesResp?.data || [];
      const hashTable: Record<
        string,
        { id: string; name: string; total: number }
      > = {};

      cards.map(card => {
        const cardFieldValues = phaseCards.find(
          pCard => pCard.id === card.id,
        )?.fieldValues;

        if (cardFieldValues && cardFieldValues.length > 0) {
          cardFieldValues.forEach((field: IValues) => {
            if (
              field.type === 'TABLE' &&
              field?.tableColumns &&
              field.valueJSON
            ) {
              const { tableColumns, valueJSON } = field;
              const numericColumns = tableColumns.filter(
                tc => tc.type === 'NUMERIC',
              );
              numericColumns.map(tableColumn => {
                const { id, name, totalize, phasesToTotalize } = tableColumn;

                if (totalize && phasesToTotalize.includes(phaseId)) {
                  let currentValue = 0;
                  valueJSON &&
                    valueJSON.length > 0 &&
                    valueJSON.forEach(row => {
                      if (
                        row[id] &&
                        row[id].value !== undefined &&
                        typeof Number(row[id].value) === 'number'
                      ) {
                        currentValue += Number(row[id].value);
                      } else {
                        currentValue += 0;
                      }
                      return row;
                    });

                  hashTable[id] = hashTable[id]
                    ? {
                        id,
                        name,
                        total: hashTable[id].total + Number(currentValue),
                      }
                    : { id, name, total: Number(currentValue) };
                }

                return tableColumn;
              });
            } else if (field.type === 'NUMERIC') {
              const { id, name, value } = field;
              let current = value;
              if (typeof value !== 'number' && value === undefined)
                current = '0';
              hashTable[id] = hashTable[id]
                ? { id, name, total: hashTable[id].total + Number(current) }
                : { id, name, total: Number(current) };
            }
          });
        }
        return card;
      });

      const result: { name: string; total: number }[] =
        Object.values(hashTable);

      return result;
    }
    if (filterTotalizer) {
      handleTotalizePhase(phase.cards, phase.id).then(res =>
        setPhaseTotalizer(res),
      );
      setLoading(false);
    } else {
      setPhaseTotalizer(phase.totalizer);
      setLoading(false);
    }
  }, [phase.cards, filterTotalizer]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openBox = () => {
    setOpen(!open);
  };

  const openPhase2 = () => {
    setOpenPhase(!openPhase);
  };

  const renderCards = () => {
    return phase.cards?.map(card => (
      <PanelCard
        customClass={classes.phasesCards}
        key={card.id}
        dispatchTypes={dispatchTypes}
        cardItem={{
          ...card,
          phaseId: phase.id,
          percentage:
            card.percentage && card.percentage >= 0 && card.percentage <= 100
              ? card.percentage
              : card.percentage && card.percentage > 100
              ? 100
              : 0,
        }}
        phase={{ id: phase.id, name: phase.name }}
        moveItem={moveItem}
        onDispatchCard={onDispatchCard}
      />
    ));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ color }}
      className={`${classes.phaseBox} ${open ? 'open' : ''}`}
    >
      <div className={classes.phaseBoxHeader}>
        <Box
          width="200px"
          display="flex"
          marginTop={color ? 0 : 1}
          className={classes.titleAlign}
        >
          <Tooltip title={phase.name} arrow>
            <Typography
              variant="h6"
              component="h6"
              noWrap
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {phase.name}
            </Typography>
          </Tooltip>
          {(phase.cards.length && phaseTotalizer.length) > 0 && !loading && (
            <PhaseTooltip
              phase={phase}
              loading={loading}
              totalizer={phaseTotalizer}
            />
          )}
        </Box>
        <Box width="200px" display="flex" marginTop={color ? 0 : 1}>
          <Typography className={classes.lengthQtd}>
            {`${phase.cards.length} cards`}
          </Typography>
        </Box>
      </div>
      <div className={classes.phaseBoxContent}>
        {renderCards()}
        <Typography onClick={() => openPhase2()} className={classes.verMais}>
          {!openPhase ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Typography>
        <Typography className={classes.maisCard}>
          <Link
            onClick={() => setOpenModal(true)}
            style={{
              color: '#676767',
              cursor: 'pointer',
            }}
          >
            + Adicionar novo card ...
          </Link>
          {openModal && (
            <Modal
              open
              closeModal={handleCloseModal}
              title="Adicionar CARD"
              titleLeft=""
              titleRight=""
            >
              <CreateCard closeModal={handleCloseModal} />
            </Modal>
          )}
        </Typography>
      </div>
    </Box>
  );
};

export { Phase };
