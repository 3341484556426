/* eslint-disable @typescript-eslint/no-explicit-any */
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { toast } from 'react-toastify';

interface PDFExportOptions {
  margin?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}

export function exportToPDF(
  elementId: string,
  options: PDFExportOptions,
  fileName: string = 'document.pdf',
): void {
  const { margin = { top: 20, bottom: 20, left: 20, right: 20 } } = options;

  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  // Calcular o tamanho do conteúdo real
  const contentWidth = element.scrollWidth;
  const contentHeight = element.scrollHeight;

  // Criar o PDF com base nas dimensões do conteúdo
  const pdf = new JsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: [
      contentWidth + margin.left + margin.right,
      contentHeight + margin.top + margin.bottom,
    ],
  });

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const usableHeight = pageHeight - margin.top - margin.bottom;

  let currentHeight = margin.top;

  const renderContentToPDF = async () => {
    let yOffset = 0;
    const scale = 1;

    const captureNextPart = async () => {
      await html2canvas(element, {
        scale,
        y: yOffset,
        width: contentWidth,
        height: usableHeight,
        windowHeight: usableHeight,
        scrollY: yOffset,
      })
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = pageWidth - margin.left - margin.right;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(
            imgData,
            'PNG',
            margin.left,
            currentHeight,
            imgWidth,
            imgHeight,
          );
          currentHeight += imgHeight;
          yOffset += imgHeight;

          if (yOffset < contentHeight) {
            captureNextPart();
          } else {
            pdf.save(fileName);
          }
        })
        .catch(error => {
          toast.error(
            'Não foi possível gerar o PDF agora, tente novamente mais tarde:',
            error,
          );
        });
    };

    captureNextPart();
  };

  renderContentToPDF();
}

const exportContentToPDF = () => {
  const options: PDFExportOptions = {
    margin: { top: 40, bottom: 40, left: 40, right: 40 },
  };

  exportToPDF('pdf-content', options, 'document.pdf');
};

export default exportContentToPDF;
