/* eslint-disable camelcase */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
} from 'react';
import { toast } from 'react-toastify';

export interface ITriggersData {
  card_id: string;
  key_card: string;
  triggers: ITriggersResponse;
  accountName: string;
  triggerDateTime: Date | string;
}

interface ITriggersResponse {
  actions: ITriggerAction[];
  success: ITriggerSuccess[];
  errors: ITriggerError[];
}

interface ITrigger {
  id: string;
  name: string;
}

interface ITriggerAction {
  type: string;
  message: string;
  trigger: ITrigger;
}

interface ITriggerSuccess {
  type: string;
  actionName: string;
  trigger: ITrigger;
}

interface ITriggerError {
  message: string;
  statusCode: number;
}

interface ITriggersAlert {
  show: boolean;
  message: string;
  trigger_id: string;
}

interface ITriggerContextData {
  triggersAlert: ITriggersAlert[];
  triggersData: ITriggersData[];
  updateTriggers(triggers: ITriggersData): void;
  handleClearAlert(triggerId: string): void;
  hasNewTriggers: boolean;
  setHasNewTriggers: (hasNewTriggers: boolean) => void;
}

interface TriggerProviderProps {
  children: ReactNode;
}

const TriggerContext = createContext<ITriggerContextData>(
  {} as ITriggerContextData,
);

const TriggerProvider: React.FC<TriggerProviderProps> = ({ children }) => {
  const [triggersAlert, setTriggerAlert] = useState<ITriggersAlert[]>([]);
  const [hasNewTriggers, setHasNewTriggers] = useState<boolean>(false);

  const [triggersData, setTriggersData] = useState<ITriggersData[]>(() => {
    const localTriggers = localStorage.getItem('@Brasao:triggersLog');

    if (localTriggers) {
      return JSON.parse(localTriggers);
    }

    return [];
  });

  const updateTriggers = useCallback(
    (newTriggers: ITriggersData) => {
      const tempTriggers = [...triggersData, newTriggers];
      const { actions, success, errors } = newTriggers.triggers;

      if (tempTriggers.length > 30) {
        tempTriggers.shift();
      }

      if (errors && errors.length > 0) {
        const errorsCount = errors.length;

        const defaultMsg = 'Ocorreu um erro na execução de gatilho.';
        const firstErrorMsg = errors[0]?.message || '';

        const totalErrosMsg = ` E mais ${errorsCount - 1} ${
          errorsCount - 1 === 1 ? 'erro' : 'erros'
        }. (Consulte timeline das execuções para mais detalhes).`;

        toast.error(
          errorsCount > 1 && firstErrorMsg.length > 0
            ? firstErrorMsg + totalErrosMsg
            : `${defaultMsg} ${firstErrorMsg}`,
          {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
            autoClose: 5000,
          },
        );
      }

      if (actions.length > 0) {
        const tempAlerts = [...triggersAlert];
        actions.forEach((action: ITriggerAction) => {
          if (action.type === 'alert') {
            tempAlerts.push({
              show: true,
              message: action.message,
              trigger_id: action.trigger.id,
            });
          }
        });
        setTriggerAlert(tempAlerts);
      }

      if (actions.length > 0 || success.length > 0 || errors.length > 0) {
        localStorage.setItem(
          '@Brasao:triggersLog',
          JSON.stringify(tempTriggers),
        );

        setHasNewTriggers(true);
        setTriggersData(tempTriggers);
      }
    },
    [triggersAlert, triggersData],
  );

  const handleClearAlert = (triggerId: string) => {
    const tempAlerts = triggersAlert.filter(t => t.trigger_id !== triggerId);

    setTriggerAlert(tempAlerts);
  };

  return (
    <TriggerContext.Provider
      value={{
        triggersAlert,
        triggersData,
        updateTriggers,
        handleClearAlert,
        hasNewTriggers,
        setHasNewTriggers,
      }}
    >
      {children}
    </TriggerContext.Provider>
  );
};

function useTrigger(): ITriggerContextData {
  const context = useContext(TriggerContext);

  if (!context) {
    throw new Error('useTrigger must be used within an TriggerProvider');
  }
  return context;
}

export { TriggerProvider, useTrigger };
