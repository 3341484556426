/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import { Card } from 'src/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { Button } from 'src/components/ui/button';
import { ArrowDownIcon, ArrowUpIcon, HelpCircleIcon } from 'lucide-react';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { IGetCardFieldApi } from 'src/services/cardFieldApi';
import {
  ITaskAssociatedFields,
  TLinkedCardFieldProperties,
  TLinkedCardFields,
} from 'src/interface/ILinkedCardFields';
import { LinkedPhaseFields } from './LinkedPhaseFields';

export interface ILinkedCardFields {
  isNotApprovalType: boolean;
  isEditingTask: boolean;
  cardFields: IGetCardFieldApi[];
  linkedCardFields: TLinkedCardFields;
  handleSetLinkedCardFields: (linkedCardFields: TLinkedCardFields) => void;
}

type TPhaseFields = Array<{
  phaseId: string;
  phaseName: string;
  fields: ITaskAssociatedFields[];
}>;

export const LinkedCardFields = ({
  isNotApprovalType,
  isEditingTask,
  cardFields,
  linkedCardFields,
  handleSetLinkedCardFields,
}: ILinkedCardFields) => {
  const [expandedRows, setExpandedRows] = useState<string | null>(null);
  const phasesFields: TPhaseFields = useMemo(() => {
    const newList: TPhaseFields = [];
    if (cardFields.length > 0) {
      cardFields.forEach(field => {
        if (!newList.some(phase => phase.phaseId === field.phase.id)) {
          newList.push({
            phaseId: field.phase.id,
            phaseName: field.phase.name,
            fields: cardFields
              .filter(cf => cf.phase.id === field.phase.id)
              .map(cf => ({
                ...cf,
                isChecked: false,
                isEditable: true,
                isApprovalCriterion: false,
              })),
          });
        }
      });
    }
    return newList;
  }, [cardFields]);

  const handleToggle = (phaseId: string) => {
    setExpandedRows(currentId => {
      if (currentId === phaseId) return null;
      return phaseId;
    });
  };

  const handleChangeFieldOptions = useCallback(
    (
      phaseId: string,
      fieldId: string,
      propertie:
        | 'isChecked'
        | 'isRequired'
        | 'isEditable'
        | 'isApprovalCriterion',
      value: boolean,
    ) => {
      const fieldIsLinked = linkedCardFields.find(lcf => lcf.id === fieldId);
      const fieldPhase = phasesFields.find(phase => phase.phaseId === phaseId);
      const field: ITaskAssociatedFields | undefined = fieldPhase?.fields.find(
        f => f.id === fieldId,
      );

      if (field && propertie === 'isChecked') {
        const newLinkedValues =
          value === false && fieldIsLinked
            ? linkedCardFields.filter(lcf => lcf.id !== fieldId)
            : value === true && fieldIsLinked
            ? linkedCardFields.filter(lcf => {
                if (lcf.id !== fieldId) return lcf;

                return {
                  ...lcf,
                  propertie: value,
                };
              })
            : [
                ...linkedCardFields,
                {
                  id: field.id,
                  isRequired: field.isRequired,
                  isEditable: field.isEditable,
                  isApprovalCriterion: field.isApprovalCriterion,
                },
              ];
        handleSetLinkedCardFields(newLinkedValues);
        return;
      }

      if (fieldIsLinked && propertie !== 'isChecked') {
        const updatedLinkedCardFields = linkedCardFields.map(lcf => {
          if (lcf.id === fieldId) {
            const isApprovalCriterion = {
              isRequired: value,
              isEditable: true,
            };

            return {
              ...lcf,
              [propertie]: value,
              ...(propertie === 'isApprovalCriterion' && isApprovalCriterion),
            };
          }
          return lcf;
        });

        handleSetLinkedCardFields(updatedLinkedCardFields);
      }
    },
    [phasesFields, linkedCardFields, handleSetLinkedCardFields],
  );

  const linkedCardsFieldIdValues: TLinkedCardFieldProperties = useMemo(() => {
    const newList: TLinkedCardFieldProperties = {};
    if (linkedCardFields.length > 0) {
      linkedCardFields.forEach(lcf => {
        newList[lcf.id] = {
          isRequired: lcf.isRequired,
          isEditable: lcf.isEditable,
          isApprovalCriterion: lcf.isApprovalCriterion,
        };
      });
    }
    return newList;
  }, [linkedCardFields]);

  if (!(phasesFields.length > 0)) return null;

  return (
    <Card className="w-full">
      <div className="flex flex-row items-center">
        <span className="px-2 w-fit font-medium text-sm bg-white text-black">
          {isEditingTask
            ? 'Campos do Card vinculados à tarefa'
            : 'Vincular campos do Card à tarefa'}
        </span>
        <TooltipWrapper
          value="Selecione os campos em que deseja vincular a tarefa"
          className="font-medium text-xs min-w-fit"
        >
          <HelpCircleIcon className="h-4 w-4 cursor-help" />
        </TooltipWrapper>
      </div>
      <div className="rounded-md border shadow overflow-auto my-2 box-border">
        <Table className="w-full">
          <TableHeader>
            <TableRow>
              <TableHead
                colSpan={2}
                className="text-left px-2 py-2 h-fit font-semibold"
              >
                Fases
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="w-full">
            {phasesFields &&
              phasesFields.map(phase => {
                if (phase.fields.length === 0) return null;
                return (
                  <React.Fragment key={phase.phaseId}>
                    <TableRow
                      className="cursor-pointer flex flex-row items-center"
                      onClick={e => {
                        e.stopPropagation();
                        handleToggle(phase.phaseId);
                      }}
                    >
                      <TableCell
                        align="left"
                        className="px-2 py-1 h-fit flex items-center justify-between w-full"
                      >
                        {phase.phaseName}
                        <TooltipWrapper
                          value="Exibir campos da fase"
                          className="font-medium text-xs min-w-fit"
                        >
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            className="rounded-full"
                          >
                            {expandedRows === phase.phaseId ? (
                              <ArrowUpIcon className="h-4 w-4" />
                            ) : (
                              <ArrowDownIcon className="h-4 w-4" />
                            )}
                          </Button>
                        </TooltipWrapper>
                      </TableCell>
                    </TableRow>
                    {expandedRows === phase.phaseId && (
                      <LinkedPhaseFields
                        isNotApprovalType={isNotApprovalType}
                        phaseFields={phase.fields}
                        linkedCardsFieldValues={linkedCardsFieldIdValues}
                        handleChangeFieldOptions={handleChangeFieldOptions}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};
