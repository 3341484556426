import { ICustomField } from '../Form.i';

export const handleChangeFieldUp = (
  fieldSelected: ICustomField,
  fieldList: ICustomField[],
  setFunction: (value: ICustomField[]) => void,
) => {
  const findField = fieldList.find(field => field.order < fieldSelected.order);

  if (findField) {
    const tempFields = [...fieldList];

    const index = tempFields.findIndex(field => field.id === fieldSelected.id);

    tempFields[index].order -= 1;
    tempFields[index - 1].order += 1;

    [tempFields[index], tempFields[index - 1]] = [
      tempFields[index - 1],
      tempFields[index],
    ];

    setFunction(tempFields);
  }
};

export const handleChangeFieldDown = (
  fieldSelected: ICustomField,
  fieldList: ICustomField[],
  setFunction: (value: ICustomField[]) => void,
) => {
  const findField = fieldList.find(field => field.order > fieldSelected.order);

  if (findField) {
    const tempFields = [...fieldList];

    const index = tempFields.findIndex(field => field.id === fieldSelected.id);

    tempFields[index].order += 1;
    tempFields[index + 1].order -= 1;

    [tempFields[index], tempFields[index + 1]] = [
      tempFields[index + 1],
      tempFields[index],
    ];

    setFunction(tempFields);
  }
};
