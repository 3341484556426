import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { renderGroup } from 'src/components/AutoCompleteVirtualization';
import { CustomRenderCheckboxOption } from 'src/components/AutoCompleteCustomComponents/CustomRenderCheckboxOption';
import { IUserAll } from 'src/services/userApi';

interface UserSelectorProps {
  onChange: (selectedUsers: IUserAll[]) => void;
  users: IUserAll[];
  loadingUsers: boolean;
  value?: IUserAll[];
}

export const UserSelector: React.FC<UserSelectorProps> = ({
  onChange,
  users,
  loadingUsers,
  value = [],
}) => {
  const [selectedUsers, setSelectedUsers] = useState<IUserAll[]>(value);

  useEffect(() => {
    setSelectedUsers(value);
  }, [value]);

  useEffect(() => {
    onChange(selectedUsers);
  }, [selectedUsers, onChange]);

  return (
    <Autocomplete
      id="custom-autocomplete-users"
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      loading={loadingUsers}
      multiple
      limitTags={2}
      value={selectedUsers || []}
      options={users}
      disableCloseOnSelect
      onChange={(e, value) => {
        setSelectedUsers(value);
      }}
      loadingText="Carregando"
      renderGroup={renderGroup}
      groupBy={option => option.name[0].toUpperCase()}
      renderOption={(option, { selected }) => (
        <CustomRenderCheckboxOption option={option.name} selected={selected} />
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Usuários responsáveis"
          placeholder="Adicionar usuários"
          InputProps={{
            ...params.InputProps,
            style: { display: 'flex', flexWrap: 'nowrap' },
            endAdornment: (
              <>
                {loadingUsers ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
