import { Box, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ModalCards from 'src/components/Modal_cards';
import Contact from 'src/pages/Contact';
import Account from 'src/pages/Account';
import { TaskTypes } from 'src/utils/taskTypes.constants';
import { taskModalStyles } from '../taskModalStyles';
import { AutocompleteField } from '../../TaskInputFields/AutocompleteField';
import { IObject, TaskAccountDataProps } from '../TaskModal.i';
import { useFormikErrorContext } from '../../../context/FormikErrorProvider';

export const TaskAccountData: React.FC<TaskAccountDataProps> = ({
  handleChangeValue,
  taskTypeId,
  accounts,
  contacts,
  taskAccount,
  taskContact,
  done,
  handleRefreshContacts,
  handleRefreshAccounts,
  isProcessTask,
  editPermissionDisabledForUser,
  wasCreatedByTrigger,
  isUserNotAccountResponsible,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = taskModalStyles();
  const { errors, hasError } = useFormikErrorContext();
  const typeAssocietedFields = taskTypeId
    ? TaskTypes[taskTypeId]?.associatedFields || []
    : [];
  const [contactModalData, setContactModalData] = useState<{
    contact_id: string | null;
    isOpen: boolean;
  }>({
    contact_id: null,
    isOpen: false,
  });
  const [isAccountModalOpen, setIsAccountModalOpen] = useState<boolean>(false);

  const useFormattedContactArray = useCallback(
    (contact: IObject[] | null) => {
      let formattedContact: IObject | IObject[] | null = contact;
      if (typeAssocietedFields && typeAssocietedFields.includes('CONTACTS')) {
        formattedContact = contact && Array.isArray(contact) ? contact : [];
      } else {
        formattedContact =
          contact !== null && Array.isArray(contact) && contact?.length > 0
            ? contact[0]
            : contact && typeof contact === 'object' && 'id' in contact
            ? contact
            : null;
      }
      return formattedContact;
    },
    [typeAssocietedFields, taskTypeId],
  );

  const formatPhoneNumber = useCallback((phoneNumber: string): string => {
    const numericValue = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters

    if (numericValue.length === 11) {
      // Mobile phone format (e.g., 47999999999)
      return `(${numericValue.slice(0, 2)})${numericValue.slice(
        2,
        3,
      )}${numericValue.slice(3, 7)}-${numericValue.slice(7)}`;
    }
    if (numericValue.length <= 10) {
      // Fixed phone format (e.g., 4733333333)
      return `(${numericValue.slice(0, 2)})${numericValue.slice(
        2,
        6,
      )}-${numericValue.slice(6)}`;
    }
    // Invalid phone number, return as is
    return phoneNumber;
  }, []);

  const handleAddContact = () => {
    setContactModalData({
      contact_id: null,
      isOpen: true,
    });
  };

  const handleEditContact = () => {
    setContactModalData({
      contact_id: taskContact ? taskContact[0].id : null,
      isOpen: true,
    });
  };

  const handleCloseContact = () => {
    setContactModalData({
      contact_id: null,
      isOpen: false,
    });
    handleRefreshContacts(taskAccount?.id || '');
  };

  const handleEditAccount = () => {
    setIsAccountModalOpen(true);
  };

  const handleCloseAccount = () => {
    setIsAccountModalOpen(false);
    handleRefreshAccounts();
  };

  const formattedContactValue = useFormattedContactArray(taskContact);

  const contactPhone =
    taskContact !== null && taskContact[0]?.phone
      ? formatPhoneNumber(taskContact[0]?.phone || '')
      : '';

  if (!taskTypeId) return null;

  const cantEditAccount =
    done ||
    isProcessTask ||
    editPermissionDisabledForUser ||
    isUserNotAccountResponsible ||
    wasCreatedByTrigger;

  const accountOptions =
    isUserNotAccountResponsible && taskAccount !== null
      ? [taskAccount]
      : accounts;

  return (
    <>
      <Box width="100%" className={classes.groupLabel}>
        Dados Conta
      </Box>
      <Box className={classes.groupBoxWithBorder}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs>
            <AutocompleteField
              fieldId="account"
              fieldName="Conta"
              fieldValue={taskAccount}
              fieldOptions={accountOptions || []}
              margin="none"
              handleChangeValue={handleChangeValue}
              isDisabled={cantEditAccount}
              error={hasError('account')}
              helperText={hasError('account') ? errors.account : ''}
              showStartLinkAdornment={
                (!!taskAccount && !isUserNotAccountResponsible) || undefined
              }
              startAdornmentTooltip="Editar Conta"
              startLinkAdornmentOnClick={
                taskAccount && !isUserNotAccountResponsible
                  ? handleEditAccount
                  : undefined
              }
              showStartAdornmentEditIcon={!isUserNotAccountResponsible}
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              gap: '0.25rem',
            }}
          >
            <AutocompleteField
              fieldId="contacts"
              fieldName="Contato(s)"
              fieldValue={formattedContactValue}
              fieldOptions={contacts || []}
              isDisabled={done || !taskAccount || editPermissionDisabledForUser}
              isMultipleOptions={
                typeAssocietedFields?.length > 0 &&
                typeAssocietedFields.includes('CONTACTS')
              }
              margin="none"
              handleChangeValue={handleChangeValue}
              error={hasError('contacts')}
              helperText={hasError('contacts') ? errors.contacts : ''}
              showStartLinkAdornment={
                (formattedContactValue &&
                  !typeAssocietedFields.includes('CONTACTS')) ||
                undefined
              }
              startAdornmentTooltip="Editar Contato"
              startLinkAdornmentOnClick={
                formattedContactValue &&
                !typeAssocietedFields.includes('CONTACTS')
                  ? handleEditContact
                  : undefined
              }
              showStartAdornmentEditIcon
            />
            <IconButton
              aria-label="add"
              onClick={handleAddContact}
              size="small"
              disabled={
                !taskAccount ||
                editPermissionDisabledForUser ||
                isUserNotAccountResponsible
              }
              color="secondary"
            >
              <Tooltip title="Adicionar contato" arrow placement="top-end">
                <AddCircleIcon />
              </Tooltip>
            </IconButton>
            {typeAssocietedFields?.length >= 0 &&
              !typeAssocietedFields.includes('CONTACTS') && (
                <TextField
                  name="phone"
                  label="Telefone"
                  value={contactPhone || ''}
                  disabled
                  size="small"
                  fullWidth
                  style={{ maxWidth: '37%' }}
                  margin="none"
                />
              )}
          </Grid>
        </Grid>
        {contactModalData?.isOpen && taskAccount && taskAccount?.id ? (
          <ModalCards
            open={contactModalData.isOpen}
            closeModal={handleCloseContact}
            title="Alterar Contato"
            titleLeft=""
          >
            <Contact
              account={taskAccount?.id}
              closeModal={handleCloseContact}
              contactId={contactModalData?.contact_id || undefined}
            />
          </ModalCards>
        ) : null}
        {isAccountModalOpen && taskAccount && taskAccount?.id ? (
          <ModalCards
            open={isAccountModalOpen}
            closeModal={handleCloseAccount}
            title="Editar Conta"
            titleLeft=""
          >
            <Account
              closeModal={handleCloseAccount}
              account_id_prop={taskAccount?.id}
            />
          </ModalCards>
        ) : null}
      </Box>
    </>
  );
};

// Memoize the component to prevent unnecessary re-renders
export const MemoizedTaskAccountData = React.memo(TaskAccountData);
