import api from './api';

export interface GetAccountsApi {
  id: string;
  name: string;
  city: string | null;
  cityCodeIBGE: string | null;
  complement: string | null;
  corporateName: string | null;
  cpfCnpj: string;
  district: string | null;
  idERP: string | null;
  numeral: string | null;
  phone: string | null;
  state: string | null;
  street: string | null;
  zipCode: string | null;
}

export const accountApi = {
  getAccounts: () => api.get<GetAccountsApi[]>(`/accounts`),
};
