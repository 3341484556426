import React, { useEffect, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

interface IDateTimeField {
  fieldId: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: Date | string | null;
  isDisabled?: boolean;
  isRequired?: boolean;
  minDate?: Date | string;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  error?: boolean;
  helperText?: string;
  handleChangeValue: (field: string, newValue: string | Date | null) => void;
}

export const MuiDateTimeField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  isDisabled = false,
  isRequired = false,
  margin = 'none',
  handleChangeValue,
  minDate,
  error,
  helperText,
}: IDateTimeField): JSX.Element => {
  const [inputValue, setInputValue] = useState<Date | string | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);

  useEffect(() => {
    if (fieldValue) setInputValue(fieldValue);
  }, [fieldValue]);

  const handleInputChange = (date: MaterialUiPickersDate) => {
    const newValue = date;
    setInputValue(newValue as Date);
  };

  const handleBlur = () => {
    const parsedDate = inputValue ? Date.parse(inputValue.toString()) : NaN;

    if (inputValue !== null && Number.isNaN(parsedDate)) {
      setInputError('Formato de data inválido');
    } else {
      setInputError(null);
    }

    handleChangeValue(fieldName, inputValue || null);
    /*  const newDate = inputValue || null;

    if (newDate === null) {
      handleChangeValue(fieldName, null);
    } else {
      const parsedDate = new Date(newDate);
      console.log('parsedDate', parsedDate);
      if (isValid(parsedDate)) {
        const isoDate = parsedDate.toISOString();

        handleChangeValue(fieldName, isoDate);
      } else {
        // The date is invalid
        console.log('Invalid date');
      }
    } */
  };

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        id={fieldId}
        name={fieldName}
        label={fieldLabel}
        inputVariant="outlined"
        required={isRequired}
        disabled={isDisabled}
        margin={margin}
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onBlurCapture={handleBlur}
        format="dd/MM/yyyy HH:mm"
        // invalidDateMessage={inputError}
        ampm={false}
        minDate={minDate}
        minDateMessage="Data fim deve ser maior que data início"
        error={!!inputError || error}
        helperText={inputError || helperText}
      />
      {/* {inputError && (
        <div style={{ color: 'red', marginTop: '8px' }}>{inputError}</div>
      )} */}
    </MuiPickersUtilsProvider>
  );
};
