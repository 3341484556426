import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: '1000px',
    '& .MuiTable-root': {
      width: '100%',
      marginLeft: '0',
      marginRight: '0',
      marginBottom: '10px',
    },
    '& label.Mui-focused': {
      color: '#007dc6',
    },
    '& .MuiInputLabel-shrink': {
      color: '#007dc6',
      fontWeight: 500,
      '& fieldset': {
        borderColor: '#007dc6',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#007dc6',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#007dc6',
      },
    },
  },
  boxFilters: { maxWidth: '99%', flexGrow: 1, padding: '10px 10px' },
  boxTable: {
    marginTop: 50,
    marginBottom: 60,
    maxWidth: '99%',
    flexGrow: 1,
  },
  columnActions: {
    width: '60px',
  },
  title: {
    fontSize: '1.5em',
    padding: '20px 10px',
    fontWeight: 'bolder',
  },
  expandSearchBox: {
    display: 'flex',
    justifyContent: 'right',
  },
  expandSearchText: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    // fontSize: '1.0em',
    fontWeight: 500,
    width: '100%',
    height: '22px',
    justifyContent: 'space-between',
  },
  table: {
    // maxWidth: 650,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  checkBox: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  tableBody: {
    cursor: 'pointer',
  },
  tableContainer: {
    maxHeight: 220,
    width: '100%',
    margin: '0px',
    padding: '0px',
    marginTop: '0.5rem',
  },
  tableCell: {
    fontSize: '0.9rem',
  },
  tableCellFieldName: {
    fontSize: '0.9rem',
    width: '40%',
    minWidth: '300px',
  },
  tableCellFieldContent: {
    fontSize: '0.9rem',
    width: '60%',
    minWidth: '300px',
  },
  fieldFilter: {
    fontSize: '0.9rem',
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    // maxWidth: '300px',
  },
  integratedType: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  customFieldIntegrationTypeContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  integratedFieldPrimaryKey: {
    color: COLORS.BLUE,
    marginRight: '0.2rem',
    fontSize: '0.9rem',
  },
  integratedFieldDisplayColumnName: {
    fontSize: '0.9rem',
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '80%',
  },
  integratedFieldButtons: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  button: {
    margin: 0,
    padding: 0,
  },
  integratedFieldDummyTextField: {
    caretColor: 'transparent',
    width: '100%',
  },
  booleanFieldText: {
    fontSize: '0.9rem',
    marginRight: '1rem',
  },
  typeIntegrated: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flexGrow: 1,
    overflow: 'hidden',
    height: '40px',
    maxWidth: '600px',
    justifyContent: 'space-between',
    borderBottom: '1.6px solid #999999',
    alignItems: 'center',
  },
  typeIntegratedButton: {
    width: '2rem',
    height: '2.5rem',
  },
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      width: '100%',
    },
    predefinedOptionsCheckBox: {
      alignItems: 'start',
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },
  predefinedInput: {
    maxWidth: '600px',
  },
  stringInput: {
    maxWidth: '600px',
    fontSize: '0.9rem',
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
  checkBoxesDiv: {
    '& .MuiFormControlLabel-root': {
      color: '#676767',
      fontSize: '0.9rem',
    },
  },
  cardFlags: {
    width: '10px',
    height: '10px',
    padding: '0px',
    borderRadius: '7px',
  },
});
