/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Edit, Delete } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import HeaderPage from '../../../components/TitleAndButton';
import Modal from '../../../components/Modal_cards';
import Account from '..';

const useStyles = makeStyles({
  content: {
    maxWidth: '650px',
  },
  boxTable: {
    maxWidth: '650px',
  },
  columnActions: {
    width: '60px',
  },
  table: {
    maxWidth: 650,
  },
});

interface IContact {
  id: string;
  name: string;
}

interface ListContacts {
  account_id: string;
}

const ListContacts: React.FC<ListContacts> = ({ account_id }) => {
  const classes = useStyles();

  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const [contacts, setContacts] = useState<IContact[]>([]);

  const handleCloseModal = () => {
    setOpenModal(false);
    api
      .post(`/accounts/contacts`, { account_ids: [account_id] })
      .then(response => setContacts(response.data));
  };

  const handleAddContact = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleEditContact = useCallback(
    (contact_id: string) => {
      history.push(`/accounts/${account_id}/contacts/${contact_id}`);
    },
    [account_id, history],
  );

  const handleDeleteContact = useCallback(
    (id: string) => {
      Swal.fire({
        title: 'Deseja excluir?',
        text: 'Essa opção não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await api.delete(`/contacts/${id}`);
            await api
              .post(`/accounts/contacts`, { account_ids: [account_id] })
              .then(response => setContacts(response.data))
              .then(() => {
                toast.success('Contato excluído com sucesso.', {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: 'colored',
                  autoClose: 2500,
                });
              });
          } catch (error: any) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              theme: 'colored',
              autoClose: 5000,
            });
          }
        }
      });
    },
    [account_id],
  );

  useEffect(() => {
    if (account_id)
      api
        .post(`/accounts/contacts`, { account_ids: [account_id] })
        .then(response => setContacts(response.data));
  }, [account_id]);

  return (
    <>
      <Box className={classes.content}>
        <Box>
          {account_id ? (
            <>
              <HeaderPage title="Contatos" handleAdd={handleAddContact} />

              <Table className={classes.table} aria-label="Contatos">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnActions} />
                    <TableCell>Contato</TableCell>
                    <TableCell className={classes.columnActions} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map(contact => (
                    <TableRow key={contact.name}>
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="Editar"
                          onClick={() => handleEditContact(contact.id)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {contact.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="Excluir"
                          onClick={() => handleDeleteContact(contact.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <div />
          )}
        </Box>
      </Box>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar Contato"
          titleLeft=""
        >
          <Account closeModal={handleCloseModal} />
        </Modal>
      )}
    </>
  );
};

export default ListContacts;
