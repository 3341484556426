import React, { createContext, useContext, useEffect, useState } from 'react';

interface MenuContextData {
  open: boolean;
  handleMenuIsOpen(): void;
}

const MenuContext = createContext<MenuContextData>({} as MenuContextData);

const MenuProvider: React.FC = ({ children }) => {
  const initialState = JSON.parse(localStorage.getItem('menuIsOpen') || 'true');
  const boolState = initialState === 'true';

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(boolState);

  function handleMenuIsOpen() {
    setMenuIsOpen(oldValue => !oldValue);
  }

  useEffect(() => {
    localStorage.setItem('menuIsOpen', JSON.stringify(menuIsOpen));
  }, [menuIsOpen]);

  return (
    <MenuContext.Provider
      value={{
        open: menuIsOpen,
        handleMenuIsOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): MenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider');
  }
  return context;
}

export { MenuProvider, useMenu };
