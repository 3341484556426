import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { toast } from 'react-toastify';
import { Checkbox } from 'src/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { Button } from 'src/components/ui/button';
import { ArrowDownIcon, ArrowUpIcon } from 'lucide-react';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { Card, CardContent, CardHeader } from 'src/components/ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import {
  DynamicColumnFormat,
  IDynamicReturnFormProps,
} from '../IntegrationRules.i';
import { generateDynamicFormat } from '../utils/integrationRuleUtils';

export const DynamicReturnFormat: React.FC<IDynamicReturnFormProps> = ({
  dynamicFormatProp,
  handleUpdateDynamicReturnFormat,
}) => {
  const [dynamicFormat, setDynamicFormat] =
    useState<Record<string, DynamicColumnFormat>>(dynamicFormatProp);

  const handleChangeInputFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (!file) return;

      const allowedTypes = ['text/plain', 'text/csv'];
      if (!allowedTypes.includes(file.type)) {
        toast.warning('Selecione um arquivo com extensão .txt ou .csv', {
          position: 'top-center',
          theme: 'dark',
        });
        e.target.value = '';
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result as string;
        const firstLine = content.split('\n')[0];
        const headerRow = firstLine.split('|');
        const parsedDynamicFormat = generateDynamicFormat(headerRow);
        setDynamicFormat(parsedDynamicFormat);
      };
      reader.readAsText(file);
    },
    [],
  );

  const handleChange = useCallback(
    (
      mainKey: string,
      propertyKey:
        | 'isPrimaryKeyColumn'
        | 'isDisplayColumn'
        | 'name'
        | 'isHidden'
        | 'order'
        | 'align',
      newValue: string | boolean,
    ) => {
      if (propertyKey === 'order') {
        const newOrderValue: number = Number(newValue as string);
        const findObjectWithSameOrder = Object.entries(dynamicFormat).find(
          ([key, values]) =>
            key !== mainKey && Number(values.order) === newOrderValue,
        );
        if (findObjectWithSameOrder !== undefined) {
          setDynamicFormat(prevState => {
            const oldOrderValue = Number(prevState[mainKey].order).toString();
            const replaceKeyOrderWithSameValue = findObjectWithSameOrder[0];
            return {
              ...prevState,
              [mainKey]: {
                ...prevState[mainKey],
                [propertyKey]: newOrderValue.toString(),
              },
              [replaceKeyOrderWithSameValue]: {
                ...prevState[replaceKeyOrderWithSameValue],
                [propertyKey as string]: oldOrderValue,
              },
            };
          });
          return;
        }
      }
      setDynamicFormat(prevState => {
        return {
          ...prevState,
          [mainKey]: {
            ...prevState[mainKey],
            [propertyKey]: newValue,
          },
        };
      });
    },
    [dynamicFormat],
  );

  useEffect(() => {
    if (dynamicFormat !== null || dynamicFormat !== undefined)
      handleUpdateDynamicReturnFormat(dynamicFormat);
  }, [dynamicFormat]);

  const renderTableBody = useCallback(() => {
    const dataArray = Object.entries(dynamicFormat);
    dataArray.sort((a, b) => Number(a[1].order) - Number(b[1].order));
    const sortedData = Object.fromEntries(dataArray);
    const mappedData = Object.entries(sortedData).map(([key, values]) => ({
      key,
      values,
    }));
    const disableIsPrimaryKeyColumn =
      mappedData.find(column => column.values?.isPrimaryKeyColumn === true) !==
      undefined;
    const disableIsDisplayColumn =
      mappedData.find(column => column.values?.isDisplayColumn === true) !==
      undefined;
    return Object.entries(sortedData).map(([columnName, column]) => {
      return (
        <TableRow key={columnName}>
          <TableCell className="py-2">{columnName}</TableCell>
          <TableCell className="py-2" align="center">
            <Checkbox
              name="isPrimaryKeyColumn"
              checked={column?.isPrimaryKeyColumn}
              onCheckedChange={checked =>
                handleChange(columnName, 'isPrimaryKeyColumn', checked)
              }
              disabled={
                column?.isDisplayColumn ||
                column?.isHidden ||
                (disableIsPrimaryKeyColumn && !column?.isPrimaryKeyColumn)
              }
            />
          </TableCell>
          <TableCell className="py-2" align="center">
            <Checkbox
              name="isDisplayColumn"
              checked={column?.isDisplayColumn}
              onCheckedChange={checked =>
                handleChange(columnName, 'isDisplayColumn', checked)
              }
              disabled={
                column?.isPrimaryKeyColumn ||
                column?.isHidden ||
                (disableIsDisplayColumn && !column?.isDisplayColumn)
              }
            />
          </TableCell>
          <TableCell className="py-2" align="center">
            <Checkbox
              name="showColumn"
              checked={!column?.isHidden}
              onCheckedChange={checked =>
                handleChange(columnName, 'isHidden', !checked)
              }
              disabled={column?.isPrimaryKeyColumn || column?.isDisplayColumn}
            />
          </TableCell>
          <TableCell className="py-2">
            <Input
              name="columnName"
              defaultValue={column.name}
              onBlur={e => {
                if (column.name !== e.target.value)
                  handleChange(columnName, 'name', e.target.value);
              }}
              disabled={column?.isHidden}
            />
          </TableCell>
          <TableCell className="py-2" align="center">
            <Select
              name="align"
              onValueChange={value => handleChange(columnName, 'align', value)}
              defaultValue={column.align}
              disabled={column?.isHidden}
            >
              <SelectTrigger className="w-[130px]">
                <SelectValue placeholder="Alinhamento" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="left">Esquerda</SelectItem>
                <SelectItem value="center">Centralizado</SelectItem>
                <SelectItem value="right">Direita</SelectItem>
              </SelectContent>
            </Select>
          </TableCell>
          <TableCell className="py-2" align="center">
            <div className="flex flex-nowrap gap-1">
              <TooltipWrapper value="Mover para cima">
                <Button
                  variant="outline"
                  size="sm"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    handleChange(
                      columnName,
                      'order',
                      (Number(column.order) - 1).toString(),
                    );
                  }}
                  disabled={Number(column.order) <= 1}
                >
                  <ArrowUpIcon className="h-4 w-4" />
                </Button>
              </TooltipWrapper>
              <TooltipWrapper value="Mover para baixo">
                <Button
                  variant="outline"
                  size="sm"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    handleChange(
                      columnName,
                      'order',
                      (Number(column.order) + 1).toString(),
                    );
                  }}
                  disabled={Number(column.order) === dataArray.length}
                >
                  <ArrowDownIcon className="h-4 w-4" />
                </Button>
              </TooltipWrapper>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }, [dynamicFormat]);

  return (
    <Card className="my-4 shadow-md">
      <Accordion type="single" collapsible defaultValue="returnFormat">
        <AccordionItem value="returnFormat" className="border-b-0">
          <AccordionTrigger className="px-1">
            <CardHeader>
              <Label htmlFor="formatFile">Formatação do retorno</Label>
            </CardHeader>
          </AccordionTrigger>
          <AccordionContent>
            <CardContent>
              <Input
                id="formatFile"
                type="file"
                accept=".txt,.csv"
                onChange={handleChangeInputFile}
                className="max-w-[50%] my-2 hover:cursor-pointer"
              />
              <div className="rounded-md border shadow overflow-auto box-border max-h-[400px]">
                <Table className="w-full h-full">
                  <TableHeader className="z-[60]">
                    <TableRow>
                      <TableHead>Campo</TableHead>
                      <TableHead className="text-center text-nowrap">
                        É chave?
                      </TableHead>
                      <TableHead className="text-center text-nowrap">
                        É descrição?
                      </TableHead>
                      <TableHead className="text-center text-nowrap">
                        Exibir na consulta?
                      </TableHead>
                      <TableHead className="text-nowrap">
                        Nome na consulta
                      </TableHead>
                      <TableHead className="text-center">Alinhamento</TableHead>
                      <TableHead className="text-center">Ordem</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </div>
            </CardContent>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
