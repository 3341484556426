import React, { useEffect, useState } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { FilterIcon, Loader2 } from 'lucide-react';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { DateRange } from 'react-day-picker';
import { DatePickerWithRange } from 'src/components/DateRangePicker';
import CustomSelect from 'src/components/Select';
import { Button } from '../../../components/ui/button';
import { useAuth } from '../../../context/AuthContext';
import { OptionType } from '../Widgets/column-graph';

interface FilterButtonProps {
  readonly users?: { id: string; name: string }[];
}

export const FunnelFiltersButton: React.FC<FilterButtonProps> = ({ users }) => {
  const {
    filtersData,
    setFiltersData,
    areas,
    graphType,
    setGraphType,
    loading,
  } = useWorkspaceFilters();
  const user = useAuth();
  const userAreaId = user.user.areaId;
  const userRole = user.user.role.name;
  const [selectedUser, setSelectedUser] = useState<OptionType[]>([user.user]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAreas, setSelectedAreas] = useState<OptionType[] | undefined>(
    undefined,
  );
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const graphTypeOptions = [
    { id: 'funnel', name: 'Funil' },
    { id: 'fixed-funnel', name: 'Funil fixo' },
    { id: 'pie', name: 'Pizza' },
  ];

  const handleApplyFilter = () => {
    setFiltersData(oldFilter => ({
      ...oldFilter,
      funnel: {
        startDate: dateRange?.from?.toISOString() || undefined,
        endDate: dateRange?.to?.toISOString() || undefined,
        user_id: selectedUser[0].id || undefined,
        area_id: selectedAreas ? selectedAreas[0].id : undefined,
      },
    }));
  };

  useEffect(() => {
    if (filtersData.funnel?.startDate && filtersData.funnel?.endDate) {
      setDateRange({
        from: new Date(filtersData.funnel.startDate),
        to: new Date(filtersData.funnel.endDate),
      });
    }

    if (
      filtersData &&
      filtersData.funnel &&
      filtersData.funnel?.user_id &&
      users
    ) {
      setSelectedUser([
        users.find(user => user.id === filtersData.funnel?.user_id) || users[0],
      ]);
    }

    if (
      filtersData &&
      filtersData.funnel &&
      filtersData.funnel?.area_id &&
      areas &&
      userAreaId
    ) {
      const findArea = areas.find(
        area =>
          (area.id === filtersData.funnel?.area_id &&
            (userRole === 'ROLE_ADMIN' ||
              (userRole !== 'ROLE_ADMIN' && area.id === userAreaId))) ||
          (area.id !== filtersData.funnel?.area_id &&
            userAreaId === area.id &&
            userRole !== 'ROLE_ADMIN'),
      );
      setSelectedAreas(findArea !== undefined ? [findArea] : undefined);
    }
  }, [filtersData.funnel, areas, users, user]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {loading ? (
        <Button
          size="icon"
          variant="default"
          className="h-[32px] w-[50px]"
          disabled
        >
          <Loader2 className="h-5 w-5 animate-spin" />
        </Button>
      ) : (
        <PopoverTrigger>
          <Button
            size="icon"
            variant={
              filtersData.column &&
              (filtersData.column.startDate ||
                filtersData.column.endDate ||
                filtersData.column.user_id ||
                filtersData.column.field_ids.length > 0)
                ? 'default'
                : 'outline'
            }
            className="h-[32px] w-[50px]"
          >
            <FilterIcon size={18} />
          </Button>
        </PopoverTrigger>
      )}
      <PopoverContent className="flex flex-col w-full h-full gap-3">
        <DatePickerWithRange
          date={dateRange}
          setDate={setDateRange}
          placeholder="Período"
        />
        {user.user.role.name === 'ROLE_ADMIN' && (
          <>
            <CustomSelect
              placeholder="Área"
              items={areas || []}
              selectedItems={selectedAreas}
              setSelectedItems={setSelectedAreas}
            />
            <CustomSelect
              items={users || []}
              selectedItems={selectedUser}
              setSelectedItems={setSelectedUser}
              placeholder="Usuário"
            />
          </>
        )}
        <CustomSelect
          items={graphTypeOptions}
          selectedItems={[
            {
              id: graphType,
              name: graphTypeOptions.find(g => g.id === graphType)?.name || '-',
            },
          ]}
          setSelectedItems={items =>
            setGraphType(items[0].id as 'pie' | 'funnel' | 'fixed-funnel')
          }
          placeholder="Tipo de gráfico"
        />
        <Button
          onClick={() => {
            handleApplyFilter();
            setOpen(false);
          }}
        >
          Aplicar
        </Button>
        <Button
          type="reset"
          variant="secondary"
          onClick={() => {
            setFiltersData({
              ...filtersData,
              funnel: {
                startDate: undefined,
                endDate: undefined,
                user_id: user.user.id,
                area_id: userAreaId,
              },
            });
            setDateRange(undefined);
            setSelectedUser([user.user]);
            setOpen(false);
          }}
        >
          Limpar
        </Button>
      </PopoverContent>
    </Popover>
  );
};
