import React from 'react';
import { ISignersTable } from '../Signature.i';
import { SignatureTableUI } from './components/SignatureTableUI';

export const SignersTable: React.FC<ISignersTable> = ({
  signers,
  signedDocumentId,
  hasSignatureEnded,
  collectionStarted,
  handleFetchSignatures,
  card_id,
  signature_id,
  expiryDate,
  resendEmail,
  resendDocument,
  hasSignerConcluded,
}) => {
  return (
    <SignatureTableUI
      signers={signers}
      signedDocumentId={signedDocumentId}
      hasSignatureEnded={hasSignatureEnded}
      collectionStarted={collectionStarted}
      handleFetchSignatures={handleFetchSignatures}
      card_id={card_id}
      signature_id={signature_id ?? ''}
      expiryDate={expiryDate}
      resendEmail={resendEmail}
      resendSignedDocument={resendDocument}
      hasSignerConcluded={hasSignerConcluded}
    />
  );
};
