/* eslint-disable camelcase */
import React from 'react';

import { Box, createStyles, DialogTitle, makeStyles } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

interface ModalProps {
  open: boolean;
  closeModal: (open: boolean) => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  title?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: '100%',
      height: '80%',
      minWidth: '300px',
      maxWidth: '850px',
      maxHeight: '100%',
      overflow: 'auto',
    },
    children: {
      padding: 0,
      margin: 0,
      height: '100%',
      width: '100%',
      maxWidth: '850px',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'auto',
    },
  }),
);

export const ModalCSV: React.FC<ModalProps> = ({
  open,
  closeModal,
  children,
  maxWidth,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      onClose={closeModal}
      maxWidth={maxWidth || false}
      {...rest}
    >
      <DialogTitle id={title}>
        <Box height="22px">
          <span>{title}</span>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>{children}</DialogContent>
    </Dialog>
  );
};
