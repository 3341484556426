/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  Container,
  createStyles,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Marker } from 'react-mark.js';
import { styles } from './styles';

interface ModalProps {
  open: boolean;
  closeModal: () => void;
  title: string;
  titleLeft: string;
  taskInstructions: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export function InstructionsModal({
  open,
  closeModal,
  title,
  titleLeft,
  taskInstructions = '',
}: ModalProps): JSX.Element {
  const classes = useStyles();

  // const [instructions, setInstructions] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const goBack = () => {
    closeModal();
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      onClose={closeModal}
      aria-labelledby={title}
      maxWidth="lg"
      // {...rest}
    >
      <DialogTitle id={taskInstructions}>
        <Box marginTop="10px" justifyContent="space-between">
          <Box height="22px">
            <span>{title}</span>
          </Box>
          <Box>
            <span style={{ fontWeight: 'bolder' }}>{titleLeft}</span>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.children}>
        <Container>
          <Box>
            <TextField
              label="Pesquisar"
              name="find"
              margin="dense"
              variant="outlined"
              fullWidth
              className={classes.muiInputSearch}
              value={searchTerm}
              onChange={value => {
                setSearchTerm(value.target.value);
              }}
            />
            <Box width="100%" className={classes.instructionsLabel}>
              Instruções
            </Box>
            <Marker mark={searchTerm}>
              <Typography className={classes.instructionContent}>
                {taskInstructions}
              </Typography>
            </Marker>
            <Box display="flex" justifyContent="flex-end" mr="1px">
              <ButtonMaterialUI
                className={classes.modalButtons}
                style={{
                  height: '40px',
                  width: '90px',
                  borderRadius: '3px',
                }}
                size="small"
                variant="contained"
                onClick={goBack}
              >
                Fechar
              </ButtonMaterialUI>
            </Box>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
