/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  Box,
  Button,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import { IFillRuleProps } from './FillRules.i';
import { boolType } from '../../utils/fillRuleUtils';
import { RichTextEditor } from '../RichTextEditor/ckeditor';
import { ExpressionInstructions } from './components/ExpressionInstruction';
import { Conditions } from '../Conditions';
import { styles } from './styles';
import { useFillRule } from './hooks/useFillRule';

export const useStyles = makeStyles(() => createStyles(styles()));

export const FillRule: React.FC<IFillRuleProps> = ({
  closeModal,
  customField,
  handleRefreshCf,
  fillRuleIdToEdit,
  customFields,
  accountCustomFields,
  numericCustomFields,
  uuidToSlugMap,
  fieldFillRules,
  phases,
  tasks,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...rest
}) => {
  const classes = useStyles();
  const {
    filteredActionType,
    actionType,
    setActionType,
    actionValue,
    setActionValue,
    valueError,
    conditionsExpressionInput,
    setConditionsExpressionInput,
    onSubmitFillRule,
    savingRule,
  } = useFillRule({
    closeModal,
    handleRefreshCf,
    customField,
    fillRuleIdToEdit,
    uuidToSlugMap,
    fieldFillRules,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      mb={4}
      width="100%"
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Autocomplete
          id="actionType"
          fullWidth
          getOptionLabel={type => type.label}
          getOptionSelected={(type, value) => type.id === value.id}
          options={filteredActionType}
          value={actionType}
          onChange={(e, value) => {
            setActionType(value);
          }}
          disableClearable
          style={{ minWidth: '300px', maxWidth: '50%' }}
          loadingText="Carregando"
          renderInput={rest => (
            <TextField
              {...rest}
              id="actionType"
              label="Tipo de ação"
              margin="dense"
              name="phase"
              variant="outlined"
            />
          )}
        />
        {actionType.id === 'ASSIGN_BOOL' ||
        actionType.id === 'ASSIGN_AND_BLOCK_BOOL' ||
        actionType.id === 'ASSIGN_AND_HIDE_BOOL' ? (
          <Autocomplete
            id="actionValue"
            disableClearable
            fullWidth
            filterSelectedOptions={false}
            options={boolType}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.id === value.id}
            value={
              boolType[boolType.findIndex(value => value.id === actionValue)] ||
              null
            }
            onChange={(e, value) => {
              setActionValue(value.id);
            }}
            loadingText="Carregando"
            style={{ minWidth: '300px', maxWidth: '50%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Valor"
                margin="dense"
                name="actionValue"
                variant="outlined"
                size="small"
                error={!!valueError && !actionValue}
                helperText={!!valueError && !actionValue ? valueError : ''}
              />
            )}
          />
        ) : (actionType.id === 'ASSIGN_PREDEFINED' ||
            actionType.id === 'RESTRICT_PREDEFINED' ||
            actionType.id === 'ASSIGN_AND_HIDE_PREDEFINED' ||
            actionType.id === 'ASSIGN_AND_BLOCK_PREDEFINED') &&
          customField.predefined_values ? (
          <Autocomplete
            id="actionValue"
            multiple
            disableCloseOnSelect
            fullWidth
            options={customField.predefined_values}
            getOptionLabel={predefinedValues => predefinedValues}
            getOptionSelected={(predefinedValues, value) =>
              predefinedValues === value
            }
            value={actionValue ? actionValue.toString().split(',') : []}
            onChange={(e, value) => {
              setActionValue(value);
            }}
            loadingText="Carregando"
            style={{ minWidth: '300px', maxWidth: '50%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Valor"
                margin="dense"
                name="actionValue"
                variant="outlined"
                autoComplete="off"
                size="small"
                error={!!valueError && !actionValue}
                helperText={!!valueError && !actionValue ? valueError : ''}
              />
            )}
          />
        ) : null}
        {(actionType.id === 'ASSIGN_NUMERIC' ||
          actionType.id === 'ASSIGN_AND_HIDE_NUMERIC' ||
          actionType.id === 'ASSIGN_AND_BLOCK_NUMERIC') && (
          <>
            <div
              style={
                !!valueError && !actionValue
                  ? {
                      border: '2px solid #f44436',
                      padding: '1px',
                      borderRadius: '3px',
                    }
                  : undefined
              }
            >
              <RichTextEditor
                propData={actionValue as string}
                setPropData={setActionValue}
                suggestionsProps={numericCustomFields}
                suggestOnlyCustomFields
                toolbarHidden
                small
              />
            </div>
            {!!valueError && !actionValue && (
              <Typography
                style={{
                  color: '#f44436',
                  paddingLeft: '6px',
                  fontSize: '12px',
                }}
              >
                {valueError}
              </Typography>
            )}
          </>
        )}
        {(actionType.id === 'ASSIGN_NUMERIC' ||
          actionType.id === 'ASSIGN_AND_HIDE_NUMERIC' ||
          actionType.id === 'ASSIGN_AND_BLOCK_NUMERIC') && (
          <ExpressionInstructions />
        )}
      </Box>
      {customField.id && customField.type && (
        <Conditions
          conditionsExpressionInput={
            conditionsExpressionInput || [[{ name: '', type: '' }]]
          }
          setConditionsExpressionInput={setConditionsExpressionInput}
          customFields={customFields}
          phases={phases}
          tasks={tasks}
          accountCustomFields={accountCustomFields}
        />
      )}
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        mt={2}
      >
        <Button
          className={classes.goBackButton}
          size="medium"
          variant="contained"
          disabled={savingRule}
          onClick={() => closeModal()}
        >
          Voltar
        </Button>
        <Button
          className={classes.addButton}
          size="medium"
          variant="contained"
          color="inherit"
          disabled={savingRule}
          onClick={onSubmitFillRule}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
};
