import {
  AlertCircleIcon,
  CheckCircle2Icon,
  ClockIcon,
  HelpCircleIcon,
} from 'lucide-react';

import { TaskTypes } from 'src/utils/taskTypes.constants';
import { returnColorByTaskStatus } from 'src/utils/taskUtils';

export const statuses = [
  {
    value: 'Não agendada',
    label: 'Não agendada',
    icon: HelpCircleIcon,
    fillColor: returnColorByTaskStatus('Não agendada'),
    color: '#fff',
  },
  {
    value: 'Agendada',
    label: 'Agendada',
    icon: ClockIcon,
    fillColor: returnColorByTaskStatus('Agendada'),
    color: '#fff',
  },
  {
    value: 'Agendamento vencido',
    label: 'Vencido',
    icon: AlertCircleIcon,
    fillColor: returnColorByTaskStatus('Agendamento vencido'),
    color: '#fff',
  },
  {
    value: 'Concluída',
    label: 'Concluída',
    icon: CheckCircle2Icon,
    fillColor: returnColorByTaskStatus('Concluída'),
    color: '#fff',
  },
];

export const taskOrigin = [
  {
    value: 'P',
    label: 'Processo',
  },
  {
    value: 'A',
    label: 'Avulsa',
  },
];

export const types = Object.values(TaskTypes)
  .map(task => ({
    value: task.name,
    label: task.name,
  }))
  .sort(
    (a: { label: string }, b: { label: string }) =>
      -b.label.localeCompare(a.label),
  );
