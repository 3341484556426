import { brazilianStatesAbbreviations } from './brazilianStates';

export type FixedAccountField = {
  id: string;
  name: string;
  type: string;
  phase: { name: string };
  predefinedValues?: string[];
};

export const fixedAccountFields: FixedAccountField[] = [
  {
    id: 'name',
    name: 'Nome',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'cpfCnpj',
    name: 'CPF/CNPJ',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'zipCode',
    name: 'CEP',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'phone',
    name: 'Telefone',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'state',
    name: 'Estado',
    type: 'STRING',
    phase: { name: 'Fixo' },
    predefinedValues: brazilianStatesAbbreviations,
  },
  {
    id: 'street',
    name: 'Rua',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'numeral',
    name: 'Número',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'complement',
    name: 'Complemento',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'district',
    name: 'Bairro',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'cityCodeIBGE',
    name: 'Código IBGE',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'corporateName',
    name: 'Razão Social',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
  {
    id: 'city',
    name: 'Cidade',
    type: 'STRING',
    phase: { name: 'Fixo' },
  },
];
