/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import { Container } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import api from '../../services/api';
import { IForm, IFormParams } from './FillForm.i';
import NotFound from '../_layouts/NotFound';
import { DefaultTemplate } from './FormTemplates/DefaultTemplate';

export const FillForm: React.FC = () => {
  const params = useParams<IFormParams>();
  const { form_id } = params;

  const [form, setForm] = useState<IForm>();
  const [template, setTemplate] = useState<string>('default');

  useEffect(() => {
    if (form_id?.length > 0) {
      api.get(`/publicForms/${form_id}`).then(response => {
        setForm(response.data);
      });
    }
  }, [form_id]);

  if (form_id === undefined || form?.isActive === false) {
    return <NotFound />;
  }

  return (
    <Container
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'auto',
        backgroundColor:
          form && form?.styles !== null && form.styles?.color.border
            ? form.styles.color.border
            : undefined,
      }}
      maxWidth={false}
    >
      {form_id && form?.isActive && template === 'default' ? (
        <DefaultTemplate
          form_id={form_id}
          title={form?.title}
          description={form.description}
          urlTitleImage={form.urlTitleImage}
          usesNameForAccountAndContact={form.usesNameForAccountAndContact}
          customFields={form.customFields.sort(
            (entryA, entryB) => entryA?.order - entryB?.order,
          )}
          accountFields={form.accountFields}
          fixedAccountFields={form.fixedAccountFields}
          backgroundColors={
            form?.styles?.color || {
              primary: '#007dc6',
              secondary: '#53a546',
              header: '#DEE6F0',
              body: '#eaeeed',
              content: '#fafafa',
              footer: '#DEE6F0',
              border: '#dfdfdf',
            }
          }
          templateFooter={form?.footer || ''}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};
