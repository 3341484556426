import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../services/api';

export interface IStorageData {
  message: string;
  usedStorage: number;
  storageLimit: number;
}

const ColorLinearProgress = withStyles({
  barColorPrimary: {
    backgroundColor: '#4a84e9',
  },
  barColorSecondary: {
    backgroundColor: '#f74e42',
  },
})(LinearProgress);

const ShowStorageData: React.FC = () => {
  const [storageData, setStorageData] = useState<IStorageData | null>(null);

  const fetchStorageData = async () => {
    try {
      const response = await api.get('files/usedStorage');
      setStorageData(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching storage data:', error);
    }
  };

  useEffect(() => {
    fetchStorageData();
  }, []);

  const storagePercentage =
    storageData !== null
      ? (storageData.usedStorage / storageData.storageLimit) * 100
      : 0;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Armazenamento de arquivos
        </Typography>
        {storageData !== null &&
          (storagePercentage > 100 ? (
            <Box display="flex" alignItems="center">
              <ColorLinearProgress
                variant="determinate"
                color="secondary"
                value={100}
                style={{
                  flexGrow: 1,
                  marginRight: '1rem',
                  height: '0.8rem',
                  borderRadius: '0.4rem',
                }}
              />
              <Typography variant="body1">
                {`${storagePercentage.toFixed(2)}%`}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <ColorLinearProgress
                variant="determinate"
                value={Math.min(storagePercentage, 100)}
                style={{
                  flexGrow: 1,
                  marginRight: '1rem',
                  height: '0.8rem',
                  borderRadius: '0.4rem',
                }}
              />
              <Typography variant="body1">
                {`${storagePercentage.toFixed(2)}%`}
              </Typography>
            </Box>
          ))}
        <Box mt={1}>
          <Typography>
            {storageData !== null
              ? `Utilizando ${storageData.usedStorage} MB de ${storageData.storageLimit} MB`
              : 'Loading...'}
            {storageData !== null &&
              storageData.usedStorage > storageData.storageLimit && (
                <Typography>Limite de armazenamento excedido</Typography>
              )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ShowStorageData;
