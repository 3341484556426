import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface ExpandIconProps {
  expanded: boolean;
  toggleBox: () => void;
}

export const ExpandIcon = ({
  expanded,
  toggleBox,
}: ExpandIconProps): JSX.Element => {
  return expanded ? (
    <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={toggleBox} />
  ) : (
    <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={toggleBox} />
  );
};
