import React from 'react';

import { Chip } from '@material-ui/core/';

interface IAccount {
  id: string;
  name: string;
}

interface IContact {
  id: string;
  name: string;
}

interface IUser {
  id: string;
  name: string;
}

interface IAutoCompleteTagProps {
  tagValue: IAccount[] | IContact[] | IUser[];
  getTagProps: ({ index }: { index: number }) => Record<string, unknown>;
}

export function CustomRenderGreenTags({
  tagValue,
  getTagProps,
}: IAutoCompleteTagProps): JSX.Element {
  return (
    <>
      {tagValue.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          key={option.name}
          label={option.name}
          size="small"
          style={{
            fontSize: '12px',
            color: '#fafafa',
            margin: '1px',
            backgroundColor: '#53a546',
          }}
        />
      ))}
    </>
  );
}
