import { StyleRules } from '@material-ui/core';

export const predefinedStyles = (): StyleRules => ({
  predefinedFieldContent: {
    '&:last-child': {
      marginBottom: '1.5rem',
    },
  },
  predefinedFieldOptionsCheckBox: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  predefinedInput: {
    display: 'flex',
    flexWrap: 'nowrap',
    '&& div.MuiAutocomplete-endAdornment': {
      position: 'relative',
      order: -1,
      display: 'flex',
      flexWrap: 'nowrap',
      /* '& > button.MuiAutocomplete-clearIndicator': {
        position: 'relative', // default was absolute. we make it relative so that it is now within the flow of the other two elements
        order: 5,
      }, */
    },
  },
});
