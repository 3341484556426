/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React from 'react';

import {
  Box,
  Button,
  createStyles,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { X } from 'lucide-react';
import { COLORS } from '../../styles/colors';

interface ModalProps {
  open: boolean;
  closeModal: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  height?: string;
  contentWidth?: string;
  title?: string;
  showGoBackButton?: boolean;
  disableEscapeKeyDown?: boolean;
  disablebackdropClick?: boolean;
  titleBackgroundColor?: string;
  showSubmitButton?: boolean;
  submitFunction?: () => void;
}

const useStyles: any = makeStyles(() =>
  createStyles({
    dialog: {
      boxSizing: 'border-box',
      // width: '100%',
      height: 'auto',
      minHeight: '50%',
      maxHeight: '100%',
      overflow: 'auto',
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#53a546',
      },
      '& label.Mui-focused': {
        color: COLORS.BLUE,
      },
      '& .MuiInputLabel-shrink': {
        color: COLORS.BLUE,
        fontWeight: 500,
        '& fieldset': {
          borderColor: COLORS.BLUE,
        },
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: COLORS.BLUE,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: COLORS.BLUE,
        },
      },
    },
    children: {
      padding: 0,
      margin: 0,
      height: '100%',
      width: '100%',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
    },
    goBackButton: {
      textDecoration: 'none',
      '&:hover': { textDecoration: 'none' },
      fontSize: '12px',
      marginRight: '0.5rem',
    },
    defaultButton: {
      height: '35px',
      fontSize: '12px',
      color: '#FFF',
      margin: 0,
    },
    submitButton: {
      height: '35px',
      width: '90px',
      fontSize: '12px',
      color: '#FFF',
      background: 'linear-gradient(to right, #007dc6, #2f98fa)',
      margin: 0,
    },
  }),
);

export const ModalDefaultWidthLg: React.FC<ModalProps> = ({
  open,
  closeModal,
  children,
  maxWidth = 'lg',
  title = 's',
  showGoBackButton = false,
  disableEscapeKeyDown = false,
  disablebackdropClick = false,
  titleBackgroundColor,
  height = '100%',
  contentWidth,
  showSubmitButton = false,
  submitFunction,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={maxWidth}
      classes={{ paper: classes.dialog }}
      open={open}
      scroll="paper"
      aria-labelledby={title}
      // disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={(event, reason) => {
        if (
          (disablebackdropClick === false && reason === 'backdropClick') ||
          (disableEscapeKeyDown === false && reason === 'escapeKeyDown')
        ) {
          closeModal();
        }
      }}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
        padding="0"
        margin="0"
        style={{
          backgroundColor: titleBackgroundColor || undefined,
        }}
      >
        <DialogTitle
          id={title}
          style={{
            maxWidth: '70%',
            overflow: 'hidden',
            padding: '0.5rem 1rem',
            margin: 0,
            fontSize: '1rem',
          }}
        >
          <span>{title}</span>
        </DialogTitle>
        <Button
          size="small"
          type="button"
          aria-label="Fechar modal"
          onClick={closeModal}
        >
          <X size="1.25rem" />
        </Button>
      </Box>
      <DialogContent className={classes.children}>
        <Box
          style={{
            width: contentWidth || '100%',
            height,
            padding: '0.75rem',
            margin: 0,
          }}
        >
          {children}
          {(showGoBackButton || showSubmitButton) && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt={2}
            >
              {showGoBackButton && (
                <Button
                  className={classes.goBackButton}
                  size="medium"
                  variant="contained"
                  onClick={() => closeModal()}
                >
                  Voltar
                </Button>
              )}
              {showSubmitButton && submitFunction && (
                <Button
                  className={classes.submitButton}
                  size="medium"
                  variant="contained"
                  type="submit"
                  onClick={() => submitFunction()}
                >
                  Salvar
                </Button>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
