import { mask as masker, unMask } from 'remask';

export const cpfCnpjPattern = ['999.999.999-99', '99.999.999/9999-99'];

export const isCPFValid = (cpf: string): boolean => {
  const cleanedCPF = cpf.replace(/[^\d]/g, '');

  if (cleanedCPF.length !== 11) {
    return false;
  }

  const digits = cleanedCPF.split('').map(Number);

  // Calculate first digit
  let sum = 0;
  for (let i = 0; i < 9; i += 1) {
    sum += digits[i] * (10 - i);
  }
  const firstDigit = (sum * 10) % 11;

  // Calculate second digit
  sum = 0;
  for (let i = 0; i < 10; i += 1) {
    sum += digits[i] * (11 - i);
  }
  const secondDigit = (sum * 10) % 11;

  return (
    digits[9] === (firstDigit === 10 ? 0 : firstDigit) &&
    digits[10] === (secondDigit === 10 ? 0 : secondDigit)
  );
};

// CNPJ Validation
export const isCNPJValid = (cnpj: string): boolean => {
  const cleanedCNPJ = cnpj.replace(/[^\d]/g, '');

  if (cleanedCNPJ.length !== 14) {
    return false;
  }

  const digits = cleanedCNPJ.split('').map(Number);

  // Calculate first digit
  let sum = 0;
  for (let i = 0; i < 12; i += 1) {
    sum += digits[i] * ((i < 4 ? 5 : 13) - i);
  }
  const firstDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  // Calculate second digit
  sum = 0;
  for (let i = 0; i < 13; i += 1) {
    sum += digits[i] * ((i < 5 ? 6 : 14) - i);
  }
  const secondDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  return digits[12] === firstDigit && digits[13] === secondDigit;
};

export const formatCpfCnpj = (rawValue: string) => {
  const value = unMask(rawValue);
  let formattedValue: string;

  if (value.length <= 11) {
    formattedValue = masker(value, cpfCnpjPattern[0]); // Format as CPF
  } else if (value.length <= 14) {
    formattedValue = masker(value, cpfCnpjPattern[1]); // Format as CNPJ
  } else {
    formattedValue = masker(value, cpfCnpjPattern);
  }
  return formattedValue;
};
