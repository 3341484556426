import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'none',
      background: 'rgba(255,255,255,.8)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 99999,
      '&.show': {
        display: 'flex',
      },
    },
    logo: {
      width: '150px',
      height: 'auto',
      animation: '$loading 2s infinite',
    },
    message: {
      color: '#6a6a6a',
    },
    '@keyframes loading': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.1)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  }),
);
