/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import {
  Box,
  Button as ButtonMaterialUI,
  Container,
  createStyles,
  FormControlLabel,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useUsers } from 'src/hooks/useUsers';
import api from '../../services/api';
import Button from '../../components/Button';
import { IFormData, IEmailParams, IEmailProps, IEmail, IUser } from './Email.i';
import { styles } from './styles';
import { InputIntegerMask } from '../../components/InputIntegerMask';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Email: React.FC<IEmailProps> = ({ closeModal = value => null }) => {
  const classes = useStyles();

  const history = useHistory();
  const { users } = useUsers();
  const params = useParams<IEmailParams>();
  const [email, setEmail] = useState<IEmail>();
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([] as IUser[]);

  const [checked, setChecked] = useState(false);

  const { email_id } = params;

  const goBack = () => {
    if (!email_id) {
      closeModal(true);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (email_id)
      api.get(`/emails/${email_id}`).then(response => {
        const { data } = response;
        setEmail(data);
        setChecked(data.sslAuthentication);

        if (data?.users) {
          setSelectedUsers(data.users);
          const arrayOfUserIds: string[] = [];
          data.users.forEach((v: IUser) => arrayOfUserIds.push(v.id));
          formik.setFieldValue('user_ids', arrayOfUserIds);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email_id]);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    formik.setFieldValue('sslAuthentication', event.target.checked);
  };

  const handleUsersChange = (value: IUser[]) => {
    setSelectedUsers(value || []);

    const arrayOfUserIds: string[] = [];
    value.forEach(v => arrayOfUserIds.push(v.id));
    formik.setFieldValue('user_ids', arrayOfUserIds);
  };

  const initialValues: IFormData = {
    email: email ? email.email : '',
    password: '',
    host: email ? email.host : '',
    port: email ? email.port : '',
    sslAuthentication: email ? email.sslAuthentication : false,
    user_ids: [],
  };

  const formSchema = Yup.object().shape({
    email: email_id
      ? Yup.string()
      : Yup.string()
          .email('Digite um email válido')
          .required('E-mail é obrigatório'),
    password: email_id
      ? Yup.string()
      : Yup.string().required('Senha é obrigatório'),
    host: Yup.string().required('Servidor STPM é obrigatório'),
    port: Yup.string().required('Porta é obrigatório'),
    sslAuthentication: Yup.boolean().optional(),
    user_ids: Yup.array().optional(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (email_id) {
          if (values.password && values.password !== '') {
            await api.put(`/emails/${email_id}`, {
              host: values.host,
              port: values.port,
              password: values.password,
              sslAuthentication: values.sslAuthentication,
              user_ids: values.user_ids,
            });
          } else {
            await api.put(`/emails/${email_id}`, {
              host: values.host,
              port: values.port,
              sslAuthentication: values.sslAuthentication,
              user_ids: values.user_ids,
            });
          }
          setSubmitting(true);

          toast.success('E-mail editado com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } else {
          await api.post('/emails', {
            email: values.email,
            host: values.host,
            port: values.port,
            password: values.password,
            sslAuthentication: values.sslAuthentication,
            user_ids: values.user_ids,
          });
          toast.success('E-mail salvo com sucesso.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
          setTimeout(() => {
            closeModal(true);
          }, 3000);
        }
      } catch (error: any) {
        setSubmitting(false);

        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    },
  });

  return (
    <Container
      maxWidth={false}
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingLeft: 35,
        paddingBottom: 15,
      }}
    >
      <Box className={classes.content}>
        {email_id ? (
          <Typography
            variant="h1"
            component="h2"
            gutterBottom
            className={classes.title}
          >
            Editar E-mail
          </Typography>
        ) : (
          <div />
        )}
        <Box style={{ marginTop: '25px' }}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              id="email"
              label="Conta E-mail"
              name="email"
              type="text"
              margin="dense"
              autoComplete="off"
              autoFocus
              fullWidth
              disabled={!!email_id}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              id="host"
              label="Servidor SMTP"
              name="host"
              margin="dense"
              autoComplete="off"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.host}
              error={formik.touched.host && Boolean(formik.errors.host)}
              helperText={formik.touched.host && formik.errors.host}
            />
            <TextField
              id="port"
              label="Porta"
              name="port"
              margin="dense"
              autoComplete="off"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.port}
              InputProps={{
                inputComponent: InputIntegerMask as any,
              }}
              error={formik.touched.port && Boolean(formik.errors.port)}
              helperText={formik.touched.port && formik.errors.port}
            />
            <TextField
              id="password"
              label="Senha"
              name="password"
              margin="dense"
              autoComplete="off"
              type="password"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    id="sslAuthentication"
                    checked={checked}
                    onChange={handleChangeCheckbox}
                    name="sslAuthentication"
                  />
                }
                label="Autenticação SSL"
                labelPlacement="end"
              />
            </Box>
            <Autocomplete
              id="user_ids"
              disableClearable
              getOptionLabel={user => user.name}
              getOptionSelected={(user, value) => user.id === value.id}
              options={users}
              fullWidth
              multiple
              disableCloseOnSelect
              limitTags={4}
              value={selectedUsers ?? []}
              onChange={(e, value) => handleUsersChange(value)}
              loadingText="Carregando"
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.usersOptionsCheckBox}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={rest => (
                <TextField
                  {...rest}
                  name="user_ids"
                  label="Usuários"
                  margin="dense"
                  error={
                    formik.touched.user_ids && Boolean(formik.errors.user_ids)
                  }
                  helperText={formik.touched.user_ids && formik.errors.user_ids}
                  InputProps={{
                    ...rest.InputProps,
                  }}
                />
              )}
            />

            <ButtonMaterialUI
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              color="inherit"
              onClick={goBack}
            >
              Voltar
            </ButtonMaterialUI>
            <Button
              className={classes.buttonSubmit}
              disabled={formik.isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default Email;
