/* eslint-disable @typescript-eslint/no-explicit-any */
import { parse } from 'mathjs';
import { IAction, IActionValue } from '../components/FillRule/FillRules.i';
import {
  ConditionsExpression,
  IResponseFillRuleCondition,
} from '../components/Conditions/Condition.i';

export const actionTypes = [
  {
    id: 'ASSIGN_BOOL',
    label: 'Atribuir valor',
  },
  {
    id: 'ASSIGN_PREDEFINED',
    label: 'Atribuir valor',
  },
  {
    id: 'RESTRICT_PREDEFINED',
    label:
      'Restringir valor (Opções não definidas na regra aparecerão inativas)',
  },
  {
    id: 'ASSIGN_NUMERIC',
    label: 'Atribuir valor',
  },
  {
    id: 'HIDE_FIELD',
    label: 'Esconder campo',
  },
  {
    id: 'BLOCK_FIELD',
    label: 'Bloquear edição',
  },
  {
    id: 'ASSIGN_AND_BLOCK_NUMERIC',
    label: 'Atribuir valor e bloquear edição',
  },
  {
    id: 'ASSIGN_AND_BLOCK_BOOL',
    label: 'Atribuir valor e bloquear edição',
  },
  {
    id: 'ASSIGN_AND_BLOCK_PREDEFINED',
    label: 'Atribuir valor e bloquear edição',
  },
  {
    id: 'ASSIGN_AND_HIDE_NUMERIC',
    label: 'Atribuir valor e esconder campo',
  },
  {
    id: 'ASSIGN_AND_HIDE_BOOL',
    label: 'Atribuir valor e esconder campo',
  },
  {
    id: 'ASSIGN_AND_HIDE_PREDEFINED',
    label: 'Atribuir valor e esconder campo',
  },
];

export const boolType = [
  {
    id: 'true',
    label: 'Sim',
  },
  {
    id: 'false',
    label: 'Não',
  },
];

interface ISlugAndUUIDMap {
  id: string;
  slug: string;
}
interface INameAndUUIDMap {
  id: string;
  name: string;
}

export const getActionTypeLabel = (type: string): string => {
  const foundType = actionTypes.find(at => at.id === type);
  const label = foundType ? foundType.label : '';
  return label;
};

export function handleFormatActionValue(action: IAction): IActionValue {
  if (
    (action.type === 'ASSIGN_BOOL' ||
      action.type === 'ASSIGN_AND_BLOCK_BOOL' ||
      action.type === 'ASSIGN_AND_HIDE_BOOL') &&
    action.boolToAssign !== undefined
  ) {
    return action.boolToAssign.toString();
  }
  if (
    (action.type === 'ASSIGN_PREDEFINED' ||
      action.type === 'RESTRICT_PREDEFINED' ||
      action.type === 'ASSIGN_AND_BLOCK_PREDEFINED' ||
      action.type === 'ASSIGN_AND_HIDE_PREDEFINED') &&
    action.predefinedValuesToAssign
  ) {
    return action.predefinedValuesToAssign;
  }
  if (
    (action.type === 'ASSIGN_NUMERIC' ||
      action.type === 'ASSIGN_AND_BLOCK_NUMERIC' ||
      action.type === 'ASSIGN_AND_HIDE_NUMERIC') &&
    action.arithmeticExpression
  ) {
    return action.arithmeticExpression;
  }

  return null;
}

export function handleFormatResponseExpression(
  expression: ConditionsExpression,
  conditions: IResponseFillRuleCondition[],
  setStateFunction: (value: ConditionsExpression) => void,
): void {
  if (conditions.length > 0 && expression.length > 0) {
    const formattedExp = expression.map((exp: any) => {
      if (Array.isArray(exp)) {
        const itemArray = exp.map(item => {
          if (item !== 'OR' && item !== 'AND') {
            const condition = conditions.find(i => i.id === item);

            // remove chaves null e id do objeto que não serão utilizadas
            condition &&
              Object.keys(condition).forEach((key: string) => {
                if (condition[key] === null || key === 'id') {
                  delete condition[key];
                }
              });

            // return condition;
            return {
              type: condition?.type || 'customField',
              operator: condition?.operator,
              customField_id: condition?.customField?.id,
              accountField_id: condition?.accountField?.id,
              accountFieldName: condition?.accountFieldName,
              value: condition?.value,
              phase_ids: condition?.phase_ids,
              task_id: condition?.task?.id,
              taskDone: condition?.taskDone,
            };
          }
          return item;
        });
        return itemArray;
      }

      return exp;
    });

    setStateFunction(formattedExp as ConditionsExpression);
  }
}

export function stripHtmlTags(html: string): string {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export function validateArithmeticExpression(expression: string): boolean {
  const variablePattern =
    /@[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
  const expressionWithPlaceholders = expression
    .replace(variablePattern, '1')
    .trim();

  try {
    const parsedExpression = parse(expressionWithPlaceholders);
    parsedExpression.evaluate();

    return true;
  } catch (error) {
    return false;
  }
}

export function replaceFieldsWithUUIDs(
  input: string,
  customFields: ISlugAndUUIDMap[],
): string {
  const regex = /@([.\w-]+)/g;

  const replacedInput = input.replace(regex, (match, slug) => {
    const field = customFields.find(cf => cf.slug === slug);
    if (field) {
      return `@${field.id}`;
    }
    return match;
  });

  return replacedInput;
}

export function replaceUUIDsWithSlugs(
  input: string,
  customFields: ISlugAndUUIDMap[],
): string {
  const regex = /@[a-f0-9-]+/g;

  const replacedInput = input.replace(regex, match => {
    const uuid = match.slice(1);
    const field = customFields.find(cf => cf.id === uuid);
    if (field) {
      return `@${field.slug}`;
    }
    return match;
  });

  return replacedInput;
}

export function mapCustomFieldsToNameAndSlugAsID(
  customFields: any[],
): INameAndUUIDMap[] {
  return customFields.map(cf => ({
    id: `@${cf.slug}`,
    name: cf.name,
  }));
}

export function mapCustomFieldsToSlugAndUUID(
  customFields: any[],
): ISlugAndUUIDMap[] {
  return customFields.map(cf => ({
    id: cf.id,
    slug: cf.slug,
  }));
}
