/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext } from 'react';

interface TaskModalContextType {
  handleOpenModal: (id: string) => void;
  handleDeleteTask: (id: string) => void;
}

export const TaskModalContext = createContext<TaskModalContextType | undefined>(
  undefined,
);

export const useTaskModalContext = (): TaskModalContextType => {
  const context = useContext(TaskModalContext);
  if (!context) {
    throw new Error(
      'useTaskModalContext must be used within a TaskModalContextProvider',
    );
  }
  return context;
};
