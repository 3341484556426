import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: 500,
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
  },
  buttonSubmit: {
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  columnActions: {
    width: '60px',
  },

  divider: {
    marginTop: '20px',
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '30px',
  },
  titulo2: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
    fontSize: '1.0em',
    fontWeight: 400,
  },
  usersOptionsCheckBox: {
    alignItems: 'start',
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
});
