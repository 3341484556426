import React, { createContext, useContext, ReactNode } from 'react';

interface FormikErrorProviderProps {
  touched: Record<string, boolean>;
  errors: Record<string, string>;
  hasError: (field: string) => boolean;
  children: ReactNode;
}

/* interface FormikErrorProviderProps {
  // if necessary to use the whole formik object
  formik: ReturnType<typeof useFormik>;
  children: ReactNode;
} */

interface FormikErrorContextValue {
  touched: Record<string, boolean>;
  errors: Record<string, string>;
  hasError: (field: string) => boolean;
}

const FormikErrorContext = createContext<FormikErrorContextValue | undefined>(
  undefined,
);

export const FormikErrorProvider: React.FC<FormikErrorProviderProps> = ({
  touched,
  errors,
  hasError,
  children,
}) => {
  const contextValue: FormikErrorContextValue = {
    touched,
    errors,
    hasError,
  };

  return (
    <FormikErrorContext.Provider value={contextValue}>
      {children}
    </FormikErrorContext.Provider>
  );
};

export const useFormikErrorContext = (): FormikErrorContextValue => {
  const context = useContext(FormikErrorContext);

  if (!context) {
    throw new Error(
      'useFormikErrorContext must be used within a FormikErrorProvider',
    );
  }

  return context;
};
