import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardBPM } from 'src/components/CardBPM';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import Modal from 'src/components/Modal_cards';
import { CreateCard } from 'src/components/CreateCard';
import { PlusCircleIcon } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import { COLORS } from 'src/styles/colors';
import { useAuth } from 'src/context/AuthContext';
import api from 'src/services/api';
import { useAccounts } from 'src/hooks/useAccounts';
import { useAreas } from 'src/hooks/useAreas';
import {
  DispatchPhasesByArea,
  IDispatchType,
  WorkspaceCardData,
} from '../types';
import { CardsFiltersButton } from '../components/cardsFilters';

export interface CardsWidgetProps {
  readonly cards: WorkspaceCardData[];
  readonly userOptions: { id: string; name: string }[];
  readonly areasPhases: DispatchPhasesByArea | null;
}

const UserCards: React.FC<CardsWidgetProps> = ({
  cards,
  userOptions,
  areasPhases,
}) => {
  const { user } = useAuth();
  const { accounts } = useAccounts();
  const { areas } = useAreas();
  const { phase_ids, account_ids, keycard } = useWorkspaceFilters();
  const [openModal, setOpenModal] = useState(false);
  const [filteredCards, setFilteredCards] =
    useState<WorkspaceCardData[]>(cards);
  const [dispatchTypes, setDispatchTypes] = useState<IDispatchType[]>([]);

  useEffect(() => {
    if (user.areaId) {
      api.get(`/dispatchTypes/area/${user.areaId}`).then(response => {
        const removePhases = response.data.filter(
          (item: IDispatchType) => item.key !== '',
        );
        setDispatchTypes(removePhases);
      });
    }
  }, [user.areaId]);

  useEffect(() => {
    let updatedCards = cards;
    if (phase_ids.length > 0) {
      updatedCards = updatedCards.filter(card =>
        phase_ids.includes(card.phase.id),
      );
    }
    if (account_ids.length > 0) {
      updatedCards = updatedCards.filter(card =>
        account_ids.includes(card.account.id),
      );
    }
    if (keycard) {
      updatedCards = updatedCards.filter(card =>
        String(card.keyCard).includes(keycard),
      );
    }
    setFilteredCards(updatedCards);
  }, [phase_ids, account_ids, cards]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const phaseOptions = useMemo(() => {
    const allPhases = areas.map(area => {
      return {
        areaId: area.id,
        phases: area.phases.map(phase => ({
          id: phase.id,
          name: phase.name,
        })),
      };
    });

    return allPhases || [];
  }, [areas]);

  const renderCards = () => {
    return filteredCards.map(card => (
      <CardBPM
        dispatchTypes={
          areasPhases && areasPhases[card.area.id]?.length > 0
            ? [...dispatchTypes, ...areasPhases[card.area.id]]
            : [...dispatchTypes]
        }
        data={card}
        key={JSON.stringify(card)}
      />
    ));
  };

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <div className="flex flex-col justify-between px-2 pb-4 items-center max-h-full">
      <div className="flex flex-row gap-4 w-full items-center justify-between pb-2">
        <div className="flex flex-row items-center">
          <p className="text-lg font-bold">Cards ({filteredCards.length})</p>
          <TooltipWrapper
            value="Adicionar novo Card"
            className="font-medium text-xs min-w-fit"
          >
            <Button
              variant="ghost"
              onClick={() => setOpenModal(true)}
              className="rounded-lg p-2 w-fit h-fit"
            >
              <PlusCircleIcon
                className="h-[22px] w-[22px] text-muted-foreground BLUE"
                strokeWidth={1.8}
                color="#fff"
                fill={COLORS.BLUE}
              />
            </Button>
          </TooltipWrapper>
        </div>
        <CardsFiltersButton
          users={userOptions}
          phaseOptions={phaseOptions}
          accountOptions={accounts}
        />
      </div>
      <div className="flex flex-col gap-2 overflow-auto max-h-fit mt-2 mb-4 w-full">
        {renderCards()}
      </div>
      {openModal && (
        <Modal
          open
          closeModal={handleCloseModal}
          title="Adicionar CARD"
          titleLeft=""
          titleRight=""
        >
          <CreateCard closeModal={handleCloseModal} />
        </Modal>
      )}
    </div>
  );
};

export default UserCards;
