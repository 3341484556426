import { AlertCircleIcon } from 'lucide-react';
import React, { useState } from 'react';
import { CheckboxWithText } from 'src/components/CheckboxWithText';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from 'src/components/ui/alert-dialog';
import { deleteSingleTask } from 'src/utils/taskUtils';

interface TaskDeletionDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  taskId: string;
  handleRefreshTaskList: () => void;
  canSendEmail: boolean;
}

export const TaskDeletionDialog: React.FC<TaskDeletionDialogProps> = ({
  open,
  setOpen,
  taskId,
  handleRefreshTaskList,
  canSendEmail = false,
}) => {
  const [checked, setChecked] = useState(canSendEmail);
  const handleOnCheckChange = (value: boolean) => {
    setChecked(value);
  };

  const handleConfirmClick = () => {
    const shouldSendEmail = canSendEmail && checked;
    deleteSingleTask(taskId, shouldSendEmail, handleRefreshTaskList);
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogOverlay className="z-[1500]">
        <AlertDialogContent
          autoFocus
          onKeyDown={e => {
            if (open && e.key === 'Escape') setOpen(false);
          }}
        >
          <AlertDialogHeader>
            <AlertDialogTitle className="flex flex-row items-center">
              Atenção
              <AlertCircleIcon
                className="h-[22px] w-[22px] ml-1 text-muted-foreground"
                fill="#FF3838"
                strokeWidth={1.8}
                color="#fff"
              />
            </AlertDialogTitle>
            <AlertDialogDescription>
              Esta ação não pode ser desfeita e resultará na exclusão permanente
              de todas as informações. Deseja continuar?
            </AlertDialogDescription>
          </AlertDialogHeader>
          {canSendEmail && (
            <CheckboxWithText
              id="shouldSendEmail"
              labelText="Enviar e-mail de notificação aos contatos"
              checked={checked}
              onCheckedChange={handleOnCheckChange}
            />
          )}
          <AlertDialogFooter>
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClick}>
              Continuar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
