/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import { WorkspaceCardData } from 'src/pages/Workspace/types';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { ChevronDown } from 'lucide-react';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { DispatchTypes } from './DispatchTypes';
import Modal from '../Modal_phase';
import { Button } from '../ui/button';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
    },

    item: {
      '&:hover': {
        background: '#eaeaea',
      },
    },
  }),
);

const DISPATCH_TYPES_CONTENT = [
  'SUSPEND',
  'FINISH',
  'ADD_CARD_SUBORDINATE',
  'CHANGE_USER',
  'CHANGE_AREA',
  'CHANGE_CONTACT',
];

interface IContact {
  name: string;
}

interface IAccount {
  id: string;
  name: string;
  contact: IContact;
}

export interface ICard {
  id: string;
  phaseId: string;
  account: IAccount;
  task: {
    name: string;
    dateDeadlineFormat: string;
  };
  keyCard: number;
  percentage: number;
  description: string;
}

export interface IDispatchProps {
  card: WorkspaceCardData;
  dispatchTypes: IDispatchType[];
  closeModal: (open: boolean) => void;
  onChangePhaseDispatch: (card_id: string, phase_id: string) => Promise<void>;
}

interface IDispatchType {
  id: string;
  value: string;
  key: string;
}

export interface IUser {
  id: string;
  name: string;
}

const DispatchOptions: React.FC<IDispatchProps> = ({
  card,
  dispatchTypes = [],
  closeModal,
  onChangePhaseDispatch = () => null,
}) => {
  const classes = useStyles();
  // const { showLoading, hideLoading } = useLoader();
  const [openDispatchCard, setOpenDispatchCard] = useState(false);
  const [openType, setOpenType] = useState<IDispatchType | null>(null);
  const [users, setUsers] = useState<IUser[] | null>(null);
  const { setShouldReloadData } = useWorkspaceFilters();

  useEffect(() => {
    if (openType && openType.key === 'CHANGE_USER') {
      api.get(`/areas/${card.area.id}/users`).then(response => {
        setUsers(response.data);
      });
    }
  }, [openType]);

  useEffect(() => {
    if (openType && openType.key === 'CHANGE_AREA') {
      if (users?.length === 0 || users === null) {
        toast.error('Não foi encontrado nenhum usuário nessa área.', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
          autoClose: 5000,
        });
      }
    }
  }, [users]);

  const handleOpenDispatchCard = (item: IDispatchType) => {
    if (DISPATCH_TYPES_CONTENT.includes(item?.key)) {
      setOpenType(item);
      setOpenDispatchCard(true);
    } else {
      onChangePhaseDispatch(card.id, item.id);
    }
  };

  const handleDispatchClose = () => {
    setOpenDispatchCard(false);
  };

  const handleDispatchCard = () => {
    setShouldReloadData(oldValue => !oldValue);
    setOpenDispatchCard(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="flex align-center h-5">
            <ChevronDown size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          {dispatchTypes.map(
            item =>
              item.key && (
                <DropdownMenuItem
                  key={item.key || item.id}
                  onClick={e => {
                    if (e.target === e.currentTarget) {
                      e.stopPropagation();
                      handleOpenDispatchCard(item);
                    }
                  }}
                  className={`cursor-pointer ${classes.item}`}
                >
                  {item.value}
                </DropdownMenuItem>
              ),
          )}
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Trocar fase</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {dispatchTypes.map(
                  item =>
                    !item.key && (
                      <DropdownMenuItem
                        key={item.id}
                        onClick={e => {
                          if (e.target === e.currentTarget) {
                            e.stopPropagation();
                            handleOpenDispatchCard(item);
                          }
                        }}
                        className="cursor-pointer"
                      >
                        {item.value}
                      </DropdownMenuItem>
                    ),
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuContent>
      </DropdownMenu>
      <Modal
        title={openType?.value || ''}
        titleLeft={card?.account?.name || ''}
        closeModal={() => {
          setOpenType(null);
          setOpenDispatchCard(false);
          closeModal(false);
        }}
        open={openDispatchCard}
      >
        <DispatchTypes
          users={users}
          cardId={card?.id}
          type={openType}
          onClose={handleDispatchClose}
          onDispatchCard={handleDispatchCard}
        />
      </Modal>
    </>
  );
};

export default DispatchOptions;
