import { brazilianStatesAbbreviations } from 'src/utils/brazilianStates';
import { isCNPJValid, isCPFValid } from 'src/utils/cpfCnpjUtils';

export function validateCsvRow(row: string[]): string[] | string {
  const rowErrors = [] as string[];
  // remove aspas duplas no inicio e no fim de cada coluna
  const formattedRow = row.map(col => col.replace(/^"|"$/g, ''));

  if (formattedRow.length !== 12) return ['Número de colunas é inválido'];

  const formattedCpfCnpj = formattedRow[0].replace(/[^\d]/g, '');
  let validCpfCnpj = false;
  if (formattedCpfCnpj?.length === 11) {
    const isCPF = isCPFValid(formattedCpfCnpj);
    validCpfCnpj = isCPF;
  }

  if (formattedCpfCnpj?.length === 14) {
    const isCNPJ = isCNPJValid(formattedCpfCnpj);
    validCpfCnpj = isCNPJ;
  }

  if (!validCpfCnpj) {
    rowErrors.push('CPF/CNPJ inválido ou não informado');
  }

  if (formattedRow[3] && formattedRow[3].length > 0) {
    const formattedZipCode = formattedRow[3].replace(/[^0-9]/g, '');
    if (!/^[\d.-]+$/.test(formattedRow[3]) || formattedZipCode.length !== 8) {
      rowErrors.push('CEP é inválido');
    }
  }

  if (
    formattedRow[4] &&
    formattedRow[4].length > 0 &&
    !brazilianStatesAbbreviations.includes(formattedRow[4])
  ) {
    rowErrors.push('UF é inválida');
  }

  /* if (formattedRow[10] && !formattedRow[11])
    rowErrors.push('Contato foi informado sem e-mail'); */

  if (
    formattedRow[11] &&
    formattedRow[11].length > 0 &&
    // eslint-disable-next-line prettier/prettier
    formattedRow[11] !== '' &&
    // eslint-disable-next-line no-useless-escape
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formattedRow[11])
  )
    rowErrors.push('E-mail de contato é inválido');

  if (rowErrors.length > 0) {
    return rowErrors;
  }

  return 'sem erro';
}
