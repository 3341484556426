/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import {
  Button as ButtonMaterialUI,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Box,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { Autocomplete } from '@material-ui/lab';
import { styles } from '../styles';
import { IAccountFieldOptions, IFixedAccountFields } from '../Form.i';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles(styles(theme)),
);

export const FixedAccountFields: React.FC<IFixedAccountFields> = ({
  fixedAccountFields,
  setFixedAccountFields,
  canCreateAccounts,
  canEditAccounts,
  canCreateContacts,
  canEditContacts,
  usesNameForAccountAndContact,
}: IFixedAccountFields) => {
  const classes = useStyles();

  const fixedAccountFieldOptions: IAccountFieldOptions[] = [
    {
      id: 'accountName',
      group: 'account',
      name: 'Nome da conta',
    },
    {
      id: 'cityCodeIBGE',
      group: 'account',
      name: 'Código IBGE',
    },
    {
      id: 'zipCode',
      group: 'account',
      name: 'CEP',
    },
    {
      id: 'city',
      group: 'account',
      name: 'Município',
    },
    {
      id: 'district',
      group: 'account',
      name: 'Bairro',
    },
    {
      id: 'street',
      group: 'account',
      name: 'Rua',
    },
    {
      id: 'numeral',
      group: 'account',
      name: 'Número',
    },
    {
      id: 'complement',
      group: 'account',
      name: 'Complemento',
    },
    {
      id: 'state',
      group: 'account',
      name: 'Estado',
    },
    {
      id: 'corporateName',
      group: 'account',
      name: 'Razão Social',
    },
    {
      id: 'contactName',
      group: 'contact',
      name: 'Nome do contato',
    },
  ];

  const [selectedAccountField, setSelectedAccountField] =
    useState<IAccountFieldOptions | null>(null);

  function handleDeleteField(index: number) {
    const tempFields = [...fixedAccountFields];
    tempFields.splice(index, 1);
    setFixedAccountFields(tempFields);
  }

  return (
    <Box component={Paper} padding="10px" mt={2}>
      <Box width="100%" className={classes.tableTitle}>
        Campos padrões de conta e contato
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Autocomplete
          id="fixedAccountFieldOptions"
          style={{ width: '45%' }}
          groupBy={opt => (opt.group === 'account' ? 'Conta' : 'Contato')}
          getOptionLabel={opt => opt.name || ''}
          getOptionSelected={(opt, value) => opt.id === value.id}
          getOptionDisabled={opt =>
            (usesNameForAccountAndContact &&
              (opt.id === 'accountName' || opt.id === 'contactName')) ||
            (!canCreateAccounts &&
              !canEditAccounts &&
              opt.group === 'account') ||
            (!canCreateContacts && !canEditContacts && opt.group === 'contact')
          }
          options={fixedAccountFieldOptions.filter(
            (optionField: IAccountFieldOptions) =>
              !fixedAccountFields.find(field => field === optionField.id),
          )}
          onChange={(e, value) => {
            setSelectedAccountField(value);
          }}
          value={selectedAccountField}
          loadingText="Carregando"
          size="small"
          noOptionsText="Sem campos disponíveis."
          renderInput={rest => (
            <TextField
              {...rest}
              id="fixedAccountFieldOptions"
              label="Campos disponíveis"
              margin="dense"
              size="small"
              name="accountFieldOptions"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <ButtonMaterialUI
          onClick={() => {
            if (selectedAccountField !== null) {
              const tempFields = [...fixedAccountFields];
              tempFields.push(selectedAccountField.id);
              setFixedAccountFields(tempFields);
              setSelectedAccountField(null);
            }
          }}
          color="primary"
          size="medium"
          className={classes.addButton}
        >
          + Adicionar
        </ButtonMaterialUI>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="lista de campos da conta">
          <TableHead>
            <TableRow>
              <TableCell align="left">Nome do campo</TableCell>
              <TableCell align="center">Excluir</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fixedAccountFields &&
              fixedAccountFields.length > 0 &&
              fixedAccountFields.map((field, index) => (
                <TableRow key={field}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{ maxWidth: '300px', overflow: 'hidden' }}
                  >
                    {
                      fixedAccountFieldOptions[
                        fixedAccountFieldOptions.findIndex(
                          item => item.id === field,
                        )
                      ]?.name
                    }
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{ width: '40px' }}
                  >
                    <IconButton
                      aria-label="Excluir Campo"
                      size="small"
                      onClick={() => handleDeleteField(index)}
                      disabled={
                        (canCreateAccounts &&
                          fixedAccountFieldOptions[
                            fixedAccountFieldOptions.findIndex(
                              item => item.id === field,
                            )
                          ]?.id === 'accountName') ||
                        (canCreateContacts &&
                          fixedAccountFieldOptions[
                            fixedAccountFieldOptions.findIndex(
                              item => item.id === field,
                            )
                          ]?.id === 'contactName')
                      }
                    >
                      <ClearRoundedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow style={{ paddingLeft: 0, paddingRight: 0 }} />
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
