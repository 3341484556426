/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  createStyles,
  Typography,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import logo from '../../../assets/logo-brasao-bpm-branco.svg';

import logoBackground from '../../../assets/bg-login.jpg';
import logoBackground2 from '../../../assets/bg-login2.jpg';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: '-7%',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '60.3vh',
      backgroundColor: '#333',
      backgroundImage: `url("${logoBackground2}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.up('xs')]: {
        backgroundImage: `url("${logoBackground}")`,
        width: '99.9%',
        height: '55rem',
      },
      [theme.breakpoints.up('sm')]: {
        width: '99.9%',
        height: '115.3vh',
      },
    },
    form: {
      marginTop: '42px',
      marginLeft: '40px',
      margin: '20px',
      padding: theme.spacing(4),
      maxWidth: '500px',
      [theme.breakpoints.up('xs')]: {
        marginTop: '34px',
        marginLeft: '43px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '124px',
        marginLeft: '100px',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      color: '#000000',
    },
    rodaTitle: {
      fontWeight: 500,
      // marginTop: '-15px',
      // marginLeft: '320px',
      fontSize: '0.8rem',
      color: '#007dc6',
      marginTop: theme.spacing(2),
    },
    buttonSubmit: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    submit: {
      background: '#007dc6',
      marginTop: theme.spacing(2),
      width: '30%',
    },
    divider: {
      margin: theme.spacing(4, 0),
    },
    logo: {
      width: '300px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const NotFound: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();

  setTimeout(() => {
    history.push('/');
  }, 4000);

  return (
    <Grid container className={classes.root}>
      <Hidden mdDown>
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-20px',
                marginTop: '149px',
              }}
            />
          </div>
          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Página não encontrada 404 !
            </Typography>
          </Paper>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <div className={classes.logo}>
            <img
              src={logo}
              alt="Logo da Empresa Brasão Sistemas"
              style={{
                width: '250px',
                marginRight: '-28px',
                marginTop: '50px',
              }}
            />
          </div>

          <Paper className={classes.form} elevation={3}>
            <Typography
              variant="h1"
              component="h2"
              gutterBottom
              className={classes.title}
            >
              Página não encontrada 404 !
            </Typography>
          </Paper>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default NotFound;
