/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { brazilianStates } from 'src/utils/brazilianStates';
import { IFixedAccountFieldsProps } from '../FillForm.i';
import { InputZipCodeMask } from '../../../components/InputZipCodeMask';
import { StyledTextField } from '../FormTemplates/DefaultTemplate';

export const FillFormFixedAccountFields: React.FC<IFixedAccountFieldsProps> = ({
  fixedAccountFields,
  fixedAccountFieldValues,
  setFixedAccountFieldValues,
}: IFixedAccountFieldsProps) => {
  if (!fixedAccountFields || fixedAccountFields.length === 0) return null;

  const handleFillFixedAccountField = (fieldName: string, value: string) => {
    if (typeof value !== 'undefined') {
      const tempAccountFields = [...fixedAccountFieldValues];
      const isInArrayOfValues = tempAccountFields.findIndex(
        field => field.name === fieldName,
      );

      if (isInArrayOfValues >= 0) {
        tempAccountFields[isInArrayOfValues] = {
          name: fieldName,
          value,
        };
      } else {
        tempAccountFields.push({
          name: fieldName,
          value,
        });
      }

      setFixedAccountFieldValues(tempAccountFields);
    }
  };

  return (
    <>
      {fixedAccountFields.includes('contactName') && (
        <StyledTextField
          id="contactName"
          label="Nome do contato"
          name="contactName"
          margin="dense"
          autoComplete="off"
          fullWidth
          required
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField('contactName', e.target.value)
          }
          InputProps={{
            inputProps: {
              minLength: 4,
              maxLength: 60,
            },
          }}
        />
      )}
      {fixedAccountFields.includes('accountName') && (
        <StyledTextField
          id="accountName"
          label="Nome da conta"
          name="accountName"
          margin="dense"
          autoComplete="off"
          fullWidth
          required
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField('accountName', e.target.value)
          }
          InputProps={{
            inputProps: {
              minLength: 4,
              maxLength: 60,
            },
          }}
        />
      )}
      {fixedAccountFields.includes('corporateName') && (
        <StyledTextField
          id="corporateName"
          label="Razão social"
          name="corporateName"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField('corporateName', e.target.value)
          }
          InputProps={{
            inputProps: {
              minLength: 4,
              maxLength: 60,
            },
          }}
        />
      )}
      {fixedAccountFields.includes('zipCode') && (
        <StyledTextField
          id="zipCode"
          label="CEP"
          name="zipCode"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField(
              'zipCode',
              e.target.value.replace('-', ''),
            )
          }
          InputProps={{
            inputComponent: InputZipCodeMask as any,
          }}
        />
      )}
      {fixedAccountFields.includes('city') && (
        <StyledTextField
          id="city"
          label="Município"
          name="city"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e => handleFillFixedAccountField('city', e.target.value)}
        />
      )}
      {fixedAccountFields.includes('cityCodeIBGE') && (
        <StyledTextField
          id="cityCodeIBGE"
          label="Código IBGE da Cidade"
          name="cityCodeIBGE"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField('cityCodeIBGE', e.target.value)
          }
        />
      )}
      {fixedAccountFields.includes('street') && (
        <StyledTextField
          id="street"
          label="Rua"
          name="street"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e => handleFillFixedAccountField('street', e.target.value)}
        />
      )}
      {fixedAccountFields.includes('district') && (
        <StyledTextField
          id="district"
          label="Bairro"
          name="district"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField('district', e.target.value)
          }
        />
      )}
      {fixedAccountFields.includes('state') && (
        <Autocomplete
          id="state"
          getOptionLabel={state => state.label}
          getOptionSelected={(state, value) => state.value === value.value}
          options={brazilianStates || []}
          onChange={(e, value) =>
            handleFillFixedAccountField('state', value?.value || '')
          }
          loadingText="Carregando"
          fullWidth
          renderInput={rest => (
            <StyledTextField
              {...rest}
              id="state"
              label="Estado"
              margin="dense"
              autoComplete="off"
              variant="outlined"
              onKeyDown={e => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          )}
        />
      )}
      {fixedAccountFields.includes('numeral') && (
        <StyledTextField
          id="numeral"
          label="Número"
          name="numeral"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e => handleFillFixedAccountField('numeral', e.target.value)}
        />
      )}
      {fixedAccountFields.includes('complement') && (
        <StyledTextField
          id="complement"
          label="Complemento"
          name="complement"
          margin="dense"
          autoComplete="off"
          fullWidth
          variant="outlined"
          onKeyDown={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
          onChange={e =>
            handleFillFixedAccountField('complement', e.target.value)
          }
        />
      )}
    </>
  );
};
