import React from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
  children: React.ReactNode;
  index: string | number;
  value: string | number;
  className: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={className}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
          {/*  <Typography component="span">{children}</Typography> */}
          {/* <Box>{children}</Box> */}
        </Box>
      )}
    </div>
  );
}
