import { StyleRules, Theme } from '@material-ui/core';
import { COLORS } from '../../styles/colors';

export const styles = (theme: Theme): StyleRules => ({
  content: {
    maxWidth: 1000,
    height: 'auto',
    padding: 10,
    '& label.Mui-focused': {
      color: COLORS.BLUE,
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.BLUE,
      fontWeight: 500,
      '& fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.BLUE,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      background: '#53a546',
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: '#53a546',
    },
    /* '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#53a546',
    }, */
  },
  buttonSubmit: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    maxWidth: '100%',
  },
  table1: {
    width: '100%',
  },
  title: { fontSize: '30px', fontWeight: 'bold', marginTop: '30px' },
  tooltip: {
    fontSize: '0.7rem',
    color: '#fff',
    backgroundColor: '#3C7CC6',
    arrow: {
      color: '#3C7CC6',
    },
  },
  checkBox: {
    alignItems: 'start',
  },
  titleTask: {
    fontSize: '16px',
    alignItems: 'center',
  },
  moments: {
    marginTop: '20px',
    width: '100%',
    height: '100%',
  },
  momentContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '@media (max-width:750px)': {
      margin: 0,
    },
  },
  momentSwitch: {
    minWidth: '240px',
    display: 'flex',
    alignItems: 'center',
  },
  momentPhase: {
    marginTop: '-15px',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:763px)': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
  fieldAssociation: {
    marginRight: 10,
  },
  hasTable: {
    paddingLeft: '2rem',
  },
});
