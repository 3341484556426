import api from './api';

export interface IGetCardFieldApi {
  id: string;
  name: string;
  slug: string;
  type: string;
  dateType: string | null;
  isRequired: boolean;
  phase: {
    id: string;
    name: string;
    order: string;
  };
  phaseOrder: number;
  table: string | null;
  tableOrder: number | null;
  isBigString: boolean;
  isInative: boolean;
}

export const cardFieldApi = {
  getCardFieldsByArea: (areaId: string) =>
    api.get<IGetCardFieldApi[]>(`/areas/${areaId}/customFields`),
};
