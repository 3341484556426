import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { useAuth } from 'src/context/AuthContext';
import { useWorkspaceFilters } from 'src/context/WorkspaceFiltersContext';
import { CalendarDaysIcon, FilterIcon, PlusCircleIcon, X } from 'lucide-react';
import { TooltipWrapper } from 'src/components/ui/tooltip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { DatePickerWithRange } from 'src/components/DateRangePicker';
import { DateRange } from 'react-day-picker';
import CustomSelect from 'src/components/Select';
import { statuses } from 'src/utils/taskFilterColumnsUtils';
import {
  CalendarFilterProps,
  DefaultObject,
  VisualizationTypes,
} from '../types';
import { Button } from '../../../components/ui/button';

interface TaskFilterProps {
  readonly visualizationFilter: string;
  readonly setVisualizationFilter: (value: VisualizationTypes) => void;
  readonly userOptions: { id: string; name: string }[];
  readonly handleClickNewTask: () => void;
  readonly uniqueAccounts: DefaultObject[];
  readonly setCalendarFilters: (value: CalendarFilterProps | undefined) => void;
}

export const TaskFilters: React.FC<TaskFilterProps> = ({
  visualizationFilter,
  setVisualizationFilter,
  userOptions,
  handleClickNewTask,
  uniqueAccounts,
  setCalendarFilters,
}) => {
  const user = useAuth();
  const { setFiltersData } = useWorkspaceFilters();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [startDateRange, setStartDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [endDateRange, setEndDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [accountOptions, setAccountOptions] = useState<DefaultObject[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<
    DefaultObject[] | undefined
  >(undefined);
  const [selectedStatus, setSelectedStatus] = useState<
    DefaultObject[] | undefined
  >(undefined);

  useEffect(() => {
    setAccountOptions(uniqueAccounts);
  }, [uniqueAccounts]);

  const handleApplyCalendarFilter = () => {
    if (selectedAccount || selectedStatus || startDateRange || endDateRange) {
      setCalendarFilters({
        account: selectedAccount || undefined,
        status: selectedStatus || undefined,
        startDateRange: startDateRange || undefined,
        endDateRange: endDateRange || undefined,
      });
      setHasFilters(true);
    } else {
      setCalendarFilters(undefined);
      setHasFilters(false);
    }
  };

  const handleCleanCalendarFilter = () => {
    setStartDateRange(undefined);
    setEndDateRange(undefined);
    setSelectedAccount(undefined);
    setSelectedStatus(undefined);
    setCalendarFilters(undefined);
    setHasFilters(false);
  };

  const handleApplyWorkspaceFilter = (user_id: string) => {
    setFiltersData(filtersData => ({
      ...filtersData,
      tasks: {
        user_id,
      },
    }));
  };

  const handleUserChange = (value: string) => {
    if (value || (user && user?.user?.id)) {
      const selectedUser = value || user.user.id;
      handleApplyWorkspaceFilter(selectedUser);
    }
  };

  const taskStatusOptions = statuses
    .map(status => ({
      id: status.value,
      name: status.label,
      options: {
        icon: status.icon,
        fillColor: status.fillColor,
        color: status.color,
      },
    }))
    .filter(status => status.id !== 'Não agendada');

  return (
    <div className="flex gap-2 rounded-md p-1 items-center box-border flex-wrap">
      <span className="font-semibold text-xl mr-8">Tarefas</span>
      <Button
        variant={visualizationFilter === 'TODAY' ? 'default' : 'secondary'}
        className="h-8 text-xs p-2 overflow-hidden min-w-fit"
        onClick={() => setVisualizationFilter('TODAY')}
      >
        <span className="text-nowrap">Do dia</span>
      </Button>
      <Button
        variant={visualizationFilter === 'OVERDUE' ? 'default' : 'secondary'}
        className="h-8 text-xs p-2 overflow-hidden min-w-fit"
        onClick={() => setVisualizationFilter('OVERDUE')}
      >
        <span className="text-nowrap">Vencidas</span>
      </Button>
      <Button
        variant={
          visualizationFilter === 'UNSCHEDULED' ? 'default' : 'secondary'
        }
        className="h-8 text-xs p-2 overflow-hidden min-w-fit"
        onClick={() => setVisualizationFilter('UNSCHEDULED')}
      >
        <span className="text-nowrap">Não agendadas</span>
      </Button>
      <TooltipWrapper
        value="Visualizar Calendário"
        className="font-medium text-xs"
      >
        <Button
          variant={visualizationFilter === 'CALENDAR' ? 'default' : 'secondary'}
          className="h-8 text-xs p-2 overflow-hidden min-w-fit"
          onClick={() => setVisualizationFilter('CALENDAR')}
        >
          <CalendarDaysIcon className="h-5 w-5" />
        </Button>
      </TooltipWrapper>
      {user?.user &&
        user.user?.role?.name === 'ROLE_ADMIN' &&
        userOptions &&
        userOptions.length > 0 && (
          <Select onValueChange={(value: string) => handleUserChange(value)}>
            <SelectTrigger className="w-[150px] h-8 text-xs font-medium p-2 overflow-hidden min-w-fit">
              <SelectValue placeholder={user.user.name} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Usuários</SelectLabel>
                {userOptions?.map(userOption => (
                  <SelectItem key={userOption.id} value={userOption.id}>
                    {userOption?.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
      {visualizationFilter === 'CALENDAR' && (
        <Popover open={isFilterOpen} onOpenChange={setIsFilterOpen}>
          <PopoverTrigger>
            <TooltipWrapper
              value="Filtros"
              className="font-medium text-xs min-w-fit"
            >
              <Button
                size="icon"
                variant={hasFilters ? 'default' : 'outline'}
                className="h-[32px] w-[50px]"
              >
                <FilterIcon size={16} />
              </Button>
            </TooltipWrapper>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col w-full h-full gap-3 max-w-[350px]">
            <DatePickerWithRange
              date={startDateRange}
              setDate={setStartDateRange}
              placeholder="Início"
            />
            <DatePickerWithRange
              date={endDateRange}
              setDate={setEndDateRange}
              placeholder="Fim"
            />
            <CustomSelect
              items={accountOptions || []}
              selectedItems={selectedAccount}
              setSelectedItems={setSelectedAccount}
              placeholder="Contas"
            />
            <CustomSelect
              items={taskStatusOptions || []}
              selectedItems={selectedStatus}
              setSelectedItems={setSelectedStatus}
              placeholder="Situação"
              maxItems={3}
            />
            <Button
              onClick={() => {
                handleApplyCalendarFilter();
                setIsFilterOpen(false);
              }}
            >
              Aplicar
            </Button>
            <Button
              type="reset"
              variant="secondary"
              onClick={handleCleanCalendarFilter}
            >
              Limpar
            </Button>
          </PopoverContent>
        </Popover>
      )}
      {hasFilters && (
        <Button
          variant="ghost"
          onClick={handleCleanCalendarFilter}
          className="h-8 px-1 lg:px-3 min-w-fit invisible lg:visible"
        >
          Limpar
          <X className="ml-1 h-4 w-4" />
        </Button>
      )}
      <div className="flex flex-1 lg:justify-end min-w-fit">
        <Button onClick={handleClickNewTask} size="sm" className="h-8">
          <PlusCircleIcon className="mr-1 h-4 w-4" /> Tarefa
        </Button>
      </div>
    </div>
  );
};
